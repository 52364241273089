import React, { useCallback, useState } from 'react';
import {
  DefaultButton,
  IComboBoxOption,
  Panel,
  PanelType,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import _ from 'lodash';
import {
  convertConstructionSitesToComboBoxOptions,
  IConstructionSite,
  modalContentStyles,
} from '../../../utils';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import DateView from '../../../components/parts/DateView';
import { IAssetLog } from '../../../utils/AssetLog';
import { convertTeamsToComboBoxOptions, ITeam } from '../../../utils/Team';
import { ComboboxWithFilter } from '../../../components/parts';
import { customPanelStyles } from '../../../theme';

const AssetLogGroupDetail = ({
  isOpen,
  dismissPanel,
  logGroup,
  setLogGroup,
  saveLogGroup,
  //
  constructionSites,
  constructionSiteSearch,
  setConstructionSiteSearch,
  //
  teams,
  teamSearch,
  setTeamSearch,
  //
  selectedObjects,
}: //
any) => {
  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setLogGroup((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  // ===============================================================================================
  // ===============================================================================================
  // ===============================================================================================

  // ===============================================================================================
  // =================================== C O N S T R U C T I O N  ==================================
  // ===============================================================================================

  const setConstructionSite = useCallback(
    (newValue: IComboBoxOption) => {
      if (constructionSites && constructionSites.length > 0 && newValue) {
        const index = constructionSites.findIndex(
          (item: IConstructionSite) => item.id === newValue.key,
        );
        const constructionSite = constructionSites[index];
        if (constructionSite) {
          setLogGroup((prevState: any) => ({
            ...prevState,
            construction_site: constructionSite,
            team: undefined,
          }));
        }
      }
    },
    [logGroup, constructionSites],
  );

  const getConstructionSitesIncludingPurchaseOrderConstructionSite =
    useCallback(() => {
      if (logGroup && logGroup.construction_site && constructionSites) {
        const constructionSiteArray = _.cloneDeep(constructionSites);
        const constructionSite = constructionSiteArray.find(
          (e: IConstructionSite) => e.id === logGroup.construction_site?.id,
        );
        if (!constructionSite) {
          constructionSiteArray.push(logGroup.construction_site);
        }

        constructionSiteArray.sort(
          (obj1: IConstructionSite, obj2: IConstructionSite) => {
            let compareName = 0;
            compareName = obj1.name.localeCompare(obj2.name);

            return compareName;
          },
        );

        return constructionSiteArray;
      }
      if (constructionSites) {
        const constructionSiteArray = _.cloneDeep(constructionSites);
        constructionSiteArray.sort(
          (obj1: IConstructionSite, obj2: IConstructionSite) => {
            let compareName = 0;
            compareName = obj1.name.localeCompare(obj2.name);

            return compareName;
          },
        );

        return constructionSiteArray;
      }
      return [];
    }, [teams, logGroup, constructionSites]);

  // ===============================================================================================
  // ===============================================================================================
  // ===============================================================================================

  // ===============================================================================================
  // ==========================================  T E A M  ==========================================
  // ===============================================================================================

  const setTeam = useCallback(
    (newValue: IComboBoxOption) => {
      if (teams && teams.length > 0 && newValue) {
        const index = teams.findIndex(
          (item: ITeam) => item.id === newValue.key,
        );
        const team = teams[index];
        if (team) {
          setLogGroup((prevState: any) => ({
            ...prevState,
            team,
            construction_site: undefined,
          }));
        }
      }
    },
    [logGroup, teams],
  );

  const getTeams = useCallback(() => {
    if (logGroup && logGroup.team && teams) {
      const teamArray = _.cloneDeep(teams);
      const team = teamArray.find((e: ITeam) => e.id === logGroup.team?.id);
      if (!team) {
        teamArray.push(logGroup.team);
      }

      teamArray.sort((obj1: ITeam, obj2: ITeam) => {
        let compareName = 0;
        compareName = obj1.name.localeCompare(obj2.name);

        return compareName;
      });

      return teamArray;
    }
    if (teams) {
      const teamArray = _.cloneDeep(teams);
      teamArray.sort((obj1: ITeam, obj2: ITeam) => {
        let compareName = 0;
        compareName = obj1.name.localeCompare(obj2.name);

        return compareName;
      });

      return teamArray;
    }
    return [];
  }, [teams, logGroup, constructionSites]);

  // ===============================================================================================
  // ===============================================================================================
  // ===============================================================================================

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText='Logs toevoegen'
      type={PanelType.custom}
      customWidth='500px'
      styles={customPanelStyles}
    >
      <div>
        <div className={modalContentStyles.header} />

        <div className={modalContentStyles.body}>
          <div
            style={{
              display: 'flex',
              gap: '8px',
              marginBottom: '10px',
              flexWrap: 'wrap',
            }}
          >
            {selectedObjects.map((obj: any) => (
              <span
                style={{
                  background: 'rgb(112, 191, 68)',
                  borderRadius: '8px',
                  color: 'white',
                  padding: '3px',
                }}
                key={obj.id}
              >
                {obj.name}
              </span>
            ))}
          </div>

          <Stack style={{ marginBottom: 10 }}>
            <ComboboxWithFilter
              label='Werf'
              options={convertConstructionSitesToComboBoxOptions(
                getConstructionSitesIncludingPurchaseOrderConstructionSite(),
              )}
              value={
                logGroup && logGroup.construction_site
                  ? logGroup.construction_site.id
                  : ''
              }
              multiline={false}
              callBack={(newValue: IComboBoxOption[]) => {
                if (newValue && newValue.length > 0) {
                  setConstructionSite(newValue[0]);
                }
              }}
              setFilter={(filterValue: string) => {
                setConstructionSiteSearch(filterValue);
              }}
              disabled={logGroup?.id && !logGroup?.last}
              allowFreeForm
              //   style={{ width: '300px' }}
            />

            <p
              style={{
                cursor: 'pointer',
                color: '#71BF44',
                marginTop: 0,
              }}
              onClick={() => {
                setLogGroup({
                  ...logGroup,
                  construction_site: undefined,
                });
              }}
              aria-hidden='true'
            >
              Verwijder
            </p>
          </Stack>
          <Stack style={{ marginBottom: 10 }}>
            <ComboboxWithFilter
              label='Team'
              options={convertTeamsToComboBoxOptions(getTeams())}
              value={logGroup && logGroup.team ? logGroup.team.id : ''}
              multiline={false}
              callBack={(newValue: IComboBoxOption[]) => {
                if (newValue && newValue.length > 0) {
                  setTeam(newValue[0]);
                }
              }}
              setFilter={(filterValue: string) => {
                setTeamSearch(filterValue);
              }}
              allowFreeForm
              disabled={logGroup?.id && !logGroup?.last}
              //   style={{ width: '300px' }}
            />

            <p
              style={{
                cursor: 'pointer',
                color: '#71BF44',
                marginTop: 0,
              }}
              onClick={() => {
                setLogGroup({
                  ...logGroup,
                  team: undefined,
                });
              }}
              aria-hidden='true'
            >
              Verwijder
            </p>
          </Stack>

          <Stack style={{ marginBottom: 10 }}>
            <DateView
              item={logGroup}
              setItem={setLogGroup}
              date={logGroup && (logGroup as IAssetLog).date_created}
              label='Aangemaakt op'
              field='date_created'
              disabled={logGroup?.id && !logGroup?.last}
            />
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='comments'
              label='Extra info'
              multiline
              value={
                logGroup && (logGroup as IAssetLog).comments
                  ? (logGroup as IAssetLog).comments
                  : ''
              }
              onChange={onChangeTextFieldValue}
              disabled={logGroup?.id && !logGroup?.last}
            />
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack
            style={{
              flexDirection: 'row',
              marginTop: 10,
              justifyContent: 'space-between',
            }}
          >
            <Stack style={{ flexDirection: 'row' }}>
              {(!logGroup?.id || (logGroup.id && logGroup.last)) && (
                <SaveButtonWithPermissions
                  disabled={!logGroup}
                  save={saveLogGroup}
                  permission='write:assetLogs'
                />
              )}

              <StackItem>
                <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
              </StackItem>
            </Stack>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default AssetLogGroupDetail;
