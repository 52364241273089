import React, { useState, useEffect } from 'react';
import {
  Stack,
  TextField,
  PrimaryButton,
  IconButton,
  Icon,
  ICommandBarItemProps,
  DetailsListLayoutMode,
  IColumn,
  DetailsRow,
  IDetailsRowProps,
  ShimmeredDetailsList,
  SpinButton,
  Label,
} from '@fluentui/react';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { IWaterPoint, UPDATE_WATER_POINTS } from '../../utils/WaterPoint';
import { ConstructionSiteCompactView } from '../construction';
import {
  cancelIcon,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  iconButtonStyles,
  IConstructionSite,
  stackTokens15,
  stackTokens5,
  stackTokens50,
} from '../../utils';
import { CommandBarSticky, DatePickerNL } from '../../components/parts';
import { commandBarTheme } from '../../theme';
import { useAppSelector } from '../../redux/hooks';
import { getPermissions } from '../../redux/permission/permissionSlice';
import { toastError, toastSuccess } from '../../utils/toast';
import SaveButtonWithPermissions from '../../components/parts/SaveButtonWithPermissions';
import WaterPointsTemplate from './WaterPointsTemplate';

interface WaterPointsListProps {
  constructionSite: IConstructionSite;
  saveConstructionSite: () => void;
  waterPoints: IWaterPoint[];
  setCurrentView: (value: string) => void;
  refetch: any;
}

const WaterPointsList: React.FC<WaterPointsListProps> = ({
  constructionSite,
  saveConstructionSite,
  waterPoints,
  setCurrentView,
  refetch,
}) => {
  const { getAccessTokenSilently, user } = useAuth0();

  const navigate = useNavigate();
  const permissions = useAppSelector(getPermissions);

  const [waterPointList, setWaterPointList] = useState<IWaterPoint[]>([]);
  const [editedIndexes, setEditedIndexes] = useState<Set<number>>(new Set());
  const [showPdf, setShowPdf] = useState(false);
  const [executionListDate, setExecutionListDate] = useState(new Date());

  useEffect(() => {
    resetWaterPointsList();
  }, [waterPoints]);

  const resetWaterPointsList = () => {
    setWaterPointList(
      waterPoints.map(point => ({
        ...point,
        toBeDeleted: false,
        isNew: false,
      })),
    );
    setEditedIndexes(new Set());
  };

  const onChangeDate = (newDate: Date) => {
    setExecutionListDate(newDate);
  };

  const totalWaterPoints = waterPointList.reduce(
    (total, point) =>
      total + (point.tap_water_points || 0) + (point.rain_water_points || 0),
    0,
  );

  const totalRainWaterPoints = waterPointList.reduce(
    (total, point) => total + (point.rain_water_points || 0),
    0,
  );

  useEffect(() => {
    document.title = constructionSite
      ? `3bouw | Tappuntlijst - ${constructionSite.name}`
      : '3bouw | Tappuntlijst';
  }, [constructionSite]);

  const [modifyWaterPoints] = useMutation(UPDATE_WATER_POINTS);

  const saveWaterPoints = async () => {
    try {
      const toUpdate = waterPointList
        .filter(
          (point, index) =>
            editedIndexes.has(index) && !point.toBeDeleted && !point.isNew,
        )
        .map(({
 isNew, toBeDeleted, __typename, ...point
}) => point);

      const toDelete = waterPointList
        .filter(point => point.toBeDeleted && !point.isNew)
        .map(({
 isNew, toBeDeleted, __typename, ...point
}) => point);

      const toCreate = waterPointList
        .filter(point => point.isNew && !point.toBeDeleted)
        .map(({
 isNew, toBeDeleted, __typename, id, ...point
}) => ({
          ...point,
          id: undefined,
        }));

      const allInput = {
        toUpdate,
        toDelete,
        toCreate,
      };

      const res = await toast.promise(
        new Promise((resolve, reject) => {
          modifyWaterPoints({
            variables: {
              construction_site_id: constructionSite.id,
              data: allInput,
            },
            onError: error => {
              reject(error);
            },
            onCompleted: async (result: any) => {
              await refetch();
              resetWaterPointsList();
              resolve(result);
              toastSuccess('Tappunten gewijzigd');
            },
          });
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      await res;
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  const addWaterPoint = () => {
    const newWaterPoint: IWaterPoint = {
      id: Date.now(),
      name: '',
      rain_water_points: 0,
      tap_water_points: 0,
      weight: waterPointList.length + 1,
      toBeDeleted: false,
      isNew: true,
    };
    setWaterPointList([...waterPointList, newWaterPoint]);
    setEditedIndexes(new Set([...editedIndexes, waterPointList.length]));
  };

  const updateWaterPoint = (
    index: number,
    key: keyof IWaterPoint,
    value: any,
  ) => {
    const updatedList = [...waterPointList];
    updatedList[index][key] = value;
    setWaterPointList(updatedList);
    setEditedIndexes(new Set([...editedIndexes, index]));
  };

  const moveItem = (index: number, direction: 'up' | 'down') => {
    const newList = [...waterPointList];
    const itemToMove = newList[index];
    const swapIndex = direction === 'up' ? index - 1 : index + 1;

    if (swapIndex >= 0 && swapIndex < newList.length) {
      newList[index] = newList[swapIndex];
      newList[swapIndex] = itemToMove;
      newList[index].weight = index + 1;
      newList[swapIndex].weight = swapIndex + 1;
      setWaterPointList(newList);
      setEditedIndexes(new Set([...editedIndexes, index, swapIndex]));
    }
  };

  const deleteWaterPoint = (index: number) => {
    const updatedList = [...waterPointList];
    updatedList[index].toBeDeleted = !updatedList[index].toBeDeleted;
    setWaterPointList(updatedList);
    setEditedIndexes(new Set([...editedIndexes, index]));
  };

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: 'saveConstructionSite',
      text: 'Opslaan',
      iconProps: { iconName: 'Save' },
      onClick: () => {
        saveWaterPoints();
      },
      theme: commandBarTheme,
      disabled:
        !editedIndexes.size || !permissions.includes('write:constructionSites'),
    },
    {
      key: 'download',
      text: 'PDF',
      iconProps: { iconName: 'Pdf' },
      onClick: () => {
        setShowPdf(true);
      },
      theme: commandBarTheme,
    },
    {
      key: 'toSiteOvervieuw',
      text: 'Werffiche',
      iconProps: {
        iconName: 'ClipboardList',
      },
      onClick: () => {
        setCurrentView('site-overview');
      },
      theme: commandBarTheme,
    },
    {
      key: 'toConstructionSheet',
      text: 'Uitvoeringslijst',
      iconProps: {
        iconName: 'Trackers',
      },
      onClick: () => {
        setCurrentView('implementation-list');
      },
      theme: commandBarTheme,
    },
  ];

  const columns: IColumn[] = [
    {
      key: 'name',
      name: 'Naam',
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 300,
      onRender: (item: IWaterPoint, index?: number) => (
        <TextField
          value={item.name}
          onChange={(e, newValue) => updateWaterPoint(index!, 'name', newValue)}
        />
      ),
    },
    {
      key: 'rainWater',
      name: 'Regenwater',
      fieldName: 'rain_water_points',
      minWidth: 100,
      maxWidth: 150,
      onRender: (item: IWaterPoint, index?: number) => (
        <SpinButton
          value={item.rain_water_points?.toString() || ''}
          onChange={(e, newValue) =>
            updateWaterPoint(index!, 'rain_water_points', Number(newValue))
          }
        />
      ),
    },
    {
      key: 'tapWater',
      name: 'Leidingwater',
      fieldName: 'tap_water_points',
      minWidth: 100,
      maxWidth: 150,
      onRender: (item: IWaterPoint, index?: number) => (
        <SpinButton
          value={item.tap_water_points?.toString() || ''}
          onChange={(e, newValue) =>
            updateWaterPoint(index!, 'tap_water_points', Number(newValue))
          }
        />
      ),
    },
    {
      key: 'total',
      name: 'Tappunt (regenwater + leidingwater)',
      fieldName: 'totalTapPoints',
      minWidth: 250,
      onRender: (item: IWaterPoint) => (
        <span style={{ fontWeight: 'bold' }}>
          {(item.rain_water_points || 0) + (item.tap_water_points || 0)}
        </span>
      ),
    },
    {
      key: 'actions',
      name: 'Sortering',
      fieldName: 'actions',
      minWidth: 350,
      onRender: (item: IWaterPoint, index?: number) => (
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <IconButton
            iconProps={{ iconName: 'Up' }}
            disabled={index === 0}
            onClick={() => moveItem(index!, 'up')}
            title='Move up'
          />
          <IconButton
            iconProps={{ iconName: 'Down' }}
            disabled={index === waterPointList.length - 1}
            onClick={() => moveItem(index!, 'down')}
            title='Move down'
          />
          <IconButton
            iconProps={{ iconName: 'Delete' }}
            onClick={() => deleteWaterPoint(index!)}
            title='Delete'
            style={{
              backgroundColor: item.toBeDeleted ? '#fce5e3 ' : 'inherit',
            }}
          />
        </Stack>
      ),
    },
  ];

  // =========================================================================
  // =========================================================================
  // =========================================================================

  const sync = async () => {
    const token = await getAccessTokenSilently();
    if (
      token
    ) {
      await toast.promise(
        new Promise((resolve, reject) => {
          fetch(`${process.env.REACT_APP_BACKEND_URI}/water-points/sync`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              construction_site_id: constructionSite?.id,
            }),
          })
            .then(res => {
              if (!res.ok) {
                throw new Error(`HTTP error! status: ${res.status}`);
              }
              return res.json();
            })
            .then(data => {
              console.log('data', data);
              resolve(data);
            })
            .catch(error => reject(error));
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return 'Syncing...';
            },
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      await refetch();

      toastSuccess('Synced');
    } else {
      toastError('Gebruiker niet gevonden');
    }
  };

  return (
    <Stack
      tokens={stackTokens15}
      style={{ width: '100%', marginBottom: '50px' }}
    >
      <div style={{ padding: '25px', paddingBottom: '0px' }}>
        <h3
          style={{
            fontSize: '20px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Icon iconName='WorkItemAlert' style={{ fontSize: '20px' }} />
          Tappunten lijst
        </h3>
      </div>

      <CommandBarSticky
        items={commandBarItems}
        ariaLabel="Gebruik pijltjes toetsen om te navigeren tussen verschillende commando's"
        theme={commandBarTheme}
        farItems={[
          {
            key: 'toConstructionsSites',
            text: 'Werfoverzicht',
            iconProps: { iconName: 'Back' },
            onClick: () => navigate('/construction-sites/'),
            theme: commandBarTheme,
          },
        ]}
      />

      {waterPointList?.length === 0 && (
        <div style={{
          marginLeft: '25px',
          marginRight: '25px',
          padding: '10px',
          background: 'rgba(255, 157, 87, 0.1)',
          backdropFilter: 'blur(8px)',
          borderRadius: '4px',
          border: '1px solid rgba(255, 157, 87, 0.2)',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '10px',
        }}
        >
          <p style={{
            margin: 0,
            color: '#ff9d57',
            fontWeight: 600,
          }}
          >Oude versie van tappunten, sync naar nieuwe versie
          </p>
          <SaveButtonWithPermissions
            disabled={!permissions.includes('write:constructionSites')}
            save={sync}
            permission='write:constructionSites'
            text='Sync'
            color='#ff9d57'
          />
        </div>
      )}

      <div style={{ marginLeft: '25px' }}>
        <ConstructionSiteCompactView constructionsite={constructionSite} />
      </div>

      <div style={{ marginLeft: '25px' }}>
        <Label>TAPPUNTEN</Label>
        <Stack
          horizontal
          disableShrink
          tokens={stackTokens50}
          styles={{ root: { paddingTop: '10px' } }}
        >
          <Stack.Item>Totaal aantal tappunten voorzien:</Stack.Item>
          <Stack.Item>{totalWaterPoints}</Stack.Item>
        </Stack>
        <Stack
          horizontal
          disableShrink
          tokens={stackTokens50}
          styles={{ root: { paddingTop: '10px' } }}
        >
          <Stack.Item>Tappunten hiervan op regenwater:</Stack.Item>
          <Stack.Item>{totalRainWaterPoints}</Stack.Item>
        </Stack>
        <Stack styles={{ root: { paddingTop: '20px' } }}>
          <Stack.Item>
            <PrimaryButton onClick={addWaterPoint}>
              Tappunt toevoegen
            </PrimaryButton>
          </Stack.Item>
        </Stack>
      </div>

      <ShimmeredDetailsList
        items={waterPointList}
        columns={columns}
        setKey='set'
        layoutMode={DetailsListLayoutMode.justified}
        selectionMode={0}
        onRenderRow={(props?: IDetailsRowProps) => {
          if (props) {
            const isEdited = editedIndexes.has(props.itemIndex);
            return (
              <DetailsRow
                {...props}
                styles={{
                  root: {
                    backgroundColor: props.item.toBeDeleted
                      ? '#fce5e3'
                      : isEdited
                      ? '#f9fcf4'
                      : 'white',
                  },
                }}
              />
            );
          }
          return null;
        }}
      />
      {showPdf && (
        <Stack
          styles={{
            root: {
              position: 'fixed',
              top: 60,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'white',
              display: 'flex',
              padding: '20px',
            },
          }}
        >
          <Stack
            horizontal
            horizontalAlign='space-between'
            styles={{ root: { marginBottom: 10 } }}
          >
            <Stack.Item>
              <h2 style={{ marginTop: 0 }}>PDF Bekijken</h2>
              <Stack tokens={stackTokens5} horizontal>
                <Stack.Item>
                  <DatePickerNL
                    updateParent={onChangeDate}
                    value={executionListDate}
                  />
                </Stack.Item>
                <Stack.Item>
                  <Stack horizontalAlign='end'>
                    <PDFDownloadLink
                      document={
                        <WaterPointsTemplate
                          constructionSite={constructionSite}
                          title='Tappunten'
                          date={executionListDate}
                          waterPoints={waterPoints}
                        />
                      }
                      fileName={`tappunten_${
                        constructionSite.id
                      }_${executionListDate.toLocaleDateString()}`}
                    >
                      {({ loading }) =>
                        (loading ? '' : <PrimaryButton text='Pdf downloaden' />)
                      }
                    </PDFDownloadLink>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel='Sluit venster'
                onClick={() => setShowPdf(false)}
              />
            </Stack.Item>
          </Stack>

          <Stack.Item styles={{ root: { flex: 1 } }}>
            <PDFViewer width='100%' height='100%'>
              <WaterPointsTemplate
                constructionSite={constructionSite}
                title='Tappunten'
                date={executionListDate}
                waterPoints={waterPoints}
              />
            </PDFViewer>
          </Stack.Item>
        </Stack>
      )}
    </Stack>
  );
};

export default WaterPointsList;
