import {
    Checkbox, DefaultButton, DetailsListLayoutMode, IColumn, IObjectWithKey, ISelection, SelectionMode,
} from '@fluentui/react';
import { useState } from 'react';
import { DetailsListDefault } from '../../../components/parts';
import { IEmployee } from '../../../utils';
import { formatPhoneNumber } from '../../../helpers';

interface props {
    items: IEmployee[],
    selection?: ISelection<IObjectWithKey> | undefined,
    onItemInvoked?: () => void;
    enableShimmer?: boolean;
    isSortedAsc?: boolean;
    sortedField: string;
    setSorting: (isDesc: boolean, field: string) => void;
    loadMore?: boolean;
    lazyLoading?: boolean;
    loading?: boolean;
    openNewUserModal: (employee: IEmployee) => void;
    loadMoreCallback?: () => void;
}

const EmployeeDetailsList = ({
    items,
    selection,
    onItemInvoked,
    enableShimmer = false,
    isSortedAsc = false,
    sortedField,
    setSorting, // = (isSortedAsc: boolean, sortedField: string) => {},
    loadMore,
    lazyLoading,
    loading,
    openNewUserModal,
    loadMoreCallback,
  }: props) => {
    // On Column Clic
    const onColumnClick = (event: any, column: any) => {
        const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
        let fieldName = '';
        let sortDescending = false;

        if (sortedColumn) {
          const newColumns = columns.map((col: IColumn) => {
            if (col.fieldName === column.fieldName) {
              col.isSorted = true;

              col.isSortedDescending =
                column.fieldName !== sortedColumn[0].fieldName
                  ? false
                  : !col.isSortedDescending;

              fieldName = column.fieldName;
              sortDescending = col.isSortedDescending;
            } else {
              col.isSorted = false;
            }
            return col;
          });

          setColumns(newColumns);
          setSorting(!sortDescending, fieldName);
        }
    };

    // Column List
    const columnsList = [
        {
          key: 'column1',
          name: 'Achternaam',
          fieldName: 'last_name',
          minWidth: 150,
          maxWidth: 150,
          isRowHeader: true,
          onRender: (employee: IEmployee) => <span>{employee.last_name}</span>,
          onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column2',
          name: 'Voornaam',
          fieldName: 'first_name',
          minWidth: 100,
          maxWidth: 100,
          isRowHeader: true,
          onRender: (employee: IEmployee) => <span>{employee.first_name}</span>,
          onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column3',
          name: 'Functie',
          fieldName: 'function',
          minWidth: 150,
          maxWidth: 150,
          isRowHeader: true,
          onRender: (employee: IEmployee) => <span>{employee.function}</span>,
          onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column4',
          name: 'GSM Werk',
          fieldName: 'mobile',
          minWidth: 130,
          maxWidth: 130,
          isRowHeader: true,
          onRender: (employee: IEmployee) => <span>{employee && employee.mobile_v2 ? employee.mobile_v2 : `${employee && employee.mobile ? employee.mobile : ''}`}</span>,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column4',
          name: 'GSM Privé',
          fieldName: 'private_mobile',
          minWidth: 130,
          maxWidth: 130,
          isRowHeader: true,
          onRender: (employee: IEmployee) => (
            <span>
              {employee && employee.private_mobile_v2 ?
            formatPhoneNumber(employee.private_mobile_v2) :
            `${employee && employee.private_mobile ? employee.private_mobile : ''}`}
            </span>
          ),
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column6',
          name: 'Actief',
          fieldName: 'active',
          minWidth: 30,
          maxWidth: 30,
          isRowHeader: true,
          onRender: (employee: IEmployee) => (
            <span>
              <Checkbox disabled checked={!!employee.active} />
            </span>
          ),
          onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column7',
          name: 'App User',
          minWidth: 150,
          maxWidth: 150,
          isRowHeader: true,
          onRender: (employee: IEmployee) => {
            if (employee && employee.app_user && employee.app_user.auth0_id) {
              return <div />;
            }
            return (
              <DefaultButton
                text='Gebruiker toevoegen'
                onClick={e => {
                  e.preventDefault();
                  openNewUserModal(employee);
                }}
              />
            );
          },
          data: 'string',
          isPadded: true,
        },
    ];

    const initColumns = (sortedField: string, isSortedAsc: boolean) => columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

    const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

    return (
      <DetailsListDefault
        items={items}
        columns={columns}
        layoutMode={DetailsListLayoutMode.justified}
        selection={selection}
        selectionMode={SelectionMode.single}
        selectionPreservedOnEmptyClick
        onItemInvoked={onItemInvoked}
        enableShimmer={enableShimmer}
        loadMore={loadMore}
        lazyLoading={lazyLoading}
        loadMoreCallback={loadMoreCallback}
        loading={loading}
      />
    );
  };

  export default EmployeeDetailsList;
