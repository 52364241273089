import React, { useState } from 'react';
import {
  Stack,
  Callout,
  Link,
  mergeStyleSets,
  Text,
  FontWeights,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import moment from 'moment';
import { property_types, IProperty, stackTokens5 } from '../../../utils';
import DocumentLink from './DocumentLink';

const MultiValueSummary = ({
  property,
  openDetail = (item: IProperty) => {},
}: {
  property: IProperty;
  openDetail: (item: IProperty) => void;
}) => {
  const [isCalloutVisible, setCalloutVisible] = useState<number | undefined>(
    undefined,
  );
  const renderChildren = (children: IProperty[]) => {
    const renderArray: any = [];

    if (!children || children.length < 1) return;

    children
      .sort((a, b) => a.weight_for_execution_list - b.weight_for_execution_list)
      .map((childProperty: IProperty) => {
        switch (childProperty.type.name) {
          case property_types.STRING:
            // alert('test');
            renderArray.push(
              <div
                style={
                  property.delete
                    ? { opacity: 0.5, textDecoration: 'line-through' }
                    : undefined
                }
                key={`${childProperty.id}_child`}
              >
                <strong>{childProperty.name}</strong>
                {': '}
                {childProperty.value}
              </div>,
            );
            break;
          case property_types.DATE:
            renderArray.push(
              <div
                style={
                  property.delete
                    ? { opacity: 0.5, textDecoration: 'line-through' }
                    : undefined
                }
                key={`${childProperty.id}_child`}
              >
                <strong>{childProperty.name}</strong>
                {': '}
                {childProperty.value &&
                  moment(childProperty.value).format('DD-MM-YYYY')}
              </div>,
            );
            break;
          case property_types.DOCUMENT:
            renderArray.push(
              <div
                style={
                  property.delete
                    ? { opacity: 0.5, textDecoration: 'line-through' }
                    : undefined
                }
                key={`${childProperty.id}_child`}
              >
                <strong>{childProperty.name}</strong>
                {': '}
                <DocumentLink property={childProperty} inline />
              </div>,
            );
            break;
          case property_types.NOTE:
            renderArray.push(
              <div
                style={
                  property.delete
                    ? { opacity: 0.5, textDecoration: 'line-through' }
                    : undefined
                }
                key={`${childProperty.id}_child`}
              >
                <strong>{childProperty.name}</strong>
                {': '}
                {childProperty.value}
              </div>,
            );
            break;
          case property_types.SUGGESTED_VALUE:
            renderArray.push(
              <div
                style={
                  property.delete
                    ? { opacity: 0.5, textDecoration: 'line-through' }
                    : undefined
                }
                key={`${childProperty.id}_child`}
              >
                <strong>{childProperty.name}</strong>
                {': '}
                {childProperty.suggested_value
                  ? childProperty.suggested_value.suggested_value
                  : undefined}
              </div>,
            );
            break;
          case property_types.PARENT:
            break;
          default:
            renderArray.push(
              <span key={`${childProperty.id}_child`}>Error</span>,
            );
        }
      });

    return renderArray;
  };

  return (
    <Stack tokens={stackTokens5}>
      {property?.value ? <span>{property?.value}</span> : null}

      {property.nested_type &&
      property.nested_type.name === property_types.PARENT ? (
        <span>
          {property.children.map(child => {
            const buttonId = `callout-button-${child.id}`;
            const labelId = `callout-label-${child.id}`;
            const descriptionId = `callout-description${child.id}`;
            return (
              <div>
                <strong>{child.name}</strong>: samengevat veld (
                <a
                  href='#'
                  id={buttonId}
                  style={{ color: '#71BF43', fontWeight: 'bold' }}
                  onClick={e => {
                    e.preventDefault();
                    setCalloutVisible(child.id);
                    // openDetail(property);
                  }}
                >
                  toon
                  {isCalloutVisible === child.id && (
                    <Callout
                      className={styles.callout}
                      ariaLabelledBy={labelId}
                      ariaDescribedBy={descriptionId}
                      role='alertdialog'
                      gapSpace={0}
                      target={`#${buttonId}`}
                      onDismiss={() => setCalloutVisible(undefined)}
                      setInitialFocus
                    >
                      <Text
                        block
                        variant='xLarge'
                        className={styles.title}
                        id={labelId}
                      >
                        {child.name}
                      </Text>
                      <Text block variant='medium' id={descriptionId}>
                        {renderChildren(child.children)}
                      </Text>
                    </Callout>
                  )}
                </a>
                )
              </div>
            );
          })}
        </span>
      ) : (
        renderChildren(property.children)
      )}
    </Stack>
  );
};

export default MultiValueSummary;

const styles = mergeStyleSets({
  button: {
    width: 130,
  },
  callout: {
    width: 320,
    padding: '20px 24px',
  },
  title: {
    marginBottom: 12,
    fontWeight: FontWeights.semilight,
  },
  link: {
    display: 'block',
    marginTop: 20,
  },
});
