import {
  DetailsListLayoutMode,
  IColumn,
  IObjectWithKey,
  ISelection,
  SelectionMode,
} from '@fluentui/react';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { DetailsListDefault } from '../../../components/parts';
import { displayNameLead, ILead } from '../../../utils/Lead';
import { IEmployee } from '../../../utils';
import { formatPhoneNumber } from '../../../helpers';
import Label from '../../../components/parts/Label';

interface props {
  items: ILead[];
  selection?: ISelection<IObjectWithKey> | undefined;
  enableShimmer?: boolean;
  isShimmered?: boolean;
  isSortedAsc?: boolean;
  sortedField: string;
  setSorting: (isDesc: boolean, field: string) => void;
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
  onItemInvoked?:
    | ((item?: any, index?: number | undefined, ev?: Event | undefined) => void)
    | undefined;
  employees?: IEmployee[];
}

const LeadsDetailsList = ({
  items,
  selection,
  onItemInvoked,
  enableShimmer = false,
  isShimmered = false,
  isSortedAsc = false,
  sortedField,
  setSorting, // = (isSortedAsc: boolean, sortedField: string) => {},
  loadMore,
  lazyLoading,
  loading,
  loadMoreCallback,
  employees,
}: props) => {
  // On Column Click
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;

          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });

      setColumns(newColumns);
      setSorting(!sortDescending, fieldName);
    }
  };

  const mergeSalesRepsToLeads = useCallback(
    (customers: ILead[]) => {
      if (employees) {
        return customers.map(customer => {
          const salesRep = employees.find(
            employee => employee.id === customer.sales_rep_employee_id,
          );
          return {
            ...customer,
            sales_rep_employee: salesRep,
          };
        });
      }
      return customers;
    },
    [employees],
  );

  const statusColor = (status: string) => {
    if (status === 'ACTIVE') {
      return 'green';
    }

    if (status === 'SOLD') {
      return 'blue';
    }

    if (status === 'PASSIVE') {
      return 'orange';
    }

    if (status === 'PASSIVE_VISUAL_CONTACT') {
      return 'orange';
    }

    if (status === 'NIHIL') {
      return 'red';
    }

    if (status === 'SOLD') {
      return 'blue';
    }

    if (status === 'INVOICE_ONLY') {
      return 'gray';
    }

    return '';
  };

  const statusLabel = (status: string) => {
    if (status === 'ACTIVE') {
      return <span style={{ padding: '0px 27px' }}>Actief</span>;
    }

    if (status === 'SOLD') {
      return <span style={{ padding: '0px 17px' }}>Verkocht</span>;
    }

    if (status === 'PASSIVE') {
      return <span style={{ padding: '0px 24px' }}>Passief</span>;
    }

    if (status === 'PASSIVE_VISUAL_CONTACT') {
      return <span>Passief visueel</span>;
    }

    if (status === 'NIHIL') {
      return <span style={{ padding: '0px 31px' }}>Nihil</span>;
    }

    if (status === 'INVOICE_ONLY') {
      return <span style={{ padding: '0px 4px' }}>Factuur klant</span>;
    }

    return <span>Onbekend</span>;
  };

  // Column List
  const columnsList: IColumn[] = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'last_name1',
      minWidth: 220,
      isRowHeader: true,
      onRender: (lead: ILead) => <span>{displayNameLead(lead, true)}</span>,
      onColumnClick,
      data: 'string',
      isPadded: true,
      isResizable: true,
    },
    {
      key: 'column2',
      name: 'Adres',
      fieldName: 'address',
      minWidth: 170,
      maxWidth: 200,
      isRowHeader: true,
      onRender: (lead: ILead) => (
        <span>
          {lead.address1}
          <br />
          {lead.zip_code1} {lead.city1}
        </span>
      ),
      data: 'string',
      isPadded: true,
      isResizable: true,
    },
    {
      key: 'column4',
      name: 'Contact',
      fieldName: 'contact',
      minWidth: 135,
      isRowHeader: true,
      onRender: (lead: ILead) => (
        <div>
          {/* Mobile Number */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '4px',
              color:
                lead.mobile1_v2 ||
                lead.mobile2_v2 ||
                lead.mobile1 ||
                lead.mobile2
                  ? 'inherit'
                  : '#e8e8e8',
            }}
          >
            <i
              className='ms-Icon ms-Icon--CellPhone'
              style={{ marginRight: '8px' }}
              aria-hidden='true'
            />
            <span>
              {lead.mobile1_v2
                ? formatPhoneNumber(lead.mobile1_v2)
                : lead.mobile2_v2
                ? formatPhoneNumber(lead.mobile2_v2)
                : lead.mobile1 || lead.mobile2 || ''}
            </span>
          </div>

          {/* Landline Phone */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              color:
                lead.phone1_v2 || lead.phone2_v2 || lead.phone1 || lead.phone2
                  ? 'inherit'
                  : '#e8e8e8',
            }}
          >
            <i
              className='ms-Icon ms-Icon--Phone'
              style={{ marginRight: '8px' }}
              aria-hidden='true'
            />
            <span>
              {lead.phone1_v2
                ? formatPhoneNumber(lead.phone1_v2)
                : lead.phone2_v2
                ? formatPhoneNumber(lead.phone2_v2)
                : lead.phone1 || lead.phone2 || ''}
            </span>
          </div>
        </div>
      ),
      data: 'string',
      isPadded: true,
      isResizable: true,
    },
    {
      key: 'column7',
      name: 'Status',
      fieldName: 'status',
      minWidth: 100,
      isRowHeader: true,
      onColumnClick,
      onRender: (lead: ILead) => (
        <Label color={statusColor(lead.status ? lead.status : '') as any}>
          {statusLabel(lead.status ? lead.status : '')}
        </Label>
      ),
      data: 'string',
      isPadded: true,
      isResizable: true,
    },
    {
      key: 'column6',
      name: 'Verkoper',
      fieldName: 'sales_rep_employee',
      minWidth: 120,
      isRowHeader: true,
      isResizable: true,
      onRender: useCallback(
        (customer: ILead) => (
          <span>
            {customer.sales_rep_employee &&
              `${customer.sales_rep_employee.first_name} ${customer.sales_rep_employee.last_name}`}
          </span>
        ),
        [employees],
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column8',
      name: 'Aangemaakt',
      fieldName: 'date_created',
      minWidth: 150,
      isRowHeader: true,
      onRender: (lead: ILead) => (
        <span>
          {lead.date_created &&
            moment(lead.date_created).format('DD-MM-YYYY hh:mm')}
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
      isResizable: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) =>
    columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  return (
    <DetailsListDefault
      items={mergeSalesRepsToLeads(items)}
      columns={columns}
      layoutMode={DetailsListLayoutMode.fixedColumns}
      selection={selection}
      selectionMode={SelectionMode.single}
      selectionPreservedOnEmptyClick
      onItemInvoked={onItemInvoked}
      enableShimmer={enableShimmer}
      isShimmered={isShimmered}
      loadMore={loadMore}
      lazyLoading={lazyLoading}
      loadMoreCallback={loadMoreCallback}
      loading={loading}
    />
  );
};

export default LeadsDetailsList;
