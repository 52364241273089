import { gql } from '@apollo/client';
import { IComboBoxOption } from '@fluentui/react';
import { ITeamMember } from './TeamMember';

export const GET_TEAMS = gql`
  query getTeams(
    $filter: TeamFilterInput
    $take: Int
    $skip: Int
    $orderBy: TeamOrderByInput
  ) {
    findManyTeams(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id

      name
      description
      active

      team_members {
        id
        employee {
          first_name
          last_name
        }
      }

      prisma_total
    }
  }
`;

export const GET_TEAM_BY_ID = gql`
  query getTeamById($where: TeamWhereInput) {
    findOneTeam(where: $where) {
      id

      name
      description
      active

      team_members {
        id
        employee {
          first_name
          last_name
        }
      }
    }
  }
`;

export const ADD_TEAM = gql`
  mutation addTeam($data: TeamCreateInput!) {
    createTeam(data: $data) {
      id

      name
      description
      active

      team_members {
        id
        employee {
          first_name
          last_name
        }
      }
    }
  }
`;

export const UPDATE_TEAM = gql`
  mutation updateTeam($id: Int!, $data: TeamUpdateInput) {
    updateTeam(id: $id, data: $data) {
      id

      name
      description
      active

      team_members {
        id
        employee {
          first_name
          last_name
        }
      }
    }
  }
`;

export const DELETE_TEAM = gql`
  mutation deleteTeam($where: TeamWhereInput) {
    deleteTeam(where: $where) {
      id
    }
  }
`;

export interface ITeam {
  id: number;
  prisma_total?: number;

  active: boolean;
  name: string;
  description: string;

  team_members: ITeamMember[];
}

export const convertTeamsToComboBoxOptions = (teams: ITeam[]) => {
  const result: IComboBoxOption[] = teams.map((team: ITeam): any => {
    if (team.id && team.id) {
      return {
        key: team.id,
        text: `${team.name}`,
      };
    }
  });
  return result.length > 0 ? result : [];
};
