/* eslint-disable no-restricted-globals */
import { useCallback, useEffect, useState } from 'react';
import {
  Checkbox,
  DefaultButton,
  IComboBoxOption,
  Panel,
  PanelType,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import _ from 'lodash';
import { modalContentStyles } from '../../utils';
import { customPanelStyles } from '../../theme';
import SuggestedViewFilter from './SuggestedViewFilter';
import ComboboxWithFilter from './ComboboxWithFilter';
import SaveButtonWithPermissions from './SaveButtonWithPermissions';
import FilterPanelProjects from './FilterPanelProjects';
import FilterPanelSuppliers from './FilterPanelSuppliers';
import MultiSelectSuggestedView from './suggestedValueList';

export type FilterState = {
  id: string;
  schema: FilterSectionState[];
};

export type FilterSectionState = {
  id: string;
  fields: FilterFieldState[];
};

export type FilterFieldState = {
  id: string;
  name: string;
  value: string;
  parsedValue: string;
};

export type Filter = {
  id: string;
  permission: string;
  schema: FilterSection[];
};

export type FilterSection = {
  id: string;
  label: string;
  width?: number;
  fields: FilterField[];
};

export type FilterField = {
  id: string;
  name: string;
  fieldName: string;
  data: string;
  type: string;
  width?: number;
  callback: any;
  setFilter?: any;
  options?: any[];
  prefix?: string;
  initialValue?: string;
};

export const initFilterPanelState = (filter: Filter) => {
  const newSectionState: FilterSectionState[] = [];

  for (let i = 0; i < filter.schema.length; i++) {
    const newFields: FilterFieldState[] = [];

    for (let t = 0; t < filter.schema[i].fields.length; t++) {
      newFields.push({
        id: filter.schema[i].fields[t].id,
        name: filter.schema[i].fields[t].fieldName,
        value: '',
        parsedValue: '',
      });
    }

    newSectionState.push({
      id: filter.schema[i].id,
      fields: newFields,
    });
  }

  const newFilterState: FilterState = {
    id: filter.id,
    schema: newSectionState,
  };

  return newFilterState;
};

const initLocalStorageState = (
  localStorageFilter: FilterState,
  filter: Filter,
) => {
  const newSectionState: FilterSectionState[] = [];

  for (let i = 0; i < filter.schema.length; i++) {
    if (
      localStorageFilter.schema[i] &&
      filter.schema[i].id === localStorageFilter.schema[i].id
    ) {
      const newFields: FilterFieldState[] = [];

      for (let t = 0; t < filter.schema[i].fields.length; t++) {
        if (
          localStorageFilter.schema[i].fields[t] &&
          filter.schema[i].fields[t].id ===
            localStorageFilter.schema[i].fields[t].id
        ) {
          newFields.push({
            id: filter.schema[i].fields[t].id,
            name: filter.schema[i].fields[t].fieldName,
            value: localStorageFilter.schema[i].fields[t].value,
            parsedValue: localStorageFilter.schema[i].fields[t].parsedValue,
          });
        } else {
          newFields.push({
            id: filter.schema[i].fields[t].id,
            name: filter.schema[i].fields[t].fieldName,
            value: '',
            parsedValue: '',
          });
        }
      }

      newSectionState.push({
        id: filter.schema[i].id,
        fields: newFields,
      });
    } else {
      const newFields: FilterFieldState[] = [];

      for (let t = 0; t < filter.schema[i].fields.length; t++) {
        newFields.push({
          id: filter.schema[i].fields[t].id,
          name: filter.schema[i].fields[t].fieldName,
          value: '',
          parsedValue: '',
        });
      }

      newSectionState.push({
        id: filter.schema[i].id,
        fields: newFields,
      });
    }
  }

  const newFilterState: FilterState = {
    id: filter.id,
    schema: newSectionState,
  };

  return newFilterState;
};

type Props = {
  isOpen: boolean;
  dismissPanel: any;
  filter: Filter;
  setFilter: React.Dispatch<React.SetStateAction<FilterState | undefined>>;
  filterState: FilterState;
  setFilterState: React.Dispatch<React.SetStateAction<FilterState>>;
};

export const getParsedValue = (field: FilterField, value: any) => {
  const prismaFilterObj: any = {
    [field.fieldName]: field.callback(value),
  };

  return prismaFilterObj;
};

export const FilterPanel = ({
  isOpen,
  dismissPanel,
  filter,
  setFilter,
  filterState,
  setFilterState,
}: Props) => {
  const onChangeFieldValue = (
    section: FilterSection,
    field: FilterField,
    value: any,
  ) => {
    setFilterState(prevState => {
      const newState = _.cloneDeep(prevState);
      const sectionIndex = newState.schema.findIndex(
        item => section.id === item.id,
      );

      if (sectionIndex === -1) {
        const fieldList: FilterFieldState[] = [];
        fieldList.push({
          id: field.id,
          name: field.fieldName,
          value,
          parsedValue: getParsedValue(field, value),
        });

        newState.schema.push({
          id: section.id,
          fields: fieldList,
        });
      }

      if (sectionIndex > -1) {
        const fieldIndex = newState.schema[sectionIndex].fields.findIndex(
          item => item.id === field.id,
        );

        if (fieldIndex === -1) {
          const fieldList: FilterFieldState[] =
            newState.schema[sectionIndex].fields;
          fieldList.push({
            id: field.id,
            name: field.fieldName,
            value,
            parsedValue: getParsedValue(field, value),
          });

          newState.schema[sectionIndex] = {
            ...newState.schema[sectionIndex],
            fields: fieldList,
          };
        }

        if (fieldIndex > -1) {
          const fieldList: FilterFieldState[] =
            newState.schema[sectionIndex].fields;
          fieldList[fieldIndex] = {
            ...newState.schema[sectionIndex].fields[fieldIndex],
            value,
            parsedValue: getParsedValue(field, value),
          };

          newState.schema[sectionIndex] = {
            ...newState.schema[sectionIndex],
            fields: fieldList,
          };
        }
      }

      return newState;
    });
  };

  const getFieldValue = useCallback(
    (section_id: string, field: FilterField) => {
      const sectionIndex = filterState.schema.findIndex(
        item => section_id === item.id,
      );

      const section = filterState.schema[sectionIndex];

      const fieldIndex = section.fields.findIndex(item => item.id === field.id);
      if (section && section.fields) {
        if (section.fields && section.fields[fieldIndex]) {
          return section.fields[fieldIndex].value.toString();
        }
      }

      return '';
    },
    [filterState],
  );

  const Fields = (fields: FilterField[], section: FilterSection) =>
    fields.map(field => {
      if (
        field.type === 'text' ||
        field.type === 'number' ||
        field.type === 'date'
      ) {
        return (
          <StackItem
            style={{
              marginBottom: 10,
              width: field.width ? `${field.width}%` : '100%',
            }}
          >
            <TextField
              name={field.id}
              type={field.type}
              label={field.name}
              value={getFieldValue(section.id, field)}
              onChange={(e: any) =>
                onChangeFieldValue(section, field, e.target.value)
              }
            />
          </StackItem>
        );
      }

      if (field.type === 'checkbox') {
        return (
          <StackItem
            style={{
              marginBottom: 10,
              width: field.width ? `${field.width}%` : '100%',
            }}
          >
            <Checkbox
              name={field.id}
              label={field.name}
              defaultChecked={getFieldValue(section.id, field) === 'true'}
              onChange={(e: any) => {
                onChangeFieldValue(
                  section,
                  field,
                  e.target.defaultChecked ? 'false' : 'true',
                );
              }}
            />
          </StackItem>
        );
      }

      if (field.type === 'suggested') {
        return (
          <StackItem
            style={{
              marginBottom: 10,
              width: field.width ? `${field.width}%` : '100%',
            }}
          >
            <SuggestedViewFilter
              onChange={onChangeFieldValue}
              section={section}
              field={field}
              prefix={field.prefix ? field.prefix : ''}
              code={getFieldValue(section.id, field)}
              label={field.name}
            />
          </StackItem>
        );
      }

      if (field.type === 'suggestedmulti') {
        return (
          <StackItem
            style={{
              marginBottom: 10,
              width: field.width ? `${field.width}%` : '100%',
            }}
          >
            <MultiSelectSuggestedView
              onChange={onChangeFieldValue}
              section={section}
              field={field}
              prefix={field.prefix ? field.prefix : ''}
              code={getFieldValue(section.id, field)}
              label={field.name}
            />
          </StackItem>
        );
      }

      if (field.type === 'list') {
        if (field.options && !field.options.some(e => e.key === 'ALLES')) {
          field.options.unshift({
            key: 'ALLES',
            text: 'Alles',
          });
        }

        return (
          <StackItem
            style={{
              marginBottom: 10,
              width: field.width ? `${field.width}%` : '100%',
            }}
          >
            <ComboboxWithFilter
              label={field.name}
              value={
                !isNaN(getFieldValue(section.id, field) as any)
                  ? parseInt(getFieldValue(section.id, field), 10)
                  : getFieldValue(section.id, field)
              }
              multiline={false}
              options={field.options ? field.options : []}
              callBack={(newValue: IComboBoxOption[]) => {
                if (newValue && newValue.length > 0) {
                  onChangeFieldValue(section, field, newValue[0].key);
                }
              }}
            />
          </StackItem>
        );
      }

      if (field.type === 'debounceList') {
        if (field.options && !field.options.some(e => e.key === 'ALLES')) {
          field.options.unshift({
            key: 'ALLES',
            text: 'Alles',
          });
        }

        return (
          <StackItem
            style={{
              marginBottom: 10,
              width: field.width ? `${field.width}%` : '100%',
            }}
          >
            <ComboboxWithFilter
              label={field.name}
              options={field.options ? field.options : []}
              value={
                !isNaN(getFieldValue(section.id, field) as any)
                  ? parseInt(getFieldValue(section.id, field), 10)
                  : getFieldValue(section.id, field)
              }
              multiline={false}
              callBack={(newValue: IComboBoxOption[]) => {
                if (newValue && newValue.length > 0) {
                  onChangeFieldValue(section, field, newValue[0].key);
                }
              }}
              setFilter={filterValue => {
                field.setFilter(filterValue);
              }}
              allowFreeForm
            />
          </StackItem>
        );
      }

      if (field.type === 'projectList') {
        return (
          <StackItem
            style={{
              marginBottom: 10,
              width: field.width ? `${field.width}%` : '100%',
            }}
          >
            <FilterPanelProjects
              label={field.name}
              field={field}
              section={section}
              onChangeFieldValue={onChangeFieldValue}
              getFieldValue={getFieldValue}
            />
          </StackItem>
        );
      }

      if (field.type === 'supplierList') {
        return (
          <StackItem
            style={{
              marginBottom: 10,
              width: field.width ? `${field.width}%` : '100%',
            }}
          >
            <FilterPanelSuppliers
              label={field.name}
              field={field}
              section={section}
              onChangeFieldValue={onChangeFieldValue}
              getFieldValue={getFieldValue}
            />
          </StackItem>
        );
      }
    });

  const Sections = filter.schema.map((section: FilterSection) => (
    <StackItem
      style={{
        width: section.width ? `${section.width}%` : '100%',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'start',
        marginBottom: 25,
      }}
    >
      <h3 style={{ marginTop: 5, marginBottom: 10, width: '100%' }}>
        {section.label}
      </h3>
      {Fields(section.fields, section)}
    </StackItem>
  ));

  const saveFilters = useCallback(() => {
    localStorage.setItem(filter.id, JSON.stringify(filterState));
    setFilter(filterState);
    setTimeout(() => {
      dismissPanel();
    }, 250);
  }, [filterState]);

  const clearFilters = useCallback(() => {
    setFilterState(initFilterPanelState(filter));
  }, [filterState]);

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText='Geavanceerd filteren'
      type={PanelType.custom}
      customWidth='900px'
      styles={customPanelStyles}
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              alignItems: 'start',
            }}
          >
            {filterState && Sections}
          </Stack>
          <Stack
            style={{ flexDirection: 'row', marginTop: 10, marginBottom: 5 }}
          >
            <SaveButtonWithPermissions
              save={saveFilters}
              permission={filter.permission}
              text='Toepassen'
            />
            <DefaultButton onClick={clearFilters} style={{ marginRight: 10 }}>
              Clear filter
            </DefaultButton>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};
