import { useState, useEffect, useCallback } from 'react';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  Stack,
} from '@fluentui/react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useBoolean } from '@fluentui/react-hooks';
import _ from 'lodash';
import { toast } from 'react-toastify';
import {
  ADD_CAR,
  GET_CAR_BY_ID,
  UPDATE_CAR,
  DELETE_MANY_CARS,
  ICar,
  GET_CARS,
} from '../../../utils/Car';
import { commandBarTheme, commandBarThemeCounter } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  GET_EMPLOYEES,
  GET_SALES_REPS,
} from '../../../utils';
import { useAppDispatch } from '../../../redux/hooks';
import { dismissNotification } from '../../../redux/notification/notificationSlice';
import { SeverityLevel, throwError } from '../../../redux/error/errorSlice';
import { toastError, toastSuccess } from '../../../utils/toast';
import CarDetail from './CarDetail';
import CarsDetailsList from './CarsDetailsList';

interface Props {
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
  cars: ICar[];
  // filter,
  // filterChange?: (filter: string) => void,
  setSorting: (isSortedAsc: boolean, sortedField: string) => void;
  isSortedAsc?: boolean;
  sortedField?: string;
  showLoadMore?: boolean;
  refetch?: any;
}

const CarsOverview = ({
  cars,
  // filter,
  loading,
  // filterChange = (filter: string) => {},
  loadMore,
  setSorting = (isSortedAsc: boolean, sortedField: string) => {},
  isSortedAsc = false,
  sortedField = '',
  showLoadMore,
  lazyLoading,
  loadMoreCallback = () => ({}),
  refetch,
}: Props) => {
  const dispatch = useAppDispatch();
  const [car, setCar] = useState<ICar | undefined>(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [deleteDialogOpen, { toggle: toggleDelete }] = useBoolean(false);
  const [employees, setEmployees] = useState([]);

  const [addCar] = useMutation(ADD_CAR, {
    // refetchQueries: [{ query: GET_CARS }],
    // ADD ITEM TO LIST IN CACHE, BUG -> MERGE DOES NOT REPLACE THE INCOMMING 'UPDATED' LIST WITH existing list. (apollo cache in app.tsx);
    /* fetchPolicy: 'no-cache',
    update(cache, { data }) {
      try {
        const createdCar = data.createCar;
        const existingCars = cache.readQuery<{
          findManyCars: ICar[];
        }>({
          query: GET_CARS,
          variables: listFilters,
        });

        const findManyCars = existingCars
          ? _.cloneDeep(existingCars?.findManyCars)
          : null;

        console.log(findManyCars);
        console.log(createdCar);

        if (findManyCars && createdCar) {
          console.log('befor new car', findManyCars);
          findManyCars.unshift(createdCar);
          console.log('after new car', findManyCars);
          cache.writeQuery({
            query: GET_CARS,
            variables: listFilters,
            data: {
              findManyCars,
            },
          });
        }
      } catch (error) {
        console.log('error', error);
      }
    }, */
  });

  const [modifyCar] = useMutation(UPDATE_CAR, {
    // no need for writeQuery since apollo updates cache automatically when executing a update mutation
  });

  const [deleteManyCars] = useMutation(DELETE_MANY_CARS, {
    // refetchQueries: [{ query: GET_CARS }],
    // REMOVE ITEM FROM LIST IN CACHE, BUG -> MERGE DOES NOT REPLACE THE INCOMMING 'UPDATED' LIST WITH existing list. (apollo cache in app.tsx);
    /* fetchPolicy: 'no-cache',
    update(cache) {
      const existingCars = cache.readQuery<{
        findManyCars: ICar[];
      }>({
        query: GET_CARS,
        variables: listFilters,
      });

      const findManyCars = existingCars
        ? _.cloneDeep(existingCars?.findManyCars)
        : null;

      // delete each selected car in findMany cars list and replace in cache
      if (findManyCars && selectionDetails) {
        for (let i = 0; i < selectionDetails.length; i++) {
          for (let j = 0; j < findManyCars.length; j++) {
            if (findManyCars[j].id === selectionDetails[i].id) {
              console.log('found, remove from manyCars list');
              findManyCars.splice(j, 1);
            }
          }
        }

       // console.log(findManyCars);

        cache.writeQuery({
          query: GET_CARS,
          variables: listFilters,
          data: {
            findManyCars,
          },
        });
      }
    }, */
  });

  useQuery(GET_EMPLOYEES, {
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      setEmployees(x.findManyEmployees);
    },
  });

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<
    ICar[] | undefined
  >();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();
    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const openCarDetail = (newCar?: boolean) => {
    if (!selectionDetails && newCar) {
      setCar(undefined);
    }
    setIsPanelOpen(true);
  };

  const { refetch: refetchCar } = useQuery(GET_CAR_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables:
      selectionDetails && selectionDetails.length > 0
        ? { where: { id: selectionDetails[0].id } }
        : undefined,
    skip: !selectionDetails || selectionDetails.length > 1,
    onCompleted: data => {
      setCar(data.findOneCar);
    },
  });

  const saveCar = async () => {
    try {
      if (car) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              name: car.name,
              car_type: car.car_type,
              car_comments: car.car_comments,
              chassis_number: car.chassis_number,
              fuel_type: car.fuel_type,
              license_plate: car.license_plate,
              first_use_date: car.first_use_date,
              lease_start_date: car.lease_start_date,
              lease_stop_date: car.lease_stop_date,
              purchase_date: car.purchase_date,
              tank_card_code: car.tank_card_code,
              tank_card_comments: car.tank_card_comments,
              tank_card_number: car.tank_card_number,
              tank_card_number2: car.tank_card_number2,
              sold_date: car.sold_date,
              active: car.active,
            };

            if (car.id) {
              modifyCar({
                variables: {
                  id: car.id,
                  data: allInput,
                },
                onError: error => {
                  setIsPanelOpen(false);
                  reject(error);
                },
                onCompleted: (x: any) => {
                  setIsPanelOpen(false);
                  resolve(x);
                  toastSuccess('Wagen gewijzigd');
                },
              });
            } else {
              addCar({
                variables: {
                  data: allInput,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: (x: any) => {
                  if (x && x.createCar) setCar(x.createCar);
                  resolve(x);
                  toastSuccess('Wagen toegevoegd');
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  const deleteSelection = useCallback(async () => {
    // delete cars after confirmation
    if (!selectionDetails) {
      toggleDelete();
      return;
    }

    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          deleteManyCars({
            variables: {
              where: {
                id: {
                  in: selectionDetails.map(car => car.id),
                },
              },
            },
            onError: error => {
              toggleDelete();
              reject(error);
            },
            onCompleted: async (x: any) => {
              toggleDelete();
              await refetch();
              setCar(undefined);
              resolve(x);
            },
          });
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      await res;
      toastSuccess('Log item verwijderd');
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_LOADING_MESSAGE);
    }
  }, [selectionDetails]);

  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'totaal',
      text: `${cars.length} / ${cars.length > 0 ? cars[0].prisma_total : 0}`,
      theme: commandBarThemeCounter,
    },
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => {
        openCarDetail(true);
      },
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openCarDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails || selectionDetails.length > 1,
    },
    {
      key: 'delete',
      text: 'Verwijderen',
      iconProps: { iconName: 'Trash' },
      onClick: () => toggleDelete(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
  ];

  useEffect(() => {
    if (car && isPanelOpen) {
      document.title = `3bouw | Auto - ${car.license_plate}`;
    } else {
      document.title = "3bouw | Auto's";
    }
  }, [car, isPanelOpen]);

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        maxWidth='2000px'
      />
      {isPanelOpen && (
        <CarDetail
          isOpen={isPanelOpen}
          dismissPanel={() => {
            setIsPanelOpen(false);
          }}
          car={car || {}}
          saveCar={saveCar}
          setCar={setCar}
          refetchCar={refetchCar}
          employees={employees}
        />
      )}
      {deleteDialogOpen && selectionDetails && selectionDetails.length > 0 && (
        <Dialog
          hidden={!deleteDialogOpen}
          onDismiss={toggleDelete}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Wagens verwijderen',
            closeButtonAriaLabel: 'Close',
          }}
        >
          <p>
            <strong>
              Ben je zeker dat je de volgende wagens wil verwijderen?
            </strong>
          </p>
          <p>
            <ul>
              {selectionDetails.map(car => (
                <li>{car.license_plate}</li>
              ))}
            </ul>
          </p>
          <p>Deze actie kan niet ongedaan gemaakt worden.</p>
          <DialogFooter>
            <PrimaryButton text='Verwijderen' onClick={deleteSelection} />
            <DefaultButton text='Annuleren' onClick={toggleDelete} />
          </DialogFooter>
        </Dialog>
      )}

      <CarsDetailsList
        items={cars}
        selection={selection}
        enableShimmer={!loading}
        isSortedAsc={isSortedAsc}
        sortedField={sortedField}
        setSorting={setSorting}
        loadMore={loadMore}
        lazyLoading={lazyLoading}
        loadMoreCallback={loadMoreCallback}
        loading={loading}
        onItemInvoked={() => openCarDetail(false)}
      />

      {cars && (
        <>
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton
                text='Toon meer'
                onClick={() => loadMoreCallback()}
              />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {!loading && !showLoadMore && cars.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default CarsOverview;
