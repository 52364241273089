import { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { useAuth0 } from '@auth0/auth0-react';
import {
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
} from '@fluentui/react';
import { useAppSelector } from '../../redux/hooks';
import { getPermissions } from '../../redux/permission/permissionSlice';

interface ISaveButtonWithPermissions {
  permission: string;
  disabled?: boolean;
  save?: () => void;
  loading?: boolean;
  text?: string;
  color?: string;
}

const SaveButtonWithPermissions = ({
  permission,
  disabled = false,
  save,
  loading = false,
  text,
  color,
}: ISaveButtonWithPermissions) => {
  const permissions = useAppSelector(getPermissions);

  return (
    <StackItem>
      {permissions.includes(permission) && (
        <StackItem>
          <PrimaryButton
            onClick={save}
            style={{ marginRight: 10, backgroundColor: color, borderColor: color }}
            disabled={disabled}
          >
            {text ? (
              <span>{text}</span>
            )
          : (<span>Opslaan</span>)}
            {loading && (
              <Stack>
                <Spinner size={SpinnerSize.small} />
              </Stack>
            )}
          </PrimaryButton>
        </StackItem>
      )}
    </StackItem>
  );
};

export default SaveButtonWithPermissions;
