import React, { useState, useRef, useEffect } from 'react';
import {
  DetailsList,
  getTheme,
  IDetailsListProps,
  IDragDropContext,
  mergeStyles,
  ShimmeredDetailsList,
} from '@fluentui/react';
import { useScrollPosition, Scroll } from '../hooks/useScrollPosition';

const theme = getTheme();

const dragEnterClass = mergeStyles({
  backgroundColor: theme.palette.neutralLight,
});

interface Props extends IDetailsListProps {
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  groups?: any[],
  loadMoreCallback?: () => void;
  dragAndDropEnabled?: boolean;
  dragAndDropCallBack?: (items?: any) => void; // can we use dynamic type??
  isShimmered?: boolean;
  enableShimmer?: boolean;
  ariaLabelForShimmer?: string;
  renderRowsAhead?: number;
  renderRowsBehind?: number;
}

const DetailsListDefault = ({
  columns,
  compact = true,
  dragAndDropEnabled = false,
  dragAndDropCallBack = (items?: any) => ({}),
  getKey = (item: any) => (item && item.id ? item.id : 0),
  isHeaderVisible = true,
  isShimmered = false,
  enableShimmer = false,
  ariaLabelForShimmer = 'Content is being fetched',
  //   ariaLabelForGrid = 'Item details',
  items,
  groups,
  layoutMode,
  onItemInvoked,
  selection,
  selectionMode,
  selectionPreservedOnEmptyClick = false,
  renderRowsAhead = 15,
  renderRowsBehind = 15,
  onRenderRow,
  loadMore = false,
  lazyLoading = false,
  loading = false,
  loadMoreCallback = () => ({}),
}: Props) => {
  const [draggedIndex, setDraggedIndex] = useState(-1);
  const [draggedItem, setDraggedItem] = useState();
  const [canLoadMore, setCanLoadMore] = useState(false);

  const ref = useRef(null);
  const scroll = useScrollPosition({
    wait: 500,
    element: ref,
  });

  const isRefBottomAboveViewport = (input: Scroll): boolean => {
    if (input && input.rect && input.viewport) {
      if (input.rect.bottom < input.viewport.h) return true;
    }
    return false;
  };

  useEffect(() => {
    if (loadMore && lazyLoading && !loading) {
      const shouldLoadMore = isRefBottomAboveViewport(scroll);

      if (shouldLoadMore && canLoadMore) {
        setCanLoadMore(false);
        loadMoreCallback();
      }
    }
  }, [scroll]);

  useEffect(() => {
    setCanLoadMore(true);
  }, [items]);

  const dragAndDrop = () => ({
    canDrop: (
      dropContext?: IDragDropContext,
      dragContext?: IDragDropContext,
    ) => true,
    canDrag: (item?: any) => true,
    onDragEnter: (item?: any, event?: DragEvent) => dragEnterClass,
    onDragLeave: (item?: any, event?: DragEvent) => {

    },
    onDrop: (item?: any, event?: DragEvent) => {
      if (draggedItem && selection) {
        const draggedItems = selection.isIndexSelected(draggedIndex)
          ? selection.getSelection()
          : [draggedItem!];

        const insertIndex = items.indexOf(item);
        const newItems = items.filter(
          (itm: any) => draggedItems.indexOf(itm) === -1,
        );

        newItems.splice(insertIndex, 0, ...draggedItems);

        selection.toggleIndexSelected(draggedIndex);
        dragAndDropCallBack(newItems);
      }
    },
    onDragStart: (
      item?: any,
      itemIndex?: number,
      selectedItems?: any[],
      event?: MouseEvent,
    ) => {
      setDraggedItem(item);
      setDraggedIndex(itemIndex!);
    },
    onDragEnd: (item?: any, event?: DragEvent) => {
      setDraggedItem(undefined);
      setDraggedIndex(-1);
    },
  });

  return (
    <div ref={ref}>
      {isShimmered ? (
        <ShimmeredDetailsList
          items={items}
          compact={compact}
          columns={columns}
          getKey={getKey}
          enableShimmer={enableShimmer}
          ariaLabelForShimmer={ariaLabelForShimmer}
          onItemInvoked={onItemInvoked}
          selection={selection}
          selectionMode={selectionMode}
          selectionPreservedOnEmptyClick={selectionPreservedOnEmptyClick}
          setKey='none'
          layoutMode={layoutMode}
          isHeaderVisible={isHeaderVisible}
          dragDropEvents={dragAndDropEnabled ? dragAndDrop() : undefined}
          listProps={{
        renderedWindowsAhead: renderRowsAhead,
        renderedWindowsBehind: renderRowsBehind,
      }}
          onRenderRow={onRenderRow}
        />
  ) : (
    <DetailsList
      items={items}
      compact={compact}
      columns={columns}
      groups={groups}
      getKey={getKey}
      onItemInvoked={onItemInvoked}
      selection={selection}
      selectionMode={selectionMode}
      selectionPreservedOnEmptyClick={selectionPreservedOnEmptyClick}
      setKey='none'
      layoutMode={layoutMode}
      isHeaderVisible={isHeaderVisible}
      dragDropEvents={dragAndDropEnabled ? dragAndDrop() : undefined}
      listProps={{
        renderedWindowsAhead: renderRowsAhead,
        renderedWindowsBehind: renderRowsBehind,
      }}
      onRenderRow={onRenderRow}
    />
    )}
      {!loading && items && items.length === 0 && (
      <div style={{ textAlign: 'center' }}>
        Geen resultaten.
      </div>
    )}
    </div>
  );
};

export default DetailsListDefault;
