/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FormEvent, useEffect, useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  Stack,
  StackItem,
  TextField,
  IComboBoxOption,
  Checkbox,
  Label,
  DetailsList,
  SelectionMode,
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  ComboBox,
  IComboBox,
  Spinner,
  SpinnerSize,
} from '@fluentui/react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment';
import { useAuth0 } from '@auth0/auth0-react';
import {
  convertEmployeesToComboBoxOptions,
  IConstructionSite,
  IEmployee,
  modalContentStyles,
  titleComboboxOptions,
} from '../../../utils';
import SuggestedView from '../../../components/parts/SuggestedView';
import DateView from '../../../components/parts/DateView';
import { ComboboxWithFilter } from '../../../components/parts';
import { Accordion, AccordionItem } from '../../../components/parts/Accordion';
import { ICustomerInvoice } from '../../../utils/CustomerInvoice';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import useDebounce from '../../../components/hooks/useDebounce';
import { toastError, toastSuccess } from '../../../utils/toast';
import ZipCodeFetcher from '../../../components/DynamicZip';
import { customPanelStyles } from '../../../theme';

const CustomerDetail = ({
  isOpen,
  dismissPanel,
  saveCustomer,
  customer,
  setCustomer,
  employees,
}: any) => {
  const {
 isAuthenticated, getAccessTokenSilently, user, isLoading,
} =
    useAuth0();
  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setCustomer((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  const [isLoadingUpsertLead, setIsLoadingUpsertLead] = useState(false);

  const hasDirectieRole = user?.['3bouw/roles']?.includes('Directie');

  const [selectedValueItem, setSelectedValueItem] = useState<
    number | string | undefined
  >();

  const handleButtonClick = async (_lid: string) => {
    try {
      setIsLoadingUpsertLead(true);
      const token = await getAccessTokenSilently();

      const response = await fetch(
        'https://3bouw-wings-sync-function.azurewebsites.net/api/PublicUpsertLead',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, // Add the Authorization header with your token
          },
          body: JSON.stringify({
            id: _lid,
          }),
        },
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log(data);
      setCustomer((prevState: any) => ({
        ...prevState,
        wings_account_code: data.wings_code,
        wings_code: data.wings_code,
      }));
      toastSuccess('Klant succesvol gesynchroniseerd');
    } catch (err: any) {
      toastError(err?.message ? err.message : 'Er is iets misgelopen');
    } finally {
      setIsLoadingUpsertLead(false);
    }
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        customer && customer.id ? 'Klant wijzigen' : 'Klant toevoegen'
      }
      type={PanelType.custom}
      customWidth='900px'
      styles={customPanelStyles}
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {customer && customer.id && <Label>ID: {customer.id}</Label>}

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <StackItem style={{ width: '48%' }}>
              <h3 style={{ marginTop: 0, marginBottom: 10 }}>Contact</h3>
              <Stack>
                <Stack style={{ marginBottom: 10 }}>
                  <ComboboxWithFilter
                    label='Aanspreking'
                    options={titleComboboxOptions()}
                    value={customer && customer.title1 ? customer.title1 : ''}
                    multiline={false}
                    callBack={(newValue: IComboBoxOption[]) => {
                      if (newValue && newValue.length > 0) {
                        const result = { ...customer };
                        result.isDirty = true;
                        result.title1 = newValue[0].key as number;
                        setCustomer(result);
                      }
                    }}
                  />
                </Stack>

                <Stack
                  style={{
                    marginBottom: 10,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  {customer && customer.business ? (
                    <StackItem style={{ width: '49.5%' }}>
                      <TextField
                        name='last_name1'
                        label='Bedrijfsnaam'
                        value={
                          customer && customer.last_name1
                            ? customer.last_name1
                            : ''
                        }
                        onChange={onChangeTextFieldValue}
                        required
                        errorMessage={
                          customer && !customer.last_name1
                            ? 'Dit veld is verplicht'
                            : ''
                        }
                      />
                    </StackItem>
                  ) : (
                    <>
                      <StackItem style={{ width: '49.5%' }}>
                        <TextField
                          name='first_name1'
                          label='Voornaam'
                          value={
                            customer && customer.first_name1
                              ? customer.first_name1
                              : ''
                          }
                          required
                          onChange={onChangeTextFieldValue}
                        />
                      </StackItem>
                      <StackItem style={{ width: '49.5%' }}>
                        <TextField
                          name='last_name1'
                          label='Achternaam'
                          value={
                            customer && customer.last_name1
                              ? customer.last_name1
                              : ''
                          }
                          onChange={onChangeTextFieldValue}
                          required
                          errorMessage={
                            customer && !customer.last_name1
                              ? 'Dit veld is verplicht'
                              : ''
                          }
                        />
                      </StackItem>
                    </>
                  )}
                </Stack>
                <Stack style={{ marginBottom: 10 }}>
                  {/* {lead.email1opt_out ? 'hoi' : 'no'} */}
                  <Checkbox
                    name='business'
                    label='Bedrijf'
                    checked={customer ? customer.business : false}
                    onChange={(e, checked) => {
                      setCustomer((prevState: any) => ({
                        ...prevState,
                        business: checked,
                      }));
                    }}
                  />
                </Stack>
                <Stack style={{ marginBottom: 5 }}>
                  <TextField
                    name='address1'
                    label='Adres'
                    value={
                      customer && customer.address1 ? customer.address1 : ''
                    }
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>

                <Stack
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 10,
                  }}
                >
                  <StackItem style={{ width: '25%' }}>
                    <ZipCodeFetcher
                      object={customer}
                      setObject={setCustomer}
                      zipFieldName='zip_code1'
                      cityFieldName='city1'
                    />
                  </StackItem>
                  <StackItem style={{ width: '74%' }}>
                    <TextField
                      name='city1'
                      value={customer && customer.city1 ? customer.city1 : ''}
                      onChange={onChangeTextFieldValue}
                    />
                  </StackItem>
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    name='country'
                    label='Land'
                    value={customer && customer.country ? customer.country : ''}
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    name='email1'
                    label='E-mailadres'
                    value={customer && customer.email1 ? customer.email1 : ''}
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <Checkbox
                    name='email1opt_out'
                    label='Geen mailing'
                    checked={customer && customer.email1opt_out}
                    onChange={() => {
                      setCustomer((prevState: any) => ({
                        ...prevState,
                        email1opt_out: customer.email1opt_out
                          ? !customer.email1opt_out
                          : true,
                      }));
                    }}
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <Label>GSM</Label>
                  <PhoneInput
                    country='be'
                    preferredCountries={['be']}
                    value={customer && customer.mobile1_v2}
                    onChange={phone =>
                      setCustomer((prevState: any) => ({
                        ...prevState,
                        mobile1_v2: phone,
                      }))
                    }
                    inputStyle={{
                      width: '100%',
                      border: '1px solid rgb(96, 94, 92)',
                      borderRadius: '2px',
                    }}
                    buttonStyle={{
                      border: '1px solid rgb(96, 94, 92)',
                      borderRadius: '2px',
                    }}
                  />
                  {customer && !customer.mobile1_v2 && customer.mobile1 && (
                    <div>{customer.mobile1}</div>
                  )}
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <Label>Telefoon</Label>
                  <PhoneInput
                    country='be'
                    preferredCountries={['be']}
                    value={customer && customer.phone1_v2}
                    onChange={phone =>
                      setCustomer((prevState: any) => ({
                        ...prevState,
                        phone1_v2: phone,
                      }))
                    }
                    inputStyle={{
                      width: '100%',
                      border: '1px solid rgb(96, 94, 92)',
                      borderRadius: '2px',
                    }}
                    buttonStyle={{
                      border: '1px solid rgb(96, 94, 92)',
                      borderRadius: '2px',
                    }}
                  />
                  {customer && !customer.phone1_v2 && customer.phone1 && (
                    <div>{customer.phone1}</div>
                  )}
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    name='vat'
                    type='text'
                    label='BTW-nummer'
                    value={customer && customer.vat ? customer.vat : ''}
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>
              </Stack>
            </StackItem>

            <StackItem style={{ width: '48%' }}>
              <h3 style={{ marginTop: 0, marginBottom: 10 }}>
                {customer && !customer.business ? 'Partner' : 'Wings & Archief'}
              </h3>
              {(!customer || !customer.business) && (
                <Stack>
                  <Stack style={{ marginBottom: 10 }}>
                    <ComboboxWithFilter
                      label='Aanspreking'
                      options={titleComboboxOptions()}
                      value={customer && customer.title2 ? customer.title2 : ''}
                      multiline={false}
                      callBack={(newValue: IComboBoxOption[]) => {
                        if (newValue && newValue.length > 0) {
                          const result = { ...customer };
                          result.isDirty = true;
                          result.title2 = newValue[0].key as number;
                          setCustomer(result);
                        }
                      }}
                    />
                  </Stack>

                  <Stack
                    style={{
                      marginBottom: 10,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <StackItem style={{ width: '49.5%' }}>
                      <TextField
                        name='first_name2'
                        label='Voornaam'
                        value={
                          customer && customer.first_name2
                            ? customer.first_name2
                            : ''
                        }
                        onChange={onChangeTextFieldValue}
                      />
                    </StackItem>
                    <StackItem style={{ width: '49.5%' }}>
                      <TextField
                        name='last_name2'
                        label='Achternaam'
                        value={
                          customer && customer.last_name2
                            ? customer.last_name2
                            : ''
                        }
                        onChange={onChangeTextFieldValue}
                      />
                    </StackItem>
                  </Stack>

                  <Stack style={{ marginBottom: 10 }}>
                    <TextField
                      name='email2'
                      label='E-mailadres'
                      value={customer && customer.email2 ? customer.email2 : ''}
                      onChange={onChangeTextFieldValue}
                    />
                  </Stack>

                  <Stack style={{ marginBottom: 10 }}>
                    <Checkbox
                      name='email2opt_out'
                      label='Geen mailing'
                      checked={customer && customer.email2opt_out}
                      onChange={() => {
                        setCustomer((prevState: any) => ({
                          ...prevState,
                          email2opt_out: customer.email2opt_out
                            ? !customer.email2opt_out
                            : true,
                        }));
                      }}
                    />
                  </Stack>

                  <Stack style={{ marginBottom: 10 }}>
                    <Label>GSM</Label>
                    <PhoneInput
                      country='be'
                      preferredCountries={['be']}
                      value={customer && customer.mobile2_v2}
                      onChange={phone =>
                        setCustomer((prevState: any) => ({
                          ...prevState,
                          mobile2_v2: phone,
                        }))
                      }
                      inputStyle={{
                        width: '100%',
                        border: '1px solid rgb(96, 94, 92)',
                        borderRadius: '2px',
                      }}
                      buttonStyle={{
                        border: '1px solid rgb(96, 94, 92)',
                        borderRadius: '2px',
                      }}
                    />
                    {customer && !customer.mobile2_v2 && customer.mobile2 && (
                      <div>{customer.mobile2}</div>
                    )}
                  </Stack>

                  <Stack style={{ marginBottom: 10 }}>
                    <Label>Telefoon</Label>
                    <PhoneInput
                      country='be'
                      preferredCountries={['be']}
                      value={customer && customer.phone2_v2}
                      onChange={phone =>
                        setCustomer((prevState: any) => ({
                          ...prevState,
                          phone2_v2: phone,
                        }))
                      }
                      inputStyle={{
                        width: '100%',
                        border: '1px solid rgb(96, 94, 92)',
                        borderRadius: '2px',
                      }}
                      buttonStyle={{
                        border: '1px solid rgb(96, 94, 92)',
                        borderRadius: '2px',
                      }}
                    />
                    {customer && !customer.phone2_v2 && customer.phone2 && (
                      <div>{customer.phone2}</div>
                    )}
                  </Stack>
                </Stack>
              )}
              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='wings_account_code'
                  label='Analytische code'
                  value={
                    customer && customer.wings_account_code
                      ? customer.wings_account_code
                      : ''
                  }
                  disabled
                />
                {customer &&
                  customer?.id &&
                  !customer.wings_account_code &&
                  hasDirectieRole && (
                    <p
                      style={{
                        cursor: 'pointer',
                        color: 'rgb(113, 191, 68)',
                        textDecoration: 'underline',
                        marginTop: 0,
                      }}
                      onClick={() => handleButtonClick(customer.id)}
                    >
                      code aanmaken{' '}
                      {isLoadingUpsertLead && (
                        <Spinner size={SpinnerSize.small} />
                      )}
                    </p>
                  )}
              </Stack>
              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='archive_no'
                  label='Archiefnummer'
                  value={
                    customer && customer.archive_no ? customer.archive_no : ''
                  }
                  onChange={onChangeTextFieldValue}
                />
              </Stack>
              <Stack style={{ marginBottom: 10 }}>
                <Checkbox
                  name='only_invoice'
                  label='Enkel factuur'
                  checked={customer && customer.only_invoice}
                  onChange={(e, checked) => {
                    setCustomer((prevState: any) => ({
                      ...prevState,
                      only_invoice: !!checked,
                    }));
                  }}
                />
              </Stack>
              <Stack>
                <h3 style={{ marginTop: 5, marginBottom: 10 }}>
                  Algemene opmerkingen
                </h3>
                <Stack>
                  <Stack style={{ marginBottom: 10 }}>
                    <TextField
                      name='comments'
                      multiline
                      value={
                        customer && customer.comments ? customer.comments : ''
                      }
                      onChange={onChangeTextFieldValue}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </StackItem>
          </Stack>

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 30,
            }}
          >
            <StackItem style={{ width: '48%' }}>
              <h3 style={{ marginTop: 0, marginBottom: 10 }}>Status</h3>
              {customer && customer.id && (
                <Stack>
                  <Stack style={{ marginBottom: 10 }}>
                    <Label>Datum ingave</Label>
                    <p>
                      {customer &&
                        moment(new Date(customer.date_created)).format(
                          'DD/MM/YYYY',
                        )}
                    </p>
                  </Stack>

                  <Stack style={{ marginBottom: 10 }}>
                    <Label>Ingegeven door</Label>
                    <p>
                      {customer &&
                        customer.created_by &&
                        `${customer.created_by.first_name} ${customer.created_by.last_name}`}
                    </p>
                  </Stack>

                  <Stack style={{ marginBottom: 10 }}>
                    <Label>Datum laatste update</Label>
                    <p>
                      {customer &&
                        moment(new Date(customer.date_last_updated)).format(
                          'DD/MM/YYYY',
                        )}
                    </p>
                  </Stack>

                  <Stack style={{ marginBottom: 10 }}>
                    <Label>Laatste update door</Label>
                    <p>
                      {customer &&
                        customer.last_updated_by &&
                        `${customer.last_updated_by.first_name} ${customer.last_updated_by.last_name}`}
                    </p>
                  </Stack>
                </Stack>
              )}
            </StackItem>

            <StackItem style={{ width: '48%' }}>
              <h3 style={{ marginTop: 0, marginBottom: 10 }}>Bouwgrond info</h3>
              <Stack>
                <Stack style={{ marginBottom: 10, marginTop: 10 }}>
                  <Checkbox
                    name='construction_site_available'
                    label='Bouwgrond beschikbaar?'
                    checked={
                      customer && customer.construction_site_available === true
                    }
                    onChange={() => {
                      setCustomer((prevState: any) => ({
                        ...prevState,
                        construction_site_available:
                          customer.construction_site_available
                            ? !customer.construction_site_available
                            : true,
                      }));
                    }}
                  />
                </Stack>

                {customer && customer.construction_site_available && (
                  <>
                    <Stack style={{ marginBottom: 5 }}>
                      <TextField
                        name='construction_site_address'
                        label='Adres (werf)'
                        placeholder='Straat + nummer'
                        value={
                          customer && customer.construction_site_address
                            ? customer.construction_site_address
                            : ''
                        }
                        onChange={onChangeTextFieldValue}
                      />
                    </Stack>
                    <Stack
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 10,
                      }}
                    >
                      <StackItem style={{ width: '25%' }}>
                        <ZipCodeFetcher
                          object={customer}
                          setObject={setCustomer}
                          zipFieldName='construction_site_zip_code'
                          cityFieldName='construction_site_city'
                        />
                      </StackItem>
                      <StackItem style={{ width: '74%' }}>
                        <TextField
                          name='construction_site_city'
                          placeholder='Gemeente'
                          value={
                            customer && customer.construction_site_city
                              ? customer.construction_site_city
                              : ''
                          }
                          onChange={onChangeTextFieldValue}
                        />
                      </StackItem>
                    </Stack>
                  </>
                )}

                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    name='construction_site_wanted_location'
                    label='Zoekt bouwgrond te'
                    value={
                      customer && customer.construction_site_wanted_location
                        ? customer.construction_site_wanted_location
                        : ''
                    }
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    name='construction_site_comments'
                    label='Extra info (werf)'
                    multiline
                    value={
                      customer && customer.construction_site_comments
                        ? customer.construction_site_comments
                        : ''
                    }
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>
              </Stack>
            </StackItem>
          </Stack>

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 30,
            }}
          >
            <StackItem style={{ width: '48%' }}>
              <h3 style={{ marginTop: 0, marginBottom: 10 }}>
                Projectinformatie
              </h3>
              <Stack>
                <Stack
                  style={{
                    marginBottom: 10,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <StackItem style={{ width: '49.5%' }}>
                    <TextField
                      name='budget_estimate_low'
                      label='Budget (min)'
                      value={
                        customer && customer.budget_estimate_low
                          ? customer.budget_estimate_low
                          : ''
                      }
                      onChange={onChangeTextFieldValue}
                    />
                  </StackItem>
                  <StackItem style={{ width: '49.5%' }}>
                    <TextField
                      name='budget_estimate_high'
                      label='Budget (max)'
                      value={
                        customer && customer.budget_estimate_high
                          ? customer.budget_estimate_high
                          : ''
                      }
                      onChange={onChangeTextFieldValue}
                    />
                  </StackItem>
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <SuggestedView
                    item={customer}
                    setItem={setCustomer}
                    prefix='customer_degreeOfCompletion_'
                    code={
                      customer &&
                      customer.degree_of_completion &&
                      customer.degree_of_completion.substr(
                        customer.degree_of_completion.length - 4,
                      )
                    }
                    label='Graad van afwerking'
                    field='degree_of_completion'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <SuggestedView
                    item={customer}
                    setItem={setCustomer}
                    prefix='customer_typeOfHome_'
                    code={
                      customer &&
                      customer.type_of_home &&
                      customer.type_of_home.substr(
                        customer.type_of_home.length - 4,
                      )
                    }
                    label='Type woning'
                    field='type_of_home'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    name='year_to_start_construction'
                    type='number'
                    label='Gepland bouwjaar'
                    value={
                      customer && customer.year_to_start_construction
                        ? customer.year_to_start_construction
                        : ''
                    }
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>
              </Stack>
            </StackItem>

            <StackItem style={{ width: '48%' }}>
              <h3 style={{ marginTop: 0, marginBottom: 10 }}>Marketing</h3>
              <Stack>
                <Stack
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <ComboboxWithFilter
                    label='Verkoper'
                    options={convertEmployeesToComboBoxOptions(
                      employees.filter((x: IEmployee) => x.sales_rep === true),
                    )}
                    value={
                      customer && customer.sales_rep_employee
                        ? customer.sales_rep_employee.id
                        : ''
                    }
                    multiline={false}
                    callBack={(newValue: IComboBoxOption[]) => {
                      if (newValue && newValue.length > 0) {
                        const result = { ...customer };
                        if (result.sales_rep_employee) {
                          result.sales_rep_employee.id = newValue[0]
                            .key as number;
                        } else {
                          result.sales_rep_employee = {
                            id: newValue[0].key as number,
                          };
                        }
                        setCustomer(result);
                      }
                    }}
                    required
                    errorMessage={
                      customer && !customer.sales_rep_employee
                        ? 'Dit veld is verplicht'
                        : ''
                    }
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <Checkbox
                    name='close_follow_up'
                    label='Speciale opvolging'
                    checked={customer && customer.close_follow_up}
                    onChange={() => {
                      setCustomer((prevState: any) => ({
                        ...prevState,
                        close_follow_up: customer.close_follow_up
                          ? !customer.close_follow_up
                          : true,
                      }));
                    }}
                  />
                </Stack>
                <Stack style={{ marginBottom: 10 }}>
                  <Checkbox
                    name='christmas_card'
                    label='Kerstkaartje?'
                    checked={
                      !customer ||
                      typeof customer.christmas_card === 'undefined'
                        ? true
                        : !!customer.christmas_card
                    }
                    onChange={(e, checked) => {
                      console.log(checked);
                      setCustomer((prevState: any) => ({
                        ...prevState,
                        christmas_card: !!checked,
                      }));
                    }}
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <SuggestedView
                    item={customer}
                    setItem={setCustomer}
                    prefix='lead_knownBy_'
                    code={
                      customer &&
                      customer.known_by &&
                      customer.known_by.substr(customer.known_by.length - 4)
                    }
                    label='Naambekendheid via'
                    field='known_by'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <SuggestedView
                    item={customer}
                    setItem={setCustomer}
                    prefix='lead_contactMetod_'
                    code={
                      customer &&
                      customer.contact_method &&
                      customer.contact_method.substr(
                        customer.contact_method.length - 4,
                      )
                    }
                    label='Contact opgenomen via'
                    field='contact_method'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <DateView
                    item={customer}
                    setItem={setCustomer}
                    date={customer && customer.date_documentation_sent}
                    label='Datum documentatie verstuurd'
                    field='date_documentation_sent'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <DateView
                    item={customer}
                    setItem={setCustomer}
                    date={customer && customer.date_visit_exhibition_house}
                    label='Datum laatste bezoek kijkwoning'
                    field='date_visit_exhibition_house'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <DateView
                    item={customer}
                    setItem={setCustomer}
                    date={customer && customer.date_visit_exposition}
                    label='Datum laatste bezoek opendeur/werf'
                    field='date_visit_exposition'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <DateView
                    item={customer}
                    setItem={setCustomer}
                    date={customer && customer.date_last_info_visit}
                    label='Datum laatste bezoek info-avond'
                    field='date_last_info_visit'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <SuggestedView
                    item={customer}
                    setItem={setCustomer}
                    prefix='lead_competitors_'
                    code={
                      customer &&
                      customer.competitors &&
                      customer.competitors.substr(
                        customer.competitors.length - 4,
                      )
                    }
                    label='Concurrentie'
                    field='competitors'
                  />
                </Stack>
              </Stack>
            </StackItem>
          </Stack>

          {customer && customer.construction_sites && (
            <div>
              <Stack>
                <h3 style={{ marginBottom: 0, marginTop: 20 }}>Werven</h3>
                <DetailsList
                  items={(customer && customer.construction_sites) || []}
                  columns={[
                    {
                      key: 'column1',
                      name: 'Naam van de werf',
                      fieldName: 'name',
                      minWidth: 180,
                      maxWidth: 180,
                      isRowHeader: true,
                      onRender: (construction_site: IConstructionSite) => (
                        <span>{construction_site.name}</span>
                      ),
                      data: 'string',
                      isPadded: true,
                    },
                    {
                      key: 'column2',
                      name: 'Werfadres',
                      fieldName: 'address',
                      minWidth: 225,
                      maxWidth: 225,
                      isRowHeader: true,
                      onRender: (construction_site: IConstructionSite) => (
                        <span>
                          {construction_site.address},{' '}
                          {construction_site.zip_code} {construction_site.city}
                        </span>
                      ),
                      data: 'string',
                      isPadded: true,
                    },
                    {
                      key: 'column3',
                      name: 'Werfstatus',
                      fieldName: 'status',
                      minWidth: 110,
                      maxWidth: 110,
                      isRowHeader: true,
                      onRender: (construction_site: IConstructionSite) => (
                        <span>{construction_site.status?.label}</span>
                      ),
                      data: 'string',
                      isPadded: true,
                    },
                    {
                      key: 'column4',
                      name: '',
                      fieldName: 'cta',
                      minWidth: 80,
                      maxWidth: 80,
                      isRowHeader: true,
                      onRender: (construction_site: any) => (
                        <a
                          href={`/construction-sites/${construction_site.id}`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <DefaultButton>Bekijk werf</DefaultButton>
                        </a>
                      ),
                      data: 'string',
                      isPadded: true,
                    },
                  ]}
                  isHeaderVisible
                  selectionMode={SelectionMode.none}
                  setKey='carDriverOverview'
                />
              </Stack>
              <Stack style={{ marginBottom: 10, marginTop: 30 }}>
                <Accordion
                  selectedKey={selectedValueItem}
                  defaultKey={undefined}
                  toggleItem={(key: string | number) => {
                    if (selectedValueItem === key) {
                      setSelectedValueItem(undefined);
                    } else setSelectedValueItem(key);
                  }}
                >
                  <AccordionItem
                    key='accordion-invoice-customer-invoices'
                    id='accordion-invoice-customer-invoices'
                    title={
                      <Stack>
                        <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                          Facturen
                        </h3>
                      </Stack>
                    }
                  >
                    <ShimmeredDetailsList
                      items={
                        customer && customer.invoices ? customer.invoices : []
                      }
                      columns={[
                        {
                          key: 'column1',
                          name: 'Factuurdatum',
                          fieldName: 'text',
                          minWidth: 100,
                          maxWidth: 100,
                          isRowHeader: true,
                          onRender: (item: ICustomerInvoice) => (
                            <span>
                              {moment(item.invoice_date).format('YYYY-MM-DD')}
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column2',
                          name: 'Factuurnr',
                          fieldName: 'text',
                          minWidth: 70,
                          maxWidth: 70,
                          isRowHeader: true,
                          onRender: (item: ICustomerInvoice) => (
                            <span>
                              <a
                                href={`/customer-invoices/${item.id}`}
                                target='_blank'
                                rel='noreferrer'
                                style={{ width: '20%', color: 'black' }}
                              >
                                {item.invoice_no}
                              </a>
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column3',
                          name: 'Intro',
                          fieldName: 'text',
                          minWidth: 300,
                          maxWidth: 300,
                          isRowHeader: true,
                          onRender: (item: ICustomerInvoice) => (
                            <span>{item.intro}</span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column4',
                          name: 'Betaald',
                          fieldName: 'text',
                          minWidth: 50,
                          maxWidth: 50,
                          isRowHeader: true,
                          onRender: (item: ICustomerInvoice) => (
                            <span>
                              <Checkbox
                                disabled
                                checked={!!item.invoice_paid}
                              />
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column6',
                          name: 'Totaal excl. BTW',
                          fieldName: 'unit_price',
                          minWidth: 100,
                          maxWidth: 100,
                          isRowHeader: true,
                          onRender: (customerInvoice: ICustomerInvoice) => (
                            <span>
                              &euro;{' '}
                              {customerInvoice.customer_invoice_line_items
                                .reduce(
                                  (previousValue, currentValue) =>
                                    previousValue +
                                    currentValue.unit_price *
                                      currentValue.units,
                                  0,
                                )
                                .toFixed(2)}
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column7',
                          name: 'Totaal incl. BTW',
                          fieldName: 'unit_price_with_vat',
                          minWidth: 100,
                          maxWidth: 100,
                          isRowHeader: true,
                          onRender: (customerInvoice: ICustomerInvoice) => (
                            <span>
                              &euro;{' '}
                              {customerInvoice.customer_invoice_line_items
                                .reduce((previousValue, currentValue) => {
                                  const unitPrice =
                                    currentValue.unit_price ?? 0; // Default to 0 if undefined
                                  const units = currentValue.units ?? 1; // Default to 1 if undefined
                                  const vatRate =
                                    currentValue.tax_code?.rate ??
                                    currentValue.percentage_vat ??
                                    0; // Use tax_code.rate if available, else fallback to percentage_vat, default to 0 if both are undefined

                                  const vatMultiplier =
                                    vatRate === 0
                                      ? 1
                                      : vatRate < 1
                                      ? 1 + vatRate
                                      : 1 + vatRate / 100; // Calculate VAT multiplier based on rate

                                  return (
                                    previousValue +
                                    unitPrice * units * vatMultiplier
                                  ); // Calculate total including VAT
                                }, 0)
                                .toFixed(2)}
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column8',
                          name: 'Open saldo',
                          fieldName: 'open_saldo',
                          minWidth: 80,
                          maxWidth: 80,
                          isRowHeader: true,
                          onRender: (customerInvoice: ICustomerInvoice) => {
                            const invoiceAmount =
                              customerInvoice.customer_invoice_line_items
                                .reduce((previousValue, currentValue) => {
                                  const unitPrice = currentValue.unit_price ?? 0; // Default to 0 if undefined
                                  const units = currentValue.units ?? 1; // Default to 1 if undefined
                                  const vatRate = currentValue.tax_code?.rate ?? currentValue.percentage_vat ?? 0; // Use tax_code.rate if available, else fallback to percentage_vat, default to 0 if both are undefined

                                  const vatMultiplier = vatRate === 0 ? 1 : vatRate < 1 ? (1 + vatRate) : (1 + vatRate / 100); // Calculate VAT multiplier based on rate

                                  return previousValue + (unitPrice * units * vatMultiplier); // Calculate total including VAT
                                }, 0)
                                .toFixed(2);

                            const invoiceAmountPayment =
                              customerInvoice.customer_invoice_payments
                                .reduce((previousValue, currentValue) => previousValue + (currentValue.amount ?? 0), 0) // Ensure amount is treated as a number, defaulting to 0 if undefined
                                .toFixed(2);

                            return (
                              <span
                                style={{
                                  color: +(invoiceAmount - invoiceAmountPayment).toFixed(2) < 0 ? 'orange' : '',
                                }}
                              >
                                &euro; {(invoiceAmount - invoiceAmountPayment).toFixed(2)}
                              </span>
                            );
                          },
                          data: 'string',
                          isPadded: true,
                        },
                      ]}
                      layoutMode={DetailsListLayoutMode.justified}
                      isHeaderVisible
                      selection={undefined}
                      selectionMode={SelectionMode.none}
                      selectionPreservedOnEmptyClick
                    />
                  </AccordionItem>
                </Accordion>
              </Stack>
            </div>
          )}
        </div>
        <div className={modalContentStyles.footer}>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <SaveButtonWithPermissions
              disabled={
                !customer ||
                (customer && !customer.last_name1) ||
                (customer && !customer.business && !customer.first_name1)
              }
              save={saveCustomer}
              permission='write:customers'
            />

            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default CustomerDetail;
