import {
  GET_ARCHITECTS,
  convertArchitectsToComboBoxOptions,
  displayNameArchitect,
  IArchitect,
} from './Architect';
import AUTH_CONFIG from './auth_config.json';
import {
  GET_CUSTOMERS,
  ICustomer,
  convertCustomersToComboBoxOptions,
  displayNameCustomer,
  ADD_CUSTOMER,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER,
  GET_CUSTOMER_BY_ID,
} from './Customer';
import {
  copyAndSort,
  returnFluentIcon,
  returnFormattedDate,
  sortComboBoxOptionOnText,
  titleComboboxOptions,
} from './functions';
import PrivateRoute from './PrivateRoute';
import {
  GET_SALES_REPS,
  ISalesRep,
  convertSalesRepsToComboBoxOptions,
  displayNameSalesRep,
  convertSalesRepsToDropdownOptions,
} from './SalesRep';
import {
  classes,
  dropdownStyles150,
  dropdownStyles350,
  overviewClassNames,
  overviewControlStyles,
  theme,
  stackTokens5,
  stackTokens10,
  stackTokens15,
  stackTokens20,
  stackTokens50,
  stackStyles,
  classNames,
  textFieldStyles300,
  textFieldStyles350,
  textFieldStyles500,
  textFieldStyles1000,
  modalContentStyles,
  paddingRight3,
  iconProps,
  dialogModelProps,
  dialogRemoveConfirmationProps,
  cancelIcon,
  iconButtonStyles,
  iconLastProps,
  columnStyles,
  callOutClasses,
  stackStyles500,
  dialogRemoveCustomerConfirmationProps,
} from './Styles';
import {
  GET_SUPPLIERS,
  ISupplier,
  convertSuppliersToComboBoxOptions,
  displayNameSupplier,
  convertSuppliersToDropdownOptions,
  IPropertySupplier,
} from './Supplier';
import {
  default_page_size,
  documentExtensions,
  property_types,
  saving_subjects,
  template_types,
} from './constants';
import {
  ISuggestedValue,
  convertSuggestedValuesToComboBoxOptions,
} from './SuggestedValue';
import {
  GET_SUGGESTED_VALUES_BY_CATEGORY_CODE,
  GET_SUGGESTED_VALUE_CATEGORIES,
  convertSuggestedValueCategoriesToComboBoxOptions,
  ISuggestedValueCategory,
} from './SuggestedValueCategory';
import { IDocument, UPDATE_DOCUMENT, DELETE_DOCUMENT } from './Document';
import {
  IPropertyListType,
  sortPropertyListTypesByName,
  convertPropertyListTypeToDropdownOptions,
  convertPropertyListTypeToComboBoxOptions,
  GET_PROPERTY_LIST_TYPES,
} from './PropertyListType';
import {
  IConstructionSheetRemark,
  UPDATE_CONSTRUCTION_SHEET_REMARKS,
  CREATE_CONSTRUCTION_SHEET_REMARKS,
  GET_CONSTRUCTION_SHEET_REMARKS_BY_CONSTRUCTION_SITE,
} from './ConstructionSheetRemark';
import {
  GET_WATER_POINTS_BY_CONSTRUCTION_SITE,
  IProperty,
  UPDATE_PROPERTY,
  ADD_PROPERTY,
  DELETE_PROPERTY,
  sortPropertiesOnExecutionList,
  sortPropertiesOnConstructionSheet,
  updateWeightsOnProperties,
} from './Property';
import {
  IPropertyType,
  convertPropertyTypeToComboBoxOptions,
  GET_PROPERTY_TYPES,
} from './PropertyType';
import {
  IEmployee,
  displayNameEmployee,
  convertEmployeesToDropdownOptions,
  convertEmployeesToComboBoxOptions,
  UPDATE_EMPLOYEE,
  CREATE_USER,
  GET_EMPLOYEES,
} from './Employee';
import {
  IConstructionSite,
  IConstructionSiteQueryResult,
  convertConstructionSitesToComboBoxOptions,
  DELETE_CONSTRUCTION_SITE,
  UPDATE_CONSTRUCTION_SITE,
  ADD_CONSTRUCTION_SITE,
  GET_CONSTRUCTION_SITE_BY_ID,
  GET_CONSTRUCTION_SITES,
  GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
  UPDATE_CONSTRUCTION_SITE_WITH_PROPERTIES,
  GET_CONSTRUCTION_SITE_BY_ID_EDIT,
} from './ConstructionSite';
import {
  ADD_PROPERTY_PROPERTY_LIST_TYPE,
  DELETE_PROPERTY_PROPERTY_LIST_TYPE,
  IPropertyPropertyListType,
} from './PropertyPropertyListTypes';
import {
  ADD_PROPERTY_SUPPLIER,
  DELETE_PROPERTY_SUPPLIER,
} from './PropertySupplier';
import {
  ITemplate,
  ITemplateType,
  ADD_TEMPLATE,
  UPDATE_TEMPLATE,
  GET_TEMPLATES,
  GET_TEMPLATE_BY_ID,
  GET_TEMPLATES_WITH_ITEMS,
  convertTemplatesToComboBoxOptions,
} from './Template';
import {
  ITemplateItem,
  ADD_TEMPLATE_ITEM,
  UPDATE_TEMPLATE_ITEM,
  DELETE_TEMPLATE_ITEM,
  GET_TEMPLATES_ITEMS,
  ITemplateItemWhereInput,
  sortTemplateItemChildrenOnExecutionList,
  sortTemplateItemChildrenOnConstructionSheet,
  ITemplateCreateInput,
} from './TemplateItem';
import {
  DELETE_TEMPLATE_TEMPLATE_ITEM,
  ITemplateTemplateItem,
  ITemplateTemplateItemCreateInput,
  ITemplateTemplateItemUpdateInput,
  sortTemplateTemplateItemOnConstructionSheet,
  sortTemplateTemplateItemOnExecutionList,
} from './TemplateTemplateItem';
import { GET_STORAGE_TOKEN, IStorageToken } from './StorageToken';
import {
  ADD_PURCHASE_ORDER,
  DELETE_PURCHASE_ORDER,
  GET_PURCHASE_ORDERS,
  GET_PURCHASE_ORDER_BY_ID,
  IPurchaseOrder,
  UPDATE_PURCHASE_ORDER,
  GET_PURCHASE_ORDER_STATUSES,
  IPurchaseOrderStatus,
} from './PurchaseOrder';
import {
  ADD_PURCHASE_ORDER_TEMPLATE,
  convertPurchaseOrderTemplatesToComboBoxOptions,
  DELETE_PURCHASE_ORDER_TEMPLATE,
  GET_PURCHASE_ORDER_TEMPLATES,
  GET_PURCHASE_ORDER_TEMPLATES_ALL_IN,
  GET_PURCHASE_ORDER_TEMPLATE_BY_ID,
  IPurchaseOrderTemplate,
  UPDATE_PURCHASE_ORDER_TEMPLATE,
} from './PurchaseOrderTemplate';
import {
  convertContactPersonsToComboBoxOptions,
  IContactPerson,
} from './ContactPerson';
import {
  convertMeasuringUnitsToComboBoxOptions,
  GET_MEASURING_UNITS,
  IMeasuringUnit,
} from './MeasuringUnit';
import {
  IPurchaseOrderLine,
  IPurchaseOrderLineConnect,
  IPurchaseOrderLineCreate,
  IPurchaseOrderLineUpdate,
} from './PurchaseOrderLine';
import DragAndDrop from './DragAndDrop';

export {
  GET_ARCHITECTS,
  GET_CONSTRUCTION_SHEET_REMARKS_BY_CONSTRUCTION_SITE,
  GET_CONSTRUCTION_SITES,
  GET_CONSTRUCTION_SITE_BY_ID,
  GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
  GET_CONSTRUCTION_SITE_BY_ID_EDIT,
  GET_CUSTOMERS,
  GET_CUSTOMER_BY_ID,
  GET_EMPLOYEES,
  GET_MEASURING_UNITS,
  GET_PROPERTY_LIST_TYPES,
  GET_PROPERTY_TYPES,
  GET_PURCHASE_ORDER_BY_ID,
  GET_PURCHASE_ORDERS,
  GET_PURCHASE_ORDER_TEMPLATES,
  GET_PURCHASE_ORDER_TEMPLATES_ALL_IN,
  GET_PURCHASE_ORDER_TEMPLATE_BY_ID,
  GET_PURCHASE_ORDER_STATUSES,
  GET_SALES_REPS,
  GET_STORAGE_TOKEN,
  GET_SUGGESTED_VALUES_BY_CATEGORY_CODE,
  GET_SUGGESTED_VALUE_CATEGORIES,
  GET_SUPPLIERS,
  GET_TEMPLATES,
  GET_TEMPLATES_ITEMS,
  GET_TEMPLATES_WITH_ITEMS,
  GET_TEMPLATE_BY_ID,
  GET_WATER_POINTS_BY_CONSTRUCTION_SITE,
  ADD_CONSTRUCTION_SITE,
  ADD_CUSTOMER,
  ADD_PROPERTY,
  ADD_PROPERTY_PROPERTY_LIST_TYPE,
  ADD_PROPERTY_SUPPLIER,
  ADD_PURCHASE_ORDER,
  ADD_PURCHASE_ORDER_TEMPLATE,
  ADD_TEMPLATE,
  ADD_TEMPLATE_ITEM,
  UPDATE_CONSTRUCTION_SHEET_REMARKS,
  CREATE_CONSTRUCTION_SHEET_REMARKS,
  UPDATE_CONSTRUCTION_SITE,
  UPDATE_CONSTRUCTION_SITE_WITH_PROPERTIES,
  UPDATE_CUSTOMER,
  UPDATE_DOCUMENT,
  UPDATE_EMPLOYEE,
  UPDATE_PROPERTY,
  UPDATE_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER_TEMPLATE,
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_ITEM,
  DELETE_CONSTRUCTION_SITE,
  DELETE_CUSTOMER,
  DELETE_DOCUMENT,
  DELETE_PROPERTY,
  DELETE_PROPERTY_PROPERTY_LIST_TYPE,
  DELETE_PROPERTY_SUPPLIER,
  DELETE_PURCHASE_ORDER,
  DELETE_PURCHASE_ORDER_TEMPLATE,
  DELETE_TEMPLATE_ITEM,
  DELETE_TEMPLATE_TEMPLATE_ITEM,
  CREATE_USER,
  convertArchitectsToComboBoxOptions,
  convertConstructionSitesToComboBoxOptions,
  convertContactPersonsToComboBoxOptions,
  convertCustomersToComboBoxOptions,
  convertEmployeesToComboBoxOptions,
  convertEmployeesToDropdownOptions,
  convertMeasuringUnitsToComboBoxOptions,
  convertPropertyListTypeToComboBoxOptions,
  convertPropertyListTypeToDropdownOptions,
  convertPropertyTypeToComboBoxOptions,
  convertPurchaseOrderTemplatesToComboBoxOptions,
  convertSalesRepsToComboBoxOptions,
  convertSalesRepsToDropdownOptions,
  convertSuggestedValuesToComboBoxOptions,
  convertSuggestedValueCategoriesToComboBoxOptions,
  convertSuppliersToComboBoxOptions,
  convertSuppliersToDropdownOptions,
  convertTemplatesToComboBoxOptions,
  DragAndDrop,
  displayNameArchitect,
  displayNameCustomer,
  displayNameEmployee,
  displayNameSalesRep,
  displayNameSupplier,
  sortComboBoxOptionOnText,
  sortPropertiesOnConstructionSheet,
  sortPropertiesOnExecutionList,
  sortTemplateItemChildrenOnExecutionList,
  sortTemplateItemChildrenOnConstructionSheet,
  sortTemplateTemplateItemOnExecutionList,
  sortTemplateTemplateItemOnConstructionSheet,
  sortPropertyListTypesByName,
  titleComboboxOptions,
  updateWeightsOnProperties,
  PrivateRoute,
  AUTH_CONFIG,
  classes,
  copyAndSort,
  overviewClassNames,
  overviewControlStyles,
  returnFluentIcon,
  theme,
  returnFormattedDate,
  paddingRight3,
  iconProps,
  iconLastProps,
  columnStyles,
  dropdownStyles150,
  dropdownStyles350,
  stackTokens5,
  stackTokens10,
  stackTokens15,
  stackTokens20,
  stackTokens50,
  textFieldStyles300,
  textFieldStyles350,
  textFieldStyles500,
  textFieldStyles1000,
  stackStyles500,
  stackStyles,
  classNames,
  callOutClasses,
  modalContentStyles,
  documentExtensions,
  property_types,
  saving_subjects,
  default_page_size,
  template_types,
  dialogModelProps,
  dialogRemoveConfirmationProps,
  dialogRemoveCustomerConfirmationProps,
  cancelIcon,
  iconButtonStyles,
};

export type {
  IArchitect,
  ICustomer,
  IConstructionSheetRemark,
  IConstructionSite,
  IConstructionSiteQueryResult,
  IContactPerson,
  IDocument,
  IEmployee,
  IMeasuringUnit,
  IProperty,
  IPropertyType,
  IPropertyListType,
  IPropertyPropertyListType,
  IPropertySupplier,
  IPurchaseOrder,
  IPurchaseOrderLine,
  IPurchaseOrderLineConnect,
  IPurchaseOrderLineCreate,
  IPurchaseOrderLineUpdate,
  IPurchaseOrderTemplate,
  IPurchaseOrderStatus,
  ISalesRep,
  IStorageToken,
  ISuggestedValue,
  ISuggestedValueCategory,
  ISupplier,
  ITemplate,
  ITemplateType,
  ITemplateItem,
  ITemplateItemWhereInput,
  ITemplateTemplateItem,
  ITemplateTemplateItemCreateInput,
  ITemplateTemplateItemUpdateInput,
  ITemplateCreateInput,
};

export const DEFAULT_TOAST_DURATION = 800;
export const DEFAULT_TOAST_POSITION = 'top-left';

export const DEFAULT_LOADING_MESSAGE = 'laden...';
export const DEFAULT_ERROR_MESSAGE = 'Er is iets misgelopen.';

export function normalizeString(str: string) {
  if (str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  return '';
}

export const resolveUndefinedVat = (vat:number | undefined) => (vat || 0);

export function normalizeFileNameBestelbon(fileName: string): string {
  // Replace special characters with hyphens, except for alphanumeric, spaces, dashes, underscores, and dots.
  const cleanedName = fileName
    .replace(/[^a-zA-Z0-9\s-._]/g, '') // Remove invalid characters, allow dot and underscore.
    .replace(/\s+/g, '-') // Replace spaces with hyphens.
    .replace(/-+/g, '-') // Collapse multiple hyphens into one.
    .trim(); // Trim leading and trailing spaces/hyphens.

  // Convert the name to lowercase for consistent file naming.
  return cleanedName.toLowerCase();
}
