/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  Stack,
  StackItem,
  TextField,
  IComboBoxOption,
  Label,
  Checkbox,
  SelectionMode,
  Selection,
  IDetailsRowCheckStyles,
  IDetailsRowBaseProps,
  DetailsRowCheck,
  IDetailsFooterProps,
  DetailsRow,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  SpinnerSize,
  Spinner,
  Toggle,
} from '@fluentui/react';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import moment from 'moment';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import {
  convertArchitectsToComboBoxOptions,
  convertEmployeesToComboBoxOptions,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  DELETE_PURCHASE_ORDER,
  GET_ARCHITECTS,
  GET_EMPLOYEES,
  GET_MEASURING_UNITS,
  GET_PURCHASE_ORDER_BY_ID,
  GET_PURCHASE_ORDER_STATUSES,
  GET_PURCHASE_ORDER_TEMPLATES_ALL_IN,
  IEmployee,
  IPurchaseOrder,
  modalContentStyles,
  stackTokens5,
} from '../../../utils';
import {
  GET_LEADS,
  ILead,
  convertLeadsToComboBoxOptions,
} from '../../../utils/Lead';
import {
  ComboboxWithFilter,
  CommandBarSticky,
} from '../../../components/parts';
import SuggestedView from '../../../components/parts/SuggestedView';
import DateView from '../../../components/parts/DateView';
import { Accordion, AccordionItem } from '../../../components/parts/Accordion';
import {
  DELETE_CUSTOMER_PAYMENT_TRANSACTION,
  ICustomerPaymentTransaction,
} from '../../../utils/CustomerPaymentTransaction';
import { GET_TEMPLATES, ITemplate } from '../../../utils/Template';
import theme, { commandBarTheme, customPanelStyles } from '../../../theme';
import useDebounce from '../../../components/hooks/useDebounce';
import { ICustomerContractUpdate } from '../../../utils/CustomerContractUpdate';
import {
  ADD_CONSTRUCTION_SITE,
  GET_CONSTRUCTION_SITES,
  GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
  IConstructionSiteCreateUpdate,
  UPDATE_CONSTRUCTION_SITE,
} from '../../../utils/ConstructionSite';
import {
  IInvoiceCustomer,
  DELETE_CONSTRUCTION_SITE_INVOICE_CUSTOMER,
} from '../../../utils/ConstructionSiteInvoiceCustomer';
import {
  convertConstructionSiteStatusesToComboBoxOptions,
  GET_CONSTRUCTION_SITE_STATUSES,
  IConstructionSiteStatus,
} from '../../../utils/ConstructionSiteStatus';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import parseNumber from '../../../utils/Numbers';
import { toastSuccess, toastError } from '../../../utils/toast';
import ZipCodeFetcher from '../../../components/DynamicZip';
import { ITaxCode } from '../../../utils/TaxCode';
import PurchaseOrderDetail, {
  sortLineItems,
} from '../../purchase-orders/components/PurchaseOrderDetail';
import { GET_SUPPLIERS_WITH_CONTACT_PERSONS } from '../../../utils/Supplier';
import purchaseOrderDownload from '../../purchase-orders/components/PurchaseOrderDownload';

interface Props {
  isOpen: boolean;
  dismissPanel: () => void;
  constructionSiteSource: IConstructionSiteCreateUpdate;
  listFilters: any;
  constructionSiteId?: number;
  taxCodes: ITaxCode[];
}

interface ICustomerContractUpdateCalculated extends ICustomerContractUpdate {
  calculatedTotal?: number;
}

const ConstructionSiteDetail = ({
  isOpen,
  dismissPanel,
  constructionSiteSource,
  constructionSiteId,
  taxCodes,
}: Props) => {
  // =====================================================
  // ====================== A U T H ======================
  // =====================================================

  const { getAccessTokenSilently, user } = useAuth0();

  const hasDirectieRole = user?.['3bouw/roles']?.includes('Directie');

  // =====================================================
  // ===================== S T A T E =====================
  // =====================================================

  const [selectedValueItem, setSelectedValueItem] = useState<
    number | string | undefined
  >();

  const [templates, setTemplates] = useState<ITemplate[]>([]);

  const [getTemplates] = useLazyQuery(GET_TEMPLATES, {
    onCompleted: data => {
      setTemplates(data.findManyTemplates);
    },
  });

  const getTemplateOptions = (templates: ITemplate[]) => {
    const templateItems = templates.map(template => ({
      key: template.id!,
      text: template.name,
    }));
    templateItems.push({ key: -1, text: 'geen sjabloon' });

    return templateItems;
  };

  const [architectFilter, setArchitectFilter] = useState('');
  const debouncedArchitectFilter = useDebounce(architectFilter, 500);

  const getArchitectFilters = (filterstring: string) => {
    const filter: any = {
      AND: [],
    };
    if (filterstring) {
      const stringArray = filterstring.split(' ');
      for (let i = 0; i < stringArray.length; i++) {
        const filters: any = [
          { first_name: { contains: stringArray[i] } },
          { last_name: { contains: stringArray[i] } },
          { company: { contains: stringArray[i] } },
          { city: { contains: stringArray[i] } },
        ];

        const numericValue = parseInt(filter, 10);

        if (numericValue) {
          filters.OR.push({ zip_code: { equals: numericValue } });
        }

        filter.AND = [...filter.AND, { OR: filters }];
      }
    }
    return filter;
  };

  const { data: architectsData } = useQuery(GET_ARCHITECTS, {
    variables: {
      filter:
        debouncedArchitectFilter && debouncedArchitectFilter.length > 0
          ? getArchitectFilters(debouncedArchitectFilter)
          : {},
      take:
        debouncedArchitectFilter && debouncedArchitectFilter.length > 0
          ? undefined
          : 20,
    },
    fetchPolicy: 'no-cache',
  });

  const { data: employeesData } = useQuery(GET_EMPLOYEES, {
    fetchPolicy: 'no-cache',
  });

  const { data: constructionSiteStatusesData } = useQuery(
    GET_CONSTRUCTION_SITE_STATUSES,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [leadFilter, setLeadFilter] = useState('');
  const debouncedLeadFilter = useDebounce(leadFilter, 500);

  const getLeadFilter = (filterstring: string) => {
    const filter: any = {
      AND: [
        {
          OR: [
            { status: { contains: 'SOLD' } },
            { status: { contains: 'ONLY_INVOICE' } },
            { status: { contains: 'PROVISIONAL_AGREEMENT' } },
            { status: { contains: 'CANCELED' } },
            { only_invoice: true },
            {
              construction_sites: {
                some: {
                  id: {
                    gt: 0,
                  },
                },
              },
            },
          ],
        },
      ],
    };
    if (filterstring) {
      const stringArray = filterstring.split(' ');
      for (let i = 0; i < stringArray.length; i++) {
        filter.AND = [
          ...filter.AND,
          {
            OR: [
              {
                first_name1: {
                  contains: stringArray[i],
                },
              },
              {
                first_name2: {
                  contains: stringArray[i],
                },
              },
              {
                last_name1: {
                  contains: stringArray[i],
                },
              },
              {
                last_name2: {
                  contains: stringArray[i],
                },
              },
            ],
          },
        ];
      }
    }

    return filter;
  };

  const [leads, setLeads] = useState<ILead[]>([]);
  useQuery(GET_LEADS, {
    variables: {
      filter:
        debouncedLeadFilter && debouncedLeadFilter.length > 0
          ? getLeadFilter(debouncedLeadFilter)
          : {},
      take:
        debouncedLeadFilter && debouncedLeadFilter.length > 0 ? undefined : 20,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      const leads = x.findManyLeads;
      if (
        x.findManyLeads &&
        constructionSite &&
        constructionSite.lead &&
        constructionSite.lead.id
      ) {
        let currentLeadExists = false;
        for (let i = 0; i < x.findManyLeads.length; i++) {
          if (x.findManyLeads[i].id === constructionSite.lead.id) {
            currentLeadExists = true;
          }
        }
        if (!currentLeadExists) {
          leads.push(constructionSite.lead);
        }
      }
      setLeads(leads);
    },
  });

  // =====================================================
  // ========== C O N S T R U C T I O N S I T E ==========
  // =====================================================

  const [constructionSite, setConstructionSite] =
    useState<IConstructionSiteCreateUpdate>(constructionSiteSource);

  const [isLoadingUpsertCon, setIsLoadingUpsertCon] = useState(false);

  const { data: constructionSiteData, refetch } = useQuery(
    GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
    {
      variables:
        (constructionSite && constructionSite.id) || constructionSiteId
          ? {
              where: { id: constructionSite.id || constructionSiteId },
              whereProps: {
                OR: [
                  { template_item_id: 1351 },
                  { template_item_id: 1376 },
                  { template_item_id: 1378 },
                ],
              },
            }
          : undefined,
      skip: (!constructionSite || !constructionSite.id) && !constructionSiteId,
    },
  );

  const [modifyConstructionSite] = useMutation(UPDATE_CONSTRUCTION_SITE, {
    refetchQueries: [{ query: GET_CONSTRUCTION_SITES }],
  });

  const [addConstructionSite] = useMutation(ADD_CONSTRUCTION_SITE, {
    // refetchQueries: [{ query: GET_CONSTRUCTION_SITES }],
  });

  const saveConstructionSite = async () => {
    if (constructionSite) {
      if (constructionSite.name === '' || !constructionSite.name) return;

      if (
        constructionSite.zip_code &&
        Number.isNaN(+constructionSite.zip_code)
      ) {
        return;
      }

      if (
        !constructionSite.lead ||
        !constructionSite.lead.id ||
        constructionSite.lead.id === 0
      ) {
        return;
      }

      try {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const data = {
              name: constructionSite.name,
              lead: {
                connect: {
                  id: constructionSite?.lead?.id,
                },
              },
              address: constructionSite.address,
              archive_no: constructionSite.archive_no,
              comments: constructionSite.comments,
              city: constructionSite.city,
              contract_amount: constructionSite.contract_amount
                ? parseNumber(constructionSite.contract_amount)
                : undefined,
              zip_code: constructionSite.zip_code
                ? +constructionSite.zip_code
                : undefined,
              architect: constructionSite.architect
                ? {
                    connect: { id: constructionSite.architect.id },
                  }
                : undefined,
              site_manager: constructionSite.site_manager
                ? {
                    connect: { id: constructionSite.site_manager.id },
                  }
                : undefined,
              epb_reporter: constructionSite.epb_reporter
                ? {
                    connect: { id: constructionSite.epb_reporter.id },
                  }
                : undefined,
              project_manager: constructionSite.project_manager
                ? {
                    connect: { id: constructionSite.project_manager.id },
                  }
                : undefined,
              sales_rep: constructionSite.sales_rep
                ? {
                    connect: { id: constructionSite.sales_rep.id },
                  }
                : undefined,
              status: constructionSite.status
                ? {
                    connect: { id: constructionSite.status.id },
                  }
                : undefined,
              safety_coordinator: constructionSite.safety_coordinator
                ? {
                    connect: { id: constructionSite.safety_coordinator.id },
                  }
                : undefined,
              degree_of_completion: constructionSite.degree_of_completion,
              type_of_home: constructionSite.type_of_home,
              style_of_home: constructionSite.style_of_home,
              billboard_comments: constructionSite.billboard_comments,
              billboard_required: constructionSite.billboard_required,
              billboard_status: constructionSite.billboard_status,
              k_level: constructionSite.k_level,
              e_level: constructionSite.e_level,
              airtightness: constructionSite.airtightness,
              date_of_contract: constructionSite.date_of_contract,
              date_sold: constructionSite.date_sold,
              date_completed: constructionSite.date_completed,
              sounding_required: constructionSite.sounding_required,
              sounding_status: constructionSite.sounding_status,
              template_id:
                constructionSite.template_id === -1
                  ? 0
                  : constructionSite.template_id,
              breyne_applicable: constructionSite.breyne_applicable,
              breyne_date_provisional_delivery:
                constructionSite.breyne_date_provisional_delivery,
              breyne_date_final_delivery: constructionSite.breyne_date_final_delivery,
            };

            if (constructionSite.id) {
              modifyConstructionSite({
                variables: {
                  id: constructionSite.id,
                  data,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: (x: any) => {
                  dismissPanel();
                  resolve(x);
                },
              });
            } else {
              addConstructionSite({
                variables: {
                  data,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: (x: any) => {
                  resolve(x);
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        const x = await (res as any);
        setConstructionSite(x.createConstructionSite);
        toastSuccess('Werf opgeslagen');
      } catch (error: any) {
        toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
      }
    }
  };

  useEffect(() => {
    if (constructionSiteData && constructionSiteData.findOneConstructionSite) {
      setConstructionSite(prevState => {
        if (!prevState.updated) {
          return constructionSiteData.findOneConstructionSite;
        }

        return prevState;
      });
    }
  }, [constructionSiteData]);

  // =====================================================
  // ============= P U R C H A S E O R D E R =============
  // =====================================================

  const [isPurchaseOrderPanelOpen, setIsPurchaseOrderPanelOpen] =
    useState(false);

  const [showDeletePurchaseOrderDialog, setShowDeletePurchaseOrderDialog] =
    useState(false);

  const [purchaseOrder, setPurchaseOrder] = useState<IPurchaseOrder>();

  const [deletePurchaseOrder, { loading: deletingPurchaseTransaction }] =
    useMutation(DELETE_PURCHASE_ORDER, {
      refetchQueries: [
        {
          query: GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
          variables:
            constructionSiteSource && constructionSiteSource.id
              ? {
                  where: { id: constructionSiteSource.id },
                }
              : undefined,
        },
      ],
    });

  const deleteSelectedPurchaseOrder = useCallback(async () => {
    try {
      if (purchaseOrder) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            deletePurchaseOrder({
              variables: {
                where: {
                  id: purchaseOrder.id,
                },
              },
              onError: error => {
                setShowDeletePurchaseOrderDialog(false);
                reject(error);
              },
              onCompleted: (x: any) => {
                setShowDeletePurchaseOrderDialog(false);
                resolve(x);
              },
            });
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
        toastSuccess('Bestelbon verwijderd');
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  }, [purchaseOrder]);

  const getSelectionPurchaseOrderDetails = () => {
    const currentSelection: any = selectionPurchaseOrder.getSelection();

    if (currentSelection.length > 0) {
      setPurchaseOrder(currentSelection[0]);
    } else {
      setPurchaseOrder(undefined);
    }
  };

  const selectionPurchaseOrder = new Selection({
    onSelectionChanged: getSelectionPurchaseOrderDetails,
  });

  const openPurchaseOrderDetail = (newLead?: boolean) => {
    if (purchaseOrder && !newLead) {
      // test
    } else {
      setPurchaseOrder(undefined);
    }
    setIsPurchaseOrderPanelOpen(true);
  };

  // =====================================================
  // ======== P A Y M E N T T R A N S A C T I O N ========
  // =====================================================

  const [
    isCustomerPaymentTransactionPanelOpen,
    setIsCustomerPaymentTransactionPanelOpen,
  ] = useState(false);

  const [
    showDeletePaymentTransactionDialog,
    setShowDeletePaymentTransactionDialog,
  ] = useState(false);

  const [customerPaymentTransaction, setCustomerPaymentTransaction] =
    useState<ICustomerPaymentTransaction>();

  const [deletePaymentTranaction, { loading: deletingPaymentTransaction }] =
    useMutation(DELETE_CUSTOMER_PAYMENT_TRANSACTION, {
      refetchQueries: [
        {
          query: GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
          variables:
            constructionSiteSource && constructionSiteSource.id
              ? {
                  where: { id: constructionSiteSource.id },
                }
              : undefined,
        },
      ],
    });

  const deleteSelectedPaymentTransaction = useCallback(async () => {
    try {
      if (customerPaymentTransaction) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            deletePaymentTranaction({
              variables: {
                where: {
                  id: customerPaymentTransaction.id,
                },
              },
              onError: error => {
                setShowDeletePaymentTransactionDialog(false);
                reject(error);
              },
              onCompleted: (x: any) => {
                setShowDeletePaymentTransactionDialog(false);
                resolve(x);
              },
            });
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
        toastSuccess('Verrekening verwijderd');
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  }, [customerPaymentTransaction]);

  const getSelectionCustomerPaymentTransactionDetails = () => {
    const currentSelection: any =
      selectionCustomerPaymentTransaction.getSelection();

    if (currentSelection.length > 0) {
      setCustomerPaymentTransaction(currentSelection[0]);
    } else {
      setCustomerPaymentTransaction(undefined);
    }
  };

  const selectionCustomerPaymentTransaction = new Selection({
    onSelectionChanged: getSelectionCustomerPaymentTransactionDetails,
  });

  const openCustomerPaymentTransactionDetail = (newLead?: boolean) => {
    if (customerPaymentTransaction && !newLead) {
      // test
    } else {
      setCustomerPaymentTransaction(undefined);
    }
    setIsCustomerPaymentTransactionPanelOpen(true);
  };

  // =====================================================
  // =========== I N V O I C E C U S T O M E R ===========
  // =====================================================

  const [isInvoiceCustomerPanelOpen, setIsInvoiceCustomerPanelOpen] =
    useState(false);

  const [invoiceCustomer, setInvoiceCustomer] = useState<IInvoiceCustomer>();

  const getSelectionInvoiceCustomerDetails = () => {
    const currentSelection: any = selectionInvoiceCustomer.getSelection();

    if (currentSelection.length > 0) {
      setInvoiceCustomer(currentSelection[0]);
    } else {
      setInvoiceCustomer({});
    }
  };

  const selectionInvoiceCustomer = new Selection({
    onSelectionChanged: getSelectionInvoiceCustomerDetails,
  });

  const openInvoiceCustomerDetail = (newIC?: boolean) => {
    if (newIC) {
      setInvoiceCustomer({});
    }
    setIsInvoiceCustomerPanelOpen(true);
  };

  const [deleteInvoiceCustomer] = useMutation(
    DELETE_CONSTRUCTION_SITE_INVOICE_CUSTOMER,
  );

  // =====================================================
  // =========== C U S T O M E R I N V O I C E ===========
  // =====================================================

  const [isCreateInvoicePanelOpen, setIsCreateInvoicePanelOpen] =
    useState(true);

  const [isCustomerInvoicePanelOpen, setIsCustomerInvoicePanelOpen] =
    useState(false);

  const [customerInvoice, setCustomerInvoice] = useState<any>({});

  const getSelectionCustomerInvoiceDetails = () => {
    const currentSelection: any = selectionCustomerInvoice.getSelection();

    if (currentSelection.length > 0) {
      setCustomerInvoice(currentSelection[0]);
    } else {
      setCustomerInvoice({});
    }
  };

  const selectionCustomerInvoice = new Selection({
    onSelectionChanged: getSelectionCustomerInvoiceDetails,
  });

  const openCustomerInvoiceDetail = (newItem?: boolean) => {
    if (newItem) {
      setCustomerInvoice({});
    }
    setIsCustomerInvoicePanelOpen(true);
  };

  // =====================================================
  // =========== C O N T R A C T U P D A T E S ===========
  // =====================================================

  const [
    isCustomerContractUpdatePanelOpen,
    setIsCustomerContractUpdatePanelOpen,
  ] = useState(false);

  const [customerContractUpdate, setCustomerContractUpdate] =
    useState<ICustomerContractUpdate>();

  const getSelectionCustomerContractUpdateDetails = () => {
    const currentSelection: any =
      selectionCustomerContractUpdate.getSelection();

    if (currentSelection.length > 0) {
      setCustomerContractUpdate(currentSelection[0]);
    } else {
      setCustomerContractUpdate({});
    }
  };

  const selectionCustomerContractUpdate = new Selection({
    onSelectionChanged: getSelectionCustomerContractUpdateDetails,
  });

  const openCustomerContractUpdateDetail = (newLead?: boolean) => {
    if (customerContractUpdate && !newLead) {
      // getCustomerContractUpdate({
      //   variables: {
      //     where: { id: selectionCustomerContractUpdateDetails.id },
      //   },
      // });
    } else {
      setCustomerContractUpdate({
        intro: 'Verrekeningen/ aanpassingen tov de aannemingsovereenkomst',
      });
    }
    setIsCustomerContractUpdatePanelOpen(true);
  };

  // =====================================================
  // =====================================================
  // =====================================================

  useEffect(() => {
    if (
      !constructionSite ||
      !constructionSite.id ||
      constructionSite.id === -1
    ) {
      getTemplates();
    }
  }, []);

  const handleButtonClick = async (_lid: string) => {
    try {
      setIsLoadingUpsertCon(true);
      const token = await getAccessTokenSilently();

      const response = await fetch(
        'https://3bouw-wings-sync-function.azurewebsites.net/api/PublicUpsertConstructionSite',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, // Add the Authorization header with your token
          },
          body: JSON.stringify({
            id: _lid,
          }),
        },
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setConstructionSite((prevState: any) => ({
        ...prevState,
        wings_account_code: data.wings_code,
      }));
      toastSuccess('Klant succesvol gesynchroniseerd');
    } catch (err: any) {
      toastError(err?.message ? err.message : 'Er is iets misgelopen');
    } finally {
      setIsLoadingUpsertCon(false);
    }
  };

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setConstructionSite((prevState: any) => ({
      ...prevState,
      updated: true,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  const onChangeTemplate = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption,
  ): void => {
    setConstructionSite((prevState: any) => ({
      ...prevState,
      updated: true,
      template_id: item ? item.key : -1,
    }));
  };

  const detailsRowCheckStyles: Partial<IDetailsRowCheckStyles> = {
    root: { visibility: 'hidden' },
  };

  const onRenderCheckForFooterRow: IDetailsRowBaseProps['onRenderCheck'] = (
    props,
  ): JSX.Element => (
    <DetailsRowCheck {...props} styles={detailsRowCheckStyles} selected />
  );

  const onRenderDetailsFooter = (
    detailsFooterProps?: IDetailsFooterProps,
  ): JSX.Element => {
    if (!detailsFooterProps) return <>nothing</>;
    const item: ICustomerPaymentTransaction = {};
    if (constructionSite && constructionSite.payment_transactions) {
      let totalPercentage = 0;
      for (let i = 0; i < constructionSite.payment_transactions.length; i++) {
        totalPercentage += parseNumber(
          constructionSite.payment_transactions[i].percentage,
        );
      }
      item.percentage = totalPercentage;
      item.description = 'Totaal';
    }
    return (
      <DetailsRow
        {...detailsFooterProps}
        columns={detailsFooterProps.columns}
        item={item}
        itemIndex={
          constructionSite.payment_transactions
            ? constructionSite.payment_transactions.length
            : 1
        }
        selectionMode={SelectionMode.single}
        onRenderCheck={onRenderCheckForFooterRow}
        styles={{
          root: {
            fontWeight: 'bold',
            backgroundColor: 'rgb(220 220 220)',
          },
        }}
      />
    );
  };

  const onRenderDetailsFooterContractUpdates = (
    detailsFooterProps?: IDetailsFooterProps,
  ): JSX.Element => {
    if (!detailsFooterProps) return <>nothing</>;
    const item: ICustomerContractUpdateCalculated = {};
    if (
      constructionSite &&
      constructionSite.customer_contract_updates &&
      constructionSite.customer_contract_updates.length > 0
    ) {
      let total = 0;
      for (
        let i = 0;
        i < constructionSite.customer_contract_updates.length;
        i++
      ) {
        total += constructionSite.customer_contract_updates![i]
          .customer_contract_update_line_items
          ? constructionSite.customer_contract_updates![
              i
            ].customer_contract_update_line_items!.reduce(
              (total, item) =>
                parseNumber(item.units) * parseNumber(item.unit_price) + total,
              0,
            )
          : 0;
      }
      item.summary = 'Totaal';
      item.calculatedTotal = total;
    }
    return (
      <DetailsRow
        {...detailsFooterProps}
        columns={detailsFooterProps.columns}
        item={item}
        itemIndex={
          constructionSite.customer_contract_updates
            ? constructionSite.customer_contract_updates.length
            : 1
        }
        selectionMode={SelectionMode.single}
        onRenderCheck={onRenderCheckForFooterRow}
        styles={{
          root: {
            fontWeight: 'bold',
            backgroundColor: 'rgb(220 220 220)',
          },
        }}
      />
    );
  };

  // ====================================================

  const [supplierSearch, setSupplierSearch] = useState<string | undefined>();
  const debouncedSearchTerm = useDebounce(supplierSearch, 500);

  const supplierQuery = useCallback(() => {
    const query: any = {
      orderBy: {
        name: 'asc',
      },
      filter: debouncedSearchTerm
        ? {
            OR: [
              { name: { contains: debouncedSearchTerm } },
              { city: { contains: debouncedSearchTerm } },
              { email: { contains: debouncedSearchTerm } },
              { phone_v2: { contains: debouncedSearchTerm } },
            ],
          }
        : undefined,
      take: 15,
    };

    return query;
  }, [debouncedSearchTerm]);

  const {
    loading: loadingTemplates,
    error: errorTemplates,
    data: dataPTemplates,
  } = useQuery(GET_PURCHASE_ORDER_TEMPLATES_ALL_IN, {
    variables: {
      orderBy: {
        name: 'asc',
      },
    },
    skip: !selectionPurchaseOrder && !isPurchaseOrderPanelOpen,
  });

  const {
    loading: loadingMeasuringUnit,
    error: errorMeasuringUnit,
    data: dataMeasuringUnits,
  } = useQuery(GET_MEASURING_UNITS, {
    variables: {
      orderBy: {
        name: 'asc',
      },
    },
  });

  const {
    loading: loadingSuppliers,
    error: errorSuppliers,
    data: dataSuppliers,
  } = useQuery(GET_SUPPLIERS_WITH_CONTACT_PERSONS, {
    variables: {
      ...supplierQuery(),
    },
    skip: !selectionPurchaseOrder && !isPurchaseOrderPanelOpen,
  });

  const { data: dataStatuses, error: errorStatuses } = useQuery(
    GET_PURCHASE_ORDER_STATUSES,
    {
      skip: !selectionPurchaseOrder && !isPurchaseOrderPanelOpen,
    },
  );

  const [getPurchaseOrderByID] = useLazyQuery(GET_PURCHASE_ORDER_BY_ID, {
    onCompleted: (x: any) => {
      if (x && x.findOnePurchaseOrder) {
        let purchaseOrder: IPurchaseOrder = x.findOnePurchaseOrder;
        purchaseOrder = sortLineItems(purchaseOrder);
        // setPurchaseOrder(purchaseOrder);
      }
    },
  });

  const downloadPDF = () => {
    if (purchaseOrder && purchaseOrder.id) {
      getPurchaseOrderByID({
        variables: {
          where: {
            id: purchaseOrder.id,
          },
        },
      }).then((x: any) => {
        if (x && x.data && x.data.findOnePurchaseOrder) {
          let purchaseOrder: IPurchaseOrder = x.data.findOnePurchaseOrder;
          purchaseOrder = sortLineItems(purchaseOrder);
          // setPurchaseOrder(purchaseOrder);
          purchaseOrderDownload(purchaseOrder);
        }
      });
    }
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      //
      headerText={
        constructionSite && constructionSite.id
          ? 'Wet Breyne informatie'
          : 'Werf toevoegen'
      }
      //
      type={PanelType.custom}
      customWidth='1200px'
      styles={{
        root: {
          zIndex: 8000,
        },
        ...customPanelStyles,
      }}
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {constructionSite && constructionSite.id && (
            <Label>ID: {constructionSite.id}</Label>
          )}

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <StackItem style={{ width: '48%' }}>
              {/* <h3 style={{ marginTop: 0, marginBottom: 10 }}>
                Algemene informatie
              </h3> */}
              <Stack>
                <Stack
                  style={{
                    marginBottom: 10,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <StackItem style={{ width: '100%' }}>
                    <TextField
                      name='name'
                      label='Naam'
                      value={
                        constructionSite && constructionSite.name
                          ? constructionSite.name
                          : ''
                      }
                      disabled
                      onChange={onChangeTextFieldValue}
                      required
                      errorMessage={
                        constructionSite && !constructionSite.name
                          ? 'Dit veld is verplicht'
                          : ''
                      }
                    />
                    {constructionSite && constructionSite.lead && (
                      <a
                        href={`/construction-sites/${constructionSite.id}`}
                        target='_blank'
                        rel='noreferrer'
                        style={{ marginTop: '5px', color: '#71BF43' }}
                      >
                        werffiche openen
                      </a>
                    )}
                  </StackItem>
                </Stack>

                {/*
                <ComboBox
                  label='Sjabloon'
                  onSelect={(
                    keys: string | string[] | number | undefined,
                    value: string | undefined,
                  ) => {
                    if (keys && !Array.isArray(keys)) {
                      updateConstructionSiteNumberValue(keys, 'template');
                    }
                  }}
                  options={addEmptyOptionToTemplates()}
                  //allowFreeForm
                  selectedKey={getTemplateId()}
                />
                */}

                <Stack style={{ marginBottom: 5 }}>
                  <TextField
                    name='wings_account_code'
                    label='Analytische code'
                    value={
                      constructionSite &&
                      (constructionSite as any).wings_account_code
                        ? (
                            constructionSite as any
                          ).wings_account_code.toString()
                        : ''
                    }
                    disabled
                  />
                </Stack>
                <Stack style={{ marginBottom: 5 }}>
                  <TextField
                    name='contract_amount'
                    label='Contractbedrag'
                    disabled
                    value={
                      constructionSite && constructionSite.contract_amount
                        ? constructionSite.contract_amount.toString()
                        : ''
                    }
                    prefix='€'
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>
                <Stack style={{ marginBottom: 5 }}>
                  <TextField
                    name='address'
                    label='Adres'
                    disabled
                    value={
                      constructionSite && constructionSite.address
                        ? constructionSite.address
                        : ''
                    }
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>

                <Stack
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 10,
                  }}
                >
                  <StackItem style={{ width: '25%' }}>
                    <TextField
                      name='zip_code'
                      disabled
                      value={
                        constructionSite && constructionSite.zip_code
                          ? constructionSite.zip_code.toString()
                          : ''
                      }
                      onChange={onChangeTextFieldValue}
                    />
                  </StackItem>
                  <StackItem style={{ width: '74%' }}>
                    <TextField
                      name='city'
                      disabled
                      value={
                        constructionSite && constructionSite.city
                          ? constructionSite.city
                          : ''
                      }
                      onChange={onChangeTextFieldValue}
                    />
                  </StackItem>
                </Stack>
              </Stack>
            </StackItem>

            <StackItem style={{ width: '48%' }}>
              <Stack
                style={{
                  marginBottom: 10,
                }}
              >
                <ComboboxWithFilter
                  label='Klant'
                  options={leads ? convertLeadsToComboBoxOptions(leads) : []}
                  value={
                    constructionSite && constructionSite.lead
                      ? constructionSite.lead.id
                      : ''
                  }
                  disabled
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      const result = { ...constructionSite };

                      // get lead
                      const lead = leads.find(
                        lead => lead.id === newValue[0].key,
                      );
                      if (lead?.sales_rep_employee?.id) {
                        result.sales_rep = {
                          ...result.sales_rep!,
                          id: lead.sales_rep_employee.id,
                        };
                      }

                      result.lead = {
                        ...result.lead!,
                        id: newValue[0].key as number,
                      };

                      result.updated = true;

                      setConstructionSite(result);
                    }
                  }}
                  required
                  allowFreeForm
                  errorMessage={
                    constructionSite && !constructionSite.lead
                      ? 'Dit veld is verplicht'
                      : ''
                  }
                  setFilter={(value: string) => {
                    setLeadFilter(value);
                  }}
                />
                {constructionSite && constructionSite.lead && (
                  <a
                    href={`/customers/${constructionSite.lead.id}`}
                    target='_blank'
                    rel='noreferrer'
                    style={{ marginTop: '5px', color: '#71BF43' }}
                  >
                    klantenfiche openen
                  </a>
                )}
              </Stack>
              <Stack style={{ marginBottom: 10 }}>
                <DateView
                  item={constructionSite}
                  setItem={(item: IConstructionSiteCreateUpdate) => {
                    item.updated = true;
                    setConstructionSite(item);
                  }}
                  disabled
                  date={constructionSite.date_sold}
                  label='Datum verkoop'
                  field='date_sold'
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <DateView
                  item={constructionSite}
                  setItem={(item: IConstructionSiteCreateUpdate) => {
                    item.updated = true;
                    setConstructionSite(item);
                  }}
                  disabled
                  date={constructionSite.date_of_contract}
                  label='Datum contract'
                  field='date_of_contract'
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <DateView
                  item={constructionSite}
                  setItem={(item: IConstructionSiteCreateUpdate) => {
                    item.updated = true;
                    setConstructionSite(item);
                  }}
                  disabled
                  date={constructionSite && constructionSite.date_completed}
                  label='Datum oplevering'
                  field='date_completed'
                />
              </Stack>
            </StackItem>
          </Stack>
          <Stack
            // onClick={() => toggleItem(id)}
            styles={{
              root: {
                background: theme.palette.neutralLight,
                padding: '10px',
                marginTop: '15px',
                marginBottom: '15px',
                color: 'black',
                fontSize: '16px',
                fontWeight: 'bold',
                h3: {
                  margin: 0,
                },
              },
            }}
            horizontal
            horizontalAlign='space-between'
            style={{ marginBottom: '0px' }}
          >
            <Stack.Item>
              <h3>Wet Breyne Informatie</h3>
            </Stack.Item>
          </Stack>
          <br />
          {/*
          wet breyne dates
          */}
          <StackItem>
            <Stack horizontal>
              <Stack.Item style={{ width: '20%' }}>
                <Stack horizontal tokens={stackTokens5}>
                  <Toggle
                    label='Wet Breyne van toepassing'
                    checked={constructionSite.breyne_applicable}
                    onChange={(e, checked) => {
                      setConstructionSite({
                        ...constructionSite,
                        breyne_applicable: !!checked,
                      });
                    }}
                  />
                </Stack>
              </Stack.Item>
              <Stack.Item style={{ width: '40%' }}>
                <DateView
                  item={constructionSite}
                  setItem={(item: IConstructionSiteCreateUpdate) => {
                    item.updated = true;
                    setConstructionSite(item);
                  }}
                  date={constructionSite.breyne_date_provisional_delivery}
                  label='Voorlopige oplevering'
                  field='breyne_date_provisional_delivery'
                />
              </Stack.Item>
              <Stack.Item style={{ width: '40%', marginLeft: '10px' }}>
                <DateView
                  item={constructionSite}
                  setItem={(item: IConstructionSiteCreateUpdate) => {
                    item.updated = true;
                    setConstructionSite(item);
                  }}
                  date={constructionSite.breyne_date_final_delivery}
                  label='Definitieve oplevering'
                  field='breyne_date_final_delivery'
                />
              </Stack.Item>
            </Stack>
          </StackItem>
          {/*
          wet breyne dates
          */}

          {showDeletePaymentTransactionDialog && customerPaymentTransaction && (
            <Dialog
              hidden={!showDeletePaymentTransactionDialog}
              onDismiss={() => {
                setShowDeletePaymentTransactionDialog(false);
              }}
              dialogContentProps={{
                type: DialogType.normal,
                title: 'Betalingsschijf verwijderen',
                closeButtonAriaLabel: 'Close',
              }}
            >
              <p>
                <strong>Ben je zeker dat je deze lijn wil verwijderen?</strong>
              </p>
              <p>{customerPaymentTransaction.description}</p>
              <p>Deze actie kan niet ongedaan gemaakt worden.</p>
              <DialogFooter>
                <PrimaryButton
                  onClick={() => {
                    deleteSelectedPaymentTransaction();
                  }}
                >
                  Verwijderen{' '}
                  {deletingPaymentTransaction && (
                    <Spinner size={SpinnerSize.small} />
                  )}
                </PrimaryButton>
                <DefaultButton
                  text='Annuleren'
                  onClick={() => {
                    setShowDeletePaymentTransactionDialog(false);
                  }}
                />
              </DialogFooter>
            </Dialog>
          )}

          {showDeletePurchaseOrderDialog && purchaseOrder && (
            <Dialog
              hidden={!showDeletePurchaseOrderDialog}
              onDismiss={() => {
                setShowDeletePaymentTransactionDialog(false);
              }}
              dialogContentProps={{
                type: DialogType.normal,
                title: 'Bestelbon verwijderen',
                closeButtonAriaLabel: 'Close',
              }}
            >
              <p>
                <strong>
                  Ben je zeker dat je deze bestelbon wil verwijderen?
                </strong>
              </p>
              <p>{purchaseOrder.description}</p>
              <p>Deze actie kan niet ongedaan gemaakt worden.</p>
              <DialogFooter>
                <PrimaryButton
                  onClick={() => {
                    deleteSelectedPurchaseOrder();
                  }}
                >
                  Verwijderen{' '}
                  {deletingPaymentTransaction && (
                    <Spinner size={SpinnerSize.small} />
                  )}
                </PrimaryButton>
                <DefaultButton
                  text='Annuleren'
                  onClick={() => {
                    setShowDeletePurchaseOrderDialog(false);
                  }}
                />
              </DialogFooter>
            </Dialog>
          )}
        </div>
        <div className={modalContentStyles.footer}>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <SaveButtonWithPermissions
              save={saveConstructionSite}
              permission='write:constructionSites'
              loading={false}
            />

            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default ConstructionSiteDetail;
