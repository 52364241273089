import { gql } from '@apollo/client';
import { IAppUser } from './AppUser';
import { IEmployee } from './Employee';
import { ILead } from './Lead';

export interface ILogItem {
  id: number;
  action_required: boolean;
  action_required_by: Date;
  date_created: Date;
  last_updated: Date;
  last_updated_by_id: number;
  lead_id: number;
  text: string;
  title: string;
  version: number;
  app_user: IAppUser;
  lead: ILead;
  sales_rep: IEmployee;
  prisma_total?: number;
}

export const GET_LOG_ITEMS = gql`
  query getLogItems(
    $filter: LogItemFilterInput
    $orderBy: LogItemOrderByInput
    $take: Int
    $skip: Int
  ) {
    findManyLogItems(
      filter: $filter
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      prisma_total
      id
      title
      text
      action_required
      action_required_by
      last_updated_by_id
      last_updated
      app_user {
        id
        username
        employee {
          first_name
          last_name
        }
      }
      sales_rep {
        id
        first_name
        last_name
      }
      lead {
        first_name1
        first_name2
        last_name1
        last_name2
      }
    }
  }
`;

export const GET_LOG_ITEM_BY_ID = gql`
  query getLogItemById($where: LogItemWhereInput) {
    findOneLogItem(where: $where) {
      id
      title
      text
      action_required
      action_required_by
      last_updated_by_id
      last_updated
      app_user {
        id
        username
      }
      sales_rep {
        id
        first_name
        last_name
      }
      lead {
        id
        first_name1
        first_name2
        last_name1
        last_name2
      }
    }
  }
`;

export const ADD_LOG_ITEM = gql`
  mutation AddLogItem($data: LogItemCreateInput!) {
    createLogItem(data: $data) {
      id
      title
      text
      action_required
      action_required_by
      last_updated_by_id
      last_updated
      app_user {
        id
        username
      }
      sales_rep {
        id
        first_name
        last_name
      }
      lead {
        first_name1
        first_name2
        last_name1
        last_name2
      }
    }
  }
`;

export const UPDATE_LOG_ITEM = gql`
  mutation UpdateLogItem($id: Int!, $data: LogItemUpdateInput!) {
    updateLogItem(id: $id, data: $data) {
      id
      title
      text
      action_required
      action_required_by
      last_updated_by_id
      last_updated
      app_user {
        id
        username
      }
      sales_rep {
        id
        first_name
        last_name
      }
      lead {
        first_name1
        first_name2
        last_name1
        last_name2
      }
    }
  }
`;

export const DELETE_LOG_ITEM = gql`
  mutation deleteLogItem($where: LogItemWhereInput) {
    deleteLogItem(where: $where) {
      id
    }
  }
`;
