import { useState, useEffect, useCallback } from 'react';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  Stack,
} from '@fluentui/react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useBoolean } from '@fluentui/react-hooks';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { commandBarTheme, commandBarThemeCounter } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  GET_EMPLOYEES,
} from '../../../utils';
import { useAppDispatch } from '../../../redux/hooks';
import { toastError, toastSuccess } from '../../../utils/toast';
import {
  ADD_TEAM,
  GET_TEAM_BY_ID,
  ITeam,
  UPDATE_TEAM,
} from '../../../utils/Team';
import TeamDetail from './TeamDetail';
import TeamsDetailsList from './TeamsDetailsList';

interface Props {
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
  teams: ITeam[];
  // filter,
  // filterChange?: (filter: string) => void,
  setSorting: (isSortedAsc: boolean, sortedField: string) => void;
  isSortedAsc?: boolean;
  sortedField?: string;
  showLoadMore?: boolean;
  refetch?: any;
}

const TeamsOverview = ({
  teams,
  // filter,
  loading,
  // filterChange = (filter: string) => {},
  loadMore,
  setSorting = (isSortedAsc: boolean, sortedField: string) => {},
  isSortedAsc = false,
  sortedField = '',
  showLoadMore,
  lazyLoading,
  loadMoreCallback = () => ({}),
  refetch,
}: Props) => {
  const dispatch = useAppDispatch();
  const [team, setTeam] = useState<ITeam | undefined>(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [deleteDialogOpen, { toggle: toggleDelete }] = useBoolean(false);
  const [employees, setEmployees] = useState([]);

  const [addTeam] = useMutation(ADD_TEAM, {
    // refetchQueries: [{ query: GET_CARS }],
    // ADD ITEM TO LIST IN CACHE, BUG -> MERGE DOES NOT REPLACE THE INCOMMING 'UPDATED' LIST WITH existing list. (apollo cache in app.tsx);
    /* fetchPolicy: 'no-cache',
    update(cache, { data }) {
      try {
        const createdCar = data.createCar;
        const existingCars = cache.readQuery<{
          findManyCars: ICar[];
        }>({
          query: GET_CARS,
          variables: listFilters,
        });

        const findManyCars = existingCars
          ? _.cloneDeep(existingCars?.findManyCars)
          : null;

        console.log(findManyCars);
        console.log(createdCar);

        if (findManyCars && createdCar) {
          console.log('befor new car', findManyCars);
          findManyCars.unshift(createdCar);
          console.log('after new car', findManyCars);
          cache.writeQuery({
            query: GET_CARS,
            variables: listFilters,
            data: {
              findManyCars,
            },
          });
        }
      } catch (error) {
        console.log('error', error);
      }
    }, */
  });

  const [modifyTeam] = useMutation(UPDATE_TEAM, {
    // no need for writeQuery since apollo updates cache automatically when executing a update mutation
  });

  useQuery(GET_EMPLOYEES, {
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      setEmployees(x.findManyEmployees);
    },
  });

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<
    ITeam[] | undefined
  >();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();
    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const openTeamDetail = (newTeam?: boolean) => {
    if (!selectionDetails && newTeam) {
      setTeam(undefined);
    }
    setIsPanelOpen(true);
  };

  const { refetch: refetchTeam } = useQuery(GET_TEAM_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables:
      selectionDetails && selectionDetails.length > 0
        ? { where: { id: selectionDetails[0].id } }
        : undefined,
    skip: !selectionDetails || selectionDetails.length > 1,
    onCompleted: data => {
      setTeam(data.findOneTeam);
    },
  });

  const saveCar = async () => {
    try {
      if (team) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              name: team.name,
              active: team.active,
              description: team.description,
            };

            if (team.id) {
              modifyTeam({
                variables: {
                  id: team.id,
                  data: allInput,
                },
                onError: error => {
                  setIsPanelOpen(false);
                  reject(error);
                },
                onCompleted: (x: any) => {
                  setIsPanelOpen(false);
                  resolve(x);
                  toastSuccess('Ploeg gewijzigd');
                },
              });
            } else {
              addTeam({
                variables: {
                  data: allInput,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: (x: any) => {
                  if (x && x.createTeam) setTeam(x.createTeam);
                  resolve(x);
                  refetch();
                  toastSuccess('Ploeg toegevoegd');
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  const deleteSelection = useCallback(async () => {
    // delete cars after confirmation
    if (!selectionDetails) {
      toggleDelete();
    }
  }, [selectionDetails]);

  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'totaal',
      text: `${teams.length} / ${teams.length > 0 ? teams[0].prisma_total : 0}`,
      theme: commandBarThemeCounter,
    },
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => {
        openTeamDetail(true);
      },
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openTeamDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails || selectionDetails.length > 1,
    },
  ];

  useEffect(() => {
    if (team && isPanelOpen) {
      document.title = `3bouw | Team - ${team.name}`;
    } else {
      document.title = "3bouw | Team's";
    }
  }, [team, isPanelOpen]);

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        maxWidth='2000px'
      />
      {isPanelOpen && (
        <TeamDetail
          isOpen={isPanelOpen}
          dismissPanel={() => {
            setIsPanelOpen(false);
          }}
          team={team || {}}
          saveTeam={saveCar}
          setTeam={setTeam}
          refetchTeam={refetchTeam}
          employees={employees}
          refetch={refetch}
        />
      )}
      {deleteDialogOpen && selectionDetails && selectionDetails.length > 0 && (
        <Dialog
          hidden={!deleteDialogOpen}
          onDismiss={toggleDelete}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Ploegen verwijderen',
            closeButtonAriaLabel: 'Close',
          }}
        >
          <p>
            <strong>
              Ben je zeker dat je de volgende ploegen wil verwijderen?
            </strong>
          </p>
          <p>
            <ul>
              {selectionDetails.map(team => (
                <li>{team.name}</li>
              ))}
            </ul>
          </p>
          <p>Deze actie kan niet ongedaan gemaakt worden.</p>
          <DialogFooter>
            <PrimaryButton text='Verwijderen' onClick={deleteSelection} />
            <DefaultButton text='Annuleren' onClick={toggleDelete} />
          </DialogFooter>
        </Dialog>
      )}

      <TeamsDetailsList
        items={teams}
        selection={selection}
        enableShimmer={!loading}
        isSortedAsc={isSortedAsc}
        sortedField={sortedField}
        setSorting={setSorting}
        loadMore={loadMore}
        lazyLoading={lazyLoading}
        loadMoreCallback={loadMoreCallback}
        loading={loading}
        onItemInvoked={() => openTeamDetail(false)}
      />

      {teams && (
        <>
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton
                text='Toon meer'
                onClick={() => loadMoreCallback()}
              />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {!loading && !showLoadMore && teams.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default TeamsOverview;
