import React, { useCallback, useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  Stack,
  Label,
  StackItem,
  TextField,
  IComboBoxOption,
  ShimmeredDetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Checkbox,
  Selection,
  DialogType,
  Dialog,
  DialogFooter,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  IDetailsFooterProps,
  DetailsRow,
  IDetailsRowBaseProps,
  DetailsRowCheck,
  getDetailsRowCheckStyles,
  IDetailsRowCheckStyles,
} from '@fluentui/react';
import _ from 'lodash';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  convertConstructionSitesToComboBoxOptions,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  GET_CONSTRUCTION_SITES,
  IConstructionSite,
  modalContentStyles,
} from '../../../utils';
import {
  ComboboxWithFilter,
  CommandBarSticky,
} from '../../../components/parts';
import { Accordion, AccordionItem } from '../../../components/parts/Accordion';
import {
  ADD_CUSTOMER_CONTRACT_UPDATE,
  GET_CUSTOMER_CONTRACT_UPDATE_BY_ID,
  ICustomerContractUpdate,
  SPLIT_CUSTOMER_CONTRACT_UPDATE,
  UPDATE_CUSTOMER_CONTRACT_UPDATE,
} from '../../../utils/CustomerContractUpdate';
import {
  ADD_CUSTOMER_CONTRACT_UPDATE_LINE_ITEM,
  ICustomerContractUpdateLineItem,
  UPDATE_CUSTOMER_CONTRACT_UPDATE_LINE_ITEM,
  DELETE_CUSTOMER_CONTRACT_UPDATE_LINE_ITEM,
} from '../../../utils/CustomerContractUpdateLineItem';
import { commandBarTheme, customPanelStyles } from '../../../theme';
import useDebounce from '../../../components/hooks/useDebounce';
import {
  GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
  IConstructionSiteCreateUpdate,
} from '../../../utils/ConstructionSite';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import { ICustomerInvoice } from '../../../utils/CustomerInvoice';
import parseNumber from '../../../utils/Numbers';
import { toastSuccess, toastError } from '../../../utils/toast';
import { ITaxCode } from '../../../utils/TaxCode';
import ContractUpdateDetail from './ContractUpdateDetail';
import CustomerContractUpdateDynamicPdf from './CustomerContractUpdateDynamicPdf';
import PaymentList from './PaymentList';

type Props = {
  isOpen: boolean;
  dismissPanel: () => void;
  customerContractUpdateSource: ICustomerContractUpdate;
  constructionSite?: IConstructionSite | IConstructionSiteCreateUpdate;
  skipFetchConstructionSites?: boolean;
  taxCodes: ITaxCode[];
};

interface ICustomerContractUpdateLineItemCalculated
  extends ICustomerContractUpdateLineItem {
  calculatedTotal?: number;
  calculatedTotalIncludingVat?: number;
}

const CustomerContractUpdateDetail = ({
  isOpen,
  dismissPanel,
  customerContractUpdateSource,
  constructionSite,
  skipFetchConstructionSites,
  taxCodes,
}: Props) => {
  const [customerContractUpdate, setCustomerContractUpdate] = useState(
    customerContractUpdateSource,
  );

  const [showPdf, setShowPdf] = useState(false);

  const [showDeleteLineItemDialog, setShowDeleteLineItemDialog] =
    useState(false);

  const { data, loading, refetch } = useQuery(
    GET_CUSTOMER_CONTRACT_UPDATE_BY_ID,
    {
      variables: customerContractUpdateSource &&
        customerContractUpdateSource.id && {
          where: { id: customerContractUpdateSource.id },
        },
      skip: !customerContractUpdateSource || !customerContractUpdateSource.id,
      onCompleted: data => {
        setCustomerContractUpdate(data.findOneCustomerContractUpdate);
      },
    },
  );

  useQuery(GET_CUSTOMER_CONTRACT_UPDATE_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: {
      where: {
        id: customerContractUpdateSource.id,
      },
    },
    skip: customerContractUpdateSource.id === undefined,
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setCustomerContractUpdate(data.findOneCustomerContractUpdate);
    },
  });

  // Save contract update
  const [addCustomerContractUpdate] = useMutation(
    ADD_CUSTOMER_CONTRACT_UPDATE,
    {
      refetchQueries: [
        {
          query: GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
          variables: {
            where: {
              id:
                skipFetchConstructionSites &&
                constructionSite &&
                constructionSite.id
                  ? constructionSite.id
                  : customerContractUpdate.construction_site
                  ? customerContractUpdate.construction_site.id
                  : undefined,
            },
          },
        },
      ],
    },
  );

  const [modifyCustomerContractUpdate] = useMutation(
    UPDATE_CUSTOMER_CONTRACT_UPDATE,
    {
      refetchQueries: [
        {
          query: GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
          variables: {
            where: {
              id:
                skipFetchConstructionSites &&
                constructionSite &&
                constructionSite.id
                  ? constructionSite.id
                  : customerContractUpdate.construction_site
                  ? customerContractUpdate.construction_site.id
                  : undefined,
            },
          },
        },
      ],
    },
  );

  const [splitCustomerContractUpdate] = useMutation(
    SPLIT_CUSTOMER_CONTRACT_UPDATE,
    {
      refetchQueries: [
        {
          query: GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
          variables: {
            where: {
              id:
                skipFetchConstructionSites &&
                constructionSite &&
                constructionSite.id
                  ? constructionSite.id
                  : customerContractUpdate.construction_site
                  ? customerContractUpdate.construction_site.id
                  : undefined,
            },
          },
        },
      ],
    },
  );

  const [deleteLineItem, { loading: deletingLineItem }] = useMutation(
    DELETE_CUSTOMER_CONTRACT_UPDATE_LINE_ITEM,
    {
      refetchQueries: [
        {
          query: GET_CUSTOMER_CONTRACT_UPDATE_BY_ID,
          variables:
            customerContractUpdateSource && customerContractUpdateSource.id
              ? {
                  where: { id: customerContractUpdateSource.id },
                }
              : undefined,
        },
      ],
    },
  );

  const saveCustomerContractUpdate = async () => {
    try {
      if (customerContractUpdate) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const constructionSiteID =
              skipFetchConstructionSites &&
              constructionSite &&
              constructionSite.id
                ? constructionSite.id
                : customerContractUpdate.construction_site
                ? customerContractUpdate.construction_site.id
                : undefined;

            if (!constructionSiteID) return; // notification
            const allInput = {
              ...customerContractUpdate,
              status: customerContractUpdate.status || 'NEW',
              construction_site: {
                connect: {
                  id: constructionSiteID,
                },
              },
              __typename: undefined,
              id: undefined,
              customer_contract_update_line_items: undefined,
              customer_invoice_contract_updates: undefined,
              customer_invoice_id: undefined,
              customer_invoice_split_customer_contract_updates: undefined,
            };

            if (customerContractUpdate.id) {
              modifyCustomerContractUpdate({
                variables: {
                  id: customerContractUpdate.id,
                  data: allInput,
                },
                onError: error => {
                  setConstructionSiteFilter('');
                  dismissPanel();
                  reject(error);
                },
                onCompleted: x => {
                  setConstructionSiteFilter('');
                  dismissPanel();
                  resolve(x);
                },
              });
            } else {
              addCustomerContractUpdate({
                variables: {
                  data: allInput,
                },
                onError: error => {
                  setConstructionSiteFilter('');
                  reject(error);
                },
                onCompleted: x => {
                  setConstructionSiteFilter('');
                  resolve(x);
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        const x = await (res as any);
        setCustomerContractUpdate(x.createCustomerContractUpdate);
        toastSuccess('Min-/meerwerk opgeslagen');
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  const splitupCustomerContractUpdate = async () => {
    try {
      if (customerContractUpdate) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            if (customerContractUpdate.id) {
              splitCustomerContractUpdate({
                variables: {
                  id: customerContractUpdate.id,
                },
                onError: error => {
                  setConstructionSiteFilter('');
                  reject(error);
                },
                onCompleted: async x => {
                  setConstructionSiteFilter('');
                  await refetch({
                    where: {
                      id: customerContractUpdate.id!,
                    },
                  });
                  resolve(x);
                  toastSuccess('Min-/meerwerk opgeslagen');
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  const [addContractUpdateDetail] = useMutation(
    ADD_CUSTOMER_CONTRACT_UPDATE_LINE_ITEM,
  );

  const [modifyContractUpdateDetail] = useMutation(
    UPDATE_CUSTOMER_CONTRACT_UPDATE_LINE_ITEM,
  );

  const saveCustomerContractUpdateLine = async () => {
    try {
      if (contractUpdateDetail && customerContractUpdate.id) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            if (contractUpdateDetail && contractUpdateDetail.id) {
              modifyContractUpdateDetail({
                variables: {
                  id: contractUpdateDetail.id,
                  data: {
                    ...contractUpdateDetail,
                    percentage_vat: parseNumber(
                      contractUpdateDetail?.tax_code?.rate
                        ? contractUpdateDetail?.tax_code?.rate
                        : contractUpdateDetail.percentage_vat,
                    ),
                    unit_price: parseNumber(contractUpdateDetail.unit_price),
                    units: parseNumber(contractUpdateDetail.units),
                    tax_code: {
                      connect: {
                        id: contractUpdateDetail?.tax_code?.id,
                      },
                    } as any,
                    customer_contract_update: {
                      connect: {
                        id: customerContractUpdate.id,
                      },
                    },
                    id: undefined, // remove id
                    __typename: undefined, // if not removed gql sends error?
                  },
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: async x => {
                  await refetch({
                    where: {
                      id: customerContractUpdate.id!,
                    },
                  });
                  resolve(x);
                  toastSuccess('Detaillijn gewijzigd');
                },
              }).then(() => {
                setIsContractUpdateDetailPanelOpen(false);
              });
            } else {
              addContractUpdateDetail({
                variables: {
                  data: {
                    ...contractUpdateDetail,
                    percentage_vat: parseNumber(
                      contractUpdateDetail.percentage_vat,
                    ),
                    units: parseNumber(contractUpdateDetail.units, 1),
                    unit_price: parseNumber(contractUpdateDetail.unit_price),
                    customer_contract_update: {
                      connect: {
                        id: customerContractUpdate.id,
                      },
                    },
                    tax_code: {
                      connect: {
                        id: contractUpdateDetail?.tax_code?.id,
                      },
                    } as any,
                  },
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: async x => {
                  await refetch({
                    where: {
                      id: customerContractUpdate.id!,
                    },
                  });
                  resolve(x);
                  toastSuccess('Detaillijn gewijzigd');
                },
              }).then(() => {
                setIsContractUpdateDetailPanelOpen(false);
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setCustomerContractUpdate((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  // contract update detail
  const [isContractUpdateDetailPanelOpen, setIsContractUpdateDetailPanelOpen] =
    useState(false);
  const [selectedValueItem, setSelectedValueItem] = useState<
    number | string | undefined
  >();

  // Selection line items
  const [contractUpdateDetail, setContractUpdateDetail] = useState<
    ICustomerContractUpdateLineItem | undefined
  >();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setContractUpdateDetail(currentSelection[0]);
    } else {
      setContractUpdateDetail(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const openLineItemDetail = (newLineItem?: boolean) => {
    if (newLineItem) {
      setContractUpdateDetail({});
    }
    setIsContractUpdateDetailPanelOpen(true);
  };

  const deleteSelection = useCallback(async () => {
    try {
      if (contractUpdateDetail) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            deleteLineItem({
              variables: {
                where: {
                  id: contractUpdateDetail.id,
                },
              },
              onError: error => {
                reject(error);
              },
              onCompleted: x => {
                resolve(x);
              },
            });
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
        setShowDeleteLineItemDialog(false);
        toastSuccess('Detaillijn verwijderd');
      }
    } catch (error: any) {
      setShowDeleteLineItemDialog(false);
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  }, [contractUpdateDetail]);

  const [constructionSiteFilter, setConstructionSiteFilter] = useState('');
  const debouncedFilter = useDebounce(constructionSiteFilter, 500);

  const { data: constructionSitesData } = useQuery(GET_CONSTRUCTION_SITES, {
    fetchPolicy: 'no-cache',
    variables: {
      take: 20,
      filter: {
        name: {
          contains: debouncedFilter,
        },
      },
    },
    skip: !!skipFetchConstructionSites,
  });

  const constructionSites =
    constructionSitesData && constructionSitesData.findManyConstructionSites;

  const disabled =
    (data &&
      data.findOneCustomerContractUpdate &&
      data.findOneCustomerContractUpdate.customer_invoice_contract_updates &&
      data.findOneCustomerContractUpdate.customer_invoice_contract_updates
        .length > 0) ||
    (data &&
      data.findOneCustomerContractUpdate &&
      data.findOneCustomerContractUpdate.split_payment === true &&
      data.findOneCustomerContractUpdate.split_payment_json &&
      data.findOneCustomerContractUpdate.status === 'INVOICED');

  const detailsRowCheckStyles: Partial<IDetailsRowCheckStyles> = {
    root: { visibility: 'hidden' },
  };

  const onRenderCheckForFooterRow: IDetailsRowBaseProps['onRenderCheck'] = (
    props,
  ): JSX.Element => (
    <DetailsRowCheck {...props} styles={getDetailsRowCheckStyles} />
  );

  const onRenderDetailsFooterContractUpdates = (
    detailsFooterProps?: IDetailsFooterProps,
  ): JSX.Element => {
    if (!detailsFooterProps) return <>nothing</>;
    const item: ICustomerContractUpdateLineItemCalculated = {};
    if (
      customerContractUpdate &&
      customerContractUpdate.customer_contract_update_line_items &&
      customerContractUpdate.customer_contract_update_line_items.length > 0
    ) {
      let total = 0;
      let totalIncludingVAT = 0;
      for (
        let i = 0;
        i < customerContractUpdate.customer_contract_update_line_items.length;
        i++
      ) {
        total +=
          parseNumber(
            customerContractUpdate.customer_contract_update_line_items![i]
              .unit_price,
          ) *
          parseNumber(
            customerContractUpdate.customer_contract_update_line_items![i]
              .units,
          );
        totalIncludingVAT +=
          parseNumber(
            customerContractUpdate.customer_contract_update_line_items![i]
              .unit_price,
          ) *
          parseNumber(
            customerContractUpdate.customer_contract_update_line_items![i]
              .units,
          ) *
          (parseNumber(
            customerContractUpdate.customer_contract_update_line_items![i]
              ?.tax_code?.rate
              ? customerContractUpdate.customer_contract_update_line_items![i]
                  ?.tax_code?.rate
              : customerContractUpdate.customer_contract_update_line_items![i]
                  ?.percentage_vat,
            100,
          ) /
            100 +
            1);
      }
      item.description = 'Totaal';
      item.calculatedTotal = total;
      item.calculatedTotalIncludingVat = totalIncludingVAT;
    }
    return (
      <DetailsRow
        {...detailsFooterProps}
        columns={detailsFooterProps.columns}
        item={item}
        itemIndex={
          customerContractUpdate.customer_contract_update_line_items
            ? customerContractUpdate.customer_contract_update_line_items.length
            : 1
        }
        selectionMode={SelectionMode.single}
        onRenderCheck={onRenderCheckForFooterRow}
        styles={{
          root: {
            fontWeight: 'bold',
            backgroundColor: 'rgb(220 220 220)',
          },
        }}
      />
    );
  };
  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        customerContractUpdate && customerContractUpdate.id
          ? `${
              customerContractUpdate.split_payment
                ? 'Gesplitst min-/meerwerk'
                : 'Min-/meerwerk wijzigen'
            }`
          : 'Min-/meerwerk toevoegen'
      }
      type={PanelType.custom}
      customWidth='1000px'
      styles={customPanelStyles}
    >
      {contractUpdateDetail && isContractUpdateDetailPanelOpen && (
        <ContractUpdateDetail
          isOpen={isContractUpdateDetailPanelOpen}
          dismissPanel={() => {
            setIsContractUpdateDetailPanelOpen(false);
          }}
          contractLine={contractUpdateDetail}
          setContractLine={setContractUpdateDetail}
          saveCustomerContractUpdateLine={saveCustomerContractUpdateLine}
          disabled={disabled}
          taxCodes={taxCodes}
        />
      )}
      {showDeleteLineItemDialog && contractUpdateDetail && (
        <Dialog
          hidden={!showDeleteLineItemDialog}
          onDismiss={() => {
            setShowDeleteLineItemDialog(false);
          }}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Detaillijn verwijderen',
            closeButtonAriaLabel: 'Close',
          }}
        >
          <p>
            <strong>Ben je zeker dat je deze lijn wil verwijderen?</strong>
          </p>
          <p>{contractUpdateDetail.description}</p>
          <p>Deze actie kan niet ongedaan gemaakt worden.</p>
          <DialogFooter>
            <PrimaryButton onClick={deleteSelection}>
              Verwijderen{' '}
              {deletingLineItem && <Spinner size={SpinnerSize.small} />}
            </PrimaryButton>
            <DefaultButton
              text='Annuleren'
              onClick={() => {
                setShowDeleteLineItemDialog(false);
              }}
            />
          </DialogFooter>
        </Dialog>
      )}

      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {customerContractUpdate && (
            <Label>ID: {customerContractUpdate.id}</Label>
          )}

          <Stack>
            <Stack style={{ marginBottom: 10 }}>
              <Label>Document no: {customerContractUpdate.document_no}</Label>
            </Stack>

            <Stack
              style={{ flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <StackItem style={{ width: '49%' }}>
                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    name='summary'
                    label='Omschrijving'
                    multiline
                    value={
                      customerContractUpdate && customerContractUpdate.summary
                        ? customerContractUpdate.summary
                        : ''
                    }
                    onChange={onChangeTextFieldValue}
                    required
                    disabled={disabled}
                  />
                </Stack>

                <Stack
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <ComboboxWithFilter
                    label='Werf'
                    options={convertConstructionSitesToComboBoxOptions(
                      skipFetchConstructionSites
                        ? [constructionSite]
                        : customerContractUpdate &&
                          customerContractUpdate.construction_site &&
                          constructionSites
                        ? [
                            ...constructionSites,
                            customerContractUpdate.construction_site,
                          ]
                        : constructionSites || [],
                    )}
                    allowFreeForm
                    value={
                      skipFetchConstructionSites &&
                      constructionSite &&
                      constructionSite.id
                        ? constructionSite.id
                        : customerContractUpdate &&
                          customerContractUpdate.construction_site
                        ? customerContractUpdate.construction_site.id
                        : ''
                    }
                    multiline={false}
                    callBack={(newValue: IComboBoxOption[]) => {
                      if (newValue && newValue.length > 0) {
                        const result = { ...customerContractUpdate };

                        result.construction_site = {
                          id: newValue[0].key as number,
                          name: newValue[0].text as any,
                        };

                        setCustomerContractUpdate(result);
                      }
                    }}
                    setFilter={(value: string) => {
                      setConstructionSiteFilter(value);
                    }}
                    required
                    disabled={disabled}
                  />
                </Stack>

                <Stack
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <ComboboxWithFilter
                    label='Status'
                    options={[
                      { key: 'NEW', text: 'Nieuw' },
                      { key: 'CANCELLED', text: 'Geannuleerd' },
                      { key: 'INVOICED', text: 'Op factuur' },
                      { key: 'COMPLETED', text: 'Afgesloten' },
                    ]}
                    value={
                      customerContractUpdate && customerContractUpdate.status
                        ? customerContractUpdate.status
                        : 'NEW'
                    }
                    multiline={false}
                    callBack={(newValue: IComboBoxOption[]) => {
                      if (newValue && newValue.length > 0) {
                        const result = { ...customerContractUpdate };

                        result.status = newValue[0].key as string;

                        setCustomerContractUpdate(result);
                      }
                    }}
                    disabled={disabled}
                  />
                </Stack>
              </StackItem>

              <StackItem style={{ width: '49%' }}>
                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    name='intro'
                    label='Inleidende tekst'
                    multiline
                    value={
                      customerContractUpdate && customerContractUpdate.intro
                        ? customerContractUpdate.intro
                        : ''
                    }
                    onChange={onChangeTextFieldValue}
                    disabled={disabled}
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    name='outro'
                    label='Afsluitende tekst'
                    multiline
                    value={
                      customerContractUpdate && customerContractUpdate.outro
                        ? customerContractUpdate.outro
                        : ''
                    }
                    disabled={disabled}
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>
              </StackItem>
            </Stack>
          </Stack>

          <Stack style={{ marginBottom: 10, marginTop: 30 }}>
            {customerContractUpdate && customerContractUpdate.id ? (
              <Accordion
                selectedKey={selectedValueItem}
                defaultKey={undefined}
                toggleItem={(key: string | number) => {
                  if (selectedValueItem === key) {
                    setSelectedValueItem(undefined);
                  } else setSelectedValueItem(key);
                }}
              >
                <AccordionItem
                  key='accordion-invoice-contract-updates'
                  id='accordion-invoice-contract-updates'
                  title={
                    <Stack>
                      <h3 style={{ marginTop: 0, marginBottom: 0 }}>Detail</h3>
                    </Stack>
                  }
                >
                  <CommandBarSticky
                    items={[
                      {
                        key: 'new',
                        text: 'Nieuw',
                        iconProps: { iconName: 'Add' },
                        onClick: () => openLineItemDetail(true),
                        theme: commandBarTheme,
                        disabled,
                      },
                      {
                        key: 'modify',
                        text: disabled ? 'Open' : 'Wijzig',
                        iconProps: { iconName: 'Edit' },
                        onClick: () => openLineItemDetail(),
                        theme: commandBarTheme,
                        disabled:
                          !contractUpdateDetail ||
                          !contractUpdateDetail.id ||
                          disabled,
                      },
                      {
                        key: 'delete',
                        text: 'Verwijderen',
                        iconProps: { iconName: 'Trash' },
                        onClick: () => setShowDeleteLineItemDialog(true),
                        theme: commandBarTheme,
                        disabled:
                          !contractUpdateDetail ||
                          !contractUpdateDetail.id ||
                          disabled,
                      },
                    ]}
                    theme={commandBarTheme}
                    width='1200px'
                    maxWidth='1200px'
                  />
                  <ShimmeredDetailsList
                    items={
                      customerContractUpdate &&
                      customerContractUpdate.customer_contract_update_line_items
                        ? customerContractUpdate.customer_contract_update_line_items
                        : []
                    }
                    columns={[
                      {
                        key: 'column1',
                        name: 'Omschrijving',
                        fieldName: 'description',
                        minWidth: 160,
                        maxWidth: 160,
                        isRowHeader: true,
                        onRender: (line_item: any) => (
                          <span>{line_item.description}</span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column2',
                        name: 'Aantal',
                        fieldName: 'units',
                        minWidth: 20,
                        maxWidth: 20,
                        isRowHeader: true,
                        onRender: (line_item: any) => (
                          <span>{line_item.units}</span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column3',
                        name: 'Eenheidsprijs',
                        fieldName: 'unit_price',
                        minWidth: 70,
                        maxWidth: 70,
                        isRowHeader: true,
                        onRender: (line_item: any) => {
                          if (!line_item.unit_price) return null;
                          return (
                            <span>
                              &euro;{' '}
                              {parseFloat(line_item.unit_price).toFixed(2)}
                            </span>
                          );
                        },
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column4',
                        name: 'BTW %',
                        fieldName: 'percentage_vat',
                        minWidth: 30,
                        maxWidth: 30,
                        isRowHeader: true,
                        onRender: (line_item: any) => {
                          // Use tax_code.rate if available, else fallback to percentage_vat, default to 0 if both are undefined
                          const vatRate =
                            line_item.tax_code?.rate ??
                            line_item.percentage_vat ??
                            0;

                          // If vatRate is effectively 0 (after fallbacks), return null to avoid rendering
                          if (vatRate === 0) return null;

                          return (
                            <span>
                              {vatRate < 1
                                ? `${(vatRate * 100).toFixed(2)}%` // Convert decimal to percentage and format to 2 decimal places
                                : `${vatRate}%`}{' '}
                            </span>
                          );
                        },

                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column5',
                        name: 'Totaal excl. BTW',
                        fieldName: 'excl_vat',
                        minWidth: 80,
                        maxWidth: 80,
                        isRowHeader: true,
                        onRender: (
                          line_item: ICustomerContractUpdateLineItemCalculated,
                        ) => {
                          if (line_item.calculatedTotal) {
                            return (
                              <span>&euro; {line_item.calculatedTotal}</span>
                            );
                          }
                          return (
                            <span>
                              &euro;{' '}
                              {(
                                parseNumber(line_item.unit_price) *
                                parseNumber(line_item.units)
                              ).toFixed(2)}
                            </span>
                          );
                        },
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column6',
                        name: 'Totaal incl. BTW',
                        fieldName: 'incl_vat',
                        minWidth: 80,
                        maxWidth: 80,
                        isRowHeader: true,
                        onRender: (
                          line_item: ICustomerContractUpdateLineItemCalculated,
                        ) => {
                          if (line_item.calculatedTotalIncludingVat) {
                            return (
                              <span>
                                &euro; {line_item.calculatedTotalIncludingVat}
                              </span>
                            );
                          }

                          // Determine VAT rate: Use tax_code.rate if available, else fallback to percentage_vat, default to 0 if both are undefined
                          const vatRate =
                            line_item.tax_code?.rate ??
                            line_item.percentage_vat ??
                            0;

                          // Calculate total including VAT based on the available VAT rate
                          const totalIncludingVat = (
                            parseNumber(line_item.unit_price) *
                            parseNumber(line_item.units) *
                            (vatRate === 0
                              ? 1
                              : +vatRate < 1
                              ? 1 + +vatRate
                              : 1 + +vatRate / 100)
                          ).toFixed(2);

                          return <span>&euro; {totalIncludingVat}</span>;
                        },

                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column7',
                        name: 'Enkel totaal',
                        fieldName: 'show_only_total',
                        minWidth: 70,
                        maxWidth: 70,
                        isRowHeader: true,
                        onRender: (line_item: any) => (
                          <span>
                            <Checkbox
                              disabled
                              checked={line_item.show_only_total === true}
                            />
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                    ]}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible
                    selection={selection}
                    selectionMode={SelectionMode.single}
                    selectionPreservedOnEmptyClick
                    onItemInvoked={() => {
                      openLineItemDetail();
                    }}
                    onRenderDetailsFooter={onRenderDetailsFooterContractUpdates}
                  />
                </AccordionItem>
                {customerContractUpdate.split_payment !== true && (
                  <AccordionItem
                    key='accordion-invoice-customer-invoices'
                    id='accordion-invoice-customer-invoices'
                    title={
                      <Stack>
                        <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                          Facturen
                        </h3>
                      </Stack>
                    }
                  >
                    <ShimmeredDetailsList
                      items={
                        data &&
                        data.findOneCustomerContractUpdate &&
                        data.findOneCustomerContractUpdate
                          .customer_invoice_contract_updates
                          ? data.findOneCustomerContractUpdate.customer_invoice_contract_updates.map(
                              (item: any) => item.customer_invoice,
                            )
                          : []
                      }
                      columns={[
                        {
                          key: 'column1',
                          name: 'Factuurdatum',
                          fieldName: 'text',
                          minWidth: 100,
                          maxWidth: 100,
                          isRowHeader: true,
                          onRender: (item: ICustomerInvoice) => (
                            <span>
                              {moment(item.invoice_date).format('YYYY-MM-DD')}
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column2',
                          name: 'Factuurnr',
                          fieldName: 'text',
                          minWidth: 70,
                          maxWidth: 70,
                          isRowHeader: true,
                          onRender: (item: ICustomerInvoice) => (
                            <span>
                              <a
                                href={`/customer-invoices/${item.id}`}
                                target='_blank'
                                rel='noreferrer'
                                style={{ width: '20%', color: 'black' }}
                              >
                                {item.invoice_no}
                              </a>
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column3',
                          name: 'Intro',
                          fieldName: 'text',
                          minWidth: 300,
                          maxWidth: 300,
                          isRowHeader: true,
                          onRender: (item: ICustomerInvoice) => (
                            <span>{item.intro}</span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column4',
                          name: 'Betaald',
                          fieldName: 'text',
                          minWidth: 50,
                          maxWidth: 50,
                          isRowHeader: true,
                          onRender: (item: ICustomerInvoice) => (
                            <span>
                              <Checkbox
                                disabled
                                checked={!!item.invoice_paid}
                              />
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column6',
                          name: 'Totaal excl. BTW',
                          fieldName: 'unit_price',
                          minWidth: 100,
                          maxWidth: 100,
                          isRowHeader: true,
                          onRender: (customerInvoice: ICustomerInvoice) => (
                            <span>
                              &euro;{' '}
                              {customerInvoice.customer_invoice_line_items
                                .reduce(
                                  (previousValue, currentValue) =>
                                    previousValue +
                                    currentValue.unit_price *
                                      currentValue.units,
                                  0,
                                )
                                .toFixed(2)}
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                      ]}
                      layoutMode={DetailsListLayoutMode.justified}
                      isHeaderVisible
                      selection={undefined}
                      selectionMode={SelectionMode.none}
                      selectionPreservedOnEmptyClick
                    />
                  </AccordionItem>
                )}
                {customerContractUpdate.split_payment === true &&
                  customerContractUpdate.split_payment_json && (
                    <AccordionItem
                      key='accordion-payment'
                      id='accordion-payment'
                      title={
                        <Stack>
                          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                            Betalingschijven
                          </h3>
                        </Stack>
                      }
                    >
                      <PaymentList
                        items={JSON.parse(
                          customerContractUpdate.split_payment_json!,
                        )}
                      />
                    </AccordionItem>
                  )}
              </Accordion>
            ) : (
              <>
                <Label>
                  Na opslaan kan je het detail van het min-/meerwerk aanpassen.
                </Label>
                <Stack style={{ opacity: 0.3, pointerEvents: 'none' }}>
                  <Accordion
                    selectedKey={undefined}
                    defaultKey={undefined}
                    toggleItem={() => {
                      setSelectedValueItem(undefined);
                    }}
                  >
                    <AccordionItem
                      key='detail-static'
                      id='detail-static'
                      title={
                        <Stack>
                          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                            Detail
                          </h3>
                        </Stack>
                      }
                    />
                  </Accordion>
                  <Accordion
                    selectedKey={undefined}
                    defaultKey={undefined}
                    toggleItem={() => {
                      setSelectedValueItem(undefined);
                    }}
                  >
                    <AccordionItem
                      key='ivoices-static'
                      id='ivoices-static'
                      title={
                        <Stack>
                          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                            Facturen
                          </h3>
                        </Stack>
                      }
                    />
                  </Accordion>
                </Stack>
              </>
            )}
          </Stack>
        </div>
        <Stack
          className={modalContentStyles.footer}
          horizontal
          horizontalAlign='space-between'
        >
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <SaveButtonWithPermissions
              disabled={
                !customerContractUpdate ||
                (customerContractUpdate && !customerContractUpdate.summary) ||
                (!customerContractUpdate.construction_site && !constructionSite)
              }
              save={saveCustomerContractUpdate}
              permission='write:customerContractUpdates'
            />

            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
          {customerContractUpdate && customerContractUpdate.id && (
            <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
              <StackItem>
                <SaveButtonWithPermissions
                  disabled={disabled}
                  text={
                    customerContractUpdate &&
                    customerContractUpdate.split_payment === true
                      ? 'Her splits'
                      : 'Splits'
                  }
                  save={splitupCustomerContractUpdate}
                  permission='write:customerContractUpdates'
                />
              </StackItem>
              <StackItem>
                <PrimaryButton
                  iconProps={{ iconName: 'Download' }}
                  onClick={() => {
                    setShowPdf(true);
                  }}
                >
                  Download PDF
                </PrimaryButton>
              </StackItem>
            </Stack>
          )}
        </Stack>
      </div>
      {showPdf && customerContractUpdate && (
        <CustomerContractUpdateDynamicPdf
          customerContractUpdateSource={customerContractUpdate}
          setShowPdf={setShowPdf}
        />
      )}
    </Panel>
  );
};

export default CustomerContractUpdateDetail;
