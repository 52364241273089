import React, { FormEvent, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import {
  Stack,
  StackItem,
  TextField,
  Toggle,
  TextStyles,
  Icon,
} from '@fluentui/react';
import useDebounce from '../../components/hooks/useDebounce';
import {
  default_page_size,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import {
  GET_CUSTOMER_PAYMENT_TRANSACTIONS,
  ICustomerPaymentTransaction,
} from '../../utils/CustomerPaymentTransaction';
import CustomerPaymentTransactionsOverview from './components/CustomerPaymentTransactionOverview';

interface IPagedCustomerPaymentTransaction {
  customerPaymentTransactions: ICustomerPaymentTransaction[];
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  lazyLoading: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

const CustomerPaymentTransactions = () => {
  const { isAuthenticated } = useAuth0();

  const [pagedState, setPagedState] =
    useState<IPagedCustomerPaymentTransaction>({
      customerPaymentTransactions: [],
      skip: 0,
      take: default_page_size,
      filter: '',
      showLoadMore: true,
      lazyLoading: true,
      isFiltered: false,
      initialLoad: true,
      isSortedAsc: true,
      sortedField: 'construction_site_name',
    });

  const [filter, setFilter] = useState('');
  const [activeFilter, setActiveFilter] = useState(true);

  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    client.cache.reset();
    setPagedState(prevState => ({
      ...prevState,
      customerPaymentTransactions: [],
      skip: 0,
    }));
  }, [filter]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    if (pagedState.sortedField === 'construction_site_name') {
      orderByObject.construction_site = {
        name: 'asc',
      };
    } else {
      orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
        ? 'asc'
        : 'desc';
    }

    return orderByObject;
  };

  const getFilters = (filterString: string, active: boolean) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [],
    };
    if (active) {
      filterObject.AND.push({
        customer_invoices: {
          none: {},
        },
      });
    }

    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [
          { description: { contains: filterArray[i] } },
          {
            construction_site: {
              OR: [{ name: { contains: filterArray[i] } }],
            },
          },
        ],
      };

      filterObject.AND.push(filterValue);
    }

    return filterObject;
  };

  const {
    data: dataCustomerPaymentTransactions,
    loading,
    error,
    fetchMore,
    client,
  } = useQuery(GET_CUSTOMER_PAYMENT_TRANSACTIONS, {
    // notifyOnNetworkStatusChange: true,
    variables: {
      filter: getFilters(debouncedValue, activeFilter),
      take: pagedState.take,
      skip: pagedState.skip,
      orderBy: getOrderBy(),
    },
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    client.cache.reset();
    clearCustomerPaymentTransactions();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const clearCustomerPaymentTransactions = () => {
    setPagedState(prevState => ({
      ...prevState,
      customerPaymentTransactions: [],
      skip: 0,
    }));
  };

  const onChangeActiveFilter = () => {
    setActiveFilter(!activeFilter);
    clearCustomerPaymentTransactions();
  };

  const fetchMoreCustomerPaymentTransactions = () => {
    // Load more guard
    fetchMore({
      variables: {
        filter: getFilters(debouncedValue, activeFilter), // inUse == active?
        take: pagedState.take,
        skip: pagedState.skip + pagedState.take,
        orderBy: getOrderBy(),
      },
    });

    setPagedState(prevState => ({
      ...prevState,
      skip: pagedState.skip + pagedState.take,
    }));
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <div style={{ padding: '25px' }}>
        <h3
          style={{
            fontSize: '20px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Icon iconName='PieSingle' style={{ fontSize: '20px' }} />
          Betalingsschijven
        </h3>

        <Stack style={{ flexDirection: 'row' }}>
          <TextField
            label='Zoeken...'
            value={filter}
            styles={textFieldStyles300}
            onChange={onSearchValueChange}
          />

          <StackItem style={{ marginLeft: 70 }}>
            <Toggle
              label='Openstaand?'
              defaultChecked={activeFilter}
              onText='Openstaand'
              offText='Afgesloten'
              onChange={onChangeActiveFilter}
            />
          </StackItem>
        </Stack>
      </div>

      <CustomerPaymentTransactionsOverview
        customerPaymentTransactions={
          dataCustomerPaymentTransactions
            ? dataCustomerPaymentTransactions.findManyCustomerPaymentTransactions
            : []
        }
        loading={loading}
        setSorting={setSorting}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        showLoadMore={pagedState.showLoadMore}
        loadMore
        loadMoreCallback={fetchMoreCustomerPaymentTransactions}
        lazyLoading={pagedState.lazyLoading}
      />
    </Stack>
  );
};

export default CustomerPaymentTransactions;
