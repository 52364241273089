import { gql } from '@apollo/client';
import { IComboBoxOption } from '@fluentui/react';
import { ISalesRep } from './SalesRep';
import { ICreateLead, ILead } from './Lead';

export const GET_CUSTOMERS = gql`
  query getCustomers(
    $filter: CustomerFilterInput
    $take: Int
    $skip: Int
    $orderBy: CustomerOrderByInput
    $cursor: CustomerWhereInput
  ) {
    findManyCustomers(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
      cursor: $cursor
    ) {
      prisma_total
      id
      address1
      city1
      country
      email1
      email2
      first_name1
      first_name2
      last_name1
      last_name2
      mobile1
      mobile2
      phone1
      phone2
      title1
      title2
      zip_code1
      status
      vat
    }
  }
`;

export const GET_CUSTOMER_BY_ID = gql`
  query getCustomerById($where: CustomerWhereInput) {
    findOneCustomer(where: $where) {
      id
      address1
      city1
      country
      email1
      email2
      first_name1
      first_name2
      last_name1
      last_name2
      mobile1
      mobile2
      phone1
      phone2
      title1
      title2
      zip_code1
      status
      vat
    }
  }
`;

export const ADD_CUSTOMER = gql`
  mutation addCustomer($data: CustomerCreateInput!) {
    createCustomer(data: $data) {
      id
      address1
      city1
      country
      email1
      email2
      first_name1
      first_name2
      last_name1
      last_name2
      mobile1
      mobile2
      phone1
      phone2
      title1
      title2
      zip_code1
      vat
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($id: Int!, $data: CustomerUpdateInput) {
    updateCustomer(id: $id, data: $data) {
      id
      address1
      city1
      country
      email1
      email2
      first_name1
      first_name2
      last_name1
      last_name2
      mobile1
      mobile2
      phone1
      phone2
      title1
      title2
      zip_code1
      vat
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation deleteCustomer($where: CustomerWhereInput) {
    deleteCustomer(where: $where) {
      id
      address1
      city1
      country
      email1
      email2
      first_name1
      first_name2
      last_name1
      last_name2
      mobile1
      mobile2
      phone1
      phone2
      title1
      title2
      zip_code1
      status
      vat
    }
  }
`;

export interface ICustomer {
  id: number;
  prisma_total?: number;
  address1: string;
  city1: string;
  country: string;
  email1: string;
  email1opt_out: boolean;
  email2: string;
  email2opt_out: boolean;
  first_name1: string;
  first_name2: string;
  last_name1: string;
  last_name2: string;
  mobile1: string;
  mobile2: string;
  phone1: string;
  phone2: string;
  title1: string;
  title2: string;
  zip_code1: number;
  status: string;
  sales_rep: ISalesRep;
  comments: string;
  vat: string;
}

export const convertCustomersToComboBoxOptions = (customers: ICustomer[]) => {
  if (customers) {
    const result: IComboBoxOption[] = customers.map(
      (customer: ICustomer): IComboBoxOption => ({
        key: customer.id,
        text: displayNameCustomer(customer),
      }),
    );
    return result;
  }

  return [];
};

export const displayNameCustomer = (lead?: ILead | ICreateLead) => {
  if (!lead) return '';
  let parsedName = '';

  if (lead.title1 && lead.last_name1) {
    parsedName = `${lead.title1} `;
  }
  if (lead.first_name1) {
    parsedName = `${parsedName}${lead.first_name1} `;
  }
  if (lead.last_name1) {
    parsedName = `${parsedName}${lead.last_name1} `;
  }
  if (lead.title2 && lead.last_name2 && lead.last_name2.length > 0) {
    parsedName = `${parsedName} - ${lead.title2} `;
  }
  if (lead.first_name2) {
    parsedName = `${parsedName}${lead.first_name2} `;
  }
  if (lead.last_name2) {
    parsedName = `${parsedName}${lead.last_name2} `;
  }

  return parsedName;
};
