import { IComboBoxOption } from '@fluentui/react';
import { gql } from '@apollo/client';
import moment from 'moment';
import { ISuggestedValueCategory } from './SuggestedValueCategory';

export interface ISuggestedValue {
  id: number;
  active?: boolean;
  suggested_value?: string;
  suggested_value_code?: string;
  suggested_value_category?: ISuggestedValueCategory;
  dirty?: boolean;
  draft?: boolean;
  delete?: boolean;
}

export const GET_SUGGESTED_VALUES = gql`
  query getSuggestedValues(
    $filter: SupplierFilterInput
    $orderBy: SupplierOrderByInput
  ) {
    findManySuggestedValues(filter: $filter, orderBy: $orderBy) {
      id
      active
      suggested_value
      suggested_value_code
    }
  }
`;

export const GET_SUGGESTED_VALUE = gql`
  query getSuggestedValue($where: SuggestedValueWhereInput) {
    findOneSuggestedValue(where: $where) {
      id
      active
      suggested_value
      suggested_value_code
    }
  }
`;

export const convertSuggestedValuesToComboBoxOptions = (
  suggestedValues?: ISuggestedValue[],
) => {
  const result: IComboBoxOption[] = suggestedValues
    ? suggestedValues.map(
        (suggestedValue: ISuggestedValue): IComboBoxOption => ({
          key: suggestedValue.id,
          text: suggestedValue.suggested_value
            ? suggestedValue.suggested_value
            : '',
        }),
      )
    : [];

  return result;
};

export const getDefaultSuggestedValue = (): ISuggestedValue => ({
  id: moment().unix(),
  suggested_value: '',
  draft: true,
});
