import {
  ComboBox,
  DefaultButton,
  IComboBox,
  IComboBoxOption,
  Label,
  Link,
  Stack,
  TextField,
  Text,
  Checkbox,
} from '@fluentui/react';
import React, { FormEvent, useState } from 'react';
import _ from 'lodash';
import { Heading } from '../../../components/typography';
import {
  convertContactPersonsToComboBoxOptions,
  convertSuppliersToComboBoxOptions,
  IContactPerson,
  IPurchaseOrderTemplate,
  ISupplier,
  stackTokens15,
  textFieldStyles300,
} from '../../../utils';
import TextFieldDropDown from '../../../components/parts/TextFieldDropdown';

interface Props {
  template: IPurchaseOrderTemplate;
  callBack: (template: IPurchaseOrderTemplate) => void;
  suppliers?: ISupplier[];
  supplierSearch?: string;
  suppliersLoading?: boolean;
  setSupplierSearch: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const PurchaseOrderTemplateHeader = ({
  template,
  //   suppliers,
  callBack,
  suppliers,
  suppliersLoading,
  supplierSearch,
  setSupplierSearch,
}: Props) => {
  const [modalContactPersonOpen, setModalContactPersonOpen] = useState(false);

  const onNameChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const newTemplate = _.cloneDeep(template);
    const newName = newValue || '';
    newTemplate.name = newName;

    callBack(newTemplate);
  };

  const onDescriptionChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const newTemplate = _.cloneDeep(template);
    const newName = newValue || '';
    newTemplate.description = newName;

    callBack(newTemplate);
  };

  const onRemarksChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | string,
  ) => {
    const newTemplate = _.cloneDeep(template);
    const newRemarks = newValue || '';
    newTemplate.remarks = newRemarks;

    callBack(newTemplate);
  };

  const onChangeContactPerson = (
    event: FormEvent<IComboBox>,
    option?: IComboBoxOption | undefined,
    // index?: number | undefined,
    // value?: string | undefined,
  ) => {
    // setSelectedContactPersonId(+option!.key);
    const contacts = template.supplier
      ? template.supplier.contact_persons
      : undefined;

    if (option && +option!.key > 0 && contacts && template) {
      const foundContactPerson = contacts.filter(
        (el: IContactPerson) => el.id === +option!.key,
      )[0];
      const newTemplate = { ...template };
      newTemplate.is_default_contact_person = false;
      newTemplate.contact_person = foundContactPerson;
      newTemplate.contact_person_changed = true;
      callBack(newTemplate);
    } else if (template) {
      const newTemplate = { ...template };
      newTemplate.is_default_contact_person = true;
      newTemplate.contact_person_changed = true;
      newTemplate.contact_person = undefined;
      callBack(newTemplate);
    }
  };

  const setSupplier = (value: ISupplier) => {
    const newTemplate = _.cloneDeep(template);
    newTemplate.supplier = value;
    callBack(newTemplate);
  };

  const setDefaultContactPerson = (value: boolean | undefined) => {
    const newTemplate = _.cloneDeep(template);
    newTemplate.is_default_contact_person = !!value;
    newTemplate.contact_person_changed = true;
    callBack(newTemplate);
  };

  const loadContactPersons = (supplier: ISupplier) => {
    if (supplier) {
      let contactArray: IComboBoxOption[] = [];

      const contactArrayTemp = supplier.contact_persons || [];

      const comboBoxOptions =
        convertContactPersonsToComboBoxOptions(contactArrayTemp);

      if (comboBoxOptions && comboBoxOptions.length > 0) {
        contactArray = contactArray.concat(comboBoxOptions);
      }

      return contactArray;
    }
    return [];
  };

  const getContactLabel = () => (
    <div>
      <Checkbox
        label='Standaard contactpersoon?'
        styles={{ root: { marginBottom: '10px' } }}
        checked={template && template.is_default_contact_person}
        onChange={(e, checked) => {
          setDefaultContactPerson(checked);
        }}
      />
      {template && !template.is_default_contact_person && (
        <ComboBox
          selectedKey={
            template && template.contact_person
              ? template.contact_person.id
              : template &&
                template.supplier &&
                template.supplier.contact_persons &&
                template.supplier.contact_persons[0] &&
                template.supplier.contact_persons[0].id
              ? template.supplier.contact_persons[0].id
              : undefined
          }
          allowFreeform
          autoComplete='on'
          options={
            template && template.supplier
              ? loadContactPersons(template.supplier)
              : []
          }
          onChange={onChangeContactPerson}
          disabled={!(template && template.supplier)}
        />
      )}
    </div>
  );

  const getPhoneLabel = () => {
    const selectedSupplier =
      template && template.supplier ? template.supplier : undefined;
    const supplierContactPhone =
      selectedSupplier &&
      selectedSupplier.contact_persons &&
      selectedSupplier.contact_persons[0] &&
      (selectedSupplier.contact_persons[0].phone_v2 ||
        selectedSupplier.contact_persons[0].phone ||
        selectedSupplier.contact_persons[0].mobile_v2 ||
        selectedSupplier.contact_persons[0].mobile)
        ? selectedSupplier.contact_persons[0].phone_v2 ||
          selectedSupplier.contact_persons[0].mobile_v2 ||
          selectedSupplier.contact_persons[0].phone ||
          selectedSupplier.contact_persons[0].mobile
        : undefined;
    return template && template.contact_person ? (
      <Label>
        {template.contact_person.phone_v2 ||
          template.contact_person.mobile_v2 ||
          template.contact_person.phone ||
          template.contact_person.mobile}
      </Label>
    ) : supplierContactPhone ? (
      <Label>{supplierContactPhone}</Label>
    ) : null;
  };

  const getFaxLabel = () => {
    const selectedSupplier =
      template && template.supplier ? template.supplier : undefined;
    const supplierContactFax =
      selectedSupplier &&
      selectedSupplier.contact_persons &&
      selectedSupplier.contact_persons[0] &&
      selectedSupplier.contact_persons[0].fax
        ? selectedSupplier.contact_persons[0].fax
        : undefined;
    return template && template.contact_person ? (
      <Label>{template.contact_person.fax}</Label>
    ) : supplierContactFax ? (
      <Label>{supplierContactFax}</Label>
    ) : null;
  };

  // if (loading) return <p>Laden...</p>;

  return (
    <div>
      <Stack horizontal tokens={{ childrenGap: 40 }}>
        <Stack.Item>
          <h3 style={{ marginBottom: '10px' }}>Algemeen</h3>
          <TextField
            label='Naam'
            value={template.name}
            styles={textFieldStyles300}
            onChange={onNameChange}
            // errorMessage={hasName ? '' : 'Naam moet zijn ingevuld!'}
            required
          />
          <TextField
            label='Stadaard omschrijving'
            value={template.description}
            styles={textFieldStyles300}
            onChange={onDescriptionChange}
            // errorMessage={hasName ? '' : 'Naam moet zijn ingevuld!'}
            // required
          />
          <Stack tokens={stackTokens15}>
            <Stack horizontal tokens={stackTokens15}>
              <TextField
                value={template.remarks}
                styles={textFieldStyles300}
                multiline
                rows={6}
                resizable={false}
                onChange={onRemarksChange}
                label='Opmerking'
              />
            </Stack>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Stack tokens={stackTokens15}>
            <h3 style={{ marginBottom: '10px' }}>Leverancier</h3>
            <TextFieldDropDown
              emptyResult={
                !suppliersLoading && suppliers && suppliers.length === 0
              }
              placeholder='zoek een leverancier'
              value={supplierSearch}
              loading={suppliersLoading}
              onChange={(e, value) => {
                setSupplierSearch(value);
              }}
              options={
                suppliers ? convertSuppliersToComboBoxOptions(suppliers) : []
              }
              select={key => {
                if (suppliers) {
                  for (let i = 0; i < suppliers.length; i++) {
                    if (suppliers[i].id === key) setSupplier(suppliers[i]);
                  }
                }
              }}
              label='Zoeken'
              styles={textFieldStyles300}
            />
            {template && template.supplier && template.supplier.id && (
              <>
                <Text
                  style={{
                    display: 'block',
                    fontWeight: 'bold',
                    marginTop: '15px',
                  }}
                >
                  {template.supplier.name}{' '}
                </Text>

                <Text style={{ display: 'block' }}>
                  {template.supplier.address}
                </Text>
                <Text style={{ display: 'block' }}>
                  {template.supplier.zip_code} {template.supplier.city}
                </Text>
                <Link href={`mailto:${template.supplier.email}`}>
                  {template.supplier.email}
                </Link>
              </>
            )}
            <h4 style={{ marginTop: '20px' }}>Contactpersoon</h4>
            <Text style={{ display: 'block' }}>{getContactLabel()}</Text>
            <Text style={{ display: 'block' }}>{getPhoneLabel()}</Text>
            <Text style={{ display: 'block' }}>{getFaxLabel()}</Text>
          </Stack>
        </Stack.Item>
      </Stack>
    </div>
  );
};

export default PurchaseOrderTemplateHeader;
