import React, { useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Label,
  StackItem,
  IComboBoxOption,
  Dialog,
  DialogFooter,
} from '@fluentui/react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  convertEmployeesToComboBoxOptions,
  modalContentStyles,
} from '../../../utils';
import { ComboboxWithFilter } from '../../../components/parts';
import {
  dialogModelProps,
  dialogRemoveConfirmationPropsGeneral,
} from '../../../utils/Styles';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import { toastSuccess, toastError } from '../../../utils/toast';
import { DELETE_TEAM_MEMBER } from '../../../utils/TeamMember';
import { customPanelStyles } from '../../../theme';

const TeamMemberDetail = ({
  isOpen,
  dismissPanel,
  saveTeamMember,
  teamMember,
  setTeamMember,
  employees,
  refetchTeam,
}: any) => {
  const [isConfirmationHidden, setIsConfirmationHidden] = useState(true);

  const [deleteMember] = useMutation(DELETE_TEAM_MEMBER);

  const toggleConfirmationDialog = () => {
    setIsConfirmationHidden(!isConfirmationHidden);
  };

  const deleteMemberFn = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          deleteMember({
            variables: {
              where: {
                id: teamMember.id,
              },
            },
            onError: async error => {
              toggleConfirmationDialog();
              dismissPanel();

              reject(error);
            },
            onCompleted: async (x: any) => {
              toggleConfirmationDialog();
              dismissPanel();
              await refetchTeam();
              resolve(x);
            },
          });
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      await res;
      toastSuccess('Werknemer verwijderd');
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_LOADING_MESSAGE);
    }
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        teamMember && teamMember.id ? 'Werknemer wijzigen' : 'Werknemer toevoegen'
      }
      type={PanelType.custom}
      customWidth='500px'
      styles={customPanelStyles}
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {teamMember && teamMember.id && <Label>ID: {teamMember.id}</Label>}

          <Stack style={{ marginBottom: 10 }}>
            <ComboboxWithFilter
              label='Werknemer'
              options={convertEmployeesToComboBoxOptions(employees)}
              value={
                teamMember && teamMember.employee && teamMember.employee.id
              }
              multiline={false}
              disabled={
                teamMember && teamMember.employee && teamMember.employee.id
              }
              callBack={(newValue: IComboBoxOption[]) => {
                if (newValue && newValue.length > 0) {
                  const result = { ...teamMember };

                  result.employee = {
                    id: newValue[0].key as number,
                  };

                  setTeamMember(result);
                }
              }}
              required
              errorMessage={
                teamMember && !teamMember.employee
                  ? 'Dit veld is verplicht'
                  : ''
              }
            />
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack
            style={{
              flexDirection: 'row',
              marginTop: 10,
              justifyContent: 'space-between',
            }}
          >
            <Stack style={{ flexDirection: 'row' }}>
              <SaveButtonWithPermissions
                disabled={!teamMember || (teamMember && !teamMember.employee)}
                save={saveTeamMember}
                permission='write:teamMembers'
              />

              <StackItem>
                <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
              </StackItem>
            </Stack>
            <Stack>
              <StackItem>
                <DefaultButton onClick={toggleConfirmationDialog}>
                  Verwijderen
                </DefaultButton>
              </StackItem>
            </Stack>
          </Stack>
        </div>
      </div>

      <Dialog
        hidden={isConfirmationHidden}
        onDismiss={toggleConfirmationDialog}
        dialogContentProps={dialogRemoveConfirmationPropsGeneral}
        modalProps={dialogModelProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={deleteMemberFn} text='Verwijderen' />
          <DefaultButton onClick={toggleConfirmationDialog} text='Annuleren' />
        </DialogFooter>
      </Dialog>
    </Panel>
  );
};

export default TeamMemberDetail;
