import { Stack, Icon } from '@fluentui/react';
import React, { useCallback } from 'react';
import _ from 'lodash';
import { InlineEditableLabel, InlineComboBox } from '../../../components/parts';
import {
  IMeasuringUnit,
  stackTokens15,
  convertMeasuringUnitsToComboBoxOptions,
  IPurchaseOrderLine,
  iconLastProps,
  IPurchaseOrderTemplate,
} from '../../../utils';
import DraggableList from '../../../components/parts/DraggableList';

interface Props {
  template: IPurchaseOrderTemplate;
  measuringUnits?: IMeasuringUnit[];
  callBack: (items: IPurchaseOrderLine[]) => void;
}

const PurchaseOrderTemplateBody = ({
  template,
  measuringUnits,
  callBack,
}: Props) => {
  const deleteLine = useCallback(
    (id: number) => {
      const newLines = _.cloneDeep(template.line_items);

      for (let i = 0; i < newLines.length; i++) {
        if (newLines[i].id === id) {
          newLines[i].delete = !newLines[i].delete;
        }
      }

      callBack(newLines);
    },
    [template, callBack],
  );

  const updateItems = useCallback(
    (item: IPurchaseOrderLine) => {
      const newItems = _.cloneDeep(template.line_items);

      for (let i = 0; i < newItems.length; i++) {
        if (newItems[i].id === item.id) newItems[i] = item;
      }

      callBack(newItems);
    },
    [template, callBack],
  );

  const updateItemValue = (
    item: IPurchaseOrderLine,
    attribute: string,
    newValue: string,
  ) => {
    const newItem = { ...item };
    newItem.isDirty = true;
    newItem.isDirty = true;
    // @ts-ignore
    newItem[attribute] = newValue;

    updateItems(newItem);
  };

  const updateItemMeasuringUnit = (
    item: IPurchaseOrderLine,
    newUnit: number,
  ) => {
    if (measuringUnits) {
      const newMeasuringUnit = measuringUnits.filter(
        (item: IMeasuringUnit) => item.id === newUnit,
      )[0];
      const newItem = { ...item };

      newItem.measuring_unit = newMeasuringUnit;
      newItem.isDirty = true;

      updateItems(newItem);
    }
  };

  const orderLineItems = (source: number, target: number) => {
    const currentTemplate = _.cloneDeep(template);
    const currentOrderLines: IPurchaseOrderLine[] =
      currentTemplate && currentTemplate.line_items
        ? currentTemplate.line_items
        : [];

    const fromIndex = source;
    const toIndex = target;

    const element = currentOrderLines.splice(fromIndex, 1)[0];

    currentOrderLines.splice(toIndex, 0, element);

    for (let i = 0; i < currentOrderLines.length; i++) {
      currentOrderLines[i].weight = i + 1;
      if (!currentOrderLines[i].isNew) {
        currentOrderLines[i].isDirty = true;
      }
    }

    currentTemplate.line_items = currentOrderLines;

    callBack(currentTemplate.line_items);
  };

  return (
    <Stack tokens={stackTokens15}>
      {template.line_items.length > 0 && (
        <Stack>
          <Stack
            horizontal
            style={{
              width: '100%',
              borderBottom: '1px solid rgb(229, 229, 229)',
              textAlign: 'left',
            }}
          >
            <Stack.Item
              style={{ textAlign: 'center', width: '75px', fontWeight: 'bold' }}
            >
              Regel
            </Stack.Item>
            <Stack.Item
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                width: '350px',
                fontWeight: 'bold',
              }}
            >
              Omschrijving
            </Stack.Item>
            <Stack.Item
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                width: '100px',
                fontWeight: 'bold',
              }}
            >
              Aantal
            </Stack.Item>
            <Stack.Item
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                width: '100px',
                fontWeight: 'bold',
              }}
            >
              Eenheid
            </Stack.Item>
            <Stack.Item
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                width: '100px',
                fontWeight: 'bold',
              }}
            >
              Prijs
            </Stack.Item>
            <Stack.Item
              grow={1}
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                fontWeight: 'bold',
              }}
            >
              Opmerking
            </Stack.Item>
            <Stack.Item style={{ textAlign: 'center', width: '75px' }} />
          </Stack>
          <DraggableList
            onDrop={(source, target) => {
              orderLineItems(source, target);
            }}
          >
            {template.line_items.map(
              (item: IPurchaseOrderLine, index: number) => {
                const fieldsPerRow = 5;
                const currentFieldIndex = index * fieldsPerRow + 1;
                return (
                  <Stack
                    horizontal
                    style={{
                      width: '100%',
                      marginTop: '10px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      // '&:hover': { background: 'rgb(239, 239, 239)' },
                    }}
                    styles={{
                      root: {
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        '&:hover': {
                          background: 'rgb(239, 239, 239)',
                          cursor: 'pointer',
                        },
                      },
                    }}
                    key={`${item.id}_id`}
                  >
                    <Stack.Item
                      style={{
                        textAlign: 'center',
                        width: '75px',
                        opacity: item.delete ? 0.5 : 1,
                      }}
                    >
                      <span>{item.weight}</span>
                    </Stack.Item>
                    <Stack.Item
                      style={{
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        width: '350px',
                        opacity: item.delete ? 0.5 : 1,
                      }}
                    >
                      <InlineEditableLabel
                        isMultiline
                        isEdit
                        value={item && item.name ? item.name : undefined}
                        callBack={(newValue: string) =>
                          updateItemValue(item, 'name', newValue)
                        }
                        tabIndex={currentFieldIndex + 1}
                      />
                    </Stack.Item>
                    <Stack.Item
                      style={{
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        width: '100px',
                        opacity: item.delete ? 0.5 : 1,
                      }}
                    >
                      <InlineEditableLabel
                        isEdit
                        value={
                          item && item.quantity ? item.quantity : undefined
                        }
                        callBack={(value: string) =>
                          updateItemValue(item, 'quantity', value)
                        }
                        tabIndex={currentFieldIndex + 2}
                      />
                    </Stack.Item>
                    <Stack.Item
                      style={{
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        width: '100px',
                        opacity: item.delete ? 0.5 : 1,
                      }}
                    >
                      <InlineComboBox
                        isEdit
                        value={
                          item && item.measuring_unit
                            ? item.measuring_unit.id
                            : undefined
                        }
                        options={
                          measuringUnits && measuringUnits.length > 0
                            ? convertMeasuringUnitsToComboBoxOptions(
                                measuringUnits,
                              )
                            : []
                        }
                        callBack={(newUnit: number) =>
                          updateItemMeasuringUnit(item, newUnit)
                        }
                        tabIndex={currentFieldIndex + 3}
                      />
                    </Stack.Item>
                    <Stack.Item
                      style={{
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        width: '100px',
                        opacity: item.delete ? 0.5 : 1,
                      }}
                    >
                      <InlineEditableLabel
                        isEdit
                        value={item.unit_price}
                        callBack={(newValue: string) =>
                          updateItemValue(item, 'unit_price', newValue)
                        }
                        tabIndex={currentFieldIndex + 4}
                      />
                    </Stack.Item>
                    <Stack.Item
                      grow={1}
                      style={{
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        opacity: item.delete ? 0.5 : 1,
                      }}
                    >
                      <InlineEditableLabel
                        isMultiline
                        isEdit
                        value={item.comment || undefined}
                        callBack={(value: string) =>
                          updateItemValue(item, 'comment', value)
                        }
                        tabIndex={currentFieldIndex + 5}
                      />
                    </Stack.Item>
                    <Stack.Item style={{ textAlign: 'center', width: '75px' }}>
                      <span>
                        <Icon
                          iconName='Delete'
                          styles={iconLastProps}
                          onClick={() => deleteLine(item.id)}
                        />
                      </span>
                    </Stack.Item>
                  </Stack>
                );
              },
            )}
          </DraggableList>
        </Stack>
      )}

      {/* <PurchaseOrderTemplateAddItem order={order} callBack={newItemCallBack} /> */}
      <Stack style={{ marginTop: '100px' }}> </Stack>
    </Stack>
  );
};

export default PurchaseOrderTemplateBody;
