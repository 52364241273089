import { gql } from '@apollo/client';
import { IConstructionSite } from './ConstructionSite';
import { ICustomerContractUpdate } from './CustomerContractUpdate';
import { ICustomerInvoice } from './CustomerInvoice';
import { IEmployee } from './Employee';
import { IPurchaseOrder } from './PurchaseOrder';

export const GET_MAIL_LOG_BY_ID = gql`
  query getMailLogById($where: MailLogWhereInput) {
    findOneMailLog(where: $where) {
      id
    }
  }
`;

export interface IMailLog {
  id: number;
  from: string;
  to: string;
  cc: string;
  bcc: string;
  subject: string;
  body: string;
  attachments: string;
  //
  employee_id: number;
  employee: IEmployee;
  //
  construction_site_id: number;
  construction_site: IConstructionSite;
  //
  customer_contract_update_id: number;
  customer_contract_update: ICustomerContractUpdate;
  //
  customer_invoice_id: number;
  customer_invoice: ICustomerInvoice;
  //
  purchase_order_id: number;
  purchase_order: IPurchaseOrder;
  //
  date_created: Date;
}
