import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Checkbox,
  DefaultButton,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  IColumn,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import { toast } from 'react-toastify';
import { useBoolean } from '@fluentui/react-hooks';
import { useParams } from 'react-router';
import {
  ADD_PLAN,
  DELETE_PLAN,
  GET_PLAN_BY_ID,
  IPlan,
  UPDATE_PLAN,
} from '../../../utils/Plan';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
} from '../../../utils';
import { toastError, toastSuccess } from '../../../utils/toast';
import { commandBarTheme, commandBarThemeCounter } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import PlanDetail from './PlanDetail';
import PlanDetailsList from './PlanDetailList';

interface Props {
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
  plans: IPlan[];
  setSorting: (isSortedAsc: boolean, sortedField: string) => void;
  isSortedAsc?: boolean;
  sortedField?: string;
  showLoadMore?: boolean;
  refetch?: any;
}

const PlansOverview = ({
  plans,
  loading,
  loadMore,
  setSorting = (isSortedAsc: boolean, sortedField: string) => {},
  isSortedAsc = false,
  sortedField = '',
  showLoadMore,
  lazyLoading,
  loadMoreCallback = () => ({}),
  refetch,
}: Props) => {
  const [plan, setPlan] = useState<IPlan | undefined>(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [deleteDialogOpen, { toggle: toggleDelete }] = useBoolean(false);
  const [addPlan] = useMutation(ADD_PLAN);
  const [modifyPlan] = useMutation(UPDATE_PLAN);
  const [deletePlan] = useMutation(DELETE_PLAN);

  const { id: planId } = useParams();

  // Selection

  const [selectionDetails, setSelectionDetails] = useState<IPlan | undefined>(
    planId ? ({ id: planId } as any) : undefined,
  );

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const { refetch: refetchPlan } = useQuery(GET_PLAN_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: selectionDetails
      ? { where: { id: Number(selectionDetails.id) } }
      : undefined,
    skip: !selectionDetails,
    onCompleted: data => {
      setPlan(data.findOnePlan);
    },
  });

  const openPlanDetail = (newPlan?: boolean) => {
    if (selectionDetails && !newPlan) {
      // getCostCenter({ variables: { where: { id: selectionDetails.id } } });
    } else {
      setPlan(undefined);
    }
    setIsPanelOpen(true);
  };

  const savePlan = async () => {
    try {
      if (plan) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              active: plan?.active === true,
              title: plan?.title ? plan?.title : undefined,

              subtitle: plan?.subtitle ? plan?.subtitle : undefined,
              description: plan?.description ? plan?.description : undefined,
              pictures: plan?.pictures ? plan?.pictures : undefined,

              style_of_home: plan?.style_of_home
                ? plan?.style_of_home
                : undefined,
              type_of_home: plan?.type_of_home ? plan?.type_of_home : undefined,
              type_of_roof: plan?.type_of_roof ? plan?.type_of_roof : undefined,
              facade_width: plan?.facade_width
                ? Number(plan.facade_width)
                : undefined,
              rooms: plan?.rooms ? Number(plan.rooms) : undefined,
              house_area: plan?.house_area
                ? Number(plan.house_area)
                : undefined,
              plot_area: plan?.plot_area ? Number(plan.plot_area) : undefined,
              internal_description: plan?.internal_description
                ? plan.internal_description
                : undefined,
            };

            if (plan.id) {
              modifyPlan({
                variables: {
                  id: plan.id,
                  data: allInput,
                },
                onError: error => {
                  setIsPanelOpen(false);
                  reject(error);
                },
                onCompleted: x => {
                  setIsPanelOpen(false);
                  resolve(x);
                },
              });
            } else {
              addPlan({
                variables: {
                  data: allInput,
                },
                onError: error => {
                  setIsPanelOpen(false);
                  reject(error);
                },
                onCompleted: x => {
                  resolve(x);
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        const x = await (res as any);
        if (x && x.createPlan) {
          setPlan(x.createPlan);
          toastSuccess('Voorbeeld project succesvol opgeslagen');
          refetch();
        }
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  const deleteSelection = useCallback(async () => {
    if (!selectionDetails) {
      toggleDelete();
      return;
    }

    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          deletePlan({
            variables: {
              where: {
                id: selectionDetails?.id,
              },
            },
            onError: (error: any) => {
              toggleDelete();
              reject(error);
            },
            onCompleted: async (x: any) => {
              toggleDelete();
              await refetch();
              setPlan(undefined);
              resolve(x);
            },
          });
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      await res;
      toastSuccess('Voorbeeld project verwijderd');
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_LOADING_MESSAGE);
    }
  }, [selectionDetails]);

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'totaal',
      text: `${plans.length} / ${plans.length > 0 ? plans[0].prisma_total : 0}`,
      theme: commandBarThemeCounter,
    },
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openPlanDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openPlanDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
    {
      key: 'delete',
      text: 'Verwijderen',
      iconProps: { iconName: 'Trash' },
      onClick: () => toggleDelete(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
  ];

  // Hooks

  useEffect(() => {
    if (plan && isPanelOpen) {
      document.title = `3bouw | Voorbeeld Project - ${plan.title}`;
    } else {
      document.title = '3bouw | Voorbeeld Projecten';
    }
  }, [plan, isPanelOpen]);

  useEffect(() => {
    if (planId) {
      setSelectionDetails({ id: planId } as any);
      openPlanDetail();
    }
  }, []);

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        maxWidth='2000px'
      />

      <PlanDetail
        isOpen={isPanelOpen}
        dismissPanel={() => {
          setIsPanelOpen(false);
        }}
        plan={plan}
        savePlan={savePlan}
        setPlan={setPlan}
        refetch={refetchPlan}
      />
      {deleteDialogOpen && selectionDetails && (
        <Dialog
          hidden={!deleteDialogOpen}
          onDismiss={toggleDelete}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Voorbeeld project verwijderen',
            closeButtonAriaLabel: 'Close',
          }}
        >
          <p>
            <strong>
              Ben je zeker dat je dit voorbeeld project wilt verwijderen.
            </strong>
          </p>
          <p>Deze actie kan niet ongedaan gemaakt worden.</p>
          <DialogFooter>
            <PrimaryButton text='Verwijderen' onClick={deleteSelection} />
            <DefaultButton text='Annuleren' onClick={toggleDelete} />
          </DialogFooter>
        </Dialog>
      )}

      {plans && (
        <>
          <PlanDetailsList
            items={plans}
            selection={selection}
            enableShimmer={!loading}
            isSortedAsc={isSortedAsc}
            sortedField={sortedField}
            setSorting={setSorting}
            loadMore={loadMore}
            lazyLoading={lazyLoading}
            loadMoreCallback={loadMoreCallback}
            loading={loading}
            onItemInvoked={() => openPlanDetail(false)}
          />
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
                // display: items.length === costCenters.length ? 'block' : 'none',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton
                text='Toon meer'
                onClick={() => loadMoreCallback()}
              />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}

      {!loading && !showLoadMore && plans.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default PlansOverview;
