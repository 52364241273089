import { gql } from '@apollo/client';

export interface ICostCenterWings {
  id: number;
  cost_center_id: number;
  wings_number: number;
}

export const GET_COST_CENTER_WINGS = gql`
  query getCostCenters(
    $filter: CostCenterWingsFilterInput
    $orderBy: CostCenterWingsOrderByInput
  ) {
    findManyCostCenters(filter: $filter, orderBy: $orderBy) {
      id
      cost_center_id
      wings_number
    }
  }
`;

export const GET_COST_CENTER_WINGS_BY_ID = gql`
  query getCostCenterWingsById($where: CostCenterWingsWhereInput) {
    findOneCostCenterWings(where: $where) {
      id
      cost_center_id
      wings_number
    }
  }
`;

export const ADD_COST_CENTER_WINGS = gql`
  mutation addCostCenter($data: CostCenterWingsCreateInput!) {
    createCostCenterWings(data: $data) {
      id
      cost_center_id
      wings_number
    }
  }
`;

export const UPDATE_COST_CENTER_WINGS = gql`
  mutation updateCostCenter($id: Int!, $data: CostCenterWingsUpdateInput) {
    updateCostCenterWings(id: $id, data: $data) {
      id
      cost_center_id
      wings_number
    }
  }
`;

export const DELETE_COST_CENTER_WINGS = gql`
  mutation deleteCostCenterWings($where: CostCenterWingsWhereInput) {
    deleteCostCenterWings(where: $where) {
      id
      cost_center_id
      wings_number
    }
  }
`;
