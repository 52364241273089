import React, { useCallback, useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  Stack,
  Label,
  StackItem,
  TextField,
  IComboBoxOption,
} from '@fluentui/react';
import _ from 'lodash';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  IConstructionSite,
  modalContentStyles,
  normalizeString,
} from '../../../utils';
import { ComboboxWithFilter } from '../../../components/parts';
import useDebounce from '../../../components/hooks/useDebounce';
import {
  convertLeadsToComboBoxOptions,
  GET_LEADS,
  ILead,
} from '../../../utils/Lead';
import {
  ADD_CONSTRUCTION_SITE_INVOICE_CUSTOMER,
  IInvoiceCustomer,
  UPDATE_CONSTRUCTION_SITE_INVOICE_CUSTOMER,
} from '../../../utils/ConstructionSiteInvoiceCustomer';
import {
  GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
  IConstructionSiteCreateUpdate,
} from '../../../utils/ConstructionSite';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import parseNumber from '../../../utils/Numbers';
import { toastError, toastSuccess } from '../../../utils/toast';
import { customPanelStyles } from '../../../theme';

type Props = {
  isOpen: boolean;
  dismissPanel: () => void;
  invoiceCustomerSource: IInvoiceCustomer;
  constructionSite?: IConstructionSite | IConstructionSiteCreateUpdate;
};

const CustomerInvoiceDetail = ({
  isOpen,
  dismissPanel,
  invoiceCustomerSource,
  constructionSite,
}: Props) => {
  const [invoiceCustomer, setInvoiceCustomer] = useState(invoiceCustomerSource);

  const [filter, setFilter] = useState('');

  const debouncedFilter = useDebounce(filter, 500);

  const getFilters = (filterString: string) => {
    const filterArray = filterString.split(' ');

    const filters: any = {
      AND: [],
    };

    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [
          { first_name1: { contains: filterArray[i] } },
          { first_name2: { contains: filterArray[i] } },
          { last_name1: { contains: filterArray[i] } },
          { last_name2: { contains: filterArray[i] } },
          { normalized_first_name1: { contains: filterArray[i] } },
          { normalized_first_name2: { contains: filterArray[i] } },
          { normalized_last_name1: { contains: filterArray[i] } },
          { normalized_last_name2: { contains: filterArray[i] } },
          {
            normalized_first_name1: {
              contains: normalizeString(filterArray[i]),
            },
          },
          {
            normalized_first_name2: {
              contains: normalizeString(filterArray[i]),
            },
          },
          {
            normalized_last_name1: {
              contains: normalizeString(filterArray[i]),
            },
          },
          {
            normalized_last_name2: {
              contains: normalizeString(filterArray[i]),
            },
          },
        ],
      };
      filters.AND.push(filterValue);
    }

    return filters;
  };

  const { data: customerData } = useQuery(GET_LEADS, {
    fetchPolicy: 'no-cache',
    variables: {
      take: 10,
      filter: getFilters(debouncedFilter),
    },
  });

  // Save invoice customer
  const [addInvoiceCustomer] = useMutation(
    ADD_CONSTRUCTION_SITE_INVOICE_CUSTOMER,
    {
      refetchQueries: [
        {
          query: GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
          variables: {
            where: {
              id: constructionSite!.id,
            },
          },
        },
      ],
    },
  );
  const [modifyInvoiceCustomer] = useMutation(
    UPDATE_CONSTRUCTION_SITE_INVOICE_CUSTOMER,
    {
      refetchQueries: [
        {
          query: GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
          variables: {
            where: {
              id: constructionSite!.id,
            },
          },
        },
      ],
    },
  );

  const saveInvoiceCustomer = async () => {
    try {
      if (invoiceCustomer && constructionSite && constructionSite.id) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput: any = {
              percentage: parseNumber(invoiceCustomer.percentage),
              lead:
                invoiceCustomer.lead &&
                invoiceCustomer.lead.id &&
                invoiceCustomer.lead.id !== -1
                  ? {
                      connect: {
                        id: invoiceCustomer.lead.id,
                      },
                    }
                  : undefined,
            };

            if (invoiceCustomer.id && invoiceCustomer.id !== -1) {
              modifyInvoiceCustomer({
                variables: {
                  id: invoiceCustomer.id,
                  data: allInput,
                },
                onError: error => {
                  dismissPanel();
                  reject(error);
                },
                onCompleted: x => {
                  dismissPanel();
                  resolve(x);
                },
              });
            } else {
              allInput.construction_site = {
                connect: {
                  id: constructionSite.id,
                },
              };
              addInvoiceCustomer({
                variables: {
                  data: allInput,
                },
                onError: error => {
                  dismissPanel();
                  reject(error);
                },
                onCompleted: x => {
                  dismissPanel();
                  resolve(x);
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
        toastSuccess('Facturatieklant succesvol opgeslagen');
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  const setPercentage = useCallback(
    (value: number) => {
      const newInvoiceCustomer = _.cloneDeep(invoiceCustomer);
      newInvoiceCustomer.percentage = value;

      setInvoiceCustomer(newInvoiceCustomer);
    },
    [invoiceCustomer, setInvoiceCustomer],
  );

  const setCustomer = useCallback(
    (lead: ILead) => {
      const newInvoiceCustomer = _.cloneDeep(invoiceCustomer);
      newInvoiceCustomer.lead = lead;

      setInvoiceCustomer(newInvoiceCustomer);
    },
    [invoiceCustomer, setInvoiceCustomer],
  );

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setPercentage(Number(newValue));
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        invoiceCustomer && invoiceCustomer.id
          ? 'Facturatieklant wijzigen'
          : 'Facturatieklant toevoegen'
      }
      type={PanelType.custom}
      customWidth='500px'
      styles={customPanelStyles}
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {invoiceCustomer && <Label>ID: {invoiceCustomer.id}</Label>}
          <Stack>
            <Stack
              style={{
                marginBottom: 10,
              }}
            >
              <ComboboxWithFilter
                label='Klant'
                allowFreeForm
                options={convertLeadsToComboBoxOptions(
                  customerData && customerData.findManyLeads
                    ? customerData.findManyLeads
                    : [],
                )}
                value={
                  invoiceCustomer &&
                  invoiceCustomer.lead &&
                  invoiceCustomer.lead.id
                    ? invoiceCustomer.lead.id
                    : ''
                }
                setFilter={setFilter}
                multiline={false}
                callBack={(newValue: IComboBoxOption[]) => {
                  if (
                    newValue &&
                    newValue.length > 0 &&
                    customerData &&
                    customerData.findManyLeads
                  ) {
                    for (
                      let i = 0;
                      i < customerData.findManyLeads.length;
                      i++
                    ) {
                      if (
                        customerData.findManyLeads[i].id === newValue[0].key
                      ) {
                        setCustomer(customerData.findManyLeads[i]);
                      }
                    }
                  }
                }}
              />
            </Stack>
            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='percentage'
                label='Percentage'
                type='number'
                value={
                  invoiceCustomer && invoiceCustomer.percentage
                    ? invoiceCustomer.percentage.toString()
                    : ''
                }
                onChange={onChangeTextFieldValue}
                required
              />
            </Stack>
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <SaveButtonWithPermissions
              disabled={
                !invoiceCustomer ||
                (invoiceCustomer &&
                  !invoiceCustomer.lead &&
                  invoiceCustomer.percentage === undefined)
              }
              save={saveInvoiceCustomer}
              permission='write:customerInvoices'
            />

            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default CustomerInvoiceDetail;
