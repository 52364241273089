import { gql } from '@apollo/client';
import { IComboBoxOption, IDropdownOption } from '@fluentui/react';
import { IContactPerson, ISuggestedValue } from '.';

export const GET_SUPPLIERS = gql`
  query getSuppliers(
    $filter: SupplierFilterInput
    $take: Int
    $skip: Int
    $orderBy: SupplierOrderByInput
  ) {
    findManySuppliers(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      prisma_total
      name
      address
      zip_code
      city
      phone_v2
      mobile_v2
      email
      email_opt_out
      vat_code
      contact
    }
  }
`;

export const GET_SUPPLIERS_WITH_CONTACT_PERSONS = gql`
  query getSuppliers(
    $filter: SupplierFilterInput
    $take: Int
    $skip: Int
    $orderBy: SupplierOrderByInput
  ) {
    findManySuppliers(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      prisma_total
      id
      name
      address
      zip_code
      city
      contact_persons {
        id
        first_name
        last_name
        email
        notes
        mobile
        mobile_v2
        phone
        phone_v2
      }
      phone_v2
      mobile_v2
      email
      email_opt_out
      vat_code
      contact
    }
  }
`;

export const GET_SUPPLIER_BY_ID = gql`
  query getSupplierById($where: SupplierWhereInput) {
    findOneSupplier(where: $where) {
      id
      name
      address
      zip_code
      city
      phone
      phone_v2
      mobile
      mobile_v2
      fax
      email
      email_opt_out
      vat_code
      contact
      payment_terms
      contact_persons {
        id
        first_name
        last_name
        email
        notes
        mobile
        mobile_v2
        phone
        phone_v2
      }
      bank_account_number
      iban
      sector
      bic
      comments
      website
      wings_code
      suggested_value {
        id
        suggested_value
        suggested_value_code
        suggested_value_category {
          category_code
        }
      }
    }
  }
`;

export const ADD_SUPPLIER = gql`
  mutation AddSupplier($data: SupplierCreateInput!) {
    createSupplier(data: $data) {
      id
      name
      address
      zip_code
      city
      phone
      phone_v2
      mobile
      mobile_v2
      fax
      email
      email_opt_out
      vat_code
      contact
      payment_terms
      bank_account_number
      iban
      sector
      bic
      comments
      website
      wings_code
      suggested_value {
        id
        suggested_value
        suggested_value_code
        suggested_value_category {
          category_code
        }
      }
    }
  }
`;

export const UPDATE_SUPPLIER = gql`
  mutation UpdateSupplier($id: Int!, $data: SupplierUpdateInput!) {
    updateSupplier(id: $id, data: $data) {
      id
      name
      address
      zip_code
      city
      phone
      phone_v2
      mobile
      mobile_v2
      fax
      email
      email_opt_out
      vat_code
      contact
      payment_terms
      contact_persons {
        id
        first_name
        last_name
        email
        notes
        mobile
        mobile_v2
        phone
        phone_v2
      }
      bank_account_number
      iban
      sector
      bic
      comments
      website
      wings_code
      suggested_value {
        id
        suggested_value
        suggested_value_code
        suggested_value_category {
          category_code
        }
      }
    }
  }
`;

export interface ISupplier {
  id: number;
  name: string;
  address?: string;
  zip_code?: string;
  city?: string;
  phone?: string;
  phone_v2?: string;
  mobile?: string;
  mobile_v2?: string;
  fax?: string;
  email?: string;
  email_opt_out?: boolean;
  vat_code?: string;
  // contact?: string; //waarom deze gebruiken? Legacy? @TP
  contact_persons?: IContactPerson[];
  bank_account_number?: string;
  payment_terms?: number;
  iban?: string;
  sector?: string;
  bic?: string;
  comments?: string;
  wings_code?: string;
  website?: string;
  suggested_value?: ISuggestedValue;
  prisma_total?: number;
}

export interface IPropertySupplier {
  id: number;
  supplier: ISupplier;
  dirty?: boolean;
  new?: boolean;
  delete?: boolean;
}

export const convertSuppliersToComboBoxOptions = (suppliers: ISupplier[]) => {
  const result: IComboBoxOption[] = suppliers.map(
    (supplier: ISupplier): IComboBoxOption => ({
      key: supplier.id,
      text: displayNameSupplier(supplier),
    }),
  );

  return result;
};

export const convertSuppliersToDropdownOptions = (suppliers: ISupplier[]) => {
  const result: IDropdownOption[] = suppliers.map(
    (supplier: ISupplier): IDropdownOption => ({
      key: supplier.id,
      text: displayNameSupplier(supplier),
    }),
  );
  return result;
};

export const displayNameSupplier = (supplier: ISupplier) => supplier.name;
