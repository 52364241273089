import { useQuery } from '@apollo/client';
import {
  DefaultButton,
  Panel,
  PanelType,
  Stack,
  StackItem,
} from '@fluentui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { Filter } from '../../../components/parts/FilterPanel';
import {
  GET_CONSTRUCTION_SITE_BY_ID,
  GET_CONSTRUCTION_SITE_BY_ID_ANALYSIS,
  IConstructionSite,
  IConstructionSiteCreateUpdate,
} from '../../../utils/ConstructionSite';
import { GET_EMPLOYEES, modalContentStyles } from '../../../utils';
import {
  GET_COST_CENTERS_FINANCIAL_ANALYSIS,
  ICostCenter,
} from '../../../utils/CostCenter';
import {
  GET_INCOMING_INVOICE_LINE_ITEMS,
  GET_INCOMING_INVOICE_LINE_ITEMS_ANALYSIS,
  IIncomingInvoiceLineItem,
} from '../../../utils/IncomingInvoiceLineItem';
import { CostCenter } from '../../cost-center';
import { GET_TIMESHEET_ENTRIES } from '../../../utils/TimesheetEntry';
import { customPanelStyles } from '../../../theme';
import FinancialAnalysisCostCenter from './FinancialAnalysisCostCenter';
import FinancialAnalysisCalculation from './FinancialAnalysisCalculation';
import FinancialAnalysisToInvoice from './FinancialAnalysisToInvoice';

type Props = {
  isOpen: boolean;
  dismissPanel: any;
  constructionSite: IConstructionSiteCreateUpdate;
};

const FinancialAnalysis = ({
  isOpen,
  dismissPanel,
  constructionSite,
}: Props) => {
  const { user } = useAuth0();

  let isDirectie = false;

  for (let i = 0; i < (user ? user['3bouw/roles'].length : 0); i++) {
    if (
      (user as any)['3bouw/roles'][i] === 'SuperAdmin' ||
      (user as any)['3bouw/roles'][i] === 'Directie'
    ) {
      isDirectie = true;
    }
  }

  const {
    loading: loadingCostCenter,
    error: errorCostCenter,
    data: dataCostCenters,
  } = useQuery(GET_COST_CENTERS_FINANCIAL_ANALYSIS, {
    variables: {
      filter: {
        OR: [
          {
            incoming_invoice_line_items: {
              some: {
                construction_site_id: constructionSite.id,
              },
            },
          },
          {
            time_sheet_entries: {
              some: {
                construction_site_id: constructionSite.id,
              },
            },
          },
        ],
      },
      lineItemFilter: {
        construction_site_id: constructionSite.id,
      },
      timeSheetFilter: {
        construction_site_id: constructionSite.id,
      },
      orderBy: {
        name: 'asc',
      },
    },
    onCompleted: data => {

    },
  });

  const {
    loading: loadingConstructionSite,
    error: errorConstructionSite,
    data: dataConstructionSite,
  } = useQuery(GET_CONSTRUCTION_SITE_BY_ID_ANALYSIS, {
    variables: {
      where: {
        id: constructionSite.id ? constructionSite.id : 0,
      },
      whereProps: {
        parent_id: null,
      },
    },
  });

  const {
    loading: loadingIncomingInvoiceLineItems,
    error: errorIncomingInvoiceLineItems,
    data: dataIncomingInvoiceLineItems,
  } = useQuery(GET_INCOMING_INVOICE_LINE_ITEMS_ANALYSIS, {
    variables: {
      filter: {
        construction_site_id: constructionSite.id ? constructionSite.id : 0,
      },
    },
  });

  const {
    loading: loadingTimeSheetEntries,
    error: errorTimeSheetEntries,
    data: dataTimeSheetEntries,
  } = useQuery(GET_TIMESHEET_ENTRIES, {
    variables: {
      filter: {
        construction_site_id: constructionSite.id ? constructionSite.id : 0,
      },
    },
  });

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText='Financiele Analyse'
      type={PanelType.custom}
      customWidth='900px'
      styles={customPanelStyles}
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              alignItems: 'start',
              width: '100%',
            }}
          >
            <div style={{ width: '100%' }}>
              {loadingCostCenter ||
              loadingConstructionSite ||
              loadingIncomingInvoiceLineItems ||
              loadingTimeSheetEntries ? (
                <div>Loading...</div>
              ) : (
                <div>
                  {errorCostCenter ||
                  errorConstructionSite ||
                  errorIncomingInvoiceLineItems ||
                  errorTimeSheetEntries ? (
                    <div>
                      Niet genoeg gegevens om een financiele analyse op te
                      stellen
                    </div>
                  ) : (
                    <StackItem style={{ width: '100%' }}>
                      {isDirectie && (
                        <FinancialAnalysisCalculation
                          incomingInvoiceLines={
                            dataIncomingInvoiceLineItems.findManyIncomingInvoiceLineItems
                          }
                          constructionSite={
                            dataConstructionSite.findOneConstructionSite
                          }
                          timeSheetEntries={
                            dataTimeSheetEntries.findManyTimeSheetEntries
                          }
                        />
                      )}
                      <div>
                        <FinancialAnalysisToInvoice
                          constructionSite={
                            dataConstructionSite.findOneConstructionSite
                          }
                        />
                      </div>
                      {dataCostCenters.findManyCostCenters.length > 0 && (
                        <>
                          <h2
                            style={{
                              marginTop: 5,
                              marginBottom: 10,
                              width: '100%',
                            }}
                          >
                            Detail onkosten
                          </h2>
                          {dataCostCenters.findManyCostCenters.map(
                            (costCenter: ICostCenter) => (
                              <FinancialAnalysisCostCenter
                                costCenter={costCenter}
                                constructionSite={
                                  dataConstructionSite.findOneConstructionSite
                                }
                                incomingInvoiceLines={
                                  dataIncomingInvoiceLineItems.findManyIncomingInvoiceLineItems
                                }
                                timeSheetEntries={
                                  dataTimeSheetEntries.findManyTimeSheetEntries
                                }
                              />
                            ),
                          )}
                        </>
                      )}
                    </StackItem>
                  )}
                </div>
              )}
            </div>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default FinancialAnalysis;
