/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { Selection, Stack, PrimaryButton } from '@fluentui/react';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  overviewClassNames as classNames,
  convertEmployeesToComboBoxOptions,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  DELETE_CONSTRUCTION_SITE,
  GET_EMPLOYEES,
  IConstructionSite,
  IEmployee,
} from '../../../utils';

import {
  GET_CONSTRUCTION_SITES,
  IConstructionSiteCreateUpdate,
} from '../../../utils/ConstructionSite';
import {
  Filter,
  FilterPanel,
  FilterState,
  initFilterPanelState,
} from '../../../components/parts/FilterPanel';
import {
  GET_CONSTRUCTION_SITE_STATUSES,
  convertConstructionSiteStatusesToComboBoxOptions,
} from '../../../utils/ConstructionSiteStatus';
import { useAppDispatch } from '../../../redux/hooks';
import { sendNotification } from '../../../redux/notification/notificationSlice';
import { GET_TAX_CODES } from '../../../utils/TaxCode';
import { toastError, toastSuccess } from '../../../utils/toast';
import FinancialAnalysis from './FinancialAnalysis';
import ConstructionSitesMessageBar from './ConstructionSitesMessageBar';
import ConstructionSitesDetailsList from './ConstructionSitesDetailsList';
import ConstructionSitesCommandBar from './ConstructionSitesCommandBar';
import ConstructionSiteDetail from './ConstructionSiteDetail';
import FinancialAnalysisDynamicPdf from './FinancialAnalysisDynamicPdf';

interface Props {
  constructionSites: IConstructionSite[];
  loading?: boolean;
  //
  setSorting: (isSortedAsc: boolean, sortedField: string) => void;
  isSortedAsc?: boolean;
  sortedField?: string;
  //
  showLoadMore?: boolean;
  loadMore?: boolean;
  loadMoreCallback?: () => void;
  lazyLoading?: boolean;
  //
  downloadFile?: () => void;
  //
  listFilters: any;
  setConstructionSiteFilter: React.Dispatch<
    React.SetStateAction<FilterState | undefined>
  >;
  //
  isFilterPanelOpen: boolean;
  setIsFilterPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  //
  refetch: any;
}

const ConstructionSitesOverview = ({
  constructionSites,
  loading,
  //
  setSorting = (isSortedAsc: boolean, sortedField: string) => {},
  isSortedAsc = false,
  sortedField = '',
  //
  showLoadMore,
  loadMore,
  loadMoreCallback = () => ({}),
  lazyLoading,
  //
  downloadFile = () => {},
  //
  listFilters,
  setConstructionSiteFilter,
  //
  isFilterPanelOpen,
  setIsFilterPanelOpen,
  //
  refetch,
}: Props) => {
  // States
  const [announcedMessage] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(!loading);
  const [showWarning, setShowWarning] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [taxCodes, setTaxCodes] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const [openPanel, setOpenPanel] = useState(false);
  const [openPanelFinancial, setOpenPanelFinancial] = useState(false);
  const [openPanelFinancialPdf, setOpenPanelFinancialPdf] = useState(false);
  const [constructionSite, setConstructionSite] = useState<
    IConstructionSiteCreateUpdate | undefined
  >(undefined);

  const { id: constructionSiteId } = useParams();

  // Queries
  const [deleteConstructionSite] = useMutation(DELETE_CONSTRUCTION_SITE, {
    refetchQueries: [{ query: GET_CONSTRUCTION_SITES }],
  });
  const dispatch = useAppDispatch();
  // Selection
  /* const [constructionSite, setconstructionSite] = useState<
    IConstructionSite | undefined
  >(); */

  const getconstructionSite = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setConstructionSite(currentSelection[0]);
    } else {
      setConstructionSite(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getconstructionSite,
  });

  const openDetail = (newSite?: boolean) => {
    if (newSite) {
      setConstructionSite({});
    }
    setOpenPanel(true);
  };

  const openFinancial = () => {
    setOpenPanelFinancial(true);
  };

  const openFinancialPdf = () => {
    setOpenPanelFinancialPdf(true);
    dispatch(
      sendNotification({
        message: 'bezig met downloaden',
        level: 0,
        module: 'constructionsite.downloadPdF',
        spinner: true,
      }),
    );
  };

  // Const
  const navigate = useNavigate();

  const gotoExecutionList = (id: number) => {
    window.open(`/construction-sites/${id}/implementation-list`, '_blank');
    // navigate(`/construction-sites/${id}/implementation-list`);
  };
  const gotoWaterPoints = (id: number) => {
    window.open(`/construction-sites/${id}/water-points`, '_blank');
    // navigate(`/construction-sites/${id}/implementation-list`);
  };
  const gotoConstructionSheet = (id: number) => {
    window.open(`/construction-sites/${id}/site-overview`, '_blank');
    // navigate(`/construction-sites/${id}/site-overview`);
  };
  const gotoAddPurchaseOrder = (id: number) => {
    window.open(
      `/purchase-orders?addNew=true&constructionSite=${id}`,
      '_blank',
    );
  };

  const removeConstructionSite = async () => {
    setIsDataLoaded(false);

    const sitesToBeDeleted = constructionSite;
    if (sitesToBeDeleted) {
      try {
        const result = await toast.promise(
          deleteConstructionSite({
            variables: {
              where: {
                id: sitesToBeDeleted.id,
              },
            },
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        // After successful deletion, you might want to update the state or refetch data
        // clearConstructionSites();
        // refetchConstructionSites();
        setIsDataLoaded(true);
        setShowWarning(false);
        toastSuccess('Construction site removed successfully!');
        await refetch();
      } catch (error: any) {
        setIsDataLoaded(true);
        // Optionally handle more specific error operations here
        toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
      }
    }
  };

  const loadExecutionList = () => {
    if (constructionSite && constructionSite.id) {
      gotoExecutionList(constructionSite.id);
    }
  };

  const loadWaterPoints = () => {
    if (constructionSite && constructionSite.id) {
      gotoWaterPoints(constructionSite.id);
    }
  };

  const loadConstructionSheet = () => {
    if (constructionSite && constructionSite.id) {
      gotoConstructionSheet(constructionSite.id);
    }
  };

  const loadNewPurchaseOrder = () => {
    if (constructionSite && constructionSite.id) {
      gotoAddPurchaseOrder(constructionSite.id);
    }
  };

  const dismissPanel = () => {
    setOpenPanel(false);
  };

  const dismissFinancialPanel = () => {
    setOpenPanelFinancial(false);
  };

  useEffect(() => {
    if (constructionSite && openPanel) {
      document.title = `3bouw | Werf - ${constructionSite.name}`;
    } else {
      document.title = '3bouw | Werven';
    }
  }, [constructionSite, openPanel]);

  useEffect(() => {
    if (constructionSiteId) {
      setOpenPanel(true);
    }
  }, []);

  useQuery(GET_EMPLOYEES, {
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      setEmployees(x.findManyEmployees);
    },
  });

  useQuery(GET_TAX_CODES, {
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      setTaxCodes(x.findManyTaxCodes);
    },
  });

  useQuery(GET_CONSTRUCTION_SITE_STATUSES, {
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      setStatuses(x.findManyConstructionSiteStatuses);
    },
  });

  const filter_schema: Filter = {
    id: 'construction_site_filter_schema_rev2',
    permission: 'read:leads',
    schema: [
      {
        id: 'section_0',
        label: 'Algemeen',
        width: 48,
        fields: [
          {
            id: 'section_0_0',
            name: 'Adres',
            fieldName: 'address',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => ({
              startsWith: fieldValue,
            }),
          },
          {
            id: 'section_0_1',
            name: 'Stad',
            fieldName: 'city',
            data: 'string',
            type: 'text',
            width: 69.5,
            callback: (fieldValue: string) => ({
              startsWith: fieldValue,
            }),
          },
          {
            id: 'section_0_2',
            name: 'Post code',
            fieldName: 'zip_code',
            data: 'number',
            type: 'number',
            width: 29.5,
            callback: (fieldValue: string) => {
              if (fieldValue) {
                return {
                  equals: parseInt(fieldValue, 10),
                };
              }
            },
          },
          {
            id: 'section_0_3',
            name: 'Post code (comma separated)',
            fieldName: 'OR',
            data: 'number',
            type: 'text',
            callback: (fieldValue: string) => {
              const fieldArray = fieldValue.replace(' ', '').split(',');
              if (fieldArray.length > 0) {
                const filterArray = [];

                for (let i = 0; i < fieldArray.length; i++) {
                  if (!isNaN(fieldArray[i] as any)) {
                    filterArray.push({
                      zip_code: {
                        equals: parseInt(fieldArray[i], 10),
                      },
                    });
                  }
                }

                return filterArray;
              }
            },
          },
          {
            id: 'section_0_4',
            name: 'Startdatum verkoop',
            fieldName: 'date_sold',
            data: 'date',
            type: 'date',
            width: 48.5,
            callback: (fieldValue: string) => ({
              gte: moment(new Date(fieldValue)).toISOString(),
            }),
          },
          {
            id: 'section_0_5',
            name: 'Einddatum verkoop',
            fieldName: 'date_sold',
            data: 'date',
            type: 'date',
            width: 48.5,
            callback: (fieldValue: string) => ({
              lte: moment(new Date(fieldValue)).toISOString(),
            }),
          },
          {
            id: 'section_0_6',
            name: 'Startdatum contract',
            fieldName: 'date_of_contract',
            data: 'date',
            type: 'date',
            width: 48.5,
            callback: (fieldValue: string) => ({
              gte: moment(new Date(fieldValue)).toISOString(),
            }),
          },
          {
            id: 'section_0_7',
            name: 'Einddatum contract',
            fieldName: 'date_of_contract',
            data: 'date',
            type: 'date',
            width: 48.5,
            callback: (fieldValue: string) => ({
              lte: moment(new Date(fieldValue)).toISOString(),
            }),
          },
          {
            id: 'section_0_8',
            name: 'Startdatum oplevering',
            fieldName: 'date_completed',
            data: 'date',
            type: 'date',
            width: 48.5,
            callback: (fieldValue: string) => ({
              gte: moment(new Date(fieldValue)).toISOString(),
            }),
          },
          {
            id: 'section_0_9',
            name: 'Einddatum oplevering',
            fieldName: 'date_completed',
            data: 'date',
            type: 'date',
            width: 48.5,
            callback: (fieldValue: string) => ({
              lte: moment(new Date(fieldValue)).toISOString(),
            }),
          },
        ],
      },
      {
        id: 'section_3',
        label: 'Eigenschappen',
        width: 48,
        fields: [
          {
            id: 'section_3_0',
            name: 'Status',
            fieldName: 'status',
            data: 'string',
            type: 'list',
            options: convertConstructionSiteStatusesToComboBoxOptions(statuses),
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  id: parseInt(fieldValue, 10),
                };
              }
            },
          },
          {
            id: 'section_3_1',
            name: 'Graad van afwerking',
            fieldName: 'degree_of_completion',
            data: 'string',
            type: 'suggested',
            prefix: 'customer_degreeOfCompletion_',
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  startsWith: `customer_degreeOfCompletion_${fieldValue}`,
                };
              }
            },
          },
          {
            id: 'section_3_2',
            name: 'Type woning',
            fieldName: 'type_of_home',
            data: 'string',
            type: 'suggested',
            prefix: 'customer_typeOfHome_',
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  startsWith: `customer_typeOfHome_${fieldValue}`,
                };
              }
            },
          },
          {
            id: 'section_3_3',
            name: 'Stijl woning',
            fieldName: 'style_of_home',
            data: 'string',
            type: 'suggested',
            prefix: 'customer_styleOfHome_',
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  startsWith: `customer_styleOfHome_${fieldValue}`,
                };
              }
            },
          },
          {
            id: 'section_3_4',
            name: 'K-peil',
            fieldName: 'k_level',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => ({
              startsWith: fieldValue,
            }),
          },
          {
            id: 'section_3_5',
            name: 'E-peil',
            fieldName: 'e_level',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => ({
              startsWith: fieldValue,
            }),
          },
        ],
      },
      {
        id: 'section_1',
        label: 'Personeel',
        width: 48,
        fields: [
          {
            id: 'section_1_0',
            name: 'Verkoper',
            fieldName: 'sales_rep',
            data: 'string',
            type: 'list',
            options: convertEmployeesToComboBoxOptions(
              employees.filter((x: IEmployee) => x.sales_rep === true),
            ),
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  id: {
                    equals: parseInt(fieldValue, 10),
                  },
                };
              }
            },
          },
          {
            id: 'section_1_1',
            name: 'EPB Verslaggever',
            fieldName: 'epb_reporter',
            data: 'string',
            type: 'list',
            options: convertEmployeesToComboBoxOptions(
              employees.filter((x: IEmployee) => x.epb_reporter === true),
            ),
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  id: {
                    equals: parseInt(fieldValue, 10),
                  },
                };
              }
            },
          },
          {
            id: 'section_1_2',
            name: 'Veiligheidscoördinator',
            fieldName: 'safety_coordinator',
            data: 'string',
            type: 'list',
            options: convertEmployeesToComboBoxOptions(
              employees.filter((x: IEmployee) => x.safety_coordinator === true),
            ),
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  id: {
                    equals: parseInt(fieldValue, 10),
                  },
                };
              }
            },
          },
          {
            id: 'section_1_3',
            name: 'Werfleider',
            fieldName: 'site_manager',
            data: 'string',
            type: 'list',
            options: convertEmployeesToComboBoxOptions(
              employees.filter(
                (x: IEmployee) => x.construction_site_manager === true,
              ),
            ),
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  id: {
                    equals: parseInt(fieldValue, 10),
                  },
                };
              }
            },
          },
          {
            id: 'section_1_4',
            name: 'Projectleider',
            fieldName: 'project_manager',
            data: 'string',
            type: 'list',
            options: convertEmployeesToComboBoxOptions(employees),
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  id: {
                    equals: parseInt(fieldValue, 10),
                  },
                };
              }
            },
          },
        ],
      },
      {
        id: 'section_7',
        label: 'Properties',
        width: 48,
        fields: [
          {
            id: 'section_7_1',
            name: 'Gevelsteen type',
            fieldName: 'properties',
            data: 'string',
            width: 100,
            type: 'suggestedmulti',
            prefix: 'customer_plaqueType_',
            callback: (fieldValue: any) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                const ids = fieldValue.map((id: any) => parseInt(id, 10)); // Convert all IDs from strings to integers
                if (ids.length === 0) return;
                return {
                  some: {
                    children: {
                      some: {
                        OR: ids.map((id: any) => ({
                          // Map each ID to its own condition in the 'OR' array
                          suggested_value: {
                            id, // Use the 'id' operator to match the specific ID
                          },
                        })),
                      },
                    },
                  },
                };
              }
            },
          },
          {
            id: 'section_7_2',
            name: 'Gevelsteen formaat',
            width: 100,
            fieldName: 'properties',
            data: 'string',
            type: 'suggestedmulti',
            prefix: 'customer_plaqueFormat_',
            callback: (fieldValue: any) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                const ids = fieldValue.map((id: any) => parseInt(id, 10)); // Convert all IDs from strings to integers
                if (ids.length === 0) return;
                return {
                  some: {
                    children: {
                      some: {
                        OR: ids.map((id: any) => ({
                          // Map each ID to its own condition in the 'OR' array
                          suggested_value: {
                            id, // Use the 'id' operator to match the specific ID
                          },
                        })),
                      },
                    },
                  },
                };
              }
            },
          },
          {
            id: 'section_7_3',
            name: 'Gevelsteen metselmethode',
            width: 100,
            fieldName: 'properties',
            data: 'string',
            type: 'suggestedmulti',
            prefix: 'klant:-metselmethode_klant:-metselmethode_',
            callback: (fieldValue: any) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                const ids = fieldValue.map((id: any) => parseInt(id, 10)); // Convert all IDs from strings to integers
                if (ids.length === 0) return;
                return {
                  some: {
                    children: {
                      some: {
                        OR: ids.map((id: any) => ({
                          // Map each ID to its own condition in the 'OR' array
                          suggested_value: {
                            id, // Use the 'id' operator to match the specific ID
                          },
                        })),
                      },
                    },
                  },
                };
              }
            },
          },
          {
            id: 'section_7_4',
            name: 'Buitenschrijnwerk type',
            width: 100,
            fieldName: 'properties',
            data: 'string',
            type: 'suggestedmulti',
            prefix: 'customer_windowType_',
            callback: (fieldValue: any) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                const ids = fieldValue.map((id: any) => parseInt(id, 10)); // Convert all IDs from strings to integers
                if (ids.length === 0) return;
                return {
                  some: {
                    children: {
                      some: {
                        OR: ids.map((id: any) => ({
                          // Map each ID to its own condition in the 'OR' array
                          suggested_value: {
                            id, // Use the 'id' operator to match the specific ID
                          },
                        })),
                      },
                    },
                  },
                };
              }
            },
          },
          {
            id: 'section_7_5',
            name: 'Pannen dakbedekking type',
            width: 100,
            fieldName: 'properties',
            data: 'string',
            type: 'suggestedmulti',
            prefix: 'customer_tileType_',
            callback: (fieldValue: any) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                const ids = fieldValue.map((id: any) => parseInt(id, 10)); // Convert all IDs from strings to integers
                if (ids.length === 0) return;
                return {
                  some: {
                    children: {
                      some: {
                        OR: ids.map((id: any) => ({
                          // Map each ID to its own condition in the 'OR' array
                          suggested_value: {
                            id, // Use the 'id' operator to match the specific ID
                          },
                        })),
                      },
                    },
                  },
                };
              }
            },
          },
        ],
      },
      // {
      //  id: 'section_8',
      //  label: 'Log Items',
      //  width: 48,
      //  fields: [
      //  {
      //    id: 'section_8_0',
      //    name: 'Titel',
      //    fieldName: 'lead',
      //    data: 'string',
      //    type: 'text',
      //    width: 100,
      //    callback: (fieldValue: string) => ({
      //      log_items: {
      //        some: {
      //          text: {
      //              contains: fieldValue,
      //            },
      //          },
      //        },
      //      }),
      //    },
      //  {
      //    id: 'section_8_1',
      //    name: 'Startdatum actie vereist',
      //    fieldName: 'lead',
      //    data: 'date',
      //    type: 'date',
      //    width: 48.5,
      //    callback: (fieldValue: string) => ({
      //      log_items: {
      //        some: {
      //          action_required_by: {
      //              gte: moment(new Date(fieldValue)).toISOString(),
      //            },
      //          },
      //        },
      //      }),
      //    },
      //  {
      //    id: 'section_8_2',
      //    name: 'Einddatum actie vereist',
      //    fieldName: 'lead',
      //    data: 'date',
      //    type: 'date',
      //    width: 48.5,
      //    callback: (fieldValue: string) => ({
      //      log_items: {
      //        some: {
      //                action_required_by: {
      //            lte: moment(new Date(fieldValue)).toISOString(),
      //          },
      //        },
      //      },
      //    }),
      //  },
      //  {
      //    id: 'section_8_3',
      //    name: 'Startdatum aanmaak',
      //    fieldName: 'lead',
      //    data: 'date',
      //    type: 'date',
      //    width: 48.5,
      //    callback: (fieldValue: string) => ({
      //      log_items: {
      //        some: {
      //          date_created: {
      //              gte: moment(new Date(fieldValue)).toISOString(),
      //            },
      //          },
      //        },
      //      }),
      //    },
      //  {
      //    id: 'section_8_4',
      //    name: 'Einddatum aanmaak',
      //    fieldName: 'lead',
      //    data: 'date',
      //    type: 'date',
      //    width: 48.5,
      //    callback: (fieldValue: string) => ({
      //      log_items: {
      //        some: {
      //          date_created: {
      //            lte: moment(new Date(fieldValue)).toISOString(),
      //          },
      //        },
      //      },
      //    }),
      //  },
      // ],
      // },
    ],
  };

  const [filterState, setFilterState] = useState<FilterState>(
    initFilterPanelState(filter_schema),
  );

  return (
    <>
      <ConstructionSitesCommandBar
        modifyFn={openDetail}
        modifyDisabled={!constructionSite}
        loadExecutionListFn={loadExecutionList}
        loadWaterPointsFn={loadWaterPoints}
        loadExecutionListDisabled={!constructionSite}
        loadConstructionSheetFn={loadConstructionSheet}
        loadConstructionSheetDisabled={!constructionSite}
        loadNewPurchaseOrderFn={loadNewPurchaseOrder}
        loadNewPurchaseOrderDisabled={!constructionSite}
        deleteFn={() => setShowWarning(true)}
        deleteDisabled={!constructionSite}
        downloadFile={downloadFile}
        openFilterPanel={setIsFilterPanelOpen}
        analyseFn={openFinancial}
        analysePdfFn={openFinancialPdf}
        constructionSites={constructionSites}
      />

      <ConstructionSitesMessageBar
        showWarning={showWarning}
        deleteConfirm={removeConstructionSite}
        deleteCancel={() => setShowWarning(false)}
        isLoaded={false}
      />

      {announcedMessage && (
        <div className={classNames.selectionDetails}>{announcedMessage}</div>
      )}

      {isFilterPanelOpen && (
        <FilterPanel
          isOpen={isFilterPanelOpen}
          dismissPanel={() => {
            setIsFilterPanelOpen(false);
          }}
          filter={filter_schema}
          setFilter={setConstructionSiteFilter}
          filterState={filterState}
          setFilterState={setFilterState}
        />
      )}

      <ConstructionSitesDetailsList
        items={constructionSites}
        selection={selection}
        // onItemInvoked={onItemInvoked}
        enableShimmer={!isDataLoaded}
        isSortedAsc={isSortedAsc}
        sortedField={sortedField}
        setSorting={setSorting}
        loadMore={loadMore}
        lazyLoading={lazyLoading}
        loadMoreCallback={loadMoreCallback}
        onItemInvoked={() => {
          openDetail();
        }}
        loading={loading}
      />

      {!loading && !showLoadMore && constructionSites.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}

      {showLoadMore && (
        <Stack
          style={{
            marginTop: '15px',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '15px',
            // display: items.length === costCenters.length ? 'block' : 'none',
          }}
          horizontal
          horizontalAlign='center'
        >
          <PrimaryButton
            text='Toon meer'
            onClick={() => {
              loadMoreCallback();
            }}
          />
        </Stack>
      )}

      {openPanel && (constructionSite || constructionSiteId) && (
        <ConstructionSiteDetail
          isOpen={openPanel}
          dismissPanel={() => dismissPanel()}
          constructionSiteSource={constructionSite || {}}
          listFilters={listFilters}
          constructionSiteId={
            constructionSiteId ? +constructionSiteId : undefined
          }
          taxCodes={taxCodes}
        />
      )}

      {openPanelFinancial && constructionSite && (
        <FinancialAnalysis
          isOpen={openPanelFinancial}
          dismissPanel={() => dismissFinancialPanel()}
          constructionSite={constructionSite}
        />
      )}

      {openPanelFinancialPdf && constructionSite && (
        <FinancialAnalysisDynamicPdf
          setShowPdf={setOpenPanelFinancialPdf}
          constructionSiteSource={constructionSite}
        />
      )}
    </>
  );
};

export default ConstructionSitesOverview;
