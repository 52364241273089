import { gql } from '@apollo/client';
import { ILead } from './Lead';

export const GET_SUSPECTS = gql`
  query getSuspects($filter: SuspectFilterInput) {
    findManySuspects(filter: $filter) {
      id
      title
      inspiration_book
      visit_show_homes
      visit_nearby_construction_site
      construction_site_available
      first_name
      last_name
      email
      phone
      address
      zip_code
      city
      first_name_normalized
      last_name_normalized
      email_normalized
      phone_normalized
      date_created
      resolved
      matched_leads {
        id
        first_name1
        first_name2
        last_name1
        last_name2
      }
      prisma_total
    }
  }
`;

export const GET_SUSPECT_BY_ID = gql`
  query getSuspectById($where: SuspectWhereInput) {
    findOneSuspect(where: $where) {
      id
      title
      inspiration_book
      visit_show_homes
      visit_nearby_construction_site
      construction_site_available
      first_name
      last_name
      email
      phone
      address
      zip_code
      city
      first_name_normalized
      last_name_normalized
      email_normalized
      phone_normalized
      date_created
      resolved
      matched_leads {
        id
        address1
        archive_no
        city1
        country
        date_created
        email1
        email2
        first_name1
        first_name2
        last_name1
        last_name2
        mobile1
        mobile1_v2
        mobile2
        mobile2_v2
        phone1
        phone1_v2
        phone2
        phone2_v2
        title1
        title2
        zip_code1
        preferred_way_of_contact
        status
        christmas_card
        only_invoice
        business
        vat_number
        sales_rep_employee_id
        prisma_total
        auth0_id
        sales_rep_employee {
          id
          first_name
          last_name
          app_user {
            id
            email
            auth0_id
            date_created
            email
            id
            last_updated
            username
            version
          }
        }
      }
    }
  }
`;

export interface ISuspect {
  id: number;
  title?: string;
  inspiration_book?: boolean;
  visit_show_homes?: boolean;
  visit_nearby_construction_site?: boolean;
  construction_site_available?: boolean;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  address: string;
  zip_code: number;
  city: string;
  first_name_normalized?: string;
  last_name_normalized?: string;
  email_normalized?: string;
  phone_normalized?: string;
  date_created?: string;
  resolved?: boolean;
  matched_leads?: ILead[];
  prisma_total?: number;
}
