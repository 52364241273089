import { ICommandBarItemProps } from '@fluentui/react';
import React from 'react';
import { CommandBarSticky } from '../../../components/parts';
import { commandBarTheme, commandBarThemeCounter } from '../../../theme';

const ConstructionSitesCommandBar = ({
  modifyFn,
  modifyDisabled = false,
  loadExecutionListFn,
  loadWaterPointsFn,
  loadExecutionListDisabled = false,
  loadConstructionSheetFn,
  loadConstructionSheetDisabled = false,
  loadNewPurchaseOrderFn,
  loadNewPurchaseOrderDisabled = false,
  deleteFn,
  analyseFn,
  analysePdfFn,
  downloadFile,
  deleteDisabled = false,
  openFilterPanel,
  constructionSites,
}: any) => {
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'totaal',
      text: `${constructionSites.length} / ${constructionSites.length > 0 ? constructionSites[0].prisma_total : 0}`,
      theme: commandBarThemeCounter,
    },
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => modifyFn(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => modifyFn(),
      disabled: modifyDisabled,
      theme: commandBarTheme,
    },
    {
      key: 'delete',
      text: 'Verwijder',
      iconProps: { iconName: 'delete' },
      onClick: () => deleteFn(),
      disabled: deleteDisabled,
      theme: commandBarTheme,
    },
    {
      key: 'executionlist',
      text: 'Uitvoeringslijst',
      iconProps: { iconName: 'Trackers' },
      onClick: () => loadExecutionListFn(),
      disabled: loadExecutionListDisabled,
      theme: commandBarTheme,
    },
    {
      key: 'constructionsheet',
      text: 'Werffiche',
      iconProps: { iconName: 'ClipboardList' },
      onClick: () => loadConstructionSheetFn(),
      disabled: loadConstructionSheetDisabled,
      theme: commandBarTheme,
    },
    {
      key: 'waterPointslist',
      text: 'Tappunten',
      iconProps: { iconName: 'WorkItemAlert' },
      onClick: () => loadWaterPointsFn(),
      disabled: loadExecutionListDisabled,
      theme: commandBarTheme,
    },
    {
      key: 'newPurchaseOrder',
      text: 'Bestelbon',
      iconProps: { iconName: 'serverEnviroment' },
      onClick: () => loadNewPurchaseOrderFn(),
      disabled: loadNewPurchaseOrderDisabled,
      theme: commandBarTheme,
    },
    {
      key: 'analyse',
      text: 'Analyse',
      iconProps: { iconName: 'ReportDocument' },
      onClick: () => analyseFn(),
      disabled: modifyDisabled,
      theme: commandBarTheme,
    },
    {
      key: 'analysepdf',
      text: 'Analyse',
      iconProps: { iconName: 'pdf' },
      onClick: () => analysePdfFn(),
      disabled: modifyDisabled,
      theme: commandBarTheme,
    },
    {
      key: 'downloadXLS',
      text: 'Excel',
      iconProps: { iconName: 'ExcelDocument' },
      onClick: () => downloadFile(),
      theme: commandBarTheme,
      // disabled: !selectionDetails,
    },
    {
      key: 'advancedFilter',
      text: 'Filter',
      iconProps: { iconName: 'Filter' },
      onClick: () => openFilterPanel(true),
      theme: commandBarTheme,
      // disabled: !selectionDetails,
    },
  ];

  return (
    <CommandBarSticky
      items={commandBaritems}
      theme={commandBarTheme}
      width='1300px'
      maxWidth='1300px'
    />
  );
};

export default ConstructionSitesCommandBar;
