import React, { useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Label,
  StackItem,
  TextField,
  Dialog,
  DialogFooter,
  IComboBoxOption,
} from '@fluentui/react';
import _ from 'lodash';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { tryEach } from 'async';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  dialogModelProps,
  modalContentStyles,
} from '../../../utils';
import DateView from '../../../components/parts/DateView';
import {
  convertLeadsToComboBoxOptions,
  displayNameLead,
  GET_LEADS,
  ILead,
} from '../../../utils/Lead';
import { dialogRemoveQuoteConfirmationProps } from '../../../utils/Styles';
import { DELETE_QUOTE_REFERENCE } from '../../../utils/QuoteReference';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import useDebounce from '../../../components/hooks/useDebounce';
import { ComboboxWithFilter } from '../../../components/parts';
import { toastError, toastSuccess } from '../../../utils/toast';
import { customPanelStyles } from '../../../theme';

const QuoteDetail = ({
  isOpen,
  dismissPanel,
  saveQuote,
  quote,
  setQuote,
  refetchLead,
}: any) => {
  const [isConfirmationHidden, setIsConfirmationHidden] = useState(true);

  const [deleteQuoteReference] = useMutation(DELETE_QUOTE_REFERENCE);

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setQuote((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  // It is toggle and clear selected value actually :-)
  const toggleConfirmationDialog = () => {
    setIsConfirmationHidden(!isConfirmationHidden);
    // if (!isConfirmationHidden) setSelectedId(0);
  };

  const deleteQuote = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          deleteQuoteReference({
            variables: {
              where: {
                id: quote.id,
              },
            },
            onError: error => {
              toggleConfirmationDialog();
              dismissPanel();
              reject(error);
            },
            onCompleted: async (x: any) => {
              toggleConfirmationDialog();
              dismissPanel();
              await refetchLead();
              resolve(x);
            },
          });
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      await res;
      toastSuccess('Offerte verwijderd');
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  const [leadFilter, setLeadFilter] = useState('');
  const debouncedLeadFilter = useDebounce(leadFilter, 500);

  const getLeadFilter = (filterstring: string) => {
    const filter: any = {
      AND: [],
    };
    if (filterstring) {
      const stringArray = filterstring.split(' ');
      for (let i = 0; i < stringArray.length; i++) {
        filter.AND = [
          ...filter.AND,
          {
            OR: [
              {
                first_name1: {
                  contains: stringArray[i],
                },
              },
              {
                first_name2: {
                  contains: stringArray[i],
                },
              },
              {
                last_name1: {
                  contains: stringArray[i],
                },
              },
              {
                last_name2: {
                  contains: stringArray[i],
                },
              },
            ],
          },
        ];
      }
    }

    return filter;
  };

  const [leads, setLeads] = useState<ILead[]>([]);
  useQuery(GET_LEADS, {
    variables: {
      filter:
        debouncedLeadFilter && debouncedLeadFilter.length > 0
          ? getLeadFilter(debouncedLeadFilter)
          : {},
      take:
        debouncedLeadFilter && debouncedLeadFilter.length > 0 ? undefined : 20,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      const leads = x.findManyLeads;
      if (x.findManyLeads && quote && quote.lead && quote.lead.id) {
        let currentLeadExists = false;
        for (let i = 0; i < x.findManyLeads.length; i++) {
          if (x.findManyLeads[i].id === quote.lead.id) {
            currentLeadExists = true;
          }
        }
        if (!currentLeadExists) {
          leads.push(quote.lead);
        }
      }

      setLeads(leads);
    },
  });

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={quote && quote.id ? 'Offerte wijzigen' : 'Offerte toevoegen'}
      type={PanelType.custom}
      customWidth='500px'
      styles={customPanelStyles}
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {quote && <Label>ID: {quote.id}</Label>}

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <ComboboxWithFilter
              label='Klant'
              options={leads ? convertLeadsToComboBoxOptions(leads) : []}
              value={quote && quote.lead ? quote.lead.id : ''}
              multiline={false}
              callBack={(newValue: IComboBoxOption[]) => {
                if (newValue && newValue.length > 0) {
                  const result = { ...quote };

                  result.lead = {
                    ...result.lead!,
                    id: newValue[0].key as number,
                  };

                  result.updated = true;

                  setQuote(result);
                }
              }}
              required
              allowFreeForm
              errorMessage={quote && !quote.lead ? 'Dit veld is verplicht' : ''}
              setFilter={(value: string) => {
                setLeadFilter(value);
              }}
            />
          </Stack>
          {quote && quote.lead && (
            <a
              href={`/customers/${quote.lead.id}`}
              target='_blank'
              rel='noreferrer'
              style={{ marginTop: '5px', color: '#71BF43' }}
            >
              klantenfiche openen
            </a>
          )}

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='quote_no'
              label='Offerte nr'
              value={quote && quote.quote_no ? quote.quote_no : ''}
              onChange={onChangeTextFieldValue}
            />
          </Stack>
          <Stack style={{ marginBottom: 10 }}>
            <DateView
              item={quote}
              setItem={setQuote}
              date={quote && quote.quote_date}
              label='Datum'
              field='quote_date'
            />
          </Stack>
          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='description'
              label='Omschrijving'
              required
              value={quote && quote.description ? quote.description : ''}
              onChange={onChangeTextFieldValue}
              errorMessage={
                quote && !quote.description ? 'Dit veld is verplicht' : ''
              }
            />
          </Stack>
          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='comments'
              label='Extra info'
              multiline
              value={quote && quote.comments ? quote.comments : ''}
              onChange={onChangeTextFieldValue}
            />
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack
            style={{
              flexDirection: 'row',
              marginTop: 10,
              justifyContent: 'space-between',
            }}
          >
            <Stack style={{ flexDirection: 'row' }}>
              <SaveButtonWithPermissions
                disabled={!quote}
                save={saveQuote}
                permission='write:quoteReferences'
              />

              <StackItem>
                <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
              </StackItem>
            </Stack>

            <Stack>
              <StackItem>
                <DefaultButton onClick={toggleConfirmationDialog}>
                  Verwijderen
                </DefaultButton>
              </StackItem>
            </Stack>
          </Stack>
        </div>
      </div>

      <Dialog
        hidden={isConfirmationHidden}
        onDismiss={toggleConfirmationDialog}
        dialogContentProps={dialogRemoveQuoteConfirmationProps}
        modalProps={dialogModelProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={deleteQuote} text='Verwijderen' />
          <DefaultButton onClick={toggleConfirmationDialog} text='Annuleren' />
        </DialogFooter>
      </Dialog>
    </Panel>
  );
};

export default QuoteDetail;
