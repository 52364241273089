import React, { useRef, useState, useEffect } from 'react';
import {
  ComboBox, IComboBox, IComboBoxOption, Spinner, SpinnerBase, SpinnerSize, Stack,
} from '@fluentui/react';

// value can be a single number (id) or an array of numbers (id's) when multiselect is true
// the callBack function has a single number (id) as parameter or an array of numbers (id's) as parameter when multiselect is true
// callBack(id: number)
// callBack(ids: number[])

type ComboBoxWithFilterProps = {
  callBack: ([string]: IComboBoxOption[]) => void;
  errorMessage?: string;
  label?: any;
  multiline?: boolean;
  options: IComboBoxOption[];
  placeholder?: string;
  value?: string[] | number[] | number | string;
  required?: boolean;
  allowFreeForm?: boolean;
  disabled?: boolean;
  setFilter?: (value: string) => void;
  loading?: boolean;
  style?: React.CSSProperties;
};

const ComboboxWithFilter = ({
  callBack,
  errorMessage = '',
  label = '',
  multiline = false,
  options: inputOptions,
  placeholder = '',
  value = [],
  required = false,
  allowFreeForm = false,
  disabled = false,
  setFilter: setRemoteFilter,
  loading = false,
  style,
}: ComboBoxWithFilterProps) => {
  const combobox = useRef<IComboBox>(null);
  const [selectedKey, setSelectedKey] = React.useState<
    string | number | undefined
  >();
  const [selectedKeys, setSelectedKeys] = useState<
    string[] | number[] | undefined
  >();
  const [options, setOptions] = React.useState<IComboBoxOption[]>([]);
  let newKey = 1;
  const [filter, setFilter] = useState('');

  const onPendingValueChanged = (
    option?: IComboBoxOption | undefined,
    index?: number | undefined,
    currentValue?: string | undefined,
  ) => {
    if (currentValue !== undefined) {
      setRemoteFilter ? setRemoteFilter(currentValue) : setFilter(currentValue);
      combobox.current?.focus(true);
    }
  };

  const getNewKey = React.useCallback((): string | number => {
    if (options) {
      let value: number = newKey++;
      for (let i = 0; i < options.length; i++) {
        const option = options[i];
        if (option.key && typeof option.key === 'number') {
          if (option.key > value) value = option.key;
        }
      }
      return value++;
    }
    return newKey++;
  }, [options, newKey]);

  const onChangeMultiValue = React.useCallback(
    (
      event: React.FormEvent<IComboBox>,
      option?: IComboBoxOption,
      index?: number,
      value?: string,
    ): void => {
      let selected = option?.selected;
      setFilter('');
      if (allowFreeForm && !option && value) {
        // If allowFreeform is true, the newly selected option might be something the user typed that
        // doesn't exist in the options list yet. So there's extra work to manually add it.
        selected = true;
        option = { key: `${getNewKey()}`, text: value };
        setOptions((prevOptions) => [...prevOptions, option!]);
      }

      if (option) {
        const prevSelectedKeys: number[] | string[] = selectedKeys || [];
        setSelectedKeys(
          selected
            ? [...prevSelectedKeys, option!.key as string]
            : (prevSelectedKeys as any[]).filter(
              (k: string | number) => k !== option!.key,
            ),
        );
      }
    },
    [allowFreeForm],
  );

  const filterOptions = () => options.filter((oneOption: IComboBoxOption) => {
    if (multiline) {
      return (
        oneOption.text.toLowerCase().indexOf(filter.toLowerCase()) > -1
          // @ts-ignore
          && selectedKeys.indexOf(+oneOption.key) === -1
      );
    }

    return oneOption.text.toLowerCase().indexOf(filter.toLowerCase()) > -1;
  });

  const onChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string,
  ): void => {
    if (option) {
      let key = option?.key;
      if (allowFreeForm && !option && value) {
        // If allowFreeForm is true, the newly selected option might be something the user typed that
        // doesn't exist in the options list yet. So there's extra work to manually add it.
        key = `${getNewKey()}`;
        setOptions((prevOptions) => [...prevOptions, { key: key!, text: value }]);
      }
      setFilter('');
      setSelectedKey(key);
    }
  };

  useEffect(() => {
    if (selectedKeys && selectedKeys.length > 0) {
      const values: IComboBoxOption[] = options.filter((option) => {
        const key: string | number = option.key;

        if (
          selectedKeys
          && Array.isArray(selectedKeys)
          // @ts-ignore
          && selectedKeys.includes(key)
        ) {
          return true;
        }
      });

      callBack(values);
    }
  }, [selectedKeys]);

  useEffect(() => {
    if (selectedKey) {
      // console.log(selectedKey);
      const values: IComboBoxOption[] = options.filter((option) => {
        const key: string | number = option.key;

        if (selectedKey === key) {
          // console.log(option);
          return true;
        }
      });

      callBack(values);
    }
  }, [selectedKey]);

  useEffect(() => {
    setOptions(inputOptions);
  }, [inputOptions]);

  return (
    <Stack styles={{ root: { position: 'relative' } }}>
      <ComboBox
        disabled={disabled}
        multiSelect={multiline}
        componentRef={combobox}
        placeholder={placeholder}
        allowFreeform={allowFreeForm}
        autoComplete='on'
        label={label}
        options={filterOptions()}
        onChange={multiline ? onChangeMultiValue : onChange}
        errorMessage={errorMessage}
        onPendingValueChanged={onPendingValueChanged}
        required={required}
        selectedKey={selectedKeys || value}
        style={style}
        styles={{
          callout: {
            minWidth: 'fit-content',
            maxHeight: '400px',
            calloutRenderEdge: 'bottom',
          },
        }}
        calloutProps={{
          directionalHintFixed: true,
          directionalHint: 6, // DirectionalHint.bottomLeftEdge
        }}
      />
      {loading && (
        <div style={{ position: 'absolute', right: '40px', top: '35px' }}>
          <Spinner size={SpinnerSize.medium} />
        </div>
      )}
    </Stack>
  );
};

export default ComboboxWithFilter;
