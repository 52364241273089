const parseNumber = (input?: string | number, replacementValue?: number): number => {
    if (!input) return replacementValue ?? 0;
    if (typeof input === 'number') return input;

    if (input.indexOf(',') !== -1) {
        // contains a comma -> strip all dots
        let parsedInput = input.replaceAll('.', '');

        // replace comma by dot
        parsedInput = parsedInput.replaceAll(',', '.');

        if (Number.isNaN(parsedInput)) return replacementValue ?? 0;
        return Number(parsedInput);
    } if (!Number.isNaN(input)) {
        // only return if the string can be parsed
        return Number(input);
    }
    return replacementValue ?? 0;
};

export default parseNumber;

export { parseNumber };
