import { gql } from '@apollo/client';
import { IComboBoxOption } from '@fluentui/react';

export interface ITaxCode {
  id?: number;
  rate?: number;
  label?: string;
  code?: string;
  description?: string;
  requireInformation?: boolean;
  prisma_total?: number;
}

export const GET_TAX_CODES = gql`
  query getTaxCodes(
    $filter: TaxCodeFilterInput
    $orderBy: TaxCodeOrderByInput
    $take: Int
    $skip: Int
  ) {
    findManyTaxCodes(
      filter: $filter
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      id
      rate
      label
      code
      description
      requireInformation
      prisma_total
    }
  }
`;

export const GET_TAX_CODE_BY_ID = gql`
  query getTaxCode($where: TaxCodeWhereUniqueInput) {
    findOneTaxCode(where: $where) {
      id
      rate
      label
      code
      description
      requireInformation
    }
  }
`;

export const ADD_TAX_CODE = gql`
  mutation AddTaxCode($data: TaxCodeCreateInput!) {
    createTaxCode(data: $data) {
      id
      rate
      label
      code
      description
      requireInformation
    }
  }
`;

export const UPDATE_TAX_CODE = gql`
  mutation UpdateTaxCode($id: Int!, $data: TaxCodeUpdateInput!) {
    updateTaxCode(id: $id, data: $data) {
      id
      rate
      label
      code
      description
      requireInformation
    }
  }
`;

export const DELETE_TAX_CODE = gql`
  mutation deleteTaxCode($where: TaxCodeWhereUniqueInput) {
    deleteTaxCode(where: $where) {
      id
    }
  }
`;

export const convertTaxCodesToComboBoxOptions = (
  taxCodes: ITaxCode[],
) => {
  const result: IComboBoxOption[] = taxCodes.map(
    (taxCode: ITaxCode): IComboBoxOption => ({
      key: taxCode.id!,
      text: `${taxCode.label} (${taxCode.rate}%)`,
    }),
  );
  return result;
};
