import React, { useState } from 'react';
import { Icon, Stack } from '@fluentui/react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { GET_TEMPLATES, ITemplate, stackTokens15 } from '../../utils';
import TemplatesOverview from './components/TemplatesOverview';

const Templates = () => {
  const { isAuthenticated } = useAuth0(); // Do we need this check since it is already builtin the routing mechanism?
  const [templates, setTemplates] = useState<ITemplate[]>([]);

  const { loading, error, refetch } = useQuery(GET_TEMPLATES, {
    variables: {
      orderBy: {
        name: 'asc',
      },
    },
    onCompleted: (x: any) => setTemplates(x.findManyTemplates),
  });

  if (!isAuthenticated) return <p>Verboden</p>;
  if (loading) return <p>Laden...</p>;
  if (error) return <p>Fout :(</p>;

  return (
    <Stack tokens={stackTokens15}>

      <TemplatesOverview
        templates={templates}
        updateContents={refetch}
        setUpdatedContents={setTemplates}
      />
    </Stack>
  );
};

export default Templates;
