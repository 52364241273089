import {
 FormEvent, useCallback, useEffect, useState,
} from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import {
 DefaultButton, Icon, Stack, TextField,
} from '@fluentui/react';
import moment from 'moment';
import useDebounce from '../../components/hooks/useDebounce';
import {
  default_page_size,
  normalizeString,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import { GET_LEADS_WITHOUT_REFERENCES, ILead } from '../../utils/Lead';
import { useAppDispatch } from '../../redux/hooks';
import { SeverityLevel, throwError } from '../../redux/error/errorSlice';
import { FilterState } from '../../components/parts/FilterPanel';
import { sendNotification } from '../../redux/notification/notificationSlice';
import CustomersOverview from './components/CustomersOverview';

interface IPagedCustomer {
  customers: ILead[];
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  lazyLoading: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

type IStatus =
  | 'provisional_agreement'
  | 'only_invoice'
  | 'no_wings'
  | 'started'
  | 'ended'
  | 'canceled';

const Customers = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const [pagedState, setPagedState] = useState<IPagedCustomer>({
    customers: [],
    skip: 0,
    take: default_page_size,
    filter: '',
    showLoadMore: true,
    lazyLoading: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: true,
    sortedField: 'last_name1',
  });
  const [parsedFilter, setParsedFilter] = useState<any>();
  const [filter, setFilter] = useState('');
  const [status, setStatus] = useState<IStatus | undefined>(undefined);
  const [leadFilter, setLeadFilter] = useState<FilterState | undefined>();
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);

  const debouncedValue = useDebounce(filter.replace('-', ''), 500);
  /*
  useEffect(() => {
    setPagedState(prevState => ({ ...prevState, customers: [], skip: 0 }));
  }, [filter, leadFilter]);
*/
  /*
  useEffect(() => {
    setPagedState(prevState => ({ ...prevState, customers: [], skip: 0 }));
  }, [status]);
*/
  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const validateEmail = (mail: string) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  };

  const isNumeric = (value: string) => {
    // remove "+" sign from value
    let newValue = value;
    if (newValue.startsWith('+')) {
      newValue = newValue.substring(1);
    }

    console.log(!Number.isNaN(parseInt(newValue, 10)));
    return !Number.isNaN(parseInt(newValue, 10));
  };

  const getFilters = useCallback(
    (
      filterString: string,
      leadFilter: FilterState | undefined,
      filterStatus?: string,
    ) => {
      const filterArray = filterString.split(' ');

      const filters: any = {
        AND: [],
      };

      let filterStatusSet = false;

      if (leadFilter && leadFilter.schema.length > 0) {
        for (let i = 0; i < leadFilter.schema.length; i++) {
          for (let t = 0; t < leadFilter.schema[i].fields.length; t++) {
            if (
              leadFilter.schema[i].fields[t].value &&
              leadFilter.schema[i].fields[t].parsedValue &&
              leadFilter.schema[i].fields[t].value !== ''
            ) {
              filters.AND.push(leadFilter.schema[i].fields[t].parsedValue);
              if (
                leadFilter.schema[i].fields[t].name === 'status' &&
                leadFilter.schema[i].fields[t].parsedValue &&
                (leadFilter.schema[i].fields[t].parsedValue as any).status
              ) {
                filterStatusSet = true;
              }
            }
          }
        }
      }
      if (filterStatus && !filterStatusSet) {
        switch (filterStatus) {
          case 'provisional_agreement':
            filters.AND.push({
              status: { contains: 'PROVISIONAL_AGREEMENT' },
            });
            break;
          case 'only_invoice':
            filters.AND.push({
              OR: [
                { status: { contains: 'INVOICE_ONLY' } },
                { only_invoice: true },
              ],
            });
            break;
          case 'no_wings':
            filters.AND.push({
              wings_code: null,
            });

            filters.AND.push({
              OR: [
                { only_invoice: true },
                { status: { contains: 'SOLD' } },
                { status: { contains: 'INVOICE_ONLY' } },
                { status: { contains: 'PROVISIONAL_AGREEMENT' } },
                { construction_sites: { some: { id: { gt: 0 } } } },
              ],
            });
            break;
          case 'canceled':
            filters.AND.push({
              construction_sites: {
                some: {
                  status: {
                    id: 4,
                  },
                },
              },
            });
            break;
          case 'started':
            filters.AND.push({
              construction_sites: {
                some: {
                  status: {
                    id: 2,
                  },
                },
              },
            });
            break;
          case 'ended':
            filters.AND.push({
              construction_sites: {
                some: {
                  status: {
                    id: 3,
                  },
                },
              },
            });
            break;
          default:
            break;
        }
      } else if (!filterStatusSet) {
        filters.AND.push({
          OR: [
            { status: { contains: 'SOLD' } },
            { status: { contains: 'INVOICE_ONLY' } },
            { status: { contains: 'PROVISIONAL_AGREEMENT' } },
            { status: { contains: 'CANCELED' } },
            { only_invoice: true },
            {
              construction_sites: {
                some: {
                  id: {
                    gt: 0,
                  },
                },
              },
            },
          ],
        });
      }

      for (let i = 0; i < filterArray.length; i++) {
        const filterValue: any = {
          OR: [],
        };

        const emailValid = validateEmail(filterArray[i]);
        const isPhone = isNumeric(filterArray[i]) && filterArray[i].length > 4;
        if (emailValid) {
          filterValue.OR = [
            { email1: { contains: filterArray[i] } },
            { email2: { contains: filterArray[i] } },
          ];
        } else if (isPhone) {
          filterValue.OR = [
            { phone1: { contains: filterArray[i] } },
            { phone1_v2: { contains: filterArray[i] } },
            { phone1search: { contains: filterArray[i] } },
            { phone2: { contains: filterArray[i] } },
            { phone2_v2: { contains: filterArray[i] } },
            { phone2search: { contains: filterArray[i] } },
            { mobile1: { contains: filterArray[i] } },
            { mobile1_v2: { contains: filterArray[i] } },
            { mobile1search: { contains: filterArray[i] } },
            { mobile2: { contains: filterArray[i] } },
            { mobile2_v2: { contains: filterArray[i] } },
            { mobile2search: { contains: filterArray[i] } },
          ];
        } else if (isNumeric(filterArray[i])) {
          const numericValue = parseInt(filterArray[i], 10);
          filterValue.OR = [{ zip_code1: numericValue }];
        } else {
          filterValue.OR = [
            { first_name1: { contains: filterArray[i] } },
            { first_name2: { contains: filterArray[i] } },
            { last_name1: { contains: filterArray[i] } },
            { last_name2: { contains: filterArray[i] } },
            { address1: { contains: filterArray[i] } },
            { city1: { contains: filterArray[i] } },
            { normalized_first_name1: { contains: filterArray[i] } },
            { normalized_first_name2: { contains: filterArray[i] } },
            { normalized_last_name1: { contains: filterArray[i] } },
            { normalized_last_name2: { contains: filterArray[i] } },
            {
              normalized_first_name1: {
                contains: normalizeString(filterArray[i]),
              },
            },
            {
              normalized_first_name2: {
                contains: normalizeString(filterArray[i]),
              },
            },
            {
              normalized_last_name1: {
                contains: normalizeString(filterArray[i]),
              },
            },
            {
              normalized_last_name2: {
                contains: normalizeString(filterArray[i]),
              },
            },
          ];
        }

        filters.AND.push(filterValue);
      }
      console.log(filters);
      return filters;
    },
    [status, leadFilter],
  );

  const downloadFile = useCallback(async () => {
    dispatch(
      sendNotification({
        message: 'bezig met downloaden',
        level: 0,
        module: 'template.updateTemplate',
        spinner: true,
      }),
    );
    const url = `${process.env.REACT_APP_BACKEND_URI}/files/customers/xls`;
    const token = await getAccessTokenSilently();
    const fileName = `customers_${moment().format('YYYYMMDD')}.xlsx`;
    const res = await fetch(url, {
      method: 'post',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ filter: parsedFilter }),
    });

    const result = await res.blob();

    const aElement = document.createElement('a');
    aElement.setAttribute('download', fileName);
    const href = URL.createObjectURL(result);
    aElement.href = href;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(href);

    dispatch(
      sendNotification({
        module: 'template.updateTemplate',
        message: 'Bestand is gedownload',
        level: 1,
        timeout: 2500,
      }),
    );
  }, [filter, status, leadFilter, parsedFilter]);

  const {
    data: dataCustomers,
    loading,
    error,
    fetchMore,
    client,
  } = useQuery(GET_LEADS_WITHOUT_REFERENCES, {
    // notifyOnNetworkStatusChange: true,
    variables: {
      filter: parsedFilter,
      take: pagedState.take,
      skip: pagedState.skip,
      orderBy: getOrderBy(),
    },
    onError: error => {
      dispatch(
        throwError({
          module: 'customer Overview',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
    skip: !parsedFilter,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  useEffect(() => {
    // client.clearStore();
    client.cache.reset();
    const filterObject = getFilters(debouncedValue, leadFilter, status);
    setParsedFilter(filterObject);
  }, [status, leadFilter, debouncedValue, status]);

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    client.cache.reset();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const fetchMoreCustomers = () => {
    // Load more

    fetchMore({
      variables: {
        filter: parsedFilter,
        take: pagedState.take,
        skip: pagedState.skip + pagedState.take,
        orderBy: getOrderBy(),
      },
    });

    setPagedState(prevState => ({
      ...prevState,
      skip: pagedState.skip + pagedState.take,
    }));
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <div style={{ padding: '25px' }}>
        <h3
          style={{
            fontSize: '20px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Icon iconName='TeamFavorite' style={{ fontSize: '20px' }} />
          Klanten
        </h3>
        <div>
          <TextField
            label='Zoeken...'
            value={filter}
            styles={textFieldStyles300}
            onChange={onSearchValueChange}
            placeholder='naam, adres, gemeente, email.'
          />
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              marginTop: '15px',
            }}
          >
            <div>
              <span
                style={{
                  fontSize: '14px',
                  fontWeight: '600',
                  color: 'rgb(50, 49, 48)',
                  display: 'block',
                  padding: '5px 0px',
                }}
              >
                Status:
              </span>
              <div style={{ paddingTop: '2px' }}>
                <DefaultButton
                  text='Alle'
                  onClick={() => {
                    setStatus(undefined);
                  }}
                  primary={status === undefined}
                />
              </div>
            </div>
            <div style={{ marginLeft: '20px' }}>
              <span
                style={{
                  fontSize: '14px',
                  fontWeight: '600',
                  color: 'rgb(50, 49, 48)',
                  display: 'block',
                  padding: '5px 0px',
                }}
              >
                Klant
              </span>
              <div style={{ paddingTop: '2px' }}>
                <DefaultButton
                  text='Voorlopige overeenkomst'
                  onClick={() => {
                    setStatus('provisional_agreement');
                  }}
                  primary={status === 'provisional_agreement'}
                />
                <DefaultButton
                  text='Enkel factuur'
                  styles={{ root: { marginLeft: '10px' } }}
                  onClick={() => {
                    setStatus('only_invoice');
                  }}
                  primary={status === 'only_invoice'}
                />
                <DefaultButton
                  text='Geen wings ID'
                  styles={{ root: { marginLeft: '10px' } }}
                  onClick={() => {
                    setStatus('no_wings');
                  }}
                  primary={status === 'no_wings'}
                />
              </div>
            </div>
            <div style={{ marginLeft: '20px' }}>
              <span
                style={{
                  fontSize: '14px',
                  fontWeight: '600',
                  color: 'rgb(50, 49, 48)',
                  display: 'block',
                  padding: '5px 0px',
                }}
              >
                Werf
              </span>
              <div style={{ paddingTop: '2px' }}>
                <DefaultButton
                  text='Gestart'
                  onClick={() => {
                    setStatus('started');
                  }}
                  primary={status === 'started'}
                />
                <DefaultButton
                  text='Beëindigd'
                  styles={{ root: { marginLeft: '10px' } }}
                  onClick={() => {
                    setStatus('ended');
                  }}
                  primary={status === 'ended'}
                />
                <DefaultButton
                  text='Geannuleerd'
                  styles={{ root: { marginLeft: '10px' } }}
                  onClick={() => {
                    setStatus('canceled');
                  }}
                  primary={status === 'canceled'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomersOverview
        customers={dataCustomers ? dataCustomers.findManyLeads : []}
        loading={loading && (!dataCustomers || !dataCustomers.findManyLeads)}
        setSorting={setSorting}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        showLoadMore={pagedState.showLoadMore}
        loadMore
        loadMoreCallback={fetchMoreCustomers}
        downloadFile={downloadFile}
        lazyLoading={pagedState.lazyLoading}
        setLeadFilter={setLeadFilter}
        isFilterPanelOpen={isFilterPanelOpen}
        setIsFilterPanelOpen={setIsFilterPanelOpen}
      />
    </Stack>
  );
};

export default Customers;
