import { gql } from '@apollo/client';
import { ICustomerContractUpdate } from './CustomerContractUpdate';
import { ITaxCode } from './TaxCode';

export const GET_CUSTOMER_CONTRACT_UPDATE_LINE_ITEMS = gql`
  query getCustomerContractUpdateLineItems(
    $filter: CustomerContractUpdateLineItemFilterInput
    $take: Int
    $skip: Int
    $orderBy: CustomerContractUpdateLineItemOrderByInput
  ) {
    findManyCustomerContractUpdateLineItems(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
    }
  }
`;

export const GET_CUSTOMER_CONTRACT_UPDATE_LINE_ITEM_BY_ID = gql`
  query getCustomerContractUpdateLineItemById(
    $where: CustomerContractUpdateLineItemWhereInput
  ) {
    findOneCustomerContractUpdateLineItem(where: $where) {
      comments
      customer_contract_update_id
      description
      id
      percentage_vat
      show_only_total
      units
      unit_price
      version

      tax_code {
        id
        label
        rate
      }

      customer_contract_update {
        id
        document_date
        document_no
        intro
        outro
        status
        summary

        construction_site {
          id
          name
        }
      }
    }
  }
`;

export const ADD_CUSTOMER_CONTRACT_UPDATE_LINE_ITEM = gql`
  mutation addCustomerContractUpdateLineItem(
    $data: CustomerContractUpdateLineItemCreateInput!
  ) {
    createCustomerContractUpdateLineItem(data: $data) {
      id
      customer_contract_update {
        id
        customer_invoice_id
        document_date
        document_no
        intro
        outro
        status
        summary

        construction_site {
          id
          name
        }

        customer_contract_update_line_items {
          id
          description
          units
          unit_price
          percentage_vat
          show_only_total
          tax_code {
            id
            label
            rate
            description
          }
        }
      }
    }
  }
`;

export const UPDATE_CUSTOMER_CONTRACT_UPDATE_LINE_ITEM = gql`
  mutation updateCustomerContractUpdateLineItem(
    $id: Int!
    $data: CustomerContractUpdateLineItemUpdateInput
  ) {
    updateCustomerContractUpdateLineItem(id: $id, data: $data) {
      id
      customer_contract_update {
        id
        customer_invoice_id
        document_date
        document_no
        intro
        outro
        status
        summary

        construction_site {
          id
          name
        }

        customer_contract_update_line_items {
          id
          description
          units
          unit_price
          percentage_vat
          show_only_total
          tax_code {
            id
            label
            rate
            description
          }
        }
      }
    }
  }
`;

export const DELETE_CUSTOMER_CONTRACT_UPDATE_LINE_ITEM = gql`
  mutation deleteCustomerContractUpdateLineItem(
    $where: CustomerContractUpdateLineItemWhereInput
  ) {
    deleteCustomerContractUpdateLineItem(where: $where) {
      id
      customer_contract_update {
        id
        customer_invoice_id
        document_date
        document_no
        intro
        outro
        status
        summary

        construction_site {
          id
          name
        }

        customer_contract_update_line_items {
          id
          description
          units
          unit_price
          percentage_vat
          show_only_total
          tax_code {
            id
            label
            rate
            description
          }
        }
      }
    }
  }
`;

export interface ICustomerContractUpdateLineItem {
  comments?: string;
  customer_contract_update_id?: number;
  description?: string;
  id?: number;
  percentage_vat?: number | string;
  show_only_total?: boolean;
  units?: number | string;
  unit_price?: number | string;
  version?: number;
  isNew?: boolean;
  isDirty?: boolean;
  delete?: boolean;
  customer_contract_update?: ICustomerContractUpdate;
  tax_code?: ITaxCode;
}
