/* eslint-disable no-underscore-dangle */
import React, { useRef } from 'react';
import { ComboBox, IComboBoxOption, IComboBox } from '@fluentui/react';

type ICustomComboBox = {
  autoComplete?: 'on' | 'off';
  onSelect: (key?: number | string | string[], value?: string) => void;
  onPendingChange?: (value?: string) => void;
  errorMessage?: string;
  label?: any;
  ariaLabel?: string;
  id?: string;
  multiSelect?: boolean;
  options: IComboBoxOption[];
  placeholder?: string;
  value?: string;
  selectedKey?: string | string[] | number | number[];
  required?: boolean;
  allowFreeForm?: boolean;
  disable?: boolean;
};

const ComboBoxWithFilter: React.FC<ICustomComboBox> = ({
  autoComplete = 'off',
  onSelect = () => {},
  onPendingChange = () => {},
  errorMessage,
  label,
  ariaLabel,
  id,
  multiSelect = false,
  options,
  placeholder,
  value = '',
  selectedKey,
  required = false,
  allowFreeForm = false,
  disable,
}) => {
  const combobox = useRef<IComboBox>(null);

  const _onChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption | undefined,
    index?: number | undefined,
    value?: string | undefined,
  ) => {
    onPendingChange(undefined);

    if (option) {
      const selected = option.selected;
      if (multiSelect) {
        const currentSelection: string[] =
          selectedKey && Array.isArray(selectedKey)
            ? (selectedKey as string[])
            : [];

        const newKeys = selected
          ? [...currentSelection, option!.key as string]
          : currentSelection.filter(k => k !== option!.key);

        onSelect(newKeys);
      } else {
        onSelect(option.key, value);
      }
    } else if (value) {
      onSelect(undefined, value);
    }
  };

  const _onPendingValueChanged = (newValue: string, composing?: boolean) => {
    if (newValue && newValue.length > 0) {
      onPendingChange(newValue);
    } else {
      onPendingChange('');
    }
    combobox.current?.focus(true);
  };

  return (
    <ComboBox
      componentRef={combobox}
      multiSelect={multiSelect}
      label={label}
      id={id}
      ariaLabel={ariaLabel}
      placeholder={placeholder}
      allowFreeform={allowFreeForm}
      autoComplete={autoComplete}
      options={options}
      autofill={{
        onInputChange: _onPendingValueChanged,
        value,
      }}
      disabled={disable}
      onChange={_onChange}
      required={required}
      errorMessage={errorMessage}
      selectedKey={selectedKey}
    />
  );
};

export default ComboBoxWithFilter;
