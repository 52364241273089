import { gql } from '@apollo/client';
import { IComboBoxOption } from '@fluentui/react';

export const GET_ARCHITECTS = gql`
  query getArchitects(
    $orderBy: ArchitectOrderByInput
    $filter: ArchitectFilterInput
    $skip: Int
    $take: Int
  ) {
    findManyArchitects(
      orderBy: $orderBy
      filter: $filter
      take: $take
      skip: $skip
    ) {
      id
      prisma_total
      first_name
      last_name
      company
      city
      email
      mobile
      phone
      mobile_v2
      phone_v2
    }
  }
`;

export const GET_ARCHITECT_BY_ID = gql`
  query getArchitectById($where: ArchitectWhereInput) {
    findOneArchitect(where: $where) {
      id
      first_name
      last_name
      company
      address
      zip_code
      city
      email
      mobile
      phone
      mobile_v2
      phone_v2
      website
      comments
    }
  }
`;

export const ADD_ARCHITECT = gql`
  mutation AddArchitect($data: ArchitectCreateInput!) {
    createArchitect(data: $data) {
      id
    }
  }
`;

export const UPDATE_ARCHITECT = gql`
  mutation UpdateArchitect($id: Int!, $data: ArchitectUpdateInput!) {
    updateArchitect(id: $id, data: $data) {
      id
      first_name
      last_name
      company
      city
      email
      mobile
      phone
      mobile_v2
      phone_v2
    }
  }
`;

export interface IArchitect {
  id: number;
  first_name: string;
  last_name: string;
  company: string;
  city: string;
  email: string;
  mobile: string;
  mobile_v2: string;
  phone: string;
  phone_v2: string;
  website: string;
  adress: string;
  zip_code: string;
  comments: string;
  prisma_total?: number;
}

export const convertArchitectsToComboBoxOptions = (
  architects: IArchitect[],
) => {
  const result: IComboBoxOption[] = architects.map(
    (architect: IArchitect): IComboBoxOption => ({
      key: architect.id,
      text: displayNameArchitect(architect).substring(0, 50),
    }),
  );
  return result;
};

export const displayNameArchitect = (employee: IArchitect) =>
  '{first_name} {last_name} ({company})'
    .replace('{first_name}', employee.first_name)
    .replace('{last_name}', employee.last_name)
    .replace('{company}', employee.company);
