import {
  IComboBoxOption,
  Icon,
  Label,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  TextField,
  DayOfWeek,
  defaultDatePickerStrings,
  DatePicker,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/nl-be';
import {
  LazyQueryExecFunction,
  OperationVariables,
  useMutation,
  useQuery,
} from '@apollo/react-hooks';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { ComboboxWithFilter } from '../../../components/parts';
import {
  convertConstructionSitesToComboBoxOptions,
  convertEmployeesToComboBoxOptions,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  GET_CONSTRUCTION_SITES,
  GET_EMPLOYEES,
  iconLastProps,
} from '../../../utils';
import {
  GET_GROUPED_TIMESHEET_ENTRIES,
  ITimesheetEntry,
} from '../../../utils/TimesheetEntry';
import {
  convertCostCentersToComboBoxOptions,
  GET_COST_CENTERS,
  ICostCenter,
} from '../../../utils/CostCenter';
import {
  ITimesheetEntryWithType,
  SAVE_TIMESHEETS,
} from '../../../utils/Timesheet';
import useDebounce from '../../../components/hooks/useDebounce';
import parseNumber from '../../../utils/Numbers';
import { toastSuccess, toastError } from '../../../utils/toast';
import SuggestedViewStatus from './SuggestedViewStatus';

// moment.locale('nl-be');

type Props = {
  days: moment.Moment[];
  timesheetEntry: any;
  timesheet: any;
  setTimesheet: any;
  index: number;
  lastRowIndex: number;
  addRow: () => void;
};

type TimeSheetProps = {
  timesheet: ITimesheetEntryWithType[];
  setTimesheet: React.Dispatch<React.SetStateAction<ITimesheetEntryWithType[]>>;
  getTimesheet: LazyQueryExecFunction<any, OperationVariables>;
  // getGroupedTimesheets: LazyQueryExecFunction<any, OperationVariables>,
  setGroupedTimesheetFilter: React.Dispatch<any>;
  groupedFilter: any;
  currentDate: moment.Moment;
  setCurrentDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
  days: moment.Moment[];
  setDays: React.Dispatch<React.SetStateAction<moment.Moment[]>>;
  duration: number;
};

const TimeSheetRow = ({
  timesheetEntry,
  timesheet,
  setTimesheet,
  index,
  days,
  addRow,
  lastRowIndex,
}: Props) => {
  const [constructionSiteFilter, setConstructionSiteFilter] = useState('');

  const debouncedFilter = useDebounce(constructionSiteFilter, 500);

  const { loading: projectsLoading, data: projectsData } = useQuery(
    GET_CONSTRUCTION_SITES,
    {
      fetchPolicy: 'no-cache',
      variables: {
        orderBy: {
          name: 'asc',
        },
        filter: debouncedFilter
          ? {
              name: {
                contains: debouncedFilter,
              },
            }
          : undefined,
        take: 15,
      },
    },
  );

  const { data: costCentersData } = useQuery(GET_COST_CENTERS, {
    fetchPolicy: 'no-cache',
  });

  return (
    <Stack
      style={{
        flexDirection: 'row',
        borderBottom: '1px solid rgb(239, 239, 239)',
        alignItems: 'top',
      }}
      key={`timesheet-lines-${index}`}
    >
      <StackItem
        style={{
          width: '20%',
          padding: 10,
        }}
      >
        {timesheetEntry.type === 'absence' ||
        timesheetEntry.status !== 'tse_stat_3136' ? (
          <div>
            <SuggestedViewStatus
              item={timesheet}
              setItem={setTimesheet}
              code={
                timesheetEntry &&
                timesheetEntry.status &&
                timesheetEntry.status.substr(timesheetEntry.status.length - 4)
              }
              timesheetLine={index}
              required
              errorMessage={
                timesheetEntry &&
                !timesheetEntry.status &&
                timesheetEntry.status !== 'tse_stat_3136'
                  ? 'Dit veld is verplicht'
                  : ''
              }
            />
          </div>
        ) : (
          <div>
            <ComboboxWithFilter
              options={convertConstructionSitesToComboBoxOptions(
                projectsData && projectsData.findManyConstructionSites
                  ? timesheetEntry.construction_site &&
                    timesheetEntry.construction_site.name
                    ? projectsData.findManyConstructionSites.concat(
                        timesheetEntry.construction_site,
                      )
                    : projectsData.findManyConstructionSites
                  : [],
              )}
              value={
                timesheetEntry &&
                timesheetEntry.construction_site &&
                timesheetEntry.construction_site.id
              }
              multiline={false}
              callBack={(newValue: IComboBoxOption[]) => {
                if (newValue && newValue.length > 0) {
                  const result = _.cloneDeep(timesheet);
                  result[index].construction_site = {
                    id: newValue[0].key,
                  };

                  setTimesheet(result);
                }
              }}
              setFilter={filterValue => {
                setConstructionSiteFilter(filterValue);
              }}
              allowFreeForm
            />
          </div>
        )}
      </StackItem>
      <StackItem
        style={{
          width: '20%',
          padding: 10,
        }}
      >
        {timesheetEntry.type === 'absence' ||
        timesheetEntry.status !== 'tse_stat_3136' ? (
          <div />
        ) : (
          <ComboboxWithFilter
            options={convertCostCentersToComboBoxOptions(
              costCentersData &&
                costCentersData.findManyCostCenters.sort(
                  (obj1: ICostCenter, obj2: ICostCenter) =>
                    obj1.name &&
                    obj2.name &&
                    obj1.name.localeCompare(obj2.name),
                ),
            )}
            required
            errorMessage={
              timesheetEntry &&
              timesheetEntry.status === 'tse_stat_3136' &&
              !timesheetEntry.cost_center.id
                ? 'Dit veld is verplicht'
                : ''
            }
            value={
              timesheetEntry &&
              timesheetEntry.cost_center &&
              timesheetEntry.cost_center.id
            }
            multiline={false}
            callBack={(newValue: IComboBoxOption[]) => {
              if (newValue && newValue.length > 0) {
                const result = _.cloneDeep(timesheet);
                result[index].cost_center.id = +newValue[0].key;
                setTimesheet(result);
              }
            }}
            allowFreeForm
          />
        )}
      </StackItem>
      <StackItem
        style={{
          width: '20%',
          padding: 10,
        }}
      >
        <TextField
          value={timesheetEntry.comments || ''}
          onChange={(
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string,
          ) => {
            const result = _.cloneDeep(timesheet);
            result[index].comments = newValue;
            setTimesheet(result);
          }}
        />
      </StackItem>
      {days.map((day, key: number) => {
        const entry: any = timesheetEntry.values.find(
          (x: any) =>
            // return moment(x.entry_date).isSameOrAfter(day) && moment(x.entry_date).isSameOrBefore(day);
            moment(x.entry_date).add('1', 'second').isSame(day, 'day'),
          // eslint-disable-next-line function-paren-newline
        );
        const entryIndex: number = timesheetEntry.values.findIndex((x: any) =>
          moment(x.entry_date).isSame(day, 'day'));
        return (
          <StackItem
            style={{
              width: '5%',
              padding: 10,
              backgroundColor:
                key === 5 || key === 6 ? 'rgb(239, 239, 239)' : 'transparent',
            }}
            key={`day-${index}-${key}-${entry && entry.id}`}
          >
            <TextField
              name={entry ? `day-${index}-${entry.id}` : `day-${index}`}
              value={entry ? entry.hours : '0'}
              min={0}
              onKeyDown={e => {
                // key is tab

                if (
                  e.key === 'Tab' &&
                  index === lastRowIndex &&
                  key === days.length - 1
                ) {
                  addRow();
                }
              }}
              onChange={(
                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string,
              ) => {
                const result = _.cloneDeep(timesheet);
                if (entry && result[index]) {
                  result[index].values[entryIndex].hours = newValue;
                  setTimesheet(result);
                } else {
                  const result = _.cloneDeep(timesheet);
                  result[index].values.push({
                    hours: newValue,
                    entry_date: moment(day).toISOString(),
                  });
                  setTimesheet(result);
                }
              }}
            />
          </StackItem>
        );
      })}
      <StackItem
        style={{
          alignSelf: 'center',
          width: '2%',
          textAlign: 'right',
        }}
        onClick={() => {
          const result = _.cloneDeep(timesheet);
          result[index].values.map((value: any, x: number) => {
            result[index].values[x].hours = 0;
          });
          setTimesheet(result);
        }}
      >
        <Icon iconName='Delete' styles={iconLastProps} />
      </StackItem>
    </Stack>
  );
};

function NewTimesheetEmployee({
  timesheet,
  setTimesheet,
  getTimesheet,
  currentDate,
  setCurrentDate,
  days,
  setDays,
  duration,
  setGroupedTimesheetFilter,
  groupedFilter,
}: TimeSheetProps) {
  const { loading: employeesLoading, data: employeesData } = useQuery(
    GET_EMPLOYEES,
    {
      fetchPolicy: 'no-cache',
      variables: {
        filter: {
          active: true,
          is_external: false,
        },
      },
    },
  );

  /* const { loading: customersLoading, data: customersData } = useQuery(
    GET_LEADS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        filter: { AND: [{ status: { contains: 'SOLD' } }] },
        take: 10,
      },
    },
  ); */

  const [currentEmployee, setCurrentEmployee] = useState<number | string>();
  const [loader, setLoader] = useState(false);

  const [workedHours, setWorkedHours] = useState(0);
  const [firstSortingDone, setfirstSortingDone] = useState(false);

  useEffect(() => {
    let hours = 0;
    timesheet.map((entry: any, i: number) => {
      entry.values.map((value: any, i: number) => {
        hours += value.hours;
      });
    });

    setWorkedHours(parseNumber(hours));

    if (!firstSortingDone && timesheet && timesheet.length > 0) {
      // Sort timesheet
      timesheet.sort(
        (obj1: ITimesheetEntryWithType, obj2: ITimesheetEntryWithType) => {
          if (
            (obj1.construction_site || obj1.lead || obj1.customer) &&
            (obj2.construction_site || obj2.lead || obj2.customer)
          ) {
            const x1 = obj1.construction_site
              ? obj1.construction_site.name
              : obj1.lead
              ? obj1.lead.first_name1
              : obj1.customer.first_name1;
            const x2 = obj2.construction_site
              ? obj2.construction_site.name
              : obj2.lead
              ? obj2.lead.first_name1
              : obj2.customer
              ? obj2.customer.first_name1
              : undefined;
            if (x2) {
              return x1.localeCompare(x2);
            }
            return 1;
          }
          return obj1.status.localeCompare(obj2.status);
        },
      );
      setfirstSortingDone(true);
    }
  }, [timesheet]);

  const [saveTimesheetEntries] = useMutation(SAVE_TIMESHEETS, {
    refetchQueries: [
      {
        query: GET_GROUPED_TIMESHEET_ENTRIES,
        variables: {
          filter: groupedFilter,
        },
      },
    ],
  });

  const getValues = (values: any, comments?: string) => {
    const valuesWithoutType: any[] = [];
    values.map((value: ITimesheetEntry) => {
      valuesWithoutType.push({
        id: value.id || undefined,
        hours: parseNumber(value.hours),
        entry_date: value.entry_date,
        comments,
      });
    });

    return valuesWithoutType;
  };

  const parseTimesheets = (timesheets: ITimesheetEntryWithType[]) => {
    const newTimeSheets = _.cloneDeep(timesheets);

    for (let i = 0; i < newTimeSheets.length; i++) {
      const timesheet = newTimeSheets[i];
      if (timesheet.values && timesheet.values.length > 0) {
        timesheet.comments = timesheet.values[0].comments;
      }
    }

    return newTimeSheets;
  };

  const saveTimesheet = async () => {
    try {
      if (timesheet) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput: any[] = [];
            timesheet.map(
              (timesheetEntry: ITimesheetEntryWithType, i: number) => {
                allInput.push({
                  customer: timesheetEntry.customer
                    ? { id: timesheetEntry.customer.id }
                    : undefined,
                  lead: timesheetEntry.lead
                    ? { id: timesheetEntry.lead.id }
                    : undefined,
                  employee: { id: currentEmployee },
                  cost_center: timesheetEntry.cost_center
                    ? { id: timesheetEntry.cost_center.id }
                    : undefined,
                  construction_site:
                    timesheetEntry.construction_site &&
                    timesheetEntry.construction_site.id
                      ? { id: +timesheetEntry.construction_site.id }
                      : undefined,
                  status: timesheetEntry.status,
                  values: getValues(
                    timesheetEntry.values,
                    timesheetEntry.comments,
                  ),
                });
              },
            );

            saveTimesheetEntries({
              variables: { data: allInput },
              update: (cache, data: any) => {
                setTimesheet(parseTimesheets(data.data.saveTimesheetObject));
              },
              onCompleted: async x => {
                resolve(x);
              },
              onError: async error => {
                reject(error);
              },
            });
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
        toastSuccess('Werkuren opgeslagen');
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  useEffect(() => {
    setGroupedTimesheetFilter({
      AND: [
        {
          entry_date: {
            gte: moment(currentDate).startOf('week').toISOString(),
          },
        },
        {
          entry_date: {
            lte: moment(currentDate).endOf('week').toISOString(),
          },
        },
        {
          employee: {
            id: {
              equals: currentEmployee,
            },
          },
        },
      ],
    });
  }, [currentDate, currentEmployee, setGroupedTimesheetFilter]);

  return (
    <Stack style={{ flexDirection: 'column' }}>
      <Stack
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '25px',
          padding: '0px 25px',
        }}
      >
        <Stack>
          <Stack style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Label style={{ marginRight: 8, minWidth: '100px' }}>Datum:</Label>
            <DatePicker
              firstDayOfWeek={DayOfWeek.Monday}
              placeholder='Select a date...'
              ariaLabel='Select a date'
              // DatePicker uses English strings by default. For localized apps, you must override this prop.
              strings={defaultDatePickerStrings}
              onSelectDate={date => {
                setDays([]);
                setCurrentDate(moment(date).startOf('week'));
                if (currentEmployee) {
                  getTimesheet({
                    variables: {
                      start: moment(date).startOf('week').toISOString(),
                      employee: currentEmployee,
                      duration,
                    },
                  });
                }
              }}
              value={currentDate.toDate()}
              style={{
                width: '250px',
              }}
            />
          </Stack>

          <Stack style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Label style={{ marginRight: 8, minWidth: '100px' }}>
              Medewerker:
            </Label>
            <StackItem style={{ width: 220 }}>
              {employeesLoading ? (
                <Stack>
                  <Spinner size={SpinnerSize.medium} />
                </Stack>
              ) : employeesData &&
                employeesData.findManyEmployees &&
                employeesData.findManyEmployees.length > 0 ? (
                // eslint-disable-next-line react/jsx-indent
                <ComboboxWithFilter
                  options={convertEmployeesToComboBoxOptions(
                    employeesData.findManyEmployees,
                  )}
                  value={currentEmployee}
                  multiline={false}
                  allowFreeForm
                  style={{
                    width: '250px',
                  }}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      setCurrentEmployee(newValue[0].key);
                      getTimesheet({
                        variables: {
                          duration,
                          employee: newValue[0].key,
                          start: moment(currentDate)
                            .startOf('week')
                            .toISOString(),
                        },
                      });
                    }
                  }}
                />
              ) : (
                <Stack>&nbsp;</Stack>
              )}
            </StackItem>
          </Stack>
        </Stack>
        <Stack style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Label style={{ marginRight: 5 }}>Aantal gewerkte uren:</Label>
          <StackItem>{workedHours.toFixed(2)} / 40</StackItem>
        </Stack>
      </Stack>
      <Stack
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',

          alignSelf: 'flex-end',
          alignItems: 'center',
          marginTop: '25px',
          padding: '0px 10px',
        }}
      >
        <StackItem style={{ fontWeight: 700 }}>
          <PrimaryButton
            disabled={!currentEmployee}
            onClick={() => {
              setDays([]);
              getTimesheet({
                variables: {
                  start: moment(currentDate).add(-1, 'w').toISOString(),
                  employee: currentEmployee,
                  duration,
                },
              });
              setCurrentDate(moment(currentDate).add(-1, 'w'));
            }}
          >
            &lt;
          </PrimaryButton>
        </StackItem>
        <StackItem style={{ fontWeight: 700, fontSize: 15 }}>
          Week {moment(currentDate).isoWeek()}:{' '}
          {moment(currentDate)
            .startOf('week')
            .locale('nl-be')
            .format('dd DD/MM/YYYY')}{' '}
          -{' '}
          {moment(currentDate)
            .startOf('week')
            .add(6, 'd')
            .format('dd DD/MM/YYYY')}
        </StackItem>
        <StackItem style={{ fontWeight: 700 }}>
          <PrimaryButton
            disabled={!currentEmployee}
            onClick={() => {
              setDays([]);
              getTimesheet({
                variables: {
                  start: moment(currentDate).add(1, 'w').toISOString(),
                  employee: currentEmployee,
                  duration,
                },
              });
              setCurrentDate(moment(currentDate).add(1, 'w'));
            }}
          >
            &gt;
          </PrimaryButton>
        </StackItem>
      </Stack>

      <Stack>
        <Stack
          style={{
            flexDirection: 'row',
            borderBottom: '1px solid rgb(229, 229, 229)',
            fontWeight: 600,
          }}
        >
          <StackItem
            style={{
              width: '20%',
              padding: 10,
              fontWeight: 600,
            }}
          >
            Project / Taak
          </StackItem>
          <StackItem
            style={{
              width: '20%',
              padding: 10,
              fontWeight: 600,
            }}
          >
            Post
          </StackItem>
          <StackItem
            style={{
              width: '20%',
              padding: 10,
              fontWeight: 600,
            }}
          >
            Notitie
          </StackItem>
          {days.map((day, i: number) => (
            <StackItem
              style={{
                width: '5%',
                padding: 10,
                fontWeight: 600,
              }}
              key={`timesheet-days-${i}`}
            >
              {moment(day).format('ddd')}
              <br />
              {moment(day).format('DD/MM')}
            </StackItem>
          ))}
          <StackItem style={{ width: '2%' }}>&nbsp;</StackItem>
        </Stack>
        {timesheet &&
          timesheet.length > 0 &&
          timesheet.map(
            (timesheetEntry: ITimesheetEntryWithType, i: number) => (
              <TimeSheetRow
                days={days}
                timesheetEntry={timesheetEntry}
                timesheet={timesheet}
                setTimesheet={setTimesheet}
                index={i}
                key={`timeSheetEntry-${timesheetEntry.id}`}
                lastRowIndex={timesheet.length - 1}
                addRow={() => {
                  setTimesheet((prevState: any) => [
                    ...prevState,
                    {
                      type: 'project',
                      customer: undefined,
                      cost_center: {},
                      status: 'tse_stat_3136',
                      employee: {
                        id: currentEmployee,
                      },
                      values: [],
                    },
                  ]);
                }}
              />
            ),
          )}
      </Stack>

      <Stack
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '25px',
        }}
      >
        <Stack style={{ flexDirection: 'row' }}>
          <PrimaryButton
            style={{ marginRight: 5 }}
            disabled={!currentEmployee}
            onClick={() => {
              setTimesheet((prevState: any) => [
                ...prevState,
                {
                  type: 'project',
                  customer: undefined,
                  cost_center: {},
                  status: 'tse_stat_3136',
                  employee: {
                    id: currentEmployee,
                  },
                  values: [],
                },
              ]);
            }}
          >
            Project toevoegen
          </PrimaryButton>
          <PrimaryButton
            disabled={!currentEmployee}
            onClick={() => {
              setTimesheet((prevState: any) => [
                ...prevState,
                {
                  type: 'absence',
                  customer: undefined,
                  cost_center: undefined,
                  employee: {
                    id: currentEmployee,
                  },
                  values: [],
                },
              ]);
            }}
          >
            Afwezigheid toevoegen
          </PrimaryButton>
        </Stack>
        <Stack>
          <PrimaryButton
            disabled={!currentEmployee}
            onClick={() => saveTimesheet()}
          >
            Werkuren opslaan
            {loader && (
              <Spinner style={{ marginLeft: 10 }} size={SpinnerSize.small} />
            )}
          </PrimaryButton>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default NewTimesheetEmployee;
