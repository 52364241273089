import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ICommandBarItemProps,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
} from '@fluentui/react';
import jwt_decode from 'jwt-decode';
import { useAuth0 } from '@auth0/auth0-react';
import { stackTokens5 } from '../../utils';
import { commandBarTheme } from '../../theme';
import { useAppSelector } from '../../redux/hooks';
import { getPermissions } from '../../redux/permission/permissionSlice';
import CommandBarSticky from './CommandBarSticky';

const ConstructionSiteMenu = ({
  id,
  isExecutionList = false,
  isConstructionSheet = false,
  saveConstructionSite,
  download,
  status = '',
  editItem,
  canEditItem = false,
  markItem,
  toggleDeleteItem,
  addNewItem,
  isMarked = false,
  isDeleted = false,
  setCurrentView,
  documentEdited,
}: any) => {
  const navigate = useNavigate();
  const permissions = useAppSelector(getPermissions);

  const commandBarItems = (): ICommandBarItemProps[] => {
    const items: ICommandBarItemProps[] = [
      {
        key: 'addNewField',
        text: 'Nieuw veld',
        iconProps: { iconName: 'Add' },
        onClick: () => addNewItem(),
        theme: commandBarTheme,
      },
      /*
      {
        key: 'toConstructionsSiteModify',
        text: 'Wijzig werf',
        iconProps: { iconName: 'edit' },
        onClick: () => history.push(`/construction-sites/${id}`),
        theme: commandBarTheme,
      }, */
      {
        key: 'modifyItem',
        text: 'Wijzig item',
        cacheKey: 'modifyPropertyCache',
        iconProps: { iconName: 'Edit' },
        onClick: () => editItem(),
        theme: commandBarTheme,
        disabled: !canEditItem,
      },
      {
        key: 'deleteItem',
        text: isDeleted ? 'Verwijderen ongedaan maken' : 'Verwijder item',
        cacheKey: 'modifyPropertyCache',
        iconProps: { iconName: 'Trash' },
        onClick: () => toggleDeleteItem(),
        theme: commandBarTheme,
        disabled: !canEditItem,
      },
      {
        key: 'markItem',
        text: isMarked ? 'Markering verwijderen' : 'Markeer',
        cacheKey: 'modifyPropertyCache',
        iconProps: { iconName: 'Highlight' },
        onClick: () => markItem(!isMarked),
        theme: commandBarTheme,
        disabled: !canEditItem,
      },
      {
        key: 'newPurchaseOrder',
        text: 'Bestelbon',
        iconProps: { iconName: 'ServerEnviroment' },
        onClick: () =>
          navigate(
            `/purchase-orders?addNew=true&constructionSite=${id}`,
          ),
        theme: commandBarTheme,
      },
      {
        key: 'purchaseOrders',
        text: 'Bestelbonnen',
        iconProps: { iconName: 'ServerEnviroment' },
        onClick: () =>
          navigate(
            `/purchase-orders?constructionSite=${id}`,
          ),
        theme: commandBarTheme,
      },
      {
        key: 'toConstructionSheet',
        text: isExecutionList ? 'Werffiche' : 'Uitvoeringslijst',
        iconProps: {
          iconName: isExecutionList ? 'ClipboardList' : 'Trackers',
        },
        onClick: () => {
          setCurrentView(
            isExecutionList ? 'site-overview' : 'implementation-list',
          );
        },
        theme: commandBarTheme,
      },
      {
        key: 'toWaterPointsList',
        text: 'Tappunten',
        iconProps: {
          iconName: 'WorkItemAlert',
        },
        onClick: () => {
          setCurrentView(
            'water-points',
          );
        },
        theme: commandBarTheme,
      },
      {
        key: 'saveConstructionSite',
        text: status === 'saved' ? 'Opgeslagen' : 'Opslaan',
        iconProps: { iconName: 'Save' },
        onClick: () => saveConstructionSite(),
        theme: commandBarTheme,
        disabled:
          !documentEdited || !permissions.includes('write:constructionSites'),
      },
      {
        key: 'download',
        text: 'PDF',
        iconProps: { iconName: 'Pdf' },
        onClick: () => download(),
        theme: commandBarTheme,
      },
    ];

    if (status) {
      switch (status) {
        case 'saved':
          items.push({
            key: 'saved',
            iconProps: { iconName: 'CheckMark' },
            theme: commandBarTheme,
          });
          break;
        case 'saving':
          items.push({
            key: 'saving',
            theme: commandBarTheme,
            onRender: () => (
              <Stack horizontal tokens={stackTokens5} verticalAlign='center'>
                <StackItem>
                  <Spinner size={SpinnerSize.small} />
                </StackItem>
              </Stack>
            ),
          });
          break;
        default:
          break;
      }
    }

    return items;
  };

  const farItems: ICommandBarItemProps[] = [
    {
      key: 'toConstructionsSites',
      text: 'Werfoverzicht',
      iconProps: { iconName: 'Back' },
      onClick: () => navigate('/construction-sites/'),
      theme: commandBarTheme,
    },
  ];

  return (
    <div>
      {/* <CommandBar
                items={commandBarItems()}
                farItems={farItems}
                ariaLabel="Gebruik pijltjes toetsen om te navigeren tussen verschillende commando's"
                theme={commandBarTheme} /> */}

      <CommandBarSticky
        items={commandBarItems()}
        farItems={farItems}
        ariaLabel="Gebruik pijltjes toetsen om te navigeren tussen verschillende commando's"
        theme={commandBarTheme}
        // width={'100%'}
        // maxWidth={isExecutionList ? '1036px' : '1275px'}
      />
    </div>
  );
};

export default ConstructionSiteMenu;
