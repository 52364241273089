import {
    DetailsListLayoutMode, IColumn, IObjectWithKey, ISelection, SelectionMode,
} from '@fluentui/react';
import { useCallback, useState } from 'react';
import { DetailsListDefault } from '../../../components/parts';
import { displayNameLead, ILead } from '../../../utils/Lead';
import { IEmployee } from '../../../utils';
import { formatPhoneNumber } from '../../../helpers';

interface Props {
    items: ILead[],
    selection?: ISelection<IObjectWithKey> | undefined,
    onItemInvoked?: ((item?: any, index?: number | undefined, ev?: Event | undefined) => void) | undefined
    enableShimmer?: boolean;
    isSortedAsc?: boolean;
    sortedField: string;
    setSorting: (isDesc: boolean, field: string) => void;
    loadMore?: boolean;
    lazyLoading?: boolean;
    loading?: boolean;
    loadMoreCallback?: () => void;
    employees?: IEmployee[];
}

const CustomersDetailsList = ({
    items,
    selection,
    onItemInvoked,
    enableShimmer = false,
    isSortedAsc = false,
    sortedField,
    setSorting, // = (isSortedAsc: boolean, sortedField: string) => {},
    loadMore,
    lazyLoading,
    loading,
    loadMoreCallback,
    employees,
  }: Props) => {
    // On Column Click
    const onColumnClick = (event: any, column: any) => {
        const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
        let fieldName = '';
        let sortDescending = false;

        if (sortedColumn) {
          const newColumns = columns.map((col: IColumn) => {
            if (col.fieldName === column.fieldName) {
              col.isSorted = true;

              col.isSortedDescending =
                column.fieldName !== sortedColumn[0].fieldName
                  ? false
                  : !col.isSortedDescending;

              fieldName = column.fieldName;
              sortDescending = col.isSortedDescending;
            } else {
              col.isSorted = false;
            }
            return col;
          });

          setColumns(newColumns);
          setSorting(!sortDescending, fieldName);
        }
    };

    const mergeSalesRepsToCustomers = useCallback((customers: ILead[]) => {
        if (employees) {
          return customers.map((customer) => {
            const salesRep = employees.find((employee) => employee.id === customer.sales_rep_employee_id);
            return {
              ...customer,
              sales_rep_employee: salesRep,
            };
          });
        }
        return customers;
    }, [employees]);

    // Column List
    const columnsList = [
        {
          key: 'column1',
          name: 'Naam',
          fieldName: 'last_name1',
          minWidth: 250,
          maxWidth: 600,
          isRowHeader: true,
          onRender: (customer: ILead) => <span>{displayNameLead(customer, true)}</span>,
          onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column2',
          name: 'Adres',
          fieldName: 'address',
          minWidth: 230,
          maxWidth: 600,
          isRowHeader: true,
          onRender: (customer: ILead) => (
            <span>
              {customer.address1}
              <br />
              {customer.zip_code1} {customer.city1}
            </span>
          ),
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column4',
          name: 'GSM',
          fieldName: 'mobile',
          minWidth: 120,
          maxWidth: 350,
          isRowHeader: true,
          onRender: (customer: ILead) => (
            <span>
              {customer.mobile1_v2 ? formatPhoneNumber(customer.mobile1_v2) :
         customer.mobile2_v2 ? formatPhoneNumber(customer.mobile2_v2) :
         customer.mobile1 || customer.mobile2}{' '}
            </span>
          ),
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column5',
          name: 'Telefoon',
          fieldName: 'phone',
          minWidth: 120,
          maxWidth: 350,
          isRowHeader: true,
          onRender: (customer: ILead) => (
            <span>
              {customer.phone1_v2 ? formatPhoneNumber(customer.phone1_v2) :
         customer.phone2_v2 ? formatPhoneNumber(customer.phone2_v2) :
         customer.phone1 || customer.phone2}
            </span>
          ),
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column6',
          name: 'Verkoper',
          fieldName: 'sales_rep_employee',
          minWidth: 120,
          maxWidth: 350,
          isRowHeader: true,
          onRender: useCallback((customer: ILead) => (
            <span>
              {customer.sales_rep_employee &&
                `${customer.sales_rep_employee.first_name} ${customer.sales_rep_employee.last_name}`}
            </span>
            ), [employees]),
          data: 'string',
          isPadded: true,
        },
    ];

    const initColumns = (sortedField: string, isSortedAsc: boolean) => columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

    const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

    return (
      <DetailsListDefault
        items={mergeSalesRepsToCustomers(items)}
        columns={columns}
        layoutMode={DetailsListLayoutMode.justified}
        selection={selection}
        selectionMode={SelectionMode.single}
        selectionPreservedOnEmptyClick
        onItemInvoked={onItemInvoked}
        enableShimmer={enableShimmer}
        loadMore={loadMore}
        lazyLoading={lazyLoading}
        loadMoreCallback={loadMoreCallback}
        loading={loading}
      />
    );
  };

  export default CustomersDetailsList;
