import React, { useState, useCallback } from 'react';
import {
  Panel,
  PanelType,
  Stack,
  StackItem,
  TextField,
  DefaultButton,
  Checkbox,
  Label,
  Text,
  IconButton,
  Icon,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  ComboBox,
  IComboBoxOption,
  Modal,
} from '@fluentui/react';
import moment from 'moment';
import { Editor } from '@tinymce/tinymce-react';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { ISuspect } from '../../../utils/Suspect';
import { customPanelStyles } from '../../../theme';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  modalContentStyles,
} from '../../../utils';
import { ILead } from '../../../utils/Lead';
import { convertEmployeesToComboBoxOptions } from '../../../utils/Employee';
import SuggestedView from '../../../components/parts/SuggestedView';
import DateView from '../../../components/parts/DateView';
import { toastError, toastSuccess } from '../../../utils/toast';

interface Props {
  isOpen: boolean;
  dismissPanel: any;
  suspect?: ISuspect;
  onLeadSelect?: (lead: ILead) => void;
  employees?: any[];
  onCreateLead?: (suspectId: number, salesRepId: number) => void;
  onDialogOpenChange?: (isOpen: boolean) => void;
  onLogItemModalChange?: (isOpen: boolean) => void;
  refetch?: any;
}

const SuspectDetail = ({
  isOpen,
  dismissPanel,
  suspect,
  onLeadSelect,
  employees = [],
  onCreateLead,
  onDialogOpenChange,
  onLogItemModalChange,
  refetch,
}: Props) => {
  const { getAccessTokenSilently, user } = useAuth0();

  const [isCreateLeadDialogOpen, setIsCreateLeadDialogOpen] = useState(false);
  const [selectedSalesRep, setSelectedSalesRep] = useState<
    number | undefined
  >();
  const [isConnectLogItemModalOpen, setIsConnectLogItemModalOpen] =
    useState(false);
  const [selectedLead, setSelectedLead] = useState<ILead | undefined>();
  const [logItemText, setLogItemText] = useState('');
  const [logItem, setLogItem] = useState<any>({
    title: '',
    text: '',
    action_required: false,
    action_required_by: new Date(),
  });
  const [leadDescription, setLeadDescription] = useState('');

  const resetLeadForm = useCallback(() => {
    setSelectedSalesRep(undefined);
    setLeadDescription('');
  }, []);

  const resetLogItemForm = useCallback(() => {
    setLogItem({
      title: '',
      text: '',
      action_required: false,
      action_required_by: new Date(),
    });
  }, []);

  const toggleCreateLeadDialog = useCallback(() => {
    const newState = !isCreateLeadDialogOpen;
    setIsCreateLeadDialogOpen(newState);
    onDialogOpenChange?.(newState);

    if (newState && suspect) {
      resetLeadForm();
      const checkedItems = [];
      if (suspect.inspiration_book) checkedItems.push('Inspiratieboek');
      if (suspect.visit_show_homes) checkedItems.push('Bezoek kijkwoningen');
      if (suspect.visit_nearby_construction_site) checkedItems.push('Bezoek nabije bouwwerven');
      if (suspect.construction_site_available) checkedItems.push('Bouwgrond beschikbaar');

      setLeadDescription(checkedItems.join(' | '));
    } else {
      resetLeadForm();
    }
  }, [isCreateLeadDialogOpen, onDialogOpenChange, suspect, resetLeadForm]);

  const handleCreateLead = useCallback(async () => {
    if (suspect && selectedSalesRep && onCreateLead) {
      const token = await getAccessTokenSilently();
      if (token) {
        await toast.promise(
          new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_BACKEND_URI}/suspects/create-lead`, {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                suspect_id: suspect?.id,
                sales_rep_employee_id: selectedSalesRep,
                description: leadDescription,
              }),
            })
              .then(res => {
                if (!res.ok) {
                  throw new Error(`HTTP error! status: ${res.status}`);
                }
                return res.json();
              })
              .then(data => {
                console.log('data', data);
                resolve(data);
              })
              .catch(error => reject(error));
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await refetch();

        toastSuccess('Prospect aangemaakt');
        handleCloseLogItemModal();
        dismissPanel(true);
      } else {
        toastError(DEFAULT_ERROR_MESSAGE);
        handleCloseLogItemModal();
      }
      setIsCreateLeadDialogOpen(false);
      resetLeadForm();
    }
  }, [suspect, selectedSalesRep, onCreateLead, resetLeadForm]);

  const handleLeadClick = (lead: ILead) => {
    handleOpenLogItemModal(lead);
  };

  const handleOpenLogItemModal = (lead: ILead) => {
    setSelectedLead(lead);
    setIsConnectLogItemModalOpen(true);
    onLogItemModalChange?.(true);
    resetLogItemForm();
  };

  const handleCloseLogItemModal = () => {
    setIsConnectLogItemModalOpen(false);
    onLogItemModalChange?.(false);
    setSelectedLead(undefined);
    resetLogItemForm();
  };

  if (!suspect) return null;

  return (
    <>
      <Panel
        isLightDismiss
        isOpen={isOpen}
        onDismiss={dismissPanel}
        closeButtonAriaLabel='Close'
        headerText={suspect ? 'Suspect details' : ''}
        type={PanelType.custom}
        customWidth='1000px'
        styles={{
          root: {
            '.ms-Panel-main': {
              marginRight: 'auto',
              marginLeft: 'auto',
            },
          },
          main: { maxWidth: '800px' },
          scrollableContent: {
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
          },
          content: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            paddingBottom: '0px',
          },
        }}
      >
        <div>
          <div className={modalContentStyles.header} />
          <div className={modalContentStyles.body}>
            <Stack tokens={{ childrenGap: 24 }}>
              {/* Suspect Information Section */}
              <Stack tokens={{ childrenGap: 16 }}>
                <h3>Suspect Informatie</h3>

                <Stack horizontal tokens={{ childrenGap: 12 }}>
                  <Stack.Item grow={1}>
                    <TextField
                      label='Titel'
                      value={suspect.title || ''}
                      readOnly
                    />
                  </Stack.Item>
                  <Stack.Item grow={2}>
                    <TextField
                      label='Voornaam'
                      value={suspect.first_name || ''}
                      readOnly
                    />
                  </Stack.Item>
                  <Stack.Item grow={2}>
                    <TextField
                      label='Achternaam'
                      value={suspect.last_name || ''}
                      readOnly
                    />
                  </Stack.Item>
                </Stack>

                <Stack horizontal tokens={{ childrenGap: 12 }}>
                  <Stack.Item grow={3}>
                    <TextField
                      label='Email'
                      value={suspect.email || ''}
                      readOnly
                    />
                  </Stack.Item>
                  <Stack.Item grow={2}>
                    <TextField
                      label='Telefoon'
                      value={suspect.phone || ''}
                      readOnly
                    />
                  </Stack.Item>
                </Stack>

                <Stack horizontal tokens={{ childrenGap: 12 }}>
                  <Stack.Item grow={3}>
                    <TextField
                      label='Adres'
                      value={suspect.address || ''}
                      readOnly
                    />
                  </Stack.Item>
                  <Stack.Item grow={1}>
                    <TextField
                      label='Postcode'
                      value={suspect.zip_code?.toString() || ''}
                      readOnly
                    />
                  </Stack.Item>
                  <Stack.Item grow={2}>
                    <TextField
                      label='Stad'
                      value={suspect.city || ''}
                      readOnly
                    />
                  </Stack.Item>
                </Stack>

                <Stack
                  horizontal
                  tokens={{ childrenGap: 24 }}
                  style={{ marginTop: 8 }}
                >
                  <Stack tokens={{ childrenGap: 8 }}>
                    <Label>Interesses</Label>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                      <Checkbox
                        label='Inspiratieboek'
                        checked={suspect.inspiration_book}
                        disabled
                      />
                      <Checkbox
                        label='Bezoek kijkwoningen'
                        checked={suspect.visit_show_homes}
                        disabled
                      />
                      <Checkbox
                        label='Bezoek nabije bouwwerven'
                        checked={suspect.visit_nearby_construction_site}
                        disabled
                      />
                      <Checkbox
                        label='Bouwgrond beschikbaar'
                        checked={suspect.construction_site_available}
                        disabled
                      />
                    </Stack>
                  </Stack>
                </Stack>

                <Stack horizontal tokens={{ childrenGap: 12 }}>
                  <Stack.Item grow={3}>
                    <TextField
                      label='Aangemaakt op'
                      value={moment(suspect.date_created).format(
                        'DD/MM/YYYY HH:mm',
                      )}
                      readOnly
                      borderless
                    />
                  </Stack.Item>
                  <Stack.Item grow={2} style={{ paddingTop: 28 }}>
                    <Checkbox
                      label='Afgehandeld'
                      checked={suspect.resolved}
                      disabled
                    />
                  </Stack.Item>
                </Stack>
              </Stack>

              {/* Matched Leads Section */}
              <Stack tokens={{ childrenGap: 12 }}>
                <h3>Overeenkomende prospecten</h3>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns:
                      'repeat(auto-fill, minmax(280px, 1fr))',
                    gap: '16px',
                    padding: '4px',
                  }}
                >
                  {suspect.matched_leads && suspect.matched_leads.length > 0 ? (
                    suspect.matched_leads.map(lead => (
                      <div
                        key={lead.id}
                        onClick={() => handleLeadClick(lead)}
                        onKeyDown={e =>
                          e.key === 'Enter' && handleLeadClick(lead)
                        }
                        role='button'
                        tabIndex={0}
                        style={{
                          background: '#ffffff',
                          padding: '16px',
                          cursor: 'pointer',
                          borderRadius: '4px',
                          border: '1px solid #E1E1E1',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                        }}
                      >
                        <Stack tokens={{ childrenGap: 8 }}>
                          <Stack
                            horizontal
                            horizontalAlign='space-between'
                            verticalAlign='center'
                          >
                            <Stack>
                              <Text
                                variant='mediumPlus'
                                styles={{ root: { fontWeight: 600 } }}
                              >
                                {`${lead.first_name1} ${lead.last_name1}`}
                              </Text>
                              {lead.first_name2 && (
                                <Text
                                  variant='small'
                                  styles={{ root: { color: '#605E5C' } }}
                                >
                                  {`& ${lead.first_name2} ${lead.last_name2}`}
                                </Text>
                              )}
                            </Stack>
                            <Stack
                              horizontal
                              tokens={{ childrenGap: 8 }}
                              verticalAlign='center'
                            >
                              {lead.status && (
                                <Text
                                  variant='small'
                                  styles={{
                                    root: {
                                      color:
                                        lead.status === 'ACTIVE'
                                          ? '#71BF43'
                                          : '#605E5C',
                                      fontWeight: 600,
                                      padding: '2px 8px',
                                      background:
                                        lead.status === 'ACTIVE'
                                          ? '#F0F9ED'
                                          : '#F3F2F1',
                                      borderRadius: '4px',
                                    },
                                  }}
                                >
                                  {lead.status}
                                </Text>
                              )}
                              <IconButton
                                iconProps={{ iconName: 'ChevronRight' }}
                                styles={{
                                  root: {
                                    color: '#605E5C',
                                    height: 24,
                                    width: 24,
                                  },
                                  rootHovered: { color: '#71BF43' },
                                }}
                              />
                            </Stack>
                          </Stack>

                          <Stack tokens={{ childrenGap: 6 }}>
                            <Stack
                              horizontal
                              horizontalAlign='space-between'
                              verticalAlign='center'
                            >
                              <Stack
                                horizontal
                                verticalAlign='center'
                                tokens={{ childrenGap: 6 }}
                              >
                                <Icon
                                  iconName='NumberSymbol'
                                  styles={{ root: { color: '#605E5C' } }}
                                />
                                <Text
                                  variant='small'
                                  styles={{ root: { fontWeight: 600 } }}
                                >
                                  {lead.id || '-'}
                                </Text>
                              </Stack>
                              {lead.sales_rep_employee && (
                                <Stack
                                  horizontal
                                  verticalAlign='center'
                                  tokens={{ childrenGap: 6 }}
                                >
                                  <Icon
                                    iconName='AccountManagement'
                                    styles={{ root: { color: '#605E5C' } }}
                                  />
                                  <Text variant='small'>
                                    {`${lead.sales_rep_employee.first_name} ${lead.sales_rep_employee.last_name}`}
                                  </Text>
                                </Stack>
                              )}
                            </Stack>

                            {(lead.address1 ||
                              lead.zip_code1 ||
                              lead.city1) && (
                              <Stack
                                horizontal
                                verticalAlign='center'
                                tokens={{ childrenGap: 6 }}
                              >
                                <Text variant='small'>
                                  {lead.address1}
                                  {(lead.zip_code1 || lead.city1) && (
                                    <span style={{ display: 'block' }}>
                                      {lead.zip_code1} {lead.city1}
                                    </span>
                                  )}
                                </Text>
                              </Stack>
                            )}

                            <Stack tokens={{ childrenGap: 4 }}>
                              {lead.email1 && (
                                <Stack
                                  horizontal
                                  verticalAlign='center'
                                  tokens={{ childrenGap: 6 }}
                                >
                                  <Text variant='small'>{lead.email1}</Text>
                                </Stack>
                              )}
                              {(lead.phone1 || lead.mobile1) && (
                                <Stack
                                  horizontal
                                  verticalAlign='center'
                                  tokens={{ childrenGap: 6 }}
                                >
                                  <Text variant='small'>
                                    {lead.phone1 || lead.mobile1}
                                  </Text>
                                </Stack>
                              )}
                            </Stack>

                            <Stack horizontal tokens={{ childrenGap: 8 }}>
                              {lead.business && (
                                <Stack
                                  horizontal
                                  verticalAlign='center'
                                  tokens={{ childrenGap: 4 }}
                                >
                                  <Icon
                                    iconName='Building'
                                    styles={{ root: { color: '#605E5C' } }}
                                  />
                                  <Text variant='small'>Business</Text>
                                </Stack>
                              )}
                              {lead.preferred_way_of_contact && (
                                <Stack
                                  horizontal
                                  verticalAlign='center'
                                  tokens={{ childrenGap: 4 }}
                                >
                                  <Icon
                                    iconName='ContactHeart'
                                    styles={{ root: { color: '#605E5C' } }}
                                  />
                                  <Text variant='small'>
                                    {lead.preferred_way_of_contact}
                                  </Text>
                                </Stack>
                              )}
                            </Stack>
                          </Stack>
                        </Stack>
                      </div>
                    ))
                  ) : (
                    <Text>Geen overeenkomende prospecten gevonden</Text>
                  )}
                </div>
              </Stack>
            </Stack>
          </div>

          <div className={modalContentStyles.footer}>
            <Stack
              horizontal
              horizontalAlign='space-between'
              tokens={{ childrenGap: 10 }}
            >
              <PrimaryButton
                onClick={toggleCreateLeadDialog}
                iconProps={{ iconName: 'AddFriend' }}
              >
                Maak Prospect
              </PrimaryButton>
              <DefaultButton onClick={dismissPanel}>Sluiten</DefaultButton>
            </Stack>
          </div>
        </div>
      </Panel>

      <Modal
        isOpen={isCreateLeadDialogOpen}
        onDismiss={toggleCreateLeadDialog}
        styles={{
          main: {
            paddingTop: 0,
            paddingBottom: 20,
            paddingLeft: 35,
            paddingRight: 35,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            backdropFilter: 'blur(10px)',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            width: '600px',
            maxWidth: '800px',
          },
          scrollableContent: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '300px',
          },
          root: {
            backgroundColor: 'rgba(255, 255, 255, 0.4)',
          },
        }}
      >
        <Stack grow={1}>
          <Stack.Item>
            <h2>Nieuwe prospect aanmaken</h2>
          </Stack.Item>
          <Stack.Item grow={1}>
            <Stack align-items='center' styles={{ root: { paddingTop: 0 } }}>
              <div style={{ width: '100%' }}>
                <ComboBox
                  label='Verkoper'
                  options={convertEmployeesToComboBoxOptions(
                    employees.filter((x: any) => x.sales_rep === true),
                  )}
                  selectedKey={selectedSalesRep}
                  onChange={(_, option) =>
                    setSelectedSalesRep(option?.key as number)
                  }
                  required
                  errorMessage={
                    !selectedSalesRep ? 'Selecteer een verkoper' : ''
                  }
                />

                <TextField
                  label='Beschrijving'
                  multiline
                  rows={4}
                  value={leadDescription}
                  onChange={(_, newValue) => setLeadDescription(newValue || '')}
                  styles={{ root: { marginTop: 15 } }}
                />
                <br />
              </div>
            </Stack>
          </Stack.Item>
          <Stack horizontal>
            <Stack.Item align='end'>
              <PrimaryButton
                text='Prospect aanmaken'
                styles={{ root: { marginRight: '15px' } }}
                onClick={handleCreateLead}
                disabled={!selectedSalesRep}
              />
            </Stack.Item>
            <Stack.Item align='end'>
              <DefaultButton
                onClick={toggleCreateLeadDialog}
                text='Annuleren'
              />
            </Stack.Item>
          </Stack>
        </Stack>
      </Modal>

      <Modal
        isOpen={isConnectLogItemModalOpen}
        onDismiss={handleCloseLogItemModal}
        styles={{
          main: {
            paddingTop: 0,
            paddingBottom: 20,
            paddingLeft: 35,
            paddingRight: 35,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            backdropFilter: 'blur(10px)',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            width: '600px',
            maxWidth: '800px',
          },
          scrollableContent: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '600px',
          },
          root: {
            backgroundColor: 'rgba(255, 255, 255, 0.4)',
          },
        }}
      >
        <Stack grow={1}>
          <Stack.Item>
            <h2>Suspect koppelen (log item wordt aangemaakt)</h2>
          </Stack.Item>
          <Stack.Item grow={1}>
            <Stack styles={{ root: { paddingTop: 0 } }}>
              <div style={{ width: '100%' }}>
                <TextField
                  label='Lead'
                  value={
                    selectedLead
                      ? `${selectedLead.first_name1} ${selectedLead.last_name1}`
                      : ''
                  }
                  disabled
                />

                <Stack style={{ marginBottom: 10 }}>
                  <SuggestedView
                    item={logItem || {}}
                    setItem={setLogItem}
                    prefix='logItem_title_'
                    code={
                      logItem &&
                      logItem.title &&
                      logItem.title.substr(logItem.title.length - 4)
                    }
                    label='Omschrijving'
                    field='title'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <Label>Opvolging nodig?</Label>
                  <Checkbox
                    name='action_required'
                    label='Opvolging nodig?'
                    checked={logItem?.action_required}
                    onChange={() => {
                      setLogItem((prevState: any) => ({
                        ...prevState,
                        action_required: !prevState?.action_required,
                      }));
                    }}
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <DateView
                    item={logItem}
                    setItem={setLogItem}
                    date={logItem && logItem.action_required_by}
                    label='Vervalt op / uitgevoerd op'
                    field='action_required_by'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    name='text'
                    label='Extra info'
                    multiline
                    value={logItem?.text || ''}
                    onChange={(e, newValue) => {
                      setLogItem((prevState: any) => ({
                        ...prevState,
                        text: newValue || '',
                      }));
                    }}
                  />
                </Stack>
              </div>
            </Stack>
          </Stack.Item>
          <Stack horizontal>
            <Stack.Item align='end'>
              <PrimaryButton
                text='Koppelen'
                styles={{ root: { marginRight: '15px' } }}
                onClick={async () => {
                  const token = await getAccessTokenSilently();
                  if (token) {
                    await toast.promise(
                      new Promise((resolve, reject) => {
                        fetch(
                          `${process.env.REACT_APP_BACKEND_URI}/suspects/match-lead`,
                          {
                            method: 'POST',
                            headers: {
                              Authorization: `Bearer ${token}`,
                              'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                              suspect_id: suspect?.id,
                              lead_id: selectedLead?.id,
                              action_required: logItem?.action_required,
                              action_required_by: logItem?.action_required_by,
                              text: logItem?.text,
                              title: logItem?.title,
                            }),
                          },
                        )
                          .then(res => {
                            if (!res.ok) {
                              throw new Error(
                                `HTTP error! status: ${res.status}`,
                              );
                            }
                            return res.json();
                          })
                          .then(data => {
                            console.log('data', data);
                            resolve(data);
                          })
                          .catch(error => reject(error));
                      }),
                      {
                        pending: {
                          position: DEFAULT_TOAST_POSITION,
                          render() {
                            return 'Koppelen...';
                          },
                        },
                      },
                      {
                        autoClose: DEFAULT_TOAST_DURATION,
                      },
                    );

                    await refetch();

                    toastSuccess('Synced');
                    handleCloseLogItemModal();
                    dismissPanel(true);
                  } else {
                    toastError('Gebruiker niet gevonden');
                    handleCloseLogItemModal();
                  }
                }}
              />
            </Stack.Item>
            <Stack.Item align='end'>
              <DefaultButton
                onClick={handleCloseLogItemModal}
                text='Annuleren'
              />
            </Stack.Item>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};

export default SuspectDetail;
