import { useQuery } from '@apollo/client';
import {
    Checkbox, DetailsListLayoutMode, IColumn, IObjectWithKey, ISelection, SelectionMode,
} from '@fluentui/react';
import moment from 'moment';
import { useState } from 'react';
import { DetailsListDefault } from '../../../components/parts';
import { displayNameSalesRep } from '../../../utils';
import { ICar } from '../../../utils/Car';
import { displayNameLead } from '../../../utils/Lead';
import { ILogItem } from '../../../utils/LogItem';
import { GET_SUGGESTED_VALUE } from '../../../utils/SuggestedValue';

interface props {
    items: ILogItem[],
    selection?: ISelection<IObjectWithKey> | undefined,
    onItemInvoked?: ((item?: any, index?: number | undefined, ev?: Event | undefined) => void) | undefined
    enableShimmer?: boolean;
    isSortedAsc?: boolean;
    sortedField: string;
    setSorting: (isDesc: boolean, field: string) => void;
    loadMore?: boolean;
    lazyLoading?: boolean;
    loading?: boolean;
    loadMoreCallback?: () => void;
}

const LogItemDetailsList = ({
    items,
    selection,
    onItemInvoked,
    enableShimmer = false,
    isSortedAsc = false,
    sortedField,
    setSorting, // = (isSortedAsc: boolean, sortedField: string) => {},
    loadMore,
    lazyLoading,
    loading,
    loadMoreCallback,
  }: props) => {
    const LogItemTitle = ({ suggestedValue }: any) => {
        const id = +suggestedValue.substr(suggestedValue.length - 4);
        const { loading, error, data } = useQuery(GET_SUGGESTED_VALUE, {
          variables: {
            where: {
              id,
            },
          },
        });

        if (data && data.findOneSuggestedValue) {
          return <span>{data.findOneSuggestedValue.suggested_value}</span>;
        }
        return <span>{suggestedValue}</span>;
    };

    // On Column Click
    const onColumnClick = (event: any, column: any) => {
        const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
        let fieldName = '';
        let sortDescending = false;

        if (sortedColumn) {
          const newColumns = columns.map((col: IColumn) => {
            if (col.fieldName === column.fieldName) {
              col.isSorted = true;

              col.isSortedDescending =
                column.fieldName !== sortedColumn[0].fieldName
                  ? false
                  : !col.isSortedDescending;

              fieldName = column.fieldName;
              sortDescending = col.isSortedDescending;
            } else {
              col.isSorted = false;
            }
            return col;
          });
          setColumns(newColumns);

          setSorting(!sortDescending, fieldName);
        }
      };

    // Column List
    const columnsList = [
        {
          key: 'column1',
          name: 'Prospect',
          fieldName: 'lead',
          minWidth: 250,
          maxWidth: 250,
          isRowHeader: true,
          onRender: (logItem: ILogItem) => (
            <span>{displayNameLead(logItem.lead)}</span>
          ),
          onColumnClick,
          data: 'string',
          isPadded: true,
          isSorted: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorteer van laag tot hoog',
          sortDescendingAriaLabel: 'Sorteer van hoog tot laag',
        },
        {
          key: 'column2',
          name: 'Verkoper',
          fieldName: 'sales_rep',
          minWidth: 150,
          maxWidth: 150,
          isRowHeader: true,
          onRender: (logItem: ILogItem) => (
            <span>
              {logItem.sales_rep || logItem.app_user
                ? displayNameSalesRep(logItem.sales_rep || logItem.app_user)
                : ''}
            </span>
          ),
          onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column4',
          name: 'Laatst gewijzigd',
          fieldName: 'last_updated',
          minWidth: 100,
          maxWidth: 100,
          isRowHeader: true,
          onRender: (logItem: ILogItem) => (
            <span>
              {logItem.last_updated &&
                moment(logItem.last_updated).format('DD-MM-YYYY')}
            </span>
          ),
          onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column5',
          name: 'Omschrijving',
          fieldName: 'title',
          minWidth: 150,
          maxWidth: 150,
          isRowHeader: true,
          onRender: (logItem: ILogItem) => (
            <span>
              <LogItemTitle suggestedValue={logItem.title} />
            </span>
          ),
          onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column6',
          name: 'Actie vereist voor',
          fieldName: 'action_required_by',
          minWidth: 100,
          maxWidth: 100,
          isRowHeader: true,
          onRender: (logItem: ILogItem) => (
            <span>
              {logItem.action_required_by &&
                logItem.action_required &&
                moment(logItem.action_required_by).format('DD-MM-YYYY')}
            </span>
          ),
          onColumnClick,
          data: 'string',
          isPadded: true,
        },
      ];

    const initColumns = (sortedField: string, isSortedAsc: boolean) => columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

    const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

    return (
      <DetailsListDefault
        items={items}
        columns={columns}
        layoutMode={DetailsListLayoutMode.justified}
        selection={selection}
        selectionMode={SelectionMode.single}
        selectionPreservedOnEmptyClick
        onItemInvoked={onItemInvoked}
        enableShimmer={enableShimmer}
        loadMore={loadMore}
        lazyLoading={lazyLoading}
        loadMoreCallback={loadMoreCallback}
        loading={loading}
      />
    );
  };

  export default LogItemDetailsList;
