/* eslint-disable no-restricted-globals */
import React, {
 useState, useEffect, useRef, useCallback,
} from 'react';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  Stack,
} from '@fluentui/react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { useBoolean } from '@fluentui/react-hooks';
import moment from 'moment';
import { commandBarTheme, commandBarThemeCounter } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import {
  convertEmployeesToComboBoxOptions,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  GET_CONSTRUCTION_SITES,
  GET_EMPLOYEES,
  IEmployee,
  ISuggestedValue,
} from '../../../utils';
import {
  Filter,
  FilterPanel,
  FilterState,
  initFilterPanelState,
} from '../../../components/parts/FilterPanel';
import { toastError, toastSuccess } from '../../../utils/toast';

import {
  ADD_ASSET,
  GET_ASSET_BY_ID,
  IAsset,
  UPDATE_ASSET,
} from '../../../utils/Asset';
import { ADD_INSPECTION_GROUP, IInspection } from '../../../utils/Inspection';
import { ADD_ASSET_LOG_GROUP, IAssetLog } from '../../../utils/AssetLog';
import useDebounce from '../../../components/hooks/useDebounce';
import { GET_TEAMS } from '../../../utils/Team';
import AssetsDetailsList from './AssetsDetailsList';
import AssetDetail from './AssetDetail';
import InspectionGroupDetail from './InspectionGroupDetails';
import AssetLogGroupDetail from './AssetLogGroupDetails';

interface Props {
  assets: IAsset[];
  suggestedValues: ISuggestedValue[];
  loading?: boolean;
  //
  setSorting: (isSortedAsc: boolean, sortedField: string) => void;
  isSortedAsc?: boolean;
  sortedField?: string;
  //
  showLoadMore?: boolean;
  loadMore?: boolean;
  loadMoreCallback?: () => void;
  lazyLoading?: boolean;
  //
  downloadFile?: () => void;
  //
  listFilters: any;
  setAssetFilter: React.Dispatch<React.SetStateAction<FilterState | undefined>>;
  //
  isFilterPanelOpen: boolean;
  setIsFilterPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  //
  refetch: any;
}

const AssetsOverview = ({
  assets,
  suggestedValues,
  loading,
  //
  setSorting,
  isSortedAsc = false,
  sortedField = '',
  //
  showLoadMore,
  loadMore,
  loadMoreCallback = () => ({}),
  lazyLoading,
  //
  downloadFile = () => {},
  //
  listFilters,
  setAssetFilter,
  //
  isFilterPanelOpen,
  setIsFilterPanelOpen,
  //
  refetch,
}: Props) => {
  const [asset, setAsset] = useState<IAsset | undefined>(undefined);
  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const assetChangeCount = useRef(0);
  const [hideModal] = useBoolean(true);

  useEffect(() => {
    if (asset) assetChangeCount.current += 1;
    else assetChangeCount.current = 0;
  }, [asset]);

  const { data: employeeData } = useQuery(GET_EMPLOYEES, {
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      setEmployees(x.findManyEmployees);
    },
  });

  const [addAsset] = useMutation(ADD_ASSET, {
    onCompleted: () => {
      assetChangeCount.current = 1;
    },
  });

  const [modifyAsset] = useMutation(UPDATE_ASSET, {
    onCompleted: () => {
      assetChangeCount.current = 1;
    },
  });

  const [selectionDetails, setSelectionDetails] = useState<
    IAsset | undefined
  >();

  const [selectionObjects, setSelectionObjects] = useState<any[]>([]);

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    const getDesignation = (asset: IAsset) => {
      if (asset.is_tool) {
        const x = asset.tools_designation
          ? suggestedValues.find(
              obj =>
                obj.id ===
                Number(asset.tools_designation!.match(/\d{4}$/)?.[0]),
            )?.suggested_value
          : '';

        return x ? `${x} ${asset.name}` : `Gereedschap ${asset.name}`;
      }

      if (asset.is_sign) {
        return `Werfbord${asset.name ? ` ${asset.name}` : ''}`;
      }

      if (asset.is_ladder) {
        return `Ladder${asset.name ? ` ${asset.name}` : ''}`;
      }

      if (asset.is_breaker) {
        return `Werfkast${asset.breakers_nr ? ` ${asset.breakers_nr}` : ''}`;
      }
    };

    setSelectionObjects(
      currentSelection.map((obj: any) => ({
        id: obj.id,
        name: obj.name || obj.breakers_type ? getDesignation(obj) : '',
        inspectable: obj.inspectable,
      })),
    );

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const { refetch: refetchAsset } = useQuery(GET_ASSET_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: selectionDetails
      ? { where: { id: selectionDetails.id } }
      : undefined,
    skip: !selectionDetails,
    onCompleted: data => {
      setAsset(data.findOneAsset);
    },
  });

  const openAssetDetail = (newAsset?: boolean) => {
    if (newAsset) {
      setAsset(undefined);
    }
    setIsPanelOpen(true);
  };

  const openFilter = () => {
    setIsFilterPanelOpen(true);
  };

  const saveAsset = async () => {
    try {
      if (asset) {
        if (
          (!asset.is_breaker && (!asset.name || asset.name === '')) ||
          (asset.is_breaker &&
            (!asset.breakers_type || asset.breakers_type === ''))
        ) {
          toast.error('velden is verplicht');
          return;
        }

        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              name: asset.name,
              make: asset.make ? asset.make : null,
              comments: asset.comments,

              is_tool: asset.is_tool,
              is_sign: asset.is_sign,
              is_ladder: asset.is_ladder,
              is_breaker: asset.is_breaker,

              tools_reference:
                asset.tools_reference && asset.is_tool
                  ? asset.tools_reference
                  : null,
              tools_nr: asset.tools_nr && asset.is_tool ? asset.tools_nr : null,
              tools_designation:
                asset.tools_designation && asset.is_tool
                  ? asset.tools_designation
                  : null,
              tools_serial:
                asset.tools_serial && asset.is_tool ? asset.tools_serial : null,
              tools_year:
                asset.tools_year && asset.is_tool
                  ? Number(asset.tools_year)
                  : null,

              ladders_nr:
                asset.ladders_nr && asset.is_ladder ? asset.ladders_nr : null,
              ladders_reference:
                asset.ladders_reference && asset.is_ladder
                  ? asset.ladders_reference
                  : null,

              breakers_nr:
                asset.breakers_nr && asset.breakers_nr
                  ? asset.breakers_nr
                  : null,
              breakers_type:
                asset.breakers_type && asset.is_breaker
                  ? asset.breakers_type
                  : null,

              inspectable: asset.inspectable,
              active: asset.active,

              owner:
                asset.owner && asset.owner.id
                  ? {
                      connect: { id: asset.owner.id },
                    }
                  : undefined,
            };

            if (asset.id) {
              modifyAsset({
                variables: {
                  id: asset.id,
                  data: allInput,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: (x: any) => {
                  assetChangeCount.current = 1;
                  resolve(x);
                  toastSuccess('Asset gewijzigd');
                },
              });
            } else {
              addAsset({
                variables: {
                  data: allInput,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: (x: any) => {
                  if (x && x.createAsset) {
                    setAsset(x.createAsset);
                    selection.setItems(x.createAsset);
                    resolve(x);
                    toastSuccess('Asset toegevoegd');
                    refetch();
                  }
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  const filter_schema: Filter = {
    id: 'leads_filter_schema_rev6',
    permission: 'read:leads',
    schema: [
      {
        id: 'section_1',
        label: 'Type',
        width: 100,
        fields: [
          {
            id: 'section_1_0',
            name: 'Gereedschap',
            fieldName: 'is_tool',
            data: 'boolean',
            type: 'checkbox',
            width: 23.5,
            callback: (fieldValue: string) => fieldValue === 'true',
          },
          {
            id: 'section_1_1',
            name: 'Werfbord',
            fieldName: 'is_sign',
            data: 'boolean',
            type: 'checkbox',
            width: 23.5,
            callback: (fieldValue: string) => fieldValue === 'true',
          },
          {
            id: 'section_1_2',
            name: 'Ladder',
            fieldName: 'is_ladder',
            data: 'boolean',
            type: 'checkbox',
            width: 23.5,
            callback: (fieldValue: string) => fieldValue === 'true',
          },
          {
            id: 'section_1_3',
            name: 'Werfkast',
            fieldName: 'is_breaker',
            data: 'boolean',
            type: 'checkbox',
            width: 23.5,
            callback: (fieldValue: string) => fieldValue === 'true',
          },
        ],
      },
      {
        id: 'section_2',
        label: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'section_2_0',
            name: 'Type',
            fieldName: 'name',
            data: 'string',
            type: 'text',
            width: 23.5,
            callback: (fieldValue: string) => ({
              contains: fieldValue,
            }),
          },
          {
            id: 'section_2_1',
            name: 'Merk',
            fieldName: 'make',
            data: 'string',
            type: 'suggested',
            prefix: 'assets_merk_',
            width: 23.5,
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  startsWith: `assets_merk_${fieldValue}`,
                };
              }
            },
          },
          {
            id: 'section_2_2',
            name: 'Verantwoordelijke',
            fieldName: 'owner_id',
            data: 'string',
            type: 'list',
            width: 23.5,
            options: convertEmployeesToComboBoxOptions(employees),
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  equals: parseInt(fieldValue, 10),
                };
              }
            },
          },
          {
            id: 'section_2_3',
            name: 'Keuringsplicht',
            fieldName: 'inspectable',
            data: 'boolean',
            type: 'checkbox',
            width: 23.5,
            callback: (fieldValue: string) => fieldValue === 'true',
          },
          // {
          //   id: 'section_2_3',
          //   name: 'Uitgefaseerd',
          //   fieldName: 'active',
          //   data: 'boolean',
          //   type: 'checkbox',
          //   width: 23.5,
          //   callback: (fieldValue: string) => fieldValue === 'true',
          // },
        ],
      },
      {
        id: 'section_3',
        label: 'Gereedschap',
        width: 48,
        fields: [
          {
            id: 'section_3_0',
            name: 'Nr',
            fieldName: 'tools_nr',
            data: 'string',
            type: 'text',
            width: 15,
            callback: (fieldValue: string) => ({
              contains: fieldValue,
            }),
          },
          {
            id: 'section_3_2',
            name: 'Kenmerk',
            fieldName: 'tools_reference',
            data: 'string',
            type: 'suggested',
            prefix: 'assets_kenmerk_',
            width: 40,
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  startsWith: `assets_kenmerk_${fieldValue}`,
                };
              }
            },
          },
          {
            id: 'section_3_3',
            name: 'Benaming',
            fieldName: 'tools_designation',
            data: 'string',
            type: 'suggested',
            prefix: 'assets_benaming_',
            width: 40,
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  startsWith: `assets_benaming_${fieldValue}`,
                };
              }
            },
          },
          {
            id: 'section_3_4',
            name: 'Serienummer',
            fieldName: 'tools_serial',
            data: 'string',
            type: 'text',
            width: 48.5,
            callback: (fieldValue: string) => ({
              contains: fieldValue,
            }),
          },
          {
            id: 'section_3_4',
            name: 'Bouwjaar',
            fieldName: 'tools_year',
            data: 'number',
            type: 'number',
            width: 48.5,
            callback: (fieldValue: string) => ({
              contains: fieldValue,
            }),
          },
        ],
      },
      {
        id: 'section_9',
        label: 'Gereedschap',
        width: 48,
        fields: [
          {
            id: 'section_9_0',
            name: 'Nr',
            fieldName: 'ladders_nr',
            data: 'string',
            type: 'text',
            width: 15,
            callback: (fieldValue: string) => ({
              contains: fieldValue,
            }),
          },
          {
            id: 'section_9_2',
            name: 'Kenmerk',
            fieldName: 'ladders_reference',
            data: 'string',
            type: 'suggested',
            prefix: 'assets_ladderkenmerk_',
            width: 80,
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  startsWith: `assets_ladderkenmerk_${fieldValue}`,
                };
              }
            },
          },
        ],
      },
      {
        id: 'section_4',
        label: 'Werfkast',
        width: 48,
        fields: [
          {
            id: 'section_4_0',
            name: 'Nr',
            fieldName: 'breakers_nr',
            data: 'string',
            type: 'text',
            width: 15,
            callback: (fieldValue: string) => ({
              contains: fieldValue,
            }),
          },
          {
            id: 'section_4_1',
            name: 'Type',
            fieldName: 'breakers_type',
            data: 'string',
            type: 'list',
            options: [
              {
                key: 'Monofasig',
                text: 'Monofasig',
              },
              { key: 'Driefasig', text: 'Driefasig' },
            ],
            width: 82,
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  startsWith: `${fieldValue}`,
                };
              }
            },
          },
        ],
      },
      {
        id: 'section_5',
        label: 'Inspecties',
        width: 100,
        fields: [
          {
            id: 'section_5_0',
            name: 'Startdatum inspectie',
            fieldName: 'inspections',
            data: 'date',
            type: 'date',
            width: 24.5,
            callback: (fieldValue: string) => ({
              some: {
                is_last: true,
                inspection_date: {
                  gte: moment(new Date(fieldValue)).toISOString(),
                },
              },
            }),
          },
          {
            id: 'section_5_1',
            name: 'Einddatum inspectie',
            fieldName: 'inspections',
            data: 'date',
            type: 'date',
            width: 24.5,
            callback: (fieldValue: string) => ({
              some: {
                is_last: true,
                inspection_date: {
                  lte: moment(new Date(fieldValue)).toISOString(),
                },
              },
            }),
          },
          {
            id: 'section_5_2',
            name: 'Startdatum verval',
            fieldName: 'inspections',
            data: 'date',
            type: 'date',
            width: 24.5,
            callback: (fieldValue: string) => ({
              some: {
                is_last: true,
                inspection_expiry_date: {
                  gte: moment(new Date(fieldValue)).toISOString(),
                },
              },
            }),
          },
          {
            id: 'section_5_3',
            name: 'Einddatum verval',
            fieldName: 'inspections',
            data: 'date',
            type: 'date',
            width: 24.5,
            callback: (fieldValue: string) => ({
              some: {
                is_last: true,
                inspection_expiry_date: {
                  lte: moment(new Date(fieldValue)).toISOString(),
                },
              },
            }),
          },
        ],
      },
    ],
  };

  const modelProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 450 } },
  };

  const dialogRemoveConfirmationProps = {
    type: DialogType.largeHeader,
    title: 'Sluit panel',
    subText: 'Niet opgeslagen wijzigingen gaan verloren.',
  };

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'totaal',
      text: `${assets.length} / ${
        assets.length > 0 ? assets[0].prisma_total : 0
      }`,
      theme: commandBarThemeCounter,
    },
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openAssetDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => {
        openAssetDetail();
      },
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
    // {
    //   key: 'downloadPDF',
    //   text: 'PDF',
    //   iconProps: { iconName: 'Download' },
    //   onClick: () => LeadDownload(lead as any, dataSuggestedValues),
    //   disabled: !selectionDetails,
    //   theme: commandBarTheme,
    // },
    {
      key: 'inspections',
      text: 'Inspecties',
      iconProps: { iconName: 'AddToShoppingList' },
      onClick: () => {
        openInspectionGroupDetail();
      },
      theme: commandBarTheme,
      disabled: !selectionObjects || selectionObjects.length === 0,
    },
    {
      key: 'logs',
      text: 'Logs',
      iconProps: { iconName: 'BranchMerge' },
      onClick: () => {
        openLogGroupDetail();
      },
      theme: commandBarTheme,
      disabled: !selectionObjects || selectionObjects.length === 0,
    },
    {
      key: 'downloadXLS',
      text: 'Excel',
      iconProps: { iconName: 'ExcelDocument' },
      onClick: () => downloadFile(),
      theme: commandBarTheme,
      disabled: !assets || assets.length === 0,
    },
    {
      key: 'filter',
      text: 'Filter',
      iconProps: { iconName: 'Filter' },
      onClick: () => openFilter(),
      theme: commandBarTheme,
    },
  ];

  useEffect(() => {
    if (asset && isPanelOpen) {
      document.title = `3bouw | Assets - ${asset?.name}`;
    } else {
      document.title = '3bouw | Assets';
    }
  }, [asset, isPanelOpen]);

  const [filterState, setFilterState] = useState<FilterState>(
    initFilterPanelState(filter_schema),
  );

  // ===============================================================================================
  // ===================================  I N S P E C T I O N   ====================================
  // ===============================================================================================

  const [isInspectionGroupPanelOpen, setIsInspectionGroupPanelOpen] =
    useState(false);
  const [inspectionGroup, setInspectionGroup] = useState<IInspection>();

  const openInspectionGroupDetail = () => {
    setIsInspectionGroupPanelOpen(true);
  };

  const [addInspectionGroup] = useMutation(ADD_INSPECTION_GROUP);

  const saveInspectionGroup = async () => {
    try {
      if (inspectionGroup) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              asset_ids: selectionObjects
                .filter((obj: any) => obj.inspectable === true)
                .map((obj: any) => obj.id),
              comments: inspectionGroup.comments,
              inspection_date: inspectionGroup.inspection_date,
              inspection_expiry_date: inspectionGroup.inspection_expiry_date,
            };

            addInspectionGroup({
              variables: {
                data: allInput,
              },
              onError: error => {
                toastError(error.message);
              },
              onCompleted: async (x: any) => {
                refetch();
                resolve(x);
                toastSuccess('Inspection toegevoegd');
              },
            }).then(() => {
              setIsInspectionGroupPanelOpen(false);
            });
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  // ===============================================================================================
  // ===============================================================================================
  // ===============================================================================================

  // ===============================================================================================
  // =====================================  A S S E T   L O G  =====================================
  // ===============================================================================================

  const [isLogGroupPanelOpen, setIsLogGroupPanelOpen] = useState(false);
  const [logGroup, setLogGroup] = useState<IAssetLog>();

  const openLogGroupDetail = (newAsset?: boolean) => {
    setIsLogGroupPanelOpen(true);
  };

  const [addLogGroup] = useMutation(ADD_ASSET_LOG_GROUP);

  const saveLogGroup = async () => {
    try {
      if (logGroup) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              asset_ids: selectionObjects
                // Filters only inspectable objects
                .map((obj: any) => obj.id),
              comments: logGroup.comments,
              date_created: logGroup.date_created,
              team: logGroup.team
                ? { connect: { id: logGroup.team.id } }
                : logGroup?.id
                ? {
                    disconnect: true,
                  }
                : undefined,
              construction_site: logGroup.construction_site
                ? { connect: { id: logGroup.construction_site.id } }
                : logGroup?.id
                ? {
                    disconnect: true,
                  }
                : undefined,
            };

            addLogGroup({
              variables: {
                data: allInput,
              },
              onError: error => {
                toastError(error.message);
              },
              onCompleted: async (x: any) => {
                refetch();
                resolve(x);
                toastSuccess('Log toegevoegd');
              },
            }).then(() => {
              setIsLogGroupPanelOpen(false);
            });
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  // ===============================================================================================
  // ===============================================================================================
  // ===============================================================================================

  // ===============================================================================================
  // =================================== C O N S T R U C T I O N  ==================================
  // ===============================================================================================

  const [constructionSiteSearch, setConstructionSiteSearch] = useState<
    string | undefined
  >();

  const debouncedSearchTermConstructionSite = useDebounce(
    constructionSiteSearch,
    500,
  );

  const constructionSiteQuery = useCallback(() => {
    const query: any = {
      orderBy: {
        name: 'asc',
      },
      filter: debouncedSearchTermConstructionSite
        ? {
            OR: [
              { name: { contains: debouncedSearchTermConstructionSite } },
              { city: { contains: debouncedSearchTermConstructionSite } },
              { address: { contains: debouncedSearchTermConstructionSite } },
            ],
          }
        : undefined,
      take: 15,
    };

    return query;
  }, [debouncedSearchTermConstructionSite]);

  const { data: dataConstructionSites } = useQuery(GET_CONSTRUCTION_SITES, {
    variables: {
      ...constructionSiteQuery(),
    },
  });

  // ===============================================================================================
  // ===============================================================================================
  // ===============================================================================================

  // ===============================================================================================
  // =================================== T E A M          T E A M ==================================
  // ===============================================================================================

  const [teamSearch, setTeamSearch] = useState<string | undefined>();

  const debouncedSearchTermTeam = useDebounce(teamSearch, 500);

  const teamQuery = useCallback(() => {
    const query: any = {
      orderBy: {
        name: 'asc',
      },
      filter: debouncedSearchTermTeam
        ? {
            OR: [{ name: { contains: debouncedSearchTermTeam } }],
          }
        : undefined,
      take: 15,
    };

    return query;
  }, [debouncedSearchTermConstructionSite]);

  const { data: dataTeams } = useQuery(GET_TEAMS, {
    variables: {
      ...teamQuery(),
    },
  });

  // ===============================================================================================
  // ===============================================================================================
  // ===============================================================================================

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        maxWidth='2000px'
      />
      <InspectionGroupDetail
        isOpen={isInspectionGroupPanelOpen}
        dismissPanel={() => {
          setIsInspectionGroupPanelOpen(false);
        }}
        inspectionGroup={inspectionGroup}
        setInspectionGroup={setInspectionGroup}
        saveInspectionGroup={saveInspectionGroup}
        selectedObjects={selectionObjects
          .filter((obj: any) => obj.inspectable === true)
          .map((obj: any) => ({ id: obj.id, name: obj.name }))}
      />
      <AssetLogGroupDetail
        isOpen={isLogGroupPanelOpen}
        dismissPanel={() => {
          setIsLogGroupPanelOpen(false);
        }}
        logGroup={logGroup}
        setLogGroup={setLogGroup}
        saveLogGroup={saveLogGroup}
        //
        constructionSites={
          dataConstructionSites
            ? dataConstructionSites.findManyConstructionSites
            : []
        }
        constructionSiteSearch={constructionSiteSearch}
        setConstructionSiteSearch={setConstructionSiteSearch}
        //
        teams={dataTeams ? dataTeams.findManyTeams : []}
        teamSearch={teamSearch}
        setTeamSearch={setTeamSearch}
        selectedObjects={selectionObjects.map((obj: any) => ({
          id: obj.id,
          name: obj.name,
        }))}
      />

      <AssetDetail
        isOpen={isPanelOpen}
        dismissPanel={() => {
          /* if (!isConfirmationRef.current) {
            setIsConfirmationHidden(false);
          } else {
            setIsPanelOpen(false);
            isConfirmationRef.current = false;
          } */
          if (!hideModal) return;

          // TODO what is this modal?
          if (assetChangeCount.current > 2) {
            console.log('TODO why open confirmation');
            setShowConfirmationDialog(true);
          } else {
            setIsPanelOpen(false);
            assetChangeCount.current = 1;
          }
        }}
        asset={asset || {}}
        saveAsset={saveAsset}
        setAsset={setAsset}
        employees={employeeData ? employeeData.findManyEmployees : []}
        refetchAsset={refetchAsset}
        refetch={refetch}
        listFilters={listFilters}
      />
      {isFilterPanelOpen && (
        <FilterPanel
          isOpen={isFilterPanelOpen}
          dismissPanel={() => {
            setIsFilterPanelOpen(false);
          }}
          filter={filter_schema}
          setFilter={setAssetFilter}
          filterState={filterState}
          setFilterState={setFilterState}
        />
      )}
      {!loading && (
        <AssetsDetailsList
          items={assets}
          selection={selection}
          isShimmered={loading}
          enableShimmer
          isSortedAsc={isSortedAsc}
          sortedField={sortedField}
          setSorting={setSorting}
          loadMore={loadMore}
          lazyLoading={lazyLoading}
          loadMoreCallback={loadMoreCallback}
          loading={loading}
          onItemInvoked={() => {
            openAssetDetail(false);
          }}
          employees={employeeData ? employeeData.findManyEmployees : []}
          suggestedValues={suggestedValues}
        />
      )}
      {showConfirmationDialog && (
        <Dialog
          dialogContentProps={dialogRemoveConfirmationProps}
          modalProps={modelProps}
          hidden={!showConfirmationDialog}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                setIsPanelOpen(false);
                setShowConfirmationDialog(false);
                assetChangeCount.current = 1;
              }}
              text='Sluit'
            />
            <DefaultButton
              text='Annuleren'
              onClick={() => {
                setShowConfirmationDialog(false);
              }}
            />
          </DialogFooter>
        </Dialog>
      )}
      {assets && (
        <>
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton
                text='Toon meer'
                onClick={() => loadMoreCallback()}
              />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {!loading && !showLoadMore && assets.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default AssetsOverview;
