import {
 useEffect, useCallback, useState, useRef,
} from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _, { filter, take } from 'lodash';
import {
 DefaultButton, Icon, Label, Stack, StackItem,
} from '@fluentui/react';
import moment from 'moment';
import { default_page_size, stackTokens15 } from '../../utils';
import {
  GET_TIMESHEET_ENTRIES,
  GET_GROUPED_TIMESHEET_ENTRIES,
  ITimesheetEntry,
} from '../../utils/TimesheetEntry';
import { FilterState } from '../../components/parts/FilterPanel';
import { useAppDispatch } from '../../redux/hooks';
import { sendNotification } from '../../redux/notification/notificationSlice';
import TimesheetsOverview from './components/TimesheetsOverview';
import DoubleEntryPanel from './components/DoubleEntryPanel';

interface IPagedState {
  timesheets: ITimesheetEntry[];
  skip: number;
  take: 20 | null;
  // filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

type IStatus =
  | 'today'
  | 'yesterday'
  | 'thisweek'
  | 'lastweek'
  | 'month'
  | 'lastmonth'
  | 'quarter';

function onlyUnique(value: any, index: any, self: any) {
  return self.indexOf(value) === index;
}

const Timesheets = () => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const dispatch = useAppDispatch();
  const [pagedState, setPagedState] = useState<IPagedState>({
    timesheets: [],
    skip: 0,
    take: 20,
    showLoadMore: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: false,
    sortedField: 'entry_date',
  });

  const [count, setCount] = useState<number>(0);
  const [uniqueEmployees, setUniqueEmployees] = useState<number>(0);
  const [advancedFilter, setAdvancedFilter] = useState<
    FilterState | undefined
  >();
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [showPanelGroupedEntries, setShowPanelGroupedEntries] = useState(false);
  // const [groupedTimesheet, setGroupedTimesheet] = useState<any[]>([]);

  const getFilters = useCallback(() => {
    const filters: any = {
      AND: [],
    };

    if (advancedFilter && advancedFilter.schema.length > 0) {
      for (let i = 0; i < advancedFilter.schema.length; i++) {
        for (let t = 0; t < advancedFilter.schema[i].fields.length; t++) {
          if (
            advancedFilter.schema[i].fields[t].value &&
            advancedFilter.schema[i].fields[t].parsedValue &&
            advancedFilter.schema[i].fields[t].value !== ''
          ) {
            // console.log(advancedFilter.schema[i].fields[t].parsedValue);
            filters.AND.push(advancedFilter.schema[i].fields[t].parsedValue);
            // takeRef.current = undefined;
          }
        }
      }
    }

    if (filters.AND.length === 0) {
      return {};
    }

    return filters;
  }, [advancedFilter]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const { loading, error } = useQuery(GET_TIMESHEET_ENTRIES, {
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: getFilters(),
      take: pagedState.take ? pagedState.take : 20,
      skip: pagedState.skip,
      orderBy: getOrderBy(),
    },
    skip: !advancedFilter,
    onCompleted: (x: any) => {
      if (x.findManyTimeSheetEntries && x.findManyTimeSheetEntries.length > 0) {
        setPagedState(prevState => ({
          ...prevState,
          timesheets: pagedState.timesheets.concat(x.findManyTimeSheetEntries),
          isFiltered: false,
          initialLoad: false,
          showLoadMore: !(
            x.findManyTimeSheetEntries.length < default_page_size
          ),
        }));
      } else if (x.findManyTimeSheetEntries) {
        setPagedState(prevState => ({
          ...prevState,
          initialLoad: false,
          showLoadMore: false,
        }));
      }
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    let amount = 0;
    const nameList: string[] = [];
    for (let i = 0; i < pagedState.timesheets.length; i++) {
      amount += pagedState.timesheets[i].hours
        ? +pagedState.timesheets[i].hours!
        : 0;
      if (pagedState.timesheets[i] && pagedState.timesheets[i].employee) {
        nameList.push(pagedState.timesheets[i].employee!.id.toString());
      }
    }

    setUniqueEmployees(nameList.filter(onlyUnique).length);
    setCount(amount);
  }, [pagedState]);

  useEffect(() => {
    setPagedState(prevState => ({
      ...prevState,
      timesheets: [],
      skip: 0,
      take: null,
    }));
  }, [advancedFilter]);

  const downloadFile = useCallback(async () => {
    dispatch(
      sendNotification({
        message: 'bezig met downloaden',
        level: 0,
        module: 'template.updateTemplate',
        spinner: true,
      }),
    );
    const url = `${process.env.REACT_APP_BACKEND_URI}/files/timesheetentries/xls`;
    const token = await getAccessTokenSilently();
    const fileName = `timesheetentries_${moment().format('YYYYMMDD')}.xlsx`;
    const res = await fetch(url, {
      method: 'post',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ filter: getFilters() }),
    });

    const result = await res.blob();

    const aElement = document.createElement('a');
    aElement.setAttribute('download', fileName);
    const href = URL.createObjectURL(result);
    aElement.href = href;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(href);

    dispatch(
      sendNotification({
        module: 'template.updateTemplate',
        message: 'Bestand is gedownload',
        level: 1,
        timeout: 2500,
      }),
    );
  }, [advancedFilter]);

  const { data: groupedTimesheetData } = useQuery(
    GET_GROUPED_TIMESHEET_ENTRIES,
    {
      // notifyOnNetworkStatusChange: true,
      variables: {
        filter: getFilters(),
      },
      skip: !advancedFilter,
      fetchPolicy: 'cache-and-network',
    },
  );

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    clearTimesheets();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const clearTimesheets = () => {
    setPagedState(prevState => ({
      ...prevState,
      timesheets: [],
      skip: 0,
    }));
  };

  const debouncedScroll = _.debounce(() => {
    setPagedState(prevState => {
      const currentDataLength = prevState.timesheets.length;
      return {
        ...prevState,
        skip: currentDataLength,
      };
    });
  }, 250);

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <div style={{ padding: '25px' }}>
        <h3
          style={{
            fontSize: '20px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Icon iconName='TemporaryUser' style={{ fontSize: '20px' }} />
          Werkuren
        </h3>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: '15px',
          }}
        >
          {groupedTimesheetData &&
            groupedTimesheetData.findManyGroupedTimesheetEntries &&
            groupedTimesheetData.findManyGroupedTimesheetEntries.length > 0 && (
              <DefaultButton
                styles={{
                  root: {
                    marginLeft: '10px',
                    borderColor: 'red',
                    color: 'red',
                  },
                }}
                onClick={() => {
                  setShowPanelGroupedEntries(true);
                }}
              >
                Dubbele ingaves{' '}
                {`(${groupedTimesheetData.findManyGroupedTimesheetEntries.length})`}
              </DefaultButton>
            )}
        </div>
      </div>

      <Stack style={{ flexDirection: 'row', alignItems: 'center', marginLeft: '25px' }}>
        <Stack style={{ flexDirection: 'column', alignItems: 'center' }}>
          <Stack style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Label style={{ marginRight: 5 }}>Aantal medewerkers:</Label>
            <StackItem>{uniqueEmployees}</StackItem>
          </Stack>
        </Stack>

        <Stack style={{ flexDirection: 'column', alignItems: 'center' }}>
          <Stack style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Label style={{ marginLeft: 10, marginRight: 5 }}>
              Aantal gewerkte uren:
            </Label>
            <StackItem>{count}</StackItem>
          </Stack>
        </Stack>
      </Stack>

      {showPanelGroupedEntries &&
        groupedTimesheetData &&
        groupedTimesheetData.findManyGroupedTimesheetEntries &&
        groupedTimesheetData.findManyGroupedTimesheetEntries.length > 0 && (
          <DoubleEntryPanel
            setShowPanel={setShowPanelGroupedEntries}
            groupedTimesheet={
              groupedTimesheetData.findManyGroupedTimesheetEntries
            }
            filter={getFilters()}
          />
        )}

      <TimesheetsOverview
        timesheets={pagedState.timesheets}
        loading={loading}
        setSorting={setSorting}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        initialLoad={pagedState.initialLoad}
        showLoadMore={pagedState.showLoadMore}
        loadMore={debouncedScroll}
        isFilterPanelOpen={isFilterPanelOpen}
        setIsFilterPanelOpen={setIsFilterPanelOpen}
        setAdvancedFilter={(value: any) => {
          setAdvancedFilter(value);
          // setStatus('all');
        }}
        downloadFile={downloadFile}
      />
    </Stack>
  );
};

export default Timesheets;
