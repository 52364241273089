import React, { FormEvent, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import {
 Icon, Stack, TextField, Toggle,
} from '@fluentui/react';
import {
  default_page_size,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import useDebounce from '../../components/hooks/useDebounce';
import { useAppDispatch } from '../../redux/hooks';
import { toastError } from '../../utils/toast';
import { GET_TEAMS, ITeam } from '../../utils/Team';
import TeamsOverview from './components/TeamsOverview';

interface IPagedTeam {
  teams: ITeam[];
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  lazyLoading: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

const Teams = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuth0();
  const [pagedState, setPagedState] = useState<IPagedTeam>({
    teams: [],
    skip: 0,
    take: default_page_size,
    filter: '',
    showLoadMore: true,
    lazyLoading: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: true,
    sortedField: 'name',
  });

  const [filter, setFilter] = useState('');
  const [inUse, setInUse] = useState(true);

  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    client.cache.reset();
    setPagedState(prevState => ({ ...prevState, skip: 0, cars: [] }));
  }, [filter, inUse]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const getFilters = (filterString: string, active: boolean) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [{ active }],
    };
    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [
          { name: { contains: filterArray[i] } },

          {
            team_members: {
              some: {
                employee: {
                  OR: [
                    { first_name: { contains: filterArray[i] } },
                    { last_name: { contains: filterArray[i] } },
                  ],
                },
              },
            },
          },
        ],
      };

      filterObject.AND.push(filterValue);
    }

    return filterObject;
  };

  const variables = {
    filter: getFilters(debouncedValue, inUse), // inUse == active?
    take: pagedState.take,
    skip: 0,
    orderBy: getOrderBy(),
  };

  const {
    data: dataTeams,
    loading,
    error,
    fetchMore,
    refetch,
    client,
  } = useQuery(GET_TEAMS, {
    // notifyOnNetworkStatusChange: true,
    variables,
    onError: error => {
      toastError(error.message);
    },
    // fetchPolicy: 'cache-and-network', comment, we want to use default fetchPolicy
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const onChangeFilterInUse = () => {
    setInUse(!inUse);
    clearTeams();
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    clearTeams();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const clearTeams = () => {
    setPagedState(prevState => ({
      ...prevState,
      teams: [],
      skip: 0,
    }));
  };

  const fetchMoreTeams = () => {
    if (pagedState.take * 2 + pagedState.skip > pagedState.teams.length) {
      setPagedState(prevState => ({
        ...prevState,
        showLoadMore: false,
      }));
    }

    fetchMore({
      variables: {
        filter: getFilters(debouncedValue, inUse), // inUse == active?
        take: pagedState.take,
        skip: pagedState.skip + pagedState.take,
        orderBy: getOrderBy(),
      },
    });

    setPagedState(prevState => ({
      ...prevState,
      skip: pagedState.skip + pagedState.take,
    }));
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <div style={{ padding: '25px' }}>
        <h3
          style={{
            fontSize: '20px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Icon iconName='Family' style={{ fontSize: '20px' }} />
          Ploegen
        </h3>

        <Stack style={{ flexDirection: 'row' }}>
          <TextField
            label='Zoeken...'
            value={filter}
            styles={textFieldStyles300}
            onChange={onSearchValueChange}
          />

          <Stack style={{ marginLeft: 20 }}>
            <Toggle
              label='Actief?'
              defaultChecked={inUse}
              onText='Actief'
              offText='Niet actief'
              onChange={onChangeFilterInUse}
            />
          </Stack>
        </Stack>
      </div>

      <TeamsOverview
        teams={dataTeams ? dataTeams.findManyTeams : []}
        loading={loading}
        setSorting={setSorting}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        showLoadMore={pagedState.showLoadMore}
        loadMore
        loadMoreCallback={fetchMoreTeams}
        lazyLoading={pagedState.lazyLoading}
        refetch={refetch}
      />
    </Stack>
  );
};

export default Teams;
