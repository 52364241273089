import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  ICommandBarItemProps,
  Stack,
} from '@fluentui/react';
import { ISuspect, GET_SUSPECT_BY_ID } from '../../../utils/Suspect';
import { commandBarTheme, commandBarThemeCounter } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import { useAppDispatch } from '../../../redux/hooks';
import { throwError, SeverityLevel } from '../../../redux/error/errorSlice';
import { ILead } from '../../../utils/Lead';
import { GET_EMPLOYEES } from '../../../utils/Employee';
import SuspectDetailList from './SuspectDetailList';
import SuspectDetail from './SuspectDetail';

interface Props {
  loading?: boolean;
  suspects: ISuspect[];
  refetch: any;
}

const SuspectOverview = ({
  suspects,
  loading,
  refetch,
}: Props) => {
  const dispatch = useAppDispatch();
  const [selectedSuspect, setSelectedSuspect] = useState<ISuspect>();
  const [isDetailPanelOpen, setIsDetailPanelOpen] = useState(false);
  const [isCreateLeadDialogOpen, setIsCreateLeadDialogOpen] = useState(false);
  const [isLogItemModalOpen, setIsLogItemModalOpen] = useState(false);

  // Add query for suspect details
  const { data: suspectData } = useQuery(GET_SUSPECT_BY_ID, {
    variables: selectedSuspect ? { where: { id: selectedSuspect.id } } : undefined,
    skip: !selectedSuspect,
    onCompleted: (data) => {
      if (data?.findOneSuspect) {
        setSelectedSuspect(data.findOneSuspect);
      }
    },
    onError: (error) => {
      dispatch(
        throwError({
          module: 'Suspects',
          message: error.message,
          level: SeverityLevel.Error,
        }),
      );
    },
  });

  // Add employees query
  const { data: employeesData } = useQuery(GET_EMPLOYEES, {
    variables: {
      filter: {
        sales_rep: true,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const handleSelect = (suspect: ISuspect) => {
    setSelectedSuspect(suspect);
    setIsDetailPanelOpen(true);
  };

  const handleLeadSelect = (lead: ILead) => {
    // Your fetch logic will go here
    console.log('Selected lead:', lead);
  };

  const handleCreateLead = async (suspectId: number, salesRepId: number) => {
    // You'll implement the mutation logic here
    console.log('Create lead for suspect:', suspectId, 'with sales rep:', salesRepId);
  };

  const handleDismissPanel = (closeAll?: boolean) => {
    if (!isCreateLeadDialogOpen && !isLogItemModalOpen) {
      setIsDetailPanelOpen(false);
      setSelectedSuspect(undefined);
    }
    if (closeAll === true) {
      setIsDetailPanelOpen(false);
      setSelectedSuspect(undefined);
    }
  };

  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'totaal',
      text: `${suspects.length}/${suspects.length > 0 ? suspects[0].prisma_total : 0}`,
      theme: commandBarThemeCounter,
    },
  ];

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        maxWidth='2000px'
      />

      <SuspectDetailList
        items={suspects}
        enableShimmer={!loading}
        onSelect={handleSelect}
      />

      <SuspectDetail
        isOpen={isDetailPanelOpen}
        dismissPanel={handleDismissPanel}
        suspect={selectedSuspect}
        onLeadSelect={handleLeadSelect}
        employees={employeesData?.findManyEmployees?.filter(
          (x: any) => x.sales_rep === true,
        ) || []}
        onCreateLead={handleCreateLead}
        onDialogOpenChange={setIsCreateLeadDialogOpen}
        onLogItemModalChange={setIsLogItemModalOpen}
        refetch={refetch}
      />

      {!loading && suspects.length === 0 && (
        <div style={{ textAlign: 'center', fontWeight: 600, fontSize: 14 }}>
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default SuspectOverview;
