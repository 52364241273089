import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { IComboBoxOption, Stack, IconButton } from '@fluentui/react';

import {
  GET_SUGGESTED_VALUES_BY_CATEGORY_CODE,
  convertSuggestedValuesToComboBoxOptions,
} from '../../utils';
import { ComboboxWithFilter } from '.';

interface ISuggestedValueView {
  onChange: any;
  prefix: string;
  label?: string;
  code?: string; // Expected to be a comma-separated string of selected item keys
  disabled?: boolean;
  section: any;
  field: any;
}

const SuggestedViewList = ({
  onChange,
  prefix,
  label,
  code,
  disabled = false,
  section,
  field,
}: ISuggestedValueView) => {
  const [selectedItems, setSelectedItems] = useState<IComboBoxOption[]>(
    code
      ? code.split(',').map(key => ({
          key: key.trim(),
          text: `Loading item ${key.trim()}...`, // Placeholder text until real data is loaded
        }))
      : [],
  );
  const { isAuthenticated } = useAuth0();

  const { loading, error, data } = useQuery(
    GET_SUGGESTED_VALUES_BY_CATEGORY_CODE,
    {
      variables: {
        filter: {
          suggested_value_category: {
            suggested_value_code_prefix: {
              contains: prefix,
            },
          },
        },
      },
      skip: prefix === undefined || prefix.length === 0,
    },
  );

  useEffect(() => {
    if (code) {
      // Assuming 'code' is a comma-separated string of keys
      const initialKeys = code.split(',').map(k => k.trim());
      const initialItems = initialKeys.map(key => ({
        key,
        text: `Loading item ${key}...`, // Placeholder text until real data is loaded
      }));
      setSelectedItems(initialItems);
    }
  }, []);

  // clear functio
  useEffect(() => {
    if (code) {
      // Assuming 'code' is a comma-separated string of keys
      const initialKeys = code.split(',').map(k => k.trim());
      const initialItems = initialKeys.map(key => ({
        key,
        text: `Loading item ${key}...`, // Placeholder text until real data is loaded
      }));

      if (data && initialItems.some(item => item.text.startsWith('Loading'))) {
        // Map real data to initialItems based on their keys
        const updatedItems = initialItems.map(item => {
          const foundItem = convertSuggestedValuesToComboBoxOptions(
            data.findManySuggestedValues,
          ).find(option => Number(option.key) === Number(item.key));

          return foundItem || item; // Fallback to the existing item if not found
        });

        setSelectedItems(updatedItems);
      }
    } else {
        setSelectedItems([]);
    }
  }, [code]);

  useEffect(() => {
    if (data && selectedItems.some(item => item.text.startsWith('Loading'))) {
      // Map real data to initialItems based on their keys
      const updatedItems = selectedItems.map(item => {
        const foundItem = convertSuggestedValuesToComboBoxOptions(
          data.findManySuggestedValues,
        ).find(option => Number(option.key) === Number(item.key));

        return foundItem || item; // Fallback to the existing item if not found
      });

      setSelectedItems(updatedItems);
    }
  }, [data]);

  if (!isAuthenticated) return <p>Verboden</p>;
  if (loading && !data) return <p>Laden...</p>;
  if (error) return <p>Fout :</p>;

  const comboboxSuggestedValueOptions: IComboBoxOption[] =
    convertSuggestedValuesToComboBoxOptions(data?.findManySuggestedValues);

  const handleAddItem = (newValue: IComboBoxOption) => {
    if (newValue && !selectedItems.find(item => item.key === newValue.key)) {
      const newItems = [...selectedItems, newValue];
      setSelectedItems(newItems);
      onChange(
        section,
        field,
        newItems.map(item => item.key),
      ); // Propagate changes
    }
  };

  const handleRemoveItem = (itemKey: string | number) => {
    const newItems = selectedItems.filter(item => item.key !== itemKey);
    setSelectedItems(newItems);
    onChange(
      section,
      field,
      newItems.map(item => item.key),
    ); // Propagate changes
  };

  return (
    <>
      <ComboboxWithFilter
        disabled={disabled}
        label={label || undefined}
        options={comboboxSuggestedValueOptions}
        value={code ? [+code] : undefined}
        multiline={false}
        callBack={(newValue: IComboBoxOption[]) => handleAddItem(newValue[0])}
      />
      <Stack horizontal wrap tokens={{ childrenGap: 10 }}>
        {selectedItems.map((item, index) => (
          <Stack
            horizontalAlign='center'
            verticalAlign='center'
            key={index}
            tokens={{ childrenGap: 4 }}
          >
            <span>{item.text}</span>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              onClick={() => handleRemoveItem(item.key)}
              ariaLabel='Remove'
            />
          </Stack>
        ))}
      </Stack>
    </>
  );
};

export default SuggestedViewList;
