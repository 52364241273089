import React, { useState } from 'react';
import { Pivot, PivotItem } from '@fluentui/react';
import _ from 'lodash';
import {
  IConstructionSheetRemark,
  IConstructionSite,
  IProperty,
  IPropertyListType,
} from '../../utils';
import ExecutionListOverview from '../implementation-list/components/ExecutionListOverview';
import WaterPoints from './components/WaterPoints';
import ConstructionListRemarks from './components/ConstructionListRemarks';

interface IProps {
  allTypes: any;
  callBackProperties: any;
  handleRemarkChange: any;
  callBackRemarks?: any;
  callBackWaterPoints: any;
  constructionSite: any;
  setConstructionSite: React.Dispatch<
    React.SetStateAction<IConstructionSite | undefined>
  >;
  remarks: any;
  waterpoints: any;
  selectedKey: any;
  setSelectedPropertyID: any;
  setSelection: any;
  onLinkClick: any;
  editItem: any;
  setFilter: React.Dispatch<string>;
  filter?: string;
  moveItem: (a: IProperty, b: IProperty) => void;
}
const PropertyFilterView = ({
  allTypes,
  callBackProperties,
  handleRemarkChange,
  callBackRemarks,
  callBackWaterPoints,
  constructionSite,
  setConstructionSite,
  remarks,
  waterpoints,
  selectedKey = 'Toon alles',
  setSelectedPropertyID,
  setSelection,
  onLinkClick,
  editItem,
  setFilter,
  filter,
  moveItem,
}: IProps) => {
  // Constants

  const SHOW_ALL = 'Toon alles';
  const TAP_POINTS = 'Tappunten';

  const filterRemarks = () =>
    remarks?.filter(
      (el: IConstructionSheetRemark) =>
        el.property_list_type.name === selectedKey,
    );

  function getRemarksByPropertyId(
    remarksList: any[],
    propertyId: string,
  ): string[] {
    return remarksList
      .filter(remark => remark.property_list_type.name === propertyId)
      .map(remark => remark.remarks);
  }

  const filteredRemarks = getRemarksByPropertyId(
    remarks || [],
    selectedKey || '',
  );

  // Functions
  const filterProperties = (): IProperty[] => {
    // Filter based on the selectedKey
    const selection: IProperty[] =
      selectedKey === SHOW_ALL
        ? constructionSite?.properties?.filter(
            (el: IProperty) => el.weight_for_execution_list > 0,
          ) || []
        : constructionSite?.properties?.filter(
            (el: IProperty) =>
              el.weight_for_execution_list > 0 &&
              el.list_types?.some(
                (ele: any) => ele.list_type?.name === selectedKey,
              ),
          ) || [];

    // Sort the filtered selection by weight_for_execution_list in ascending order
    selection.sort(
      (a, b) => a.weight_for_execution_list - b.weight_for_execution_list,
    );

    return selection;
  };

  return (
    <Pivot
      aria-label='Werflijsten'
      onLinkClick={onLinkClick}
      selectedKey={selectedKey}
    >
      {allTypes.map((el: IPropertyListType) => (
        <PivotItem headerText={el.name} itemKey={el.name} key={el.name}>
          {constructionSite &&
            (selectedKey === TAP_POINTS ? (
              <WaterPoints
                properties={waterpoints}
                callBackWaterPoints={callBackWaterPoints}
                // setSelection={setSelection}
                setSelectedPropertyID={setSelectedPropertyID}
                editItem={editItem}
              />
            ) : (
              <div>
                <ExecutionListOverview
                  // id={constructionSite?.id}
                  properties={filterProperties()}
                  dragAndDropEnabled={selectedKey === SHOW_ALL}
                  callBackProperties={callBackProperties}
                  // saveConstructionSite={saveConstructionSite}
                  setSelectedPropertyID={setSelectedPropertyID}
                  setSelection={setSelection}
                  editItem={editItem}
                  setFilter={setFilter}
                  filter={filter}
                  moveItem={moveItem}
                  constructionSite={constructionSite}
                  setConstructionSite={setConstructionSite}
                />
                &nbsp;
                <ConstructionListRemarks
                  remark={filteredRemarks}
                  callBackRemark={handleRemarkChange}
                  show={selectedKey !== SHOW_ALL}
                  name={selectedKey}
                />
              </div>
            ))}
        </PivotItem>
      ))}
    </Pivot>
  );
};

export default PropertyFilterView;
