import {
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  Selection,
} from '@fluentui/react';
import { useState } from 'react';
import moment from 'moment';
import { DetailsListDefault } from '../../../components/parts';
import { ISuspect } from '../../../utils/Suspect';

interface Props {
  items: ISuspect[];
  enableShimmer?: boolean;
  onSelect?: (suspect: ISuspect) => void;
}

const SuspectDetailList = ({
  items,
  enableShimmer = false,
  onSelect,
}: Props) => {
  const columns: IColumn[] = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'name',
      minWidth: 150,
      maxWidth: 200,
      isRowHeader: true,
      onRender: (suspect: ISuspect) => (
        <span>{`${suspect.first_name} ${suspect.last_name}`}</span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Email',
      fieldName: 'email',
      minWidth: 150,
      maxWidth: 200,
      isRowHeader: true,
      onRender: (suspect: ISuspect) => <span>{suspect.email}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Telefoon',
      fieldName: 'phone',
      minWidth: 120,
      maxWidth: 120,
      isRowHeader: true,
      onRender: (suspect: ISuspect) => <span>{suspect.phone}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Adres',
      fieldName: 'address',
      minWidth: 200,
      maxWidth: 300,
      isRowHeader: true,
      onRender: (suspect: ISuspect) => (
        <span>
          {suspect.address}
          <br />
          {suspect.zip_code} {suspect.city}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'Datum',
      fieldName: 'date_created',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      onRender: (suspect: ISuspect) => (
        <span>{moment(suspect.date_created).format('DD/MM/YYYY')}</span>
      ),
      data: 'string',
      isPadded: true,
    },
  ];

  const selection = new Selection({
    onSelectionChanged: () => {
      const selectedItem = selection.getSelection()[0] as ISuspect;
      if (selectedItem && onSelect) {
        onSelect(selectedItem);
      }
    },
  });

  return (
    <DetailsListDefault
      items={items}
      columns={columns}
      layoutMode={DetailsListLayoutMode.justified}
      selection={selection}
      selectionMode={SelectionMode.single}
      enableShimmer={enableShimmer}
    />
  );
};

export default SuspectDetailList;
