import { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Button,
  DefaultButton,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
} from '@fluentui/react';
import { useAppSelector } from '../../redux/hooks';
import { getPermissions } from '../../redux/permission/permissionSlice';

interface IDeleteButtonWithPermissions {
  permission: string;
  disabled?: boolean;
  deleteFn?: () => void;
  loading?: boolean;
  text?: string;
}

const DeleteButtonWithPermissions = ({
  permission,
  disabled = false,
  deleteFn,
  loading = false,
  text,
}: IDeleteButtonWithPermissions) => {
  const permissions = useAppSelector(getPermissions);

  return (
    <StackItem>
      {permissions.includes(permission) && (
        <StackItem>
          <DefaultButton
            onClick={deleteFn}
            style={{ marginRight: 10 }}
            disabled={disabled}
          >
            {text ? (
              <span>{text}</span>
            )
          : (<span>Verwijderen</span>)}
            {loading && (
              <Stack style={{ marginLeft: '5px' }}>
                <Spinner size={SpinnerSize.small} />
              </Stack>
            )}
          </DefaultButton>
        </StackItem>
      )}
    </StackItem>
  );
};

export default DeleteButtonWithPermissions;
