import { StackItem } from '@fluentui/react';
import { IConstructionSite } from '../../../utils/ConstructionSite';
import { IIncomingInvoiceLineItem } from '../../../utils/IncomingInvoiceLineItem';
import { ITimesheetEntry } from '../../../utils/TimesheetEntry';
import {
  calculateMeerMinWerkenBons,
} from './FinancialAnalysisCalculation';

type Props = {
  constructionSite: IConstructionSite;
};

const FinancialAnalysisToInvoice = ({
  constructionSite,
}: Props) => {
  const minmeerwerken = calculateMeerMinWerkenBons(constructionSite);

  return (
    <div>
      {minmeerwerken.toInvoiceUpdates &&
      minmeerwerken.toInvoiceUpdates.length ? (
        <>
          <h2 style={{ marginTop: 5, marginBottom: 10, width: '100%' }}>
            Te factureren onkosten
          </h2>
          <StackItem
            style={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'start',
              marginBottom: 25,
              border: '1px solid #c9c9c9',
              borderRadius: 10,
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <>
              {minmeerwerken.toInvoiceUpdates.map((updates) => (
                <>
                  <StackItem
                    style={{
                      marginBottom: 10,
                      width: '25%',
                    }}
                  >
                    <p
                      style={{
                        paddingLeft: 10,
                      }}
                    >
                      {updates.nr}
                    </p>
                  </StackItem>
                  <StackItem
                    style={{
                      marginBottom: 10,
                      width: '25%',
                    }}
                  >
                    <p>{updates.summary}</p>
                  </StackItem>
                  <StackItem
                    style={{
                      marginBottom: 10,
                      width: '25%',
                    }}
                  >
                    <p>{updates.status}</p>
                  </StackItem>
                  <StackItem
                    style={{
                      marginBottom: 10,
                      width: '25%',
                      textAlign: 'right',
                    }}
                  >
                    <p
                      style={{
                        paddingRight: 10,
                      }}
                    >
                      <strong>{updates.amount.toFixed(2)}</strong>
                    </p>
                  </StackItem>
                </>
              ))}
            </>
          </StackItem>
        </>
      ) : (
        <p />
      )}
    </div>
  );
};

export default FinancialAnalysisToInvoice;
