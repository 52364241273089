import React, { useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Label,
  StackItem,
  Checkbox,
  Dialog,
  DialogFooter,
  TextField,
} from '@fluentui/react';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  dialogModelProps,
  displayNameEmployee,
  modalContentStyles,
} from '../../../utils';
import { dialogRemoveConfirmationPropsGeneral } from '../../../utils/Styles';
import DateView from '../../../components/parts/DateView';
import { DELETE_EMPLOYEE_DEPENDENT_CHILDREN } from '../../../utils/EmployeeDependentChildren';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import { toastSuccess, toastError } from '../../../utils/toast';
import { customPanelStyles } from '../../../theme';

const DependentChildrenDetail = ({
  isOpen,
  dismissPanel,
  saveDependentChildren,
  dependentChildren,
  setDependentChildren,
  refetchEmployee,
}: any) => {
  const [isConfirmationHidden, setIsConfirmationHidden] = useState(true);

  const [deleteDependentChildren] = useMutation(
    DELETE_EMPLOYEE_DEPENDENT_CHILDREN,
  );

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    console.log(event.target, newValue);
    setDependentChildren((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
    console.log(dependentChildren);
  };

  const toggleConfirmationDialog = () => {
    setIsConfirmationHidden(!isConfirmationHidden);
  };

  const deleteDependentChildrenFn = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          deleteDependentChildren({
            variables: {
              where: {
                id: dependentChildren.id,
              },
            },
            onCompleted: async x => {
              toggleConfirmationDialog();
              await refetchEmployee();
              dismissPanel();
              resolve(x);
            },
            onError: err => {
              toggleConfirmationDialog();
              reject(err);
            },
          });
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      await res;
      toastSuccess('Kind ten laste verwijderd');
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        dependentChildren && dependentChildren.id
          ? 'Kinderen ten laste wijzigen'
          : 'Kinderen ten laste toevoegen'
      }
      type={PanelType.custom}
      customWidth='500px'
      styles={customPanelStyles}
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {dependentChildren && dependentChildren.id && (
            <>
              <Label>ID: {dependentChildren.id}</Label>

              <Stack style={{ marginBottom: 10 }}>
                <Label>
                  Medewerker:{' '}
                  {dependentChildren.employee &&
                    displayNameEmployee(dependentChildren.employee)}
                </Label>
              </Stack>
            </>
          )}

          <Stack style={{ marginBottom: 10 }}>
            <DateView
              item={dependentChildren}
              setItem={setDependentChildren}
              date={dependentChildren && dependentChildren.start_date}
              label='Vanaf'
              field='start_date'
            />
          </Stack>

          <Stack style={{ marginBottom: 10 }}>
            <DateView
              item={dependentChildren}
              setItem={setDependentChildren}
              date={dependentChildren && dependentChildren.end_date}
              label='Tot'
              field='end_date'
            />
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='number_of_dependent_children'
              label='Aantal kinderen ten laste'
              type='number'
              value={
                dependentChildren &&
                dependentChildren.number_of_dependent_children
                  ? dependentChildren.number_of_dependent_children
                  : ''
              }
              onChange={onChangeTextFieldValue}
              required
            />
          </Stack>

          <Stack style={{ marginBottom: 10 }}>
            <Label>Huidige status</Label>
            <Checkbox
              name='current_status'
              label='Huidige status?'
              checked={dependentChildren && dependentChildren.current_status}
              onChange={() => {
                setDependentChildren((prevState: any) => ({
                  ...prevState,
                  current_status:
                    dependentChildren && dependentChildren.current_status
                      ? !dependentChildren.current_status
                      : true,
                }));
              }}
            />
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack
            style={{
              flexDirection: 'row',
              marginTop: 10,
              justifyContent: 'space-between',
            }}
          >
            <Stack style={{ flexDirection: 'row' }}>
              <SaveButtonWithPermissions
                disabled={
                  !dependentChildren ||
                  (dependentChildren &&
                    !dependentChildren.number_of_dependent_children)
                }
                save={saveDependentChildren}
                permission='write:employeeDependentChildren'
              />

              <StackItem>
                <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
              </StackItem>
            </Stack>

            <Stack>
              <StackItem>
                <DefaultButton onClick={toggleConfirmationDialog}>
                  Verwijderen
                </DefaultButton>
              </StackItem>
            </Stack>
          </Stack>
        </div>
      </div>

      <Dialog
        hidden={isConfirmationHidden}
        onDismiss={toggleConfirmationDialog}
        dialogContentProps={dialogRemoveConfirmationPropsGeneral}
        modalProps={dialogModelProps}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={deleteDependentChildrenFn}
            text='Verwijderen'
          />
          <DefaultButton onClick={toggleConfirmationDialog} text='Annuleren' />
        </DialogFooter>
      </Dialog>
    </Panel>
  );
};

export default DependentChildrenDetail;
