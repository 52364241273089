import React, { useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  Stack,
  Label,
  StackItem,
  TextField,
  Checkbox,
  ShimmeredDetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
} from '@fluentui/react';
import PhoneInput from 'react-phone-input-2';
import { useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  IEmployee,
  modalContentStyles,
} from '../../../utils';
import { Accordion, AccordionItem } from '../../../components/parts/Accordion';
import DateView from '../../../components/parts/DateView';
import SuggestedView from '../../../components/parts/SuggestedView';
import { commandBarTheme, customPanelStyles } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import {
  ADD_CIVIL_CLASS_STATUS,
  GET_CIVIL_CLASS_STATUS_BY_ID,
  UPDATE_CIVIL_CLASS_STATUS,
} from '../../../utils/CivilClassStatuses';
import {
  ADD_EMPLOYEE_DEPENDENT_CHILDREN,
  GET_EMPLOYEE_DEPENDENT_CHILDREN_BY_ID,
  UPDATE_EMPLOYEE_DEPENDENT_CHILDREN,
} from '../../../utils/EmployeeDependentChildren';
import { IAppUserRole } from '../../../utils/AppUserRole';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import { toastSuccess, toastError } from '../../../utils/toast';
import ZipCodeFetcher from '../../../components/DynamicZip';
import CivilClassDetail from './CivilClassDetail';
import EmployeeDependentChildrenDetail from './DependentChildrenDetail';

const EmployeeDetail = ({
  isOpen,
  dismissPanel,
  saveEmployee,
  employee,
  setEmployee,
  refetchEmployee,
  openNewUserModal,
}: any) => {
  const [selectedValueItem, setSelectedValueItem] = useState<
    number | string | undefined
  >();

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setEmployee((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  const onChangeZipFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setEmployee((prevState: any) => ({
      ...prevState,
      // eslint-disable-next-line radix
      [(event.target as HTMLTextAreaElement).name]:
        parseInt(newValue || '', 10) || null,
    }));
  };

  const onChangeAppUserRole = (checked: any, index: number) => {
    const newState: ExpandedRole[] = employee.app_user.app_user_roles.map(
      (expandedRole: IAppUserRole, _index: number) => {
        // if index equals indx to change, update value
        if (index === _index) {
          return { ...expandedRole, checked };
        }

        // otherwise return object as is
        return expandedRole;
      },
    );

    const newEmployee: IEmployee = {
      ...employee,
      app_user: {
        ...employee.app_user,
        dirty: true, // app user is dirty bc it has been updated
        app_user_roles: newState,
      },
    };

    setEmployee(newEmployee);
  };

  const onChangeAppUserEmail = (value: string) => {
    const newEmployee: IEmployee = {
      ...employee,
      app_user: {
        ...employee.app_user,
        dirty: true, // app user is dirty bc it has been updated
        email: value,
      },
    };

    setEmployee(newEmployee);
  };

  const onChangeAppUserBlocked = (checked: any) => {
    const newEmployee: IEmployee = {
      ...employee,
      app_user: {
        ...employee.app_user,
        dirty: true, // app user is dirty bc it has been updated
        account_locked: checked,
      },
    };

    setEmployee(newEmployee);
  };

  // Dependent children
  const [isDependentChildrenPanelOpen, setIsDependentChildrenPanelOpen] =
    useState(false);
  const [dependentChildren, setDependentChildren] = useState<any>();
  const [
    selectionDependentChildrenDetails,
    setSelectionDependentChildrenDetails,
  ] = useState<IEmployee | undefined>();

  const getSelectionDependentChildrenDetails = () => {
    const currentDependentChildrenSelection: any =
      selectionDependentChildren.getSelection();

    if (currentDependentChildrenSelection.length > 0) {
      setSelectionDependentChildrenDetails(
        currentDependentChildrenSelection[0],
      );
    } else {
      setSelectionDependentChildrenDetails(undefined);
    }
  };

  const selectionDependentChildren = new Selection({
    onSelectionChanged: getSelectionDependentChildrenDetails,
  });

  useQuery(GET_EMPLOYEE_DEPENDENT_CHILDREN_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: selectionDependentChildrenDetails
      ? { where: { id: selectionDependentChildrenDetails.id } }
      : undefined,
    skip: !selectionDependentChildrenDetails,
    onCompleted: data => {
      setDependentChildren(data.findOneEmployeeDependentChildren);
    },
  });

  const openDependentChildrenDetail = (newDependentChildren?: boolean) => {
    if (newDependentChildren) {
      setDependentChildren(undefined);
    }
    setIsDependentChildrenPanelOpen(true);
  };

  const [addDependentChildren] = useMutation(ADD_EMPLOYEE_DEPENDENT_CHILDREN);
  const [modifyDependentChildren] = useMutation(
    UPDATE_EMPLOYEE_DEPENDENT_CHILDREN,
  );

  const saveEmployeeDependentChildren = async () => {
    try {
      if (dependentChildren) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              number_of_dependent_children:
                dependentChildren.number_of_dependent_children
                  ? +dependentChildren.number_of_dependent_children
                  : null,
              current_status: dependentChildren.current_status,
              end_date: dependentChildren.end_date,
              start_date: dependentChildren.start_date,
              employee: { connect: { id: employee.id } },
            };

            if (dependentChildren.id) {
              modifyDependentChildren({
                variables: {
                  id: dependentChildren.id,
                  data: allInput,
                },
                onError: error => {
                  setIsDependentChildrenPanelOpen(false);
                  reject(error);
                },
                onCompleted: async data => {
                  await refetchEmployee();
                  setIsDependentChildrenPanelOpen(false);
                  resolve(data);
                },
              });
            } else {
              addDependentChildren({
                variables: {
                  data: allInput,
                },
                onError: error => {
                  setIsDependentChildrenPanelOpen(false);
                  reject(error);
                },
                onCompleted: async data => {
                  await refetchEmployee();
                  setIsDependentChildrenPanelOpen(false);
                  resolve(data);
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
        toastSuccess('Kinderen opgeslagen');
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  // Civil class
  const [isCivilClassPanelOpen, setIsCivilClassPanelOpen] = useState(false);
  const [civilClass, setCivilClass] = useState<any>();
  const [selectionCivilClassDetails, setSelectionCivilClassDetails] = useState<
    IEmployee | undefined
  >();

  const getSelectionCivilClassDetails = () => {
    const currentCivilClassSelection: any = selectionCivilClass.getSelection();

    if (currentCivilClassSelection.length > 0) {
      setSelectionCivilClassDetails(currentCivilClassSelection[0]);
    } else {
      setSelectionCivilClassDetails(undefined);
    }
  };

  const selectionCivilClass = new Selection({
    onSelectionChanged: getSelectionCivilClassDetails,
  });

  useQuery(GET_CIVIL_CLASS_STATUS_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: selectionCivilClassDetails
      ? { where: { id: selectionCivilClassDetails.id } }
      : undefined,
    skip: !selectionCivilClassDetails,
    onCompleted: data => {
      setCivilClass(data.findOneCivilClassStatus);
    },
  });

  const openCivilClassDetail = (newCivilClass?: boolean) => {
    if (newCivilClass) {
      setCivilClass(undefined);
    }
    setIsCivilClassPanelOpen(true);
  };

  const [addCivilClassStatus] = useMutation(ADD_CIVIL_CLASS_STATUS);
  const [modifyCivilClassStatus] = useMutation(UPDATE_CIVIL_CLASS_STATUS);

  const saveCivilClassStatus = async () => {
    try {
      if (civilClass) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              civil_class: civilClass.civil_class,
              current_status: civilClass.current_status || false,
              end_date: civilClass.end_date,
              start_date: civilClass.start_date,
              employee: { connect: { id: employee.id } },
            };

            if (civilClass.id) {
              modifyCivilClassStatus({
                variables: {
                  id: civilClass.id,
                  data: allInput,
                },
                onError: error => {
                  setIsCivilClassPanelOpen(false);
                  reject(error);
                },
                onCompleted: async data => {
                  await refetchEmployee();
                  setIsCivilClassPanelOpen(false);
                  resolve(data);
                },
              });
            } else {
              addCivilClassStatus({
                variables: {
                  data: allInput,
                },
                onError: error => {
                  setIsCivilClassPanelOpen(false);
                  reject(error);
                },
                onCompleted: async data => {
                  await refetchEmployee();
                  setIsCivilClassPanelOpen(false);
                  resolve(data);
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
        toastSuccess('Burgerlijke staat opgeslagen');
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  const showCivilClass = (status: string) => {
    switch (status) {
      case 'ongehuwd':
        return 'Ongehuwd';
      case 'gehuwd':
        return 'Gehuwd';
      case 'weduwe-weduwnaar':
        return 'Weduwe/Weduwnaar';
      case 'gescheiden':
        return 'Gescheiden';
      case 'samenwonend-wettelijk':
        return 'Wettelijk samenwonend';
      case 'samenwonend-feitelijk':
        return 'Feitelijk samenwonend';
      default:
        return status;
    }
  };

  type ExpandedRole = {
    id: string;
    name: string;
    description: string;
    checked: boolean;
    isConnect: boolean;
    isDisconnect: boolean;
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        employee && employee.id ? 'Medewerker wijzigen' : 'Medewerker toevoegen'
      }
      type={PanelType.custom}
      customWidth='900px'
      styles={customPanelStyles}
    >
      <EmployeeDependentChildrenDetail
        isOpen={isDependentChildrenPanelOpen}
        dismissPanel={() => {
          setIsDependentChildrenPanelOpen(false);
        }}
        dependentChildren={dependentChildren}
        saveDependentChildren={saveEmployeeDependentChildren}
        setDependentChildren={setDependentChildren}
        refetchEmployee={refetchEmployee}
      />
      <CivilClassDetail
        isOpen={isCivilClassPanelOpen}
        dismissPanel={() => {
          setIsCivilClassPanelOpen(false);
        }}
        civilClassStatus={civilClass}
        saveCivilClassStatus={saveCivilClassStatus}
        setCivilClassStatus={setCivilClass}
        refetchEmployee={refetchEmployee}
      />

      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {employee && (
            <Stack
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 5,
              }}
            >
              <StackItem>
                <Label>ID: {employee.id}</Label>
              </StackItem>
              {employee.app_user && employee.app_user.auth0_id ? (
                <StackItem />
              ) : (
                <StackItem>
                  <DefaultButton
                    text='App gebruiker toevoegen'
                    onClick={e => {
                      e.preventDefault();
                      openNewUserModal(employee);
                    }}
                  />
                </StackItem>
              )}
            </Stack>
          )}

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 5,
            }}
          >
            <StackItem style={{ width: '48%' }}>
              <TextField
                name='last_name'
                label='Naam'
                value={employee && employee.last_name ? employee.last_name : ''}
                onChange={onChangeTextFieldValue}
                required
                errorMessage={
                  employee && !employee.last_name ? 'Dit veld is verplicht' : ''
                }
              />
            </StackItem>
            <StackItem style={{ width: '48%' }}>
              <TextField
                name='first_name'
                label='Voornaam'
                value={
                  employee && employee.first_name ? employee.first_name : ''
                }
                onChange={onChangeTextFieldValue}
                required
                errorMessage={
                  employee && !employee.first_name
                    ? 'Dit veld is verplicht'
                    : ''
                }
              />
            </StackItem>
          </Stack>

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 5,
            }}
          >
            <StackItem style={{ width: '48%' }}>
              <Stack style={{ marginBottom: 5 }}>
                <TextField
                  name='address'
                  label='Adres'
                  value={employee && employee.address ? employee.address : ''}
                  onChange={onChangeTextFieldValue}
                />
              </Stack>
              <Stack
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <StackItem style={{ width: '25%' }}>
                  <ZipCodeFetcher
                    object={employee}
                    setObject={setEmployee}
                    zipFieldName='zip_code'
                    cityFieldName='city'
                  />
                </StackItem>
                <StackItem style={{ width: '74%' }}>
                  <TextField
                    name='city'
                    value={employee && employee.city ? employee.city : ''}
                    onChange={onChangeTextFieldValue}
                  />
                </StackItem>
              </Stack>
            </StackItem>
            <StackItem style={{ width: '48%' }}>
              <TextField
                name='email'
                label='E-mail'
                value={employee && employee.email ? employee.email : ''}
                onChange={onChangeTextFieldValue}
              />
            </StackItem>
          </Stack>

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 5,
            }}
          >
            <StackItem style={{ width: '48%' }}>
              <div>
                <Label>In dienst?</Label>
                <Checkbox
                  name='active'
                  label='In dienst'
                  defaultChecked={employee && employee.active}
                  onChange={(e, checked) => {
                    setEmployee((prevState: any) => ({
                      ...prevState,
                      active: checked,
                    }));
                  }}
                />
              </div>
              <div>
                <Label>Extern?</Label>
                <Checkbox
                  name='is_external'
                  label='Extern'
                  defaultChecked={employee && employee.is_external}
                  onChange={(e, checked) => {
                    setEmployee((prevState: any) => ({
                      ...prevState,
                      is_external: checked,
                    }));
                  }}
                />
              </div>
            </StackItem>
            <StackItem style={{ width: '48%' }}>
              <TextField
                name='function'
                label='Functie'
                value={employee && employee.function ? employee.function : ''}
                onChange={onChangeTextFieldValue}
              />
            </StackItem>
          </Stack>

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 5,
            }}
          >
            <StackItem style={{ width: '48%' }}>
              <Label>GSM</Label>
              <PhoneInput
                country='be'
                preferredCountries={['be']}
                value={employee && employee.mobile_v2}
                onChange={phone =>
                  setEmployee((prevState: any) => ({
                    ...prevState,
                    mobile_v2: phone,
                  }))
                }
                inputStyle={{
                  width: '100%',
                  border: '1px solid rgb(96, 94, 92)',
                  borderRadius: '2px',
                }}
                buttonStyle={{
                  border: '1px solid rgb(96, 94, 92)',
                  borderRadius: '2px',
                }}
              />
              {employee && !employee.mobile_v2 && employee.mobile && (
                <div>{employee.mobile}</div>
              )}
            </StackItem>
            <StackItem style={{ width: '48%' }}>
              <Label>GSM Privé</Label>
              <PhoneInput
                country='be'
                preferredCountries={['be']}
                value={employee && employee.private_mobile_v2}
                onChange={phone =>
                  setEmployee((prevState: any) => ({
                    ...prevState,
                    private_mobile_v2: phone,
                  }))
                }
                inputStyle={{
                  width: '100%',
                  border: '1px solid rgb(96, 94, 92)',
                  borderRadius: '2px',
                }}
                buttonStyle={{
                  border: '1px solid rgb(96, 94, 92)',
                  borderRadius: '2px',
                }}
              />
              {employee &&
                !employee.private_mobile_v2 &&
                employee.private_mobile && <div>{employee.private_mobile}</div>}
            </StackItem>
          </Stack>

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 5,
            }}
          >
            <StackItem style={{ width: '48%' }}>
              <Label>Telefoon</Label>
              <PhoneInput
                country='be'
                preferredCountries={['be']}
                value={employee && employee.phone_v2}
                onChange={phone =>
                  setEmployee((prevState: any) => ({
                    ...prevState,
                    phone_v2: phone,
                  }))
                }
                inputStyle={{
                  width: '100%',
                  border: '1px solid rgb(96, 94, 92)',
                  borderRadius: '2px',
                }}
                buttonStyle={{
                  border: '1px solid rgb(96, 94, 92)',
                  borderRadius: '2px',
                }}
              />
              {employee && !employee.phone_v2 && employee.phone && (
                <div>{employee.phone}</div>
              )}
            </StackItem>
            <StackItem style={{ width: '48%' }}>
              <DateView
                item={employee}
                setItem={setEmployee}
                date={employee && employee.birthday}
                label='Geboortedatum'
                field='birthday'
              />
            </StackItem>
          </Stack>

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 5,
            }}
          >
            <StackItem style={{ width: '48%' }}>
              <TextField
                name='bank_account_number'
                label='Rekeningnummer'
                disabled
                value={
                  employee && employee.bank_account_number
                    ? employee.bank_account_number
                    : ''
                }
              />
            </StackItem>
            <StackItem style={{ width: '48%' }}>
              <TextField
                name='iban'
                label='IBAN'
                value={employee && employee.iban ? employee.iban : ''}
                onChange={onChangeTextFieldValue}
              />
            </StackItem>
          </Stack>

          <Stack style={{ marginBottom: 5 }}>
            <TextField
              name='comments'
              label="Nota's"
              multiline
              value={employee && employee.comments ? employee.comments : ''}
              onChange={onChangeTextFieldValue}
            />
          </Stack>

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 5,
            }}
          >
            <StackItem style={{ width: '48%' }}>
              <TextField
                name='diploma'
                label='Diploma'
                value={employee && employee.diploma ? employee.diploma : ''}
                onChange={onChangeTextFieldValue}
              />
            </StackItem>
            <Stack
              style={{
                width: '48%',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <StackItem style={{ width: '48%' }}>
                <DateView
                  item={employee}
                  setItem={setEmployee}
                  date={employee && employee.start_date}
                  label='Startdatum'
                  field='start_date'
                />
              </StackItem>
              <StackItem style={{ width: '48%' }}>
                <DateView
                  item={employee}
                  setItem={setEmployee}
                  date={employee && employee.end_date}
                  label='Einddatum'
                  field='end_date'
                />
              </StackItem>
            </Stack>
          </Stack>

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 10,
            }}
          >
            <StackItem style={{ width: '48%' }}>
              <Stack
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <StackItem style={{ marginBottom: 5, width: '48%' }}>
                  <TextField
                    name='hourly_rate'
                    label='Uurloon'
                    value={
                      employee && employee.hourly_rate
                        ? employee.hourly_rate.toString()
                        : ''
                    }
                    onChange={(e, value) => {
                      onChangeTextFieldValue(e, value);
                    }}
                  />
                </StackItem>

                <StackItem style={{ marginBottom: 5, width: '48%' }}>
                  <TextField
                    name='hourly_rate_for_calculation'
                    label='Uurloon (Na-calculatie)'
                    value={
                      employee && employee.hourly_rate_for_calculation
                        ? employee.hourly_rate_for_calculation.toString()
                        : ''
                    }
                    onChange={(e, value) => {
                      onChangeTextFieldValue(e, value);
                    }}
                    required
                    errorMessage={
                      employee && !employee.first_name
                        ? 'Dit veld is verplicht'
                        : ''
                    }
                  />
                </StackItem>
              </Stack>

              <StackItem>
                <TextField
                  name='monthly_rate'
                  label='Maandloon'
                  value={
                    employee && employee.monthly_rate
                      ? employee.monthly_rate
                      : ''
                  }
                  onChange={onChangeTextFieldValue}
                />
              </StackItem>
            </StackItem>
            <StackItem style={{ width: '48%', marginTop: 30 }}>
              <StackItem style={{ marginBottom: 10 }}>
                <Checkbox
                  name='driver'
                  label='Chauffeur?'
                  defaultChecked={employee && employee.driver}
                  onChange={(e, checked) => {
                    setEmployee((prevState: any) => ({
                      ...prevState,
                      driver: checked,
                    }));
                  }}
                />
              </StackItem>

              <StackItem style={{ marginBottom: 10 }}>
                <Checkbox
                  name='customer_manager'
                  label='Dossierbeheerder?'
                  defaultChecked={employee && employee.customer_manager}
                  onChange={(e, checked) => {
                    setEmployee((prevState: any) => ({
                      ...prevState,
                      customer_manager: checked,
                    }));
                  }}
                />
              </StackItem>

              <StackItem style={{ marginBottom: 10 }}>
                <Checkbox
                  name='construction_site_manager'
                  label='Werfleider?'
                  defaultChecked={
                    employee && employee.construction_site_manager
                  }
                  onChange={(e, checked) => {
                    setEmployee((prevState: any) => ({
                      ...prevState,
                      construction_site_manager: checked,
                    }));
                  }}
                />
              </StackItem>
              <StackItem style={{ marginBottom: 10 }}>
                <Checkbox
                  name='sales_rep'
                  label='Verkoper?'
                  defaultChecked={employee && employee.sales_rep}
                  onChange={(e, checked) => {
                    setEmployee((prevState: any) => ({
                      ...prevState,
                      sales_rep: checked,
                    }));
                  }}
                />
              </StackItem>
              <StackItem style={{ marginBottom: 10 }}>
                <Checkbox
                  name='epb_reporter'
                  label='EPB-verslaggever?'
                  defaultChecked={employee && employee.epb_reporter}
                  onChange={(e, checked) => {
                    setEmployee((prevState: any) => ({
                      ...prevState,
                      epb_reporter: checked,
                    }));
                  }}
                />
              </StackItem>
              <StackItem>
                <Checkbox
                  name='safety_coordinator'
                  label='Veiligheidscoördinator?'
                  defaultChecked={employee && employee.safety_coordinator}
                  onChange={(e, checked) => {
                    setEmployee((prevState: any) => ({
                      ...prevState,
                      safety_coordinator: checked,
                    }));
                  }}
                />
              </StackItem>
            </StackItem>
          </Stack>

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 5,
            }}
          >
            <StackItem style={{ width: '48%' }}>
              <TextField
                name='passport_no'
                label='identiteitskaartnummer'
                value={
                  employee && employee.passport_no
                    ? employee.passport_no
                    : ''
                }
                onChange={onChangeTextFieldValue}
              />
            </StackItem>
            <StackItem style={{ width: '48%' }}>
              <TextField
                name='sis_no'
                label='Rijksregisternummer'
                value={employee && employee.sis_no ? employee.sis_no : ''}
                onChange={onChangeTextFieldValue}
              />
            </StackItem>
          </Stack>

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 5,
            }}
          >
            <StackItem style={{ width: '48%' }}>
              <SuggestedView
                item={employee}
                setItem={setEmployee}
                prefix='employee_employmentMethod_'
                code={
                  employee &&
                  employee.employment_method &&
                  employee.employment_method.substr(
                    employee.employment_method.length - 4,
                  )
                }
                label='Tewerkstelling'
                field='employment_method'
              />
            </StackItem>
            <StackItem style={{ width: '48%' }}>
              <SuggestedView
                item={employee}
                setItem={setEmployee}
                prefix='employee_category_'
                code={
                  employee &&
                  employee.category &&
                  employee.category.substr(employee.category.length - 4)
                }
                label='Hoedanigheid'
                field='category'
              />
            </StackItem>
          </Stack>

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 5,
            }}
          >
            <StackItem style={{ width: '48%' }}>
              <TextField
                name='name_partner'
                label='Naam Partner'
                value={
                  employee && employee.name_partner ? employee.name_partner : ''
                }
                onChange={onChangeTextFieldValue}
              />
            </StackItem>
            <StackItem style={{ width: '48%' }}>
              <TextField
                name='mobile_partner'
                label='GSM Partner'
                value={
                  employee && employee.mobile_partner
                    ? employee.mobile_partner
                    : ''
                }
                onChange={onChangeTextFieldValue}
              />
            </StackItem>
          </Stack>

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 5,
            }}
          >
            <StackItem style={{ width: '48%' }}>
              <DateView
                item={employee}
                setItem={setEmployee}
                date={employee && employee.birthday_partner}
                label='Geboortedatum Partner'
                field='birthday_partner'
              />
            </StackItem>
            <StackItem style={{ width: '48%' }}>
              <TextField
                name='children'
                label='# Kinderen'
                value={employee && employee.children ? employee.children : ''}
                onChange={onChangeTextFieldValue}
              />
            </StackItem>
          </Stack>
          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 5,
            }}
          >
            <StackItem style={{ width: '19%' }}>
              <TextField
                name='jacket_size'
                label='Maat jas'
                value={
                  employee && employee.jacket_size ? employee.jacket_size : ''
                }
                onChange={onChangeTextFieldValue}
              />
            </StackItem>
            <StackItem style={{ width: '19%' }}>
              <TextField
                name='pants_size'
                label='Maat broek'
                value={
                  employee && employee.pants_size ? employee.pants_size : ''
                }
                onChange={onChangeTextFieldValue}
              />
            </StackItem>
            <StackItem style={{ width: '19%' }}>
              <TextField
                name='shirt_size'
                label='Maat shirt'
                value={
                  employee && employee.shirt_size ? employee.shirt_size : ''
                }
                onChange={onChangeTextFieldValue}
              />
            </StackItem>
            <StackItem style={{ width: '19%' }}>
              <TextField
                name='shoe_size'
                label='Maat schoen'
                value={employee && employee.shoe_size ? employee.shoe_size : ''}
                onChange={onChangeTextFieldValue}
              />
            </StackItem>
            <StackItem style={{ width: '19%' }}>
              <TextField
                name='sweater_size'
                label='Maat sweater'
                value={
                  employee && employee.sweater_size ? employee.sweater_size : ''
                }
                onChange={onChangeTextFieldValue}
              />
            </StackItem>
          </Stack>

          <Stack style={{ marginTop: 30, marginBottom: 10 }}>
            {employee && employee.id ? (
              <Accordion
                selectedKey={selectedValueItem}
                defaultKey={undefined}
                toggleItem={(key: string | number) => {
                  if (selectedValueItem === key) {
                    setSelectedValueItem(undefined);
                  } else setSelectedValueItem(key);
                }}
              >
                <AccordionItem
                  key='accordion-dependent-children'
                  id='accordion-dependent-children'
                  title={
                    <Stack>
                      <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                        Historiek kinderen ten laste
                      </h3>
                    </Stack>
                  }
                >
                  <CommandBarSticky
                    items={[
                      {
                        key: 'new',
                        text: 'Nieuw',
                        iconProps: { iconName: 'Add' },
                        onClick: () => openDependentChildrenDetail(true),
                        theme: commandBarTheme,
                      },
                      {
                        key: 'modify',
                        text: 'Wijzig',
                        iconProps: { iconName: 'Edit' },
                        onClick: () => openDependentChildrenDetail(),
                        theme: commandBarTheme,
                        disabled: !selectionDependentChildrenDetails,
                      },
                    ]}
                    theme={commandBarTheme}
                    width='1200px'
                    maxWidth='1200px'
                  />
                  <ShimmeredDetailsList
                    items={employee.employee_dependent_children || []}
                    columns={[
                      {
                        key: 'column1',
                        name: 'Kinderen ten laste',
                        fieldName: 'number_of_dependent_children',
                        minWidth: 200,
                        maxWidth: 200,
                        isRowHeader: true,
                        onRender: (child: any) => (
                          <span>{child.number_of_dependent_children}</span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column2',
                        name: 'Start',
                        fieldName: 'start_date',
                        minWidth: 100,
                        maxWidth: 100,
                        isRowHeader: true,
                        onRender: (child: any) => (
                          <span>
                            {child.start_date &&
                              moment(new Date(child.start_date)).format(
                                'YYYY-MM-DD',
                              )}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column3',
                        name: 'Einde',
                        fieldName: 'end_date',
                        minWidth: 100,
                        maxWidth: 100,
                        isRowHeader: true,
                        onRender: (child: any) => (
                          <span>
                            {child.end_date &&
                              moment(new Date(child.end_date)).format(
                                'YYYY-MM-DD',
                              )}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column4',
                        name: 'Huidige status',
                        fieldName: 'current_status',
                        minWidth: 80,
                        maxWidth: 80,
                        isRowHeader: true,
                        onRender: (child: any) => (
                          <span>
                            <Checkbox
                              disabled
                              defaultChecked={child.current_status}
                            />
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                    ]}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible
                    selection={selectionDependentChildren}
                    selectionMode={SelectionMode.single}
                    selectionPreservedOnEmptyClick
                  />
                </AccordionItem>
                <AccordionItem
                  key='accordion-civil-class-status'
                  id='accordion-civil-class-status'
                  title={
                    <Stack>
                      <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                        Historiek burgerlijke stand
                      </h3>
                    </Stack>
                  }
                >
                  <CommandBarSticky
                    items={[
                      {
                        key: 'new',
                        text: 'Nieuw',
                        iconProps: { iconName: 'Add' },
                        onClick: () => openCivilClassDetail(true),
                        theme: commandBarTheme,
                      },
                      {
                        key: 'modify',
                        text: 'Wijzig',
                        iconProps: { iconName: 'Edit' },
                        onClick: () => openCivilClassDetail(),
                        theme: commandBarTheme,
                        disabled: !selectionCivilClassDetails,
                      },
                    ]}
                    theme={commandBarTheme}
                    width='1200px'
                    maxWidth='1200px'
                  />
                  <ShimmeredDetailsList
                    items={employee.civil_class_statuses || []}
                    columns={[
                      {
                        key: 'column1',
                        name: 'Burgerlijke stand',
                        fieldName: 'civil_class',
                        minWidth: 200,
                        maxWidth: 200,
                        isRowHeader: true,
                        onRender: (status: any) => (
                          <span>{showCivilClass(status.civil_class)}</span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column2',
                        name: 'Start',
                        fieldName: 'start_date',
                        minWidth: 100,
                        maxWidth: 100,
                        isRowHeader: true,
                        onRender: (status: any) => (
                          <span>
                            {status.start_date &&
                              moment(new Date(status.start_date)).format(
                                'YYYY-MM-DD',
                              )}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column3',
                        name: 'Einde',
                        fieldName: 'end_date',
                        minWidth: 100,
                        maxWidth: 100,
                        isRowHeader: true,
                        onRender: (status: any) => (
                          <span>
                            {status.end_date &&
                              moment(new Date(status.end_date)).format(
                                'YYYY-MM-DD',
                              )}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column4',
                        name: 'Huidige status',
                        fieldName: 'current_status',
                        minWidth: 80,
                        maxWidth: 80,
                        isRowHeader: true,
                        onRender: (status: any) => (
                          <span>
                            <Checkbox
                              disabled
                              checked={status && status.current_status}
                            />
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                    ]}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible
                    selection={selectionCivilClass}
                    selectionMode={SelectionMode.single}
                    selectionPreservedOnEmptyClick
                  />
                </AccordionItem>
                {employee.app_user && employee.app_user.id && (
                  <AccordionItem
                    key='accordion-app-user'
                    id='accordion-app-user'
                    title={
                      <Stack>
                        <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                          Gebruiker details
                        </h3>
                      </Stack>
                    }
                  >
                    <Stack
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 5,
                      }}
                    >
                      <StackItem style={{ width: '48%' }}>
                        <TextField
                          name='app_user_email'
                          label='Gebruiker email'
                          value={
                            employee && employee.app_user.email
                              ? employee.app_user.email
                              : ''
                          }
                          onChange={(e: any) =>
                            onChangeAppUserEmail(e.target.value)
                          }
                        />
                        <StackItem style={{ marginTop: 30 }}>
                          <Checkbox
                            name='account locked'
                            label='Geblokkeerd'
                            defaultChecked={employee.app_user.account_locked}
                            onChange={(e: any, checked) =>
                              onChangeAppUserBlocked(checked)
                            }
                          />
                        </StackItem>
                      </StackItem>
                      <StackItem style={{ width: '48%', marginTop: 30 }}>
                        {employee.app_user.app_user_roles.map(
                          (app_user_role: IAppUserRole, index: number) => (
                            <StackItem style={{ marginBottom: 10 }}>
                              <Checkbox
                                name={app_user_role.name}
                                label={app_user_role.name}
                                defaultChecked={app_user_role.checked}
                                onChange={(e: any, checked) =>
                                  onChangeAppUserRole(checked, index)
                                }
                              />
                            </StackItem>
                          ),
                        )}
                      </StackItem>
                    </Stack>
                  </AccordionItem>
                )}
              </Accordion>
            ) : (
              <>
                <Label>
                  Na opslaan kan je de kinderen ten laste en bugerlijke stand
                  aanpassen.
                </Label>
                <Stack style={{ opacity: 0.3, pointerEvents: 'none' }}>
                  <Accordion
                    selectedKey={undefined}
                    defaultKey={undefined}
                    toggleItem={() => {
                      setSelectedValueItem(undefined);
                    }}
                  >
                    <AccordionItem
                      key=''
                      id=''
                      title={
                        <Stack>
                          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                            Historiek kinderen ten laste
                          </h3>
                        </Stack>
                      }
                    />
                    <AccordionItem
                      key=''
                      id=''
                      title={
                        <Stack>
                          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                            Historiek burgerlijke stand
                          </h3>
                        </Stack>
                      }
                    />
                  </Accordion>
                </Stack>
              </>
            )}
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <SaveButtonWithPermissions
              disabled={
                !employee ||
                (employee &&
                  !employee.first_name &&
                  !employee.last_name &&
                  !employee.hourly_rate_for_calculation)
              }
              save={saveEmployee}
              permission='write:employees'
            />

            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default EmployeeDetail;
