import {
  DetailsListLayoutMode,
  IColumn,
  Panel,
  PanelType,
  Selection,
  SelectionMode,
} from '@fluentui/react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { useState } from 'react';
import { modalContentStyles } from '../../../utils';
import {
  GET_WINGS_SYNC_ISSUES,
  IWingsSyncIssue,
} from '../../../utils/WingsSyncIssues';
import { DetailsListDefault } from '../../../components/parts';
import { customPanelStyles } from '../../../theme';

// {
// key: 'column1',
// name: 'Factuur Nr',
// fieldName: 'invoice_no',
// minWidth: 100,
// maxWidth: 250,
// isRowHeader: true,
// onRender: (wingsSyncIssue: IWingsSyncIssue) => (
// <span>{wingsSyncIssue.version > 0 ? `${wingsSyncIssue.version > 1 ? `${wingsSyncIssue.invoice_no} maanden geleden` : `${wingsSyncIssue.version} maand geleden`}` : 'Deze maand'}</span>
// ),
// data: 'string',
// isPadded: true,
// },

const IncomingInvoiceIssueDetail = ({ isOpen, dismissPanel }: any) => {
  const { loading: issuesLoading, data: issuesData } = useQuery(
    GET_WINGS_SYNC_ISSUES,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const columnsList = [
    {
      key: 'column1',
      name: 'Factuur Nr',
      fieldName: 'invoice_no',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      onRender: (wingsSyncIssue: IWingsSyncIssue) => (
        <span>
          {wingsSyncIssue.invoice_no ? (
            <a
              target='_blank'
              style={{ width: '20%', color: 'black' }}
              href={`/incoming-invoices/${wingsSyncIssue.invoice_no}`}
              rel='noreferrer'
            >
              {wingsSyncIssue.issue_details}
            </a>
          ) : (
            <span>{wingsSyncIssue.issue_details}</span>
          )}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Omschrijving',
      fieldName: 'description',
      minWidth: 160,
      maxWidth: 2000,
      isRowHeader: true,
      onRender: (wingsSyncIssue: IWingsSyncIssue) => (
        <span>{wingsSyncIssue.issue_description}</span>
      ),
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Sync datum',
      fieldName: 'invoice_date',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      onRender: (wingsSyncIssue: IWingsSyncIssue) => (
        <span>{moment(wingsSyncIssue.dated_created).format('DD/MM/YYYY')}</span>
      ),
      data: 'string',
      isPadded: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) =>
    columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

  const filter = (array: any[]) => {
    const filteredList = array.filter(
      (value, index, self) =>
        index ===
        self.findIndex(t => t.invoice_no === value.invoice_no && t.issue_description === value.issue_description),
    );

    return filteredList;
  };

  const [columns, setColumns] = useState(initColumns('sortedField', false));

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText='Wings sync alerts'
      type={PanelType.custom}
      customWidth='1050px'
      styles={customPanelStyles}
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          <div>
            <DetailsListDefault
              items={
                issuesData?.findManyWingsSyncIssues
                  ? filter(issuesData?.findManyWingsSyncIssues)
                  : []
              }
              columns={columns}
              layoutMode={DetailsListLayoutMode.justified}
              loading={issuesLoading}
              enableShimmer={!issuesLoading}
            />
          </div>
        </div>
      </div>
    </Panel>
  );
};

export default IncomingInvoiceIssueDetail;
