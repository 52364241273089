import React from 'react';
import { IComboBoxOption, Icon } from '@fluentui/react';
import moment from 'moment';
import { classes } from './Styles';

// eslint-disable-next-line @typescript-eslint/ban-types
export const copyAndSort = <T extends {}>(
  items: T[],
  columnKey: string,
  isSortedDescending?: boolean,
): T[] => {
  const key = columnKey as keyof T;
  return items
    .slice(0)
    .sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
};

export const returnFluentIcon = (iconName: string) => <Icon iconName={iconName} className={classes.icon} />;

export const returnFormattedDate = (someDate: string) => moment(someDate).toISOString();

export const sortComboBoxOptionOnText = (
  a?: IComboBoxOption,
  b?: IComboBoxOption,
) => {
  if (a && b) {
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  }
  return 0;
};

export const titleComboboxOptions = () => [
  { key: 'Dhr.', text: 'Dhr.' },
  { key: 'Mevr.', text: 'Mevr.' },
  // { key: "Mej.", text: "Mej." },
  // { key: "-", text: "-" },
  // { key: "", text: "" },
];

function safeParseJSON<T>(jsonString: string): T | null {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    console.error('Failed to parse JSON:', error);
    return null;
  }
}

export function findTransactionPercentage(itemsStringified: string, paymentTransactionId: number): number | null {
  const items: any[] | null = safeParseJSON<any[]>(itemsStringified);

  if (!items) {
    console.error('Invalid JSON data');
    return null;
  }

  // Use .find() to locate the first item containing the transaction ID
  const foundItem = items.find(item =>
    item.customer_payment_transactions.some((transactionStr: any) => {
      const transaction = safeParseJSON<any>(transactionStr);
      return transaction?.id === paymentTransactionId;
    }));

  // Return the percentage if an item with the transaction ID is found, otherwise null
  return foundItem ? foundItem.percentage : null;
}
