import { ICommandBarItemProps } from '@fluentui/react';
import React from 'react';
import { CommandBarSticky } from '../../../components/parts';
import { commandBarTheme, commandBarThemeCounter } from '../../../theme';

const WetBreyneConstructionSitesCommandBar = ({
  openFilterPanel,
  constructionSites,
}: any) => {
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'totaal',
      text: `${constructionSites.length} / ${constructionSites.length > 0 ? constructionSites[0].prisma_total : 0}`,
      theme: commandBarThemeCounter,
    },

    {
      key: 'advancedFilter',
      text: 'Filter',
      iconProps: { iconName: 'Filter' },
      onClick: () => openFilterPanel(true),
      theme: commandBarTheme,
      // disabled: !selectionDetails,
    },
  ];

  return (
    <CommandBarSticky
      items={commandBaritems}
      theme={commandBarTheme}
      width='1300px'
      maxWidth='1300px'
    />
  );
};

export default WetBreyneConstructionSitesCommandBar;
