import React, { useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Label,
  StackItem,
  Dialog,
  DialogFooter,
  TextField,
} from '@fluentui/react';
import _ from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import {
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  dialogModelProps,
  modalContentStyles,
} from '../../../utils';
import { dialogRemoveConfirmationPropsGeneral } from '../../../utils/Styles';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import { toastError, toastSuccess } from '../../../utils/toast';
import { IAsset } from '../../../utils/Asset';
import { DELETE_INSPECTION, IInspection } from '../../../utils/Inspection';
import DateView from '../../../components/parts/DateView';

const InspectionDetail = ({
  isOpen,
  dismissPanel,
  inspection,
  setInspection,
  saveInspection,
  //
  refetchAsset,
  asset,
}: any) => {
  const [isConfirmationHidden, setIsConfirmationHidden] = useState(true);

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setInspection((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  const [deleteInspection] = useMutation(DELETE_INSPECTION);

  const toggleConfirmationDialog = () => {
    setIsConfirmationHidden(!isConfirmationHidden);
  };

  const deleteInspectionFn = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          deleteInspection({
            variables: {
              where: {
                id: inspection.id,
              },
            },
            onError: error => {
              dismissPanel();
              toggleConfirmationDialog();
              reject(error);
            },
            onCompleted: async (x: any) => {
              dismissPanel();
              toggleConfirmationDialog();
              await refetchAsset();

              // clearLogItems();
              // await refetchLogItems();

              resolve(x);
            },
          });
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      await res;
      toastSuccess('Inspectie verwijderd');
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_LOADING_MESSAGE);
    }
  };

  // ===============================================================================================
  // ===============================================================================================
  // ===============================================================================================

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        inspection && inspection.id
          ? 'Inspectie wijzigen'
          : 'Inspectie toevoegen'
      }
      type={PanelType.custom}
      customWidth='500px'
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {inspection && inspection.id && (
            <>
              <Label>ID: {inspection.id}</Label>

              <Stack style={{ marginBottom: 10 }}>
                <Label>Asset: {(asset as IAsset).name}</Label>
              </Stack>
            </>
          )}

          {/* <Stack style={{ marginBottom: 10 }}>
            <SuggestedView
              item={logItem || {}}
              setItem={setLogItem}
              prefix='logItem_title_'
              code={
                logItem &&
                logItem.title &&
                logItem.title.substr(logItem.title.length - 4)
              }
              label='Omschrijving'
              field='title'
            />
          </Stack> */}

          {/* <Stack style={{ marginBottom: 10 }}>
            <Label>Opvolging nodig?</Label>
            <Checkbox
              name='action_required'
              label='Opvolging nodig?'
              checked={!!(logItem && logItem.action_required)}
              onChange={() => {
                setLogItem((prevState: any) => ({
                  ...prevState,
                  action_required:
                    logItem && logItem.action_required
                      ? !logItem.action_required
                      : true,
                }));
              }}
            />
          </Stack> */}

          <Stack style={{ marginBottom: 10 }}>
            <DateView
              item={inspection}
              setItem={setInspection}
              date={inspection && (inspection as IInspection).inspection_date}
              label='Datum inspectie'
              field='inspection_date'
              required
              disabled={inspection?.id && !inspection?.last}
            />
          </Stack>

          <Stack style={{ marginBottom: 10 }}>
            <DateView
              item={inspection}
              setItem={setInspection}
              date={
                inspection && (inspection as IInspection).inspection_expiry_date
              }
              label='Vervaldatum inspectie'
              field='inspection_expiry_date'
              required
              disabled={inspection?.id && !inspection?.last}
            />
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='comments'
              label='Extra info'
              multiline
              value={
                inspection && (inspection as IInspection).comments
                  ? (inspection as IInspection).comments
                  : ''
              }
              onChange={onChangeTextFieldValue}
              disabled={inspection?.id && !inspection?.last}
            />
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack
            style={{
              flexDirection: 'row',
              marginTop: 10,
              justifyContent: 'space-between',
            }}
          >
            <Stack style={{ flexDirection: 'row' }}>
              {(!inspection?.id || (inspection.id && inspection.last)) && (
                <SaveButtonWithPermissions
                  disabled={
                    !inspection ||
                    (inspection &&
                      (!(inspection as IInspection).inspection_date ||
                        !(inspection as IInspection).inspection_expiry_date))
                  }
                  save={saveInspection}
                  permission='write:inspections'
                />
              )}

              <StackItem>
                <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
              </StackItem>
            </Stack>

            <Stack>
              {inspection?.id && inspection.last && (
                <StackItem>
                  <DefaultButton onClick={toggleConfirmationDialog}>
                    Verwijderen
                  </DefaultButton>
                </StackItem>
              )}
            </Stack>
          </Stack>
        </div>
      </div>

      <Dialog
        hidden={isConfirmationHidden}
        onDismiss={toggleConfirmationDialog}
        dialogContentProps={dialogRemoveConfirmationPropsGeneral}
        modalProps={dialogModelProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={deleteInspectionFn} text='Verwijderen' />
          <DefaultButton onClick={toggleConfirmationDialog} text='Annuleren' />
        </DialogFooter>
      </Dialog>
    </Panel>
  );
};

export default InspectionDetail;
