export function formatPhoneNumber(phoneNumber: string) {
    // Remove any non-digit characters
    const cleaned = phoneNumber.replace(/\D/g, '');

    // Apply different formatting based on length
    if (cleaned.length === 11) { // Format: +32 499 15 72 66
        return `+${cleaned.substring(0, 2)} ${cleaned.substring(2, 5)} ${cleaned.substring(5, 7)} ${cleaned.substring(7, 9)} ${cleaned.substring(9, 11)}`;
    } if (cleaned.length === 10) { // Format: +32 166 33 91 0
        return `+${cleaned.substring(0, 2)} ${cleaned.substring(2, 5)} ${cleaned.substring(5, 7)} ${cleaned.substring(7, 9)} ${cleaned.substring(9, 10)}`;
    }
        // Return the original string if it doesn't match expected formats
        return phoneNumber;
}

export function formatPhoneNumber2(phoneNumber: string) {
    // Remove any non-digit characters
    const cleaned = phoneNumber.replace(/\D/g, '');

    // Apply different formatting based on length
    if (cleaned.length === 11) { // Format: +32 499 15 72 66
        return `+${cleaned.substring(0, 2)} ${cleaned.substring(2, 5)} ${cleaned.substring(5, 7)} ${cleaned.substring(7, 9)} ${cleaned.substring(9, 11)}`;
    } if (cleaned.length === 10) { // Format: +32 166 33 91 0
        return `+${cleaned.substring(0, 2)} ${cleaned.substring(2, 5)} ${cleaned.substring(5, 7)} ${cleaned.substring(7, 9)} ${cleaned.substring(9, 10)}`;
    }
        // Return the original string if it doesn't match expected formats
        return phoneNumber;
}
