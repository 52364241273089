import React, { FormEvent, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import { Icon, Stack, TextField } from '@fluentui/react';
import useDebounce from '../../components/hooks/useDebounce';
import {
  stackTokens15,
  textFieldStyles300,
  default_page_size,
} from '../../utils';
import {
  GET_SUGGESTED_VALUE_CATEGORIES,
  ISuggestedValueCategory,
} from '../../utils/SuggestedValueCategory';
import SuggestedValueCategoryOverview from './components/SuggestedValueCategoryOverview';

interface IPagedSuggestedValueCategory {
  suggestedValueCategories: ISuggestedValueCategory[];
  skip: number;
  take: number;
  isFiltered: boolean;
  initialLoad: boolean;
  isSortedAsc: boolean;
  sortedField: string;
  showLoadMore: boolean;
}

const SuggestedValueCategories = () => {
  const { isAuthenticated } = useAuth0();

  const [pagedState, setPagedState] = useState<IPagedSuggestedValueCategory>({
    suggestedValueCategories: [],
    skip: 0,
    take: default_page_size,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: true,
    sortedField: 'category_description',
    showLoadMore: true,
  });

  const [filter, setFilter] = useState('');

  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    setPagedState(prevState => ({
      ...prevState,
      suggestedValueCategories: [],
      skip: 0,
    }));
  }, [filter]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const { loading, error, refetch } = useQuery(GET_SUGGESTED_VALUE_CATEGORIES, {
    variables: {
      filter: {
        category_description: { contains: debouncedValue },
      },
      // take: pagedState.take,
      // skip: pagedState.skip,
      orderBy: getOrderBy(),
    },
    onCompleted: (x: any) => {
      if (
        x.findManySuggestedValueCategories &&
        x.findManySuggestedValueCategories.length > 0
      ) {
        setPagedState(prevState => ({
          ...prevState,
          suggestedValueCategories: pagedState.suggestedValueCategories.concat(
            x.findManySuggestedValueCategories,
          ),
          isFiltered: false,
          initialLoad: false,
          showLoadMore: !(
            x.findManySuggestedValueCategories.length < default_page_size
          ),
        }));
      }
    },
    fetchPolicy: 'no-cache',
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
    // debouncedFilterChange(filterString);
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    clearSuggestedValueCategories();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const clearSuggestedValueCategories = () => {
    setPagedState(prevState => ({
      ...prevState,
      suggestedValueCategories: [],
      skip: 0,
    }));
  };

  const debouncedScroll = _.debounce(() => {
    setPagedState(prevState => {
      const currentDataLength = prevState.suggestedValueCategories.length;
      return {
        ...prevState,
        skip: currentDataLength,
      };
    });
  }, 250);

  if (!isAuthenticated) return <p>Verboden</p>;

  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <div style={{ padding: '25px' }}>
        <h3
          style={{
            fontSize: '20px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Icon iconName='FilterSettings' style={{ fontSize: '20px' }} />
          Voorgestelde waarden
        </h3>

        <TextField
          label='Zoeken...'
          value={filter}
          styles={textFieldStyles300}
          onChange={onSearchValueChange}
        />
      </div>

      <SuggestedValueCategoryOverview
        suggestedValueCategories={pagedState.suggestedValueCategories}
        loading={loading}
        loadMore={debouncedScroll}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        setSorting={setSorting}
        showLoadMore={false}
        refetch={refetch}
      />
    </Stack>
  );
};

export default SuggestedValueCategories;
