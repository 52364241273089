import React, { useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Label,
  StackItem,
  Checkbox,
  Dialog,
  DialogFooter,
  TextField,
} from '@fluentui/react';
import _ from 'lodash';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import {
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  dialogModelProps,
  modalContentStyles,
} from '../../../utils';
import { DELETE_LOG_ITEM } from '../../../utils/LogItem';
import { dialogRemoveConfirmationPropsGeneral } from '../../../utils/Styles';
import { displayNameLead } from '../../../utils/Lead';
import DateView from '../../../components/parts/DateView';
import SuggestedView from '../../../components/parts/SuggestedView';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import { toastError, toastSuccess } from '../../../utils/toast';
import { customPanelStyles } from '../../../theme';

const LogItemDetail = ({
  isOpen,
  dismissPanel,
  saveLogItem,
  logItem,
  setLogItem,
  refetchLogItems,
  clearLogItems,
  lead,
  setLead,
  refetchLead,
}: any) => {
  const [isConfirmationHidden, setIsConfirmationHidden] = useState(true);

  const [deleteLogItem] = useMutation(DELETE_LOG_ITEM);

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setLogItem((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  const toggleConfirmationDialog = () => {
    setIsConfirmationHidden(!isConfirmationHidden);
  };

  const deleteLogItemFn = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          deleteLogItem({
            variables: {
              where: {
                id: logItem.id,
              },
            },
            onError: error => {
              dismissPanel();
              toggleConfirmationDialog();
              reject(error);
            },
            onCompleted: async (x: any) => {
              dismissPanel();
              toggleConfirmationDialog();
              await refetchLead();
              // clearLogItems();
              // await refetchLogItems();
              resolve(x);
            },
          });
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      await res;
      toastSuccess('Logitem verwijderd');
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_LOADING_MESSAGE);
    }
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        logItem && logItem.id ? 'Log item wijzigen' : 'Log item toevoegen'
      }
      type={PanelType.custom}
      customWidth='500px'
      styles={customPanelStyles}
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {logItem && logItem.id && (
            <>
              <Label>ID: {logItem.id}</Label>

              <Stack style={{ marginBottom: 10 }}>
                <Label>
                  Prospect: {logItem.lead && displayNameLead(logItem.lead)}
                </Label>
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <Label>Laatst gewijzigd door</Label>
                <p>
                  {logItem &&
                    logItem.app_user &&
                    `${logItem.app_user.first_name} ${logItem.app_user.last_name}`}
                </p>
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <Label>Laatst gewijzigd op</Label>
                <p>
                  {logItem &&
                    moment(new Date(logItem.last_updated)).format('DD/MM/YYYY')}
                </p>
              </Stack>
            </>
          )}

          <Stack style={{ marginBottom: 10 }}>
            <SuggestedView
              item={logItem || {}}
              setItem={setLogItem}
              prefix='logItem_title_'
              code={
                logItem &&
                logItem.title &&
                logItem.title.substr(logItem.title.length - 4)
              }
              label='Omschrijving'
              field='title'
            />
          </Stack>

          <Stack style={{ marginBottom: 10 }}>
            <Label>Opvolging nodig?</Label>
            <Checkbox
              name='action_required'
              label='Opvolging nodig?'
              checked={!!(logItem && logItem.action_required)}
              onChange={() => {
                setLogItem((prevState: any) => ({
                  ...prevState,
                  action_required:
                    logItem && logItem.action_required
                      ? !logItem.action_required
                      : true,
                }));
              }}
            />
          </Stack>

          <Stack style={{ marginBottom: 10 }}>
            <DateView
              item={logItem}
              setItem={setLogItem}
              date={logItem && logItem.action_required_by}
              label='Vervalt op / uitgevoerd op'
              field='action_required_by'
            />
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='text'
              label='Extra info'
              multiline
              value={logItem && logItem.text ? logItem.text : ''}
              onChange={onChangeTextFieldValue}
            />
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack
            style={{
              flexDirection: 'row',
              marginTop: 10,
              justifyContent: 'space-between',
            }}
          >
            <Stack style={{ flexDirection: 'row' }}>
              <SaveButtonWithPermissions
                disabled={!logItem || (logItem && !logItem.title)}
                save={saveLogItem}
                permission='write:logItems'
              />

              <StackItem>
                <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
              </StackItem>
            </Stack>

            <Stack>
              <StackItem>
                <DefaultButton onClick={toggleConfirmationDialog}>
                  Verwijderen
                </DefaultButton>
              </StackItem>
            </Stack>
          </Stack>
        </div>
      </div>

      <Dialog
        hidden={isConfirmationHidden}
        onDismiss={toggleConfirmationDialog}
        dialogContentProps={dialogRemoveConfirmationPropsGeneral}
        modalProps={dialogModelProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={deleteLogItemFn} text='Verwijderen' />
          <DefaultButton onClick={toggleConfirmationDialog} text='Annuleren' />
        </DialogFooter>
      </Dialog>
    </Panel>
  );
};

export default LogItemDetail;
