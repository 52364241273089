import { StackItem } from '@fluentui/react';
import { IConstructionSite } from '../../../utils';
import { IIncomingInvoiceLineItem } from '../../../utils/IncomingInvoiceLineItem';
import { ITimesheetEntry } from '../../../utils/TimesheetEntry';
import parseNumber from '../../../utils/Numbers';

type Props = {
  constructionSite: IConstructionSite;
  incomingInvoiceLines: IIncomingInvoiceLineItem[];
  timeSheetEntries: ITimesheetEntry[];
};

export function calculateMeerMinWerkenBons(
  constructionSite: IConstructionSite,
) {
  let minAmount = 0;
  let plusAmount = 0;
  const plannedUpdates: {
    nr: string;
    summary: string;
    amount: number;
    status: string;
  }[] = [];

  if (constructionSite && constructionSite.customer_contract_updates) {
    for (
      let i = 0;
      i < constructionSite.customer_contract_updates.length;
      i++
    ) {
      const updateObj = constructionSite.customer_contract_updates[i];

      if (
        updateObj.customer_contract_update_line_items && updateObj.status !== 'CANCELLED' // && updateObj.status === 'INVOICED' <- we tellen alle min meer werken mee, ook die nog niet gefactureerd zijn. MAAR NIET DE GENE DIE GEANULEERD ZIJN
      ) {
        for (
          let r = 0;
          r < updateObj.customer_contract_update_line_items.length;
          r++
        ) {
          const lineObj = updateObj.customer_contract_update_line_items[r];
          const amount =
            parseNumber(lineObj.units, 1) * parseNumber(lineObj.unit_price);

          if (amount > 0) {
            plusAmount += amount;
          } else {
            minAmount += amount;
          }
        }
      }

      // ADD all updates that are planned
      if (
        updateObj.customer_contract_update_line_items &&
        (updateObj.status === 'COMPLETED' || updateObj.status === 'NEW')
      ) {
        let toInvoiceAmount = 0;
        for (
          let r = 0;
          r < updateObj.customer_contract_update_line_items.length;
          r++
        ) {
          const lineObj = updateObj.customer_contract_update_line_items[r];
          const amount =
            parseNumber(lineObj.units, 1) * parseNumber(lineObj.unit_price);

          toInvoiceAmount += amount;
        }

        plannedUpdates.push({
          nr: updateObj.document_no ? updateObj.document_no : '',
          summary: updateObj.summary ? updateObj.summary : '',
          amount: toInvoiceAmount,
          status: updateObj.status,
        });
      }
    }
  }

  return {
    min: minAmount,
    plus: plusAmount,
    toInvoiceUpdates: plannedUpdates,
  };
}

export function calculateInvoiced(constructionSite: IConstructionSite) {
  let amountInvoice = 0;
  let amountPaid = 0;

  if (constructionSite && constructionSite.customer_invoices) {
    for (let i = 0; i < constructionSite.customer_invoices.length; i++) {
      const invoiceObj = constructionSite.customer_invoices[i];
      if (invoiceObj.customer_invoice_line_items) {
        for (
          let r = 0;
          r < invoiceObj.customer_invoice_line_items.length;
          r++
        ) {
          const lineObj = invoiceObj.customer_invoice_line_items[r];
          amountInvoice +=
            (lineObj.units ? lineObj.units : 1) *
            (lineObj.unit_price ? lineObj.unit_price : 0);

          if (invoiceObj.invoice_paid) {
            amountPaid +=
              (lineObj.units ? lineObj.units : 1) *
              (lineObj.unit_price ? lineObj.unit_price : 0);
          }
        }
      }
    }
  }

  return {
    invoiceAmount: amountInvoice,
    paidAmount: amountPaid,
  };
}

export function calculateCosts(
  incomingInvoiceLines: IIncomingInvoiceLineItem[],
  timeSheetEntries: ITimesheetEntry[],
) {
  let amountInvoice = 0;
  let amountHours = 0;

  if (incomingInvoiceLines) {
    for (let r = 0; r < incomingInvoiceLines.length; r++) {
      amountInvoice += incomingInvoiceLines[r].amount
        ? incomingInvoiceLines[r].amount
        : 0;
    }
  }

  if (timeSheetEntries) {
    for (let r = 0; r < timeSheetEntries.length; r++) {
      if (
        timeSheetEntries[r] &&
        timeSheetEntries[r].hourly_rate &&
        timeSheetEntries[r].hours
      ) {
        const rate = timeSheetEntries[r].hourly_rate;
        const hours = timeSheetEntries[r].hours;
        if (hours && rate) {
          amountHours += +hours * rate;
        }
      }
    }
  }

  return {
    timeAmount: amountHours,
    invoiceAmount: amountInvoice,
  };
}

const FinancialAnalysisCalculation = ({
  constructionSite,
  incomingInvoiceLines,
  timeSheetEntries,
}: Props) => {
  const contractAmount = constructionSite.contract_amount ? constructionSite.contract_amount : 0;

  const costs = calculateCosts(incomingInvoiceLines, timeSheetEntries);
  const invoiced = calculateInvoiced(constructionSite);
  const minmeerwerken = calculateMeerMinWerkenBons(constructionSite);

  return (
    <>
      <h2 style={{ marginTop: 5, marginBottom: 10, width: '100%' }}>
        Samenvatting
      </h2>
      <StackItem
        style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'start',
          marginBottom: 25,
          border: '1px solid #c9c9c9',
          borderRadius: 10,
          paddingTop: 10,
        }}
      >
        <StackItem
          style={{
            marginBottom: 35,
            width: '40%',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
        >
          <StackItem
            style={{
              marginBottom: 10,
              width: '50%',
            }}
          >
            <p
              style={{
                paddingLeft: 10,
              }}
            >
              Gefactureerd
            </p>
            <p
              style={{
                paddingLeft: 10,
              }}
            >
              Ontvangen
            </p>
            <hr
              style={{
                marginLeft: 10,
              }}
            />
            <p
              style={{
                paddingLeft: 10,
              }}
            >
              Saldo
            </p>
          </StackItem>
          <StackItem
            style={{
              marginBottom: 10,
              width: '50%',
              textAlign: 'right',
            }}
          >
            <p>
              <strong>{invoiced.invoiceAmount.toFixed(2)}</strong>
            </p>
            <p>
              <strong>{invoiced.paidAmount.toFixed(2)}</strong>
            </p>
            <hr />
            <p>
              <strong>
                {(invoiced.invoiceAmount - invoiced.paidAmount).toFixed(2)}
              </strong>
            </p>
          </StackItem>
        </StackItem>

        <StackItem
          style={{
            marginBottom: 35,
            width: '50%',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
        >
          <StackItem
            style={{
              marginBottom: 10,
              width: '60%',
            }}
          >
            <p><strong>Marge (Gefactureerd / kosten)</strong></p>
          </StackItem>
          <StackItem
            style={{
              marginBottom: 10,
              width: '30%',
              textAlign: 'right',
            }}
          >
            <p
              style={{
                paddingRight: 10,
              }}
            >
              <strong>
                {(
                  invoiced.invoiceAmount /
                  (costs.timeAmount + costs.invoiceAmount)
                ).toFixed(2)}
              </strong>
            </p>
          </StackItem>
        </StackItem>

        <StackItem
          style={{
            marginBottom: 35,
            width: '40%',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
        >
          <StackItem
            style={{
              marginBottom: 10,
              width: '50%',
            }}
          >
            <p
              style={{
                paddingLeft: 10,
              }}
            >
              Contractbedrag
            </p>
            <p
              style={{
                paddingLeft: 10,
              }}
            >
              Meerwerkenbons
            </p>
            <p
              style={{
                paddingLeft: 10,
              }}
            >
              Minderwerkenbons
            </p>
            <hr
              style={{
                marginLeft: 10,
              }}
            />
            <p
              style={{
                paddingLeft: 10,
              }}
            >
              Totaal facturatie
            </p>
          </StackItem>
          <StackItem
            style={{
              marginBottom: 10,
              width: '50%',
              textAlign: 'right',
            }}
          >
            <p>
              <strong>{contractAmount.toFixed(2)}</strong>
            </p>
            <p>
              <strong>{minmeerwerken.plus.toFixed(2)}</strong>
            </p>
            <p>
              <strong>{minmeerwerken.min.toFixed(2)}</strong>
            </p>
            <hr />
            <p>
              <strong>
                {(
                  contractAmount +
                  minmeerwerken.plus +
                  minmeerwerken.min
                ).toFixed(2)}
              </strong>
            </p>
          </StackItem>
        </StackItem>

        <StackItem
          style={{
            marginBottom: 35,
            width: '50%',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
        >
          <StackItem
            style={{
              marginBottom: 10,
              width: '50%',
            }}
          >
            <p
              style={{
                paddingLeft: 10,
              }}
            >
              Saldo (Totaal - gefactureerd)
            </p>
            <p
              style={{
                paddingLeft: 10,
              }}
            >
              Saldo (Totaal - ontvangen)
            </p>
            <br />
          </StackItem>
          <StackItem
            style={{
              marginBottom: 10,
              width: '50%',
              textAlign: 'right',
            }}
          >
            <p
              style={{
                paddingRight: 10,
              }}
            >
              <strong>
                {(
                  contractAmount +
                  minmeerwerken.plus +
                  minmeerwerken.min -
                  invoiced.invoiceAmount
                ).toFixed(2)}
              </strong>
            </p>
            <p
              style={{
                paddingRight: 10,
              }}
            >
              <strong>
                {(
                  contractAmount +
                  minmeerwerken.plus +
                  minmeerwerken.min -
                  invoiced.paidAmount
                ).toFixed(2)}
              </strong>
            </p>
            <br />
          </StackItem>
        </StackItem>

        <StackItem
          style={{
            marginBottom: 35,
            width: '40%',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
        >
          <StackItem
            style={{
              marginBottom: 10,
              width: '50%',
            }}
          >
            <p
              style={{
                paddingLeft: 10,
              }}
            >
              Inkomende kosten
            </p>
            <p
              style={{
                paddingLeft: 10,
              }}
            >
              Werkuren kosten
            </p>
            <hr
              style={{
                marginLeft: 10,
              }}
            />
            <p
              style={{
                paddingLeft: 10,
              }}
            >
              Totaal kosten
            </p>
          </StackItem>
          <StackItem
            style={{
              marginBottom: 10,
              width: '50%',
              textAlign: 'right',
            }}
          >
            <p>
              <strong>{costs.invoiceAmount.toFixed(2)}</strong>
            </p>
            <p>
              <strong>{costs.timeAmount.toFixed(2)}</strong>
            </p>
            <hr />
            <p>
              <strong>
                {(costs.timeAmount + costs.invoiceAmount).toFixed(2)}
              </strong>
            </p>
          </StackItem>
        </StackItem>

        <StackItem
          style={{
            marginBottom: 35,
            width: '50%',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
        >
          <StackItem
            style={{
              marginBottom: 10,
              width: '50%',
            }}
          >
            <p>Saldo (Gefactureerd - kosten)</p>
            <p>Saldo (Totaal - kosten)</p>
            <p>Saldo (Ontvangen - kosten)</p>
          </StackItem>
          <StackItem
            style={{
              marginBottom: 10,
              width: '50%',
              textAlign: 'right',
            }}
          >
            <p
              style={{
                paddingRight: 10,
              }}
            >
              <strong>
                {(
                  invoiced.invoiceAmount -
                  (costs.timeAmount + costs.invoiceAmount)
                ).toFixed(2)}
              </strong>
            </p>
            <p
              style={{
                paddingRight: 10,
              }}
            >
              <strong>
                {(
                  contractAmount +
                  minmeerwerken.plus +
                  minmeerwerken.min -
                  (costs.timeAmount + costs.invoiceAmount)
                ).toFixed(2)}
              </strong>
            </p>
            <p
              style={{
                paddingRight: 10,
              }}
            >
              <strong>
                {(
                  invoiced.paidAmount -
                  (costs.timeAmount + costs.invoiceAmount)
                ).toFixed(2)}
              </strong>
            </p>
          </StackItem>
        </StackItem>
      </StackItem>
    </>
  );
};

export default FinancialAnalysisCalculation;
