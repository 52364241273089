import { gql } from '@apollo/client';
import { ICar } from './Car';
import { IConstructionSite } from './ConstructionSite';
import { ICostCenter } from './CostCenter';
import { ICustomer } from './Customer';
import { IIncomingInvoice } from './IncomingInvoice';

export const GET_INCOMING_INVOICE_LINE_ITEMS = gql`
  query getIncomingInvoiceLineItems(
    $filter: IncomingInvoiceLineItemFilterInput
    $take: Int
    $skip: Int
    $orderBy: IncomingInvoiceLineItemOrderByInput
  ) {
    findManyIncomingInvoiceLineItems(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      amount
      amount_vat
      car_id
      comment
      cost_center_id
      customer_id
      description
      id
      incoming_invoice_id
      wings_lineid
      car {
        id
        license_plate
      }
      cost_center {
        id
      }
      customer {
        id
      }
      incoming_invoice {
        id
      }
    }
  }
`;

export const GET_INCOMING_INVOICE_LINE_ITEMS_ANALYSIS = gql`
  query getIncomingInvoiceLineItems(
    $filter: IncomingInvoiceLineItemFilterInput
  ) {
    findManyIncomingInvoiceLineItems(
      filter: $filter
    ) {
      amount
      amount_vat
      car_id
      comment
      cost_center_id
      customer_id
      description
      id
      incoming_invoice_id
      wings_lineid
      car {
        id
        license_plate
      }
      cost_center {
        id
      }
      customer {
        id
      }
      incoming_invoice {
        id
      }
    }
  }
`;

export const GET_INCOMING_INVOICE_LINE_ITEM_BY_ID = gql`
  query getIncomingInvoiceLineItemById(
    $where: IncomingInvoiceLineItemWhereInput
  ) {
    findOneIncomingInvoiceLineItem(where: $where) {
      amount
      amount_vat
      car_id
      comment
      cost_center_id
      customer_id
      description
      id
      incoming_invoice_id
      wings_lineid
      car {
        id
        license_plate
      }
      cost_center {
        id
      }
      customer {
        id
      }
      incoming_invoice {
        id
      }
      construction_site {
        id
      }
    }
  }
`;

export const ADD_INCOMING_INVOICE_LINE_ITEM = gql`
  mutation AddIncomingInvoiceLineItem(
    $data: IncomingInvoiceLineItemCreateInput!
  ) {
    createIncomingInvoiceLineItem(data: $data) {
      id
    }
  }
`;

export const UPDATE_INCOMING_INVOICE_LINE_ITEM = gql`
  mutation UpdateIncomingInvoiceLineItem(
    $id: Int!
    $data: IncomingInvoiceLineItemUpdateInput!
  ) {
    updateIncomingInvoiceLineItem(id: $id, data: $data) {
      id
    }
  }
`;

export interface IIncomingInvoiceLineItem {
  amount: number;
  amount_vat: number;
  comment: string;
  description: string;
  id: number;
  version: number;
  wings_lineid: string;
  car: ICar;
  cost_center: ICostCenter;
  construction_site: IConstructionSite;
  incoming_invoice: IIncomingInvoice;
}
