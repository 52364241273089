/* eslint-disable no-restricted-globals */
import { useState } from 'react';
import { IComboBoxOption } from '@fluentui/react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import {
  GET_CONSTRUCTION_SITES,
  GET_CONSTRUCTION_SITE_BY_ID,
  convertConstructionSitesToComboBoxOptions,
} from '../../utils';
import useDebounce from '../hooks/useDebounce';
import {
  GET_SUPPLIERS,
  GET_SUPPLIER_BY_ID,
  convertSuppliersToComboBoxOptions,
} from '../../utils/Supplier';
import ComboboxWithFilter from './ComboboxWithFilter';
import { FilterField, FilterSection } from './FilterPanel';

type Props = {
  label: string;
  field: FilterField;
  section: FilterSection;
  onChangeFieldValue: (
    section: FilterSection,
    field: FilterField,
    value: any,
  ) => void;
  getFieldValue: (section_id: string, field: FilterField) => string;
};

const FilterPanelSuppliers = ({
  label,
  field,
  section,
  onChangeFieldValue,
  getFieldValue,
}: Props) => {
  const [supplierFilter, setSupplierFilter] = useState('');
  const debouncedFilter = useDebounce(supplierFilter, 500);

  const { loading: supplierLoading, data: supplierData } = useQuery(
    GET_SUPPLIER_BY_ID,
    {
      fetchPolicy: 'no-cache',
      skip:
        getFieldValue(section.id, field) === undefined ||
        getFieldValue(section.id, field) === null,
      variables: {
        where: {
          id: parseInt(getFieldValue(section.id, field) || '-1', 10),
        },
      },
    },
  );

  const { loading: suppliersLoading, data: suppliersData } = useQuery(
    GET_SUPPLIERS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        orderBy: {
          name: 'asc',
        },
        filter: debouncedFilter
          ? {
              name: {
                contains: debouncedFilter,
              },
            }
          : undefined,
        take: 15,
      },
    },
  );

  const options = convertSuppliersToComboBoxOptions(
    suppliersData && suppliersData.findManySuppliers
      ? supplierData &&
        supplierData.findOneSupplier !== null &&
        supplierData.findOneSupplier.name
        ? suppliersData.findManySuppliers.concat(supplierData.findOneSupplier)
        : suppliersData.findManySuppliers
      : [],
  );

  options.push({ key: 'ALLES', text: 'Alles' });

  return (
    <ComboboxWithFilter
      label={label}
      options={options}
      value={
        !isNaN(getFieldValue(section.id, field) as any)
          ? parseInt(getFieldValue(section.id, field), 10)
          : getFieldValue(section.id, field)
      }
      multiline={false}
      callBack={(newValue: IComboBoxOption[]) => {
        if (newValue && newValue.length > 0) {
          onChangeFieldValue(section, field, newValue[0].key);
        }
      }}
      setFilter={filterValue => {
        setSupplierFilter(filterValue);
      }}
      allowFreeForm
    />
  );
};

export default FilterPanelSuppliers;
