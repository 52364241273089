import React from 'react';
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import moment from 'moment';
import Briefpapier from '../../../Sjabloon-briefpapier.jpg';
import fontSegoe from '../../../fonts/Segoe_UI.ttf';
import fontSegoeBold from '../../../fonts/Segoe_UI_Bold.ttf';
import { ICustomerPaymentTransaction } from '../../../utils/CustomerPaymentTransaction';
import { displayNameCustomer } from '../../../utils';

Font.register({
  family: 'Segoe UI',
  fonts: [{ src: fontSegoe }, { src: fontSegoeBold, fontStyle: 'bold' }],
});

const CustomerPaymentTransactionDownloadTemplate = ({
  customerPaymentTransactions,
}: {
  customerPaymentTransactions: ICustomerPaymentTransaction[];
}) => (
  <Document>
    <Page size='A4' style={styles.page}>
      <View
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100vw',
          height: '100vh',
        }}
        fixed
      >
        <Image src={Briefpapier} style={{ width: '100%', height: '100%' }} />
      </View>

      {/* Header repeated on every page */}
      <PageHeader />

      {/* <View style={styles.pageHeader}> */}
      <View>
        <PagePaymentTransactionLines
          customerPaymentTransactions={customerPaymentTransactions}
        />
      </View>
      {/* </View> */}

      {/* Footer repeated on every page */}
    </Page>
  </Document>
);

const PageHeader = () => (
  <View style={styles.pageHeaderWrapper} fixed>
    <View style={styles.pageHeader}>
      <View>
        <Text style={styles.pageHeaderTitle}>
          Openstaande betalingsschijven
        </Text>
        <Text style={styles.small}>
          {moment(new Date()).format('DD/MM/YYYY')}
        </Text>
        <Text
          style={styles.small}
          render={({ pageNumber, totalPages }) =>
            `Pagina ${pageNumber} van ${totalPages}`
          }
        />
      </View>
    </View>
  </View>
);

const PagePaymentTransactionLines = ({
  customerPaymentTransactions,
}: {
  customerPaymentTransactions: ICustomerPaymentTransaction[];
}) => {
  const transactions: any[] = [];

  customerPaymentTransactions.map(transaction => {
    if (transactions && transaction.construction_site) {
      const index = transactions.findIndex(
        (x: any) =>
          x.construction_site &&
          x.construction_site.id === transaction.construction_site!.id,
      );
      if (index && transactions[index]) {
        transactions[index].info.push(transaction);
      } else {
        transactions.push({
          construction_site: transaction.construction_site,
          info: [transaction],
        });
      }
    } else if (
      transactions &&
      !transaction.construction_site &&
      transaction.customer
    ) {
      const customerIndex = transactions.findIndex(
        (x: any) => x.customer && x.customer.id === transaction.customer!.id,
      );
      if (customerIndex && transactions[customerIndex]) {
        transactions[customerIndex].info.push(transaction);
      } else {
        transactions.push({
          customer: transaction.customer,
          info: [transaction],
        });
      }
    }
  });

  return (
    <View style={styles.table}>
      {/* Table header repeated on every page */}
      <View style={styles.tableRowHeader} fixed>
        <Text style={styles.tableColSite}>Werf</Text>
        <Text style={styles.tableColDescription}>Omschrijving</Text>
        <Text style={styles.tableColPrice}>%</Text>
        <Text style={styles.tableColTotal}>Bedrag</Text>
      </View>

      {/* Lines */}
      {transactions.map((line: any, i: number) => (
        <View key={`line-${i}`} style={styles.tableRow} wrap={false}>
          <Text style={styles.tableColSite}>
            {line.construction_site
              ? line.construction_site.name
              : line.customer && displayNameCustomer(line.customer)}
          </Text>
          {line.info.map((x: any, i: number) => (
            <>
              {i > 0 && <Text style={styles.tableColSite} />}
              <Text style={styles.tableColDescription}>{x.description}</Text>
              <Text style={styles.tableColPrice}>{x.percentage}%</Text>
              <Text style={styles.tableColTotal}>
                {line.construction_site &&
                  line.construction_site.contract_amount && (
                    <>
                      &euro;{' '}
                      {(
                        (x.percentage / 100) *
                        line.construction_site.contract_amount
                      ).toFixed(2)}
                    </>
                  )}
              </Text>
            </>
          ))}
        </View>
      ))}
      {/* {customerPaymentTransactions && customerPaymentTransactions.length > 0
        ? customerPaymentTransactions.map((line, i: number) => (
            // eslint-disable-next-line react/jsx-indent
            <View key={`line-${i}`} style={styles.tableRow} wrap={false}>
              <Text style={styles.tableColSite}>
                {line.construction_site
                  ? line.construction_site.name
                  : line.customer && displayNameCustomer(line.customer)}
              </Text>
              <Text style={styles.tableColDescription}>{line.description}</Text>
              <Text style={styles.tableColPrice}>{line.percentage}%</Text>
            </View>
          ))
        : null} */}
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    width: '100px',
    objectFit: 'scale-down',
    marginRight: 15,
  },
  small: {
    fontFamily: 'Segoe UI',
    fontSize: 9,
  },
  page: {
    backgroundColor: '#ffffff',
    paddingBottom: 60,
    paddingLeft: 45,
    paddingRight: 45,
    paddingTop: 95,
  },
  pageHeaderWrapper: {
    position: 'absolute',
    top: 30,
    left: 45,
    right: 45,
  },
  pageHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
    paddingBottom: 10,
  },
  pageHeaderTitle: {
    fontFamily: 'Segoe UI',
    fontSize: 16,
    fontStyle: 'bold',
    paddingBottom: 5,
  },
  table: {
    display: 'flex',
    width: 'auto',
    paddingTop: '15px',
  },
  tableColDescription: {
    width: '48%',
  },
  tableColSite: {
    width: '29%',
    paddingRight: 5,
  },
  tableColPrice: {
    width: '8%',
    textAlign: 'right',
    paddingRight: 5,
  },
  tableColTotal: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 5,
  },
  tableRow: {
    borderBottomWidth: 1,
    borderColor: '#D2D4D1',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    fontFamily: 'Segoe UI',
    fontSize: 10,
    paddingBottom: '3px',
    paddingLeft: '5px',
    paddingTop: '3px',
  },
  tableRowHeader: {
    backgroundColor: '#D2D4D1',
    flexDirection: 'row',
    fontFamily: 'Segoe UI',
    justifyContent: 'space-between',
    fontSize: 10,
    paddingBottom: '3px',
    paddingLeft: '5px',
    paddingTop: '3px',
  },
});

export default CustomerPaymentTransactionDownloadTemplate;
