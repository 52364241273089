import React from 'react';
import { Stack } from '@fluentui/react';
import { Heading } from '../../components/typography';
import { stackStyles, stackTokens10, stackTokens15 } from '../../utils';
import TemplateAddExistingItem from './components/TemplateAddExistingItem';

const TemplatesManageItems = () => (
  <Stack tokens={stackTokens15}>
    <div style={{
      padding: '25px',
    }}
    >
      <h3>Beheer items</h3>
      <Stack tokens={stackTokens10} styles={stackStyles}>
        <TemplateAddExistingItem forModal={false} />
      </Stack>
    </div>

  </Stack>
);

export default TemplatesManageItems;
