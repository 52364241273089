import React from 'react';
import { createBrowserRouter, useRouteError } from 'react-router-dom';
import {
  ConstructionSites,
  ConstructionSiteDetail,
  ConstructionSiteView,
} from './modules/construction';
import { Dashboard, Setting } from './modules/general';
import { Template, Templates, TemplatesManageItems } from './modules/templates';
import {
  PurchaseOrderTemplate,
  PurchaseOrderTemplates,
} from './modules/purchase-order-templates';
import { PurchaseOrders } from './modules/purchase-orders';
import { Customers } from './modules/customers';
import Suppliers from './modules/supplier/Suppliers';
// import SupplierDetail from './modules/supplier/components/SupplierDetail';
import SuggestedValueCategories from './modules/suggested-value';
import Leads from './modules/leads/Leads';
import { Quotes } from './modules/quotes';
import LogItems from './modules/log-items/LogItems';
import Cars from './modules/cars/Cars';
import { Employees } from './modules/employees';
import Timesheets from './modules/timesheets/Timesheets';
import NewTimesheet from './modules/timesheets/components/NewTimesheet';
import Architects from './modules/architects/Architects';
import CustomerPaymentTransactions from './modules/payment-transaction/CustomerPaymentTransactions';
import CustomerInvoices from './modules/customer-invoice/CustomerInvoice';
import { WorkOrders } from './modules/work-orders';
import CustomerContractUpdates from './modules/customer-contract-update/CustomerContractUpdates';
import IncomingInvoices from './modules/incoming-invoice/IncomingInvoice';
import CostCenters from './modules/cost-center/CostCenters';
import PrivateRoute from './utils/PrivateRoute';
import { SalesReps } from './modules/sales-reps';
import TaxCodes from './modules/tax-code/TaxCodes';
import Plans from './modules/plans/Plans';
import { Teams } from './modules/teams';
import { Assets } from './modules/assets';
import MailTemplates from './modules/mail-templates/MailTemplates';
import { WetBreyne } from './modules/wet-breyne';
import { Suspects } from './modules/suspects';

function replaceWithBr(text: string) {
  return text.replace(/\n/g, '<br />');
}

function ErrorBoundary() {
  const error: any = useRouteError();
  console.error(error);

  return (
    <div>
      <h2>Er ging iets mis.</h2>
      <p>
        <strong>{error.message}</strong>
      </p>
      <p
        dangerouslySetInnerHTML={{ __html: replaceWithBr(error.stack) }}
        style={{ fontSize: '12px' }}
      />
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <PrivateRoute>
        <ConstructionSites />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/start',
    element: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/employees',
    element: (
      <PrivateRoute permission='nav:employees'>
        <Employees />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/sales-reps',
    element: (
      <PrivateRoute permission='nav:employees'>
        <SalesReps />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/suspects',
    element: (
      <PrivateRoute permission='nav:leads'>
        <Suspects />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },

  {
    path: '/construction-sites/',
    element: (
      <PrivateRoute permission='nav:constructionSites'>
        <ConstructionSites />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/construction-sites/add',
    element: (
      <PrivateRoute permission='nav:constructionSites'>
        <ConstructionSiteView />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/construction-sites/:id/:view',
    element: (
      <PrivateRoute permission='nav:constructionSites'>
        <ConstructionSiteDetail />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/construction-sites/:id',
    element: (
      <PrivateRoute permission='nav:constructionSites'>
        <ConstructionSites />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/templates',
    element: (
      <PrivateRoute permission='nav:templates'>
        <Templates />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/template/:id',
    element: (
      <PrivateRoute permission='nav:templates'>
        <Template />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/templates/manage-items',
    element: (
      <PrivateRoute permission='nav:templates'>
        <TemplatesManageItems />
      </PrivateRoute>
    ),
  },
  /* {
    path: '/purchase-order/:id',
    element: (
      <PrivateRoute permission='nav:purchaseOrders'>
        <PurchaseOrder />
      </PrivateRoute>
    ),
  }, */
  {
    path: '/purchase-order-template/:id',
    element: (
      <PrivateRoute permission='nav:purchaseOrderTemplates'>
        <PurchaseOrderTemplate />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/purchase-order-templates',
    element: (
      <PrivateRoute permission='nav:purchaseOrderTemplates'>
        <PurchaseOrderTemplates />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/purchase-orders',
    element: (
      <PrivateRoute permission='nav:purchaseOrders'>
        <PurchaseOrders />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/customers',
    element: (
      <PrivateRoute permission='nav:customers'>
        <Customers />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/customers/:id',
    element: (
      <PrivateRoute permission='nav:customers'>
        <Customers />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/leads',
    element: (
      <PrivateRoute permission='nav:leads'>
        <Leads />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/example-projects',
    element: (
      <PrivateRoute permission='nav:plans'>
        <Plans />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/example-projects/:id',
    element: (
      <PrivateRoute permission='nav:plans'>
        <Plans />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/cars',
    element: (
      <PrivateRoute permission='nav:cars'>
        <Cars />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/teams',
    element: (
      <PrivateRoute permission='nav:teams'>
        <Teams />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/quotes',
    element: (
      <PrivateRoute permission='nav:quoteReferences'>
        <Quotes />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/log-items',
    element: (
      <PrivateRoute permission='nav:logItems'>
        <LogItems />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/suppliers',
    element: (
      <PrivateRoute permission='nav:suppliers'>
        <Suppliers />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/assets',
    element: (
      <PrivateRoute permission='nav:assets'>
        <Assets />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/timesheets',
    element: (
      <PrivateRoute permission='nav:timeSheetEntries'>
        <Timesheets />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/new-timesheet',
    element: (
      <PrivateRoute permission='nav:timeSheetEntries'>
        <NewTimesheet />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/architects',
    element: (
      <PrivateRoute permission='nav:architects'>
        <Architects />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/payment-transactions',
    element: (
      <PrivateRoute permission='nav:customerPaymentTransactions'>
        <CustomerPaymentTransactions />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/payment-transactions/:id',
    element: (
      <PrivateRoute permission='nav:customerPaymentTransactions'>
        <CustomerPaymentTransactions />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/customer-invoices',
    element: (
      <PrivateRoute permission='nav:customerInvoices'>
        <CustomerInvoices />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/customer-invoices/:id',
    element: (
      <PrivateRoute permission='nav:customerInvoices'>
        <CustomerInvoices />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/incoming-invoices',
    element: (
      <PrivateRoute permission='nav:incomingInvoices'>
        <IncomingInvoices />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/incoming-invoices/:id',
    element: (
      <PrivateRoute permission='nav:incomingInvoices'>
        <IncomingInvoices />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/work-orders',
    element: (
      <PrivateRoute permission='nav:defectWorkOrders'>
        <WorkOrders />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/contract-updates',
    element: (
      <PrivateRoute permission='nav:customerContractUpdates'>
        <CustomerContractUpdates />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/mail-templates',
    element: (
      <PrivateRoute permission='nav:mailTemplates'>
        <MailTemplates />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/contract-updates/:id',
    element: (
      <PrivateRoute permission='nav:customerContractUpdates'>
        <CustomerContractUpdates />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/cost-centers',
    element: (
      <PrivateRoute permission='nav:costCenters'>
        <CostCenters />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/tax-codes',
    element: (
      <PrivateRoute permission='nav:taxCodes'>
        <TaxCodes />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/suggested-values',
    element: (
      <PrivateRoute permission='nav:suggestedValues'>
        <SuggestedValueCategories />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/settings',
    element: (
      <PrivateRoute permission='nav:suggestedValueCategories'>
        <Setting />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/wet-breyne',
    element: (
      <PrivateRoute permission='nav:constructionSites'>
        <WetBreyne />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/health',
    element: <div>All Good!</div>,
    errorElement: <ErrorBoundary />,
  },
]);

export default router;
