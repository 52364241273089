import React, {
  useCallback, useState, useRef, useEffect,
} from 'react';
import {
  PrimaryButton,
  Stack,
  ComboBox,
  IComboBox,
  IComboBoxOption,
  DefaultButton,
  Panel,
  PanelType,
  TextField,
  Toggle,
  Checkbox,
  Dropdown,
  IDropdownOption,
} from '@fluentui/react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { defaultProperty, IProperty } from '../../../utils/Property';
import { IConstructionSite } from '../../../utils/ConstructionSite';
import {
  modalContentStyles,
  stackTokens10,
  stackTokens5,
  convertPropertyListTypeToDropdownOptions,
  IPropertyListType,
  convertPropertyTypeToComboBoxOptions,
  IPropertyType,
  property_types,
  GET_PROPERTY_TYPES,
} from '../../../utils';
import { updateListValue, getNextWeight } from '../../construction/utils/index';
import { useAppDispatch } from '../../../redux/hooks';
import { throwError, SeverityLevel } from '../../../redux/error/errorSlice';
import { toastError } from '../../../utils/toast';
import { customPanelStyles } from '../../../theme';
import ModifyProperty from './ModifyProperty';

interface ExecutionListPanelProps {
  closePanel: () => void;
  dataTypes: IPropertyListType[];
  properties: IProperty[];
  selectedKey?: number;
  setConstructionSite: React.Dispatch<
    React.SetStateAction<IConstructionSite | undefined>
  >;
}

const getProperty = (properties: IProperty[], selectedKey: number) => {
  for (let i = 0; i < properties.length; i++) {
    const property = properties[i];
    if (property.id === selectedKey) return property;
  }

  return defaultProperty;
};

const ExecutionListPanel: React.FC<ExecutionListPanelProps> = ({
  closePanel,
  dataTypes,
  properties,
  selectedKey,
  setConstructionSite,
}) => {
  const dispatch = useAppDispatch();

  const [currentSelection, setCurrentSelection] = useState<
    IProperty | undefined
  >();
  const [addSelectionToExecutionList, setAddSelectionToExecutionList] = useState<boolean | undefined>();
  const [addSelectionToConstructionSheet, setAddSelectiontoConstructionSheet] = useState<boolean | undefined>();

  useEffect(() => {
    if (properties && selectedKey) {
      const existingProperty = getProperty(properties, selectedKey);
      if (existingProperty) setCurrentSelection(existingProperty);
      else {
        setCurrentSelection(defaultProperty);
      }
    } else {
      setCurrentSelection(defaultProperty);
    }
  }, [selectedKey]);

  useEffect(() => {
    if (!currentSelection) return; // only occurs in first render
    if (currentSelection && currentSelection.isNew && currentSelection.show_in_execution_list && currentSelection.show_in_construction_sheet) {
      setAddSelectionToExecutionList(true);
      setAddSelectiontoConstructionSheet(true);
      return;
    } if (currentSelection && currentSelection.isNew && currentSelection.show_in_execution_list) {
      setAddSelectionToExecutionList(true);
      return;
    } if (currentSelection && currentSelection.isNew && currentSelection.show_in_construction_sheet) {
      setAddSelectiontoConstructionSheet(true);
      return;
    }
    if (
      currentSelection
      && currentSelection.weight_for_execution_list > 0
      && addSelectionToExecutionList === undefined
    ) setAddSelectionToExecutionList(true);
    else if (addSelectionToExecutionList === undefined) setAddSelectionToExecutionList(false);

    if (
      currentSelection
      && currentSelection.weight_for_construction_sheet > 0
      && addSelectionToConstructionSheet === undefined
    ) setAddSelectiontoConstructionSheet(true);
    else if (addSelectionToConstructionSheet === undefined) setAddSelectiontoConstructionSheet(false);
  }, [currentSelection]);

  // When the TemplateItem is a parent, it loses its original type and therefor the ability to be edited.
  // So when it is a parent, the original type is passed along and all the controls are diverted to use this one.
  // 9 is id of string, should fetch id from inputP
  const comboboxPropertyType = useRef<IComboBox>(null);

  const {
    loading: loadingTypes,
    error: errorTypes,
    data: types,
  } = useQuery(GET_PROPERTY_TYPES, {
    variables: {
      orderBy: {
        name: 'asc',
      },
    },
    onError: (error) => {
      toastError(error.message);
    },
  });

  const onChangePropertyName = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    if (currentSelection) {
      const updatedSelection = _.cloneDeep(currentSelection);
      updatedSelection.name = newValue || '';
      updatedSelection.isDirty = true;
      setCurrentSelection(updatedSelection);
    }
  };

  const onChangeNotApplicable = (
    event: React.MouseEvent<HTMLElement>,
    checked?: boolean,
  ) => {
    if (currentSelection) {
      const updatedSelection = _.cloneDeep(currentSelection);
      updatedSelection.is_not_applicable = checked || false;
      updatedSelection.isDirty = true;

      setCurrentSelection(updatedSelection);
    }
  };

  const onChangeTappoints = (
    event: React.MouseEvent<HTMLElement>,
    checked?: boolean,
  ) => {
    if (currentSelection) {
      const updatedSelection = _.cloneDeep(currentSelection);
      updatedSelection.needs_water_points = checked || false;
      updatedSelection.isDirty = true;
      setCurrentSelection(updatedSelection);
    }
  };

  const savePanel = useCallback(() => {
    if (!currentSelection) {
      closePanel();
      return;
    }

    setConstructionSite((prevState) => {
      const newState = _.cloneDeep(prevState);
      if (!newState) return prevState;

      newState.update = true;
      const currentItem = currentSelection;

      // Update construction sheet weight
      if (addSelectionToConstructionSheet && currentItem.weight_for_construction_sheet <= 0) {
        currentItem.weight_for_construction_sheet = getNextWeight('construction', newState);
        currentItem.isDirty = true;
      } else if (!addSelectionToConstructionSheet && currentItem.weight_for_construction_sheet > 0) {
        currentItem.weight_for_construction_sheet = -1;
        currentItem.isDirty = true;
      }

      // Update execution list weight
      if (addSelectionToExecutionList && currentItem.weight_for_execution_list <= 0) {
        currentItem.weight_for_execution_list = getNextWeight('execution', newState);
        currentItem.isDirty = true;
      } else if (!addSelectionToExecutionList && currentItem.weight_for_execution_list > 0) {
        currentItem.weight_for_execution_list = -1;
        currentItem.isDirty = true;
      }

      // Update property in list if it exists
      const propertyIndex = newState.properties.findIndex(item => item.id === currentItem.id);
      if (propertyIndex >= 0) {
        newState.properties[propertyIndex] = currentItem;
      }

      return newState;
    });

    closePanel();
  }, [
    currentSelection,
    setConstructionSite,
    addSelectionToConstructionSheet,
    addSelectionToExecutionList,
    closePanel,
  ]);

  const getPropertyType = (id: number | string): IPropertyType | undefined => {
    const propertyTypes = types && types.findManyPropertyTypes ? types.findManyPropertyTypes : [];
    if (id) {
      return propertyTypes.filter((el: IPropertyType) => el.id === id)[0];
    }
    return undefined;
  };

  const updateType = (type: IPropertyType) => {
    if (currentSelection) {
      const updatedSelection = _.cloneDeep(currentSelection);
      updatedSelection.type = type;
      updatedSelection.isDirty = true;
      setCurrentSelection(updatedSelection);
    }
  };
  return (
    <Panel
      // isLightDismiss
      onDismiss={() => {
        closePanel();
      }}
      closeButtonAriaLabel='Close'
      headerText='Item toevoegen of verwijderen'
      type={PanelType.custom}
      customWidth='650px'
      isOpen
      styles={customPanelStyles}
    >
      <div>
        <div className={modalContentStyles.header}>
          <span>
            {currentSelection && currentSelection.id && currentSelection.id > 0
              ? 'Wijzig item'
              : 'Voeg item toe'}
          </span>
        </div>
        <div className={modalContentStyles.body}>
          <Stack
            horizontal
            tokens={stackTokens10}
            disableShrink
            style={{ marginBottom: '15px' }}
          >
            <Stack.Item>
              <Checkbox
                label='Uitvoeringslijst'
                checked={!!addSelectionToExecutionList}
                onChange={() => {
                  setAddSelectionToExecutionList(!addSelectionToExecutionList);
                }}
              />
            </Stack.Item>
            <Stack.Item>
              <Checkbox
                label='Werffiche'
                checked={!!addSelectionToConstructionSheet}
                onChange={() => {
                  setAddSelectiontoConstructionSheet(
                    !addSelectionToConstructionSheet,
                  );
                }}
              />
            </Stack.Item>
          </Stack>
          <Stack tokens={stackTokens10} style={{ marginBottom: '15px' }}>
            {currentSelection && dataTypes && dataTypes.length > 0 && (
              <Dropdown
                placeholder='Kies lijsttype(s)'
                multiSelect
                selectedKeys={
                  currentSelection && currentSelection.list_types
                    ? currentSelection.list_types
                      .filter((item) => !item.delete)
                      .map((item) => item.list_type.id)
                    : undefined
                }
                options={convertPropertyListTypeToDropdownOptions(
                  dataTypes.filter(
                    (item: IPropertyListType) => item.name.toLowerCase() !== 'tappunten',
                  ),
                )}
                onChange={(
                  event: React.FormEvent<HTMLDivElement>,
                  option?: IDropdownOption | undefined,
                  // index?: number | undefined,
                ) => {
                  updateListValue(
                    option,
                    currentSelection,
                    setCurrentSelection,
                  );
                }}
              />
            )}
          </Stack>
          <Toggle
            onText='n.v.t.'
            offText='n.v.t.'
            checked={currentSelection && currentSelection.is_not_applicable}
            onChange={onChangeNotApplicable}
          />
          <Stack style={{ marginTop: '15px' }}>
            <Toggle
              onText='Tappunten'
              offText='Geen tappunten'
              checked={currentSelection && currentSelection.needs_water_points}
              onChange={onChangeTappoints}
            />
          </Stack>
        </div>
        <div className={modalContentStyles.body}>
          <ComboBox
            componentRef={comboboxPropertyType}
            selectedKey={
              currentSelection && currentSelection.type
                ? currentSelection.type.id
                : undefined
            }
            label='Type'
            allowFreeform
            autoComplete='on'
            onChange={(
              event: React.FormEvent<IComboBox>,
              option?: IComboBoxOption,
              //   index?: number,
              //   value?: string,
            ) => {
              if (option) {
                const type = getPropertyType(option.key);
                if (type) updateType(type);
              }
            }}
            options={convertPropertyTypeToComboBoxOptions(
              types
                ? types.findManyPropertyTypes.filter(
                  (type: IPropertyType) => type.name !== property_types.PARENT,
                )
                : [],
            )}
            // required
          />
        </div>
        <div className={modalContentStyles.body}>
          <Stack>
            <Stack.Item style={{ marginBottom: 10 }}>
              <TextField
                name='name'
                label='Naam'
                value={currentSelection && currentSelection.name}
                onChange={onChangePropertyName}
                required
              />
            </Stack.Item>
          </Stack>

          {currentSelection && (
            <ModifyProperty
              item={currentSelection}
              setCurrentSelection={setCurrentSelection}
            />
          )}
        </div>
        <div className={modalContentStyles.footer}>
          <Stack horizontal reversed tokens={stackTokens5}>
            <DefaultButton
              onClick={() => {
                closePanel();
              }}
              text='Annuleren'
            />
            <PrimaryButton
              onClick={() => {
                savePanel();
              }}
              disabled={false}
              text={
                currentSelection
                && currentSelection.id
                && currentSelection.id > 0
                  ? 'Wijzigen'
                  : 'Toevoegen'
              }
            />
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default ExecutionListPanel;
