/* App.tsx */
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import jwt_decode from 'jwt-decode';
import { RouterProvider } from 'react-router-dom';
import {
  initializeIcons,
  Spinner,
  SpinnerSize,
  IStackStyles,
} from '@fluentui/react';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { useClientHeight } from './components/hooks';
import { Container, Section, Row } from './components/layout';
import TopBarWithNavigation from './components/parts/TopBarWithNavigation';
import Error from './components/parts/Error';
import Notification from './components/parts/Notification';
import router from './Router';
import './App.css';
import './styles.css';
import { useAppDispatch } from './redux/hooks';
import { setPermission } from './redux/permission/permissionSlice';
import 'react-toastify/dist/ReactToastify.css';

initializeIcons(/* optional base url */);

const wrapperStyle = {
  minHeight: '100vh',
  background:
    'linear-gradient(162deg, rgba(225,235,220,0.26514355742296913) 8%, rgba(255,255,255,0.4640231092436975) 32%, rgba(225,235,220,0.26514355742296913) 46%, rgba(247,251,244,1) 65%, rgba(255,255,255,1) 97%)',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  // display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'center',
  // position: 'relative' as const,
  // overflow: 'hidden' as const,
};

const gradientBackgroundStyle = {
  position: 'absolute' as const,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background:
    'linear-gradient(162deg, rgba(225,235,220,0.26514355742296913) 100%, rgba(255,255,255,1) 100%)',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  zIndex: 0,
};

const blurOverlayStyle = {
  position: 'absolute' as const,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backdropFilter: 'blur(20px)',
  background: 'rgba(255, 255, 255, 0.3)', // Light white overlay for a frosted glass effect
  zIndex: 1,
  borderRadius: '10px',
};

const contentWrapperStyle = {
  position: 'relative' as const,
  zIndex: 2,
  padding: '20px',
  borderRadius: '12px',
  background: 'rgba(255, 255, 255, 0.7)', // Adds a subtle frosted glass effect to the content wrapper itself
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
};

const loadingStackStyle: IStackStyles = {
  root: {
    minHeight: '100vh',
  },
};

const backEndUri = `${process.env.REACT_APP_BACKEND_URI}/graphql` || '';

function App() {
  const dispatch = useAppDispatch();
  const [headerRef, headerHeight] = useClientHeight();
  const [token, setToken] = useState<string | undefined>();
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

  // Setup link with data api
  const httpLink = createHttpLink({
    uri: backEndUri,
  });

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }));

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            findManyConstructionSites: {
              ...offsetLimitPagination(['filter', 'orderBy']),
              // Concatenate the incoming list items with
              // the existing list items.
              // eslint-disable-next-line default-param-last
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
            findManyPlans: {
              ...offsetLimitPagination(['filter', 'orderBy']),
              // Concatenate the incoming list items with
              // the existing list items.
              // eslint-disable-next-line default-param-last
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
            findManyLogItems: {
              ...offsetLimitPagination(['filter', 'orderBy']),
              // Concatenate the incoming list items with
              // the existing list items.
              // eslint-disable-next-line default-param-last
              merge(existing = [], incoming) {
                // MERGE DOES NOT REPLACE DUPLICATE __REF
                // console.log('incoming', incoming);
                // console.log('existing', incoming);
                // console.log('merged', [...existing, ...incoming]);
                return [...existing, ...incoming];
              },
            },
            findManyQuoteReferences: {
              ...offsetLimitPagination(['filter', 'orderBy']),
              // Concatenate the incoming list items with
              // the existing list items.
              // eslint-disable-next-line default-param-last
              merge(existing = [], incoming) {
                // MERGE DOES NOT REPLACE DUPLICATE __REF
                // console.log('incoming', incoming);
                // console.log('existing', incoming);
                // console.log('merged', [...existing, ...incoming]);
                return [...existing, ...incoming];
              },
            },
            findManyEmployees: {
              ...offsetLimitPagination(['filter', 'orderBy']),
              // Concatenate the incoming list items with
              // the existing list items.
              // eslint-disable-next-line default-param-last
              merge(existing = [], incoming) {
                // MERGE DOES NOT REPLACE DUPLICATE __REF
                // console.log('incoming', incoming);
                // console.log('existing', incoming);
                // console.log('merged', [...existing, ...incoming]);
                return [...existing, ...incoming];
              },
            },
            findManyLeads: {
              ...offsetLimitPagination(['filter', 'orderBy']),
              // Concatenate the incoming list items with
              // the existing list items.
              // eslint-disable-next-line default-param-last
              merge(existing = [], incoming) {
                // MERGE DOES NOT REPLACE DUPLICATE __REF
                // console.log('incoming', incoming);
                // console.log('existing', incoming);
                // console.log('merged', [...existing, ...incoming]);
                return [...existing, ...incoming];
              },
            },
            findManyAssets: {
              ...offsetLimitPagination(['filter', 'orderBy']),
              // Concatenate the incoming list items with
              // the existing list items.
              // eslint-disable-next-line default-param-last
              merge(existing = [], incoming) {
                // MERGE DOES NOT REPLACE DUPLICATE __REF
                // console.log('incoming', incoming);
                // console.log('existing', incoming);
                // console.log('merged', [...existing, ...incoming]);
                return [...existing, ...incoming];
              },
            },
            findManyCars: {
              ...offsetLimitPagination(['filter', 'orderBy']),
              // Concatenate the incoming list items with
              // the existing list items.
              // eslint-disable-next-line default-param-last
              merge(existing = [], incoming) {
                // MERGE DOES NOT REPLACE DUPLICATE __REF
                // console.log('incoming', incoming);
                // console.log('existing', incoming);
                // console.log('merged', [...existing, ...incoming]);
                return [...existing, ...incoming];
              },
            },
            findManyTeams: {
              ...offsetLimitPagination(['filter', 'orderBy']),
              // Concatenate the incoming list items with
              // the existing list items.
              // eslint-disable-next-line default-param-last
              merge(existing = [], incoming) {
                // MERGE DOES NOT REPLACE DUPLICATE __REF
                // console.log('incoming', incoming);
                // console.log('existing', incoming);
                // console.log('merged', [...existing, ...incoming]);
                return [...existing, ...incoming];
              },
            },
            findManyDefectWorkOrders: {
              ...offsetLimitPagination(['filter', 'orderBy']),
              // Concatenate the incoming list items with
              // the existing list items.
              // eslint-disable-next-line default-param-last
              merge(existing = [], incoming) {
                // MERGE DOES NOT REPLACE DUPLICATE __REF
                // console.log('incoming', incoming);
                // console.log('existing', incoming);
                // console.log('merged', [...existing, ...incoming]);
                return [...existing, ...incoming];
              },
            },
            findManySuppliers: {
              ...offsetLimitPagination(['filter', 'orderBy']),
              // Concatenate the incoming list items with
              // the existing list items.
              // eslint-disable-next-line default-param-last
              merge(existing = [], incoming) {
                // MERGE DOES NOT REPLACE DUPLICATE __REF
                // console.log('incoming', incoming);
                // console.log('existing', incoming);
                // console.log('merged', [...existing, ...incoming]);
                return [...existing, ...incoming];
              },
            },
            findManyCustomerPaymentTransactions: {
              ...offsetLimitPagination(['filter', 'orderBy']),
              // Concatenate the incoming list items with
              // the existing list items.
              // eslint-disable-next-line default-param-last
              merge(existing = [], incoming) {
                // MERGE DOES NOT REPLACE DUPLICATE __REF
                // console.log('incoming', incoming);
                // console.log('existing', incoming);
                // console.log('merged', [...existing, ...incoming]);
                return [...existing, ...incoming];
              },
            },
            findManyCustomerContractUpdates: {
              ...offsetLimitPagination(['filter', 'orderBy']),
              // Concatenate the incoming list items with
              // the existing list items.
              // eslint-disable-next-line default-param-last
              merge(existing = [], incoming) {
                // MERGE DOES NOT REPLACE DUPLICATE __REF
                // console.log('incoming', incoming);
                // console.log('existing', incoming);
                // console.log('merged', [...existing, ...incoming]);
                return [...existing, ...incoming];
              },
            },
            findManyCustomerInvoices: {
              ...offsetLimitPagination(['filter', 'orderBy']),
              // Concatenate the incoming list items with
              // the existing list items.
              // eslint-disable-next-line default-param-last
              merge(existing = [], incoming) {
                // MERGE DOES NOT REPLACE DUPLICATE __REF
                // console.log('incoming', incoming);
                // console.log('existing', incoming);
                // console.log('merged', [...existing, ...incoming]);
                return [...existing, ...incoming];
              },
            },
            findManyCostCenters: {
              ...offsetLimitPagination(['filter', 'orderBy']),
              // Concatenate the incoming list items with
              // the existing list items.
              // eslint-disable-next-line default-param-last
              merge(existing = [], incoming) {
                // MERGE DOES NOT REPLACE DUPLICATE __REF
                // console.log('incoming', incoming);
                // console.log('existing', incoming);
                // console.log('merged', [...existing, ...incoming]);
                return [...existing, ...incoming];
              },
            },
            findManyIncomingInvoices: {
              ...offsetLimitPagination(['filter', 'orderBy']),
              // Concatenate the incoming list items with
              // the existing list items.
              // eslint-disable-next-line default-param-last
              merge(existing = [], incoming) {
                // MERGE DOES NOT REPLACE DUPLICATE __REF
                // console.log('incoming', incoming);
                // console.log('existing', incoming);
                // console.log('merged', [...existing, ...incoming]);
                return [...existing, ...incoming];
              },
            },
            findManyTemplateItems: {
              ...offsetLimitPagination(['filter', 'orderBy']),
              // Concatenate the incoming list items with
              // the existing list items.
              // eslint-disable-next-line default-param-last
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
            findManyArchitects: {
              ...offsetLimitPagination(['filter', 'orderBy']),
              // Concatenate the incoming list items with
              // the existing list items.
              // eslint-disable-next-line default-param-last
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
            findManyTaxCodes: {
              ...offsetLimitPagination(['filter', 'orderBy']),
              // Concatenate the incoming list items with
              // the existing list items.
              // eslint-disable-next-line default-param-last
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
          },
        },
      },
    }),
    /* defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
    }, */
  });

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then(token => {
        setToken(token);
        const decoded: any = jwt_decode(token);
        if (decoded && typeof decoded === 'object' && decoded.permissions) {
          dispatch(setPermission(decoded.permissions));
        }
      });
    }
  }, [isAuthenticated]);

  return (
    <ApolloProvider client={client}>
      <div style={wrapperStyle}>
        {/* <div style={gradientBackgroundStyle} />
        <div style={blurOverlayStyle} /> */}
        <div>
          <TopBarWithNavigation
            headerRef={headerRef}
            headerHeight={headerHeight}
          />
          {!isLoading && (
            <Container wrapperStyle={{ top: headerHeight || 41 }}>
              <Section>
                <Row
                  id='content'
                  style={{
                    backgroundColor: 'white',
                    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                    borderRadius: '10px',
                  }}
                >
                  <RouterProvider router={router} />
                </Row>
              </Section>
            </Container>
          )}
          {isLoading && (
            <Container
              verticalAlign='center'
              styles={loadingStackStyle}
              wrapperStyle={{ minHeight: '100vh' }}
            >
              <Spinner size={SpinnerSize.large} />
            </Container>
          )}
          <Error />
          <Notification />
        </div>
      </div>
    </ApolloProvider>
  );
}

export default App;
