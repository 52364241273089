import { IDropdownOption } from '@fluentui/react';

export const documentExtensions: IDropdownOption[] = [
  { key: 'DOC', text: 'DOC' },
  { key: 'DOCX', text: 'DOCX' },
  { key: 'DWG', text: 'DWG' },
  { key: 'JPEG', text: 'JPEG' },
  { key: 'JPG', text: 'JPG' },
  { key: 'PDF', text: 'PDF' },
  { key: 'PNG', text: 'PNG' },
  { key: 'RVT', text: 'RVT' },
  { key: 'XLS', text: 'XLS' },
  { key: 'XLSX', text: 'XLSX' },
];

export const property_types = {
  DATE: 'date',
  DOCUMENT: 'document',
  NOTE: 'note',
  PARENT: 'parent',
  STRING: 'string',
  SUGGESTED_VALUE: 'suggested-value',
  MULTI_VALUE: 'multi-value',
};

export const saving_subjects = {
  CONSTRUCTION_SITE: 'construction_site',
  CONSTRUCTION_SHEET_REMARKS: 'construction_sheet_remarks',
  WATER_POINTS: 'water_points',
};

export const default_page_size = 40;

export const template_types = {
  CONSTRUCTION_SHEET: 'weight_for_construction_sheet',
  EXECUTION_LIST: 'weight_for_execution_list',
};
