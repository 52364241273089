import { gql } from '@apollo/client';
import { IConstructionSite } from './ConstructionSite';
import { ICostCenter } from './CostCenter';
import { ICustomer } from './Customer';
import { ICustomerInvoice } from './CustomerInvoice';
import { IEmployee } from './Employee';
import { ISupplier } from './Supplier';

export const GET_WORK_ORDERS = gql`
  query getWorkOrders(
    $filter: DefectWorkOrderFilterInput
    $take: Int
    $skip: Int
    $orderBy: DefectWorkOrderOrderByInput
  ) {
    findManyDefectWorkOrders(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      prisma_total
      assigned_employee_id
      assigned_supplier_id
      comments
      completion_date
      customer_id
      customer_invoice_id
      description
      document_date
      document_no
      id
      status
      guarantee
      cost_center {
        id
        name
      }
      supplier {
        id
        name
      }

      employee {
        id
        first_name
        last_name
      }

      construction_site {
        id
        name
      }
    }
  }
`;

export const GET_WORK_ORDER_BY_ID = gql`
  query getWorkOrderById($where: DefectWorkOrderWhereInput) {
    findOneDefectWorkOrder(where: $where) {
      assigned_employee_id
      assigned_supplier_id
      comments
      completion_date
      customer_id
      customer_invoice_id
      description
      document_date
      document_no
      id
      status
      guarantee
      cost_center {
        id
        name
      }
      supplier {
        id
        name
      }

      employee {
        id
        first_name
        last_name
      }

      defect_work_order_line_items {
        id
        comments
        description
      }

      construction_site {
        id
        name
        address
        city
        zip_code
        lead {
          id
          address1
          city1
          country
          email1
          email2
          first_name1
          first_name2
          last_name1
          last_name2
          mobile1
          mobile1_v2
          mobile2
          mobile2_v2
          phone1
          phone1_v2
          phone2
          phone2_v2
          title1
          title2
          zip_code1
          business
          vat_number
        }
      }
    }
  }
`;

export const ADD_WORK_ORDER = gql`
  mutation AddWorkOrder($data: DefectWorkOrderCreateInput!) {
    createDefectWorkOrder(data: $data) {
      id
    }
  }
`;

export const UPDATE_WORK_ORDER = gql`
  mutation UpdateWorkOrder($id: Int!, $data: DefectWorkOrderUpdateInput!) {
    updateDefectWorkOrder(id: $id, data: $data) {
      id
    }
  }
`;

export interface IWorkOrder {
  prisma_total?: number;
  assigned_employee_id: number;
  assigned_supplier_id: number;
  comments: string;
  completion_date: Date;
  customer_id: number;
  customer_invoice_id: number;
  description: string;
  document_date: Date;
  document_no: string;
  id: number;
  status: string;
  version: number;
  guarantee: boolean;

  customer?: ICustomer;
  construction_site: IConstructionSite;
  customer_invoice?: ICustomerInvoice;
  employee?: IEmployee;
  supplier?: ISupplier;
  cost_center?: ICostCenter;

  defect_work_order_line_items: IWorkOrderLineItem[];
}

export interface IWorkOrderLineItem {
  comments: string;
  description: string;
  hourly_rate: number;
  hours: number;
  id: number;
  internal_comments: string;
  line_item_type: string;
  percentage_vat: number;
  travel_cost: number;
  units: number;
  unit_price: number;
  version: number;
  warranty_code: string;
  cost_center: ICostCenter;
  defect_work_order: IWorkOrder;
}
