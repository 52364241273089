import { gql } from '@apollo/client';
import { ICustomerInvoice } from './CustomerInvoice';

export const GET_CUSTOMER_INVOICE_PAYMENTS = gql`
  query getCustomerInvoicePayments(
    $filter: CustomerInvoicePaymentFilterInput
    $take: Int
    $skip: Int
    $orderBy: CustomerInvoicePaymentOrderByInput
  ) {
    findManyCustomerInvoicePayments(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      prisma_total
      amount
      comments
      customer_invoice_id
      id
      payment_date
      version

      customer_invoice {
        id
      }
    }
  }
`;

export const GET_CUSTOMER_INVOICE_PAYMENT_BY_ID = gql`
  query getCustomerInvoicePaymentById(
    $where: CustomerInvoicePaymentWhereInput
  ) {
    findOneCustomerInvoicePayment(where: $where) {
      amount
      comments
      customer_invoice_id
      id
      payment_date
      version

      customer_invoice {
        id
      }
    }
  }
`;

export const ADD_CUSTOMER_INVOICE_PAYMENT = gql`
  mutation AddCustomerInvoicePayment(
    $data: CustomerInvoicePaymentCreateInput!
  ) {
    createCustomerInvoicePayment(data: $data) {
      id
    }
  }
`;

export const UPDATE_CUSTOMER_INVOICE_PAYMENT = gql`
  mutation UpdateCustomerInvoicePayment(
    $id: Int!
    $data: CustomerInvoicePaymentUpdateInput!
  ) {
    updateCustomerInvoicePayment(id: $id, data: $data) {
      id
    }
  }
`;

export const DELETE_CUSTOMER_INVOICE_PAYMENT = gql`
  mutation DeleteCustomerInvoicePayment(
    $where: CustomerInvoicePaymentWhereInput!
  ) {
    deleteCustomerInvoicePayment(where: $where) {
      id
    }
  }
`;

export interface ICustomerInvoicePayment {
  amount: number | string;
  comments: string;
  customer_invoice_id: number;
  id: number;
  payment_date: Date;
  version: number;
  prisma_total?: number;

  customer_invoice: ICustomerInvoice;
}
