import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ApolloError } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import { useParams } from 'react-router';
import { forEach } from 'async';
import { toast } from 'react-toastify';
import { BarLoader } from 'react-spinners';
import {
  GET_CONSTRUCTION_SHEET_REMARKS_BY_CONSTRUCTION_SITE,
  GET_CONSTRUCTION_SITE_BY_ID,
  GET_WATER_POINTS_BY_CONSTRUCTION_SITE,
  GET_PROPERTY_TYPES,
  GET_SUGGESTED_VALUE_CATEGORIES,
  GET_SALES_REPS,
  GET_EMPLOYEES,
  GET_SUPPLIERS,
  IConstructionSheetRemark,
  IConstructionSite,
  IProperty,
  UPDATE_CONSTRUCTION_SITE_WITH_PROPERTIES,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_TOAST_POSITION,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
} from '../../utils';
import { UPDATE_PROPERTY, ADD_PROPERTY } from '../../utils/Property';
import { GET_PROPERTY_LIST_TYPES } from '../../utils/PropertyListType';
import ExecutionListOverview from '../implementation-list/ExecutionList';
import WaterPointsListOverview from '../water-points-list/WaterPoints';
import ConstructionSheetOverview from '../site-overview/ConstructionSheet';
import useCtrl from '../../components/hooks/useCtrl';
import { toastError, toastSuccess } from '../../utils/toast';
import { getPropertyCreateData, getPropertyUpdateData } from './utils';
/*
const sortConstructionSiteProperties = (constructionSite: IConstructionSite, view?: string) => {
    let newConstructionsite = _.cloneDeep(constructionSite);
    let properties = newConstructionsite.properties;

    if(view === "implementation-list") {
        newConstructionsite.properties = properties.sort((a, b) => {
            return a.weight_for_construction_sheet - b.weight_for_construction_sheet
        })
    } else if(view === 'site-overview') {
        newConstructionsite.properties = properties.sort((a, b) => {
            return a.weight_for_execution_list - b.weight_for_execution_list

        })
    } else {
        console.log("no view!!!");
    }
    return newConstructionsite;
}
*/

type DocumentState = {
  constructionSite: {
    init: boolean; // flag if data is set for the first time
    isDirty?: boolean;
  };
  remarks: {
    init: boolean; // flag if data is set for the first time
    isDirty?: boolean;
  };
  waterPoints: {
    init: boolean; // flag if data is set for the first time
    isDirty?: boolean;
  };
};

const ConstructionSiteDetail = () => {
  // Hooks
  const [documentState, setDocumentState] = useState<DocumentState>({
    constructionSite: {
      init: false,
      isDirty: false,
    },
    remarks: {
      init: false,
      isDirty: false,
    },
    waterPoints: {
      init: false,
      isDirty: false,
    },
  });

  // @ts-ignore
  const { id: constructionSiteId, view } = useParams();

  const [currentView, setCurrentView] = useState<string>(
    view || 'implementation-list',
  );

  const [constructionSite, setConstructionSite] = useState<IConstructionSite>();
  const [constructionSheetRemarks, setConstructionSheetRemarks] = useState<
    any[]
  >([]);
  const [waterPoints, setWaterPoints] = useState<IProperty[]>();

  const handleRemarkChange = (newRemarkText: any, propertyName: any) => {
    setConstructionSheetRemarks(prevRemarks => {
      const remarkIndex = prevRemarks.findIndex(
        remark => remark.property_list_type.name === propertyName,
      );

      if (remarkIndex > -1) {
        // Update existing remark
        return prevRemarks.map((remark, index) =>
          (index === remarkIndex
            ? { ...remark, remarks: newRemarkText }
            : remark));
      }
      // Add new remark
      const newRemark = {
        label: 'New Label', // You might want to change this
        remarks: newRemarkText,
        property_list_type: {
          name: propertyName,
        },
      };
      return [...prevRemarks, newRemark];
    });
  };

  const [filter, setFilter] = useState('');

  const filterConstructionSite = useCallback(() => {
    if (filter && filter.length > 0 && constructionSite) {
      const newConstructionSite = _.cloneDeep(constructionSite);
      newConstructionSite.properties = newConstructionSite.properties.filter(
        item =>
          item.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()),
      );

      return newConstructionSite;
    }
    return constructionSite;
  }, [filter, constructionSite]);

  const { isAuthenticated } = useAuth0();

  // Mutations
  const [updateConstructionSite] = useMutation(
    UPDATE_CONSTRUCTION_SITE_WITH_PROPERTIES,
    {
      onCompleted: d => {
        if (d && d.updateConstructionSite) {
          setConstructionSite({ ...d.updateConstructionSite, update: false });
          refetchWaterPoints();
          refetchRemarks();
        }
      },
      onError: error => {
        toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
      },
    },
  );
  const [updateProperty] = useMutation(UPDATE_PROPERTY, {
    onError: error => {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    },
  });
  // Queries
  const [addProperty] = useMutation(ADD_PROPERTY, {
    onError: error => {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    },
  });

  const { loading, error, refetch } = useQuery(GET_CONSTRUCTION_SITE_BY_ID, {
    variables: {
      where: {
        id: parseInt(constructionSiteId || '-1', 10),
      },
      whereProps: {
        parent_id: null,
      },
    },
    skip: constructionSiteId === undefined,
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      setConstructionSite(x.findOneConstructionSite);
    },
    onError: error => {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    },
  });

  const {
    loading: loadingTypes,
    error: errorTypes,
    data: dataTypes,
  } = useQuery(GET_PROPERTY_LIST_TYPES, {
    variables: {
      orderBy: {
        name: 'asc',
      },
    },
    onError: error => {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    },
  });

  const {
    data: dataSuggestedValueCategories,
    loading: loadingSuggestedValueCategories,
  } = useQuery(GET_SUGGESTED_VALUE_CATEGORIES, {
    variables: {
      orderBy: {
        category_description: 'asc',
      },
    },
    onError: error => {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    },
  });
  const { data: dataPropertyTypes, loading: loadingPropertyTypes } = useQuery(
    GET_PROPERTY_TYPES,
    {
      variables: {
        orderBy: {
          name: 'asc',
        },
      },
      onError: error => {
        toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
      },
    },
  );
  const {
    loading: loadingWaterPoints,
    error: errorWaterPoints,
    refetch: refetchWaterPoints,
  } = useQuery(GET_WATER_POINTS_BY_CONSTRUCTION_SITE, {
    variables: {
      filter: {
        OR: [
          {
            construction_site_id: parseInt(constructionSiteId || '-1', 10),
            needs_water_points: true,
          },
          {
            needs_water_points: true,
            parent: {
              construction_site_id: parseInt(constructionSiteId || '-1', 10),
            },
          },
        ],
        NOT: [
          {
            type: {
              id: 14,
            },
          },
        ],
      },
    },
    skip: constructionSiteId === undefined,
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      setWaterPoints(x.findManyProperties);
    },
    onError: error => {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    },
  });
  const {
    loading: loadingRemarks,
    error: errorRemarks,
    refetch: refetchRemarks,
  } = useQuery(GET_CONSTRUCTION_SHEET_REMARKS_BY_CONSTRUCTION_SITE, {
    variables: {
      filter: {
        construction_site_id: parseInt(constructionSiteId || '-1', 10),
      },
    },
    fetchPolicy: 'no-cache',
    skip: constructionSiteId === undefined,
    onCompleted: (x: any) => {
      if (
        _.isEqual(constructionSheetRemarks, x.findManyConstructionSheetRemarks)
      ) {
        setDocumentState(prevState => ({
          ...prevState,
          remarks: { init: true, isDirty: false },
        }));
      }
      setConstructionSheetRemarks(x.findManyConstructionSheetRemarks);
    },
    onError: error => {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    },
  });
  const {
    loading: loadingSalesRep,
    error: errorSalesRep,
    data: dataSalesRep,
  } = useQuery(GET_SALES_REPS, {
    variables: {
      filter: {
        active: true,
      },
      orderBy: {
        last_name: 'asc',
      },
    },
    onError: error => {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    },
  });
  const {
    loading: loadingEmployees,
    error: errorEmployees,
    data: dataEmployees,
  } = useQuery(GET_EMPLOYEES, {
    variables: {
      filter: {
        AND: [
          // { active: true },
          {
            OR: [{ construction_site_manager: true }, { sales_rep: true }],
          },
        ],
      },
      orderBy: {
        last_name: 'asc',
      },
    },
  });

  const {
    loading: loadingSuppliers,
    error: errorSuppliers,
    data: dataSuppliers,
  } = useQuery(GET_SUPPLIERS, {
    variables: {
      orderBy: {
        name: 'asc',
      },
    },
    onError: error => {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    },
  });
  /*
    const saveConstructionSheetRemarks = async () => {
        return new Promise((resolve, reject) => {
            if (constructionSheetRemarks && constructionSite) {
                const updatedRemarks = constructionSheetRemarks.map(
                    (remark: IConstructionSheetRemark) => {
                        return {
                            data: {
                                remarks: remark.remarks,
                            },
                            where: {
                                id: remark.id,
                            },
                        };
                    },
                );

                updateConstructionSite({
                    variables: {
                        id: constructionSite.id,
                        data: {
                            construction_sheet_remarks: {
                                update: updatedRemarks,
                            },
                        },
                    },
                })
                    .then((result) => {
                        resolve(result);
                    })
                    .catch((error: any) => {
                        reject(error);
                    });
            }
        });
    };
*/

  const saveConstructionSite = useCallback(async () => {
    try {
      if (constructionSite) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const newProperties = constructionSite.properties
              .filter(
                (property: IProperty) => property.isNew && !property.delete,
              )
              .map((property: IProperty) => {
                let children;

                if (property.children && property.children.length > 0) {
                  children = property.children.map((child: IProperty) => ({
                    ...getPropertyCreateData(child),
                    construction_site: {
                      connect: {
                        id: constructionSite.id,
                      },
                    },
                  }));
                }

                let parent = {};

                parent = {
                  ...parent,
                  ...getPropertyCreateData(property),
                };

                if (children && children.length > 0) {
                  parent = {
                    ...parent,
                    children: {
                      create: children,
                    },
                  };
                }
                return parent;
              });

            const updatedProperties = constructionSite.properties
              .filter(
                (property: IProperty) =>
                  (!property.isNew && !property.delete) &&
                  (property.isDirty || (property.children && property.children.some(child => child.isDirty))),
              )
              .map((property: IProperty) => {
                let updateData = {};

                updateData = {
                  ...updateData,
                  ...getPropertyUpdateData(property),
                };

                return {
                  data: updateData,
                  where: {
                    id: property.id,
                  },
                };
              });

            const deleteProperties = constructionSite.properties
              .filter(
                (property: IProperty) => !property.isNew && property.delete,
              )
              .map((property: IProperty) => ({ id: property.id }));

            const updatedRemarks = constructionSheetRemarks
              ? constructionSheetRemarks
                  .filter((remark: IConstructionSheetRemark) => remark.id)
                  .map((remark: IConstructionSheetRemark) => ({
                    data: {
                      remarks: remark.remarks,
                    },
                    where: {
                      id: remark.id,
                    },
                  }))
              : undefined;

            const newRemarks = constructionSheetRemarks
              ? constructionSheetRemarks
                  .filter((remark: IConstructionSheetRemark) => !remark.id)
                  .map((remark: IConstructionSheetRemark) => ({
                    remarks: remark.remarks,
                    property_list_type: {
                      connect: {
                        id: dataTypes.findManyPropertyListTypes.find(
                          (obj: any) =>
                            obj.name === remark.property_list_type.name,
                        ).id,
                      },
                    },
                    is_default: false,
                    label: remark.label,
                  }))
              : undefined;

            // console.log('===========================================');
            // console.log('create', newProperties);
            // console.log('update', updatedProperties);
            // console.log('delete', deleteProperties);
            // console.log('===========================================');

            updateConstructionSite({
              variables: {
                id: constructionSite.id,
                data: {
                  execution_list_internal_remarks:
                    constructionSite?.execution_list_internal_remarks,
                  properties: {
                    create: newProperties,
                    update: updatedProperties,
                    delete: deleteProperties,
                  },
                  construction_sheet_remarks: {
                    update: updatedRemarks,
                    create:
                      newRemarks && newRemarks.length > 0
                        ? newRemarks
                        : undefined,
                  },
                },
                whereProps: {
                  parent_id: null,
                },
                orderByProps: {
                  weight_for_execution_list: 'asc',
                },
              },
            })
              .then(resolve)
              .catch(reject);
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;

        // Optionally handle other post-success actions here, like saving water points
        await saveWaterPoints();

        toastSuccess('De wijzigingen zijn opgeslagen');
      }
    } catch (error) {
      if (typeof error === 'string') {
        toastError(error);
      } else if (error instanceof Error || error instanceof ApolloError) {
        toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
      }
    }
  }, [constructionSite, constructionSheetRemarks, waterPoints]);

  const saveWaterPoints = () =>
    new Promise((resolve, reject) => {
      (async () => {
        try {
          if (waterPoints) {
            const newWaterPoints = waterPoints
              .filter((waterPoint: IProperty) => waterPoint.isNew)
              .map((waterPoint: IProperty) => {
                if (constructionSite) {
                  return {
                    data: {
                      name: waterPoint.name,
                      needs_water_points: waterPoint.needs_water_points,
                      tap_water_points: waterPoint.tap_water_points,
                      rain_water_points: waterPoint.rain_water_points,
                      construction_site: {
                        connect: {
                          id: constructionSite.id,
                        },
                      },
                      type: {
                        connect: {
                          id: 9, // text
                        },
                      },
                    },
                  };
                }
              });

            const updatedWaterPoints = waterPoints
              .filter(
                (waterPoint: IProperty) =>
                  waterPoint.isDirty && !waterPoint.isNew,
              )
              .map((waterPoint: IProperty) => ({
                data: {
                  tap_water_points: waterPoint.tap_water_points,
                  rain_water_points: waterPoint.rain_water_points,
                  name: waterPoint.name,
                },
                id: waterPoint.id,
              }));

            await forEach(updatedWaterPoints, (waterPoint, cb) => {
              updateProperty({ variables: waterPoint }).then(data => {
                cb();
              });
            });

            await forEach(newWaterPoints, (waterPoint, cb) => {
              addProperty({ variables: waterPoint }).then(data => {
                cb();
              });
            });
          }
          resolve(true);
        } catch (error) {
          reject(error);
        }
      })();
    });

  const moveExecutionListItem = (a: IProperty, b: IProperty) => {
    // DONE , fixed execution mist order move
    const newConstructionSite = _.cloneDeep(constructionSite);
    if (newConstructionSite) {
      const properties = newConstructionSite.properties;

      // Sort properties by weight
      properties.sort(
        (x, y) => x.weight_for_execution_list - y.weight_for_execution_list,
      );

      const newProperties: IProperty[] = [];
      let propertyInserted = false;

      for (let i = 0; i < properties.length; i++) {
        const currentProperty = properties[i];

        if (currentProperty.id === b.id && !propertyInserted) {
          // Insert the dragged property before the target property
          a.weight_for_execution_list =
            currentProperty.weight_for_execution_list;
          a.isDirty = true;
          newProperties.push(a);

          // Shift the target property down
          currentProperty.weight_for_execution_list += 1;
          currentProperty.isDirty = true;
          newProperties.push(currentProperty);

          propertyInserted = true;
        } else {
          // Adjust weights if the property has been inserted
          if (propertyInserted && currentProperty.id !== a.id) {
            currentProperty.weight_for_execution_list += 1;
            currentProperty.isDirty = true;
          }

          // Only push the currentProperty if it's not the dragged one
          if (currentProperty.id !== a.id) {
            newProperties.push(currentProperty);
          }
        }
      }

      if (!propertyInserted) {
        // If 'a' was not inserted (e.g., 'b' was at the end of the list)
        a.weight_for_execution_list = newProperties.length;
        a.isDirty = true;
        newProperties.push(a);
      }

      newConstructionSite.properties = newProperties;
      setConstructionSite(newConstructionSite);
    }
  };

  const moveConstructionSheetItem = (a: IProperty, b: IProperty) => {
    const newConstructionSite = _.cloneDeep(constructionSite);
    if (newConstructionSite) {
      const properties = newConstructionSite.properties;
      properties.sort(
        (a, b) =>
          a.weight_for_construction_sheet - b.weight_for_construction_sheet,
      );

      const newProperties: IProperty[] = [];
      let propertyInserted = false;
      let previousWeight = -1;
      for (let i = 0; i < properties.length; i++) {
        const currentProperty = properties[i];

        if (currentProperty.id === a.id) {
          // skip if current property is the one that needs to be moved
        } else if (currentProperty.id !== b.id && !propertyInserted) {
          newProperties.push(currentProperty);
          previousWeight = currentProperty.weight_for_construction_sheet;
        } else if (currentProperty.id !== b.id && propertyInserted) {
          currentProperty.weight_for_construction_sheet = previousWeight + 1;
          currentProperty.isDirty = true;
          previousWeight = currentProperty.weight_for_construction_sheet;
          newProperties.push(currentProperty);
        } else if (currentProperty.id === b.id) {
          // current property should shift down
          const newProperty = a;
          newProperty.weight_for_construction_sheet =
            currentProperty.weight_for_construction_sheet;
          currentProperty.weight_for_construction_sheet =
            newProperty.weight_for_construction_sheet + 1;
          previousWeight = currentProperty.weight_for_construction_sheet;
          propertyInserted = true;
          newProperties.push(currentProperty, newProperty);
        }
      }

      newConstructionSite.properties = newProperties;
      setConstructionSite(newConstructionSite);
    }
  };

  useCtrl({
    ctrlS: () => {
      saveConstructionSite();
    },
  });

  useEffect(() => {
    if (view) setCurrentView(view);
  }, [view]);

  useEffect(() => {
    if (constructionSite) {
      if (documentState && documentState.constructionSite.init) {
        setDocumentState(prevState => ({
          ...prevState,
          constructionSite: { init: true, isDirty: true },
        }));
      } else if (documentState) {
        setDocumentState(prevState => ({
          ...prevState,
          constructionSite: { init: true, isDirty: false },
        }));
      }

      if (constructionSite.update) {
        saveConstructionSite();
      }
    }
  }, [constructionSite]);

  useEffect(() => {
    if (constructionSheetRemarks) {
      if (documentState && documentState.remarks.init) {
        setDocumentState(prevState => ({
          ...prevState,
          remarks: { init: true, isDirty: true },
        }));
      } else if (documentState) {
        setDocumentState(prevState => ({
          ...prevState,
          remarks: { init: true, isDirty: false },
        }));
      }
    }
  }, [constructionSheetRemarks]);

  useEffect(() => {
    if (waterPoints) {
      if (documentState && documentState.waterPoints.init) {
        setDocumentState(prevState => ({
          ...prevState,
          waterPoints: { init: true, isDirty: true },
        }));
      } else if (documentState) {
        setDocumentState(prevState => ({
          ...prevState,
          waterPoints: { init: true, isDirty: false },
        }));
      }
    }
  }, [waterPoints]);
  /*
  useEffect(() => {
    if (autosave) {
      if (
        documentState.constructionSite.isDirty ||
        documentState.remarks.isDirty ||
        documentState.waterPoints.isDirty
      ) {
        console.log('autosaved');
        setDocumentState({
          constructionSite: {
            init: false,
            isDirty: false,
          },
          remarks: {
            init: false,
            isDirty: false,
          },
          waterPoints: {
            init: false,
            isDirty: false,
          },
        });
        saveConstructionSite();
      }
    }
  }, [documentState]);
*/
  if (!isAuthenticated) return <p>Verboden</p>;

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '580px',
        }}
      >
        <BarLoader color='#71bf43' loading={loading} width={200} />
      </div>
    );
  }
  if (error) return <p>Fout :(</p>;

  const filteredConstructionSite = filterConstructionSite();

  if (!filteredConstructionSite) return <p>no construction site found</p>;

  return (
    <>
      {currentView === 'site-overview' && (
        <ConstructionSheetOverview
          constructionSite={filteredConstructionSite}
          employees={dataEmployees ? dataEmployees.findManyEmployees : []}
          dataTypes={dataTypes ? dataTypes.findManyPropertyListTypes : []}
          suggestedValueCategories={
            dataSuggestedValueCategories
              ? dataSuggestedValueCategories.findManySuggestedValueCategories
              : []
          }
          salesReps={dataSalesRep ? dataSalesRep.findManySalesReps : []}
          suppliers={dataSuppliers ? dataSuppliers.findManySuppliers : []}
          setConstructionSite={setConstructionSite}
          saveConstructionSite={saveConstructionSite}
          setCurrentView={value => {
            setCurrentView(value);
          }}
          setFilter={setFilter}
          filter={filter}
          moveItem={moveConstructionSheetItem}
          documentEdited={
            documentState.constructionSite.isDirty ||
            documentState.remarks.isDirty ||
            documentState.waterPoints.isDirty
          }
          refetch={refetch}
        />
      )}

      {currentView === 'implementation-list' && (
        <ExecutionListOverview
          constructionSite={filteredConstructionSite}
          constructionSheetRemarks={constructionSheetRemarks}
          dataTypes={dataTypes ? dataTypes.findManyPropertyListTypes : []}
          dataRemarks={constructionSheetRemarks || []}
          dataPropertyTypes={
              dataPropertyTypes ? dataPropertyTypes.findManyPropertyTypes : []
            }
          dataSuggestedValueCategories={
              dataSuggestedValueCategories
                ? dataSuggestedValueCategories.findManySuggestedValueCategories
                : []
            }
          status=''
          setConstructionSheetRemarks={setConstructionSheetRemarks}
          handleRemarkChange={handleRemarkChange}
          setConstructionSite={setConstructionSite}
          setWaterPoints={setWaterPoints}
          saveConstructionSite={saveConstructionSite}
          waterPoints={waterPoints || []}
          setCurrentView={value => {
              setCurrentView(value);
            }}
          setFilter={setFilter}
          filter={filter}
          moveItem={moveExecutionListItem}
          documentEdited={
              documentState.constructionSite.isDirty ||
              documentState.remarks.isDirty ||
              documentState.waterPoints.isDirty
            }
        />
      )}

      {currentView === 'water-points' && (
        <WaterPointsListOverview
          constructionSite={constructionSite!}
          saveConstructionSite={saveConstructionSite}
          waterPoints={
            constructionSite?.water_points ? constructionSite.water_points : []
          }
          setCurrentView={value => {
            setCurrentView(value);
          }}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default ConstructionSiteDetail;
