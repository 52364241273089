import { gql } from '@apollo/client';
import { IComboBoxOption } from '@fluentui/react';
import {
  IConstructionSite,
  IConstructionSiteCreateUpdate,
} from './ConstructionSite';
import { ICustomer } from './Customer';
import { ICustomerContractUpdateLineItem } from './CustomerContractUpdateLineItem';
import {
  ICustomerInvoice,
  ICustomerInvoiceContractUpdate,
} from './CustomerInvoice';
import { ISalesRep } from './SalesRep';

export const GET_CUSTOMER_CONTRACT_UPDATES = gql`
  query getCustomerContractUpdates(
    $filter: CustomerContractUpdateFilterInput
    $take: Int
    $skip: Int
    $orderBy: CustomerContractUpdateOrderByInput
  ) {
    findManyCustomerContractUpdates(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      prisma_total
      customer_invoice_id
      document_date
      document_no
      intro
      outro
      status
      summary
      split_payment
      split_payment_complete
      split_payment_json
      customer_invoice_split_customer_contract_updates {
        id
        customer_invoice_id
        percentage
        description
        customer_invoice {
          id
          credit_note
          customer_id
          intro
          invoice_date
          invoice_due_date
          invoice_no
          invoice_paid
          outro
          version
          customer_invoice_line_items {
            comments
            customer_invoice_id
            description
            id
            percentage_vat
            show_only_total
            units
            unit_price
            tax_code {
              id
              label
              rate
              description
            }
          }
          customer_invoice_payments {
            amount
            comments
            customer_invoice_id
            id
            payment_date
          }
        }
      }
      customer_contract_update_line_items {
        id
        description
        units
        unit_price
        percentage_vat
        show_only_total
        tax_code {
          id
          rate
          label
          description
        }
      }
      construction_site {
        id
        name
        address
        city
        zip_code
        comments
        contract_amount
        architect_id
        lead_id
        lead {
          id
          address1
          city1
          country
          email1
          email2
          first_name1
          first_name2
          last_name1
          last_name2
          mobile1
          mobile1_v2
          mobile2
          mobile2_v2
          phone1
          phone1_v2
          phone2
          phone2_v2
          title1
          title2
          zip_code1
          preferred_way_of_contact
          status
        }
        epb_reporter_id
        manager_id
        project_manager_id
        sales_rep_id
        safety_coordinator_id
        creation_date
        project_manager {
          id
          first_name
          last_name
          mobile
        }
        invoice_customers {
          id
          construction_site_id
          lead_id
          lead {
            id
            address1
            city1
            country
            email1
            email2
            first_name1
            first_name2
            last_name1
            last_name2
            mobile1
            mobile1_v2
            mobile2
            mobile2_v2
            phone1
            phone1_v2
            phone2
            phone2_v2
            title1
            title2
            zip_code1
            preferred_way_of_contact
            status
          }
          percentage
        }
        status {
          id
          label
        }
      }
      customer_invoice_contract_updates {
        contract_update {
          id
          document_no
          document_date
          summary
          split_payment
          split_payment_complete
          split_payment_json
          customer_contract_update_line_items {
            id
            description
            units
            unit_price
            percentage_vat
            show_only_total
            tax_code {
              id
              rate
              label
              description
            }
          }
        }
      }
    }
  }
`;

export const GET_CUSTOMER_CONTRACT_UPDATE_BY_ID = gql`
  query getCustomerContractUpdateById(
    $where: CustomerContractUpdateWhereInput
  ) {
    findOneCustomerContractUpdate(where: $where) {
      id
      customer_invoice_id
      document_date
      document_no
      intro
      outro
      status
      summary
      split_payment
      split_payment_complete
      split_payment_json
      customer_invoice_split_customer_contract_updates {
        id
        customer_invoice_id
        percentage
        description
        customer_invoice {
          id
          credit_note
          customer_id
          intro
          invoice_date
          invoice_due_date
          invoice_no
          invoice_paid
          outro
          version
          customer_invoice_line_items {
            comments
            customer_invoice_id
            description
            id
            percentage_vat
            show_only_total
            units
            unit_price
            tax_code {
              id
              label
              rate
              description
            }
          }
          customer_invoice_payments {
            amount
            comments
            customer_invoice_id
            id
            payment_date
          }
        }
      }

      construction_site {
        id
        name
        address
        city
        zip_code
        comments
        contract_amount
        architect_id
        lead_id
        lead {
          id
          address1
          city1
          country
          email1
          email2
          first_name1
          first_name2
          last_name1
          last_name2
          mobile1
          mobile1_v2
          mobile2
          mobile2_v2
          phone1
          phone1_v2
          phone2
          phone2_v2
          title1
          title2
          zip_code1
          preferred_way_of_contact
          status
        }
        epb_reporter_id
        manager_id
        project_manager_id
        sales_rep_id
        safety_coordinator_id
        creation_date
        project_manager {
          id
          first_name
          last_name
          mobile
        }
        invoice_customers {
          id
          construction_site_id
          lead_id
          lead {
            id
            address1
            city1
            country
            email1
            email2
            first_name1
            first_name2
            last_name1
            last_name2
            mobile1
            mobile1_v2
            mobile2
            mobile2_v2
            phone1
            phone1_v2
            phone2
            phone2_v2
            title1
            title2
            zip_code1
            preferred_way_of_contact
            status
          }
          percentage
        }
        status {
          id
          label
        }
      }

      customer_contract_update_line_items {
        id
        description
        units
        unit_price
        percentage_vat
        show_only_total
        tax_code {
          id
          rate
          label
          description
        }
      }
      customer_invoice_contract_updates {
        customer_invoice {
          id
          credit_note
          customer_id
          intro
          invoice_date
          invoice_due_date
          invoice_no
          invoice_paid
          outro
          version
          customer_invoice_line_items {
            comments
            customer_invoice_id
            description
            id
            percentage_vat
            show_only_total
            units
            unit_price
            tax_code {
              id
              label
              rate
              description
            }
          }
          customer_invoice_payments {
            amount
            comments
            customer_invoice_id
            id
            payment_date
          }
        }
      }
    }
  }
`;

export const ADD_CUSTOMER_CONTRACT_UPDATE = gql`
  mutation addCustomerContractUpdate(
    $data: CustomerContractUpdateCreateInput!
  ) {
    createCustomerContractUpdate(data: $data) {
      id
      customer_invoice_id
      document_date
      document_no
      intro
      outro
      status
      summary
      split_payment
      split_payment_complete
      split_payment_json
      construction_site {
        id
        name
      }
    }
  }
`;

export const UPDATE_CUSTOMER_CONTRACT_UPDATE = gql`
  mutation updateCustomerContractUpdate(
    $id: Int!
    $data: CustomerContractUpdateUpdateInput
  ) {
    updateCustomerContractUpdate(id: $id, data: $data) {
      id
    }
  }
`;

export const SPLIT_CUSTOMER_CONTRACT_UPDATE = gql`
  mutation splitCustomerContractUpdate($id: Int!) {
    splitCustomerContractUpdate(id: $id) {
      id
    }
  }
`;

export const DELETE_CUSTOMER_CONTRACT_UPDATE = gql`
  mutation deleteCustomerContractUpdate(
    $where: CustomerContractUpdateWhereInput
  ) {
    deleteCustomerContractUpdate(where: $where) {
      id
    }
  }
`;

export interface ICustomerContractUpdate {
  customer_id?: number;
  prisma_total?: number;
  customer_invoice_id?: number;
  document_date?: Date;
  document_no?: string;
  id?: number;
  intro?: string;
  outro?: string;
  status?: string;
  summary?: string;
  version?: number;

  connect?: boolean; // only frontend app
  disconnect?: boolean; // only frontend app
  split_payment?: boolean;
  split_payment_complete?: boolean;
  split_payment_json?: string;

  construction_site?: IConstructionSite | IConstructionSiteCreateUpdate;
  // customer: ICustomer;
  customer_invoice?: ICustomerInvoice;
  customer_contract_update_line_items?: ICustomerContractUpdateLineItem[];
  customer_invoice_contract_updates?: ICustomerInvoiceContractUpdate[];
  customer_invoice_split_customer_contract_update?: ICustomerInvoiceSplitCustomerContractUpdate[];
}

export interface ICustomerInvoiceSplitCustomerContractUpdate {
  id?: number;
  customer_invoice_id?: number;
  customer_invoice?: ICustomerInvoice;
  customer_contract_update_id?: number;
  customer_contract_update?: ICustomerContractUpdate;
  percentage?: number;
  description?: string;
}
