import React, { useCallback, useState, useEffect } from 'react';
import {
  PrimaryButton,
  Stack,
  DefaultButton,
  Panel,
  PanelType,
  TextField,
} from '@fluentui/react';
import _ from 'lodash';
import { defaultProperty, IProperty } from '../../../utils/Property';
import { modalContentStyles, stackTokens5 } from '../../../utils';
import { customPanelStyles } from '../../../theme';

interface ExecutionListPanelProps {
  closePanel: () => void;
  properties: IProperty[];
  selectedKey?: number;
  setWaterPoints: React.Dispatch<React.SetStateAction<IProperty[] | undefined>>;
}

const getProperty = (properties: IProperty[], selectedKey: number) => {
  for (let i = 0; i < properties.length; i++) {
    const property = properties[i];
    if (property.id === selectedKey) return property;
  }

  return defaultProperty;
};

const WaterpointPanel: React.FC<ExecutionListPanelProps> = ({
  closePanel,
  properties,
  selectedKey,
  setWaterPoints,
}) => {
  const [currentSelection, setCurrentSelection] = useState<
    IProperty | undefined
  >();
  useEffect(() => {
    if (properties && selectedKey) {
      const existingProperty = getProperty(properties, selectedKey);
      if (existingProperty) setCurrentSelection(existingProperty);
      else setCurrentSelection(defaultProperty);
    } else {
      setCurrentSelection(defaultProperty);
    }
  }, [selectedKey, properties]);

  const onChangePropertyName = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    if (currentSelection) {
      const updatedSelection = _.cloneDeep(currentSelection);
      updatedSelection.name = newValue || '';
      updatedSelection.isDirty = true;
      setCurrentSelection(updatedSelection);
    }
  };

  const savePanel = useCallback(() => {
    closePanel();

    if (!currentSelection) return; // avoid further execution (normaly this will not happen)

    setWaterPoints((prevState) => {
      const newState = _.cloneDeep(prevState);
      if (prevState) {
        const currentItem = currentSelection;
        currentItem.isDirty = true;

        if (newState) {
          newState.map((item, index) => {
            if (newState && currentItem && item.id === currentItem.id) {
              // console.log(currentItem);
              newState[index] = currentItem;
            }
          });
        }
      }
      return newState;
    });
  }, [currentSelection, setWaterPoints]);

  return (
    <Panel
      // isLightDismiss
      onDismiss={() => {
        closePanel();
      }}
      closeButtonAriaLabel='Close'
      headerText='Item toevoegen of verwijderen'
      type={PanelType.custom}
      customWidth='650px'
      isOpen
      styles={customPanelStyles}
    >
      <div>
        <div className={modalContentStyles.header}>
          <span>
            {currentSelection && currentSelection.id && currentSelection.id > 0
              ? 'Wijzig item'
              : 'Voeg item toe'}
          </span>
        </div>
        <div className={modalContentStyles.body}>
          <Stack>
            <Stack.Item style={{ marginBottom: 10 }}>
              <TextField
                name='name'
                label='Naam'
                value={currentSelection && currentSelection.name}
                onChange={onChangePropertyName}
                required
              />
            </Stack.Item>
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack horizontal reversed tokens={stackTokens5}>
            <DefaultButton
              onClick={() => {
                closePanel();
              }}
              text='Annuleren'
            />
            <PrimaryButton
              onClick={() => {
                savePanel();
              }}
              disabled={false}
              text={
                currentSelection
                && currentSelection.id
                && currentSelection.id > 0
                  ? 'Wijzigen'
                  : 'Toevoegen'
              }
            />
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default WaterpointPanel;
