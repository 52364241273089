import {
 PanelType, Stack, Panel, PrimaryButton,
 Icon,
} from '@fluentui/react';
import { pdf, PDFViewer } from '@react-pdf/renderer';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { saveAs } from 'file-saver';
import {
  GET_CUSTOMER_CONTRACT_UPDATE_BY_ID,
  ICustomerContractUpdate,
} from '../../../utils/CustomerContractUpdate';
import { customPanelStyles } from '../../../theme';
import { toastError } from '../../../utils/toast';
import { normalizeFileNameBestelbon } from '../../../utils';
import CustomerContractUpdateDownloadTemplate from './CustomerContractUpdateDownloadTemplate';

const CustomerContractUpdateDynamicPdf = ({
  customerContractUpdateSource,
  setShowPdf,
}: any) => {
  const [customerContractUpdate, setCustomerContractUpdate] = useState<
    ICustomerContractUpdate | undefined
  >();

  const { loading, error } = useQuery(GET_CUSTOMER_CONTRACT_UPDATE_BY_ID, {
    variables: {
      where: {
        id: customerContractUpdateSource.id,
      },
    },
    fetchPolicy: 'no-cache',
    onError: (error: { message: any }) => {
      toastError(error.message);
    },
    onCompleted: (data: any) => {
      setCustomerContractUpdate(data.findOneCustomerContractUpdate);
    },
  });

  return (
    <Panel
      isOpen
      onDismiss={() => setShowPdf(false)}
      closeButtonAriaLabel='Close'
      headerText='PDF Bekijken'
      type={PanelType.custom}
      customWidth='100%'
      styles={customPanelStyles}
    >
      {!loading && !error && customerContractUpdate && (
        <Stack
          styles={{
            root: {
              position: 'fixed',
              top: 60,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'white',
              marginTop: '0!important',
              display: 'flex',
              padding: '20px',
            },
          }}
        >
          <Stack.Item styles={{ root: { paddingBottom: 10 } }}>
            <PrimaryButton
              onClick={async () => {
                const blob = await pdf(
                  <CustomerContractUpdateDownloadTemplate
                    customerContractUpdate={customerContractUpdate}
                  />,
                ).toBlob();
                saveAs(
                  blob,
                  `${normalizeFileNameBestelbon(
                    `min-meerwerk-${customerContractUpdate.document_no || ''}-${
                      customerContractUpdate.construction_site?.name || ''
                    }`,
                  )}.pdf`,
                );
              }}
            >
              Download <Icon style={{ marginLeft: 7 }} iconName='PDF' />
            </PrimaryButton>
          </Stack.Item>
          <Stack.Item styles={{ root: { flex: 1 } }}>
            <PDFViewer width='100%' height='100%'>
              <CustomerContractUpdateDownloadTemplate
                customerContractUpdate={customerContractUpdate}
              />
            </PDFViewer>
          </Stack.Item>
        </Stack>
      )}
    </Panel>
  );
};

export default CustomerContractUpdateDynamicPdf;
