import { IComboBoxOption } from '@fluentui/react';
import { gql } from '@apollo/client';
import { IIncomingInvoiceLineItem } from './IncomingInvoiceLineItem';
import { ITimesheetEntry } from './TimesheetEntry';
import { ICostCenterWings } from './ConstCenterWings';

export interface ICostCenter {
  active?: boolean;
  customer_specific?: boolean;
  id: number;
  name?: string;
  number?: number;
  version?: number;
  cost_center_wings?: ICostCenterWings[];
  // defect_work_order_line_items: [Defect_work_order_line_item]
  prisma_total?: number;

  incoming_invoice_line_items?: IIncomingInvoiceLineItem[];
  time_sheet_entries?: ITimesheetEntry[];
}

export const GET_COST_CENTERS_FINANCIAL_ANALYSIS = gql`
  query getCostCenters(
    $filter: CostCenterFilterInput
    $orderBy: CostCenterOrderByInput
    $lineItemFilter: IncomingInvoiceLineItemWhereInput
    $timeSheetFilter: TimeSheetEntryWhereInput
  ) {
    findManyCostCenters(filter: $filter, orderBy: $orderBy) {
      id
      prisma_total
      name
      active
      customer_specific
      incoming_invoice_line_items(where: $lineItemFilter) {
        amount
        amount_vat
        car_id
        comment
        cost_center_id
        customer_id
        description
        id
        incoming_invoice_id
        incoming_invoice {
          id
          invoice_no
          internal_invoice_no
          supplier {
            id
            name
          }
        }
        wings_lineid
        construction_site {
          id
        }
      }
      time_sheet_entries(where: $timeSheetFilter) {
        comments
        cost_center_id
        customer_id
        employee_id
        employee {
          id
          first_name
          last_name
        }
        entry_date
        hourly_rate
        hours
        id
        status
        travel_allowance
        version
      }
    }
  }
`;

export const GET_COST_CENTERS = gql`
  query getCostCenters(
    $filter: CostCenterFilterInput
    $orderBy: CostCenterOrderByInput
  ) {
    findManyCostCenters(filter: $filter, orderBy: $orderBy) {
      id
      name
      active
      customer_specific
      prisma_total
    }
  }
`;

export const GET_COST_CENTER_BY_ID = gql`
  query getCostCenterById($where: CostCenterWhereInput) {
    findOneCostCenter(where: $where) {
      id
      name
      active
      customer_specific
      cost_center_wings {
        id
        cost_center_id
        wings_number
      }
    }
  }
`;

export const ADD_COST_CENTER = gql`
  mutation addCostCenter($data: CostCenterCreateInput!) {
    createCostCenter(data: $data) {
      id
      name
      active
      customer_specific
      cost_center_wings {
        id
        cost_center_id
        wings_number
      }
    }
  }
`;

export const UPDATE_COST_CENTER = gql`
  mutation updateCostCenter($id: Int!, $data: CostCenterUpdateInput) {
    updateCostCenter(id: $id, data: $data) {
      id
      name
      active
      customer_specific
      cost_center_wings {
        id
        cost_center_id
        wings_number
      }
    }
  }
`;

export const DELETE_COST_CENTER = gql`
  mutation deleteCostCenter($where: CostCenterWhereInput) {
    deleteCostCenter(where: $where) {
      id
      name
      active
      customer_specific
    }
  }
`;

export const convertCostCentersToComboBoxOptions = (
  cost_centers: ICostCenter[],
) => {
  if (cost_centers) {
    const result: IComboBoxOption[] = cost_centers.map(
      (cost_center: ICostCenter): IComboBoxOption => ({
        key: cost_center.id,
        text: cost_center.name || '',
      }),
    );
    return result;
  }

  return [];
};
