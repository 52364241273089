/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { concat } from 'lodash';
import BriefpapierLeeg from '../../../Sjabloon-briefpapier-leeg.jpg';
import fontSegoe from '../../../fonts/Segoe_UI.ttf';
import fontSegoeBold from '../../../fonts/Segoe_UI_Bold.ttf';

import { IConstructionSite, normalizeFileNameBestelbon } from '../../../utils';
import { ITimesheetEntry } from '../../../utils/TimesheetEntry';
import { ICostCenter } from '../../../utils/CostCenter';
import { IIncomingInvoiceLineItem } from '../../../utils/IncomingInvoiceLineItem';
import {
  calculateCosts,
  calculateInvoiced,
  calculateMeerMinWerkenBons,
} from './FinancialAnalysisCalculation';
import { invoiceLines, timeLines } from './FinancialAnalysisCostCenter';

Font.register({
  family: 'Segoe UI',
  fonts: [{ src: fontSegoe }, { src: fontSegoeBold, fontStyle: 'bold' }],
});

const FinancialAnalysisDownloadTemplate = ({
  costCenters,
  constructionSite,
  incomingInvoiceLineItems,
  timeSheetEntries,
}: {
  costCenters: ICostCenter[];
  constructionSite: IConstructionSite;
  incomingInvoiceLineItems: IIncomingInvoiceLineItem[];
  timeSheetEntries: ITimesheetEntry[];
}) => (
  // console.log(timeSheetEntries.length);
  <Document
    title={`${normalizeFileNameBestelbon(
      `financiele-analyse-${
        constructionSite?.name || ''
      }`,
    )}.pdf`}
  >
    <Page size='A4' style={styles.page}>
      <View
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100vw',
          height: '100vh',
        }}
        fixed
      >
        <Image
          src={BriefpapierLeeg}
          style={{ width: '100%', height: '100%' }}
        />
      </View>

      <PageHeader constructionSite={constructionSite} />

      <Calculation
        incomingInvoiceLines={incomingInvoiceLineItems}
        constructionSite={constructionSite}
        timeSheetEntries={timeSheetEntries}
      />

      {costCenters.map((costCenter: ICostCenter) => (
        <View key={costCenter.id}>
          <AnalysisCostCenter
            costCenter={costCenter}
            constructionSite={constructionSite}
            incomingInvoiceLines={incomingInvoiceLineItems}
            timeSheetEntries={timeSheetEntries}
          />
        </View>
      ))}
    </Page>
  </Document>
);
const PageHeader = ({
  constructionSite,
}: {
  constructionSite: IConstructionSite;
}) => (
  <View style={styles.pageHeaderWrapper} fixed>
    <View style={styles.pageHeader}>
      {/* <Image src={Logo} style={styles.image} /> */}
      <View>
        <Text style={styles.pageHeaderTitle}>
          Werf: {constructionSite.name}
        </Text>
      </View>
    </View>
  </View>
);

const Calculation = ({
  constructionSite,
  incomingInvoiceLines,
  timeSheetEntries,
}: {
  constructionSite: IConstructionSite;
  incomingInvoiceLines: IIncomingInvoiceLineItem[];
  timeSheetEntries: ITimesheetEntry[];
}) => {
  const contractAmount = constructionSite.contract_amount
    ? constructionSite.contract_amount
    : 0;

  const costs = calculateCosts(incomingInvoiceLines, timeSheetEntries);
  const invoiced = calculateInvoiced(constructionSite);
  const minmeerwerken = calculateMeerMinWerkenBons(constructionSite);

  return (
    <View>
      <Text
        style={{
          marginBottom: 5,
        }}
      >
        Samenvatting
      </Text>
      <View style={styles.calculationOne}>
        <View
          style={{
            marginBottom: 35,
            width: '40%',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <View
            style={{
              marginBottom: 10,
              width: '50%',
            }}
          >
            <Text
              style={{
                paddingLeft: 10,
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
              }}
              wrap={false}
            >
              Gefactureerd
            </Text>
            <Text
              style={{
                paddingLeft: 10,
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
              }}
              wrap={false}
            >
              Ontvangen
            </Text>

            <Text
              style={{
                paddingLeft: 10,
                paddingBottom: 3,
                paddingTop: 3,
              }}
              wrap={false}
            >
              Saldo
            </Text>
          </View>
          <View
            style={{
              marginBottom: 10,
              width: '50%',
              textAlign: 'right',
            }}
          >
            <Text
              style={{
                paddingLeft: 10,
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
                fontStyle: 'bold',
              }}
            >
              {invoiced.invoiceAmount.toFixed(2)}
            </Text>
            <Text
              style={{
                paddingLeft: 10,
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
                fontStyle: 'bold',
              }}
            >
              {invoiced.paidAmount.toFixed(2)}
            </Text>

            <Text
              style={{
                paddingLeft: 10,
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
                fontStyle: 'bold',
              }}
            >
              {(invoiced.invoiceAmount - invoiced.paidAmount).toFixed(2)}
            </Text>
          </View>
        </View>

        <View
          style={{
            marginBottom: 35,
            width: '50%',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <View
            style={{
              marginBottom: 10,
              fontSize: 10,
              width: '70%',
            }}
          >
            <Text
              style={{
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
              }}
              wrap={false}
            >
              Marge (Gefactureerd / kosten)
            </Text>
          </View>
          <View
            style={{
              marginBottom: 10,
              width: '30%',
              textAlign: 'right',
            }}
          >
            <Text
              style={{
                paddingRight: 10,
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
                fontStyle: 'bold',
              }}
            >
              {(
                invoiced.invoiceAmount /
                (costs.timeAmount + costs.invoiceAmount)
              ).toFixed(2)}
            </Text>
          </View>
        </View>
        <View
          style={{
            marginBottom: 35,
            width: '40%',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <View
            style={{
              marginBottom: 10,
              width: '50%',
            }}
          >
            <Text
              style={{
                paddingLeft: 10,
                fontSize: 10,
              }}
              wrap={false}
            >
              Contractbedrag
            </Text>
            <Text
              style={{
                paddingLeft: 10,
                paddingBottom: 3,
                paddingTop: 3,
                fontSize: 10,
              }}
            >
              Meerwerkenbons
            </Text>
            <Text
              style={{
                paddingLeft: 10,
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
              }}
            >
              Minderwerkenbons
            </Text>

            <Text
              style={{
                paddingLeft: 10,
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
              }}
              wrap={false}
            >
              Totaal facturatie
            </Text>
          </View>
          <View
            style={{
              marginBottom: 10,
              width: '50%',
              textAlign: 'right',
            }}
          >
            <Text
              style={{
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
                fontStyle: 'bold',
              }}
            >
              {contractAmount.toFixed(2)}
            </Text>
            <Text
              style={{
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
                fontStyle: 'bold',
              }}
            >
              {minmeerwerken.plus.toFixed(2)}
            </Text>

            <Text
              style={{
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
                fontStyle: 'bold',
              }}
            >
              {minmeerwerken.min.toFixed(2)}
            </Text>
            <Text
              style={{
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
                fontStyle: 'bold',
              }}
            >
              {(
                contractAmount +
                minmeerwerken.plus +
                minmeerwerken.min
              ).toFixed(2)}
            </Text>
          </View>
        </View>
        <View
          style={{
            marginBottom: 35,
            width: '50%',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <View
            style={{
              marginBottom: 10,
              width: '70%',
            }}
          >
            <Text
              style={{
                paddingLeft: 10,
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
              }}
              wrap={false}
            >
              Saldo (Totaal - gefactureerd)
            </Text>
            <Text
              style={{
                paddingLeft: 10,
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
              }}
              wrap={false}
            >
              Saldo (Totaal - ontvangen)
            </Text>
          </View>
          <View
            style={{
              marginBottom: 10,
              width: '30%',
              textAlign: 'right',
            }}
          >
            <Text
              style={{
                paddingRight: 10,
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
                fontStyle: 'bold',
              }}
            >
              {(
                contractAmount +
                minmeerwerken.plus +
                minmeerwerken.min -
                invoiced.invoiceAmount
              ).toFixed(2)}
            </Text>
            <Text
              style={{
                paddingRight: 10,
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
                fontStyle: 'bold',
              }}
            >
              {(
                contractAmount +
                minmeerwerken.plus +
                minmeerwerken.min -
                invoiced.paidAmount
              ).toFixed(2)}
            </Text>
          </View>
        </View>
        <View
          style={{
            marginBottom: 35,
            width: '40%',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <View
            style={{
              marginBottom: 10,
              width: '50%',
            }}
          >
            <Text
              style={{
                paddingLeft: 10,
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
              }}
              wrap={false}
            >
              Inkomende kosten
            </Text>
            <Text
              style={{
                paddingLeft: 10,
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
              }}
              wrap={false}
            >
              Werkuren kosten
            </Text>
            <Text
              style={{
                paddingLeft: 10,
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
              }}
              wrap={false}
            >
              Totaal kosten
            </Text>
          </View>
          <View
            style={{
              marginBottom: 10,
              width: '50%',
              textAlign: 'right',
            }}
          >
            <Text
              style={{
                paddingLeft: 10,
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
                fontStyle: 'bold',
              }}
            >
              {costs.invoiceAmount.toFixed(2)}
            </Text>
            <Text
              style={{
                paddingLeft: 10,
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
                fontStyle: 'bold',
              }}
            >
              {costs.timeAmount.toFixed(2)}
            </Text>
            <Text
              style={{
                paddingLeft: 10,
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
                fontStyle: 'bold',
              }}
            >
              {(costs.timeAmount + costs.invoiceAmount).toFixed(2)}
            </Text>
          </View>
        </View>
        <View
          style={{
            marginBottom: 35,
            width: '50%',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <View
            style={{
              marginBottom: 10,
              fontSize: 10,
              width: '70%',
            }}
          >
            <Text
              style={{
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
              }}
              wrap={false}
            >
              Saldo (Gefactureerd - kosten)
            </Text>
            <Text
              style={{
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
              }}
              wrap={false}
            >
              Saldo (Totaal - kosten)
            </Text>
            <Text
              style={{
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
              }}
              wrap={false}
            >
              Saldo (Ontvangen - kosten)
            </Text>
          </View>
          <View
            style={{
              marginBottom: 10,
              width: '30%',
              textAlign: 'right',
            }}
          >
            <Text
              style={{
                paddingRight: 10,
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
                fontStyle: 'bold',
              }}
            >
              {(
                invoiced.invoiceAmount -
                (costs.timeAmount + costs.invoiceAmount)
              ).toFixed(2)}
            </Text>
            <Text
              style={{
                paddingRight: 10,
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
                fontWeight: 'bold',
              }}
            >
              {(
                contractAmount +
                minmeerwerken.plus +
                minmeerwerken.min -
                (costs.timeAmount + costs.invoiceAmount)
              ).toFixed(2)}
            </Text>
            <Text
              style={{
                paddingRight: 10,
                fontSize: 10,
                paddingBottom: 3,
                paddingTop: 3,
                fontStyle: 'bold',
              }}
            >
              {(
                invoiced.paidAmount -
                (costs.timeAmount + costs.invoiceAmount)
              ).toFixed(2)}
            </Text>
          </View>
        </View>
      </View>
      {minmeerwerken.toInvoiceUpdates && minmeerwerken.toInvoiceUpdates.length && (
        <View wrap={false}>
          <Text
            style={{
              marginBottom: 5,
            }}
          >
            Te factureren onkosten
          </Text>
          <View>
            {minmeerwerken.toInvoiceUpdates.map((updates, index) => (
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  marginBottom: 25,
                  border: '1px solid #c9c9c9',
                  borderRadius: 10,
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
                wrap={false}
              >
                <View
                  style={{
                    marginBottom: 10,
                    width: '25%',
                  }}
                >
                  <Text
                    style={{
                      fontSize: 10,
                      paddingBottom: 3,
                      paddingTop: 3,
                    }}
                  >
                    {updates.nr}
                  </Text>
                </View>
                <View
                  style={{
                    marginBottom: 10,
                    width: '25%',
                  }}
                >
                  <Text
                    style={{
                      fontSize: 10,
                    }}
                  >
                    {updates.summary}
                  </Text>
                </View>
                <View
                  style={{
                    marginBottom: 10,
                    width: '25%',
                  }}
                >
                  <Text
                    style={{
                      fontSize: 10,
                      paddingBottom: 3,
                      paddingTop: 3,
                    }}
                  >
                    {updates.status}
                  </Text>
                </View>
                <View
                  style={{
                    marginBottom: 10,
                    width: '25%',
                    textAlign: 'right',
                  }}
                >
                  <Text
                    style={{
                      fontSize: 10,
                      paddingBottom: 3,
                      paddingTop: 3,
                    }}
                  >
                    {updates.amount.toFixed(2)}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      )}
    </View>
  );
};

const AnalysisCostCenter = ({
  costCenter,
  constructionSite,
  incomingInvoiceLines,
  timeSheetEntries,
}: {
  costCenter: ICostCenter;
  constructionSite: IConstructionSite;
  incomingInvoiceLines: IIncomingInvoiceLineItem[];
  timeSheetEntries: ITimesheetEntry[];
}) => {
  const t = 1;

  return (
    <View
      style={{
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: 25,
        padding: 0,
        borderRadius: 10,
        border: '1px solid #c9c9c9',
      }}
    >
      <View
        style={{
          marginTop: 0,
          marginBottom: 10,
          width: '100%',
          backgroundColor: '#c9c9c9',
          padding: 10,
          fontSize: 15,
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
        }}
        wrap={false}
      >
        <Text>{`Post: ${costCenter.name}`}</Text>
      </View>
      <Lines
        time_sheet_entries={
          costCenter.time_sheet_entries ? costCenter.time_sheet_entries : []
        }
        incoming_invoice_line_items={
          costCenter.incoming_invoice_line_items
            ? costCenter.incoming_invoice_line_items
            : []
        }
      />
    </View>
  );
};

const Lines = ({
  time_sheet_entries,
  incoming_invoice_line_items,
}: {
  time_sheet_entries: ITimesheetEntry[];
  incoming_invoice_line_items: IIncomingInvoiceLineItem[];
}) => {
  const invoice_lines_calculation = invoiceLines(incoming_invoice_line_items);
  const time_sheet_lines_calculation = timeLines(time_sheet_entries);

  const linesList: {
    id: number;
    name: string;
    items: {
      description: string;
      nr: string;
      invoice_id?: string;
      price: number;
    }[];
  }[] = concat(
    time_sheet_lines_calculation.lines,
    invoice_lines_calculation.lines,
  );

  const total =
    invoice_lines_calculation.total + time_sheet_lines_calculation.total;

  return (
    <View>
      {linesList.map((line, index) => (
        <View wrap>
          {line.items.map((item, index) => (
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                marginBottom: 4,
                marginTop: 4,
              }}
              wrap={false}
            >
              <View
                style={{
                  marginBottom: 10,
                  width: '30%',
                  fontSize: 10,
                }}
              >
                <Text
                  style={{
                    paddingLeft: 10,
                    fontSize: 10,
                  }}
                >
                  {index < 1 ? line.name : ''}
                </Text>
              </View>
              <View
                style={{
                  marginBottom: 10,
                  width: '40%',
                  fontSize: 10,
                }}
              >
                <Text
                  style={{
                    paddingLeft: 10,
                    fontSize: 10,
                  }}
                >
                  {item.description}
                </Text>
              </View>
              <View
                style={{
                  marginBottom: 10,
                  width: '15%',
                  fontSize: 10,
                }}
              >
                <Text
                  style={{
                    paddingLeft: 10,
                    fontSize: 10,
                  }}
                >
                  {item.nr}
                </Text>
              </View>
              <View
                style={{
                  marginBottom: 10,
                  width: '15%',
                  fontSize: 10,
                }}
              >
                <Text
                  style={{
                    paddingLeft: 10,
                    fontSize: 10,
                  }}
                >
                  {item.price.toFixed(2)}
                </Text>
              </View>
            </View>
          ))}
        </View>
      ))}
      <View
        style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <View
          style={{
            width: '100%',
            textAlign: 'right',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignItems: 'flex-end',
            marginTop: 20,
            justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              width: '40%',
            }}
          >
            <Text>
              <br />
            </Text>
          </View>
          <View
            style={{
              width: '20%',
              backgroundColor: '#dedfe0',
              fontSize: 10,
              padding: 10,
              borderTopLeftRadius: 10,
              borderBottomRightRadius: 10,
            }}
            wrap={false}
          >
            <Text
              style={{
                flex: 1,
                marginBottom: 10,
              }}
            >
              Totaal: {total.toFixed(2)}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  calculationOne: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 25,
    border: '1px solid #c9c9c9',
    borderRadius: 10,
    paddingTop: 10,
    fontSize: 10,
  },
  calculationTwo: {
    marginBottom: 35,
    width: '40%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  image: {
    width: '100px',
    objectFit: 'scale-down',
    marginRight: 15,
  },
  invoiceDetailsRow: {
    fontFamily: 'Segoe UI',
    fontSize: 10,
    lineHeight: '120%',
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
  },
  invoiceDetailsLabelAlt: {
    fontStyle: 'bold',
    width: '20%',
  },
  invoiceDetailsValueAlt: {
    width: '80%',
  },
  legalInfo: {
    fontFamily: 'Segoe UI',
    fontSize: 8,
    position: 'absolute',
    bottom: 30,
    left: 40,
    right: 140,
    paddingTop: 2,
  },
  legalInfoRow: {
    flexDirection: 'row',
  },
  now: {
    position: 'absolute',
    fontFamily: 'Segoe UI',
    fontSize: 9,
    bottom: 55,
    right: 40,
  },
  page: {
    backgroundColor: '#ffffff',
    paddingBottom: 60,
    paddingLeft: 45,
    paddingRight: 45,
    paddingTop: 95,
  },
  pageHeaderWrapper: {
    position: 'absolute',
    top: 20,
    left: 45,
    right: 45,
  },
  pageHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
    paddingBottom: 10,
  },
  pageHeaderName: {
    fontFamily: 'Segoe UI',
    fontSize: 12,
  },
  pageHeaderTitle: {
    fontFamily: 'Segoe UI',
    fontSize: 15,
    fontStyle: 'bold',
    paddingBottom: 5,
  },
  pageNumber: {
    position: 'absolute',
    fontFamily: 'Segoe UI',
    fontSize: 9,
    bottom: 43,
    left: 0,
    right: 40,
    textAlign: 'right',
  },
  supplierDetailsLabel: {
    fontStyle: 'bold',
    width: '11%',
  },
  supplierDetailsLabelAlt: {
    fontStyle: 'bold',
    width: '40%',
  },
  supplierDetailsRight: {
    width: '49%',
    borderLeft: '1px solid black',
    paddingLeft: 10,
  },
  supplierDetailsRow: {
    fontFamily: 'Segoe UI',
    fontSize: 10,
    lineHeight: '120%',
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
  },
  supplierDetailsValueAlt: {
    width: '59%',
  },
  text: {
    paddingBottom: 15,
    paddingRight: 5,
    fontFamily: 'Segoe UI',
    fontSize: 10,
    lineHeight: '120%',
  },
  table: {
    display: 'flex',
    width: 'auto',
    paddingTop: '15px',
  },
  tableColDescription: {
    width: '60%',
  },
  tableColBTW: {
    width: '5%',
  },
  tableColPrice: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 5,
  },
  tableRow: {
    borderBottomWidth: 1,
    borderColor: '#D2D4D1',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    fontFamily: 'Segoe UI',
    fontSize: 10,
    paddingBottom: '3px',
    paddingLeft: '5px',
    paddingTop: '3px',
  },
  tableRowHeader: {
    backgroundColor: '#D2D4D1',
    flexDirection: 'row',
    fontFamily: 'Segoe UI',
    justifyContent: 'space-between',
    fontSize: 10,
    paddingBottom: '3px',
    paddingLeft: '5px',
    paddingTop: '3px',
  },
});

export default FinancialAnalysisDownloadTemplate;
