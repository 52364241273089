import React, {
 FormEvent, useCallback, useEffect, useState,
} from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import moment from 'moment';
import {
  DefaultButton,
  Icon,
  PrimaryButton,
  Stack,
  TextField,
  Toggle,
} from '@fluentui/react';
import useDebounce from '../../components/hooks/useDebounce';
import {
  default_page_size,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import {
  GET_INCOMING_INVOICES,
  IIncomingInvoice,
} from '../../utils/IncomingInvoice';
import DateView from '../../components/parts/DateView';
import { useAppDispatch } from '../../redux/hooks';
import { SeverityLevel, throwError } from '../../redux/error/errorSlice';
import { FilterState } from '../../components/parts/FilterPanel';
import { sendNotification } from '../../redux/notification/notificationSlice';
import IncomingInvoicesOverview from './components/IncomingInvoicesOverview';

interface IPagedIncomingInvoice {
  incomingInvoices: IIncomingInvoice[];
  skip: number;
  take: number;
  initialLoad: boolean;
  showLoadMore: boolean;
  lazyLoading: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

const IncomingInvoices = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuth0();
  const [pagedState, setPagedState] = useState<IPagedIncomingInvoice>({
    incomingInvoices: [],
    skip: 0,
    take: default_page_size,
    // filter: '',
    showLoadMore: true,
    lazyLoading: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: false,
    sortedField: 'invoice_date',
  });

  const [advancedFilter, setAdvancedFilter] = useState<
    FilterState | undefined
  >();
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [isAlertPanelOpen, setIsAlertPanelOpen] = useState(false);
  const [wingsDate, setWingsDate] = useState<string | undefined>(undefined);

  const [filter, setFilter] = useState('');
  const [isPaid, setIsPaid] = useState(true);
  const [dateFilter, setDateFilter] = useState({
    start_date: '',
    end_date: '',
  });

  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    client.cache.reset();
    setPagedState(prevState => ({
      ...prevState,
      incomingInvoices: [],
      skip: 0,
    }));
  }, [filter, dateFilter, isPaid, advancedFilter]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const getFilters = useCallback(
    (
      filterString: string,
      dates: {
        start_date: string;
        end_date: string;
      },
    ) => {
      const filterArray = filterString.split(' ');
      const filters: any = {
        AND: [
          {
            paid: isPaid === true ? false : undefined,
          },
        ],
      };

      if (advancedFilter && advancedFilter.schema.length > 0) {
        for (let i = 0; i < advancedFilter.schema.length; i++) {
          for (let t = 0; t < advancedFilter.schema[i].fields.length; t++) {
            if (
              advancedFilter.schema[i].fields[t].value &&
              advancedFilter.schema[i].fields[t].parsedValue &&
              advancedFilter.schema[i].fields[t].value !== ''
            ) {
              filters.AND.push(advancedFilter.schema[i].fields[t].parsedValue);
            }
          }
        }
      }

      if (dates.start_date || dates.end_date) {
        filters.AND.push({
          invoice_date: {
            gte: dates.start_date
              ? moment(new Date(dates.start_date))
              : undefined,
            lte: dates.end_date ? moment(new Date(dates.end_date)) : undefined,
          },
        });
      }

      for (let i = 0; i < filterArray.length; i++) {
        const filterValue: any = {
          OR: [
            { invoice_no: { contains: filterArray[i] } },
            { internal_invoice_no: { contains: filterArray[i] } },
            { supplier: { name: { contains: filterArray[i] } } },
          ],
        };

        filters.AND.push(filterValue);
      }

      console.log('filters', filters);
      return filters;
    },
    [filter, dateFilter, isPaid, advancedFilter],
  );

  const {
    data: dataIncomingInvoices,
    loading,
    error,
    fetchMore,
    client,
  } = useQuery(GET_INCOMING_INVOICES, {
    // notifyOnNetworkStatusChange: true,
    variables: {
      filter: getFilters(debouncedValue, dateFilter),
      take: pagedState.take,
      skip: pagedState.skip,
      orderBy: getOrderBy(),
    },
    onError: error => {
      dispatch(
        throwError({
          module: 'incoming invoices overview',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
    onCompleted: data => {
      console.log('EXECUTED');
    },
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    client.cache.reset();
    clearIncomingInvoices();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const onChangeFilterPaid = () => {
    setIsPaid(!isPaid);
    clearIncomingInvoices();
  };

  const clearIncomingInvoices = () => {
    setPagedState(prevState => ({
      ...prevState,
      incomingInvoices: [],
      skip: 0,
    }));
  };

  const fetchMoreCars = () => {
    // Load more callback

    fetchMore({
      variables: {
        filter: getFilters(debouncedValue, dateFilter), // inUse == active?
        take: pagedState.take,
        skip: pagedState.skip + pagedState.take,
        orderBy: getOrderBy(),
      },
    });

    setPagedState(prevState => ({
      ...prevState,
      skip: pagedState.skip + pagedState.take,
    }));
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <div style={{ padding: '25px' }}>
        <h3
          style={{
            fontSize: '20px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Icon iconName='ClosePane' style={{ fontSize: '20px' }} />
          Inkomende facturen
        </h3>

        <TextField
          label='Zoeken...'
          value={filter}
          styles={textFieldStyles300}
          onChange={onSearchValueChange}
        />

        <Stack style={{ flexDirection: 'row' }}>
          <Stack style={{ flexDirection: 'row', marginRight: 70 }}>
            <Stack style={{ marginBottom: 5, marginRight: 15 }}>
              <DateView
                item={dateFilter}
                setItem={setDateFilter}
                date={dateFilter && dateFilter.start_date}
                label='Startdatum'
                field='start_date'
                onChangeDate={(date: string) =>
                setDateFilter(prevState => ({ ...prevState, start_date: date }))
              }
              />
            </Stack>

            <Stack style={{ marginBottom: 5 }}>
              <DateView
                item={dateFilter}
                setItem={setDateFilter}
                date={dateFilter && dateFilter.end_date}
                label='Einddatum'
                field='end_date'
                onChangeDate={(date: string) =>
                setDateFilter(prevState => ({ ...prevState, end_date: date }))
              }
              />
            </Stack>
          </Stack>

          <Toggle
            label='Niet betaald?'
            checked={isPaid}
            onText='Niet betaald'
            offText='Alle'
            onChange={onChangeFilterPaid}
          />
          <Stack style={{ marginBottom: 5, marginLeft: 50 }}>
            <TextField
              label='Wings maand'
              type='month'
              value={wingsDate || ''}
              onChange={(event: any) => {
              setWingsDate(event.target.value);
            }}
            />
          </Stack>

          <Stack style={{ marginTop: 28, marginLeft: 10 }}>
            <PrimaryButton
              text='sync'
              disabled={!wingsDate}
              onClick={async () => {
              if (wingsDate) {
                dispatch(
                  sendNotification({
                    message: 'Wings Sync wordt gestart',
                    level: 0,
                    module: 'wings.sync',
                    spinner: true,
                  }),
                );

                console.log(wingsDate);
                const body = {
                  date: wingsDate,
                };

                const response = await fetch(
                  'https://3bouw-wings-sync-function.azurewebsites.net/api/SyncInvoices',
                  {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    body: JSON.stringify(body),
                  },
                );

                setWingsDate(undefined);

                console.log(response);

                if (response.ok) {
                  dispatch(
                    sendNotification({
                      module: 'wings.sync',
                      message: 'Wings Sync is gestart',
                      level: 1,
                      timeout: 2500,
                    }),
                  );
                } else {
                  dispatch(
                    throwError({
                      module: 'wings.sync',
                      message: 'Wings Sync is mislukt',
                      level: SeverityLevel.Critical,
                    }),
                  );
                }
              }
            }}
            />
          </Stack>
        </Stack>
      </div>

      <IncomingInvoicesOverview
        incomingInvoices={
          dataIncomingInvoices
            ? dataIncomingInvoices.findManyIncomingInvoices
            : []
        }
        loading={loading}
        setSorting={setSorting}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        showLoadMore={pagedState.showLoadMore}
        loadMore
        loadMoreCallback={fetchMoreCars}
        lazyLoading={pagedState.lazyLoading}
        setAdvancedFilter={setAdvancedFilter}
        isFilterPanelOpen={isFilterPanelOpen}
        setIsFilterPanelOpen={setIsFilterPanelOpen}
        isAlertPanelOpen={isAlertPanelOpen}
        setIsAlertPanelOpen={setIsAlertPanelOpen}
      />
    </Stack>
  );
};

export default IncomingInvoices;
