import { gql } from '@apollo/client';
import { IComboBoxOption } from '@fluentui/react';

export const GET_CONSTRUCTION_SITE_STATUSES = gql`
  query getConstructionSites(
    $filter: ConstructionSiteStatusFilterInput
    $take: Int
    $skip: Int
    $orderBy: ConstructionSiteStatusOrderByInput
  ) {
    findManyConstructionSiteStatuses(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      label
    }
  }
`;

export interface IConstructionSiteStatus {
  id?: number;
  label?: string;
}

export interface IConstructionSiteStatusQueryResult {
  findManyConstructionSiteStatuses: IConstructionSiteStatus[];
}

export const convertConstructionSiteStatusesToComboBoxOptions = (
  constructionSites: IConstructionSiteStatus[],
) => {
  const result: IComboBoxOption[] = constructionSites.map(
    (constructionSiteStatus: IConstructionSiteStatus): any => {
      if (constructionSiteStatus.id && constructionSiteStatus.id) {
        return {
          key: constructionSiteStatus.id,
          text: constructionSiteStatus.label,
        };
      }
    },
  );
  return result.length > 0 ? result : [];
};
