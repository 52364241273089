import * as React from 'react';
import { Stack, IStackProps } from '@fluentui/react/lib/Stack';

const Row: React.FC<IStackProps> = ({ children, ...props }) => (
  <Stack tokens={{ childrenGap: 15 }} {...props}>
    {children}
  </Stack>
);

export default Row;
