import {
  IComboBoxOption,
  Icon,
  Label,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  TextField,
  DayOfWeek,
  defaultDatePickerStrings,
  DatePicker,
  IRefObject,
  ITextField,
} from '@fluentui/react';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import 'moment/locale/nl-be';
import {
  LazyQueryExecFunction,
  OperationVariables,
  useMutation,
  useQuery,
} from '@apollo/react-hooks';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { ComboboxWithFilter } from '../../../components/parts';
import {
  convertConstructionSitesToComboBoxOptions,
  convertEmployeesToComboBoxOptions,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  GET_CONSTRUCTION_SITES,
  GET_EMPLOYEES,
  iconLastProps,
} from '../../../utils';
import {
  convertCostCentersToComboBoxOptions,
  GET_COST_CENTERS,
} from '../../../utils/CostCenter';
import {
  ITimesheetEntryWithType,
  SAVE_TIMESHEETS,
} from '../../../utils/Timesheet';
import useDebounce from '../../../components/hooks/useDebounce';
import { ITimesheetEntry } from '../../../utils/TimesheetEntry';
import parseNumber from '../../../utils/Numbers';
import { toastError, toastSuccess } from '../../../utils/toast';

// moment.locale('nl-be');

type Props = {
  days: moment.Moment[];
  timesheetEntry: any;
  timesheet: any;
  setTimesheet: any;
  index: number;
  lastRowIndex: number;
  addRow: () => void;
};

type TimeSheetProps = {
  timesheet: ITimesheetEntryWithType[];
  setTimesheet: React.Dispatch<React.SetStateAction<ITimesheetEntryWithType[]>>;
  getTimesheet: LazyQueryExecFunction<any, OperationVariables>;
  setGroupedTimesheetFilter: React.Dispatch<any>;
  currentDate: moment.Moment;
  setCurrentDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
  days: moment.Moment[];
  setDays: React.Dispatch<React.SetStateAction<moment.Moment[]>>;
  duration: number;
  employees: number;
};

const TimeSheetRow = ({
  timesheetEntry,
  timesheet,
  setTimesheet,
  index,
  lastRowIndex,
  addRow,
  days,
}: Props) => {
  const lastFieldRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (lastAction: boolean) => {
    addRow();
  };
  const [constructionSiteFilter, setConstructionSiteFilter] = useState('');

  const debouncedFilter = useDebounce(constructionSiteFilter, 500);

  const { loading: projectsLoading, data: projectsData } = useQuery(
    GET_CONSTRUCTION_SITES,
    {
      fetchPolicy: 'no-cache',
      variables: {
        orderBy: {
          name: 'asc',
        },
        filter: debouncedFilter
          ? {
              name: {
                contains: debouncedFilter,
              },
            }
          : undefined,
        take: 15,
      },
    },
  );

  const { data: employeesData, loading: employeesLoading } = useQuery(
    GET_EMPLOYEES,
    {
      fetchPolicy: 'no-cache',
      variables: {
        filter: {
          // active: true,
          is_external: false,
        },
      },
    },
  );

  return (
    <Stack
      style={{
        flexDirection: 'row',
        borderBottom: '1px solid rgb(239, 239, 239)',
        alignItems: 'top',
      }}
      key={`timesheet-lines-${index}`}
    >
      <StackItem
        style={{
          width: '20%',
          padding: 10,
        }}
      >
        <ComboboxWithFilter
          options={convertEmployeesToComboBoxOptions(
            (() => {
              // Ensure employees data is available
              const employees = employeesData?.findManyEmployees ?? [];

              // Determine if a specific employee is selected
              const selectedEmployeeId = timesheetEntry?.employee?.id;

              // Start with filtering only active employees if no specific employee is selected
              const filteredEmployees = employees.filter(
                (employee: any) => employee.active === true,
              );

              if (selectedEmployeeId !== undefined) {
                const selectedEmployeeExists = filteredEmployees.some(
                  (employee: any) => employee.id === selectedEmployeeId,
                );

                // If the selected employee exists but is not active, add them to the list explicitly
                if (!selectedEmployeeExists) {
                  const selectedEmployee = employees.find(
                    (employee: any) => employee.id === selectedEmployeeId,
                  );

                  if (selectedEmployee) {
                    filteredEmployees.push(selectedEmployee);
                  }
                }
                // If no specific selection, ensure the list includes only active employees
                // This part is handled by the initial filter condition
              }

              // Sort the filtered list
              return filteredEmployees.sort((obj1: any, obj2: any) => {
                const compareName =
                  obj1.first_name && obj2.first_name
                    ? obj1.first_name.localeCompare(obj2.first_name)
                    : 0;
                const compareTitle =
                  obj1.last_name && obj2.last_name
                    ? obj1.last_name.localeCompare(obj2.last_name)
                    : 0;
                return compareName || compareTitle;
              });
            })(),
          )}
          required
          errorMessage={
            !timesheetEntry ||
            !timesheetEntry.employee ||
            !timesheetEntry.employee.id
              ? 'Dit veld is verplicht'
              : ''
          }
          value={timesheetEntry?.employee?.id}
          multiline={false}
          callBack={(newValue: IComboBoxOption[]) => {
            if (newValue && newValue.length > 0) {
              const result = _.cloneDeep(timesheet);
              if (result[index].employee) {
                result[index].employee.id = +newValue[0].key;
              } else {
                result[index].employee = { id: +newValue[0].key };
              }
              setTimesheet(result);
            }
          }}
        />
      </StackItem>
      <StackItem
        style={{
          width: '20%',
          padding: 10,
        }}
      >
        <div>
          <ComboboxWithFilter
            options={convertConstructionSitesToComboBoxOptions(
              projectsData && projectsData.findManyConstructionSites
                ? timesheetEntry.construction_site &&
                  timesheetEntry.construction_site.name
                  ? projectsData.findManyConstructionSites.concat(
                      timesheetEntry.construction_site,
                    )
                  : projectsData.findManyConstructionSites
                : [],
            )}
            value={
              timesheetEntry &&
              timesheetEntry.construction_site &&
              timesheetEntry.construction_site.id
            }
            multiline={false}
            callBack={(newValue: IComboBoxOption[]) => {
              if (newValue && newValue.length > 0) {
                const result = _.cloneDeep(timesheet);
                result[index].construction_site = {
                  id: newValue[0].key,
                };

                setTimesheet(result);
              }
            }}
            setFilter={filterValue => {
              setConstructionSiteFilter(filterValue);
            }}
            allowFreeForm
          />
        </div>
      </StackItem>
      <StackItem
        style={{
          width: '20%',
          padding: 10,
        }}
      >
        <TextField
          value={timesheetEntry.comments || ''}
          onChange={(
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string,
          ) => {
            const result = _.cloneDeep(timesheet);
            result[index].comments = newValue;
            setTimesheet(result);
          }}
        />
      </StackItem>
      {days.map((day, key: number) => {
        const entry: any = timesheetEntry.values.find(
          (x: any) => moment(x.entry_date).isSame(day, 'day'),
          // eslint-disable-next-line function-paren-newline
        );
        const entryIndex: number = timesheetEntry.values.findIndex((x: any) =>
          moment(x.entry_date).isSame(day, 'day'));
        return (
          <StackItem
            style={{
              width: '5%',
              padding: 10,
              backgroundColor:
                key === 5 || key === 6 ? 'rgb(239, 239, 239)' : 'transparent',
            }}
            key={`day-${index}-${key}`}
          >
            <TextField
              name={entry ? entry.id : `day-${index}`}
              value={entry ? entry.hours : '0'}
              onKeyDown={e => {
                // key is tab

                if (
                  e.key === 'Tab' &&
                  index === lastRowIndex &&
                  key === days.length - 1
                ) {
                  addRow();
                }
              }}
              min={0}
              onChange={(
                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string,
              ) => {
                if (entry) {
                  const result = _.cloneDeep(timesheet);
                  result[index].values[entryIndex].hours = newValue;
                  setTimesheet(result);
                } else {
                  const result = _.cloneDeep(timesheet);
                  result[index].values.push({
                    hours: newValue,
                    entry_date: moment(day).toISOString(),
                  });
                  setTimesheet(result);
                }
              }}
            />
          </StackItem>
        );
      })}
      <StackItem
        style={{
          alignSelf: 'center',
          width: '2%',
          textAlign: 'right',
        }}
        onClick={() => {
          const result = _.cloneDeep(timesheet);
          result[index].values.map((value: any, x: number) => {
            result[index].values[x].hours = 0;
          });
          setTimesheet(result);
        }}
      >
        <Icon iconName='Delete' styles={iconLastProps} />
      </StackItem>
    </Stack>
  );
};

function NewTimesheetCostCenter({
  timesheet,
  setTimesheet,
  getTimesheet,
  setGroupedTimesheetFilter,
  currentDate,
  setCurrentDate,
  days,
  setDays,
  duration,
  employees,
}: TimeSheetProps) {
  const { loading: costCentersLoading, data: costCentersData } = useQuery(
    GET_COST_CENTERS,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [currentCostCenter, setCurrentCostCenter] = useState<number | string>();

  const [loader, setLoader] = useState(false);

  const [workedHours, setWorkedHours] = useState(0);
  const [firstSortingDone, setfirstSortingDone] = useState(false);

  useEffect(() => {
    let hours = 0;
    timesheet.map((entry: any) => {
      entry.values.map((value: any) => {
        hours += value.hours;
      });
    });
    setWorkedHours(hours);

    if (!firstSortingDone && timesheet && timesheet.length > 0) {
      // Sort timesheet
      timesheet.sort(
        (obj1: ITimesheetEntryWithType, obj2: ITimesheetEntryWithType) => {
          if (
            (obj1.construction_site || obj1.lead || obj1.customer) &&
            (obj2.construction_site || obj2.lead || obj2.customer)
          ) {
            const x1 = obj1.construction_site
              ? obj1.construction_site.name
              : obj1.lead
              ? obj1.lead.first_name1
              : obj1.customer.first_name1;
            const x2 = obj2.construction_site
              ? obj2.construction_site.name
              : obj2.lead
              ? obj2.lead.first_name1
              : obj2.customer
              ? obj2.customer.first_name1
              : undefined;
            if (x2) {
              return x1.localeCompare(x2);
            }
            return 1;
          }
          return obj1.status.localeCompare(obj2.status);
        },
      );
      setfirstSortingDone(true);
    }
  }, [timesheet]);

  const [saveTimesheetEntries] = useMutation(SAVE_TIMESHEETS);

  const getValues = (values: any, comments?: string) => {
    const valuesWithoutType: any[] = [];
    values.map((value: ITimesheetEntry) => {
      valuesWithoutType.push({
        id: value.id || undefined,
        hours: parseNumber(value.hours),
        entry_date: value.entry_date,
        comments,
      });
    });

    return valuesWithoutType;
  };

  const parseTimesheets = (timesheets: ITimesheetEntryWithType[]) => {
    const newTimeSheets = _.cloneDeep(timesheets);

    for (let i = 0; i < newTimeSheets.length; i++) {
      const timesheet = newTimeSheets[i];
      if (timesheet.values && timesheet.values.length > 0) {
        timesheet.comments = timesheet.values[0].comments;
      }
    }

    return newTimeSheets;
  };

  const saveTimesheet = async () => {
    try {
      if (timesheet) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput: any[] = [];
            timesheet.map((timesheetEntry: ITimesheetEntryWithType) => {
              allInput.push({
                customer: timesheetEntry.customer
                  ? { id: timesheetEntry.customer.id }
                  : undefined,
                lead: timesheetEntry.lead
                  ? { id: timesheetEntry.lead.id }
                  : undefined,
                cost_center: { id: currentCostCenter },
                employee: timesheetEntry.employee
                  ? { id: timesheetEntry.employee.id }
                  : undefined,
                construction_site:
                  timesheetEntry.construction_site &&
                  timesheetEntry.construction_site.id
                    ? { id: +timesheetEntry.construction_site.id }
                    : undefined,
                status: timesheetEntry.status,
                values: getValues(
                  timesheetEntry.values,
                  timesheetEntry.comments,
                ),
              });
            });

            saveTimesheetEntries({
              variables: { data: allInput },
              onCompleted: x => {
                resolve(x);
              },
              onError: err => {
                reject(err);
              },
              update: (cache, data: any) => {
                setTimesheet(parseTimesheets(data.data.saveTimesheetObject));
              },
            });
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
        toastSuccess('Werkuren opgeslagen');
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  return (
    <Stack style={{ flexDirection: 'column' }}>
      <Stack
        style={{
          marginBottom: '25px',
          padding: '0px 25px',
        }}
        horizontal
        horizontalAlign='space-between'
        verticalAlign='end'
        grow={1}
      >
        <Stack>
          <Stack style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Label style={{ marginRight: 8, minWidth: '100px' }}>Datum:</Label>
            <DatePicker
              firstDayOfWeek={DayOfWeek.Monday}
              placeholder='Select a date...'
              ariaLabel='Select a date'
              // DatePicker uses English strings by default. For localized apps, you must override this prop.
              strings={defaultDatePickerStrings}
              onSelectDate={date => {
                setDays([]);
                setCurrentDate(moment(date).startOf('week'));
                getTimesheet({
                  variables: {
                    start: moment(date).startOf('week').toISOString(),
                    cost_center: currentCostCenter,
                    duration,
                  },
                });
              }}
              style={{
                width: '250px',
              }}
              value={currentDate.toDate()}
            />
          </Stack>

          <Stack style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Label style={{ marginRight: 8, minWidth: '100px' }}>Post:</Label>
            <StackItem style={{ width: 220 }}>
              {costCentersLoading ? (
                <Stack>
                  <Spinner size={SpinnerSize.medium} />
                </Stack>
              ) : costCentersData &&
                costCentersData.findManyCostCenters &&
                costCentersData.findManyCostCenters.length > 0 ? (
                // eslint-disable-next-line react/jsx-indent
                <ComboboxWithFilter
                  options={convertCostCentersToComboBoxOptions(
                    costCentersData.findManyCostCenters,
                  )}
                  value={currentCostCenter}
                  multiline={false}
                  allowFreeForm
                  style={{
                    width: '250px',
                  }}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      setCurrentCostCenter(newValue[0].key);
                      getTimesheet({
                        variables: {
                          duration,
                          cost_center: newValue[0].key as number,
                          start: moment(currentDate)
                            .startOf('week')
                            .toISOString(),
                        },
                      });
                    }
                  }}
                />
              ) : (
                <Stack>&nbsp;</Stack>
              )}
            </StackItem>
          </Stack>
        </Stack>
        <Stack style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Label style={{ marginRight: 5 }}>Aantal medewerkers:</Label>
          <StackItem>{employees}</StackItem>
        </Stack>
      </Stack>

      <Stack
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '25px',
          padding: '0px 10px',
          alignSelf: 'flex-end',
          alignItems: 'center',
        }}
      >
        <StackItem style={{ fontWeight: 700 }}>
          <PrimaryButton
            disabled={!currentCostCenter}
            onClick={() => {
              setDays([]);
              getTimesheet({
                variables: {
                  start: moment(currentDate).add(-1, 'w').toISOString(),
                  cost_center: currentCostCenter,
                  duration,
                },
              });
              setCurrentDate(moment(currentDate).add(-1, 'w'));
            }}
          >
            &lt;
          </PrimaryButton>
        </StackItem>
        <StackItem style={{ fontWeight: 700, fontSize: 15 }}>
          Week {moment(currentDate).isoWeek()}:{' '}
          {moment(currentDate)
            .startOf('week')
            .locale('nl-be')
            .format('dd DD/MM/YYYY')}{' '}
          -{' '}
          {moment(currentDate)
            .startOf('week')
            .add(6, 'd')
            .format('dd DD/MM/YYYY')}
        </StackItem>
        <StackItem style={{ fontWeight: 700 }}>
          <PrimaryButton
            disabled={!currentCostCenter}
            onClick={() => {
              setDays([]);
              getTimesheet({
                variables: {
                  start: moment(currentDate).add(1, 'w').toISOString(),
                  cost_center: currentCostCenter,
                  duration,
                },
              });
              setCurrentDate(moment(currentDate).add(1, 'w'));
            }}
          >
            &gt;
          </PrimaryButton>
        </StackItem>
      </Stack>

      <Stack>
        <Stack
          style={{
            flexDirection: 'row',
            borderBottom: '1px solid rgb(229, 229, 229)',
            fontWeight: 600,
          }}
        >
          <StackItem
            style={{
              width: '20%',
              padding: 10,
              fontWeight: 600,
            }}
          >
            Medewerker
          </StackItem>
          <StackItem
            style={{
              width: '20%',
              padding: 10,
              fontWeight: 600,
            }}
          >
            Project / Taak
          </StackItem>
          <StackItem
            style={{
              width: '20%',
              padding: 10,
              fontWeight: 600,
            }}
          >
            Notitie
          </StackItem>
          {days.map((day, i: number) => (
            <StackItem
              style={{
                width: '5%',
                padding: 10,
                fontWeight: 600,
              }}
              key={`timesheet-days-${i}`}
            >
              {moment(day).format('ddd')}
              <br />
              {moment(day).format('DD/MM')}
            </StackItem>
          ))}
          <StackItem style={{ width: '2%' }}>&nbsp;</StackItem>
        </Stack>
        {timesheet &&
          timesheet.length > 0 &&
          timesheet.map(
            (timesheetEntry: ITimesheetEntryWithType, i: number) => (
              <TimeSheetRow
                days={days}
                timesheetEntry={timesheetEntry}
                timesheet={timesheet}
                setTimesheet={setTimesheet}
                index={i}
                lastRowIndex={timesheet.length - 1}
                addRow={() => {
                  setTimesheet((prevState: any) => [
                    ...prevState,
                    {
                      type: 'project',
                      customer: undefined,
                      employee: {},
                      status: 'tse_stat_3136',
                      cost_center: {
                        id: currentCostCenter,
                      },
                      values: [],
                    },
                  ]);
                }}
                key={`timeSheetEntry-${timesheetEntry.id}`}
              />
            ),
          )}
      </Stack>

      <Stack
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '25px',
        }}
      >
        <Stack style={{ flexDirection: 'row' }}>
          <PrimaryButton
            style={{ marginRight: 5 }}
            disabled={!currentCostCenter}
            onClick={() => {
              setTimesheet((prevState: any) => [
                ...prevState,
                {
                  type: 'project',
                  customer: undefined,
                  employee: {},
                  status: 'tse_stat_3136',
                  cost_center: {
                    id: currentCostCenter,
                  },
                  values: [],
                },
              ]);
            }}
          >
            Medewerker toevoegen
          </PrimaryButton>
        </Stack>
        <Stack>
          <PrimaryButton
            disabled={!currentCostCenter}
            onClick={() => saveTimesheet()}
          >
            Werkuren opslaan
            {loader && (
              <Spinner style={{ marginLeft: 10 }} size={SpinnerSize.small} />
            )}
          </PrimaryButton>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default NewTimesheetCostCenter;
