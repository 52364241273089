import { useRef } from 'react';
import { useQuery } from '@apollo/client';
import { saveAs } from 'file-saver';
import {
 Icon, Panel, PanelType, PrimaryButton, Stack,
} from '@fluentui/react';
import { pdf, PDFViewer } from '@react-pdf/renderer';
import { useAppDispatch } from '../../../redux/hooks';
import { GET_COST_CENTERS_FINANCIAL_ANALYSIS } from '../../../utils/CostCenter';
import { GET_CONSTRUCTION_SITE_BY_ID_ANALYSIS } from '../../../utils/ConstructionSite';
import { GET_INCOMING_INVOICE_LINE_ITEMS_ANALYSIS } from '../../../utils/IncomingInvoiceLineItem';
import { GET_TIMESHEET_ENTRIES_ANALYSIS } from '../../../utils/TimesheetEntry';
import { sendNotification } from '../../../redux/notification/notificationSlice';
import { customPanelStyles } from '../../../theme';
import { normalizeFileNameBestelbon } from '../../../utils';
import FinancialAnalysisDownloadTemplate from './FinancialAnalysisDownloadTemplate';

const FinancialAnalysisDynamicPdf = ({
  constructionSiteSource,
  setShowPdf,
}: any) => {
  const dispatch = useAppDispatch();

  const statusCostCenter = useRef('loading');
  const statusConstructionSite = useRef('loading');
  const statusIncomingInvoiceLineItems = useRef('loading');
  const statusTimeSheetEntries = useRef('loading');

  const checkLoadingPdf = () => {
    if (
      statusCostCenter.current === 'loaded' &&
      statusConstructionSite.current === 'loaded' &&
      statusIncomingInvoiceLineItems.current === 'loaded' &&
      statusTimeSheetEntries.current === 'loaded'
    ) {
      dispatch(
        sendNotification({
          module: 'constructionsite.downloadPdF',
          message: 'Bestand is gedownload',
          level: 1,
          timeout: 2500,
        }),
      );
    } else if (
      statusCostCenter.current !== 'loaded' &&
      statusConstructionSite.current !== 'loaded' &&
      statusIncomingInvoiceLineItems.current !== 'loaded' &&
      statusTimeSheetEntries.current !== 'loaded' &&
      (statusTimeSheetEntries.current === 'error' ||
        statusIncomingInvoiceLineItems.current === 'error' ||
        statusConstructionSite.current === 'error' ||
        statusCostCenter.current === 'error')
    ) {
      dispatch(
        sendNotification({
          module: 'constructionsite.downloadPdF',
          message: 'Error',
          level: 1,
          timeout: 2500,
        }),
      );
    }
  };

  const {
    loading: loadingTimeSheetEntries,
    error: errorTimeSheetEntries,
    data: dataTimeSheetEntries,
  } = useQuery(GET_TIMESHEET_ENTRIES_ANALYSIS, {
    variables: {
      filter: {
        construction_site_id: constructionSiteSource.id
          ? constructionSiteSource.id
          : 0,
      },
    },
    onCompleted: data => {
      statusTimeSheetEntries.current = 'loaded';
      console.log(data);
      checkLoadingPdf();
    },
  });

  const {
    loading: loadingCostCenters,
    error: errorCostCenters,
    data: dataCostCenters,
  } = useQuery(GET_COST_CENTERS_FINANCIAL_ANALYSIS, {
    variables: {
      filter: {
        OR: [
          {
            incoming_invoice_line_items: {
              some: {
                construction_site_id: constructionSiteSource.id,
              },
            },
          },
          {
            time_sheet_entries: {
              some: {
                construction_site_id: constructionSiteSource.id,
              },
            },
          },
        ],
      },
      lineItemFilter: {
        construction_site_id: constructionSiteSource.id,
      },
      timeSheetFilter: {
        construction_site_id: constructionSiteSource.id,
      },
      orderBy: {
        name: 'asc',
      },
    },
    onCompleted: data => {
      statusCostCenter.current = 'loaded';
      console.log(data);
      checkLoadingPdf();
    },
  });

  const {
    loading: loadingConstructionSite,
    error: errorConstructionSite,
    data: dataConstructionSite,
  } = useQuery(GET_CONSTRUCTION_SITE_BY_ID_ANALYSIS, {
    variables: {
      where: {
        id: constructionSiteSource.id ? constructionSiteSource.id : 0,
      },
      whereProps: {
        parent_id: null,
      },
    },
    onCompleted: data => {
      statusConstructionSite.current = 'loaded';
      console.log(data);
      checkLoadingPdf();
    },
  });

  const {
    loading: loadingIncomingInvoiceLineItems,
    error: errorIncomingInvoiceLineItems,
    data: dataIncomingInvoiceLineItems,
  } = useQuery(GET_INCOMING_INVOICE_LINE_ITEMS_ANALYSIS, {
    variables: {
      filter: {
        construction_site_id: constructionSiteSource.id
          ? constructionSiteSource.id
          : 0,
      },
    },
    onCompleted: data => {
      statusIncomingInvoiceLineItems.current = 'loaded';
      checkLoadingPdf();
    },
  });

  return (
    <Panel
      isOpen
      onDismiss={() => setShowPdf(false)}
      closeButtonAriaLabel='Close'
      headerText='PDF Bekijken'
      type={PanelType.custom}
      customWidth='100%'
      styles={customPanelStyles}
    >
      {!loadingCostCenters &&
        !loadingConstructionSite &&
        !loadingIncomingInvoiceLineItems &&
        !loadingTimeSheetEntries &&
        !errorCostCenters &&
        !errorConstructionSite &&
        !errorIncomingInvoiceLineItems &&
        !errorTimeSheetEntries && (
          <Stack
            styles={{
              root: {
                position: 'fixed',
                top: 60,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'white',
                marginTop: '0!important',
                display: 'flex',
                padding: '20px',
              },
            }}
          >
            <Stack.Item styles={{ root: { flex: 1 } }}>
              <Stack.Item styles={{ root: { paddingBottom: 10 } }}>
                <PrimaryButton
                  onClick={async () => {
                    const blob = await pdf(
                      <FinancialAnalysisDownloadTemplate
                        costCenters={dataCostCenters.findManyCostCenters}
                        constructionSite={
                          dataConstructionSite.findOneConstructionSite
                        }
                        incomingInvoiceLineItems={
                          dataIncomingInvoiceLineItems.findManyIncomingInvoiceLineItems
                        }
                        timeSheetEntries={
                          dataTimeSheetEntries.findManyTimeSheetEntries
                        }
                      />,
                    ).toBlob();
                    saveAs(
                      blob,
                      `${normalizeFileNameBestelbon(
                        `financiele-analyse-${
                          dataConstructionSite.findOneConstructionSite?.name ||
                          ''
                        }`,
                      )}.pdf`,
                    );
                  }}
                >
                  Download <Icon style={{ marginLeft: 7 }} iconName='PDF' />
                </PrimaryButton>
              </Stack.Item>
              <PDFViewer width='100%' height='100%'>
                <FinancialAnalysisDownloadTemplate
                  costCenters={dataCostCenters.findManyCostCenters}
                  constructionSite={
                    dataConstructionSite.findOneConstructionSite
                  }
                  incomingInvoiceLineItems={
                    dataIncomingInvoiceLineItems.findManyIncomingInvoiceLineItems
                  }
                  timeSheetEntries={
                    dataTimeSheetEntries.findManyTimeSheetEntries
                  }
                />
              </PDFViewer>
            </Stack.Item>
          </Stack>
        )}
    </Panel>
  );
};

export default FinancialAnalysisDynamicPdf;
