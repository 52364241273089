import { gql } from '@apollo/client';
import { IComboBoxOption } from '@fluentui/react';

export interface IMailTemplate {
  id: number;
  active?: boolean;
  name?: string;
  comments?: string;
  type?: string;
  body?: string;
  prisma_total?: number;
}

export const GET_MAIL_TEMPLATES = gql`
  query getMailTemplates(
    $filter: MailTemplateFilterInput
    $orderBy: MailTemplateOrderByInput
    $take: Int
    $skip: Int
  ) {
    findManyMailTemplates(
      filter: $filter
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      id
      active
      name
      comments
      type
      body
      prisma_total
    }
  }
`;

export const GET_MAIL_TEMPLATE_BY_ID = gql`
  query getMailTemplate($where: MailTemplateWhereInput) {
    findOneMailTemplate(where: $where) {
      id
      active
      name
      comments
      type
      body
    }
  }
`;

export const ADD_MAIL_TEMPLATE = gql`
  mutation AddMailTemplate($data: MailTemplateCreateInput!) {
    createMailTemplate(data: $data) {
      id
      active
      name
      comments
      type
      body
    }
  }
`;

export const UPDATE_MAIL_TEMPLATE = gql`
  mutation UpdateMailTemplate($id: Int!, $data: MailTemplateUpdateInput!) {
    updateMailTemplate(id: $id, data: $data) {
      id
      active
      name
      comments
      type
      body
    }
  }
`;

export const DELETE_MAIL_TEMPLATE = gql`
  mutation deleteMailTemplate($where: MailTemplateWhereInput) {
    deleteMailTemplate(where: $where) {
      id
    }
  }
`;

export const convertMailTemplatesToComboBoxOptions = (
  temp: IMailTemplate[],
) => {
  const result: IComboBoxOption[] = temp.map(
    (temp: IMailTemplate): IComboBoxOption => ({
      key: temp.id,
      text: `${temp.name}`,
    }),
  );
  return result;
};
