import { useRef, useEffect, MutableRefObject } from 'react';

type props = {
  ctrlS?: () => void;
  ctrlC?: () => void;
  ctrlV?: () => void;
}
// Hook
function useCtrl({ ctrlC, ctrlS, ctrlV }: props): [ref: MutableRefObject<any>] {
  const ref = useRef(null);

  const onKeyPress = (event: globalThis.KeyboardEvent): void => {
    const charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === 's') {
      event.preventDefault();
      if (ctrlS) ctrlS();
    }
  };

  useEffect(
    () => {
      // const node = ref.current;
        window.document.addEventListener('keydown', onKeyPress);
        return () => {
            window.document.removeEventListener('keydown', onKeyPress);
        };
    },
    [ref.current], // Recall only if ref changes
  );

  return [ref];
}

  export default useCtrl;
