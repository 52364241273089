import { gql } from '@apollo/client';

export interface IWingsSyncIssue {
  dated_created?: string;
  id: number;
  invoice_no: string;
  issue_description: string;
  issue_details: string;
  version: number;
}

export const GET_WINGS_SYNC_ISSUES = gql`
  query getWingsSyncIssues(
    $filter: WingsSyncIssueFilterInput
    $orderBy: WingsSyncIssueOrderByInput
    $take: Int
    $skip: Int
  ) {
    findManyWingsSyncIssues(
      filter: $filter
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      date_created
      id
      invoice_no
      issue_description
      issue_details
      version
    }
  }
`;
