import {
    Checkbox, DetailsListLayoutMode, IColumn, IObjectWithKey, ISelection, SelectionMode,
} from '@fluentui/react';
import { useState } from 'react';
import { DetailsListDefault } from '../../../components/parts';
import { ISupplier } from '../../../utils';
import { formatPhoneNumber } from '../../../helpers';

interface props {
    items: ISupplier[],
    selection?: ISelection<IObjectWithKey> | undefined,
    onItemInvoked?: () => void;
    enableShimmer?: boolean;
    isSortedAsc?: boolean;
    sortedField: string;
    setSorting: (isDesc: boolean, field: string) => void;
    loadMore?: boolean;
    lazyLoading?: boolean;
    loading?: boolean;
    loadMoreCallback?: () => void;
}

const SuppliersDetailsList = ({
    items,
    selection,
    onItemInvoked,
    enableShimmer = false,
    isSortedAsc = false,
    sortedField,
    setSorting, // = (isSortedAsc: boolean, sortedField: string) => {},
    loadMore,
    lazyLoading,
    loading,
    loadMoreCallback,
  }: props) => {
    // On Column Click
    const onColumnClick = (event: any, column: any) => {
        const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
        let fieldName = '';
        let sortDescending = false;

        if (sortedColumn) {
          const newColumns = columns.map((col: IColumn) => {
            if (col.fieldName === column.fieldName) {
              col.isSorted = true;

              col.isSortedDescending =
                column.fieldName !== sortedColumn[0].fieldName
                  ? false
                  : !col.isSortedDescending;

              fieldName = column.fieldName;
              sortDescending = col.isSortedDescending;
            } else {
              col.isSorted = false;
            }
            return col;
          });
          setColumns(newColumns);

          setSorting(!sortDescending, fieldName);
        }
      };

    // Column List
    const columnsList = [
        {
          key: 'column1',
          name: 'Naam',
          fieldName: 'name',
          minWidth: 200,
          maxWidth: 350,
          isRowHeader: true,
          isSorted: true,
          onColumnClick,
          onRender: (supplier: ISupplier) => <span>{supplier.name}</span>,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column2',
          name: 'Adres',
          fieldName: 'address',
          minWidth: 200,
          maxWidth: 350,
          isRowHeader: true,
          onRender: (supplier: ISupplier) => (
            <span>
              {supplier.address}
              <br />
              {supplier.zip_code} {supplier.city}
            </span>
          ),
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column4',
          name: 'Telefoon',
          fieldName: 'phone',
          minWidth: 90,
          maxWidth: 350,
          isRowHeader: true,
          onRender: (supplier: ISupplier) => <span>{supplier?.phone_v2 ? formatPhoneNumber(supplier?.phone_v2) : ''}</span>,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column5',
          name: 'GSM',
          fieldName: 'mobile',
          minWidth: 90,
          maxWidth: 350,
          isRowHeader: true,
          onRender: (supplier: ISupplier) => <span>{supplier.mobile_v2 ? formatPhoneNumber(supplier.mobile_v2) : ''}</span>,
          data: 'string',
          isPadded: true,
        },
        /* {
          key: 'column6',
          name: 'Email',
          fieldName: 'email',
          minWidth: 90,
          maxWidth: 350,
          isRowHeader: true,
          onRender: (supplier: ISupplier) => <span>{supplier.email}</span>,
          data: 'string',
          isPadded: true,
        }, */
        {
          key: 'column7',
          name: 'BTW nr',
          fieldName: 'vat',
          minWidth: 110,
          maxWidth: 350,
          isRowHeader: true,
          onRender: (supplier: ISupplier) => <span>{supplier.vat_code}</span>,
          data: 'string',
          isPadded: true,
        },
    ];

    const initColumns = (sortedField: string, isSortedAsc: boolean) => columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

    const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

    return (
      <DetailsListDefault
        items={items}
        columns={columns}
        layoutMode={DetailsListLayoutMode.justified}
        selection={selection}
        selectionMode={SelectionMode.single}
        selectionPreservedOnEmptyClick
        onItemInvoked={onItemInvoked}
        enableShimmer={enableShimmer}
        loadMore={loadMore}
        lazyLoading={lazyLoading}
        loadMoreCallback={loadMoreCallback}
        loading={loading}
      />
    );
  };

  export default SuppliersDetailsList;
