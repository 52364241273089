import React, { useEffect, useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  Stack,
  Label,
  StackItem,
  Checkbox,
  TextField,
  ComboBox,
} from '@fluentui/react';
import _ from 'lodash';
import {
  IPropertyType,
  convertPropertyTypeToComboBoxOptions,
  modalContentStyles,
  resolveUndefinedVat,
} from '../../../utils';
import { ICustomerContractUpdateLineItem } from '../../../utils/CustomerContractUpdateLineItem';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import {
  ITaxCode,
  convertTaxCodesToComboBoxOptions,
} from '../../../utils/TaxCode';
import Combobox from '../../../components/parts/ComboBox';
import { customPanelStyles } from '../../../theme';

type Props = {
  isOpen: boolean;
  dismissPanel: () => void;
  saveCustomerContractUpdateLine: () => void;
  contractLine: ICustomerContractUpdateLineItem;
  setContractLine: React.Dispatch<
    React.SetStateAction<ICustomerContractUpdateLineItem | undefined>
  >;
  disabled?: boolean;
  taxCodes: ITaxCode[];
};

const ContractUpdateDetail = ({
  isOpen,
  dismissPanel,
  contractLine,
  setContractLine,
  disabled,
  saveCustomerContractUpdateLine,
  taxCodes,
}: Props) => {
  useEffect(() => {
    if (contractLine?.id === undefined) {
      setContractLine((prevState: any) => ({
        ...prevState,
        show_only_total: true,
      }));
    }
  }, [contractLine?.id, isOpen]);

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setContractLine((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue,
    }));
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        contractLine && contractLine.id
          ? 'Min-/meerwerk detail wijzigen'
          : 'Min-/meerwerk detail toevoegen'
      }
      type={PanelType.custom}
      customWidth='500px'
      styles={customPanelStyles}
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {contractLine && contractLine.id && (
            <Label>ID: {contractLine.id}</Label>
          )}

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='description'
              label='Omschrijving'
              value={
                contractLine && contractLine.description
                  ? contractLine.description
                  : ''
              }
              onChange={onChangeTextFieldValue}
              multiline
              required
              disabled={disabled}
            />
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='units'
              label='Aantal'
              value={
                contractLine && contractLine.units
                  ? contractLine.units.toString()
                  : ''
              }
              onChange={onChangeTextFieldValue}
              required
              disabled={disabled}
            />
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='unit_price'
              label='Eenheidsprijs'
              prefix='€'
              value={
                contractLine && contractLine.unit_price
                  ? contractLine.unit_price.toString()
                  : ''
              }
              onChange={onChangeTextFieldValue}
              required
              disabled={disabled}
            />
          </Stack>

          {contractLine && contractLine.unit_price && contractLine.units && (
            <Stack
              style={{
                marginBottom: 10,
              }}
            >
              <Label>Totaal excl BTW</Label>&euro;{' '}
              {(+contractLine.unit_price * +contractLine.units).toFixed(2)}
            </Stack>
          )}

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='percentage_vat'
              label='BTW %'
              placeholder='21'
              value={
                contractLine &&
                (contractLine.percentage_vat ||
                  contractLine.percentage_vat === 0)
                  ? contractLine.percentage_vat.toString()
                  : ''
              }
              onChange={onChangeTextFieldValue}
              required
              disabled={disabled}
            />
          </Stack>
          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <Combobox
              label='BTW Code'
              required
              options={convertTaxCodesToComboBoxOptions(taxCodes)}
              selectedKey={
                contractLine && contractLine.tax_code
                  ? contractLine.tax_code.id
                  : undefined
              }
              onSelect={key => {
                if (key && !Array.isArray(key)) {
                  if (taxCodes) {
                    const filterValues = taxCodes.filter((item: ITaxCode) => {
                      if (item && item.id && item.id === key) {
                        return true;
                      }
                      return false;
                    });

                    if (filterValues && filterValues[0]) {
                      const typeValue = filterValues[0];
                      const result = _.cloneDeep(contractLine);
                      result.tax_code = typeValue;
                      result.percentage_vat = typeValue.rate;

                      setContractLine(result);
                    }
                  }
                }
              }}
              disable={disabled}
            />
          </Stack>

          {contractLine && contractLine.unit_price && contractLine.units && (
            <Stack
              style={{
                marginBottom: 10,
              }}
            >
              <Label>Totaal incl BTW</Label>&euro;{' '}
              {(contractLine.tax_code?.rate ??
                resolveUndefinedVat(Number(contractLine.percentage_vat))) === 0
                ? (+contractLine.unit_price * +contractLine.units).toFixed(2)
                : (contractLine.tax_code?.rate ??
                    resolveUndefinedVat(Number(contractLine.percentage_vat))) <
                  1
                ? (
                    +contractLine.unit_price *
                    +contractLine.units *
                    (1 +
                      (contractLine.tax_code?.rate ??
                        resolveUndefinedVat(
                          Number(contractLine.percentage_vat),
                        )))
                  ).toFixed(2)
                : (
                    +contractLine.unit_price *
                    +contractLine.units *
                    (1 +
                      (contractLine.tax_code?.rate ??
                        resolveUndefinedVat(
                          Number(contractLine.percentage_vat),
                        )) /
                        100)
                  ).toFixed(2)}
            </Stack>
          )}

          <Stack style={{ marginBottom: 10 }}>
            <Label>Toon enkel totaal</Label>
            <Checkbox
              name='show_only_total'
              label='Toon enkel totaal?'
              checked={!!(contractLine && contractLine.show_only_total)}
              onChange={() => {
                setContractLine((prevState: any) => ({
                  ...prevState,
                  show_only_total:
                    contractLine && contractLine.show_only_total
                      ? !contractLine.show_only_total
                      : true,
                }));
              }}
              disabled={disabled}
            />
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack
            style={{
              flexDirection: 'row',
              marginTop: 10,
              justifyContent: 'space-between',
            }}
          >
            <Stack style={{ flexDirection: 'row' }}>
              <SaveButtonWithPermissions
                disabled={
                  !contractLine ||
                  (contractLine &&
                    (!contractLine.units ||
                      !contractLine.unit_price ||
                      (!contractLine.percentage_vat &&
                        contractLine.percentage_vat !== 0) ||
                      !contractLine.description))
                }
                save={saveCustomerContractUpdateLine}
                permission='write:customerContractUpdateLines'
              />

              <StackItem>
                <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
              </StackItem>
            </Stack>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default ContractUpdateDetail;
