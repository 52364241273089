import {
  DetailsListLayoutMode,
  IColumn,
  IObjectWithKey,
  ISelection,
  SelectionMode,
} from '@fluentui/react';
import { useState } from 'react';
import moment from 'moment';
import { DetailsListDefault } from '../../../components/parts';
import { IEmployee, ISuggestedValue } from '../../../utils';
import { IAsset } from '../../../utils/Asset';

interface props {
  items: IAsset[];
  selection?: ISelection<IObjectWithKey> | undefined;
  enableShimmer?: boolean;
  isShimmered?: boolean;
  isSortedAsc?: boolean;
  sortedField: string;
  setSorting: (isDesc: boolean, field: string) => void;
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
  onItemInvoked?:
    | ((item?: any, index?: number | undefined, ev?: Event | undefined) => void)
    | undefined;
  employees?: IEmployee[];
  suggestedValues: ISuggestedValue[];
}

const AssetsDetailsList = ({
  items,
  selection,
  onItemInvoked,
  enableShimmer = false,
  isShimmered = false,
  isSortedAsc = false,
  sortedField,
  setSorting, // = (isSortedAsc: boolean, sortedField: string) => {},
  loadMore,
  lazyLoading,
  loading,
  loadMoreCallback,
  employees,
  suggestedValues,
}: props) => {
  // On Column Click
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;

          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });

      setColumns(newColumns);
      setSorting(!sortDescending, fieldName);
    }
  };

  const getDesignation = (asset: IAsset) => {
    if (asset.is_tool) {
      const x = asset.tools_designation
        ? suggestedValues.find(
            obj =>
              obj.id === Number(asset.tools_designation!.match(/\d{4}$/)?.[0]),
          )?.suggested_value
        : '';

      return x || 'Gereedschap';
    }

    if (asset.is_sign) {
      return 'Werfbord';
    }

    if (asset.is_ladder) {
      return 'Ladder';
    }

    if (asset.is_breaker) {
      return `Werfkast${asset.breakers_nr ? ` ${asset.breakers_nr}` : ''}`;
    }
  };

  const getLocation = (asset: IAsset) => {
    // no last log avalible, assume its at 3bouw
    if (!asset.last_log) return '3Bouw';

    if (asset.last_log && asset.last_log.construction_site) {
      return `Werf: ${asset.last_log.construction_site.name}`;
    }

    if (asset.last_log && asset.last_log.team) {
      return `Ploeg: ${asset.last_log.team.name}`;
    }

    return '3Bouw';
    // not with a team or at a construction site, so assumed it is at 3bouw
  };

  const getInspection = (asset: IAsset) => {
    // not inspectable
    if (!asset.inspectable) return <span>Niet van toepassing</span>;

    const today = moment();
    const inspectionDate = asset.last_inspection?.inspection_date
      ? moment(new Date(asset.last_inspection.inspection_date))
      : null;
    const inspectionExpiryDate = asset.last_inspection?.inspection_expiry_date
      ? moment(new Date(asset.last_inspection.inspection_expiry_date))
      : null;

    // inspectable but not yet inspected
    if (!inspectionDate) return <span style={{ color: 'red' }}>Keuring vereist</span>;

    // If there is no expiry date, it's always valid (green)
    if (!inspectionExpiryDate) {
      return <span style={{ color: '#71BF44' }}>{inspectionDate.format('DD/MM/YYYY')}</span>;
    }

    const daysUntilExpiry = inspectionExpiryDate.diff(today, 'days');

    // inspectable but last inspection expired
    if (daysUntilExpiry <= 0) {
      return <span style={{ color: 'red' }}>{inspectionDate.format('DD/MM/YYYY')}</span>;
    }

    // inspectable but last inspection will expire within a month
    if (daysUntilExpiry <= 30) {
      return <span style={{ color: 'orange' }}>{inspectionDate.format('DD/MM/YYYY')}</span>;
    }

    // inspectable and last inspection is still valid for over a month
    return <span style={{ color: '#71BF44' }}>{inspectionDate.format('DD/MM/YYYY')}</span>;
  };

  // Column List
  const columnsList: IColumn[] = [
    {
      key: 'column2',
      name: 'Benaming',
      fieldName: 'tools_designation',
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      onRender: (asset: IAsset) => <span>{getDesignation(asset)}</span>,
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column1',
      name: 'Type',
      fieldName: 'name',
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      onRender: (asset: IAsset) => (
        <span>
          {asset.is_breaker ? `${asset.breakers_type}` : `${asset.name}`}
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Merk',
      fieldName: 'make',
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      onRender: (asset: IAsset) => (
        <span>
          {asset && asset.make
            ? suggestedValues.find(
                obj => obj.id === Number(asset.make!.match(/\d{4}$/)?.[0]),
              )?.suggested_value
            : ''}
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Locatie',
      fieldName: 'last_log',
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      onRender: (asset: IAsset) => <span>{getLocation(asset)}</span>,
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'Keuring',
      fieldName: 'last_inspection',
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      onRender: (asset: IAsset) => getInspection(asset),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) =>
    columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  return (
    <DetailsListDefault
      items={items}
      columns={columns}
      layoutMode={DetailsListLayoutMode.justified}
      selection={selection}
      selectionMode={SelectionMode.multiple}
      selectionPreservedOnEmptyClick
      onItemInvoked={onItemInvoked}
      enableShimmer={enableShimmer}
      isShimmered={isShimmered}
      loadMore={loadMore}
      lazyLoading={lazyLoading}
      loadMoreCallback={loadMoreCallback}
      loading={loading}
    />
  );
};

export default AssetsDetailsList;
