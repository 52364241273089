import { gql } from '@apollo/client';
import { ICar } from './Car';
import { IEmployee } from './Employee';

export const GET_TEAM_MEMBERS = gql`
  query getTeamMembers(
    $filter: TeamMembersFilterInput
    $take: Int
    $skip: Int
    $orderBy: TeamMemberOrderByInput
  ) {
    findManyTeamMembers(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      employee {
        id
        first_name
        last_name
      }
    }
  }
`;

export const GET_TEAM_MEMBER_BY_ID = gql`
  query getTeamMemberById($where: TeamMemberWhereInput) {
    findOneTeamMember(where: $where) {
      id
      employee {
        id
        first_name
        last_name
      }
    }
  }
`;

export const ADD_TEAM_MEMBER = gql`
  mutation addTeamMember($data: TeamMemberCreateInput!) {
    createTeamMember(data: $data) {
      id
      employee {
        id
        first_name
        last_name
      }
    }
  }
`;

export const UPDATE_TEAM_MEMBER = gql`
  mutation updateTeamMember($id: Int!, $data: TeamMemberUpdateInput) {
    updateTeamMember(id: $id, data: $data) {
      id
      employee {
        id
        first_name
        last_name
      }
    }
  }
`;

export const DELETE_TEAM_MEMBER = gql`
  mutation deleteTeamMember($where: TeamMemberWhereInput) {
    deleteTeamMember(where: $where) {
      id
    }
  }
`;

export interface ITeamMember {
  id: number;
  current_driver: boolean;
  car: ICar;
  employee: IEmployee;
}
