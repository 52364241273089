import React, { useState, useEffect } from 'react';
import { TextField } from '@fluentui/react';
import useDebounce from './hooks/useDebounce';

const ZipCodeFetcher = ({
  object,
  setObject,
  zipFieldName,
  cityFieldName,
}: {
  object: any;
  setObject: React.Dispatch<React.SetStateAction<any>>;
  zipFieldName: string;
  cityFieldName: string;
}) => {
  const [zipcode, setZipcode] = useState(object?.[zipFieldName]);
  const [municipalities, setMunicipalities] = useState<string[]>([]);
  const debouncedZipcode = useDebounce(zipcode, 500);

  const fetchMunicipalities = async (zip: string) => {
    try {
      const response = await fetch(
        `https://api.basisregisters.vlaanderen.be/v2/postinfo/${zip}`,
      );
      if (response.ok) {
        const data = await response.json();
        const names = [data.gemeente.gemeentenaam.geografischeNaam.spelling];
        data.postnamen.forEach((name: any) => {
          if (
            name.geografischeNaam.spelling !==
            name.geografischeNaam.spelling.toUpperCase()
          ) {
            names.push(name.geografischeNaam.spelling);
          }
        });
        setMunicipalities(names);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    setZipcode(object?.[zipFieldName]);
  }, [object?.[zipFieldName]]);

  useEffect(() => {
    if (debouncedZipcode) {
      if (debouncedZipcode.length === 4) {
        fetchMunicipalities(debouncedZipcode);
      } else {
        setMunicipalities([]);
      }
    } else {
        setMunicipalities([]);
    }
  }, [debouncedZipcode]);

  return (
    <div>
      <TextField
        name='zip_code'
        placeholder='Postcode'
        type='number'
        value={zipcode}
        onChange={(e: any) => {
          setZipcode(e.target.value);
          setObject({
            ...object,
            [zipFieldName]: e.target.value,
          });
        }}
      />

      <div>
        {municipalities.map((name, index) => (
          <div key={index}>
            <button
              type='submit'
              onClick={e => {
                e.preventDefault();
                setObject({
                  ...object,
                  [cityFieldName]: name,
                });
                setMunicipalities([]);
              }}
              style={{
                width: '100%',
                marginTop: '5px',
                padding: '5px',
                paddingTop: '7px',
                paddingBottom: '7px',
                color: 'white',
                backgroundColor: '#7AB51C',
                boxShadow: 'none',
                border: 'none',
                borderRadius: '2px',
                cursor: 'pointer',
              }}
            >
              {name}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ZipCodeFetcher;
