import { FormEvent, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
// eslint-disable-next-line object-curly-newline
import { Icon, Stack, StackItem, TextField, Toggle } from '@fluentui/react';
import {
  DEFAULT_ERROR_MESSAGE,
  default_page_size,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import { GET_EMPLOYEES, IEmployee } from '../../utils/Employee';
import useDebounce from '../../components/hooks/useDebounce';
import { GET_APP_ROLES } from '../../utils/AppUser';
import { IAppUserRole } from '../../utils/AppUserRole';
import { toastError } from '../../utils/toast';
import EmployeesOverview from './components/EmployeesOverview';

interface IPagedEmployee {
  employees: IEmployee[];
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  lazyLoading: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

const Employees = () => {
  const { isAuthenticated } = useAuth0();
  const [pagedState, setPagedState] = useState<IPagedEmployee>({
    employees: [],
    skip: 0,
    take: default_page_size,
    filter: '',
    showLoadMore: true,
    lazyLoading: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: true,
    sortedField: 'first_name',
  });

  const [filter, setFilter] = useState('');
  const [active, setActive] = useState(true);
  const [internal, setInternal] = useState(true);

  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    client.cache.reset();
    setPagedState(prevState => ({ ...prevState, employees: [], skip: 0 }));
  }, [filter]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const getFilters = (filterString: string) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [{ active, is_external: !internal }],
    };
    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [
          { first_name: { contains: filterArray[i] } },
          { last_name: { contains: filterArray[i] } },
          { function: { contains: filterArray[i] } },
          { city: { contains: filterArray[i] } },
          { address: { contains: filterArray[i] } },
        ],
      };

      const numericValue = parseInt(filterArray[i], 10);
      if (numericValue) filterValue.OR.push({ zip_code1: +numericValue });

      filterObject.AND.push(filterValue);
    }

    return filterObject;
  };

  const {
    data: dataEmployees,
    loading,
    error,
    fetchMore,
    client,
    refetch,
  } = useQuery(GET_EMPLOYEES, {
    // notifyOnNetworkStatusChange: true,
    variables: {
      filter: getFilters(debouncedValue),
      take: pagedState.take,
      skip: 0,
      orderBy: getOrderBy(),
    },
    onError: error => {
      toastError(error.message || DEFAULT_ERROR_MESSAGE);
    },
  });

  const { data: dataRoles } = useQuery(GET_APP_ROLES, {
    // notifyOnNetworkStatusChange: true,
    onError: error => {
      toastError(error.message || DEFAULT_ERROR_MESSAGE);
    },
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const onChangeFilterActive = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    checked?: boolean | undefined,
  ) => {
    setActive(!!checked);
    clearEmployees();
    // refetchEmployees(); send filter to findMany employees?
  };

  const onChangeFilterExternal = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    checked?: boolean | undefined,
  ) => {
    setInternal(!!checked);
    clearEmployees();
    // refetchEmployees(); send filter to findMany employees?
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    clearEmployees();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const clearEmployees = () => {
    setPagedState(prevState => ({
      ...prevState,
      employees: [],
      skip: 0,
    }));
  };

  const formatRoles = (roles: any[]) => {
    const expandedRoles: IAppUserRole[] = [];
    roles.forEach(role => {
      expandedRoles.push({
        ...role,
        connect: false,
        disconnect: false,
        checked: false,
      });
    });

    return expandedRoles;
  };

  const fetchMoreEmployees = () => {
    // Load more callback

    fetchMore({
      variables: {
        filter: getFilters(debouncedValue),
        take: pagedState.take,
        skip: pagedState.skip + pagedState.take,
        orderBy: getOrderBy(),
      },
    });

    setPagedState(prevState => ({
      ...prevState,
      skip: pagedState.skip + pagedState.take,
    }));
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <div style={{ padding: '25px' }}>
        <h3
          style={{
            fontSize: '20px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Icon iconName='CRMServices' style={{ fontSize: '20px' }} />
          Medewerkers
        </h3>

        <Stack style={{ flexDirection: 'row' }}>
          <TextField
            label='Zoeken...'
            value={filter}
            styles={textFieldStyles300}
            onChange={onSearchValueChange}
          />

          <StackItem style={{ marginLeft: 70 }}>
            <Toggle
              label='In dienst / uit dienst?'
              defaultChecked={active}
              onText='In dienst'
              offText='Niet in dienst'
              onChange={onChangeFilterActive}
            />
          </StackItem>
          <StackItem style={{ marginLeft: 70 }}>
            <Toggle
              label='Intern / extern?'
              defaultChecked={active}
              onText='Intern'
              offText='Extern'
              onChange={onChangeFilterExternal}
            />
          </StackItem>
        </Stack>

      </div>

      <EmployeesOverview
        employees={dataEmployees ? dataEmployees.findManyEmployees : []}
        roles={dataRoles ? formatRoles(dataRoles.findManyAppRoles) : []}
        loading={loading}
        setSorting={setSorting}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        showLoadMore
        loadMore
        loadMoreCallback={fetchMoreEmployees}
        lazyLoading={pagedState.lazyLoading}
        refetch={refetch}
      />
    </Stack>
  );
};

export default Employees;
