import React, { useState, useEffect } from 'react';
import {
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import _ from 'lodash';
import moment from 'moment';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  ADD_WORK_ORDER,
  GET_WORK_ORDERS,
  GET_WORK_ORDER_BY_ID,
  IWorkOrder,
  UPDATE_WORK_ORDER,
} from '../../../utils/WorkOrder';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  displayNameEmployee,
  displayNameSupplier,
  GET_CONSTRUCTION_SITES,
  GET_EMPLOYEES,
  GET_SUPPLIERS,
  ISupplier,
} from '../../../utils';
import { commandBarTheme, commandBarThemeCounter } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import useDebounce from '../../../components/hooks/useDebounce';
import { GET_COST_CENTERS, ICostCenter } from '../../../utils/CostCenter';
import { useAppDispatch } from '../../../redux/hooks';
import { dismissNotification } from '../../../redux/notification/notificationSlice';
import { SeverityLevel, throwError } from '../../../redux/error/errorSlice';
import { toastSuccess, toastError } from '../../../utils/toast';
import WorkOrderDetail from './WorkOrderDetail';
import WorkOrdersDetailsList from './WorkOrderDetailsList';
import WorkOrdersDynamicPdf from './WorkOrderDynamicPdf';

interface Props {
  loadMore?: boolean;
  lazyLoading?: boolean;
  initialLoad?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
  workOrders: IWorkOrder[];
  setSorting: (isSortedAsc: boolean, sortedField: string) => void;
  isSortedAsc?: boolean;
  sortedField?: string;
  showLoadMore?: boolean;
}

function WorkOrdersOverview({
  workOrders,
  loading,
  initialLoad,
  loadMore,
  setSorting,
  isSortedAsc = false,
  sortedField = '',
  showLoadMore,
  lazyLoading,
  loadMoreCallback = () => ({}),
}: Props) {
  const dispatch = useAppDispatch();
  const [workOrder, setWorkOrder] = useState<IWorkOrder | undefined>(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [costCenters, setCostCenters] = useState<ICostCenter[]>([]);
  const [constructionSites, setConstructionSites] = useState([]);
  const [suppliers, setSuppliers] = useState<ISupplier[]>([]);
  const [supplierFilter, setSupplierFilter] = useState('');
  const debouncedFilter = useDebounce(supplierFilter, 500);

  const [showPdf, setShowPdf] = useState(false);

  const [constructionSiteFilter, setConstructionSiteFilter] = useState('');
  const debouncedConstructionSiteFilter = useDebounce(
    constructionSiteFilter,
    500,
  );

  const [addWorkOrder] = useMutation(ADD_WORK_ORDER, {
    // refetchQueries: [{ query: GET_WORK_ORDERS }],
  });

  const [modifyWorkOrder] = useMutation(UPDATE_WORK_ORDER, {});

  useQuery(GET_SUPPLIERS, {
    fetchPolicy: 'no-cache',
    variables: {
      take: 20,
      filter: {
        name: {
          contains: debouncedFilter,
        },
      },
    },
    onCompleted: (x: any) => {
      setSuppliers(x.findManySuppliers);
    },
  });

  const { loading: loadingConstructionSites } = useQuery(
    GET_CONSTRUCTION_SITES,
    {
      fetchPolicy: 'no-cache',
      variables: {
        take: 20,
        filter: {
          name: {
            contains: debouncedConstructionSiteFilter,
          },
        },
      },
      onCompleted: (x: any) => {
        setConstructionSites(x.findManyConstructionSites);
      },
    },
  );

  useQuery(GET_EMPLOYEES, {
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      setEmployees(x.findManyEmployees);
    },
  });

  const {
    loading: loadingCostCenters,
    error: errorCostCenters,
    // refetch: refetchCostCenters,
  } = useQuery(GET_COST_CENTERS, {
    variables: {
      filter: {
        active: {
          equals: true,
        },
      },
      orderBy: {
        name: 'asc',
      },
    },
    onError: (e: any) => {
      // console.log(e);
    },
    onCompleted: (x: any) => {
      if (x.findManyCostCenters && x.findManyCostCenters.length > 0) {
        setCostCenters(x.findManyCostCenters);
      }
    },
    fetchPolicy: 'no-cache',
  });

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<
    IWorkOrder | undefined
  >();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
      setWorkOrder(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  useQuery(GET_WORK_ORDER_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: selectionDetails
      ? { where: { id: selectionDetails.id } }
      : undefined,
    skip: !selectionDetails,
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setWorkOrder(data.findOneDefectWorkOrder);
    },
  });

  const openWorkOrderDetail = (newWorkOrder?: boolean) => {
    if (selectionDetails && !newWorkOrder) {
      // getWorkOrder({
      //   variables: { where: { id: selectionDetails.id } },
      // });
    } else {
      setWorkOrder(undefined);
    }
    setIsPanelOpen(true);
  };

  const saveWorkOrder = async () => {
    try {
      if (workOrder) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              description: workOrder.description,
              document_no: workOrder.document_no,
              status: workOrder.status,
              guarantee: workOrder.guarantee,
              cost_center: workOrder.cost_center
                ? {
                    connect: {
                      id: workOrder.cost_center.id,
                    },
                  }
                : undefined,
              construction_site: workOrder.construction_site
                ? {
                    connect: { id: workOrder.construction_site.id },
                  }
                : {},
              employee: workOrder.employee
                ? {
                    connect: { id: workOrder.employee.id },
                  }
                : {},
              supplier: workOrder.supplier
                ? {
                    connect: { id: workOrder.supplier.id },
                  }
                : {},
            };

            if (workOrder.id) {
              modifyWorkOrder({
                variables: {
                  id: workOrder.id,
                  data: allInput,
                },
                onError: error => {
                  setIsPanelOpen(false);
                  setSupplierFilter('');
                  reject(error);
                },
                onCompleted: (x: any) => {
                  setIsPanelOpen(false);
                  setSupplierFilter('');
                  resolve(x);
                },
              });
            } else {
              addWorkOrder({
                variables: {
                  data: allInput,
                },
                onError: error => {
                  setIsPanelOpen(false);
                  setSupplierFilter('');
                  reject(error);
                },
                onCompleted: (x: any) => {
                  setIsPanelOpen(false);
                  setSupplierFilter('');
                  resolve(x);
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        const x = await (res as any);
        if (x && x.createDefectWorkOrder) {
          setWorkOrder(x.createDefectWorkOrder);
        }
        toastSuccess('Werkbon opgeslagen');
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  // Sorting
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;

          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });
      setColumns(newColumns);

      setSorting(!sortDescending, fieldName);
    }
  };

  // Columns
  const columnsList = [
    {
      key: 'column1',
      name: 'Doc. no',
      fieldName: 'document_no',
      minWidth: 50,
      maxWidth: 50,
      isRowHeader: true,
      onRender: (workOrder: IWorkOrder) => <span>{workOrder.document_no}</span>,
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Omschrijving',
      fieldName: 'description',
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      onRender: (workOrder: IWorkOrder) => <span>{workOrder.description}</span>,
      // onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Uitvoerder (supplier)',
      fieldName: 'supplier',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      onRender: (workOrder: IWorkOrder) => (
        <span>
          {workOrder.supplier && displayNameSupplier(workOrder.supplier)}
        </span>
      ),
      // onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Uitvoerder (employee)',
      fieldName: 'employee',
      minWidth: 125,
      maxWidth: 125,
      isRowHeader: true,
      onRender: (workOrder: IWorkOrder) => (
        <span>
          {workOrder.employee && displayNameEmployee(workOrder.employee)}
        </span>
      ),
      // onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Werf',
      fieldName: 'construction_site',
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      onRender: (workOrder: IWorkOrder) => (
        <span>{workOrder.construction_site?.name}</span>
      ),
      // onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'Datum',
      fieldName: 'document_date',
      minWidth: 90,
      maxWidth: 90,
      isRowHeader: true,
      onRender: (workOrder: IWorkOrder) => (
        <span>
          {workOrder.document_date &&
            moment(new Date(workOrder.document_date)).format('YYYY-MM-DD')}
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'Status',
      fieldName: 'status',
      minWidth: 90,
      maxWidth: 90,
      isRowHeader: true,
      onRender: (workOrder: IWorkOrder) => {
        switch (workOrder.status) {
          case 'NEW':
            return <span>Nieuw</span>;
          case 'COMPLETED':
            return <span>Afgesloten</span>;
          default:
            return null;
        }
      },
      // onColumnClick,
      data: 'string',
      isPadded: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) =>
    columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

  const [columns, setColumns] = useState(
    initColumns(sortedField as string, isSortedAsc),
  );

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'totaal',
      text: `${workOrders.length} / ${workOrders.length > 0 ? workOrders[0].prisma_total : 0}`,
      theme: commandBarThemeCounter,
    },
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openWorkOrderDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openWorkOrderDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
    {
      key: 'download',
      text: 'PDF',
      iconProps: { iconName: 'PDF' },
      onClick: () => setShowPdf(true),
      theme: commandBarTheme,
      disabled: !workOrder || !workOrder.id,
    },
  ];

  // Other
  const getKey = (item: any) => {
    if (item) return item.id;
    return null;
  };

  useEffect(() => {
    if (workOrder && isPanelOpen) {
      document.title = `3bouw | Werkbon - ${workOrder.document_no}`;
    } else {
      document.title = '3bouw | Werkbonnen';
    }
  }, [workOrder, isPanelOpen]);

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        maxWidth='2000px'
      />

      {isPanelOpen && (
      <WorkOrderDetail
        isOpen={isPanelOpen}
        dismissPanel={() => {
          setIsPanelOpen(false);
          // setWorkOrder(undefined);
        }}
        workOrder={workOrder || {}}
        saveWorkOrder={saveWorkOrder}
        setWorkOrder={setWorkOrder}
        suppliers={suppliers}
        cost_centers={costCenters}
        employees={employees}
        constructionSites={constructionSites}
        loadingConstructionSites={loadingConstructionSites}
        setSupplierFilter={(value: string) => setSupplierFilter(value)}
        setConstructionSiteFilter={(value: string) =>
          setConstructionSiteFilter(value)
        }
      />
      )}
      <WorkOrdersDetailsList
        items={workOrders}
        selection={selection}
        enableShimmer={!loading}
        isSortedAsc={isSortedAsc}
        sortedField={sortedField}
        setSorting={setSorting}
        loadMore={loadMore}
        lazyLoading={lazyLoading}
        loadMoreCallback={loadMoreCallback}
        loading={loading}
        initialLoad={initialLoad}
        onItemInvoked={() => openWorkOrderDetail(false)}
      />

      {workOrders && (
        <>
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton
                text='Toon meer'
                onClick={() => loadMoreCallback()}
              />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {!loading && !showLoadMore && workOrders.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}

      {showPdf && workOrder && (
        <WorkOrdersDynamicPdf
          workOrderSource={workOrder}
          setShowPdf={setShowPdf}
        />
      )}
    </>
  );
}

export default WorkOrdersOverview;
