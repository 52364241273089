import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  ICommandBarItemProps,
  Icon,
  PrimaryButton,
  Selection,
  SelectionMode,
  Stack,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CommandBarSticky } from '../../../components/parts';
import { commandBarTheme } from '../../../theme';
import {
  columnStyles,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  DELETE_PURCHASE_ORDER_TEMPLATE,
  dialogModelProps,
  dialogRemoveConfirmationProps,
  iconLastProps,
  iconProps,
  IPurchaseOrderTemplate,
  stackTokens15,
} from '../../../utils';
import { toastSuccess, toastError } from '../../../utils/toast';

const PurchaseOrderTemplatesOverview = ({
  purchaseOrderTemplates,
  refetch,
}: //   setPurchaseOrderTemplates,
//   ...orders
any) => {
  const [items, setItems] = useState(purchaseOrderTemplates);
  const [deletePurchaseOrderTemplate] = useMutation(
    DELETE_PURCHASE_ORDER_TEMPLATE,
  );
  const [isConfirmationHidden, setIsConfirmationHidden] = useState(true);
  const [selectedId, setSelectedId] = useState(0);
  const navigate = useNavigate();

  const [selection, setSelection] = useState<any[]>([]);

  const onSelectionChanged = () => {
    const selection = selectionTemp.getSelection();
    setSelection(selection);
  };

  const selectionTemp = new Selection({
    onSelectionChanged,
  });

  const onItemInvoked = () => {};
  const deleteTemplate = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          deletePurchaseOrderTemplate({
            variables: {
              where: {
                id: selectedId,
              },
            },
            onCompleted: (data: any) => {
              refetch().then((x: any) => {
                setItems(x.data.findManyPurchaseOrderTemplates);
                toggleConfirmationDialog();
              });
              resolve(data);
            },
            onError: (error: any) => {
              reject(error);
            },
          });
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      await res;
      toastSuccess('Bestelbon sjabloon verwijderd');
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
      toggleConfirmationDialog();
    }
  };

  // It is toggle and clear selected value actually :-)
  const toggleConfirmationDialog = () => {
    setIsConfirmationHidden(!isConfirmationHidden);
    if (!isConfirmationHidden) setSelectedId(0);
  };

  const editPurchaseOrderTemplate = (id?: number) => {
    if (id) {
      navigate(`/purchase-order-template/${id}`);
    } else {
      navigate(
        `/purchase-order-template/${
          (selection[0] as IPurchaseOrderTemplate).id
        }`,
      );
    }
  };
  const copyPurchaseOrderTemplate = (id?: number) => {
    if (id) {
      navigate(`/purchase-order-template/copy${id}`);
    } else {
      navigate(
        `/purchase-order-template/copy${
          (selection[0] as IPurchaseOrderTemplate).id
        }`,
      );
    }
  };

  const removePurchaseOrderTemplate = (id?: number) => {
    if (id) {
      setSelectedId(id);
    } else {
      setSelectedId((selection[0] as IPurchaseOrderTemplate).id);
    }

    toggleConfirmationDialog();
  };

  const columns = [
    {
      key: 'column1',
      name: 'Naam',
      minWidth: 900,
      maxWidth: 900,
      isRowHeader: true,
      isResizable: false,
      onColumnClick: () => {},
      onRender: (item: IPurchaseOrderTemplate) => <span>{item.name}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: '',
      className: columnStyles.centerContents,
      minWidth: 50,
      maxWidth: 50,
      isRowHeader: true,
      isResizable: false,
      onColumnClick: () => {},
      onRender: (item: IPurchaseOrderTemplate) => (
        <span>
          <Icon
            iconName='Edit'
            styles={iconProps}
            onClick={() => editPurchaseOrderTemplate(item.id)}
          />
          <Icon
            iconName='Delete'
            styles={iconLastProps}
            onClick={() => removePurchaseOrderTemplate(item.id)}
          />
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
  ];

  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      href: '/purchase-order-template/add',
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => editPurchaseOrderTemplate(),
      disabled: selection.length < 1,
      theme: commandBarTheme,
    },
    {
      key: 'delete',
      text: 'Verwijder',
      iconProps: { iconName: 'delete' },
      onClick: () => removePurchaseOrderTemplate(),
      disabled: selection.length < 1,
      theme: commandBarTheme,
    },
    {
      key: 'copy',
      text: 'Kopiëren',
      iconProps: { iconName: 'Copy' },
      onClick: () => copyPurchaseOrderTemplate(),
      disabled: selection.length < 1,
      theme: commandBarTheme,
    },
  ];

  useEffect(() => {
    (() => {
      setItems(purchaseOrderTemplates);
    })();
  }, [purchaseOrderTemplates]);

  document.title = '3bouw | Bestelbon sjablonen';

  return (
    <Stack tokens={stackTokens15}>
      {/* <CommandBar
                items={commandBaritems}
                ariaLabel="Gebruik de pijltjes toetsen om tussen de verschillende commando's te navigeren."
                theme={commandBarTheme} />                 */}

      <CommandBarSticky
        items={commandBaritems}
        ariaLabel="Gebruik de pijltjes toetsen om tussen de verschillende commando's te navigeren."
        theme={commandBarTheme}
        maxWidth='2000px'
      />

      <DetailsList
        items={items}
        compact
        columns={columns}
        selection={selectionTemp}
        selectionMode={SelectionMode.single}
        selectionPreservedOnEmptyClick
        getKey={undefined}
        setKey='none'
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible
        onItemInvoked={onItemInvoked}
      />

      <Dialog
        hidden={isConfirmationHidden}
        onDismiss={toggleConfirmationDialog}
        dialogContentProps={dialogRemoveConfirmationProps}
        modalProps={dialogModelProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={deleteTemplate} text='Verwijderen' />
          <DefaultButton onClick={toggleConfirmationDialog} text='Annuleren' />
        </DialogFooter>
      </Dialog>
    </Stack>
  );
};

export default PurchaseOrderTemplatesOverview;
