import React from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import _ from 'lodash';
import { modalContentStyles } from '../../../utils';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import { IInspection } from '../../../utils/Inspection';
import DateView from '../../../components/parts/DateView';

const InspectionGroupDetail = ({
  isOpen,
  dismissPanel,
  inspectionGroup,
  setInspectionGroup,
  saveInspectionGroup,
  selectedObjects,
}: //
any) => {
  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setInspectionGroup((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  // ===============================================================================================
  // ===============================================================================================
  // ===============================================================================================

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText='Inspecties toevoegen'
      type={PanelType.custom}
      customWidth='500px'
    >
      <div>
        <div className={modalContentStyles.header} />

        <div className={modalContentStyles.body}>
          <div
            style={{
              display: 'flex',
              gap: '8px',
              marginBottom: '10px',
              flexWrap: 'wrap',
            }}
          >
            {selectedObjects.map((obj: any) => (
              <span
                style={{
                  background: 'rgb(112, 191, 68)',
                  borderRadius: '8px',
                  color: 'white',
                  padding: '3px',
                  flexWrap: 'wrap',
                }}
                key={obj.id}
              >
                {obj.name}
              </span>
            ))}
          </div>
          {/* {inspection && inspection.id && (
            <>
              <Label>ID: {inspection.id}</Label>

              <Stack style={{ marginBottom: 10 }}>
                <Label>Asset: {(asset as IAsset).name}</Label>
              </Stack>
            </>
          )} */}

          {/* <Stack style={{ marginBottom: 10 }}>
            <SuggestedView
              item={logItem || {}}
              setItem={setLogItem}
              prefix='logItem_title_'
              code={
                logItem &&
                logItem.title &&
                logItem.title.substr(logItem.title.length - 4)
              }
              label='Omschrijving'
              field='title'
            />
          </Stack> */}

          {/* <Stack style={{ marginBottom: 10 }}>
            <Label>Opvolging nodig?</Label>
            <Checkbox
              name='action_required'
              label='Opvolging nodig?'
              checked={!!(logItem && logItem.action_required)}
              onChange={() => {
                setLogItem((prevState: any) => ({
                  ...prevState,
                  action_required:
                    logItem && logItem.action_required
                      ? !logItem.action_required
                      : true,
                }));
              }}
            />
          </Stack> */}

          <Stack style={{ marginBottom: 10 }}>
            <DateView
              item={inspectionGroup}
              setItem={setInspectionGroup}
              date={
                inspectionGroup &&
                (inspectionGroup as IInspection).inspection_date
              }
              label='Datum inspectie'
              field='inspection_date'
              required
              disabled={inspectionGroup?.id && !inspectionGroup?.last}
            />
          </Stack>

          <Stack style={{ marginBottom: 10 }}>
            <DateView
              item={inspectionGroup}
              setItem={setInspectionGroup}
              date={
                inspectionGroup &&
                (inspectionGroup as IInspection).inspection_expiry_date
              }
              label='Vervaldatum inspectie'
              field='inspection_expiry_date'
              required
              disabled={inspectionGroup?.id && !inspectionGroup?.last}
            />
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='comments'
              label='Extra info'
              multiline
              value={
                inspectionGroup && (inspectionGroup as IInspection).comments
                  ? (inspectionGroup as IInspection).comments
                  : ''
              }
              onChange={onChangeTextFieldValue}
              disabled={inspectionGroup?.id && !inspectionGroup?.last}
            />
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack
            style={{
              flexDirection: 'row',
              marginTop: 10,
              justifyContent: 'space-between',
            }}
          >
            <Stack style={{ flexDirection: 'row' }}>
              {(!inspectionGroup?.id ||
                (inspectionGroup.id && inspectionGroup.last)) && (
                <SaveButtonWithPermissions
                  disabled={
                    !inspectionGroup ||
                    (inspectionGroup &&
                      (!(inspectionGroup as IInspection).inspection_date ||
                        !(inspectionGroup as IInspection)
                          .inspection_expiry_date))
                  }
                  save={saveInspectionGroup}
                  permission='write:inspections'
                />
              )}

              <StackItem>
                <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
              </StackItem>
            </Stack>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default InspectionGroupDetail;
