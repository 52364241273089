import { StackItem } from '@fluentui/react';
import { concat } from 'lodash';
import { ICostCenter } from '../../../utils/CostCenter';
import { ITimesheetEntry } from '../../../utils/TimesheetEntry';
import { IIncomingInvoiceLineItem } from '../../../utils/IncomingInvoiceLineItem';
import { IConstructionSite } from '../../../utils';
import FinancialAnalysisCalculation from './FinancialAnalysisCalculation';

export const invoiceLines = (invoice_line_items: IIncomingInvoiceLineItem[]) => {
  const itemList: {
    id: number;
    name: string;
    items: { description: string; nr: string; price: number, invoice_id?: string }[];
  }[] = [];

  let total = 0;

  for (let i = 0; i < invoice_line_items.length; i++) {
    const index = itemList.findIndex(
      x => x.id === invoice_line_items[i].incoming_invoice.supplier.id,
    );

    if (index === -1) {
      itemList.push({
        id: invoice_line_items[i].incoming_invoice.supplier.id,
        name: invoice_line_items[i].incoming_invoice.supplier.name,
        items: [
          {
            description: invoice_line_items[i].description,
            nr: invoice_line_items[i].incoming_invoice.internal_invoice_no,
            price: invoice_line_items[i].amount,
            invoice_id: invoice_line_items[i].incoming_invoice.id.toString() ? invoice_line_items[i].incoming_invoice.id.toString() : undefined,
          },
        ],
      });
    } else {
      itemList[index].items.push({
        description: invoice_line_items[i].description,
        nr: invoice_line_items[i].incoming_invoice.internal_invoice_no,
        invoice_id: invoice_line_items[i].incoming_invoice.id.toString(),
        price: invoice_line_items[i].amount,
      });
    }

    total += invoice_line_items[i].amount;
  }

  return {
    lines: itemList,
    total,
  };
};

export const timeLines = (time_sheet_entries: ITimesheetEntry[]) => {
  const entriesList: {
    id: number;
    name: string;
    totalHours: number;
    totalPrice: number;
  }[] = [];

  let total = 0;

  for (let i = 0; i < time_sheet_entries.length; i++) {
    const time_sheet_obj = time_sheet_entries[i];

    if (time_sheet_obj && time_sheet_obj.employee_id && time_sheet_obj.employee?.first_name && time_sheet_obj.employee.last_name && time_sheet_obj.hourly_rate && time_sheet_obj.hours) {
      const index = entriesList.findIndex(
        x => x.id === time_sheet_obj.employee_id,
      );

      if (index === -1) {
        const obj = {
          id: time_sheet_obj.employee.id,
          name: `${time_sheet_obj.employee.last_name as any} ${
            time_sheet_obj.employee.first_name
          }` as any,
          totalHours: +time_sheet_obj.hours,
          totalPrice: +time_sheet_obj.hours * time_sheet_obj.hourly_rate,
        };

        entriesList.push(obj);
      } else {
        entriesList[index].totalHours += +time_sheet_obj.hours;
        entriesList[index].totalPrice +=
        +time_sheet_obj.hours * time_sheet_obj.hourly_rate;
      }

      total += +time_sheet_obj.hours * time_sheet_obj.hourly_rate;
    }
  }

  const itemList: {
    id: number;
    name: string;
    items: { description: string; nr: string; price: number }[];
  }[] = [
    {
      id: -1,
      name: '*** Werkuren ***',
      items: [],
    },
  ];

  for (let i = 0; i < entriesList.length; i++) {
    itemList[0].items.push({
      description: `${entriesList[i].name} (${entriesList[i].totalHours})`,
      nr: '',
      price: entriesList[i].totalPrice,
    });
  }

  return {
    lines: itemList,
    total,
  };
};

const Lines = ({
  time_sheet_entries,
  incoming_invoice_line_items,
}: {
  time_sheet_entries: ITimesheetEntry[];
  incoming_invoice_line_items: IIncomingInvoiceLineItem[];
}) => {
  const invoice_lines_calculation = invoiceLines(incoming_invoice_line_items);
  const time_sheet_lines_calculation = timeLines(time_sheet_entries);

  const linesList: {
    id: number;
    name: string;
    items: {
      description: string;
      nr: string;
      invoice_id?: string;
      price: number;
    }[];
  }[] = concat(
    time_sheet_lines_calculation.lines,
    invoice_lines_calculation.lines,
  );

  const total =
    invoice_lines_calculation.total + time_sheet_lines_calculation.total;

  return (
    <>
      {linesList.map((line, index) => (
        <>
          {line.items.map((item, index) => (
            <>
              <StackItem
                style={{
                  marginBottom: 10,
                  width: '30%',
                }}
              >
                <p
                  style={{
                    paddingLeft: 10,
                  }}
                >
                  {index < 1 ? line.name : ''}
                </p>
              </StackItem>
              <StackItem
                style={{
                  marginBottom: 10,
                  width: '40%',
                }}
              >
                <p>{item.description}</p>
              </StackItem>
              <StackItem
                style={{
                  marginBottom: 10,
                  width: '15%',
                }}
              >
                <p><a target="_blank" style={{ width: '20%', color: 'black' }} href={`/incoming-invoices/${item.invoice_id}`} rel="noreferrer">{item.nr}</a></p>
              </StackItem>
              <StackItem
                style={{
                  marginBottom: 10,
                  width: '15%',
                  textAlign: 'right',
                }}
              >
                <p
                  style={{
                    paddingRight: 10,
                  }}
                >
                  <strong>{item.price.toFixed(2)}</strong>
                </p>
              </StackItem>
            </>
          ))}
        </>
      ))}
      <StackItem
        style={{
          width: '100%',
          textAlign: 'right',
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          alignItems: 'end',
          marginTop: 20,
          justifyContent: 'space-between',
        }}
      >
        <StackItem
          style={{
            width: '40%',
          }}
        >
          <br />
        </StackItem>

        <StackItem
          style={{
            width: '15%',
            backgroundColor: '#dedfe0',
            padding: 10,
            borderRadius: '10px 0px 10px 0px',
          }}
        >
          <p>
            Totaal:
            <strong
              style={{
                paddingLeft: 20,
              }}
            >
              {total.toFixed(2)}
            </strong>
          </p>
        </StackItem>
      </StackItem>
    </>
  );
};

type Props = {
  costCenter: ICostCenter;
  constructionSite: IConstructionSite;
  incomingInvoiceLines: IIncomingInvoiceLineItem[];
  timeSheetEntries: ITimesheetEntry[];
};

const FinancialAnalysisCostCenter = ({
  costCenter,
  constructionSite,
  incomingInvoiceLines,
  timeSheetEntries,
}: Props) => (
  <StackItem
    style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'start',
          marginBottom: 25,
          border: '1px solid #c9c9c9',
          padding: 0,
          borderRadius: 10,
        }}
  >
    <h3
      style={{
            marginTop: 0,
            marginBottom: 10,
            width: '100%',
            backgroundColor: '#c9c9c9',
            padding: 10,
            borderRadius: '10px 10px 0px 0px',
          }}
    >
      {`Post: ${costCenter.name}`}
    </h3>
    <Lines
      time_sheet_entries={
            costCenter.time_sheet_entries ? costCenter.time_sheet_entries : []
          }
      incoming_invoice_line_items={
            costCenter.incoming_invoice_line_items
              ? costCenter.incoming_invoice_line_items
              : []
          }
    />
  </StackItem>
  );

export default FinancialAnalysisCostCenter;
