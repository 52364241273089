import React, { useCallback, useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Label,
  StackItem,
  Dialog,
  DialogFooter,
  TextField,
  IComboBoxOption,
} from '@fluentui/react';
import _ from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import {
  convertConstructionSitesToComboBoxOptions,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  dialogModelProps,
  IConstructionSite,
  modalContentStyles,
} from '../../../utils';
import { dialogRemoveConfirmationPropsGeneral } from '../../../utils/Styles';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import { toastError, toastSuccess } from '../../../utils/toast';
import { IAsset } from '../../../utils/Asset';
import { DELETE_ASSET_LOG, IAssetLog } from '../../../utils/AssetLog';
import { ComboboxWithFilter } from '../../../components/parts';
import { convertTeamsToComboBoxOptions, ITeam } from '../../../utils/Team';
import DateView from '../../../components/parts/DateView';
import { customPanelStyles } from '../../../theme';

const LogItemDetail = ({
  isOpen,
  dismissPanel,
  logItem,
  setLogItem,
  saveLogItem,
  //
  refetchAsset,
  asset,
  //
  refetchLogItems,
  clearLogItems,
  setAsset,
  //
  constructionSites,
  constructionSiteSearch,
  setConstructionSiteSearch,
  //
  teams,
  teamSearch,
  setTeamSearch,
}: any) => {
  const [isConfirmationHidden, setIsConfirmationHidden] = useState(true);

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setLogItem((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  const [deleteLogItem] = useMutation(DELETE_ASSET_LOG);

  const toggleConfirmationDialog = () => {
    setIsConfirmationHidden(!isConfirmationHidden);
  };

  const deleteLogItemFn = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          deleteLogItem({
            variables: {
              where: {
                id: logItem.id,
              },
            },
            onError: error => {
              dismissPanel();
              toggleConfirmationDialog();
              reject(error);
            },
            onCompleted: async (x: any) => {
              dismissPanel();
              toggleConfirmationDialog();
              await refetchAsset();
              // clearLogItems();
              // await refetchLogItems();
              resolve(x);
            },
          });
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      await res;
      toastSuccess('Log verwijderd');
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  // ===============================================================================================
  // ===============================================================================================
  // ===============================================================================================

  // ===============================================================================================
  // =================================== C O N S T R U C T I O N  ==================================
  // ===============================================================================================

  const setConstructionSite = useCallback(
    (newValue: IComboBoxOption) => {
      if (constructionSites && constructionSites.length > 0 && newValue) {
        const index = constructionSites.findIndex(
          (item: IConstructionSite) => item.id === newValue.key,
        );
        const constructionSite = constructionSites[index];
        if (constructionSite) {
          setLogItem((prevState: any) => ({
            ...prevState,
            construction_site: constructionSite,
            team: undefined,
          }));
        }
      }
    },
    [logItem, constructionSites],
  );

  const getConstructionSitesIncludingPurchaseOrderConstructionSite =
    useCallback(() => {
      if (logItem && logItem.construction_site && constructionSites) {
        const constructionSiteArray = _.cloneDeep(constructionSites);
        const constructionSite = constructionSiteArray.find(
          (e: IConstructionSite) => e.id === logItem.construction_site?.id,
        );
        if (!constructionSite) {
          constructionSiteArray.push(logItem.construction_site);
        }

        constructionSiteArray.sort(
          (obj1: IConstructionSite, obj2: IConstructionSite) => {
            let compareName = 0;
            compareName = obj1.name.localeCompare(obj2.name);

            return compareName;
          },
        );

        return constructionSiteArray;
      }
      if (constructionSites) {
        const constructionSiteArray = _.cloneDeep(constructionSites);
        constructionSiteArray.sort(
          (obj1: IConstructionSite, obj2: IConstructionSite) => {
            let compareName = 0;
            compareName = obj1.name.localeCompare(obj2.name);

            return compareName;
          },
        );

        return constructionSiteArray;
      }
      return [];
    }, [teams, logItem, constructionSites]);

  // ===============================================================================================
  // ===============================================================================================
  // ===============================================================================================

  // ===============================================================================================
  // ==========================================  T E A M  ==========================================
  // ===============================================================================================

  const setTeam = useCallback(
    (newValue: IComboBoxOption) => {
      if (teams && teams.length > 0 && newValue) {
        const index = teams.findIndex(
          (item: ITeam) => item.id === newValue.key,
        );
        const team = teams[index];
        if (team) {
          setLogItem((prevState: any) => ({
            ...prevState,
            team,
            construction_site: undefined,
          }));
        }
      }
    },
    [logItem, teams],
  );

  const getTeams = useCallback(() => {
    if (logItem && logItem.team && teams) {
      const teamArray = _.cloneDeep(teams);
      const team = teamArray.find((e: ITeam) => e.id === logItem.team?.id);
      if (!team) {
        teamArray.push(logItem.team);
      }

      teamArray.sort((obj1: ITeam, obj2: ITeam) => {
        let compareName = 0;
        compareName = obj1.name.localeCompare(obj2.name);

        return compareName;
      });

      return teamArray;
    }
    if (teams) {
      const teamArray = _.cloneDeep(teams);
      teamArray.sort((obj1: ITeam, obj2: ITeam) => {
        let compareName = 0;
        compareName = obj1.name.localeCompare(obj2.name);

        return compareName;
      });

      return teamArray;
    }
    return [];
  }, [teams, logItem, constructionSites]);

  // ===============================================================================================
  // ===============================================================================================
  // ===============================================================================================

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        logItem && logItem.id ? 'Log item wijzigen' : 'Log item toevoegen'
      }
      type={PanelType.custom}
      customWidth='500px'
      styles={customPanelStyles}
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {logItem && logItem?.id && (
            <>
              <Label>ID: {logItem.id}</Label>

              <Stack style={{ marginBottom: 10 }}>
                <Label>Asset: {(asset as IAsset).name}</Label>
              </Stack>
            </>
          )}

          {/* <Stack style={{ marginBottom: 10 }}>
            <SuggestedView
              item={logItem || {}}
              setItem={setLogItem}
              prefix='logItem_title_'
              code={
                logItem &&
                logItem.title &&
                logItem.title.substr(logItem.title.length - 4)
              }
              label='Omschrijving'
              field='title'
            />
          </Stack> */}

          {/* <Stack style={{ marginBottom: 10 }}>
            <Label>Opvolging nodig?</Label>
            <Checkbox
              name='action_required'
              label='Opvolging nodig?'
              checked={!!(logItem && logItem.action_required)}
              onChange={() => {
                setLogItem((prevState: any) => ({
                  ...prevState,
                  action_required:
                    logItem && logItem.action_required
                      ? !logItem.action_required
                      : true,
                }));
              }}
            />
          </Stack> */}

          {/* <Stack style={{ marginBottom: 10 }}>
            <DateView
              item={logItem}
              setItem={setLogItem}
              date={logItem && logItem.action_required_by}
              label='Vervaldatum'
              field='action_required_by'
            />
          </Stack> */}

          <Stack style={{ marginBottom: 10 }}>
            <ComboboxWithFilter
              label='Werf'
              options={convertConstructionSitesToComboBoxOptions(
                getConstructionSitesIncludingPurchaseOrderConstructionSite(),
              )}
              value={
                logItem && logItem.construction_site
                  ? logItem.construction_site.id
                  : ''
              }
              multiline={false}
              callBack={(newValue: IComboBoxOption[]) => {
                if (newValue && newValue.length > 0) {
                  setConstructionSite(newValue[0]);
                }
              }}
              setFilter={(filterValue: string) => {
                setConstructionSiteSearch(filterValue);
              }}
              disabled={logItem?.id && !logItem?.last}
              allowFreeForm
              //   style={{ width: '300px' }}
            />
            {((logItem?.id && logItem?.last) || !logItem?.id) && (
              <p
                style={{
                  cursor: 'pointer',
                  color: '#71BF44',
                  marginTop: 0,
                }}
                onClick={() => {
                  setLogItem({
                    ...logItem,
                    construction_site: undefined,
                  });
                }}
                aria-hidden='true'
              >
                Verwijder
              </p>
            )}
          </Stack>
          <Stack style={{ marginBottom: 10 }}>
            <ComboboxWithFilter
              label='Team'
              options={convertTeamsToComboBoxOptions(getTeams())}
              value={logItem && logItem.team ? logItem.team.id : ''}
              multiline={false}
              callBack={(newValue: IComboBoxOption[]) => {
                if (newValue && newValue.length > 0) {
                  setTeam(newValue[0]);
                }
              }}
              setFilter={(filterValue: string) => {
                setTeamSearch(filterValue);
              }}
              allowFreeForm
              disabled={logItem?.id && !logItem?.last}
              //   style={{ width: '300px' }}
            />
            {((logItem?.id && logItem?.last) || !logItem?.id) && (
              <p
                style={{
                  cursor: 'pointer',
                  color: '#71BF44',
                  marginTop: 0,
                }}
                onClick={() => {
                  setLogItem({
                    ...logItem,
                    team: undefined,
                  });
                }}
                aria-hidden='true'
              >
                Verwijder
              </p>
            )}
          </Stack>

          <Stack style={{ marginBottom: 10 }}>
            <DateView
              item={logItem}
              setItem={setLogItem}
              date={logItem && (logItem as IAssetLog).date_created}
              label='Aangemaakt op'
              field='date_created'
              disabled={logItem?.id && !logItem?.last}
            />
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='comments'
              label='Extra info'
              multiline
              value={
                logItem && (logItem as IAssetLog).comments
                  ? (logItem as IAssetLog).comments
                  : ''
              }
              onChange={onChangeTextFieldValue}
              disabled={logItem?.id && !logItem?.last}
            />
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack
            style={{
              flexDirection: 'row',
              marginTop: 10,
              justifyContent: 'space-between',
            }}
          >
            <Stack style={{ flexDirection: 'row' }}>
              {(!logItem?.id || (logItem.id && logItem.last)) && (
                <SaveButtonWithPermissions
                  disabled={!logItem}
                  save={saveLogItem}
                  permission='write:assetLogs'
                />
              )}

              <StackItem>
                <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
              </StackItem>
            </Stack>

            <Stack>
              {logItem?.id && logItem.last && (
                <StackItem>
                  <DefaultButton onClick={toggleConfirmationDialog}>
                    Verwijderen
                  </DefaultButton>
                </StackItem>
              )}
            </Stack>
          </Stack>
        </div>
      </div>

      <Dialog
        hidden={isConfirmationHidden}
        onDismiss={toggleConfirmationDialog}
        dialogContentProps={dialogRemoveConfirmationPropsGeneral}
        modalProps={dialogModelProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={deleteLogItemFn} text='Verwijderen' />
          <DefaultButton onClick={toggleConfirmationDialog} text='Annuleren' />
        </DialogFooter>
      </Dialog>
    </Panel>
  );
};

export default LogItemDetail;
