import { Stack } from '@fluentui/react';
import { returnFluentIcon } from '../../utils';
import { useAppSelector } from '../../redux/hooks';
import { getPermissions } from '../../redux/permission/permissionSlice';
import Separator from './Separator';
import NavItem from './NavItem';

interface INavbarItem {
  key: string;
  icon: string;
  disableHover: boolean;
  isFavorite: boolean;
  href: string;
  text: string;
  isSeparator: boolean;
  permission?: string;
  permissions?: string[];
}

const NavbarItems: INavbarItem[] = [
  {
    key: '1',
    icon: 'Home',
    disableHover: true,
    isFavorite: false,
    href: '/start',
    text: 'Dashboard',
    isSeparator: false,
  },
  {
    key: '2',
    icon: 'LineChart',
    disableHover: false,
    isFavorite: false,
    href: '',
    text: 'Verkoop',
    isSeparator: true,
    permissions: [
      'nav:leads',
      'nav:logItems',
      'nav:quoteReferences',
      'nav:plans',
    ],
  },
  {
    key: '20',
    icon: 'People',
    disableHover: false,
    isFavorite: false,
    href: '/leads',
    text: 'Prospecten',
    isSeparator: false,
    permission: 'nav:leads',
  },
  // {
  //   key: '21',
  //   icon: 'ConnectContacts',
  //   disableHover: false,
  //   isFavorite: false,
  //   href: '/suspects',
  //   text: 'Suspecten',
  //   isSeparator: false,
  //   permission: 'nav:leads',
  // },
  {
    key: '22',
    icon: 'RecurringTask',
    disableHover: true,
    isFavorite: false,
    href: '/log-items',
    text: 'Logitems',
    isSeparator: false,
    permission: 'nav:logItems',
  },
  {
    key: '23',
    icon: 'DocumentSet',
    disableHover: true,
    isFavorite: false,
    href: '/quotes',
    text: 'Offertes',
    isSeparator: false,
    permission: 'nav:quoteReferences',
  },
  {
    key: '24',
    icon: 'PictureCenter',
    disableHover: false,
    isFavorite: false,
    href: '/example-projects',
    text: 'Plannenbibliotheek',
    isSeparator: false,
    permission: 'nav:plans',
  },
  {
    key: '3',
    icon: 'ExternalBuild',
    disableHover: false,
    isFavorite: false,
    href: '',
    text: 'Werfopvolging',
    isSeparator: true,
    permissions: [
      'nav:constructionSites',
      'nav:purchaseOrders',
      'nav:defectWorkOrders',
      'nav:customerContractUpdates',
      'nav:assets',
    ],
  },
  {
    key: '31',
    icon: 'MapPin',
    disableHover: false,
    isFavorite: false,
    href: '/construction-sites',
    text: 'Werven',
    isSeparator: false,
    permission: 'nav:constructionSites',
  },
  {
    key: '32',
    icon: 'ServerEnviroment',
    disableHover: false,
    isFavorite: false,
    href: '/purchase-orders',
    text: 'Bestelbonnen',
    isSeparator: false,
    permission: 'nav:purchaseOrders',
  },
  {
    key: '33',
    icon: 'ServerEnviroment',
    disableHover: true,
    isFavorite: false,
    href: '/work-orders',
    text: 'Werkbonnen',
    isSeparator: false,
    permission: 'nav:defectWorkOrders',
  },
  {
    key: '34',
    icon: 'ServerEnviroment',
    disableHover: true,
    isFavorite: false,
    href: '/contract-updates',
    text: 'Meerwerkbonnen',
    isSeparator: false,
    permission: 'nav:customerContractUpdates',
  },
  {
    key: '35',
    icon: 'DeveloperTools',
    disableHover: false,
    isFavorite: false,
    href: '/assets',
    text: 'Materiaal inventaris',
    isSeparator: false,
    permission: 'nav:assets',
  },
  // {
  //   key: '4',
  //   icon: 'CRMServices',
  //   disableHover: false,
  //   isFavorite: false,
  //   href: '',
  //   text: 'Naverkoop',
  //   isSeparator: true,
  //   permissions: ['nav:defectWorkOrders'],
  // },
  {
    key: '5',
    icon: 'DiagnosticDataBarTooltip',
    disableHover: false,
    isFavorite: false,
    href: '',
    text: 'Boekhouding',
    isSeparator: true,
    permissions: [
      'nav:customers',
      'nav:customerPaymentTransactions',
      'nav:customerInvoices',
      'nav:customerInvoices',
      'nav:costCenters',
    ],
  },
  {
    key: '51',
    icon: 'TeamFavorite',
    disableHover: false,
    isFavorite: false,
    href: '/customers',
    text: 'Klanten',
    isSeparator: false,
    permission: 'nav:customers',
  },
  {
    key: '55',
    icon: 'ClosePane',
    disableHover: true,
    isFavorite: false,
    href: '/incoming-invoices',
    text: 'Inkomende facturen',
    isSeparator: false,
    permission: 'nav:customerInvoices',
  },
  {
    key: '54',
    icon: 'ClosePaneMirrored',
    disableHover: true,
    isFavorite: false,
    href: '/customer-invoices',
    text: 'Uitgaande facturen',
    isSeparator: false,
    permission: 'nav:customerInvoices',
  },
  {
    key: '52',
    icon: 'PieSingle',
    disableHover: true,
    isFavorite: false,
    href: '/payment-transactions',
    text: 'Betalingsschijven',
    isSeparator: false,
    permission: 'nav:customerPaymentTransactions',
  },
  {
    key: '56',
    icon: 'Dictionary',
    disableHover: true,
    isFavorite: false,
    href: '/cost-centers',
    text: 'Grootboekposten',
    isSeparator: false,
    permission: 'nav:costCenters',
  },
  {
    key: '57',
    icon: 'Coupon',
    disableHover: true,
    isFavorite: false,
    href: '/tax-codes',
    text: 'Belastingcodes',
    isSeparator: false,
    permission: 'nav:costCenters',
  },
  {
    key: '6',
    icon: 'Org',
    disableHover: false,
    isFavorite: false,
    href: '',
    text: 'Organisatie',
    isSeparator: true,
    permissions: [
      'nav:employees',
      'nav:timeSheetEntries',
      'nav:cars',
      'nav:architects',
    ],
  },
  {
    key: '61',
    icon: 'Family',
    disableHover: true,
    isFavorite: false,
    href: '/teams',
    text: 'Ploegen',
    isSeparator: false,
    permission: 'nav:cars',
  },
  {
    key: '62',
    icon: 'CRMServices',
    disableHover: true,
    isFavorite: false,
    href: '/employees',
    text: 'Medewerkers',
    isSeparator: false,
    permission: 'nav:employees',
  },
  {
    key: '63',
    icon: 'AccountManagement',
    disableHover: true,
    isFavorite: false,
    href: '/sales-reps',
    text: 'Verkopers',
    isSeparator: false,
    permission: 'nav:employees',
  },
  {
    key: '64',
    icon: 'UserEvent',
    disableHover: false,
    isFavorite: false,
    href: '/suppliers',
    text: 'Leveranciers',
    isSeparator: false,
    permission: 'nav:suppliers',
  },
  {
    key: '65',
    icon: 'EditContact',
    disableHover: true,
    isFavorite: false,
    href: '/architects',
    text: 'Architecten',
    isSeparator: false,
    permission: 'nav:architects',
  },
  {
    key: '66',
    icon: 'TemporaryUser',
    disableHover: true,
    isFavorite: false,
    href: '/timesheets',
    text: 'Werkuren',
    isSeparator: false,
    permission: 'nav:timeSheetEntries',
  },
  {
    key: '67',
    icon: 'DateTime2',
    disableHover: true,
    isFavorite: false,
    href: '/new-timesheet',
    text: 'Werkuren registreren',
    isSeparator: false,
    permission: 'nav:timeSheetEntries',
  },
  {
    key: '68',
    icon: 'DeliveryTruck',
    disableHover: true,
    isFavorite: false,
    href: '/cars',
    text: 'Wagenpark',
    isSeparator: false,
    permission: 'nav:cars',
  },
  {
    key: '7',
    icon: 'Processing',
    disableHover: false,
    isFavorite: false,
    href: '',
    text: 'Configuratie',
    isSeparator: true,
    permissions: [
      'nav:templates',
      'nav:purchaseOrders',
      'nav:suggestedValueCategories',
      'nav:taxCodes',
    ],
  },
  {
    key: '71',
    icon: 'FilterSettings',
    disableHover: false,
    isFavorite: false,
    href: '/suggested-values',
    text: 'Voorgestelde waarden',
    isSeparator: false,
    permission: 'nav:suggestedValues',
  },
  {
    key: '72',
    icon: 'DocumentManagement',
    disableHover: false,
    isFavorite: false,
    href: '/templates',
    text: 'Werf sjablonen',
    isSeparator: false,
    permission: 'nav:templates',
  },
  {
    key: '73',
    icon: 'DocumentManagement',
    disableHover: false,
    isFavorite: false,
    href: '/purchase-order-templates',
    text: 'Bestelbon sjablonen',
    isSeparator: false,
    permission: 'nav:purchaseOrderTemplates',
  },
  {
    key: '74',
    icon: 'DocumentManagement',
    disableHover: false,
    isFavorite: false,
    href: '/mail-templates',
    text: 'Email sjablonen',
    isSeparator: false,
    permission: 'nav:mailTemplates',
  },
];

const hasOneOfPermissions = (scopes: string[], permissions: string[]) => {
  for (let i = 0; i < scopes.length; i++) {
    if (permissions.includes(scopes[i])) {
      return true;
    }
  }

  return false;
};

const NavbarItemsList = () => {
  const permissions = useAppSelector(getPermissions);

  return (
    <Stack>
      {NavbarItems.map((item: INavbarItem) => {
        if (
          item.isSeparator &&
          (item.permissions
            ? hasOneOfPermissions(item.permissions || [], permissions)
            : true)
        ) {
          return (
            <Separator key={item.key} icon={returnFluentIcon(item.icon)}>
              {item.text}
            </Separator>
          );
        }
        if (permissions.includes(item.permission || '')) {
          return (
            <NavItem
              key={item.key}
              icon={returnFluentIcon(item.icon)}
              href={item.href}
              disableHover={item.disableHover}
              // isFavorite={item.isFavorite}
              navID='test'
            >
              {item.text}
            </NavItem>
          );
        }
        return null;
      })}
    </Stack>
  );
};

export default NavbarItemsList;
