import React, { useState, useEffect } from 'react';
/* eslint-disable react/function-component-definition */
/* eslint-disable no-trailing-spaces */
import { useAuth0 } from '@auth0/auth0-react';
import { PrimaryButton, Stack } from '@fluentui/react';
import { useAppSelector } from '../redux/hooks';
import { getPermissions } from '../redux/permission/permissionSlice';

interface IPrivateRoute {
  // component: any;
  // path: any;
  permission?: string;
}

const checkPermissions = (permissions: string[], permission?: string) => {
  if (!permission) return true;
  if (permissions.includes(permission)) return true;
  return false;
};

const PrivateRoute: React.FC<IPrivateRoute> = ({ permission, children }) => {
  const permissions = useAppSelector(getPermissions);
  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  if (isLoading) return <div>...Laden</div>;
  if (!checkPermissions(permissions, permission)) {
    return (
      <Stack
        styles={{
        root: {
        position: 'fixed', top: 0, left: 0, right: 0, bottom: 0,
        },
        }}
        horizontalAlign='center'
        verticalAlign='center'
      >
        <img src="https://ismartdigital.imgix.net/3bouw.svg" alt="3bouw" width="200px" />
        <h2 style={{ marginTop: 25, textAlign: 'center' }}>Je hebt geen rechten om deze pagina te bekijken.</h2>
      </Stack>
    );
  }
  if (!isAuthenticated) {
    return (
      <Stack
        styles={{
        root: {
          position: 'fixed', top: 70, left: 0, right: 0, bottom: 0,
        },
        }}
        className='test'
        horizontalAlign='center'
        verticalAlign='center'
      >
        <img src="https://ismartdigital.imgix.net/3bouw.svg" alt="3bouw" width="200px" />
        <h2 style={{ marginTop: 25, textAlign: 'center' }}>Meld je aan om gebruik te maken van de applicatie.</h2>
        <PrimaryButton onClick={() => { loginWithRedirect(); }}>Meld je aan</PrimaryButton>
      </Stack>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default PrivateRoute;
