import { gql } from '@apollo/client';
import { ICustomerInvoice } from './CustomerInvoice';
import { ITaxCode } from './TaxCode';

export const GET_CUSTOMER_INVOICE_LINE_ITEMS = gql`
  query getCustomerInvoiceLineItems(
    $filter: CustomerInvoiceLineItemFilterInput
    $take: Int
    $skip: Int
    $orderBy: CustomerInvoiceLineItemOrderByInput
  ) {
    findManyCustomerInvoiceLineItems(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      comments
      customer_invoice_id
      description
      percentage_vat
      show_only_total
      units
      unit_price
      version
      customer_invoice {
        id
      }
      tax_code {
        id
        label
        rate
        description
      }
    }
  }
`;

export const GET_CUSTOMER_INVOICE_LINE_ITEM_BY_ID = gql`
  query getCustomerInvoiceLineItemById(
    $where: CustomerInvoiceLineItemWhereInput
  ) {
    findOneCustomerInvoiceLineItem(where: $where) {
      id
      comments
      customer_invoice_id
      description
      percentage_vat
      show_only_total
      units
      unit_price
      version
      customer_invoice {
        id
      }
      tax_code {
        id
        description
        rate
        label
      }
    }
  }
`;

export const ADD_CUSTOMER_INVOICE_LINE_ITEM = gql`
  mutation AddCustomerInvoiceLineItem(
    $data: CustomerInvoiceLineItemCreateInput!
  ) {
    createCustomerInvoiceLineItem(data: $data) {
      id
      comments
      customer_invoice_id
      description
      percentage_vat
      show_only_total
      units
      unit_price
      version
      customer_invoice {
        id
      }
      tax_code {
        id
        description
        rate
        label
      }
    }
  }
`;

export const UPDATE_CUSTOMER_INVOICE_LINE_ITEM = gql`
  mutation UpdateCustomerInvoiceLineItem(
    $id: Int!
    $data: CustomerInvoiceLineItemUpdateInput!
  ) {
    updateCustomerInvoiceLineItem(id: $id, data: $data) {
      id
      comments
      customer_invoice_id
      description
      percentage_vat
      show_only_total
      units
      unit_price
      version
      customer_invoice {
        id
      }
      tax_code {
        id
        description
        rate
        label
      }
    }
  }
`;

export const DELETE_CUSTOMER_INVOICE_LINE_ITEM = gql`
  mutation DeleteCustomerInvoiceLineItem(
    $where: CustomerInvoiceLineItemWhereInput
  ) {
    deleteCustomerInvoiceLineItem(where: $where) {
      id
    }
  }
`;

export interface ICustomerInvoiceLineItem {
  comments?: string;
  customer_invoice_id?: number;
  description?: string;
  id?: number;
  percentage_vat?: number | string;
  show_only_total?: boolean;
  units?: number | string;
  unit_price?: number | string;
  version?: number;
  customer_invoice?: ICustomerInvoice;
  isNew?: boolean;
  isDirty?: boolean;
  delete?: boolean;
  customer_contract_update_line_item?: ICustomerInvoiceLineItem;
  tax_code?: ITaxCode;
}

export interface ICustomerInvoiceLineItemCreateWithoutCustomerInvoice {
  comments?: string;
  description: string;
  percentage_vat?: number;
  show_only_total?: boolean;
  unit_price?: number;
  units?: number;
  customer_contract_update_line_item?: {
    connect: {
      id: number;
    };
  };
  tax_code?: {
    connect: {
      id: number;
    };
  };
}

export interface ICustomerInvoiceLineItemUpdateWithoutCustomerInvoice {
  comments?: string;
  description?: string;
  percentage_vat?: number | string;
  show_only_total?: boolean;
  unit_price?: number | string;
  units?: number | string;
  tax_code?: {
    connect: {
      id: number;
    };
  };
}
