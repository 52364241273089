import { useAuth0 } from '@auth0/auth0-react';
import { Icon, Stack } from '@fluentui/react';
import React, { useCallback, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useSearchParams } from 'react-router-dom';
import {
  default_page_size,
  GET_PURCHASE_ORDERS,
  IPurchaseOrder,
  stackTokens15,
} from '../../utils';
import useDebounce from '../../components/hooks/useDebounce';
import PurchaseOrdersOverview from './components/PurchaseOrdersOverview';

function PurchaseOrders() {
  const { isAuthenticated } = useAuth0();
  const [purchaseOrders, setPurchaseOrders] = useState<IPurchaseOrder[]>([]);

  const [searchParams] = useSearchParams();
  const [searchField, setSearchField] = useState<string>('');

  const debouncedSearchField = useDebounce(searchField.replace('-', ''), 500);

  const searchFieldQuery = useCallback(() => {
    const query: any = {
      filter: debouncedSearchField
        ? {
            OR: [
              { supplier: { name: { contains: debouncedSearchField } } },
              {
                construction_site: { name: { contains: debouncedSearchField } },
              },
              {
                purchase_order_no: { contains: debouncedSearchField },
              },
            ],
          }
        : undefined,
      take: 50,
      orderBy: {
        creation_date: 'desc',
      },
    };

    return query;
  }, [debouncedSearchField]);

  const construction_site = searchParams.get('constructionSite');
  const redirect = searchParams.get('redirect');
  const getQueryParams = () => {
    if (construction_site && !debouncedSearchField) {
      return {
        filter: {
          construction_site: { id: { equals: +construction_site } },
        },
        orderBy: {
          creation_date: 'desc',
        },
      };
    }
    return {
      ...searchFieldQuery(),
    };
  };

  const { loading, error, refetch } = useQuery(GET_PURCHASE_ORDERS, {
    variables: getQueryParams(),
    onCompleted: (x: any) => setPurchaseOrders(x.findManyPurchaseOrders),
  });

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>oeps er ging iets mis(</p>;

  return (
    <Stack tokens={stackTokens15}>
      <PurchaseOrdersOverview
        orders={purchaseOrders}
        refetch={refetch}
        setPurchaseOrders={setPurchaseOrders}
        showBack={!!construction_site}
        constructionSite={construction_site || null}
        redirectTarget={redirect || null}
        searchField={searchField}
        setSearchField={setSearchField}
      />
    </Stack>
  );
}

export default PurchaseOrders;
