import React, { Children, FormEvent, useState } from 'react';
import {
  Stack,
  TextField,
  DefaultButton,
  PrimaryButton,
  Toggle,
  ComboBox,
  Icon,
  IComboBoxOption,
  IComboBox,
} from '@fluentui/react';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import moment from 'moment';
import { getTheme } from '@fluentui/react/lib/Styling';
import { updateProperty } from 'typescript';
import {
  property_types,
  stackTokens5,
  convertPropertyTypeToComboBoxOptions,
  IPropertyType,
  iconProps,
} from '../../../utils';
import { defaultProperty, IProperty } from '../../../utils/Property';
import {
  GET_SUGGESTED_VALUE_CATEGORIES,
  convertSuggestedValueCategoriesToComboBoxOptions,
  ISuggestedValueCategory,
} from '../../../utils/SuggestedValueCategory';
import { Accordion, AccordionItem } from '../../../components/parts/Accordion';
import TextView from './TextView';
import DocumentView from './DocumentView';
import DateView from './DateView';
import SuggestedValueView from './SuggestedValueView';

const theme = getTheme();

interface IMultiValueView {
  property: IProperty;
  callBack: (data: IProperty) => void;
  types: IPropertyType[];
}
const MultiValueView = ({ callBack, property, types }: IMultiValueView) => {
  /*
  const updateChildProperties = (childProperty: IProperty) => {
    const result = property;
    childProperty.isDirty = true;
    result.isDirty = true;
    result.children = result.children.map((otherChildProperty: IProperty) =>
      otherChildProperty.id === childProperty.id
        ? childProperty
        : otherChildProperty,
    );
    callBack(result);
  };
  */

  const {
    loading: loadingSuggestedValueCategories,
    error: errorSuggestedValueCategories,
    data: dataSuggestedValueCategories,
  } = useQuery(GET_SUGGESTED_VALUE_CATEGORIES, {
    variables: {
      orderBy: {
        category_description: 'asc',
      },
    },
  });

  const [selectedAccordion, setSelectedAccordion] = useState<any>();

  const updateCurrentChild = (index: number, newItem: IProperty) => {
    const currentProperty = _.cloneDeep(property);
    if (
      currentProperty
      && currentProperty.children
      && currentProperty.children[index]
    ) {
      currentProperty.children[index] = newItem;
      currentProperty.isDirty = true;
      callBack(currentProperty);
    }
  };

  const updateMultiValueChild = (
    index: number,
    childIndex: number,
    newItem: IProperty,
  ) => {
    const currentProperty = _.cloneDeep(property);
    if (
      currentProperty
      && currentProperty.children
      && currentProperty.children[index]
    ) {
      if (currentProperty.children[index].children[childIndex]) {
        currentProperty.children[index].isDirty = true;
        currentProperty.children[index].children[childIndex] = newItem;
        currentProperty.isDirty = true;
        callBack(currentProperty);
      }
    }
  };

  const addNewItem = () => {
    const currentProperty = _.cloneDeep(property);
    const newProperty: IProperty = _.cloneDeep(defaultProperty);
    newProperty.id = moment().unix();
    newProperty.isNew = true;
    newProperty.isDirty = true;
    newProperty.needs_water_points = currentProperty.needs_water_points;

    if (!currentProperty.children || property.children.length === 0) {
      newProperty.weight_for_construction_sheet = 1;
    } else if (currentProperty.children) {
      let max = 0;

      for (let i = 0; i < currentProperty.children.length; i++) {
        const child = currentProperty.children[i];
        if (child.weight_for_execution_list > max) max = child.weight_for_execution_list;
      }

      newProperty.weight_for_execution_list = max + 1;
    }

    if (currentProperty.nested_type) {
      newProperty.type = currentProperty.nested_type;
    }

    if (currentProperty.remote_category) {
      newProperty.remote_category = currentProperty.remote_category;
    }

    if (currentProperty.value) {
      newProperty.value = currentProperty.value;
    }

    if (currentProperty.document) {
      newProperty.document = {
        ...currentProperty.document,
        draft: true,
      };
    }

    if (currentProperty.type_children) {
      newProperty.children = [];
      for (let i = 0; i < currentProperty.type_children.length; i++) {
        const typeChild = currentProperty.type_children[i];
        typeChild.isNew = true;
        typeChild.isDirty = true;

        newProperty.children.push(typeChild);
      }
    }

    currentProperty.isDirty = true;

    currentProperty.children.push(newProperty);

    callBack(currentProperty);
  };

  const toggleDeleteItem = (index: number) => {
    const currentProperty = _.cloneDeep(property);
    if (
      currentProperty
      && currentProperty.children
      && currentProperty.children[index]
    ) {
      currentProperty.children[index].delete = !currentProperty.children[index].delete;
      currentProperty.isDirty = true;
      callBack(currentProperty);
    }
  };

  const changeSubType = (index: number, type: IPropertyType) => {
    const currentProperty = _.cloneDeep(property);
    if (
      currentProperty
      && currentProperty.children
      && currentProperty.children[index]
    ) {
      currentProperty.children[index].type = type;
      callBack(currentProperty);
    }
  };

  const changeSubCategory = (
    index: number,
    category: ISuggestedValueCategory,
  ) => {
    const currentProperty = _.cloneDeep(property);
    if (
      currentProperty
      && currentProperty.children
      && currentProperty.children[index]
    ) {
      currentProperty.children[index].remote_category = category.category_code;
      callBack(currentProperty);
    }
  };

  const moveChildDown = (index: number) => {
    const currentItem = _.cloneDeep(property);
    if (currentItem && currentItem.children) {
      const children = currentItem.children;
      const currentChildSortKey = children[index]
        ? children[index].weight_for_execution_list
        : undefined;
      const nextChildSortKey = children[index + 1]
        ? children[index + 1].weight_for_execution_list
        : undefined;

      if (
        typeof currentChildSortKey !== 'undefined'
        && typeof nextChildSortKey !== 'undefined'
      ) {
        children[index].weight_for_execution_list = nextChildSortKey;
        children[index].isDirty = true;
        children[index + 1].weight_for_execution_list = currentChildSortKey;
        children[index + 1].isDirty = true;
      }

      const newChildren = children.sort((a, b) => a.weight_for_execution_list - b.weight_for_execution_list);

      currentItem.children = newChildren;

      callBack(currentItem);
    }
  };

  const moveChildUp = (index: number) => {
    const currentItem = _.cloneDeep(property);
    if (currentItem && currentItem.children) {
      const children = currentItem.children;
      const currentChildSortKey = children[index]
        ? children[index].weight_for_execution_list
        : undefined;
      const prevChildSortKey = children[index - 1]
        ? children[index - 1].weight_for_execution_list
        : undefined;

      if (
        typeof currentChildSortKey !== 'undefined'
        && typeof prevChildSortKey !== 'undefined'
      ) {
        children[index].weight_for_execution_list = prevChildSortKey;
        children[index].isDirty = true;
        children[index - 1].weight_for_execution_list = currentChildSortKey;
        children[index - 1].isDirty = true;
      }

      const newChildren = children.sort((a, b) => a.weight_for_execution_list - b.weight_for_execution_list);

      currentItem.children = newChildren;

      callBack(currentItem);
    }
  };

  const toggleDeleteChild = (index: number) => {
    const currentItem = _.cloneDeep(property);
    currentItem.isDirty = true;
    if (currentItem && currentItem.children) {
      if (currentItem.children[index]) {
        currentItem.children[index].delete = !currentItem.children[index].delete;
        currentItem.children[index].isDirty = true;
      }
    }

    callBack(currentItem);
  };

  const renderChildren = () => property?.children.sort((a, b) => a.weight_for_execution_list - b.weight_for_execution_list).map((childProperty: IProperty, index) => {
    const title = (
      <Stack horizontal>
        <Stack.Item
          onClick={() => {
            if (selectedAccordion === childProperty.id) setSelectedAccordion(undefined);
            else setSelectedAccordion(childProperty.id);
          }}
          style={{
            textDecoration: childProperty.delete
              ? 'line-through'
              : undefined,
            marginRight: '10px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: '200px',
          }}
        >
          {childProperty.isNew && <Icon iconName="LocationDot" />}
          {childProperty.name}
        </Stack.Item>
        <Stack.Item>
          <Icon
            iconName={childProperty.delete ? 'Refresh' : 'Delete'}
            styles={iconProps}
            onClick={() => {
              toggleDeleteChild(index);
            }}
          />
          <Icon
            iconName='Up'
            styles={iconProps}
            onClick={() => {
              moveChildUp(index);
            }}
          />
          <Icon
            iconName='Down'
            styles={iconProps}
            onClick={() => {
              moveChildDown(index);
            }}
          />
        </Stack.Item>
      </Stack>
    );
    switch (childProperty.type.name) {
      case property_types.PARENT:
        return (
          <AccordionItem
            key={childProperty.id}
            id={childProperty.id}
            title={title}
          >
            <Stack.Item style={{ marginBottom: '10px' }}>
              {childProperty.delete && 'dit item zal worden verwijderd.'}
              <TextField
                label='Naam'
                value={childProperty.name}
                  // style={{marginBottom: "10px"}}
                onChange={(
                  event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                  newValue?: string,
                ) => {
                  const result = _.cloneDeep(childProperty);
                  result.name = newValue || '';
                  result.isDirty = true;
                  updateCurrentChild(index, result);
                }}
              />
              <TextField
                label='Opmerking'
                value={childProperty.value}
                  // style={{marginBottom: "10px"}}
                onChange={(
                  event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                  newValue?: string,
                ) => {
                  const result = _.cloneDeep(childProperty);
                  result.value = newValue || '';
                  result.isDirty = true;
                  updateCurrentChild(index, result);
                }}
              />
              <Stack style={{ marginTop: '15px' }}>
                <Toggle
                  onText='Tappunten'
                  offText='Geen tappunten'
                  checked={childProperty.needs_water_points}
                  onChange={(
                    event: React.MouseEvent<HTMLElement>,
                    checked?: boolean,
                  ) => {
                    const result = _.cloneDeep(childProperty);

                    result.needs_water_points = checked || false;
                    result.isDirty = true;
                    updateCurrentChild(index, result);
                  }}
                />
              </Stack>

              {childProperty
                  && childProperty.children
                    .sort(
                      (a, b) => a.weight_for_execution_list
                        - b.weight_for_execution_list,
                    )
                    .map((item, itemIndex) => {
                      if (
                        item.type
                        && item.type.name === property_types.STRING
                      ) {
                        return (
                          <Stack.Item
                            style={{
                              padding: '10px',
                              backgroundColor: theme.palette.neutralLight,
                              marginTop: '10px',
                              marginBottom: '10px',
                            }}
                          >
                            <Stack.Item
                              style={{
                                fontWeight: 'bold',
                                marginBottom: '5px',
                              }}
                            >
                              {item.name}
                            </Stack.Item>
                            <TextView
                              key={item.id}
                              property={item}
                              callBack={(data) => updateMultiValueChild(index, itemIndex, data)
                              }
                            />
                          </Stack.Item>
                        );
                      } if (
                        item.type
                        && item.type.name === property_types.NOTE
                      ) {
                        return (
                          <Stack.Item
                            style={{
                              padding: '10px',
                              backgroundColor: theme.palette.neutralLight,
                              marginTop: '10px',
                              marginBottom: '10px',
                            }}
                          >
                            <Stack.Item
                              style={{
                                fontWeight: 'bold',
                                marginBottom: '5px',
                              }}
                            >
                              {item.name}
                            </Stack.Item>
                            <TextView
                              key={item.id}
                              property={item}
                              callBack={(data) => updateMultiValueChild(index, itemIndex, data)
                              }
                            />
                          </Stack.Item>
                        );
                      } if (
                        item.type
                        && item.type.name === property_types.DATE
                      ) {
                        return (
                          <Stack.Item
                            style={{
                              padding: '10px',
                              backgroundColor: theme.palette.neutralLight,
                              marginTop: '10px',
                              marginBottom: '10px',
                            }}
                          >
                            <Stack.Item
                              style={{
                                fontWeight: 'bold',
                                marginBottom: '5px',
                              }}
                            >
                              {item.name}
                            </Stack.Item>
                            <DateView
                              key={item.id}
                              property={item}
                              callBack={(data) => updateMultiValueChild(index, itemIndex, data)
                              }
                            />
                          </Stack.Item>
                        );
                      } if (
                        item.type
                        && item.type.name === property_types.DOCUMENT
                      ) {
                        return (
                          <Stack.Item
                            style={{
                              padding: '10px',
                              backgroundColor: theme.palette.neutralLight,
                              marginTop: '10px',
                              marginBottom: '10px',
                            }}
                          >
                            <Stack.Item
                              style={{
                                fontWeight: 'bold',
                                marginBottom: '5px',
                              }}
                            >
                              {item.name}
                            </Stack.Item>
                            <DocumentView
                              key={item.id}
                              property={item}
                              callBack={(data) => updateMultiValueChild(index, itemIndex, data)
                              }
                            />
                          </Stack.Item>
                        );
                      } if (
                        item.type
                        && item.type.name === property_types.SUGGESTED_VALUE
                      ) {
                        return (
                          <Stack.Item
                            style={{
                              padding: '10px',
                              backgroundColor: theme.palette.neutralLight,
                              marginTop: '10px',
                              marginBottom: '10px',
                            }}
                          >
                            <Stack.Item
                              style={{
                                fontWeight: 'bold',
                                marginBottom: '5px',
                              }}
                            >
                              {item.name}
                            </Stack.Item>
                            <SuggestedValueView
                              key={item.id}
                              property={item}
                              callBack={(data) => updateMultiValueChild(index, itemIndex, data)
                              }
                            />
                          </Stack.Item>
                        );
                      }
                    })}
              <Stack.Item style={{ marginTop: '10px' }}>
                <PrimaryButton
                  onClick={() => {
                    // setShowPanel(false);
                    toggleDeleteItem(index);
                  }}
                  text={
                      childProperty.delete
                        ? 'Verwijderen ongedaan maken'
                        : 'Verwijderen'
                    }
                />
              </Stack.Item>
            </Stack.Item>
          </AccordionItem>
        );

        break;
      case property_types.STRING:
      case property_types.NOTE:
        return (
          <AccordionItem
            key={childProperty.id}
            id={childProperty.id}
            title={title}
          >
            <Stack
              style={{
                background: theme.palette.neutralLighterAlt,
                padding: '10px',
                marginBottom: '10px',
                opacity: childProperty.delete ? 0.5 : 1,
              }}
            >
              {childProperty.isNew && (
              <ComboBox
                    // componentRef={comboboxPropertyType}
                selectedKey={
                      childProperty.type ? childProperty.type.id : undefined
                    }
                    // disabled={!!item.id && !item.draft && !item.editType}
                label='Type'
                allowFreeform
                autoComplete='on'
                onChange={(event, option) => {
                  if (option) {
                    const newItem = _.cloneDeep(property);
                    // setSelectedKeyPropertyType(+option.key);
                    const selectedType = types.filter((t) => {
                      if (t.id === +option.key) return true;
                    })[0];
                    changeSubType(index, selectedType);
                  }
                }}
                options={convertPropertyTypeToComboBoxOptions(
                  types.filter((item: IPropertyType) => {
                    if (
                      item.name === 'parent'
                          || item.name === 'free-multivalue'
                          || item.name === 'multi-value'
                    ) {
                      return false;
                    }
                    return true;
                  }),
                )}
                required
              />
              )}
              <Stack.Item style={{ marginBottom: '10px' }}>
                {childProperty.delete && 'dit item zal worden verwijderd.'}
                <TextField
                  label='Naam'
                  value={childProperty.name}
                    // style={{marginBottom: "10px"}}
                  onChange={(
                    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                    newValue?: string,
                  ) => {
                    const result = _.cloneDeep(childProperty);
                    result.name = newValue || '';
                    result.isDirty = true;
                    updateCurrentChild(index, result);
                  }}
                />
              </Stack.Item>
              <Stack style={{ marginTop: '15px' }}>
                <Toggle
                  onText='Tappunten'
                  offText='Geen tappunten'
                  checked={childProperty.needs_water_points}
                  onChange={(
                    event: React.MouseEvent<HTMLElement>,
                    checked?: boolean,
                  ) => {
                    const result = _.cloneDeep(childProperty);
                    result.isDirty = true;
                    result.needs_water_points = checked || false;

                    updateCurrentChild(index, result);
                  }}
                />
              </Stack>
              <TextView
                key={childProperty.id}
                property={childProperty}
                callBack={(data) => updateCurrentChild(index, data)}
              />
              <Stack.Item style={{ marginTop: '10px' }}>
                <PrimaryButton
                  onClick={() => {
                    // setShowPanel(false);
                    toggleDeleteItem(index);
                  }}
                  text={
                      childProperty.delete
                        ? 'Verwijderen ongedaan maken'
                        : 'Verwijderen'
                    }
                />
              </Stack.Item>
            </Stack>
          </AccordionItem>
        );
      case 'free-multivalue':
        return (
          <AccordionItem
            key={childProperty.id}
            id={childProperty.id}
            title={title}
          >
            <ComboBox
                // componentRef={comboboxPropertyType}
              selectedKey={
                  childProperty.type ? childProperty.type.id : undefined
                }
                // disabled={!!item.id && !item.draft && !item.editType}
              label='Type'
              allowFreeform
              autoComplete='on'
              onChange={(event, option) => {
                if (option) {
                  const newItem = _.cloneDeep(property);
                  // setSelectedKeyPropertyType(+option.key);
                  const selectedType = types.filter((t) => {
                    if (t.id === +option.key) return true;
                  })[0];
                  changeSubType(index, selectedType);
                }
              }}
              options={convertPropertyTypeToComboBoxOptions(
                types.filter((item: IPropertyType) => {
                  if (
                    item.name === 'parent'
                      || item.name === 'free-multivalue'
                      || item.name === 'multi-value'
                  ) {
                    return false;
                  }
                  return true;
                }),
              )}
              required
            />
          </AccordionItem>
        );
      case property_types.DATE:
        return (
          <AccordionItem
            key={childProperty.id}
            id={childProperty.id}
            title={title}
          >
            <Stack
              style={{
                background: theme.palette.neutralLighterAlt,
                padding: '10px',
                marginBottom: '10px',
                opacity: childProperty.delete ? 0.5 : 1,
              }}
            >
              {childProperty.isNew && (
              <ComboBox
                    // componentRef={comboboxPropertyType}
                selectedKey={
                      childProperty.type ? childProperty.type.id : undefined
                    }
                    // disabled={!!item.id && !item.draft && !item.editType}
                label='Type'
                allowFreeform
                autoComplete='on'
                onChange={(event, option) => {
                  if (option) {
                    const newItem = _.cloneDeep(property);
                    // setSelectedKeyPropertyType(+option.key);
                    const selectedType = types.filter((t) => {
                      if (t.id === +option.key) return true;
                    })[0];
                    changeSubType(index, selectedType);
                  }
                }}
                options={convertPropertyTypeToComboBoxOptions(
                  types.filter((item: IPropertyType) => {
                    if (
                      item.name === 'parent'
                          || item.name === 'free-multivalue'
                          || item.name === 'multi-value'
                    ) {
                      return false;
                    }
                    return true;
                  }),
                )}
                required
              />
              )}

              <Stack.Item style={{ marginBottom: '10px' }}>
                {childProperty.delete && 'dit item zal worden verwijderd.'}
                <TextField
                  label='Naam'
                  value={childProperty.name}
                    // style={{marginBottom: "10px"}}
                  onChange={(
                    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                    newValue?: string,
                  ) => {
                    const result = _.cloneDeep(childProperty);
                    result.name = newValue || '';
                    result.isDirty = true;
                    updateCurrentChild(index, result);
                  }}
                />
                <Stack.Item style={{ marginTop: '10px' }}>
                  <DateView
                    key={childProperty.id}
                    property={childProperty}
                    callBack={(data) => updateCurrentChild(index, data)}
                  />
                </Stack.Item>

                <Stack.Item style={{ marginTop: '10px' }}>
                  <PrimaryButton
                    onClick={() => {
                      // setShowPanel(false);
                      toggleDeleteItem(index);
                    }}
                    text={
                        childProperty.delete
                          ? 'Verwijderen ongedaan maken'
                          : 'Verwijderen'
                      }
                  />
                </Stack.Item>
              </Stack.Item>
            </Stack>
          </AccordionItem>
        );
      case property_types.DOCUMENT:
        return (
          <AccordionItem
            key={childProperty.id}
            id={childProperty.id}
            title={title}
          >
            <Stack
              style={{
                background: theme.palette.neutralLighterAlt,
                padding: '10px',
                marginBottom: '10px',
                opacity: childProperty.delete ? 0.5 : 1,
              }}
            >
              {childProperty.isNew && (
              <ComboBox
                    // componentRef={comboboxPropertyType}
                selectedKey={
                      childProperty.type ? childProperty.type.id : undefined
                    }
                    // disabled={!!item.id && !item.draft && !item.editType}
                label='Type'
                allowFreeform
                autoComplete='on'
                onChange={(event, option) => {
                  if (option) {
                    const newItem = _.cloneDeep(property);
                    // setSelectedKeyPropertyType(+option.key);
                    const selectedType = types.filter((t) => {
                      if (t.id === +option.key) return true;
                    })[0];
                    changeSubType(index, selectedType);
                  }
                }}
                options={convertPropertyTypeToComboBoxOptions(
                  types.filter((item: IPropertyType) => {
                    if (
                      item.name === 'parent'
                          || item.name === 'free-multivalue'
                          || item.name === 'multi-value'
                    ) {
                      return false;
                    }
                    return true;
                  }),
                )}
                required
              />
              )}
              <Stack.Item style={{ marginBottom: '10px' }}>
                {childProperty.delete && 'dit item zal worden verwijderd.'}
                <TextField
                  label='Naam'
                  value={childProperty.name}
                    // style={{marginBottom: "10px"}}
                  onChange={(
                    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                    newValue?: string,
                  ) => {
                    const result = _.cloneDeep(childProperty);
                    result.name = newValue || '';
                    result.isDirty = true;
                    updateCurrentChild(index, result);
                  }}
                />
              </Stack.Item>

              <DocumentView
                key={childProperty.id}
                property={childProperty}
                callBack={(data) => updateCurrentChild(index, data)}
              />
              <Stack.Item style={{ marginTop: '10px' }}>
                <PrimaryButton
                  onClick={() => {
                    // setShowPanel(false);
                    toggleDeleteItem(index);
                  }}
                  text={
                      childProperty.delete
                        ? 'Verwijderen ongedaan maken'
                        : 'Verwijderen'
                    }
                />
              </Stack.Item>
            </Stack>
          </AccordionItem>
        );
      case property_types.SUGGESTED_VALUE:
        return (
          <AccordionItem
            key={childProperty.id}
            id={childProperty.id}
            title={title}
          >
            <Stack
              style={{
                background: theme.palette.neutralLighterAlt,
                padding: '10px',
                marginBottom: '10px',
                opacity: childProperty.delete ? 0.5 : 1,
              }}
            >
              {childProperty.isNew && (
              <ComboBox
                    // componentRef={comboboxPropertyType}
                selectedKey={
                      childProperty.type ? childProperty.type.id : undefined
                    }
                    // disabled={!!item.id && !item.draft && !item.editType}
                label='Type'
                allowFreeform
                autoComplete='on'
                onChange={(event, option) => {
                  if (option) {
                    const newItem = _.cloneDeep(property);
                    // setSelectedKeyPropertyType(+option.key);
                    const selectedType = types.filter((t) => {
                      if (t.id === +option.key) return true;
                    })[0];
                    changeSubType(index, selectedType);
                  }
                }}
                options={convertPropertyTypeToComboBoxOptions(
                  types.filter((item: IPropertyType) => {
                    if (
                      item.name === 'parent'
                          || item.name === 'free-multivalue'
                          || item.name === 'multi-value'
                    ) {
                      return false;
                    }
                    return true;
                  }),
                )}
                required
              />
              )}
              <Stack.Item style={{ marginBottom: '10px' }}>
                {childProperty.delete && 'dit item zal worden verwijderd.'}
                <TextField
                  label='Naam'
                  value={childProperty.name}
                    // style={{marginBottom: "10px"}}
                  onChange={(
                    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                    newValue?: string,
                  ) => {
                    const result = _.cloneDeep(childProperty);
                    result.name = newValue || '';
                    result.isDirty = true;
                    updateCurrentChild(index, result);
                  }}
                />
              </Stack.Item>
              <Stack style={{ marginTop: '15px' }}>
                <Toggle
                  onText='Tappunten'
                  offText='Geen tappunten'
                  checked={childProperty.needs_water_points}
                  onChange={(
                    event: React.MouseEvent<HTMLElement>,
                    checked?: boolean,
                  ) => {
                    const result = _.cloneDeep(childProperty);

                    result.needs_water_points = checked || false;
                    result.isDirty = true;
                    updateCurrentChild(index, result);
                  }}
                />
              </Stack>

              <ComboBox
                  // componentRef={comboboxPropertyType}
                selectedKey={
                    childProperty.remote_category
                    && dataSuggestedValueCategories
                      ? dataSuggestedValueCategories.findManySuggestedValueCategories.filter(
                        (filterItem: ISuggestedValueCategory) => {
                          if (
                            filterItem.category_code
                              === childProperty.remote_category
                          ) return true;
                          return false;
                        },
                      )[0].id
                      : undefined
                  }
                  // disabled={!!item.id && !item.draft && !item.editType}
                label='Voorgestelde waarde categorie'
                autoComplete='on'
                onChange={(event, option) => {
                  if (option && dataSuggestedValueCategories) {
                    const newItem = _.cloneDeep(property);
                    // setSelectedKeyPropertyType(+option.key);
                    const selectedCategory = dataSuggestedValueCategories.findManySuggestedValueCategories.filter(
                      (t: ISuggestedValueCategory) => {
                        if (t.id === +option.key) return true;
                      },
                    )[0];
                    changeSubCategory(index, selectedCategory);
                  }
                }}
                options={
                    dataSuggestedValueCategories
                      ? convertSuggestedValueCategoriesToComboBoxOptions(
                        dataSuggestedValueCategories.findManySuggestedValueCategories,
                      )
                      : []
                  }
                disabled={!childProperty.isNew}
              />
              {childProperty.remote_category && (
              <SuggestedValueView
                key={childProperty.id}
                property={childProperty}
                callBack={(data) => updateCurrentChild(index, data)}
              />
              )}

              <Stack.Item style={{ marginTop: '10px' }}>
                <PrimaryButton
                  onClick={() => {
                    // setShowPanel(false);
                    toggleDeleteItem(index);
                  }}
                  text={
                      childProperty.delete
                        ? 'Verwijderen ongedaan maken'
                        : 'Verwijderen'
                    }
                />
              </Stack.Item>
            </Stack>
          </AccordionItem>
        );
      default:
        return <span>Error</span>;
    }
  });

  return (
    <Stack tokens={stackTokens5}>
      <TextField
        value={property.value || ''}
        label="Waarde"
        multiline
        onChange={(
          event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string,
        ) => {
          const newItem = { ...property };
          newItem.value = newValue || '';
          newItem.isDirty = true;
          callBack(newItem);
        }}
      />
      <h3>Items</h3>
      <Accordion
        selectedKey={selectedAccordion}
        defaultKey={undefined}
        toggleItem={(key: string | number) => {
          if (selectedAccordion === key) setSelectedAccordion(undefined);
          else setSelectedAccordion(key);
        }}
      >
        {renderChildren()}
      </Accordion>
      <DefaultButton
        onClick={() => {
          // setShowPanel(false);
          addNewItem();
        }}
        text='Item toevoegen'
      />
    </Stack>
  );
};

export default MultiValueView;
