import {
 FormEvent, useCallback, useEffect, useState,
} from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import {
  IComboBoxOption,
  Icon,
  Label,
  PrimaryButton,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import moment from 'moment';
import useDebounce from '../../components/hooks/useDebounce';
import {
  convertEmployeesToComboBoxOptions,
  convertSalesRepsToComboBoxOptions,
  default_page_size,
  GET_EMPLOYEES,
  GET_SALES_REPS,
  ISalesRep,
  normalizeString,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import {
  GET_QUOTE_REFERENCES,
  IQuoteReference,
} from '../../utils/QuoteReference';
import { ComboboxWithFilter } from '../../components/parts';
import DateView from '../../components/parts/DateView';
import { useAppDispatch } from '../../redux/hooks';
import { SeverityLevel, throwError } from '../../redux/error/errorSlice';
import { sendNotification } from '../../redux/notification/notificationSlice';
import QuotesOverview from './components/QuotesOverview';

interface IPagedQuote {
  quotes: IQuoteReference[];
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  lazyLoading: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

const Quotes = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [pagedState, setPagedState] = useState<IPagedQuote>({
    quotes: [],
    skip: 0,
    take: default_page_size,
    filter: '',
    showLoadMore: true,
    isFiltered: false,
    lazyLoading: true,
    initialLoad: true,
    isSortedAsc: false,
    sortedField: 'quote_no',
  });

  const [filter, setFilter] = useState('');
  const [salesRep, setSalesRep] = useState<number>(-1);
  // const [salesReps, setSalesReps] = useState<ISalesRep[]>([]);
  const [dateFilter, setDateFilter] = useState<{
    start_date?: string;
    end_date?: string;
  }>({
    start_date: moment().subtract(1, 'year').toDate().toISOString(),
    end_date: undefined,
  });

  const everyonefilter: IComboBoxOption[] = [{ key: -1, text: 'Iedereen' }];

  const { data: salesRepData } = useQuery(GET_EMPLOYEES, {
    variables: {
      filter: {
        sales_rep: true,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    setPagedState(prevState => ({ ...prevState, quotes: [], skip: 0 }));
  }, [filter, dateFilter, salesRep]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const getFilters = useCallback(
    (filterString: string) => {
      const filterArray = filterString.split(' ');
      const filters: any = {
        AND: [],
      };

      for (let i = 0; i < filterArray.length; i++) {
        const filterValue: any = {
          OR: [
            { quote_no: { contains: filterArray[i] } },
            { description: { contains: filterArray[i] } },
            {
              lead: {
                OR: [
                  { first_name1: { contains: filterArray[i] } },
                  { last_name1: { contains: filterArray[i] } },
                  { first_name2: { contains: filterArray[i] } },
                  { last_name2: { contains: filterArray[i] } },
                  { normalized_first_name1: { contains: filterArray[i] } },
                  { normalized_first_name2: { contains: filterArray[i] } },
                  { normalized_last_name1: { contains: filterArray[i] } },
                  { normalized_last_name2: { contains: filterArray[i] } },
                  {
                    normalized_first_name1: {
                      contains: normalizeString(filterArray[i]),
                    },
                  },
                  {
                    normalized_first_name2: {
                      contains: normalizeString(filterArray[i]),
                    },
                  },
                  {
                    normalized_last_name1: {
                      contains: normalizeString(filterArray[i]),
                    },
                  },
                  {
                    normalized_last_name2: {
                      contains: normalizeString(filterArray[i]),
                    },
                  },
                  {
                    sales_rep: {
                      OR: [
                        { first_name: { contains: filterArray[i] } },
                        { last_name: { contains: filterArray[i] } },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        };

        filters.AND.push(filterValue);
      }

      if (salesRep) {
        filters.AND.push({
          lead: {
            sales_rep_employee: {
              id: {
                equals:
                  salesRep !== 0 && salesRep !== -1 ? salesRep : undefined,
              },
            },
          },
        });
      }

      if (dateFilter && dateFilter.end_date && !dateFilter.start_date) {
        filters.AND.push({
          quote_date: {
            lte: dateFilter.end_date ? dateFilter.end_date : undefined,
          },
        });
      } else if (dateFilter && dateFilter.start_date && !dateFilter.end_date) {
        filters.AND.push({
          quote_date: {
            gte: dateFilter.start_date ? dateFilter.start_date : undefined,
          },
        });
      } else if (dateFilter) {
        filters.AND.push({
          quote_date: {
            gte: dateFilter.start_date ? dateFilter.start_date : undefined,
          },
        });
        filters.AND.push({
          quote_date: {
            lte: dateFilter.end_date ? dateFilter.end_date : undefined,
          },
        });
      }

      return filters;
    },
    [salesRep, dateFilter],
  );

  const {
    data: dataQuoteReferences,
    loading,
    error,
    fetchMore,
  } = useQuery(GET_QUOTE_REFERENCES, {
    // notifyOnNetworkStatusChange: true,
    variables: {
      filter: getFilters(debouncedValue),
      take: pagedState.take,
      skip: pagedState.skip,
      orderBy: getOrderBy(),
    },
    onError: error => {
      dispatch(
        throwError({
          module: 'car Overview',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
    // fetchPolicy: 'cache-and-network', comment, we want to use default fetchPolicy
  });

  const downloadFile = useCallback(async () => {
    dispatch(
      sendNotification({
        message: 'bezig met downloaden',
        level: 0,
        module: 'quotes.overview',
        spinner: true,
      }),
    );
    const url = `${process.env.REACT_APP_BACKEND_URI}/files/quotes/xls`;
    const token = await getAccessTokenSilently();
    const fileName = `quotes_${moment().format('YYYYMMDD')}.xlsx`;
    const res = await fetch(url, {
      method: 'post',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ filter: getFilters(debouncedValue) }),
    });

    const result = await res.blob();

    const aElement = document.createElement('a');
    aElement.setAttribute('download', fileName);
    const href = URL.createObjectURL(result);
    aElement.href = href;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(href);

    dispatch(
      sendNotification({
        module: 'quotes.overview',
        message: 'Bestand is gedownload',
        level: 1,
        timeout: 2500,
      }),
    );
  }, [debouncedValue, getFilters]);

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    clearQuotes();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const clearQuotes = () => {
    setPagedState(prevState => ({
      ...prevState,
      quotes: [],
      skip: 0,
    }));
  };

  const fetchMoreQuotes = () => {
    // Show more guard

    fetchMore({
      variables: {
        filter: getFilters(debouncedValue),
        take: pagedState.take,
        skip: pagedState.skip + pagedState.take,
        orderBy: getOrderBy(),
      },
    });

    setPagedState(prevState => ({
      ...prevState,
      skip: pagedState.skip + pagedState.take,
    }));
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <div style={{ padding: '25px' }}>
        <h3
          style={{
            fontSize: '20px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Icon iconName='DocumentSet' style={{ fontSize: '20px' }} />
          Offertes
        </h3>

        <Stack
          style={{ flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <StackItem>
            <Stack style={{ flexDirection: 'row', marginBottom: 5 }}>
              {salesRepData &&
                salesRepData.findManyEmployees &&
                salesRepData.findManyEmployees.length > 0 && (
                  <StackItem style={{ width: 300, marginRight: 25 }}>
                    <ComboboxWithFilter
                      label='Verkoper'
                      options={[
                        ...convertEmployeesToComboBoxOptions(
                          salesRepData.findManyEmployees,
                        ),
                        ...everyonefilter,
                      ]}
                      value={salesRep || ''}
                      callBack={(newValue: IComboBoxOption[]) => {
                        if (newValue && newValue.length > 0) {
                          if (newValue[0].text === 'Iedereen') {
                            setSalesRep(-1);
                            clearQuotes();
                            // refetchQuotes();
                          } else {
                            setSalesRep(newValue[0].key as number);
                            clearQuotes();
                            // refetchQuotes();
                          }
                        }
                      }}
                    />
                  </StackItem>
                )}
            </Stack>

            <Stack
              style={{
                flexDirection: 'row',
              }}
            >
              <Stack style={{ marginBottom: 5, marginRight: 15 }}>
                <TextField
                  label='Zoeken...'
                  value={filter}
                  styles={textFieldStyles300}
                  onChange={onSearchValueChange}
                />
              </Stack>
              <Stack style={{ marginBottom: 5, marginRight: 15 }}>
                <DateView
                  item={dateFilter}
                  setItem={setDateFilter}
                  date={dateFilter && dateFilter.start_date}
                  label='Startdatum'
                  field='start_date'
                  onChangeDate={() => clearQuotes()}
                />
              </Stack>

              <Stack style={{ marginBottom: 5 }}>
                <DateView
                  item={dateFilter}
                  setItem={setDateFilter}
                  date={dateFilter && dateFilter.end_date}
                  label='Einddatum'
                  field='end_date'
                  onChangeDate={() => clearQuotes()}
                />
              </Stack>
            </Stack>
          </StackItem>
        </Stack>
      </div>

      <QuotesOverview
        quotes={
          dataQuoteReferences ? dataQuoteReferences.findManyQuoteReferences : []
        }
        loading={loading}
        setSorting={setSorting}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        showLoadMore={
          dataQuoteReferences &&
          dataQuoteReferences.findManyQuoteReferences &&
          dataQuoteReferences.findManyQuoteReferences.length >=
            pagedState.take + pagedState.skip
        }
        loadMore={!(dateFilter.end_date && dateFilter.start_date)}
        loadMoreCallback={fetchMoreQuotes}
        lazyLoading={pagedState.lazyLoading}
        downloadFile={downloadFile}
        // isReport={isReport}
      />
    </Stack>
  );
};

export default Quotes;
