import {
 PanelType, Stack, Panel, PrimaryButton, Icon,
} from '@fluentui/react';
import { PDFViewer } from '@react-pdf/renderer';
import { useQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { GET_PURCHASE_ORDER_BY_ID, IPurchaseOrder } from '../../../utils';
import { customPanelStyles } from '../../../theme';
import { toastError } from '../../../utils/toast';
import PurchaseOrderDownloadTemplate from './PurchaseOrderDownloadTemplate';
import purchaseOrderDownload from './PurchaseOrderDownload';

const PurchaseOrdersDynamicPdf = ({ purchaseOrderSource, setShowPdf }: any) => {
  const [purchaseOrder, setPurchaseOrder] = useState<
    IPurchaseOrder | undefined
  >();

  const { loading, error } = useQuery(GET_PURCHASE_ORDER_BY_ID, {
    variables: {
      where: {
        id: purchaseOrderSource.id,
      },
    },
    fetchPolicy: 'no-cache',
    onError: (error: { message: any }) => {
      toastError(error.message);
    },
    onCompleted: (data: any) => {
      setPurchaseOrder(data.findOnePurchaseOrder);
    },
  });

  return (
    <Panel
      isOpen
      onDismiss={() => setShowPdf(false)}
      closeButtonAriaLabel='Close'
      headerText='PDF Bekijken'
      type={PanelType.custom}
      customWidth='100%'
      styles={customPanelStyles}
    >
      {!loading && !error && purchaseOrder && (
        <Stack
          styles={{
            root: {
              position: 'fixed',
              top: 60,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'white',
              marginTop: '0!important',
              display: 'flex',
              padding: '20px',
            },
          }}
        >
          <Stack.Item styles={{ root: { paddingBottom: 10 } }}>
            <PrimaryButton
              onClick={() => {
                purchaseOrderDownload(purchaseOrder);
              }}
            >
              Download <Icon style={{ marginLeft: 7 }} iconName='PDF' />
            </PrimaryButton>
          </Stack.Item>
          <Stack.Item styles={{ root: { flex: 1 } }}>
            <PDFViewer width='100%' height='100%'>
              <PurchaseOrderDownloadTemplate purchaseOrder={purchaseOrder} />
            </PDFViewer>
          </Stack.Item>
        </Stack>
      )}
    </Panel>
  );
};

export default PurchaseOrdersDynamicPdf;
