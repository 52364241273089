import React, { FormEvent } from 'react';
import { ITextFieldStyles, Stack, TextField } from '@fluentui/react';
import _ from 'lodash';
import {
  IConstructionSheetRemark,
  stackTokens15,
  textFieldStyles1000,
} from '../../../utils';

export const textFieldStylesMax: Partial<ITextFieldStyles> = {
  fieldGroup: { width: 1000 },
};

const ConstructionListRemarks = ({
  remark,
  callBackRemark,
  show,
  name,
}: any) => {
  const handleInputChange = (e: any) => {
    callBackRemark(e.target.value, name);
  };

  return show ? (
    <Stack tokens={stackTokens15}>
      <TextField
        key={323}
        label='Interne opmerkingen'
        value={remark}
        multiline
        rows={5}
        onChange={handleInputChange}
      />
    </Stack>
  ) : null;
};
// Functions
// const updateValues = (id: number, newValue?: string) => {
//   const newValues = remarks.map((el: IConstructionSheetRemark) => {
//     const remark = _.cloneDeep(el);
//     if (remark.id === id && newValue) remark.remarks = newValue;
//     return remark;
//   });
//   callBackRemarks(newValues);
// };

export default ConstructionListRemarks;
