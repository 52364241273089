import {
  getTheme,
  loadTheme,
  mergeStyleSets,
  IStackTokens,
  IStackStyles,
  IDropdownStyles,
  ITextFieldStyles,
  FontWeights,
  DialogType,
  IIconProps,
  IIconStyles,
} from '@fluentui/react';
import { FontSizes } from '@uifabric/fluent-theme/lib/fluent/FluentType';
import themeOverrides from '../theme';

loadTheme(themeOverrides);

export const theme = getTheme();
export const classes = mergeStyleSets({
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    minHeight: '100vh',
  },
  navigation: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    minHeight: '100vh',
    zIndex: 7777,
  },
  menu: {
    position: 'absolute',
    maxWidth: '300px',
    width: '100%',
    minHeight: '100vh',
    height: '100%',
    overflowY: 'auto',
    backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent white
    backdropFilter: 'blur(10px)', // Adjust blur level as desired
    borderTopRightRadius: '8px', // Radius for top-right corner
    borderBottomRightRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional: subtle shadow
  },
  dismiss: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgb(0 0 0 / 28%)',
    opacity: 1,
    zIndex: 555,
  },
  toggleButton: {
    display: 'flex',
    paddingLeft: '16px',
    selectors: {
      ':hover': {
        background: theme.palette.themeTertiary,
      },
    },
  },
  item: {
    position: 'relative',
    display: 'flex',
    paddingLeft: '16px',
    selectors: {
      ':hover': {
        background: theme.palette.themeTertiary,
        color: 'rgba(113,191,68,1)',
        a: {
          color: 'rgba(113,191,68,1)',
        },
      },
    },
  },
  innerItem: {
    paddingTop: '8px',
    paddingBottom: '8px',
    fontSize: FontSizes.size14,
    lineHeight: FontSizes.size14,
    color: 'inherit',
    width: '100%',
    display: 'flex',
    flexGrow: '1',
    marginRight: '8px',
    selectors: {
      ':hover': {
        textDecoration: 'none',
      },
    },
  },
  icon: {
    minWidth: '20px',
    paddingRight: theme.spacing.m,
    pointerEvents: 'none',
  },
  title: {
    width: '100%',
    minHeight: '16px',
    paddingTop: '8px',
    paddingBottom: '8px',
    fontSize: FontSizes.size14,
    lineHeight: FontSizes.size14,
    position: 'relative',
    selectors: {
      i: {
        paddingRight: '8px',
      },
    },
  },
  titleContent: {
    width: 'fit-content',
    marginLeft: theme.spacing.m,
    padding: '8px',

    backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent white
    backdropFilter: 'blur(10px)', // Adjust blur level as desired
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional: subtle shadow

    color: theme.palette.neutralTertiary,
  },
  titleBar: {
    position: 'absolute',
    width: '100%',
    top: '50%',
    borderBottom: `solid 1px ${theme.palette.neutralTertiary}`,
  },
  favoriteStar: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    color: '#6d1f80',
    selectors: {
      ':hover': {
        cursor: 'pointer',
      },
    },
  },
});

export const overviewClassNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px',
  },
  fileIconCell: {
    textAlign: 'center',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
      },
    },
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px',
  },
  selectionDetails: {
    marginBottom: '20px',
  },
});

export const modalContentStyles = mergeStyleSets({
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  footer: {
    padding: '0 24px 24px 24px',
  },
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: 700,
  },
});

export const callOutClasses = mergeStyleSets({
  buttonArea: {
    verticalAlign: 'top',
    display: 'inline-block',
    textAlign: 'center',
    margin: '0 0px',
    minWidth: 75,
    height: 32,
  },
  buttonArea2: {
    cursor: 'pointer',
    textAlign: 'center',
    margin: '12px 4px',
  },
  callout: {
    maxWidth: 300,
  },
  header: {
    padding: '18px 24px 12px',
  },
  title: [
    theme.fonts.xLarge,
    {
      margin: 0,
      fontWeight: FontWeights.semilight,
    },
  ],
  inner: {
    height: '100%',
    padding: '0px 24px 20px',
  },
  actions: {
    position: 'relative',
    marginTop: 20,
    width: '100%',
    whiteSpace: 'nowrap',
  },
  subtext: [
    theme.fonts.small,
    {
      margin: 0,
      fontWeight: FontWeights.semilight,
    },
  ],
  link: [
    theme.fonts.medium,
    {
      color: theme.palette.neutralPrimary,
    },
  ],
});

export const overviewControlStyles = {
  root: {
    margin: '0 30px 20px 0',
    maxWidth: '300px',
  },
};

export const paddingRight3 = { paddingRight: 7 };

export const iconProps: IIconStyles = {
  root: {
    paddingRight: 7,
    cursor: 'pointer',
    selectors: {
      ':hover': {
        color: theme.palette.themePrimary,
      },
    },
  },
};

export const iconLastProps: IIconStyles = {
  root: {
    cursor: 'pointer',
    selectors: {
      ':hover': {
        color: theme.palette.themePrimary,
      },
    },
  },
};

export const columnStyles = mergeStyleSets({
  centerContents: {
    textAlign: 'center',
    display: 'flex!important',
    alignItems: 'center',
  },
});

export const stackTokens5: IStackTokens = { childrenGap: 5 };
export const stackTokens10: IStackTokens = { childrenGap: 10 };
export const stackTokens15: IStackTokens = { childrenGap: 15 };
export const stackTokens20: IStackTokens = { childrenGap: 20 };
export const stackTokens50: IStackTokens = { childrenGap: 50 };

export const stackStyles500: Partial<IStackStyles> = { root: { width: 500 } };
export const stackStyles: Partial<IStackStyles> = { root: { width: 1000 } };

export const dropdownStyles150: Partial<IDropdownStyles> = {
  dropdown: { width: 150 },
};
export const dropdownStyles350: Partial<IDropdownStyles> = {
  dropdown: { width: 350 },
};

export const textFieldStyles300: Partial<ITextFieldStyles> = {
  fieldGroup: { width: 300 },
};
export const textFieldStyles350: Partial<ITextFieldStyles> = {
  fieldGroup: { width: 350 },
};
export const textFieldStyles500: Partial<ITextFieldStyles> = {
  fieldGroup: { width: 500 },
};
export const textFieldStyles1000: Partial<ITextFieldStyles> = {
  fieldGroup: { width: 1000 },
};

export const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px',
    textAlign: 'right',
  },
  fileIconCell: {
    textAlign: 'right',
    fontSize: '14px',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        maxWidth: '16px',
        visibility: 'hidden',
      },
    },
  },
});

export const dialogModelProps = {
  isBlocking: false,
  styles: { main: { maxWidth: 450 } },
};

export const dialogRemoveConfirmationPropsGeneral = {
  type: DialogType.largeHeader,
  title: 'Verwijder item',
  subText:
    'Bent u zeker dat u dit item wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden!',
};

export const dialogRemoveConfirmationProps = {
  type: DialogType.largeHeader,
  title: 'Verwijder sjabloon',
  subText:
    'Bent u zeker dat u het sjabloon wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden!',
};

export const dialogRemovePurchaseOrderConfirmationProps = {
  type: DialogType.largeHeader,
  title: 'Verwijder bestelbon',
  subText:
    'Bent u zeker dat u de bestelbon wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden!',
};

export const dialogRemoveCustomerConfirmationProps = {
  type: DialogType.largeHeader,
  title: 'Verwijder klant',
  subText:
    'Bent u zeker dat u de klant wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden!',
};

export const dialogRemoveQuoteConfirmationProps = {
  type: DialogType.largeHeader,
  title: 'Verwijder offerte',
  subText:
    'Bent u zeker dat u de offerte wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden!',
};

export const cancelIcon: IIconProps = { iconName: 'Cancel' };
export const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
