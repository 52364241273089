import React from 'react';
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  MessageBar,
  MessageBarType,
  Text,
  SelectionMode,
} from '@fluentui/react';

interface CustomerInvoice {
  id: number;
  invoice_no: string;
  // Other properties of CustomerInvoice can be added here as needed
}

interface CustomerPaymentTransaction {
  id: number;
  percentage: number;
  description: string;
  customer_invoices: CustomerInvoice[];
}

interface PaymentItem {
  customer_payment_transactions: string[]; // Array of JSON stringified objects
  percentage: number;
  customer_invoice?: CustomerInvoice; // Optional top-level invoice property
}

// Helper function to safely parse JSON strings
function safeParseJSON<T>(jsonString: string): T | null {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    console.error('Failed to parse JSON:', error);
    return null;
  }
}

// Component to display a single payment transaction item
const PaymentTransactionList: React.FC<{ transactions: string[] }> = ({
  transactions,
}) => (
  <div>
    {transactions.map((transactionStr, index) => {
      const transaction =
        safeParseJSON<CustomerPaymentTransaction>(transactionStr);
      if (!transaction) {
        return (
          <MessageBar messageBarType={MessageBarType.error} key={index}>
            Error parsing transaction data.
          </MessageBar>
        );
      }

      const {
 id, percentage, description, customer_invoices,
} = transaction;

      return (
        <div
          key={id}
          style={{
            marginBottom: '8px',
            padding: '8px',
            border: '1px solid #000',
            borderRadius: '2px',
          }}
        >
          <Text variant='small'> {description}</Text>
          <Text variant='small'>Percentage: {percentage}%</Text>
          {customer_invoices.length > 0 ? (
            <Text variant='small'>
              {' '}
              [Factuur:{' '}
              {customer_invoices.map(inv => inv.invoice_no).join(', ')}]
            </Text>
          ) : (
            <Text variant='small' />
          )}
        </div>
      );
    })}
  </div>
);

// Main component to list all items and their transactions
const PaymentList: React.FC<{ items: PaymentItem[] }> = ({ items }) => {
  const columns: IColumn[] = [
    {
      key: 'column1',
      name: 'Percentage',
      fieldName: 'percentage',
      minWidth: 25,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: 'column2',
      name: 'Betalingsschijf',
      fieldName: 'transactions',
      minWidth: 300,
      isMultiline: true,
    },
  ];

  // Transform items data for DetailsList
  const transformedItems = items.map((item, index) => ({
    key: index.toString(),
    percentage: `${item.percentage}%`,
    transactions: (
      <PaymentTransactionList
        transactions={item.customer_payment_transactions}
      />
    ),
    invoice: item.customer_invoice ? (
      <Text variant='small'>
        Nr{' '}
        <a
          href={`/customer-invoices/${item.customer_invoice.id}`}
          target='_blank'
          rel='noreferrer'
          style={{ width: '20%', color: '#71BF43' }}
        >
          {item.customer_invoice.invoice_no}
        </a>
      </Text>
    ) : (
      <Text variant='small' />
    ),
  }));

  return (
    <DetailsList
      items={transformedItems}
      columns={[
        ...columns,
        {
          key: 'column3',
          name: 'Factuur',
          fieldName: 'invoice',
          minWidth: 100,
          isMultiline: true,
        },
      ]}
      layoutMode={DetailsListLayoutMode.justified}
      selectionMode={SelectionMode.none} // Disable selection dots
      onRenderItemColumn={(item, index, column) => {
        switch (column?.key) {
          case 'column2':
            return item.transactions;
          case 'column3':
            return item.invoice;
          default:
            return <span>{item[column?.fieldName as keyof typeof item]}</span>;
        }
      }}
    />
  );
};

export default PaymentList;
