import {
     DetailsListLayoutMode, IColumn, IObjectWithKey, ISelection, SelectionMode,
} from '@fluentui/react';
import moment from 'moment';
import { useState } from 'react';
import { DetailsListDefault } from '../../../components/parts';
import { ICustomerContractUpdate } from '../../../utils/CustomerContractUpdate';

interface props {
    items: ICustomerContractUpdate[],
    selection?: ISelection<IObjectWithKey> | undefined,
    onItemInvoked?: () => void;
    enableShimmer?: boolean;
    isSortedAsc?: boolean;
    sortedField: string;
    setSorting: (isDesc: boolean, field: string) => void;
    loadMore?: boolean;
    lazyLoading?: boolean;
    loading?: boolean;
    loadMoreCallback?: () => void;
}

const CustomerContractUpdatesDetailsList = ({
    items,
    selection,
    onItemInvoked,
    enableShimmer = false,
    isSortedAsc = false,
    sortedField,
    setSorting, // = (isSortedAsc: boolean, sortedField: string) => {},
    loadMore,
    lazyLoading,
    loading,
    loadMoreCallback,
  }: props) => {
    // On Column Click
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;

          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });
      setColumns(newColumns);

      setSorting(!sortDescending, fieldName);
    }
  };

    // Column List
    const columnsList = [
        {
          key: 'column1',
          name: 'Document no',
          fieldName: 'document_no',
          minWidth: 75,
          maxWidth: 75,
          isRowHeader: true,
          onRender: (customerContractUpdate: ICustomerContractUpdate) => (
            <span>{customerContractUpdate.document_no}</span>
          ),
          onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column2',
          name: 'Omschrijving',
          fieldName: 'summary',
          minWidth: 400,
          maxWidth: 400,
          isRowHeader: true,
          onRender: (customerContractUpdate: ICustomerContractUpdate) => (
            <span>{customerContractUpdate.summary}</span>
          ),
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column4',
          name: 'Werf',
          fieldName: 'construction_site',
          minWidth: 325,
          maxWidth: 325,
          isRowHeader: true,
          onRender: (customerContractUpdate: ICustomerContractUpdate) => (
            <span>{customerContractUpdate.construction_site?.name}</span>
          ),
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column5',
          name: 'Datum',
          fieldName: 'document_date',
          minWidth: 80,
          maxWidth: 80,
          isRowHeader: true,
          onRender: (customerContractUpdate: ICustomerContractUpdate) => (
            <span>
              {moment(customerContractUpdate.document_date).format('YYYY-MM-DD')}
            </span>
          ),
          onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column6',
          name: 'Status',
          fieldName: 'status',
          minWidth: 80,
          maxWidth: 80,
          isRowHeader: true,
          onColumnClick,
          onRender: (customerContractUpdate: ICustomerContractUpdate) => {
            switch (customerContractUpdate.status) {
              case 'NEW':
                return <span>Nieuw</span>;
              case 'CANCELLED':
                return <span>Geannuleerd</span>;
              case 'INVOICED':
                return <span>Op factuur</span>;
              case 'COMPLETED':
                return <span>Afgesloten</span>;
              default:
                return <span>{customerContractUpdate.status}</span>;
            }
          },
          data: 'string',
          isPadded: true,
        },
    ];

    const initColumns = (sortedField: string, isSortedAsc: boolean) => columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

    const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

    return (
      <DetailsListDefault
        items={items}
        columns={columns}
        layoutMode={DetailsListLayoutMode.justified}
        selection={selection}
        selectionMode={SelectionMode.single}
        selectionPreservedOnEmptyClick
        onItemInvoked={onItemInvoked}
        enableShimmer={enableShimmer}
        loadMore={loadMore}
        lazyLoading={lazyLoading}
        loadMoreCallback={loadMoreCallback}
        loading={loading}
      />
    );
  };

  export default CustomerContractUpdatesDetailsList;
