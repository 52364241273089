import {
  Checkbox,
  DetailsListLayoutMode,
  IColumn,
  IObjectWithKey,
  ISelection,
  SelectionMode,
} from '@fluentui/react';
import { useState } from 'react';
import { DetailsListDefault } from '../../../components/parts';
import { ICar } from '../../../utils/Car';
import { ITeam } from '../../../utils/Team';

interface props {
  items: ITeam[];
  selection?: ISelection<IObjectWithKey> | undefined;
  onItemInvoked?: () => void;
  enableShimmer?: boolean;
  isSortedAsc?: boolean;
  sortedField: string;
  setSorting: (isDesc: boolean, field: string) => void;
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
}

const TeamsDetailsList = ({
  items,
  selection,
  onItemInvoked,
  enableShimmer = false,
  isSortedAsc = false,
  sortedField,
  setSorting, // = (isSortedAsc: boolean, sortedField: string) => {},
  loadMore,
  lazyLoading,
  loading,
  loadMoreCallback,
}: props) => {
  // On Column Click
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;

          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });

      setColumns(newColumns);
      setSorting(!sortDescending, fieldName);
    }
  };

  // Column List
  const columnsList = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'name',
      minWidth: 500,
      maxWidth: 500,
      isRowHeader: true,
      onRender: (team: ITeam) => <span>{team.name}</span>,
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column12',
      name: 'Beschrijving',
      fieldName: 'description',
      minWidth: 200,
      isRowHeader: true,
      onRender: (team: ITeam) => <span>{team.description}</span>,
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'Actief?',
      fieldName: 'active',
      minWidth: 200,
      isRowHeader: true,
      onRender: (team: ITeam) => (
        <span>
          <Checkbox disabled checked={!!team.active} />
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) =>
    columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  return (
    <DetailsListDefault
      items={items}
      columns={columns}
      layoutMode={DetailsListLayoutMode.justified}
      selection={selection}
      selectionMode={SelectionMode.single}
      selectionPreservedOnEmptyClick
      onItemInvoked={onItemInvoked}
      enableShimmer={enableShimmer}
      loadMore={loadMore}
      lazyLoading={lazyLoading}
      loadMoreCallback={loadMoreCallback}
      loading={loading}
    />
  );
};

export default TeamsDetailsList;
