import { useState, useEffect } from 'react';
import {
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  Stack,
} from '@fluentui/react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { FiAlertCircle } from 'react-icons/fi';
import {
  ADD_ARCHITECT,
  displayNameArchitect,
  GET_ARCHITECT_BY_ID,
  UPDATE_ARCHITECT,
  IArchitect,
  GET_ARCHITECTS,
} from '../../../utils/Architect';
import { commandBarTheme, commandBarThemeCounter } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  GET_SALES_REPS,
} from '../../../utils';
import { toastError, toastSuccess } from '../../../utils/toast';
import ArchitectDetail from './ArchitectDetail';
import ArchitectsDetailsList from './ArchitectsDetailsList';

interface Props {
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
  architects: IArchitect[];
  setSorting: (isSortedAsc: boolean, sortedField: string) => void;
  isSortedAsc?: boolean;
  sortedField?: string;
  showLoadMore?: boolean;
}

const ArchitectsOverview = ({
  architects,
  loading,
  loadMore,
  setSorting = (isSortedAsc: boolean, sortedField: string) => {},
  isSortedAsc = false,
  sortedField = '',
  showLoadMore,
  lazyLoading,
  loadMoreCallback = () => ({}),
}: Props) => {
  const [architect, setArchitect] = useState<IArchitect | undefined>(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [salesReps, setSalesReps] = useState([]);

  const [addArchitect] = useMutation(ADD_ARCHITECT);

  const [modifyArchitect] = useMutation(UPDATE_ARCHITECT);

  useQuery(GET_SALES_REPS, {
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      setSalesReps(x.findManySalesReps);
    },
  });

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<
    IArchitect | undefined
  >();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const openArchitectDetail = (newArchitect?: boolean) => {
    if (selectionDetails && !newArchitect) {
      // getArchitect({ variables: { where: { id: selectionDetails.id } } });
    } else {
      setArchitect(undefined);
    }
    setIsPanelOpen(true);
  };

  useQuery(GET_ARCHITECT_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: selectionDetails
      ? { where: { id: selectionDetails.id } }
      : undefined,
    skip: !selectionDetails,
    onCompleted: data => {
      setArchitect(data.findOneArchitect);
    },
  });

  const saveArchitect = async () => {
    try {
      if (architect) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              first_name: architect.first_name,
              last_name: architect.last_name,
              company: architect.company,
              address: architect.adress,
              zip_code: architect.zip_code ? +architect.zip_code : undefined,
              city: architect.city,
              mobile_v2: architect.mobile_v2,
              phone_v2: architect.phone_v2,
              email: architect.email,
              website: architect.website,
              comments: architect.comments,
            };

            if (architect.id) {
              modifyArchitect({
                variables: {
                  id: architect.id,
                  data: allInput,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: (x: any) => {
                  setIsPanelOpen(false);
                  resolve(x);
                  toastSuccess('Architect gewijzigd');
                },
              });
            } else {
              addArchitect({
                variables: {
                  data: allInput,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: (x: any) => {
                  setIsPanelOpen(false);
                  if (x && x.createArchitect) {
                    setArchitect(x.createArchitect);
                    resolve(x);
                  }
                  resolve(x);
                  toastSuccess('Architect gewijzigd');
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'totaal',
      text: `${architects.length} / ${architects.length > 0 ? architects[0].prisma_total : 0}`,
      theme: commandBarThemeCounter,
    },
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openArchitectDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openArchitectDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
  ];

  useEffect(() => {
    if (architect && isPanelOpen) {
      document.title = `3bouw | Architect - ${displayNameArchitect(architect)}`;
    } else {
      document.title = '3bouw | Architecten';
    }
  }, [architect, isPanelOpen]);

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        maxWidth='2000px'
      />

      <ArchitectDetail
        isOpen={isPanelOpen}
        dismissPanel={() => {
          setIsPanelOpen(false);
        }}
        architect={architect}
        saveArchitect={saveArchitect}
        setArchitect={setArchitect}
        salesReps={salesReps}
      />

      {architects && (
        <>
          <ArchitectsDetailsList
            items={architects}
            selection={selection}
            enableShimmer={!loading}
            isSortedAsc={isSortedAsc}
            sortedField={sortedField}
            setSorting={setSorting}
            loadMore={loadMore}
            lazyLoading={lazyLoading}
            loadMoreCallback={loadMoreCallback}
            loading={loading}
            onItemInvoked={() => openArchitectDetail(false)}
          />
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton
                text='Toon meer'
                onClick={() => loadMoreCallback()}
              />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {!loading && !showLoadMore && architects.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default ArchitectsOverview;
