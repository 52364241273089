import { useAuth0 } from '@auth0/auth0-react';
import { ICommandBarItemProps } from '@fluentui/react';
import { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { CalloutDefault, CommandBarSticky } from '../../../components/parts';
import { commandBarTheme } from '../../../theme';
import { callOutClasses } from '../../../utils';
import { useAppSelector } from '../../../redux/hooks';
import { getPermissions } from '../../../redux/permission/permissionSlice';

const TemplateCommandBar = ({
  addItem,
  addExistingItem,
  callOutContinue,
  callOutCondition,
  modifyItem,
  modifyDisabled = false,
  deleteItem,
  deleteDisabled = false,
  moveItemDown,
  moveDownDisabled,
  moveItemUp,
  moveUpDisbaled = false,
  saveTemplate,
}: any) => {
  const permissions = useAppSelector(getPermissions);

  // constants
  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: 'newItem',
      text: 'Voeg item toe',
      cacheKey: 'addPropertyCache',
      iconProps: { iconName: 'Add' },
      theme: commandBarTheme,
      subMenuProps: {
        items: [
          {
            key: 'newNewItem',
            text: 'Nieuw item',
            iconProps: { iconName: 'Add' },
            onClick: () => addItem(),
            theme: commandBarTheme,
          },
          {
            key: 'existingItem',
            text: 'Bestaand item',
            iconProps: { iconName: 'ComplianceAudit' },
            onClick: () => addExistingItem(),
            theme: commandBarTheme,
          },
        ],
      },
    },
    {
      key: 'modifyItem',
      text: 'Wijzig item',
      cacheKey: 'modifyPropertyCache',
      iconProps: { iconName: 'Edit' },
      onClick: () => modifyItem(),
      theme: commandBarTheme,
      disabled: modifyDisabled,
    },
    {
      key: 'deleteItem',
      text: 'Verwijder item',
      cacheKey: 'deletePropertyCache',
      iconProps: { iconName: 'Delete' },
      onClick: () => deleteItem(),
      theme: commandBarTheme,
      disabled: deleteDisabled,
    },
    {
      key: 'moveItemDown',
      text: 'Omlaag',
      cacheKey: 'movePropertyDownCache',
      iconProps: { iconName: 'Down' },
      onClick: () => moveItemDown(),
      theme: commandBarTheme,
      disabled: moveDownDisabled(),
    },
    {
      key: 'moveItemUp',
      text: 'Omhoog',
      cacheKey: 'movePropertyUpCache',
      iconProps: { iconName: 'Up' },
      onClick: () => moveItemUp(),
      theme: commandBarTheme,
      disabled: moveUpDisbaled(),
    },
  ];

  const commandBarFarItems: ICommandBarItemProps[] = [
    {
      key: 'toTemplates',
      text: 'Sjabloonoverzicht',
      iconProps: { iconName: 'Back' },
      onClick: () => toggleCallOut(),
      theme: commandBarTheme,
      // onRender: () => (
      //   <div className={callOutClasses.buttonArea2} onClick={toggleCallOut}>
      //     <Stack horizontal tokens={stackTokens5} verticalAlign='center'>
      //       <StackItem>
      //         <Icon
      //           iconName='back'
      //           styles={{ root: { color: theme.palette.themePrimary } }}
      //         />
      //       </StackItem>
      //       <StackItem>Sjabloonoverzicht</StackItem>
      //     </Stack>
      //   </div>
      // ),
    },
    {
      key: 'saveTemplate',
      text: 'Opslaan',
      cacheKey: 'saveTemplateCache',
      iconProps: { iconName: 'Save' },
      onClick: () => saveTemplate(),
      theme: commandBarTheme,
      disabled: !permissions.includes('write:templates'),
    },
  ];

  // Hooks
  const [isCallOutVisible, setIsCallOutVisible] = useState(false);

  // Functions
  const hideCallOut = () => {
    setIsCallOutVisible(false);
  };

  const toggleCallOut = () => {
    if (callOutCondition()) {
      setIsCallOutVisible(true);
    } else {
      callOutContinue();
    }
  };

  return (
    <div>
      <CommandBarSticky
        items={commandBarItems}
        farItems={commandBarFarItems}
        theme={commandBarTheme}
        width='2000px'
      />

      <CalloutDefault
        actionConfirm={callOutContinue}
        actionCancel={hideCallOut}
        actionText='Ga door'
        cancelText='Verder werken'
        message='Alle niet opgeslagen wijzigingen gaan verloren!'
        target={`.${callOutClasses.buttonArea2}`}
        title='Bent u zeker?'
        visible={isCallOutVisible}
      />
    </div>
  );
};

export default TemplateCommandBar;
