import React, { useCallback, useEffect, useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  Stack,
  Label,
  StackItem,
  TextField,
  Checkbox,
  SelectionMode,
  Selection,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  ShimmeredDetailsList,
  DetailsListLayoutMode,
} from '@fluentui/react';
import _ from 'lodash';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { useBoolean } from '@fluentui/react-hooks';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  displayNameEmployee,
  IEmployee,
  modalContentStyles,
} from '../../../utils';
import { Accordion, AccordionItem } from '../../../components/parts/Accordion';
import { commandBarTheme, customPanelStyles } from '../../../theme';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import { toastError, toastSuccess } from '../../../utils/toast';
import {
  ADD_TEAM_MEMBER,
  DELETE_TEAM_MEMBER,
  GET_TEAM_MEMBER_BY_ID,
  ITeamMember,
  UPDATE_TEAM_MEMBER,
} from '../../../utils/TeamMember';
import { DELETE_TEAM } from '../../../utils/Team';
import DeleteButtonWithPermissions from '../../../components/parts/DeleteButtonWithPermissions';
import { CommandBarSticky } from '../../../components/parts';
import TeamMemberDetail from './MemberDetail';

interface Props {
  isOpen: any;
  dismissPanel: any;
  saveTeam: any;
  team: any;
  setTeam: any;
  refetchTeam: any;
  employees: IEmployee[];
  refetch: any;
}

const TeamDetail = ({
  isOpen,
  dismissPanel,
  saveTeam,
  team,
  setTeam,
  refetchTeam,
  employees,
  refetch,
}: Props) => {
  const [selectedValueItem, setSelectedValueItem] = useState<
    number | string | undefined
  >();

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setTeam((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  const [isTeamMemberPanelOpen, setIsTeamMemberPanelOpen] = useState(false);
  const [teamMember, setTeamMember] = useState<ITeamMember | undefined>(
    undefined,
  );

  const [addTeamMember] = useMutation(ADD_TEAM_MEMBER);

  const [modifyTeamMember] = useMutation(UPDATE_TEAM_MEMBER);

  const [deleteTeamMember] = useMutation(DELETE_TEAM_MEMBER);

  const deleteTeamMemberFn = async (id: number) => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          deleteTeamMember({
            variables: {
              where: {
                id,
              },
            },
            onError: error => {
              reject(error);
            },
            onCompleted: x => {
              resolve(x);
            },
          });
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      await res;
      toastSuccess('Werknemer verwijderd');
      refetchTeam();
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  // ==============================================================================

  const [deleteDialogOpen, { toggle: toggleDelete }] = useBoolean(false);
  const [deleteTeam] = useMutation(DELETE_TEAM);

  const deleteTeamFn = async () => {
    try {
      const res = toast.promise(
        new Promise((resolve, reject) => {
          deleteTeam({
            variables: {
              where: {
                id: team.id,
              },
            },
            onError: error => {
              dismissPanel();
              toggleDelete();
              reject(error);
            },
            onCompleted: async (x: any) => {
              dismissPanel();
              toggleDelete();
              refetch();
              setTeam(undefined);
              await refetch();
              resolve(x);
            },
          });
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      await res;
      toastSuccess('Ploeg verwijderd');
    } catch (error: any) {
      toastError(error.message ? error.message : error);
    }
  };

  // Selection
  const [selectedTeamMemberID, setSelectedTeamMemberID] = useState<
    number | undefined
  >();

  useEffect(() => {
    if (team.team_members && team.team_members.length === 1) {
      setSelectedTeamMemberID(team.team_members[0].id);
    }
  }, [team]);

  const getMemberSelectionDetails = () => {
    const currentSelection: any = memberSelection.getSelection();

    if (currentSelection.length > 0) {
      setSelectedTeamMemberID(currentSelection[0].id);
    } else {
      setSelectedTeamMemberID(undefined);
    }
  };

  const memberSelection = new Selection({
    onSelectionChanged: getMemberSelectionDetails,
  });

  const openTeamMemberDetail = (newTeamMember?: boolean) => {
    if (newTeamMember) {
      setTeamMember(undefined);
      setSelectedTeamMemberID(undefined);
    }
    setIsTeamMemberPanelOpen(true);
  };

  useQuery(GET_TEAM_MEMBER_BY_ID, {
    variables: selectedTeamMemberID
      ? { where: { id: selectedTeamMemberID } }
      : undefined,
    skip: !selectedTeamMemberID,
    onCompleted: data => {
      setTeamMember(data.findOneTeamMember);
    },
  });

  const saveTeamMember = async () => {
    try {
      if (teamMember) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              employee: teamMember.employee && {
                connect: { id: teamMember.employee.id },
              },
              team: {
                connect: {
                  id: team.id,
                },
              },
            };

            if (teamMember.id) {
              modifyTeamMember({
                variables: {
                  id: teamMember.id,
                  data: allInput,
                },
                onError: (error: any) => {
                  reject(error);
                },
                onCompleted: async (x: any) => {
                  setIsTeamMemberPanelOpen(false);
                  await refetchTeam();
                  resolve(x);
                  toastSuccess('Ploeg gewijzigd');
                },
              });
            } else {
              addTeamMember({
                variables: {
                  data: allInput,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: async (x: any) => {
                  setIsTeamMemberPanelOpen(false);
                  await refetchTeam();
                  resolve(x);
                  toastSuccess('Ploeg gewijzigd');
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  const isComplete = useCallback(() => {
    if (!team) return false;
    if (!team.name) return false;
    return true;
  }, [team]);

  // =============================

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={team && team.id ? 'Ploeg wijzigen' : 'Ploeg toevoegen'}
      type={PanelType.custom}
      customWidth='900px'
      styles={customPanelStyles}
    >
      {deleteDialogOpen && (
        <>
          {team.team_members.length > 0 ? (
            <Dialog
              hidden={!deleteDialogOpen}
              onDismiss={toggleDelete}
              dialogContentProps={{
                type: DialogType.normal,
                title: `Ploeg ${team.name} verwijderen`,
                closeButtonAriaLabel: 'Close',
              }}
            >
              <p>
                <strong>Kan ploeg niet verwijderen</strong>
                <p>
                  De geselecteerde ploeg is een ploeg met werknemers en of
                  materiaal logs
                </p>
              </p>
              <DialogFooter>
                <DefaultButton text='Annuleren' onClick={toggleDelete} />
              </DialogFooter>
            </Dialog>
          ) : (
            <Dialog
              hidden={!deleteDialogOpen}
              onDismiss={toggleDelete}
              dialogContentProps={{
                type: DialogType.normal,
                title: `Ploeg ${team.name} verwijderen`,
                closeButtonAriaLabel: 'Close',
              }}
            >
              <p>
                <strong>Ben je zeker dat je wil verwijderen?</strong>
              </p>
              <p>Deze actie kan niet ongedaan gemaakt worden.</p>
              <DialogFooter>
                <PrimaryButton text='Verwijderen' onClick={deleteTeamFn} />
                <DefaultButton text='Annuleren' onClick={toggleDelete} />
              </DialogFooter>
            </Dialog>
          )}
          <p />
        </>
      )}

      {isTeamMemberPanelOpen && (
        <TeamMemberDetail
          isOpen={isTeamMemberPanelOpen}
          dismissPanel={() => {
            setIsTeamMemberPanelOpen(false);
          }}
          teamMember={teamMember}
          saveTeamMember={saveTeamMember}
          setTeamMember={setTeamMember}
          refetchTeam={refetchTeam}
          employees={employees}
        />
      )}
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {team && <Label>ID: {team.id}</Label>}

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 10,
            }}
          >
            <StackItem style={{ width: '48%' }}>
              <h3 style={{ marginTop: 0, marginBottom: 10 }}>Algemene info</h3>

              <Stack
                style={{
                  marginBottom: 15,
                }}
              >
                <TextField
                  name='name'
                  label='Naam'
                  value={team && team.name ? team.name : ''}
                  onChange={onChangeTextFieldValue}
                  required
                  errorMessage={
                    team && !team.name ? 'Dit veld is verplicht' : ''
                  }
                />
              </Stack>

              <Stack
                style={{
                  marginBottom: 15,
                }}
              >
                <TextField
                  name='description'
                  label='Beschrijving'
                  value={team && team.description ? team.description : ''}
                  onChange={onChangeTextFieldValue}
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <Checkbox
                  name='active'
                  label='Active?'
                  checked={team && team.active}
                  defaultChecked={team && team.active}
                  onChange={() => {
                    setTeam((prevState: any) => ({
                      ...prevState,
                      active: team.active ? !team.active : true,
                    }));
                  }}
                />
              </Stack>

              {/* <Stack style={{ marginBottom: 10 }}>
                {team && team.team_members && (
                  <Stack style={{ marginBottom: 10 }}>
                    <Label>Werknemers:</Label>{' '}
                    {team.team_members.map((team_member: any) => (
                      <span>{`${team_member.employee.last_name} ${team_member.employee.first_name}`}</span>
                    ))}
                  </Stack>
                )}
              </Stack> */}
            </StackItem>
          </Stack>

          <Stack style={{ marginTop: 30, marginBottom: 10 }}>
            {team && team.id ? (
              <Accordion
                selectedKey={selectedValueItem}
                defaultKey={undefined}
                toggleItem={(key: string | number) => {
                  if (selectedValueItem === key) {
                    setSelectedValueItem(undefined);
                  } else setSelectedValueItem(key);
                }}
              >
                {team &&
                  team.id && ( // only show members when team is created
                    <AccordionItem
                      key='accordion-team-member'
                      id='accordion-team-member'
                      title={
                        <Stack>
                          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                            Werknemers
                          </h3>
                        </Stack>
                      }
                    >
                      <CommandBarSticky
                        items={[
                          {
                            key: 'new',
                            text: 'Nieuw',
                            iconProps: { iconName: 'Add' },
                            onClick: () => openTeamMemberDetail(true),
                            theme: commandBarTheme,
                          },
                          {
                            key: 'delete',
                            text: 'Verwijderen',
                            iconProps: { iconName: 'Trash' },
                            onClick: () =>
                              deleteTeamMemberFn(selectedTeamMemberID as any),
                            theme: commandBarTheme,
                            disabled: selectedTeamMemberID === undefined,
                          },
                        ]}
                        theme={commandBarTheme}
                      />
                      <ShimmeredDetailsList
                        items={
                          team.team_members
                            ? team.team_members
                            : []
                        }
                        columns={[
                          {
                            key: 'column1',
                            name: 'Naam',
                            fieldName: 'employee',
                            minWidth: 150,
                            maxWidth: 150,
                            isRowHeader: true,
                            onRender: (team: any) => (
                              <span>{displayNameEmployee(team.employee)}</span>
                            ),
                            data: 'string',
                            isPadded: true,
                          },
                        ]}
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible
                        selection={memberSelection}
                        selectionMode={SelectionMode.single}
                        selectionPreservedOnEmptyClick
                        setKey='teamMemberOverview'
                      />
                    </AccordionItem>
                  )}
              </Accordion>
            ) : (
              <>
                <Label>Na opslaan kan je de werknemers aanpassen.</Label>
                <Stack style={{ opacity: 0.3, pointerEvents: 'none' }}>
                  <Accordion
                    selectedKey={undefined}
                    defaultKey={undefined}
                    toggleItem={() => {
                      setSelectedValueItem(undefined);
                    }}
                  >
                    <AccordionItem
                      key=''
                      id=''
                      title={
                        <Stack>
                          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                            Werknemers
                          </h3>
                        </Stack>
                      }
                    />
                  </Accordion>
                </Stack>
              </>
            )}
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <SaveButtonWithPermissions
              disabled={!isComplete()}
              save={saveTeam}
              permission='write:teams'
            />
            <StackItem>
              <DefaultButton onClick={dismissPanel} style={{ marginRight: 10 }}>
                Annuleren
              </DefaultButton>
            </StackItem>
            {team && team.id && (
              <StackItem>
                <DeleteButtonWithPermissions
                  disabled={!team || !team.id}
                  deleteFn={toggleDelete}
                  permission='write:teams'
                />
              </StackItem>
            )}
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default TeamDetail;
