import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Logtail } from '@logtail/browser';
import { RootState } from '../store';

const logtail = new Logtail('xWmXJXiNNKdbkPq7EsjFExGk');
// for more info => https://github.com/reduxjs/cra-template-redux-typescript

export interface ErrorState {
  module?: string;
  message?: string | Error;
  level?: number;
  active: boolean;
  timeout?: number;
}

enum SeverityLevel {
  Critical = 3,
  Error = 2,
  Warning = 1,
  Info = 0,
}

export interface ThrowError {
  module: string;
  message: string | Error;
  level: SeverityLevel;
  timeout?: number;
}

const initialState: ErrorState = {
  active: false,
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    throwError: (state, action: PayloadAction<ThrowError>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const {
        level, message, module, timeout,
      } = action.payload;
      switch (level) {
        case SeverityLevel.Critical:
          logtail.error(message);
          break;
        case SeverityLevel.Error:
          logtail.error(message);
          break;
        case SeverityLevel.Warning:
          logtail.warn(message);
          break;
        case SeverityLevel.Info:
          logtail.info(message);
          break;
        default:
          logtail.info(message);
      }
      // logError(level, message);
      state.message = message;
      state.module = module;
      state.timeout = timeout;
      state.level = level;
      state.active = true;
    },
    dismissError: (state) => {
      state.active = false;
    },
  },
});

export const { throwError, dismissError } = errorSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const getError = (state: RootState) => state.error;

export default errorSlice.reducer;

export { SeverityLevel };
