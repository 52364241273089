import { gql } from '@apollo/client';
import { IComboBoxOption, IDropdownOption } from '@fluentui/react';
import { IEmployee } from './Employee';

export const GET_SALES_REPS = gql`
  query getSalesReps(
    $filter: SalesRepFilterInput
    $orderBy: SalesRepOrderByInput
  ) {
    findManySalesReps(filter: $filter, orderBy: $orderBy) {
      id
      first_name
      last_name
      prisma_total
    }
  }
`;

export interface ISalesRep {
  id?: number;
  first_name?: string;
  last_name?: string;
  prisma_total?: number;
}

export const convertSalesRepsToComboBoxOptions = (salesReps: ISalesRep[]) => {
  if (salesReps) {
    const result: IComboBoxOption[] = salesReps.map(
      (salesRep: ISalesRep): IComboBoxOption => ({
        key: salesRep.id as number,
        text: displayNameSalesRep(salesRep),
      }),
    );
    return result;
  }

  return [];
};

export const convertSalesRepsToDropdownOptions = (salesReps: ISalesRep[]) => {
  const result: IDropdownOption[] = salesReps.map(
    (salesRep: ISalesRep): IDropdownOption => ({
      key: salesRep.id as number,
      text: displayNameSalesRep(salesRep),
    }),
  );
  return result;
};

export const displayNameSalesRep = (salesRep: ISalesRep | IEmployee) => `${salesRep.first_name} ${salesRep.last_name}`;
