import { gql } from '@apollo/client';
import { IConstructionSite } from './ConstructionSite';

export interface IWaterPoint {
  id?: number;
  name?: string;
  weight?: number;
  comments?: string;
  rain_water_points?: number;
  tap_water_points?: number;

  construction_site_id?: number;
  construction_site?: IConstructionSite;
  toBeDeleted?: boolean;
  isNew?: boolean;
  prisma_total?: number;
  __typename?: string;
}

export const UPDATE_WATER_POINTS = gql`
  mutation UpdateWaterPoints(
    $construction_site_id: Int!
    $data: WaterPointsUpdateInput!
  ) {
    updateWaterPoints(construction_site_id: $construction_site_id, data: $data)
  }
`;
