import React, { useState, useRef, useEffect } from 'react';
import { Stack, PrimaryButton, IconButton } from '@fluentui/react';
import { useLazyQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { GET_STORAGE_TOKEN } from '../../../utils';
import { BlobStorage } from '../../blob-storage';

const UploadImages = ({ plan, setPlan }: { plan: any; setPlan: any }) => {
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [uploadedImages, setUploadedImages] = useState<any[]>(
    plan?.pictures ? JSON.parse(plan.pictures) : [],
  );
  const [uploadStatus, setUploadStatus] = useState('init'); // 'init', 'uploading', 'uploaded'
  const fileInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setPlan({
      ...plan,
      pictures: JSON.stringify(uploadedImages),
    });
  }, [uploadedImages]);

  useEffect(() => {
    setUploadedImages(plan?.pictures ? JSON.parse(plan.pictures) : []);
  }, [plan?.pictures]);

  const [acquireToken, { loading }] = useLazyQuery(GET_STORAGE_TOKEN, {
    fetchPolicy: 'no-cache',
    onCompleted: data => uploadFiles(data.createStorageToken),
  });

  const handleFilesSelection = (event: any) => {
    setSelectedFiles(Array.from(event.target.files));
  };

  const initiateUpload = () => {
    setUploadStatus('uploading');
    acquireToken();
  };

  const uploadFiles = (_data: any) => {
    const fileStore = new BlobStorage(_data.accountName, _data.sasToken);

    const uploadPromises = selectedFiles.map((file: any) => {
      const fileName = `${moment().format('YYYYMMDD_HHmmss')}_${file.name}`;
      return file.arrayBuffer().then((buffer: any) => {
        const url = `https://${_data.accountName}.blob.core.windows.net/images/${fileName}`;
        return fileStore
          .uploadBlob('images', fileName, buffer, buffer.byteLength)
          .then(() => ({
            url,
            featured: false,
            id: fileName, // Using fileName as a unique identifier for simplicity
          }));
      });
    });

    Promise.all(uploadPromises).then(newImages => {
      setUploadedImages(prevImages => [...prevImages, ...newImages]);
      setUploadStatus('uploaded');
      setSelectedFiles([]);
    });
  };

  const deleteImage = (id: any) => {
    setUploadedImages(prevImages =>
      prevImages.filter(image => image.id !== id));
  };

  const toggleFeaturedImage = (id: any) => {
    setUploadedImages(prevImages =>
      prevImages.map(image => ({
        ...image,
        featured: image.id === id ? !image.featured : false,
      })));
  };

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
        {uploadedImages.map((img: any, index) => (
          <div
            key={index}
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img
              src={img.url}
              alt={`Uploaded ${index}`}
              style={{
                width: 100,
                height: 100,
                border: img.featured ? '2.5px solid #581a73' : 'none',
                cursor: 'pointer',
              }}
              aria-hidden='true'
              onClick={() => toggleFeaturedImage(img.id)}
            />
            <IconButton
              iconProps={{ iconName: 'Delete' }}
              onClick={() => deleteImage(img.id)}
              title='Delete'
              ariaLabel='Delete image'
              styles={{
                root: {
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  margin: '4px',
                },
              }}
            />
          </div>
        ))}
      </div>
      <input
        ref={fileInput}
        type='file'
        accept='image/*'
        multiple
        onChange={handleFilesSelection}
        disabled={loading || uploadStatus === 'uploading'}
        style={{ display: 'none' }}
      />
      {selectedFiles.map((file, index) => (
        <div key={index}>{file.name}</div>
      ))}
      <PrimaryButton
        text='Selecteer afbeeldingen'
        onClick={() => fileInput.current && fileInput.current.click()}
        disabled={loading || uploadStatus === 'uploading'}
      />
      <PrimaryButton
        text='Upload Afbeeldingen'
        onClick={initiateUpload}
        disabled={
          loading || uploadStatus === 'uploading' || selectedFiles.length === 0
        }
      />
      {uploadStatus === 'uploading' && <p>Uploading...</p>}
    </Stack>
  );
};

export default UploadImages;
