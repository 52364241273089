import { gql } from '@apollo/client';
import { ILead } from './Lead';
import { ICostCenter } from './CostCenter';
import { IConstructionSite, ICustomer, IEmployee } from '.';

export interface ITimesheetEntry {
  comments?: string;
  cost_center_id?: number;
  customer_id?: number;
  employee_id?: number;
  entry_date?: Date | string;
  hourly_rate?: number;
  hours?: number | string; // string to create!
  id?: number;
  status?: string;
  version?: number;
  cost_center?: ICostCenter;
  customer?: ICustomer;
  employee?: IEmployee;
  construction_site?: IConstructionSite;
  lead?: ILead;
}

export interface IGroupedEntries {
  entries: ITimesheetEntry[]
  employee: IEmployee | null
  employee_id: number | null
  entry_date: string | null
  _count: {
    hours: number
  },
  _sum: {
    hours: number;
  }
}

export const GET_TIMESHEET_ENTRIES = gql`
  query getTimeSheetEntries(
    $filter: TimeSheetEntryFilterInput
    $orderBy: TimeSheetEntryOrderByInput
    $take: Int
    $skip: Int
  ) {
    findManyTimeSheetEntries(
      filter: $filter
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      comments
      cost_center_id
      customer_id
      employee_id
      entry_date
      hourly_rate
      hours
      id
      status
      travel_allowance
      version

      cost_center {
        id
        name
      }
      lead {
        id
        first_name1
        first_name2
        last_name1
        last_name2
      }
      construction_site {
        id
        name
      }
      employee {
        id
        first_name
        last_name
      }
    }
  }
`;

export const GET_TIMESHEET_ENTRIES_ANALYSIS = gql`
  query getTimeSheetEntries(
    $filter: TimeSheetEntryFilterInput
    $orderBy: TimeSheetEntryOrderByInput
    $take: Int
    $skip: Int
  ) {
    findManyTimeSheetEntries(
      filter: $filter
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      comments
      cost_center_id
      customer_id
      employee_id
      entry_date
      hourly_rate
      hours
      id
      status
      travel_allowance
      version

      cost_center {
        id
        name
      }

      construction_site {
        id
        name
      }
      employee {
        id
        first_name
        last_name
      }
    }
  }
`;

export const GET_GROUPED_TIMESHEET_ENTRIES = gql`
  query getGroupedTimeSheetEntries(
    $filter: TimeSheetEntryFilterInput
  ) {
    findManyGroupedTimesheetEntries(
      filter: $filter
    ) {
      employee_id
      entry_date
      _sum {
        hours
      }
      _count {
        hours
      }
      employee {
        id
        first_name
        last_name
      }
      entries {
        comments
        cost_center_id
        customer_id
        employee_id
        entry_date
        hourly_rate
        hours
        id
        status
        travel_allowance
        version
        construction_site {
          id
          name
        }
        lead {
          id
          first_name1
          first_name2
          last_name1
          last_name2
        }
        cost_center {
          id
          name
        }
        employee {
          id
          first_name
          last_name
        }
      }
    }
  }
`;

export const GET_TIMESHEET_ENTRY = gql`
  query getTimeSheetEntry($where: TimeSheetEntryWhereInput) {
    findOneTimeSheetEntry(where: $where) {
      comments
      cost_center_id
      customer_id
      employee_id
      entry_date
      hourly_rate
      hours
      id
      status
      travel_allowance
      version

      customer {
        id
        first_name1
        first_name2
        last_name1
        last_name2
      }
      employee {
        id
        first_name
        last_name
      }
    }
  }
`;

export const ADD_TIMESHEET_ENTRY = gql`
  mutation AddTimeSheetEntry($data: TimeSheetEntryCreateInput!) {
    createTimeSheetEntry(data: $data) {
      id
    }
  }
`;

export const UPDATE_TIMESHEET_ENTRY = gql`
  mutation UpdateTimeSheetEntry($id: Int!, $data: TimeSheetEntryUpdateInput!) {
    updateTimeSheetEntry(id: $id, data: $data) {
      id
    }
  }
`;

export const DELETE_TIMESHEET_ENTRY = gql`
  mutation deleteTimeSheetEntry($where: TimeSheetEntryWhereInput) {
    deleteTimeSheetEntry(where: $where) {
      id
    }
  }
`;
