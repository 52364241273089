import { FormEvent, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { Icon, Stack, TextField } from '@fluentui/react';
import useDebounce from '../../components/hooks/useDebounce';
import { stackTokens15, textFieldStyles300 } from '../../utils';
import { useAppDispatch } from '../../redux/hooks';
import { SeverityLevel, throwError } from '../../redux/error/errorSlice';
import { GET_SUSPECTS } from '../../utils/Suspect';
import SuspectOverview from './components/SuspectOverview';

const Suspects = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuth0();
  const [filter, setFilter] = useState('');
  const debouncedValue = useDebounce(filter, 500);

  const getFilters = (filterString: string) => {
    const filterArray = filterString.split(' ');
    const filterObject: any = {
      AND: [
        {
          OR: [
            {
              resolved: {
                equals: false,
              },
            },
            {
              resolved: {
                equals: null,
              },
            },
          ],
        },
      ],
    };

    for (let i = 0; i < filterArray.length; i++) {
      filterObject.AND.push({
        OR: [
          { first_name: { contains: filterArray[i] } },
          { last_name: { contains: filterArray[i] } },
          { email: { contains: filterArray[i] } },
          { phone: { contains: filterArray[i] } },
          { address: { contains: filterArray[i] } },
          { city: { contains: filterArray[i] } },
        ],
      });
    }

    return filterObject;
  };

  const {
    data: dataSuspects,
    loading,
    error,
    refetch,
  } = useQuery(GET_SUSPECTS, {
    variables: {
      filter: getFilters(debouncedValue),
    },
    onError: error => {
      dispatch(
        throwError({
          module: 'Suspects Overview',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <div style={{ padding: '25px' }}>
        <h3
          style={{
            fontSize: '20px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Icon iconName='ContactCard' style={{ fontSize: '20px' }} />
          Suspects
        </h3>

        <TextField
          label='Zoeken...'
          value={filter}
          styles={textFieldStyles300}
          onChange={onSearchValueChange}
        />
      </div>

      <SuspectOverview
        suspects={dataSuspects ? dataSuspects.findManySuspects : []}
        loading={loading}
        refetch={refetch}
      />
    </Stack>
  );
};

export default Suspects;
