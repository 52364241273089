import {
 DetailsListLayoutMode, IColumn, IObjectWithKey, ISelection, SelectionMode,
} from '@fluentui/react';
import moment from 'moment';
import { useState } from 'react';
import { DetailsListDefault } from '../../../components/parts';
import { displayNameEmployee, displayNameSupplier } from '../../../utils';
import { IWorkOrder } from '../../../utils/WorkOrder';
import CarsDetailsList from '../../cars/components/CarsDetailsList';

interface props {
    items: IWorkOrder[],
    selection?: ISelection<IObjectWithKey> | undefined,
    onItemInvoked?: () => void;
    enableShimmer?: boolean;
    isSortedAsc?: boolean;
    sortedField: string;
    setSorting: (isDesc: boolean, field: string) => void;
    loadMore?: boolean;
    lazyLoading?: boolean;
    loading?: boolean;
    initialLoad?: boolean;
    loadMoreCallback?: () => void;
}

const WorkOrdersDetailsList = ({
    items,
    selection,
    onItemInvoked,
    enableShimmer = false,
    isSortedAsc = false,
    sortedField,
    setSorting, // = (isSortedAsc: boolean, sortedField: string) => {},
    loadMore,
    lazyLoading,
    loading,
    initialLoad,
    loadMoreCallback,
  }: props) => {
    // On Column Click
    const onColumnClick = (event: any, column: any) => {
        const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
        let fieldName = '';
        let sortDescending = false;

        if (sortedColumn) {
          const newColumns = columns.map((col: IColumn) => {
            if (col.fieldName === column.fieldName) {
              col.isSorted = true;

              col.isSortedDescending = column.fieldName !== sortedColumn[0].fieldName
                ? false
                : !col.isSortedDescending;

              fieldName = column.fieldName;
              sortDescending = col.isSortedDescending;
            } else {
              col.isSorted = false;
            }
            return col;
          });
          setColumns(newColumns);

          setSorting(!sortDescending, fieldName);
        }
      };

    // Column List
    const columnsList = [
        {
          key: 'column1',
          name: 'Doc. no',
          fieldName: 'document_no',
          minWidth: 50,
          maxWidth: 50,
          isRowHeader: true,
          onRender: (workOrder: IWorkOrder) => <span>{workOrder.document_no}</span>,
          onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column2',
          name: 'Omschrijving',
          fieldName: 'description',
          minWidth: 200,
          maxWidth: 200,
          isRowHeader: true,
          onRender: (workOrder: IWorkOrder) => <span>{workOrder.description}</span>,
          // onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column3',
          name: 'Uitvoerder (supplier)',
          fieldName: 'supplier',
          minWidth: 150,
          maxWidth: 150,
          isRowHeader: true,
          onRender: (workOrder: IWorkOrder) => (
            <span>
              {workOrder.supplier && displayNameSupplier(workOrder.supplier)}
            </span>
          ),
          // onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column3',
          name: 'Uitvoerder (employee)',
          fieldName: 'employee',
          minWidth: 125,
          maxWidth: 125,
          isRowHeader: true,
          onRender: (workOrder: IWorkOrder) => (
            <span>
              {workOrder.employee && displayNameEmployee(workOrder.employee)}
            </span>
          ),
          // onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column4',
          name: 'Werf',
          fieldName: 'construction_site',
          minWidth: 200,
          maxWidth: 200,
          isRowHeader: true,
          onRender: (workOrder: IWorkOrder) => (
            <span>{workOrder.construction_site?.name}</span>
          ),
          // onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column5',
          name: 'Datum',
          fieldName: 'document_date',
          minWidth: 90,
          maxWidth: 90,
          isRowHeader: true,
          onRender: (workOrder: IWorkOrder) => (
            <span>
              {workOrder.document_date
                && moment(new Date(workOrder.document_date)).format('YYYY-MM-DD')}
            </span>
          ),
          onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column6',
          name: 'Status',
          fieldName: 'status',
          minWidth: 90,
          maxWidth: 90,
          isRowHeader: true,
          onRender: (workOrder: IWorkOrder) => {
            switch (workOrder.status) {
              case 'NEW':
                return <span>Nieuw</span>;
              case 'COMPLETED':
                return <span>Afgesloten</span>;
              default:
                return null;
            }
          },
          // onColumnClick,
          data: 'string',
          isPadded: true,
        },
      ];

    const initColumns = (sortedField: string, isSortedAsc: boolean) => columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

    const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

    return (
      <DetailsListDefault
        items={items}
        columns={columns}
        layoutMode={DetailsListLayoutMode.justified}
        selection={selection}
        selectionMode={SelectionMode.single}
        selectionPreservedOnEmptyClick
        onItemInvoked={onItemInvoked}
        enableShimmer={enableShimmer}
        loadMore={loadMore}
        lazyLoading={lazyLoading}
        loadMoreCallback={loadMoreCallback}
        loading={loading}
      />
    );
  };

  export default WorkOrdersDetailsList;
