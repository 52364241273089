import {
    Checkbox, DetailsListLayoutMode, IColumn, IObjectWithKey, ISelection, SelectionMode,
} from '@fluentui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DetailsListDefault } from '../../../components/parts';
import { displayNameCustomer } from '../../../utils';
import { ICar } from '../../../utils/Car';
import { ICustomerPaymentTransaction } from '../../../utils/CustomerPaymentTransaction';
import parseNumber from '../../../utils/Numbers';

interface props {
    items: ICustomerPaymentTransaction[],
    selection?: ISelection<IObjectWithKey> | undefined,
    paymentTransaction?: ICustomerPaymentTransaction,
    onItemInvoked?: () => void;
    enableShimmer?: boolean;
    isSortedAsc?: boolean;
    sortedField: string;
    setSorting: (isDesc: boolean, field: string) => void;
    loadMore?: boolean;
    lazyLoading?: boolean;
    loading?: boolean;
    loadMoreCallback?: () => void;
}

const CustomerPaymentTransactionsDetailsList = ({
    items,
    selection,
    paymentTransaction,
    onItemInvoked,
    enableShimmer = true,
    isSortedAsc = false,
    sortedField,
    setSorting, // = (isSortedAsc: boolean, sortedField: string) => {},
    loadMore,
    lazyLoading,
    loading,
    loadMoreCallback,
  }: props) => {
    const [groups, setGroups] = useState<any[]>([]);
    // Groups
    function groupsGenerator(itemsList: any, fieldName: any) {
        // Array of group objects
        const groupObjArr: any[] = [];

        // Get the group names from the items list
        const groupNames = [
            ...new Set(
            itemsList.map((item: any) => {
                if (item.construction_site) {
                    return item.construction_site?.id;
                }
            }),
        ),
        ];

        // Iterate through each group name to build proper group object
        groupNames.forEach(gn => {
        // Count group items
        const groupLength = itemsList.filter(
            (item: any) => item.construction_site?.id === gn,
        ).length;

        // Find the first group index
        const groupIndex = itemsList
            .map((item: any) => item.construction_site?.id)
            .indexOf(gn);

        // Generate a group object
        groupObjArr.push({
            key: gn,
            // name: itemsList[groupIndex].customer
            //   ? displayNameCustomer(itemsList[groupIndex].customer)
            //   : '',
            name: itemsList[groupIndex].construction_site
                ? itemsList[groupIndex].construction_site.name
                : 'Geen werf gekoppeld',
            level: 0,
            count: groupLength,
            startIndex: groupIndex,
            });
        });

        // The final groups array returned
        return groupObjArr;
    }

    useEffect(() => {
        setGroups(groupsGenerator(items, 'customer_id'));
    }, [items]);

    // On Column Click
    const onColumnClick = (event: any, column: any) => {
        const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
        let fieldName = '';
        let sortDescending = false;

        if (sortedColumn) {
          const newColumns = columns.map((col: IColumn) => {
            if (col.fieldName === column.fieldName) {
              col.isSorted = true;

              col.isSortedDescending =
                column.fieldName !== sortedColumn[0].fieldName
                  ? false
                  : !col.isSortedDescending;

              fieldName = column.fieldName;
              sortDescending = col.isSortedDescending;
            } else {
              col.isSorted = false;
            }
            return col;
          });
          setColumns(newColumns);

          setSorting(!sortDescending, fieldName);
        }
      };

    // Column List
    const columnsList = [
        {
          key: 'column1',
          name: 'Werf',
          fieldName: 'construction_site',
          minWidth: 75,
          maxWidth: 75,
          isRowHeader: true,
          onRender: () => <span />,
          onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column2',
          name: 'Omschrijving',
          fieldName: 'description',
          minWidth: 440,
          maxWidth: 440,
          isRowHeader: true,
          onRender: (customerPaymentTransaction: ICustomerPaymentTransaction) => (
            <span>
              {!customerPaymentTransaction.construction_site &&
                paymentTransaction &&
                paymentTransaction.customer &&
                `${displayNameCustomer(paymentTransaction.customer)}: `}
              {customerPaymentTransaction.description}
            </span>
          ),
          onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column3',
          name: 'Datum',
          fieldName: 'payment_date',
          minWidth: 150,
          maxWidth: 150,
          isRowHeader: true,
          onRender: (customerPaymentTransaction: ICustomerPaymentTransaction) => (
            <span>
              {customerPaymentTransaction.payment_date &&
                moment(new Date(customerPaymentTransaction.payment_date)).format(
                  'YYYY-MM-DD',
                )}
            </span>
          ),
          onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column4',
          name: 'Factuur',
          fieldName: 'customer_invoice',
          minWidth: 150,
          maxWidth: 150,
          isRowHeader: true,
          onRender: (customerPaymentTransaction: ICustomerPaymentTransaction) => (
            <span>
              {customerPaymentTransaction.customer_invoices &&
                customerPaymentTransaction.customer_invoices.map(invoice => {
                  if (invoice.invoice_paid) return 'Betaald';
                  return 'Niet betaald';
                })}
              {(!customerPaymentTransaction.customer_invoices ||
                customerPaymentTransaction.customer_invoices.length === 0) &&
                'Geen factuur'}
            </span>
          ),
          onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column5',
          name: 'Percentage',
          fieldName: 'percentage',
          minWidth: 50,
          maxWidth: 50,
          isRowHeader: true,
          onRender: (customerPaymentTransaction: ICustomerPaymentTransaction) => (
            <span>
              {customerPaymentTransaction.percentage &&
                `${customerPaymentTransaction.percentage}%`}
            </span>
          ),
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column6',
          name: 'Bedrag',
          fieldName: 'contract_amount',
          minWidth: 70,
          maxWidth: 70,
          isRowHeader: true,
          onRender: (customerPaymentTransaction: ICustomerPaymentTransaction) => (
            <span>
              {customerPaymentTransaction.construction_site &&
                customerPaymentTransaction.construction_site.contract_amount &&
                customerPaymentTransaction.percentage &&
                `€ ${
                  parseNumber(customerPaymentTransaction.construction_site.contract_amount) *
                  (parseNumber(customerPaymentTransaction.percentage) / 100)
                }`}
            </span>
          ),
          data: 'string',
          isPadded: true,
        },
      ];

    const initColumns = (sortedField: string, isSortedAsc: boolean) => columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

    const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

    return (
      <DetailsListDefault
        items={items}
        columns={columns}
        layoutMode={DetailsListLayoutMode.justified}
        groups={groups}
        selection={selection}
        selectionMode={SelectionMode.single}
        selectionPreservedOnEmptyClick
        onItemInvoked={onItemInvoked}
        enableShimmer={enableShimmer}
        dragAndDropEnabled={false}
        loadMore={loadMore}
        lazyLoading={lazyLoading}
        loadMoreCallback={loadMoreCallback}
        loading={loading}
      />
    );
  };

  export default CustomerPaymentTransactionsDetailsList;
