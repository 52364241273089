import React, { useEffect, useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  Stack,
  Label,
  StackItem,
  TextField,
  IComboBoxOption,
  Checkbox,
} from '@fluentui/react';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  convertConstructionSitesToComboBoxOptions,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  displayNameCustomer,
  GET_CONSTRUCTION_SITES,
  IConstructionSite,
  modalContentStyles,
} from '../../../utils';
import {
  ADD_CUSTOMER_PAYMENT_TRANSACTION,
  GET_CUSTOMER_PAYMENT_TRANSACTION_BY_ID,
  ICustomerPaymentTransaction,
  UPDATE_CUSTOMER_PAYMENT_TRANSACTION,
} from '../../../utils/CustomerPaymentTransaction';
import { ComboboxWithFilter } from '../../../components/parts';
import DateView from '../../../components/parts/DateView';
import {
  GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
  IConstructionSiteCreateUpdate,
} from '../../../utils/ConstructionSite';
import useDebounce from '../../../components/hooks/useDebounce';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import parseNumber from '../../../utils/Numbers';
import { toastSuccess, toastError } from '../../../utils/toast';
import { customPanelStyles } from '../../../theme';

type Props = {
  isOpen: boolean;
  dismissPanel: () => void;
  customerPaymentTransactionSource: ICustomerPaymentTransaction;
  constructionSite?: IConstructionSite | IConstructionSiteCreateUpdate;
  skipFetchConstructionSites?: boolean;
  paymentTransactionId?: number;
};

const PaymentTransactionDetail = ({
  isOpen,
  dismissPanel,
  customerPaymentTransactionSource,
  constructionSite,
  skipFetchConstructionSites,
  paymentTransactionId,
}: Props) => {
  const [customerPaymentTransaction, setCustomerPaymentTransaction] = useState(
    customerPaymentTransactionSource,
  );
  const { data } = useQuery(GET_CUSTOMER_PAYMENT_TRANSACTION_BY_ID, {
    variables: ((customerPaymentTransactionSource &&
      customerPaymentTransactionSource.id) ||
      paymentTransactionId) && {
      where: {
        id: customerPaymentTransactionSource.id || paymentTransactionId,
      },
    },
    skip:
      (!customerPaymentTransactionSource ||
        !customerPaymentTransactionSource.id) &&
      !paymentTransactionId,
    onCompleted: data => {
      setCustomerPaymentTransaction(data.findOneCustomerPaymentTransaction);
    },
  });

  // Save payment transaction
  const [addCustomerPaymentTransaction] = useMutation(
    ADD_CUSTOMER_PAYMENT_TRANSACTION,
    {
      refetchQueries: [
        {
          query: GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
          variables: {
            where: {
              id:
                skipFetchConstructionSites &&
                constructionSite &&
                constructionSite.id
                  ? constructionSite.id
                  : customerPaymentTransaction.construction_site
                  ? customerPaymentTransaction.construction_site.id
                  : undefined,
            },
          },
        },
      ],
    },
  );
  const [modifyCustomerPaymentTransaction] = useMutation(
    UPDATE_CUSTOMER_PAYMENT_TRANSACTION,
    {
      refetchQueries: [
        {
          query: GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
          variables: {
            where: {
              id:
                skipFetchConstructionSites &&
                constructionSite &&
                constructionSite.id
                  ? constructionSite.id
                  : customerPaymentTransaction.construction_site
                  ? customerPaymentTransaction.construction_site.id
                  : undefined,
            },
          },
        },
      ],
    },
  );

  const savePaymentTransaction = async () => {
    try {
      if (customerPaymentTransaction) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const constructionSiteID =
              skipFetchConstructionSites &&
              constructionSite &&
              constructionSite.id
                ? constructionSite.id
                : customerPaymentTransaction.construction_site
                ? customerPaymentTransaction.construction_site.id
                : undefined;

            if (!constructionSiteID) return;
            const allInput = {
              description: customerPaymentTransaction.description,
              payment_date: customerPaymentTransaction.payment_date,
              percentage: parseNumber(customerPaymentTransaction.percentage),
              engineering_expense:
                customerPaymentTransaction.engineering_expense,
              construction_site: {
                connect: {
                  id: constructionSiteID,
                },
              },
              /* customer_invoice_legacy:
            customerPaymentTransaction.customer_invoice_legacy && {
              connect: {
                id: customerPaymentTransaction.customer_invoice_legacy.id,
              },
            }, */
            };

            if (customerPaymentTransaction.id) {
              modifyCustomerPaymentTransaction({
                variables: {
                  id: customerPaymentTransaction.id,
                  data: allInput,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: (x: any) => {
                  resolve(x);
                },
              });
            } else {
              addCustomerPaymentTransaction({
                variables: {
                  data: allInput,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: (x: any) => {
                  resolve(x);
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
        toastSuccess('Betalingschijf opgeslagen');
        dismissPanel();
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
      dismissPanel();
    }
  };

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setCustomerPaymentTransaction((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  const onChangeCheckboxValue = (
    event?: React.FormEvent<HTMLInputElement | HTMLElement>,
    checked?: boolean,
  ) => {
    setCustomerPaymentTransaction((prevState: any) => ({
      ...prevState,
      [(event!.target as HTMLTextAreaElement).name]: checked,
    }));
  };
  const [constructionSiteFilter, setConstructionSiteFilter] = useState('');
  const debouncedFilter = useDebounce(constructionSiteFilter, 500);

  const [constructionSites, setConstructionSites] = useState<
    IConstructionSite[]
  >([]);

  const { data: constructionSitesData } = useQuery(GET_CONSTRUCTION_SITES, {
    fetchPolicy: 'no-cache',
    variables: {
      take: 20,
      filter: {
        name: {
          contains: debouncedFilter,
        },
      },
    },
    skip: !!skipFetchConstructionSites,
  });

  useEffect(() => {
    const parsedConstructionSites: any[] =
      constructionSitesData && constructionSitesData.findManyConstructionSites
        ? constructionSitesData.findManyConstructionSites
        : [];
    if (
      customerPaymentTransaction &&
      customerPaymentTransaction.construction_site
    ) {
      if (
        parsedConstructionSites.filter(
          item => item.id === customerPaymentTransaction.construction_site!.id,
        ).length === 0
      ) {
        // only add if not in results
        parsedConstructionSites.push(
          customerPaymentTransaction.construction_site,
        );
      }
    }
    setConstructionSites(parsedConstructionSites);
  }, [constructionSitesData]);

  /* const constructionSites =
    constructionSitesData && constructionSitesData.findManyConstructionSites; */

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        customerPaymentTransaction && customerPaymentTransaction.id
          ? 'Betalingsschijf wijzigen'
          : 'Betalingsschijf toevoegen'
      }
      type={PanelType.custom}
      customWidth='500px'
      styles={customPanelStyles}
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {customerPaymentTransaction && (
            <Label>ID: {customerPaymentTransaction.id}</Label>
          )}

          <Stack>
            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='description'
                label='Omschrijving'
                multiline
                value={
                  customerPaymentTransaction &&
                  customerPaymentTransaction.description
                    ? customerPaymentTransaction.description
                    : ''
                }
                onChange={onChangeTextFieldValue}
                required
              />
            </Stack>

            <Stack
              style={{
                marginBottom: 10,
              }}
            >
              {/* <ComboboxWithFilter
                label='Factuur'
                disabled
                options={convertCustomerInvoicesToComboBoxOptions(
                  customerInvoices,
                )}
                value={
                  paymentTransaction
                  && paymentTransaction.customer_invoice_legacy
                    ? paymentTransaction.customer_invoice_legacy.id
                    : ''
                }
                multiline={false}
                callBack={(newValue: IComboBoxOption[]) => {
                  if (newValue && newValue.length > 0) {
                    const result = { ...paymentTransaction };
                    if (result.customer_invoice_legacy) {
                      result.customer_invoice_legacy.id = newValue[0]
                        .key as number;
                    } else {
                      result.customer_invoice_legacy = {
                        id: newValue[0].key as number,
                      };
                    }
                    setPaymentTransaction(result);
                  }
                }}
              /> */}
              {customerPaymentTransaction.customer_invoices &&
                customerPaymentTransaction.customer_invoices.length > 0 && (
                  <>
                    <Label>Facturen</Label>
                    {customerPaymentTransaction.customer_invoices &&
                      customerPaymentTransaction.customer_invoices.map(item => (
                        <a
                          href={`/customer-invoices/${item.id}`}
                          target='_blank'
                          rel='noreferrer'
                          style={{ width: '20%' }}
                        >
                          <DefaultButton>{item.invoice_no}</DefaultButton>
                        </a>
                      ))}
                  </>
                )}
            </Stack>

            <Stack style={{ marginBottom: 10 }}>
              <DateView
                item={customerPaymentTransaction}
                setItem={setCustomerPaymentTransaction}
                date={
                  customerPaymentTransaction &&
                  customerPaymentTransaction.payment_date
                }
                label='Payment date'
                field='payment_date'
              />
            </Stack>

            <Stack
              style={{
                marginBottom: 10,
              }}
            >
              <ComboboxWithFilter
                label='Werf'
                options={convertConstructionSitesToComboBoxOptions(
                  skipFetchConstructionSites && constructionSite
                    ? [constructionSite as IConstructionSite]
                    : constructionSites,
                )}
                allowFreeForm
                value={
                  skipFetchConstructionSites &&
                  constructionSite &&
                  constructionSite.id
                    ? constructionSite.id
                    : customerPaymentTransaction &&
                      customerPaymentTransaction.construction_site
                    ? customerPaymentTransaction.construction_site.id
                    : ''
                }
                multiline={false}
                callBack={(newValue: IComboBoxOption[]) => {
                  if (newValue && newValue.length > 0) {
                    const result = { ...customerPaymentTransaction };

                    result.construction_site = {
                      id: newValue[0].key as number,
                    };

                    setCustomerPaymentTransaction(result);
                  }
                }}
                setFilter={(value: string) => {
                  setConstructionSiteFilter(value);
                }}
                required
                disabled={skipFetchConstructionSites}
              />
            </Stack>
            {customerPaymentTransaction &&
              !customerPaymentTransaction.construction_site &&
              customerPaymentTransaction.customer && (
                <Stack style={{ marginBottom: 10 }}>
                  Klant:{' '}
                  {displayNameCustomer(customerPaymentTransaction.customer)}
                </Stack>
              )}

            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='percentage'
                label='Percentage'
                value={
                  customerPaymentTransaction &&
                  customerPaymentTransaction.percentage
                    ? customerPaymentTransaction.percentage.toString()
                    : ''
                }
                onChange={onChangeTextFieldValue}
              />
            </Stack>
            <Stack style={{ marginBottom: 10 }}>
              <Checkbox
                name='engineering_expense'
                label='Studiekost'
                checked={
                  !!(
                    customerPaymentTransaction &&
                    customerPaymentTransaction.engineering_expense
                  )
                }
                onChange={onChangeCheckboxValue}
              />
            </Stack>

            {customerPaymentTransaction.construction_site &&
              customerPaymentTransaction.construction_site.contract_amount &&
              customerPaymentTransaction.percentage && (
                <Stack>
                  Bedrag: &euro;{' '}
                  {(
                    parseNumber(
                      customerPaymentTransaction.construction_site
                        .contract_amount,
                    ) *
                    (parseNumber(customerPaymentTransaction.percentage) / 100)
                  ).toFixed(2)}
                </Stack>
              )}
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <SaveButtonWithPermissions
              disabled={
                !customerPaymentTransaction ||
                (customerPaymentTransaction &&
                  (!customerPaymentTransaction.description ||
                    (!skipFetchConstructionSites &&
                      !customerPaymentTransaction.construction_site)))
              }
              save={savePaymentTransaction}
              permission='write:customerPaymentTransactions'
            />

            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default PaymentTransactionDetail;
