import { useAuth0 } from '@auth0/auth0-react';
import {
 FormEvent, useCallback, useEffect, useState,
} from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useQuery } from '@apollo/client';
import {
 Icon, PrimaryButton, Stack, TextField, Toggle,
} from '@fluentui/react';
import { useAppDispatch } from '../../redux/hooks';
import { FilterState } from '../../components/parts/FilterPanel';
import useDebounce from '../../components/hooks/useDebounce';
import { sendNotification } from '../../redux/notification/notificationSlice';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  GET_CONSTRUCTION_SITES,
  normalizeString,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import { toastError, toastSuccess } from '../../utils/toast';
import WetBreyneConstructionSitesOverview from './components/WetBreyneConstructionSitesOverview';

interface IPagedConstructionSites {
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
  lastCall?: number;
}

const WetBreyne = () => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const dispatch = useAppDispatch();
  const [pagedState, setPagedState] = useState<IPagedConstructionSites>({
    skip: 0,
    take: 20,
    filter: '',
    showLoadMore: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: false,
    sortedField: 'creation_date',
  });

  let isDirectie = false;

  for (let i = 0; i < (user ? user['3bouw/roles'].length : 0); i++) {
    if (
      (user as any)['3bouw/roles'][i] === 'SuperAdmin' ||
      (user as any)['3bouw/roles'][i] === 'Directie'
    ) {
      isDirectie = true;
    }
  }

  const [breyne_applicable, setBreyneApplicable] = useState(true);

  const [filter, setFilter] = useState('');
  const [constructionSiteFilter, setConstructionSiteFilter] = useState<
    FilterState | undefined
  >();
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);

  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    client.cache.reset();
    setPagedState(prevState => ({
      ...prevState,
      constructionSites: [],
      skip: 0,
    }));
  }, [filter, constructionSiteFilter]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const getFilters = useCallback(
    (filterString: string) => {
      const filterArray = filterString.split(' ');

      const bryn =
        breyne_applicable === true
          ? {
              breyne_applicable: {
                equals: true,
              },
            }
          : {
              OR: [
                { breyne_applicable: { equals: false } },
                { breyne_applicable: null },
              ],
            };

      const filters: any = {
        AND: [bryn],
      };

      if (constructionSiteFilter && constructionSiteFilter.schema.length > 0) {
        for (let i = 0; i < constructionSiteFilter.schema.length; i++) {
          for (
            let t = 0;
            t < constructionSiteFilter.schema[i].fields.length;
            t++
          ) {
            if (
              constructionSiteFilter.schema[i].fields[t].value &&
              constructionSiteFilter.schema[i].fields[t].parsedValue &&
              constructionSiteFilter.schema[i].fields[t].value !== ''
            ) {
              filters.AND.push(
                constructionSiteFilter.schema[i].fields[t].parsedValue,
              );
            }
          }
        }
      }

      for (let i = 0; i < filterArray.length; i++) {
        const filterValue: any = {
          OR: [
            { name: { contains: filterArray[i] } },
            { normalized_name: { contains: filterArray[i] } },
            { normalized_name: { contains: normalizeString(filterArray[i]) } },
            { address: { contains: filterArray[i] } },
            { city: { contains: filterArray[i] } },
          ],
        };

        const numericValue = parseInt(filterArray[i], 10);
        if (numericValue) {
          filterValue.OR.push({ zip_code: { equals: +numericValue } });
        }

        filters.AND.push(filterValue);
      }

      return filters;
    },
    [filter, constructionSiteFilter, breyne_applicable],
  );

  const variables = {
    filter: getFilters(debouncedValue),
    take: pagedState.take,
    skip: 0,
    orderBy: getOrderBy(),
  };

  const {
    data: dataConstructionSites,
    loading,
    error,
    fetchMore,
    refetch,
    client,
  } = useQuery(GET_CONSTRUCTION_SITES, {
    variables: {
      filter: getFilters(debouncedValue),
      take: pagedState.take,
      skip: 0,
      orderBy: getOrderBy(),
    },
    onError: error => {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    },
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const onBreyneApplicableChange = (checked: boolean) => {
    setBreyneApplicable(checked);
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    client.cache.reset();
    clearConstructionSites();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const clearConstructionSites = () => {
    setPagedState(prevState => ({
      ...prevState,
      constructionSites: [],
      skip: 0,
    }));
  };

  const openFilter = () => {
    setIsFilterPanelOpen(true);
  };

  const fetchMoreConstructionSites = () => {
    fetchMore({
      variables: {
        filter: getFilters(debouncedValue),
        take: pagedState.take,
        skip: pagedState.skip + pagedState.take,
        orderBy: getOrderBy(),
      },
    });

    setPagedState(prevState => ({
      ...prevState,
      skip: pagedState.skip + pagedState.take,
    }));
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <div style={{ padding: '25px' }}>
        <h3
          style={{
            fontSize: '20px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Icon iconName='Shield' style={{ fontSize: '20px' }} />
          Werven
        </h3>

        <Stack horizontal tokens={{ childrenGap: 15 }}>
          <TextField
            label='Zoeken...'
            value={filter}
            styles={textFieldStyles300}
            onChange={onSearchValueChange}
          />
          {isDirectie && (
            <Toggle
              label='Wet Breyne van toepassing'
              checked={breyne_applicable}
              onChange={(e, checked) => {
                onBreyneApplicableChange(!!checked);
              }}
            />
          )}
        </Stack>
      </div>

      <WetBreyneConstructionSitesOverview
        constructionSites={
          dataConstructionSites
            ? dataConstructionSites.findManyConstructionSites
            : []
        }
        loading={loading}
        //
        setSorting={setSorting}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        //
        showLoadMore
        loadMore
        loadMoreCallback={fetchMoreConstructionSites}
        lazyLoading
        //
        //
        listFilters={variables}
        setConstructionSiteFilter={setConstructionSiteFilter}
        //
        isFilterPanelOpen={isFilterPanelOpen}
        setIsFilterPanelOpen={setIsFilterPanelOpen}
        //
        refetch={refetch}
      />
    </Stack>
  );
};

export default WetBreyne;
