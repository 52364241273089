import { gql } from '@apollo/client';
import { IComboBoxOption } from '@fluentui/react';

export interface IPlan {
  id: number;
  active: boolean;
  title: string;
  subtitle?: string;
  description?: string;
  pictures?: string;
  style_of_home?: string;
  type_of_home?: string;
  type_of_roof?: string;
  plot_area?: number;
  house_area?: number;
  facade_width?: number;
  rooms?: number;
  date_created: Date;
  prisma_total?: number;
  internal_description?: string;
}

export const GET_PLANS = gql`
  query getPlans(
    $filter: PlanFilterInput
    $orderBy: PlanOrderByInput
    $take: Int
    $skip: Int
  ) {
    findManyPlans(
      filter: $filter
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      id
      active
      title
      subtitle
      description
      pictures
      style_of_home
      type_of_home
      type_of_roof
      plot_area
      house_area
      facade_width
      rooms
      date_created
      prisma_total
      internal_description
    }
  }
`;

export const GET_PLAN_BY_ID = gql`
  query getPlan($where: PlanWhereInput) {
    findOnePlan(where: $where) {
      id
      active
      title
      subtitle
      description
      pictures
      style_of_home
      type_of_home
      type_of_roof
      plot_area
      house_area
      facade_width
      rooms
      date_created
      internal_description
    }
  }
`;

export const ADD_PLAN = gql`
  mutation AddPlan($data: PlanCreateInput!) {
    createPlan(data: $data) {
      id
      active
      title
      subtitle
      description
      pictures
      style_of_home
      type_of_home
      type_of_roof
      plot_area
      house_area
      facade_width
      rooms
      date_created
      internal_description
    }
  }
`;

export const UPDATE_PLAN = gql`
  mutation UpdatePlan($id: Int!, $data: PlanUpdateInput!) {
    updatePlan(id: $id, data: $data) {
      id
      active
      title
      subtitle
      description
      pictures
      style_of_home
      type_of_home
      type_of_roof
      plot_area
      house_area
      facade_width
      rooms
      date_created
      internal_description
    }
  }
`;

export const DELETE_PLAN = gql`
  mutation deletePlan($where: PlanWhereInput) {
    deletePlan(where: $where) {
      id
    }
  }
`;

export const convertPlansToComboBoxOptions = (plans: IPlan[]) => {
  const result: IComboBoxOption[] = plans.map(
    (plan: IPlan): IComboBoxOption => ({
      key: plan.id,
      text: `${plan.title}`,
    }),
  );
  return result;
};
