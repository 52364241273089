import {
 DetailsListLayoutMode, IColumn, IObjectWithKey, ISelection, SelectionMode,
} from '@fluentui/react';
import moment from 'moment';
import { useState } from 'react';
import { DetailsListDefault } from '../../../components/parts';
import { displayNameEmployee } from '../../../utils';
import { displayNameLead } from '../../../utils/Lead';
import { IQuoteReference } from '../../../utils/QuoteReference';

interface props {
    items: IQuoteReference[],
    selection?: ISelection<IObjectWithKey> | undefined,
    onItemInvoked?: () => void;
    enableShimmer?: boolean;
    isSortedAsc?: boolean;
    sortedField: string;
    setSorting: (isDesc: boolean, field: string) => void;
    loadMore?: boolean;
    lazyLoading?: boolean;
    loading?: boolean;
    loadMoreCallback?: () => void;
}

const QuoteDetailsList = ({
    items,
    selection,
    onItemInvoked,
    enableShimmer = false,
    isSortedAsc = false,
    sortedField,
    setSorting, // = (isSortedAsc: boolean, sortedField: string) => {},
    loadMore,
    lazyLoading,
    loading,
    loadMoreCallback,
  }: props) => {
    // On Column Click
    const onColumnClick = (event: any, column: any) => {
        const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
        let fieldName = '';
        let sortDescending = false;

        if (sortedColumn) {
          const newColumns = columns.map((col: IColumn) => {
            if (col.fieldName === column.fieldName) {
              col.isSorted = true;

              col.isSortedDescending =
                column.fieldName !== sortedColumn[0].fieldName
                  ? false
                  : !col.isSortedDescending;

              fieldName = column.fieldName;
              sortDescending = col.isSortedDescending;
            } else {
              col.isSorted = false;
            }
            return col;
          });

          setColumns(newColumns);
          setSorting(!sortDescending, fieldName);
        }
      };

    // Column List
    const columnsList = [
        {
          key: 'column1',
          name: 'Offerte nr',
          fieldName: 'quote_no',
          minWidth: 70,
          maxWidth: 600,
          isRowHeader: true,
          onRender: (quote: IQuoteReference) => <span>{quote.quote_no}</span>,
          onColumnClick,
          data: 'string',
          isPadded: true,
          isSorted: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorteer van laag tot hoog',
          sortDescendingAriaLabel: 'Sorteer van hoog tot laag',
        },
        {
          key: 'column2',
          name: 'Prospect',
          fieldName: 'lead',
          minWidth: 250,
          maxWidth: 600,
          isRowHeader: true,
          onRender: (quote: IQuoteReference) => (
            <span>{displayNameLead(quote.lead)}</span>
          ),
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column3',
          name: 'Omschrijving',
          fieldName: 'description',
          minWidth: 250,
          maxWidth: 600,
          isRowHeader: true,
          onRender: (quote: IQuoteReference) => <span>{quote.description}</span>,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column4',
          name: 'Datum',
          fieldName: 'quote_date',
          minWidth: 100,
          maxWidth: 600,
          isRowHeader: true,
          onRender: (quote: IQuoteReference) => (
            <span>{moment(new Date(quote.quote_date)).format('DD/MM/YYYY')}</span>
          ),
          onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column5',
          name: 'Verkoper',
          fieldName: 'description',
          minWidth: 150,
          maxWidth: 600,
          isRowHeader: true,
          onRender: (quote: any) => (
            <span>
              {quote.lead &&
                quote.lead.sales_rep_employee &&
                displayNameEmployee(quote.lead.sales_rep_employee)}
            </span>
          ),
          data: 'string',
          isPadded: true,
        },
    ];

    const initColumns = (sortedField: string, isSortedAsc: boolean) => columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

    const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

    return (
      <DetailsListDefault
        items={items}
        columns={columns}
        layoutMode={DetailsListLayoutMode.justified}
        selection={selection}
        selectionMode={SelectionMode.single}
        selectionPreservedOnEmptyClick
        onItemInvoked={onItemInvoked}
        enableShimmer={enableShimmer}
        loadMore={loadMore}
        lazyLoading={lazyLoading}
        loadMoreCallback={loadMoreCallback}
        loading={loading}
      />
    );
  };

  export default QuoteDetailsList;
