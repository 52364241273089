import {
    Checkbox, DetailsListLayoutMode, IColumn, IObjectWithKey, ISelection, SelectionMode,
} from '@fluentui/react';
import { useState } from 'react';
import { DetailsListDefault } from '../../../components/parts';
import { IEmployee, ISalesRep } from '../../../utils';
import { ICar } from '../../../utils/Car';
import { formatPhoneNumber } from '../../../helpers';

interface props {
    items: ISalesRep[],
    selection?: ISelection<IObjectWithKey> | undefined,
    onItemInvoked?: () => void;
    enableShimmer?: boolean;
    isSortedAsc?: boolean;
    sortedField: string;
    setSorting: (isDesc: boolean, field: string) => void;
    loadMore?: boolean;
    lazyLoading?: boolean;
    loading?: boolean;
    loadMoreCallback?: () => void;
}

const SalesRepDetailsList = ({
    items,
    selection,
    onItemInvoked,
    enableShimmer = false,
    isSortedAsc = false,
    sortedField,
    setSorting, // = (isSortedAsc: boolean, sortedField: string) => {},
    loadMore,
    lazyLoading,
    loading,
    loadMoreCallback,
  }: props) => {
    // On Column Click
    const onColumnClick = (event: any, column: any) => {
        const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
        let fieldName = '';
        let sortDescending = false;

        if (sortedColumn) {
          const newColumns = columns.map((col: IColumn) => {
            if (col.fieldName === column.fieldName) {
              col.isSorted = true;

              col.isSortedDescending =
                column.fieldName !== sortedColumn[0].fieldName
                  ? false
                  : !col.isSortedDescending;

              fieldName = column.fieldName;
              sortDescending = col.isSortedDescending;
            } else {
              col.isSorted = false;
            }
            return col;
          });
          setColumns(newColumns);

          setSorting(!sortDescending, fieldName);
        }
      };

    // Column List
    const columnsList = [
        {
          key: 'column1',
          name: 'Achternaam',
          fieldName: 'last_name',
          minWidth: 150,
          maxWidth: 150,
          isRowHeader: true,
          onRender: (salesRep: IEmployee) => <span>{salesRep.last_name}</span>,
          onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column2',
          name: 'Voornaam',
          fieldName: 'first_name',
          minWidth: 150,
          maxWidth: 150,
          isRowHeader: true,
          onRender: (salesRep: IEmployee) => <span>{salesRep.first_name}</span>,
          onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column3',
          name: 'Functie',
          fieldName: 'function',
          minWidth: 150,
          maxWidth: 150,
          isRowHeader: true,
          onRender: (salesRep: IEmployee) => <span>{salesRep.function}</span>,
          onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column4',
          name: 'GSM Werk',
          fieldName: 'mobile_v2',
          minWidth: 130,
          maxWidth: 130,
          isRowHeader: true,
          onRender: (salesRep: IEmployee) => <span>{salesRep?.mobile_v2 ? formatPhoneNumber(salesRep?.mobile_v2) : ''}</span>,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column4',
          name: 'GSM Privé',
          fieldName: 'private_mobile_v2',
          minWidth: 130,
          maxWidth: 130,
          isRowHeader: true,
          onRender: (salesRep: IEmployee) => (
            <span>{salesRep?.private_mobile_v2 ? formatPhoneNumber(salesRep?.private_mobile_v2) : ''}</span>
          ),
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column6',
          name: 'Actief',
          fieldName: 'active',
          minWidth: 30,
          maxWidth: 30,
          isRowHeader: true,
          onRender: (salesRep: IEmployee) => (
            <span>
              <Checkbox disabled defaultChecked={salesRep.active} />
            </span>
          ),
          onColumnClick,
          data: 'string',
          isPadded: true,
        },
    ];

    const initColumns = (sortedField: string, isSortedAsc: boolean) => columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

    const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

    return (
      <DetailsListDefault
        items={items}
        columns={columns}
        layoutMode={DetailsListLayoutMode.justified}
        selection={selection}
        selectionMode={SelectionMode.single}
        selectionPreservedOnEmptyClick
        onItemInvoked={onItemInvoked}
        enableShimmer={enableShimmer}
        loadMore={loadMore}
        lazyLoading={lazyLoading}
        loadMoreCallback={loadMoreCallback}
        loading={loading}
      />
    );
  };

  export default SalesRepDetailsList;
