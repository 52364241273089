import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { ILead } from '../../../utils/Lead';
import LeadDownloadTemplate from './LeadDownloadTemplate';

const LeadDownload = async (lead?: ILead, dataSuggestedValues?: any) => {
  if (lead) {
    const blob = await pdf(
      <LeadDownloadTemplate lead={lead} dataSuggestedValues={dataSuggestedValues?.findManySuggestedValues} />,
    ).toBlob();
    saveAs(
      blob,
      'prospect{id}.pdf'.replace(
        '{id}',
        lead?.id
          ? `_${lead?.id}`
          : '',
      ),
    );
  }
};

export default LeadDownload;
