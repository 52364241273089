import React, { useState, useEffect } from 'react';
import {
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { displayNameLead } from '../../../utils/Lead';
import { displayNameEmployee } from '../../../utils';
import {
  ADD_QUOTE_REFERENCE,
  GET_QUOTE_REFERENCE,
  GET_QUOTE_REFERENCES,
  IQuoteReference,
  UPDATE_QUOTE_REFERENCE,
} from '../../../utils/QuoteReference';
import { commandBarTheme, commandBarThemeCounter } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import { dismissNotification } from '../../../redux/notification/notificationSlice';
import { SeverityLevel, throwError } from '../../../redux/error/errorSlice';
import { useAppDispatch } from '../../../redux/hooks';
import QuoteDetail from './QuoteDetail';
import QuoteDetailsList from './QuoteDetailsList';

const QuotesOverview = ({
  quotes,
  loading,
  loadMore,
  setSorting,
  isSortedAsc = false,
  sortedField,
  showLoadMore,
  lazyLoading,
  loadMoreCallback = () => ({}),
  downloadFile = () => ({}),
  isReport,
}: any) => {
  const dispatch = useAppDispatch();
  const [quote, setQuote] = useState<IQuoteReference | undefined>(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<
    IQuoteReference | undefined
  >();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  useQuery(GET_QUOTE_REFERENCE, {
    // notifyOnNetworkStatusChange: true,
    variables: selectionDetails
      ? { where: { id: selectionDetails.id } }
      : undefined,
    skip: !selectionDetails,
    onCompleted: data => {
      setQuote(data.findOneQuoteReference);
    },
  });

  const openQuoteDetail = (newQuote?: boolean) => {
    if (selectionDetails && !newQuote) {
      //  console.log('getting the quote');
      //  getQuote({ variables: { where: { id: selectionDetails.id } } });
    } else {
      setQuote(undefined);
    }
    setIsPanelOpen(true);
  };

  const [addQuote] = useMutation(ADD_QUOTE_REFERENCE, {
    onError: error => {
      dispatch(dismissNotification());

      dispatch(
        throwError({
          module: 'executionList.saveQuote',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
    // refetchQueries: [{ query: GET_QUOTE_REFERENCES }],
  });

  const [modifyQuote] = useMutation(UPDATE_QUOTE_REFERENCE, {
    onError: error => {
      dispatch(dismissNotification());

      dispatch(
        throwError({
          module: 'executionList.updateQuote',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
  });

  const saveQuote = () => {
    if (quote) {
      const allInput = {
        quote_no: quote.quote_no,
        quote_date: quote.quote_date,
        description: quote.description,
        comments: quote.comments,
        lead: { connect: { id: quote.lead.id } },
      };

      if (quote.id) {
        modifyQuote({
          variables: {
            id: quote.id,
            data: allInput,
          },
        }).then(() => {
          setIsPanelOpen(false);
        });
      } else {
        addQuote({
          variables: {
            data: allInput,
          },
        }).then((x:any) => {
          if (x && x.data && x.data.createQuote) setQuote(x.data.createQuote);
          setIsPanelOpen(false);
        });
      }
    }
  };

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'totaal',
      text: `${quotes.length} / ${quotes.length > 0 ? quotes[0].prisma_total : 0}`,
      theme: commandBarThemeCounter,
    },
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openQuoteDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openQuoteDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
    {
      key: 'download',
      text: 'Excel',
      iconProps: { iconName: 'ExcelDocument' },
      onClick: () => downloadFile(),
      theme: commandBarTheme,
      // disabled: !selectionDetails,
    },
  ];

  useEffect(() => {
    if (quote && isPanelOpen) {
      document.title = `3bouw | Offerte - ${quote.quote_no}`;
    } else {
      document.title = '3bouw | Offertes';
    }
  }, [quote, isPanelOpen]);

  return (
    <>
      {!isReport && (
        <>
          <CommandBarSticky
            items={commandBaritems}
            theme={commandBarTheme}
            maxWidth='2000px'
          />
          {isPanelOpen && (<QuoteDetail
            isOpen={isPanelOpen}
            dismissPanel={() => {
              setIsPanelOpen(false);
            }}
            quote={quote || {}}
            saveQuote={saveQuote}
            setQuote={setQuote}
          />)}
        </>
      )}
      <QuoteDetailsList
        items={quotes}
        selection={selection}
        enableShimmer={!loading}
        isSortedAsc={isSortedAsc}
        sortedField={sortedField}
        setSorting={setSorting}
        loadMore={loadMore}
        lazyLoading={lazyLoading}
        loadMoreCallback={loadMoreCallback}
        loading={loading}
        onItemInvoked={() => openQuoteDetail(false)}
      />
      {quotes && (
        <>
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton text='Toon meer' onClick={() => loadMoreCallback()} />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {/* {loading && !initialLoad && (
        <div style={{ textAlign: 'center' }}>Laden....</div>
      )} */}
      {!loading && !showLoadMore && quotes.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default QuotesOverview;
