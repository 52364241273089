import React from 'react';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { IPurchaseOrder, normalizeFileNameBestelbon } from '../../../utils';
import PurchaseOrderDownloadTemplate from './PurchaseOrderDownloadTemplate';

const purchaseOrderDownload = async (purchaseOrder?: IPurchaseOrder) => {
  if (purchaseOrder) {
    const blob = await pdf(
      <PurchaseOrderDownloadTemplate purchaseOrder={purchaseOrder} />,
    ).toBlob();
    saveAs(
      blob,
      `${normalizeFileNameBestelbon(
        `Bestelbon-${purchaseOrder.description || ''}-${
          purchaseOrder.construction_site?.name || ''
        }`,
      )}.pdf`,
    );
  }
};

export default purchaseOrderDownload;
